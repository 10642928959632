import React from 'react';

import { Modal, Button } from 'antd';

import styles from './style.module.scss';

interface Props {
  isVisible: boolean;
  onCancel: Function;
  onAgree: Function;
}

const Tnc: React.FC<Props> = ({ isVisible, onCancel, onAgree }) => {
  return (
    <Modal
      visible={isVisible}
      title="Terms of services"
      width={'70%'}
      bodyStyle={{ padding: '1rem' }}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onAgree}>
          I Agree
        </Button>,
      ]}
    >
      <div className={styles.parentModal}>
        <div className={styles.agreementBlock}>
          <p>
            <b>ThiknkSabio</b> (hereinafter “We,” “Us,” “Our,” or “Company”) is
            not a registered“investment adviser” as that term is defined in
            §202(11) of the Investment Advisers Act of 1940,codified at 15
            U.S.C. §80b-2(a)(11), or as that term is defined in the North
            Carolina InvestmentAdvisers Act, N.C. Gen. Stat. §78C-2(1). We
            provide aggregated statistical informationcompiled from publicly
            available sources regarding securities owned by certain persons
            and/orentities (the “Service”) and make it freely available to any
            user (hereinafter “You” or “User”) forany lawful purpose. We do not
            provide individualized or personalized investment advice, and Wedo
            not earn any compensation for providing the Service to You. By using
            the Service, You agreeto hold Us harmless from any liability of any
            kind whatsoever arising from any decision youmake based on
            information provided by the Service, to the fullest extent permitted
            by law.
          </p>
          <p>
            We do not engage in the business of advising others as to the value,
            advisability, or suitability ofinvesting in any particular security
            or financial product. All information provided by Us, togetherwith
            any other information posted or made available by any other User of
            the Service is forinformational purposes only and is offered on an
            “as is” basis, with no warranties, express orimplied, as to the
            completeness, accuracy, or suitability of the information provided.
            Investmentsare inherently risky, subject to decline in value
            (including the total loss of principal invested),and are not insured
            by the Federal Deposit Insurance Company (“FDIC”). You should
            consultwith a registered investment adviser, financial planner, or
            other fiduciary for individualizedadvice prior to making any
            investment decision.{' '}
          </p>{' '}
          <p>
            We are not responsible for, and do not warrant, guarantee, or
            ensure, the accuracy, suitability, orany other quality of the
            content posted on the Service by other Users. You agree that You
            retainno ownership rights or other privileges of any kind with
            respect to content, messages, orinformation You share, post, or
            upload in connection with Your use of the Service, and Youunderstand
            that Your use of the Service is a limited license that is freely
            revocable by Us withoutnotice or justification. We reserve the
            right, in Our sole and exclusive discretion, to remove anyUser’s
            access to the Service for any reason whatsoever, or to limit,
            abridge, remove, or otherwisemodify any content posted or uploaded
            to the Service by any User. The Service, and all relatedcontent,
            ideas, and iterations thereof is the exclusive intellectual property
            of the Company, andall rights in the Service and related content are
            expressly reserved by Us.
          </p>
        </div>
      </div>
    </Modal>
  );
};
export default Tnc;
