import React from 'react';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  // CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Cell,
} from 'recharts';

import { useTheme } from '../../../ThemeContext';
import { black1, white, green, red } from '../../../utils';

const HorizontalBarChart: React.FC<Props> = ({ data, onChange }: Props) => {
  const { theme } = useTheme();
  const getBarColor = (value) => {
    if (value > 0) {
      return green;
    } else if (value < 0) {
      return red;
    } else {
      return theme === 'dark' ? white : black1;
    }
  };
  let position = 'left';
  if (data.every((e) => e.perChange >= 0)) {
    position = 'right';
  }
  // position = 'right';
  // console.log('position', position);
  const changeHandler = (rec) => {
    const item = data.find((d) => d.symbol_sector === rec.value);
    if (item.symbol !== 'SPY') {
      onChange(item.sector);
    }
  };
  return (
    <ResponsiveContainer width={'100%'} height={400}>
      <BarChart
        width={'100%'}
        height={400}
        data={data}
        layout="vertical"
        barCategoryGap={1}
        // barSize={200}
        // style={{
        //   border: '1px solid red',
        // }}
        margin={{
          top: 5,
          right: position === 'left' ? 100 : 50,
          left: 10,
          bottom: 5,
        }}
      >
        <XAxis type="number" hide />
        <YAxis
          dataKey="symbol_sector"
          type="category"
          width={150}
          stroke={theme === 'dark' ? white : black1}
          onClick={(rec) => changeHandler(rec)}
          style={{
            cursor: 'pointer',
          }}
        />
        <Bar isAnimationActive={false} dataKey="perChange" barSize={15}>
          <LabelList dataKey="formatted_value" position={position} />
          {data.map((entry, index) => {
            return (
              <Cell key={`cell-${index}`} fill={getBarColor(entry.perChange)} />
            );
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HorizontalBarChart;
