import React, { useState, useEffect, useCallback } from 'react';

import { FilterOutlined } from '@ant-design/icons';
import { DatePicker, Row, Typography, Button, Input } from 'antd';
import { get, debounce, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Table, Pagination } from '../../components';
import { getSymbolSearchCounts } from '../../graphql';
import { setQuarterlyTrendingData } from '../../redux/reducers/trending';
import { useTheme } from '../../ThemeContext';
import { errorHandler, formatColumn, formatTableData2 } from '../../utils';
import FilteredValues from './FilteredValues';
import { arrangeColumns } from './FormatTableColumns';
import styles from './styles.module.scss';
import TrendingFilter from './TrendingFilter';

const { Title } = Typography;

interface Props extends RouteComponentProps<any> {
  type: string;
}

const QuarterlyTrendingData: React.FC<Props> = ({ type, history }) => {
  const { data } = useSelector(
    ({ trending }) => ({
      data: get(trending, 'quarterly', {}),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const trendingData = get(data, type.toUpperCase(), {});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [date, setDate] = useState(moment().tz('America/New_York'));
  const [filters, setFilters] = useState({});
  const [openFilterForm, setOpenFilterForm] = useState<boolean>(false);
  const [sortDetail, setSortDetail] = useState<Array>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [pageHeight, setPageHeight] = useState<number>();
  const [fetchData, setFetchData] = useState<Date>(new Date());

  useEffect(() => {
    setPageHeight(window.innerHeight);
    window.addEventListener('resize', (e) => {
      setTimeout(() => {
        setPageHeight(window.innerHeight);
      }, 300);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const apis = [
      getSymbolSearchCounts({
        quarter: `${date.format('YYYY')}_Q${date.quarter()}`,
        type: 'QUARTERLY',
        page: pageNo,
        size: pageSize,
        for: type.toUpperCase(),
        searchText: searchText,
        filters: filters,
        sort: sortDetail,
      }),
    ];
    Promise.all(apis)
      .then((res) => {
        dispatch(setQuarterlyTrendingData(res[0], type.toUpperCase()));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    date,
    // filters,
    fetchData,
    pageNo,
    pageSize,
    searchText,
    // sortDetail,
    // type,
  ]);

  // useEffect(() => {
  //   setPageNo(1);
  //   setPageSize(10);
  //   setSearchText('');
  //   setSortDetail([]);
  //   setFilters({});
  //   setFetchData(new Date());
  // }, [type]);

  const onChange = (date: Date) => {
    setDate(date);
  };

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setPageNo(page);
  };

  const handleSort = (sortBy, sortOrder, multi) => {
    if (multi) {
      let newSort = sortDetail;
      if (sortOrder) {
        newSort = sortDetail.filter((s) => s.sortBy !== sortBy);
        newSort.push({
          sortBy: sortBy,
          sortOrder: sortOrder,
        });
      } else {
        newSort = sortDetail.filter((s) => s.sortBy !== sortBy);
      }
      setSortDetail([...newSort]);
    } else {
      if (sortOrder) {
        setSortDetail([
          {
            sortBy: sortBy,
            sortOrder: sortOrder,
          },
        ]);
      } else {
        setSortDetail([]);
      }
    }
    setFetchData(new Date());
  };

  const changeSearchText = (value: string) => {
    setPageNo(1);
    setSearchText(value);
    // setFilterOptions({});
  };

  const onFilter = (val, sort) => {
    setFilters(val);
    if (sort && Array.isArray(sort)) {
      setSortDetail(sort);
    }
    setFetchData(new Date());
  };

  const debouncedSave = useCallback(
    debounce((value) => changeSearchText(value), 500),
  );

  const onTextChange = (event) => {
    const { value } = event.target;
    debouncedSave(value);
  };

  const panelHeader = () => (
    <Row span={24} justify="space-between" className={styles.vPanelHeaderTable}>
      <Title level={4}>{`ThinkSabien's Activity by ${
        type === 'SYMBOL' ? 'Symbols' : 'Institutions'
      } for Q${date.quarter()}-${date.format('YYYY')} `}</Title>
      <DatePicker
        disabledDate={(currentDate: Date) => currentDate > moment()}
        onChange={onChange}
        defaultValue={date}
        picker="quarter"
        clearIcon={false}
        className={styles.vDatePickerNew1}
      />
    </Row>
  );

  const handleTableOnChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    let index = sortDetail.findIndex((d) => d.sortBy === field);
    if (index > -1) {
      let newOrder =
        order === 'ascend' ? 'ASC' : order === 'descend' ? 'DESC' : null;
      handleSort(field, newOrder, false);
    } else {
      if (order === 'ascend') {
        handleSort(field, 'ASC', false);
      } else if (order === 'descend') {
        handleSort(field, 'DESC', false);
      } else {
        handleSort(field, null, false);
      }
    }
  };
  const handleFilterValueClick = (name, value) => {
    const updatedFilters = { ...filters };
    delete updatedFilters[name];
    setFilters(updatedFilters);
    setOpenFilterForm(false);
    setFetchData(new Date());
  };
  const handleReset = () => {
    setFilters({});
    setFetchData(new Date());
  };

  const y = pageHeight - 290;
  const total = get(trendingData, 'total', 0);
  return (
    <>
      <div className={styles.vReportSearchHead}>
        <div className={styles.reportOptionBlock} style={{ marginRight: 20 }}>
          <Input
            placeholder={`search by ${
              type === 'SYMBOL' ? 'symbol' : 'institution'
            }...`}
            onChange={onTextChange}
            className={styles.VdailySearch}
            allowClear
          />
        </div>
        <Button
          icon={<FilterOutlined />}
          size={20}
          onClick={() => {
            setOpenFilterForm(!openFilterForm);
          }}
          //style={{ marginRight: 20 }}
          className={styles.Vfilterbtn}
          disabled={false}
          id="reportColumnFilters"
        >
          Filter/Sort
        </Button>
      </div>
      <div className={styles.filterValueBox}>
        {!isEmpty(filters) &&
          Object.values(filters).filter((d) => d).length > 0 && (
            <FilteredValues
              filterOptions={filters}
              handleClick={handleFilterValueClick}
              handleReset={handleReset}
            />
          )}
      </div>
      {get(trendingData, 'data', []).length > 0 && (
        <>
          <Table
            loading={isLoading}
            scroll={{ y: y }}
            columns={formatColumn(
              arrangeColumns(get(trendingData, 'columns')),
              true,
              sortDetail,
              history,
              false,
              null,
              null,
              null,
              null,
              theme,
              // formatTableData2(get(trendingData, 'data', [])),
            )}
            dataSource={formatTableData2(get(trendingData, 'data'))}
            pagination={false}
            size="small"
            bordered={true}
            title={() => panelHeader()}
            // onChange={handleTableOnChange}
            rowClassName={styles.tableHeader}
            className={styles.TabularData}
            showSorterTooltip={false}
            onChange={handleTableOnChange}
            id="allPicksId"
          />

          <Pagination
            pageSize={pageSize}
            total={total}
            pageNo={pageNo}
            onChange={onPaginationChange}
            loading={isLoading}
            showPagination={true}
          />
        </>
      )}

      {openFilterForm && (
        <TrendingFilter
          visible={openFilterForm}
          onCancel={() => setOpenFilterForm(false)}
          onFilter={onFilter}
          filterOptions={filters}
          sortDetail={sortDetail}
          type={type.toUpperCase()}
          // handleSortReset={() => setSortDetail([])}
          // handleSortSubmit={(val) => setSortDetail(val)}
          columns={arrangeColumns(get(trendingData, 'columns', []))}
        />
      )}
    </>
  );
};

export default QuarterlyTrendingData;
