import React from 'react';

import styles from './style.module.scss';

const universityTypeData = {
  stocks: {
    title: 'STOCKS SIMPLIFIED',
    cards: [
      {
        text: (
          <span>
            Popular <br /> Stocks
          </span>
        ),
        image_url: 'https://thinksabio-asset.s3.amazonaws.com/background1.jpeg',
        page_url: 'popular-stock',
      },
      {
        text: (
          <span>
            Success <br /> Stories
          </span>
        ),
        image_url: 'https://thinksabio-asset.s3.amazonaws.com/background1.jpeg',
        page_url: 'success-story',
      },
      {
        text: (
          <span>
            Chalk Talk by <br /> Experts
          </span>
        ),
        image_url: 'https://thinksabio-asset.s3.amazonaws.com/background2.jpeg',
        page_url: 'expert-talk',
      },
      {
        text: (
          <span>
            Videos on <br /> Reports
          </span>
        ),
        image_url: 'https://thinksabio-asset.s3.amazonaws.com/background3.jpeg',
        page_url: 'report-videos',
      },
      {
        text: (
          <span>
            Telegram <br /> Groups
          </span>
        ),
        image_url: 'https://thinksabio-asset.s3.amazonaws.com/background4.jpeg',
        page_url: 'telegram-groups',
      },
    ],
  },
  options: {
    title: 'OPTIONS MAIN GROUP',
    cards: [
      {
        text: (
          <span>
            Success <br /> Stories
          </span>
        ),
        image_url: 'https://thinksabio-asset.s3.amazonaws.com/background1.jpeg',
        page_url: 'success-story',
      },
      {
        text: (
          <span>
            Chalk Talk by <br /> Experts
          </span>
        ),
        image_url: 'https://thinksabio-asset.s3.amazonaws.com/background2.jpeg',
        page_url: 'expert-talk',
      },
      {
        text: (
          <span>
            Option Telegram <br /> Groups
          </span>
        ),
        image_url: 'https://thinksabio-asset.s3.amazonaws.com/background3.jpeg',
        page_url: 'telegram-groups',
      },
    ],
  },
  crypto: {
    title: 'CRYPTO STOCKS',
    cards: [
      {
        text: (
          <span>
            Success <br /> Stories
          </span>
        ),
        image_url: 'https://thinksabio-asset.s3.amazonaws.com/background1.jpeg',
        page_url: 'success-story',
      },
      {
        text: (
          <span>
            Chalk Talk by <br /> Experts
          </span>
        ),
        image_url: 'https://thinksabio-asset.s3.amazonaws.com/background1.jpeg',
        page_url: 'expert-talk',
      },
      {
        text: (
          <span>
            Crypto Telegram <br /> Groups
          </span>
        ),
        image_url: 'https://thinksabio-asset.s3.amazonaws.com/background2.jpeg',
        page_url: 'telegram-groups',
      },
      // {
      //   text: <span>Feedback</span>,
      //   image_url: 'https://thinksabio-asset.s3.amazonaws.com/background3.jpeg',
      //   page_url: 'telegram-groups',
      // },
    ],
  },
};

const UniversityType: React.FC<Props> = ({ history, match }) => {
  const { params } = match;
  const { type } = params;
  const pageData = universityTypeData[type];
  const { title, cards } = pageData;
  return (
    <div className={styles.stockUniversityPage}>
      <div className={styles.universityHeaderView}>
        <h1>{title}</h1>
        <button onClick={() => history.goBack()}>BACK</button>
      </div>
      <div className={styles.cardSection}>
        {cards.map(({ text, image_url, page_url }, index) => (
          <div key={index} className={styles.card}>
            <img src={image_url} alt="card" />
            <h4>{text}</h4>
            <button
              onClick={() => history.push(`/university/${type}/${page_url}`)}
            >
              Learn More
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UniversityType;
