import React, { useState } from 'react';

import { ArrowLeftOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { RouteComponentProps, Link } from 'react-router-dom';

import { MembershipCard } from '../../components';
import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {}

const Pricing: React.FC<Props> = ({ history }) => {
  const [showBothCard, setShowBothCard] = useState<boolean>(false);

  const cards = [
    {
      validity: '/annually',
      btnText: '',
      title: '12-Months',
      type: 'yearly',
      plan: { price: 99.99 },
      show: true,
      popular: true,
      billedText: `*Billed $99.99 Annually`,
      // realPrice: '$39.99',
      discountedPrice: '$8.33',
      percentOff: '17',
    },
    {
      validity: '/month',
      btnText: '',
      title: '1-Month',
      type: 'monthly',
      plan: { price: 9.99 },
      show: showBothCard,
      popular: false,
      billedText: `*Billed $9.99 Monthly`,
      // realPrice: '$39.99',
      discountedPrice: '$9.99',
      // percentOff: null, //'0',
    },
  ];

  const onPlanSelect = () => {
    // history.push('/sign-up');
  };

  return (
    <>
      <div
        style={{ marginTop: 100, marginLeft: 50 }}
        className={styles.vGoBackSaveBar}
      >
        <p onClick={() => history.goBack()} className={styles.vGoBackBtnBlock}>
          <span className={styles.vGoBackBtn}>
            <ArrowLeftOutlined /> Go Back
          </span>
        </p>
      </div>
      <div className={styles.pricingCardSection}>
        <h5 className={styles.choosePlanText}>Choose a plan that suits you.</h5>
        <Row span={24} justify="center" className={styles.cardRow}>
          {cards
            .filter((card) => card.show)
            .map((card) => (
              <Col key={card.type} className={styles.cardCol}>
                <MembershipCard
                  // setMembership={(val) => {}}
                  // validity={card.validity}
                  btnText={card.btnText}
                  title={card.title}
                  type={card.type}
                  onPlanSelect={onPlanSelect}
                  // isLoading={false}
                  // plan={card.plan}
                  popular={card.popular}
                  billedText={card.billedText}
                  discountedPrice={card.discountedPrice}
                  realPrice={card.realPrice}
                  percentOff={card.percentOff}
                />
              </Col>
            ))}
        </Row>
        {!showBothCard && (
          <p className={styles.insteadText}>
            Instead go for{' '}
            <span onClick={() => setShowBothCard(true)}> Monthly Plan </span>
          </p>
        )}
        <div className={styles.featuresSection}>
          <h3 className={styles.titleFeature}>On Subscribing:</h3>
          <ul>
            {/* <li>
      <CheckCircleOutlined /> Life Time Price is applicable only for Early
      ThinkSabiens & if No cancellation between Renewal ( Monthly/Yearly
      ).
    </li> */}
            <li>
              <CheckCircleOutlined /> Subscription will be Automatic Renewal (
              Monthly/Yearly ) based on your current plan.
            </li>
            <li>
              <CheckCircleOutlined /> Payments processed are Non-Refundable (
              Monthly/Yearly ).
            </li>
            <li>
              <CheckCircleOutlined /> Cancel anytime with one Click, upon
              cancellation you’re not Billed on your Next Renewal Date
            </li>
            <li>
              <CheckCircleOutlined /> Read Full{' '}
              <Link
                to="/tnc"
                // onClick={() => setOpenTnc(true)}
                className={styles.tnc}
              >
                Terms and Conditions
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Pricing;
