import React, { useState } from 'react';

import { Row, Col, Form, Button, InputNumber, notification, Input } from 'antd';

import { savePortfolioInfo } from '../../../graphql';
import { errorHandler, red } from '../../../utils';
import styles from './style.module.scss';
interface Props {
  handleCancel: Function;
  data: Array<{}>;
  portfolioName: string;
}

const EditRealStock: React.FC<Props> = ({
  handleCancel,
  data,
  portfolioName,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [avgCostError, setAvgCostError] = useState('');
  const [sharesError, setSharesError] = useState('');
  const [formFields, setFormFields] = useState(
    (data || []).map((item) => ({
      name: item.id,
      shares: item.no_of_shares,
      price: item.avg_cost,
      symbol: item.symbol,
      id: item.id,
    })),
  );

  const onFinish = () => {
    if (sharesError || avgCostError) {
      return;
    }
    const payload = formFields.map((field) => ({
      company: {
        symbol: field.symbol,
      },
      id: field.id,
      no_of_shares: field.shares.toFixed(2),
      avg_cost: field.price.toFixed(4),
    }));
    setLoading(true);
    savePortfolioInfo(payload, 'REAL', portfolioName)
      .then((res) => {
        // form.resetFields();
        notification['success']({
          message: 'Portfolio Updated successfully',
        });
        handleCancel();
      })
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  const onPriceChange = (value, name) => {
    let fields = formFields.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          price: value,
        };
      } else return item;
    });

    setFormFields(fields);
  };

  const onSharesChange = (value, name) => {
    let fields = formFields.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          shares: value,
        };
      } else return item;
    });

    setFormFields(fields);
  };
  const onInputChange = (value, name) => {
    var regExp = /^[0-9]*$/;
    let valueStr1 = value?.toString();
    var valueStr = valueStr1.replaceAll(',', '');
    valueStr = valueStr.replaceAll('.', '');
    valueStr = valueStr?.substr(name === 'avg' ? 2 : 0, valueStr.length);
    var match = valueStr.match(regExp);
    if (!match && name === 'avg') {
      setAvgCostError('special characters and alphabets not allowed');
      return;
    } else if (!match && name === 'shares') {
      setSharesError('special characters and alphabets not allowed');
      return;
    } else {
      setAvgCostError('');
      setSharesError('');
    }
  };
  return (
    <Form form={form} name="add-form" onFinish={onFinish} autoComplete="off">
      <Row span={24}>
        <Col span={10} className={styles.updateFormLabel}>
          Symbol
        </Col>
        <Col className={styles.updateFormLabel} span={7}>
          No of Shares
        </Col>
        <Col className={styles.updateFormLabel} span={7}>
          Avg Cost
        </Col>
      </Row>
      <Form.List name="portfolio">
        {(fields) => (
          <div style={{ maxHeight: 400, minHeight: 200, overflow: 'auto' }}>
            {formFields.map((field, i) => {
              const { name, symbol, shares, price } = field;
              return (
                <Row span={24} className={styles.sortFormScroll} key={name}>
                  <Col span={10}>
                    <Form.Item
                      {...field}
                      // label={i === 0 ? 'Symbol' : null}
                      name={[field.name, 'symbol']}
                      fieldKey={[field.fieldKey, 'symbol']}
                      rules={[
                        { required: true, message: 'Symbol is required' },
                      ]}
                      initialValue={symbol}
                      style={{ width: '90%' }}
                    >
                      <Input disabled size="middle" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'shares']}
                      //  label={i === 0 ? 'No of Shares' : null}
                      fieldKey={[field.fieldKey, 'shares']}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || value === 0) {
                              return Promise.reject('No of Shares is required');
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                      initialValue={shares}
                      style={{ width: '90%' }}
                    >
                      <InputNumber
                        min={0}
                        // ype="number"
                        // type="number"
                        placeholder="No of Shares"
                        style={{ width: '100%' }}
                        onChange={(value) => onSharesChange(value, name)}
                        onInput={(value) => onInputChange(value, 'shares')}
                        formatter={(n) => {
                          if (n?.toString().indexOf('.') > -1) {
                            const [p1, p2] = n?.toString().split('.');
                            let a = `${p1}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            );
                            if (p2) {
                              const b =
                                p2.length > 2
                                  ? p2.toString().substring(0, 2)
                                  : p2.toString();
                              a = a + '.' + b;
                            } else {
                              a = a + '.';
                            }
                            return a;
                          } else {
                            return `${n}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                          }
                        }}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                    {sharesError && <p style={{ color: red }}>{sharesError}</p>}
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'price']}
                      //  label={i === 0 ? 'Avg Cost' : null}
                      fieldKey={[field.fieldKey, 'price']}
                      rules={[
                        { required: true, message: 'Avg Cost is required' },
                      ]}
                      initialValue={price}
                      style={{ width: '90%' }}
                    >
                      <InputNumber
                        // min={0.0009}
                        // type="number"
                        onChange={(value) => onPriceChange(value, name)}
                        onInput={(value) => onInputChange(value, 'avg')}
                        placeholder="Avg Cost"
                        style={{ width: '100%' }}
                        formatter={(n) => {
                          if (n?.toString().indexOf('.') > -1) {
                            const [p1, p2] = n?.toString().split('.');
                            let a = `$ ${p1}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            );
                            if (p2) {
                              const b =
                                p2.length >= 4
                                  ? p2.toString().substring(0, 4)
                                  : p2.toString();
                              a = a + '.' + b;
                            } else {
                              a = a + '.';
                            }
                            return a;
                          } else {
                            return `$ ${n}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            );
                          }
                        }}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                    {avgCostError && (
                      <p style={{ color: red }}>{avgCostError}</p>
                    )}
                  </Col>
                  {/* <Col span={1} style={{ alignSelf: 'flexStart' }}>
                      <DeleteIcon
                        style={{ color: red }}
                        size={50}
                        onClick={() => onDelete(field)}
                      />
                    </Col> */}
                </Row>
              );
            })}
          </div>
        )}
      </Form.List>
      <Row
        span={24}
        justify="center"
        // className={styles.buttonSectionBoxNew}
      >
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default EditRealStock;
