import React, { useState, useEffect, useCallback } from 'react';

import { FilterOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Button, Input, Select, DatePicker } from 'antd';
import { get, debounce } from 'lodash';
import moment from 'moment-timezone';
// import queryString from 'query-string';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import { getOurPicks, esSearchCompany } from '../../graphql';
import { noData } from '../../images';
import { setQuarterlyPicks } from '../../redux/reducers/picks';
// import { setOurPickURL } from '../../redux/reducers/user';
import { green, errorHandler } from '../../utils';
import FilteredValues from './FilteredValues';
import { columns, OurPickType } from './Pick.constant';
import PickFilter from './PickFilter';
import PicksList from './PickList';
import styles from './style.module.scss';

// const { SubMenu } = Menu;
const { Title } = Typography;
const { Option } = Select;

interface Props {
  data: Array<OurPickType>;
  history: Object;
  match: Object;
  picksQueryData: Object;
}
const QuarterlyPick: React.FC<Props> = ({ history, match }) => {
  const { data, picksQueryData } = useSelector(
    ({ picks }) => ({
      data: get(picks, 'quarterly', []),
      picksQueryData: {}, //get(picks, 'queryData.quarterly', {}),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();

  const [year, setYear] = useState(
    get(picksQueryData, 'year', moment().get('year')),
  );
  const [quarter, setQuarter] = useState(
    get(picksQueryData, 'quarter', `Q${moment().quarter()}`),
  );
  const [openFilterForm, setOpenFilterForm] = useState<boolean>(false);
  const [type, setType] = useState(get(picksQueryData, 'type', 'quarter'));

  const [filters, setFilters] = useState(get(picksQueryData, 'filters', {}));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortDetail, setSortDetail] = useState<Array>(
    get(picksQueryData, 'sort', []),
  );
  const [searchText, setSearchText] = useState<string>(
    get(picksQueryData, 'searchText', ''),
  );
  const [fetchData, setFetchData] = useState<Date>(new Date());
  const [pageNo, setPageNo] = useState<number>(get(picksQueryData, 'page', 1));
  const [pageSize, setPageSize] = useState<number>(
    get(picksQueryData, 'size', 20),
  );
  const [symbolData, setSymbolData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [selectedSymbols, setSelectedSymbols] = useState(
    get(picksQueryData, 'symbols', []),
  );

  useEffect(() => {
    setIsLoading(true);
    let baseFilter = {};
    if (type === 'quarter') {
      baseFilter = {
        quarter: `${year}_${quarter}`,
      };
    }
    let allFilters = {
      ...baseFilter,
      ...filters,
    };
    if (type === 'symbol') {
      allFilters = {
        ...allFilters,
        symbols: selectedSymbols,
      };
    }
    // dispatch(
    //   setPickQueryData(
    //     {
    //       filters: filters,
    //       page: pageNo,
    //       size: pageSize,
    //       searchText: searchText,
    //       sort: sortDetail,
    //       type: type,
    //       year: year,
    //       symbols: selectedSymbols,
    //       quarter: quarter,
    //     },
    //     'quarterly',
    //   ),
    // );
    const apis = [
      getOurPicks({
        request: {
          type: 'QUARTERLY',
          filters: allFilters,
          page: pageNo,
          size: pageSize,
          searchText: searchText,
          sort: sortDetail.filter((i) => i.sortBy),
        },
      }),
    ];

    Promise.all(apis)
      .then((data) => {
        dispatch(setQuarterlyPicks(data[0]));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    // filters,
    history,
    pageNo,
    pageSize,
    quarter,
    searchText,
    selectedSymbols,
    fetchData,
    // sortDetail,
    type,
    year,
  ]);

  const fetchSymbols = async (value) => {
    setFetching(true);
    const res = await esSearchCompany(value);
    if (res) {
      setSymbolData(res);
    }
    setFetching(false);
  };

  // const onQuarterChange = (key: string) => {
  //   setSelectedQuarter(key);
  // };
  const onFilter = (val: FilterType, sort) => {
    if (sort && Array.isArray(sort)) {
      setSortDetail(sort);
    }
    setFilters(val);
    setFetchData(new Date());
  };
  const changeSearchText = (value: string) => {
    setPageNo(1);
    setSearchText(value);
    // setFilterOptions({});
  };

  const debouncedSave = useCallback(
    debounce((value) => changeSearchText(value), 500),
  );

  const onTextChange = (event) => {
    const { value } = event.target;
    debouncedSave(value);
  };
  const handleFilterValueClick = (name, value) => {
    let updatedFilters = { ...filters };
    if (name === 'symbols') {
      updatedFilters = {
        ...filters,
        symbols: updatedFilters.symbols.filter((s) => s !== value),
      };
    } else if (name === 'sector') {
      updatedFilters = {
        ...filters,
        sector: Array.isArray(updatedFilters.sector)
          ? updatedFilters.sector.filter((s) => s !== value)
          : undefined,
      };
    } else {
      delete updatedFilters[name];
    }

    setFilters(updatedFilters);
    setFetchData(new Date());
    setOpenFilterForm(false);
  };
  const isFilterEmpty = Object.keys(filters).filter((d) =>
    d === 'symbols' || d === 'sector'
      ? filters[d] && filters[d].length === 0
        ? undefined
        : filters[d]
      : filters[d],
  );
  const changeType = (val) => {
    setType(val);
    setPageNo(1);
  };
  const onQuarterChange = (date, dateString) => {
    const data = dateString.split('-');
    setYear(data[0]);
    setQuarter(data[1]);
    setPageNo(1);
  };
  const onSymbolSelect = (symbol) => {
    const newSymbols = [...selectedSymbols];
    newSymbols.push(symbol);
    setSelectedSymbols(newSymbols);
    setSymbolData([]);
  };

  const onSymbolDeSelect = (symbol) => {
    const symbols =
      (selectedSymbols || []).filter((sym) => sym !== symbol) || [];
    setSelectedSymbols(symbols);
  };
  const handleReset = () => {
    setFilters({});
    setFetchData(new Date());
  };
  const handleSort = (val) => {
    setSortDetail(val);
    setFetchData(new Date());
  };
  return (
    <>
      <Row justify="space-between">
        <Col className={styles.vSiteCalendarDemoCard1}>
          <Select
            value={type}
            onChange={changeType}
            className={styles.vDateRangeSelectBlockNew}
          >
            <Option value="quarter">Quarter</Option>
            <Option value="symbol">Symbol</Option>
          </Select>
          {type === 'quarter' && (
            <DatePicker
              className={styles.vDateBlocknew2}
              onChange={onQuarterChange}
              picker="quarter"
              disabledDate={(currentDate) => currentDate > moment()}
              allowClear={false}
              defaultValue={moment(year, 'YYYY')
                .quarter(quarter[1])
                .startOf('quarter')}
            />
          )}
          {type === 'symbol' && (
            <Select
              placeholder="Enter valid symbols"
              mode="multiple"
              style={{ width: 300 }}
              notFoundContent={
                fetching ? (
                  <span className={styles.spinnerStyle}>
                    <BounceLoader color={green} loading={true} size={50} />
                  </span>
                ) : null
              }
              onSearch={fetchSymbols}
              filterOption={false}
              showSearch
              allowClear={selectedSymbols.length > 1}
              onClear={() => setSelectedSymbols([])}
              onSelect={(key) => onSymbolSelect(key)}
              onDeselect={(key) => onSymbolDeSelect(key)}
              size="middle"
            >
              {symbolData.map((d) => (
                <Option key={d.symbol} className={styles.optionStyle}>
                  <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                  {d.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col className={styles.vFilterNewV2}>
          <div className={styles.reportOptionBlock}>
            <Input
              placeholder="search..."
              onChange={onTextChange}
              className={styles.VdailySearch}
              allowClear
            />
          </div>
          <Button
            icon={<FilterOutlined />}
            size="middle"
            onClick={() => {
              setOpenFilterForm(!openFilterForm);
            }}
            //style={{ marginRight: 20 }}
            className={styles.Vfilterbtn}
            disabled={false}
            id="reportColumnFilters"
          >
            Filter/Sort
          </Button>
        </Col>
      </Row>

      <div className={styles.pickUpSectionBox}>
        <div className={styles.pickupLeftBox}>
          {type === 'quarter' && (
            <Title level={5}>{`Picks for ${year}-${quarter}`}</Title>
          )}
        </div>
        <div className={styles.pickupRightBox}>
          {isFilterEmpty.length > 0 && (
            <FilteredValues
              filterOptions={filters}
              handleClick={handleFilterValueClick}
              type="symbol"
              handleReset={handleReset}
            />
          )}
        </div>
      </div>

      {data.length > 0 && (
        <PicksList
          isLoading={isLoading}
          data={data}
          type="quarterly"
          setSortDetail={(val) => handleSort(val)}
          setSearchText={(val) => setSearchText(val)}
          setPageNo={(val) => setPageNo(val)}
          setPageSize={(val) => setPageSize(val)}
          sortDetail={sortDetail}
          pageSize={pageSize}
          pageNo={pageNo}
          history={history}
        />
      )}
      {data.length === 0 && !isLoading && (
        <Row justify="space-between" span={24}>
          <div className={styles.noDataBox}>
            <div className={styles.noDataBoxContent}>
              <img src={noData} alt="no_data" />
              <p className={styles.vNoPicks}>{`No Picks ${year}-${quarter}`}</p>
            </div>
          </div>
        </Row>
      )}
      {openFilterForm && (
        <PickFilter
          visible={openFilterForm}
          onCancel={() => setOpenFilterForm(false)}
          onFilter={onFilter}
          filterOptions={filters}
          columns={columns}
          sortDetail={sortDetail}
          // handleSortReset={() => setSortDetail([])}
          // handleSortSubmit={(val) => setSortDetail(val)}
          type={type}
        />
      )}
    </>
  );
};

export default QuarterlyPick;
