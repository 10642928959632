import React, { useState, useEffect } from 'react';

import { Select } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
// import queryString from 'query-string';
import BounceLoader from 'react-spinners/BounceLoader';

import {
  esSearchAllInstitution,
  portFolioSearchByFavInst,
} from '../../../graphql';
import { setHealthCheckByFavIns } from '../../../redux/reducers/portfolio';
import { green, errorHandler } from '../../../utils';
import HealthCheckTable from './HealthCheckTable';
import styles from './style.module.scss';

const { Option } = Select;

interface Props {
  history: Object;
  portfolioType: string;
  portfolioName: string;
}

const HealthCheckByFavIns: React.FC<Props> = ({
  history,
  portfolioType,
  portfolioName,
}) => {
  const { selectedInstitutions } = useSelector(
    ({ portfolio }) => ({
      selectedInstitutions: get(portfolio, 'healthCheckByFavIns', []),
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const formatColumns = (columns: {}) => {
    const formattedColumns = (columns || []).map((col, index) => ({
      key: col.key,
      name: col.key,
      value: col.value,
      dataIndex: col.key,
      visible: true,
      index: col.key === 'symbol' ? 0 : index + 1,
      sort: true,
    }));
    return formattedColumns;
  };
  const [tableData, setTableData] = useState(null);
  const [loadingTypeData, setLoadingTypeData] = useState<boolean>(false);
  const [tableColumns, setTableColumns] = useState();
  const [institutionsData, setInstitutionsData] = useState([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  // const [selectedInstitutions, setSelectedInstitutions] = useState(
  //   institutions || [],
  // );
  const [loadingCik, setLoadingCik] = useState<boolean>(false);

  useEffect(() => {
    const ciks = (selectedInstitutions || []).map((inst) => inst.cik);
    if (ciks.length > 0) {
      let api = [
        portFolioSearchByFavInst({
          type: portfolioType,
          ciks: ciks,
          page: pageNo,
          size: pageSize,
          name: portfolioName,
        }),
      ];

      setLoadingTypeData(true);
      Promise.all(api)
        .then(([res]) => {
          setTableData(res);
          const { columns = [] } = res;
          setTableColumns(formatColumns(columns));
        })
        .catch(errorHandler)
        .finally(() => {
          setLoadingTypeData(false);
        });
    } else {
      setTableData(null);
      // setTableColumns(null);
    }
  }, [
    pageNo,
    pageSize,
    portfolioName,
    portfolioType,
    selectedInstitutions,
    selectedInstitutions.length,
  ]);
  const fetchInstitution = async (value) => {
    setLoadingCik(true);
    const res = await esSearchAllInstitution(value);
    setInstitutionsData(res);
    setLoadingCik(false);
  };

  const handleSelect = (insObj) => {
    const ins = selectedInstitutions;
    ins.push({
      name: insObj.value,
      cik: insObj.key,
    });
    dispatch(setHealthCheckByFavIns(ins));
    setInstitutionsData([]);
  };

  const handleDeSelect = (name, key) => {
    let ins = selectedInstitutions.filter((i) => i.name !== name);
    dispatch(setHealthCheckByFavIns(ins));
  };

  const getDefaultValue = () =>
    (selectedInstitutions || []).map((inst) => inst.name);

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setPageNo(page);
  };
  const handleChange = (names: [string]) => {
    if (!names.length) {
      dispatch(setHealthCheckByFavIns([]));
    }
  };

  return (
    <div style={{ minHeight: 400 }} id="healthCheck">
      <div className={styles.inputAndCheckoutBtnBox}>
        <Select
          mode={'multiple'}
          placeholder="Enter Institutions"
          //style={{ width: '100%' }}
          className={styles.vInstituBarNew}
          notFoundContent={
            loadingCik ? (
              <span className={styles.spinnerStyle}>
                <BounceLoader color={green} loading={true} size={50} />
              </span>
            ) : null
          }
          filterOption={false}
          onSearch={fetchInstitution}
          // size="large"
          defaultValue={getDefaultValue()}
          showSearch={true}
          allowClear={true}
          showArrow={false}
          onChange={(value: [string]) => handleChange(value)}
          onSelect={(value, key) => handleSelect(key)}
          onDeselect={(value, key) => handleDeSelect(value, key)}
        >
          {institutionsData.map((institution) => (
            <Option key={institution.cik} value={institution.name}>
              {institution.name}
            </Option>
          ))}
        </Select>
        {/* <Button
          loading={loadingTypeData}
          type="primary"
          onClick={handleSearch}
          block
          disabled={selectedInstitutions.length === 0}
        >
          Check Now
        </Button> */}
      </div>
      {tableData !== null && (
        <HealthCheckTable
          data={tableData}
          selectedKey={3}
          isLoading={loadingTypeData}
          history={history}
          setTableColumns={setTableColumns}
          tableColumns={tableColumns}
          healthCheckType="fav_institutions"
          showPagination
          pageSize={pageSize}
          pageNo={pageNo}
          onPaginationChange={onPaginationChange}
        />
      )}
    </div>
  );
};

export default HealthCheckByFavIns;
