import React, { useState } from 'react';

import { Tabs } from 'antd';
import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import PercentCalc from '../Tools.PercentCalc/PercentCalc';
import StopLossCalc from '../Tools.StopLossCalc/StopLossCalc';
import WhatIfCalc from '../Tools.WhatIfCalc/WhatIfCalc';

const { TabPane } = Tabs;

interface Props extends RouteComponentProps<any> {
  onStateChange: Function;
}
const Setting: React.FC<Props> = ({ history }) => {
  const [type, setType] = useState('1');
  // const [record2, setRecord2] = useState({});
  // const [record3, setRecord3] = useState({});
  // const [record4, setRecord4] = useState({});
  const { alerts } = useSelector(
    ({ user }) => ({
      alerts: get(user, 'alerts', {}),
    }),
    shallowEqual,
  );
  // const setTypeFunction = (key, record) => {
  //   setType(key);
  //   console.log(record);
  //   if (key === '2') {
  //     setRecord2(record);
  //   } else if (key === '3') {
  //     setRecord3(record);
  //   } else if (key === '4') {
  //     setRecord4(record);
  //   } else if (key === '5') {
  //     setRecord5(record);
  //   }
  // };

  const onTypeChangeFunction = (k) => {
    setType(k);
  };
  return (
    <>
      <div id="SettingBoxTabs">
        <div>
          <h4
            style={{
              color: 'var(--whitecolor)',
              textAlign: 'center',
              marginTop: '100px',
              marginLeft: '200px',
            }}
          >
            Tools
          </h4>
        </div>
        <Tabs
          defaultActiveKey={type}
          activeKey={type}
          tabPosition="left"
          id="settingTab"
          onChange={(key) => onTypeChangeFunction(key)}
          style={{ paddingTop: 0 }}
        >
          <TabPane tab="Percentage Calculator" key="1">
            <PercentCalc tabNum={type} alerts={alerts} />
          </TabPane>
          <TabPane tab="Stop Loss Calculator" key="2">
            <StopLossCalc tabNum={type} alerts={alerts} />
          </TabPane>
          <TabPane tab="Back Tester" key="3">
            <WhatIfCalc tabNum={type} alerts={alerts} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default Setting;
