import React, { useState } from 'react';

import { Form, Input, Button, message } from 'antd';
import { Auth } from 'aws-amplify';

import { red } from '../../utils';
import styles from './style.module.scss';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

interface ChangePasswordFormType {
  oldPassword: string;
  newPassword: string;
}

const ChangePassword: React.FC = () => {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const onFinish = (values: ChangePasswordFormType) => {
    setIsLoading(true);
    const { oldPassword, newPassword } = values;
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then((data) => {
        message.success('Password Successfully changed');
        form.resetFields();
      })
      .catch((err) => {
        const message =
          err.message === 'Incorrect username or password.' ||
          err.message ===
            `1 validation error detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6`
            ? 'Incorrect Current Password.'
            : err.message;
        setError(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className={styles.clientContainer}>
      <div className={styles.searchPaneTitle}>
        <h2>Change Password</h2>
      </div>
      <span style={{ color: red }}>{error}</span>
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
        name="change-password"
        onValuesChange={() => setError('')}
        id="vFormId"
      >
        <Form.Item
          label="Current Password"
          name="oldPassword"
          rules={[
            { required: true, message: 'Please input your old password!' },
          ]}
        >
          <Input.Password placeholder="Current Password" />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPassword"
          hasFeedback
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                let regex =
                  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,16}$/;
                if (!value) {
                  return Promise.reject('Password is required');
                }
                if (!regex.test(value)) {
                  return Promise.reject(
                    'Password must be 8-16 characters and contain both numbers and letters/special characters.',
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password placeholder="New Password" />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  'The two passwords that you entered do not match!',
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>
        <Form.Item {...tailLayout} className={styles.changeBtn}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePassword;
