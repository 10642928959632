import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
// import { get } from 'lodash';
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   // Legend,
//   ResponsiveContainer,
// } from 'recharts';
import { Bar } from 'react-chartjs-2';

import {
  green,
  // formatNumberWithPercent,
  //black
} from '../../../utils';

interface Props {
  data: [
    {
      sector: string;
      allocation: string;
    },
  ];
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const BarGraph: React.FC<Props> = ({ data }: Props) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: '% Allocation based on symbol',
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem, data) => {
            return tooltipItem.raw + '%';
          },
        },
      },
    },
  };
  const labels = data.map((d) => d.symbol);
  const values = data.map((a) => +a.percentage);

  // const data1 = {
  //   labels,
  //   datasets: [
  //     {
  //       data: values,
  //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //     },
  //   ],
  // };
  return (
    <>
      <Bar
        options={options}
        data={{
          labels,
          datasets: [
            {
              data: values,
              backgroundColor: green,
            },
          ],
        }}
      />
    </>
  );
};

export default BarGraph;
