import initialState from '../initialState';
// Actions
const SET_REPORT_QUARTERS = 'report/SET_REPORT_QUARTERS';
const SET_IIR_QUARTERS = 'report/SET_IIR_QUARTERS';
const SET_REPORT_SECTORS = 'report/SET_REPORT_SECTORS';
const SET_REPORT_INDUSTRIES = 'report/SET_REPORT_INDUSTRIES';
const RESET_REPORT = 'report/RESET_REPORT';
const SET_REPORT_NO_OF_WEEKS = 'report/SET_REPORT_NO_OF_WEEKS';
const SET_WEEKLY_YTD_NO_OF_WEEKS = 'report/SET_WEEKLY_YTD_NO_OF_WEEKS';
const SET_REPORT_DOW_NO_OF_DAyS = 'report/SET_REPORT_DOW_NO_OF_DAyS';
const SET_REPORT_DOW_NO_OF_DAYS_FOR_VOlUME =
  'report/SET_REPORT_DOW_NO_OF_DAYS_FOR_VOlUME';
const SET_REPORT_FILER_NAMES = 'report/SET_REPORT_FILER_NAMES';
const SET_REPORT_COUNTRIES = 'report/SET_REPORT_COUNTRIES';
const SET_REPORT_INSIDER_TITLES = 'report/SET_REPORT_INSIDER_TITLES';
const SET_REPORT_MUTUAL_FUND_SYMBOLS = 'report/SET_REPORT_MUTUAL_FUND_SYMBOLS';
const SET_REPORT_MUTUAL_FUND_FUND_SYMBOLS =
  'report/SET_REPORT_MUTUAL_FUND_FUND_SYMBOLS';
const SET_REPORT_SERIES_NAMES = 'report/SET_REPORT_SERIES_NAMES';

// Reducer
export default function reducer(state = initialState.report, action = {}) {
  switch (action.type) {
    case SET_REPORT_QUARTERS:
      return {
        ...state,
        quarters: action.payload,
      };
    case SET_IIR_QUARTERS:
      return {
        ...state,
        iirQuarters: {
          ...state.iirQuarters,
          [action.payload.cik]: action.payload.data,
        },
      };
    case SET_REPORT_SECTORS:
      return {
        ...state,
        sectors: action.payload,
      };
    case SET_REPORT_INDUSTRIES:
      return {
        ...state,
        industries: action.payload,
      };
    case SET_REPORT_NO_OF_WEEKS:
      return {
        ...state,
        noOfWeeks: action.payload,
      };
    case SET_WEEKLY_YTD_NO_OF_WEEKS:
      return {
        ...state,
        noOfWeeklYTDWeeks: action.payload,
      };
    case SET_REPORT_DOW_NO_OF_DAyS:
      return {
        ...state,
        noOfDays: action.payload,
      };
    case SET_REPORT_DOW_NO_OF_DAYS_FOR_VOlUME:
      return {
        ...state,
        noOfDaysForVolume: action.payload,
      };

    case SET_REPORT_FILER_NAMES:
      return {
        ...state,
        filerNames: action.payload,
      };
    case SET_REPORT_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_REPORT_INSIDER_TITLES:
      return {
        ...state,
        insiderTitles: action.payload,
      };
    case SET_REPORT_MUTUAL_FUND_SYMBOLS:
      console.log(action.payload);
      return {
        ...state,
        symbols: action.payload,
      };
    case SET_REPORT_MUTUAL_FUND_FUND_SYMBOLS:
      console.log(action.payload);
      return {
        ...state,
        fundSymbols: action.payload,
      };
    case SET_REPORT_SERIES_NAMES:
      console.log(action.payload);
      return {
        ...state,
        seriesNames: action.payload,
      };
    case RESET_REPORT:
      return {};
    default:
      return state;
  }
}

// Action Creators

export function setReportQuarters(data) {
  return {
    type: SET_REPORT_QUARTERS,
    payload: data,
  };
}

export function setIIRQuarters(cik, data) {
  return {
    type: SET_IIR_QUARTERS,
    payload: { cik: cik, data: data },
  };
}

export function setReportSectors(data) {
  return {
    type: SET_REPORT_SECTORS,
    payload: data.sort((a, b) => (a.name > b.name && 1) || -1),
  };
}
export function setReportIndustries(data) {
  return {
    type: SET_REPORT_INDUSTRIES,
    payload: data.sort((a, b) => (a.name > b.name && 1) || -1),
  };
}
export function resetReport() {
  return {
    type: RESET_REPORT,
  };
}

export function setReportNoOfWeeks(data) {
  return {
    type: SET_REPORT_NO_OF_WEEKS,
    payload: data,
  };
}

export function setWeeklyYTDNoOfWeeks(data) {
  console.log('data', data);
  return {
    type: SET_WEEKLY_YTD_NO_OF_WEEKS,
    payload: data,
  };
}

export function setReportNoOfDowDays(data) {
  return {
    type: SET_REPORT_DOW_NO_OF_DAyS,
    payload: data,
  };
}

export function setReportNoOfDowDaysForVolume(data) {
  return {
    type: SET_REPORT_DOW_NO_OF_DAYS_FOR_VOlUME,
    payload: data,
  };
}

export function setReportFilerNames(data) {
  return {
    type: SET_REPORT_FILER_NAMES,
    payload: data,
  };
}
export function setCountries(data) {
  return {
    type: SET_REPORT_COUNTRIES,
    payload: data,
  };
}

export function setReportInsiderTitles(data) {
  return {
    type: SET_REPORT_INSIDER_TITLES,
    payload: data,
  };
}

export function setReportMutualFundSymbols(data) {
  return {
    type: SET_REPORT_MUTUAL_FUND_SYMBOLS,
    payload: data,
  };
}

export function setReportMutualFundFundSymbols(data) {
  return {
    type: SET_REPORT_MUTUAL_FUND_FUND_SYMBOLS,
    payload: data,
  };
}

export function setReportSeriesNames(data) {
  return {
    type: SET_REPORT_SERIES_NAMES,
    payload: data,
  };
}
