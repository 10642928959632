import React, { useState, useCallback, useEffect } from 'react';

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { get } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { subscribePlan } from '../../api/RestApi';
import { getCustomerSubscriptionInfo, isReferralBoolean } from '../../graphql';
import { setUserProfile } from '../../redux/reducers/user';
import { green, errorHandler } from '../../utils';
import MembershipFormPage from './MembershipFormPage';
// import PaymentComplete from './PaymentComplete';
import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {
  customerId: string;
  priceId: string;
  selectedPlan: string;
  price: string;
  isNewUser: Boolean;
  closeModal?: Function;
}

const MembershipForm: React.FC<Props> = ({
  customerId,
  priceId,
  selectedPlan,
  price,
  history,
  isNewUser,
  closeModal,
}) => {
  const [isReferral, setIsReferral] = useState(false);
  const { personalData } = useSelector(
    ({ user }) => ({
      personalData: get(user, 'personalData', {}),
    }),
    shallowEqual,
  );
  const email = get(personalData, 'email', '');
  const getReferralBoolean = async () => {
    return await isReferralBoolean({ email: email });
  };
  useEffect(() => {
    getReferralBoolean().then((res) => {
      console.log('res', res.referral_to_claimed);
      setIsReferral(!res.referral_to_claimed);
      const bal = res.creditBalance;
      setBalance(bal !== 0 ? bal / 100 : 0);
    });
  }, [isReferral]);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState<boolean>(false);
  // const [
  //   subscriptionPaymentComplete,
  //   setSubscriptionPaymentComplete,
  // ] = useState<boolean>(false);

  const updateUserSubscriptionStatus = useCallback(() => {
    const promises = [getCustomerSubscriptionInfo()];
    setIsLoading(true);
    Promise.all(promises)
      .then((res) => {
        dispatch(setUserProfile(res[0]));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  const createSubscription = useCallback(
    ({ paymentMethodId, cardDetail, values }) => {
      const { cardHolderName, country, address, state, city, promoCode } =
        values;
      const { exp_month, exp_year, funding, last4 } = cardDetail;
      const payload = {
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        isTrial: selectedPlan === 'trial',
        promoCode: promoCode,
        card_details: {
          name: cardHolderName,
          country: country,
          address: address,
          state: state,
          city: city,
          last4: last4,
          expiry_month: exp_month,
          expiry_year: exp_year,
          funding: funding,
        },
      };
      const promises = [subscribePlan(payload)];
      setIsLoading(true);
      Promise.all(promises)
        .then((response) => {
          return response;
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        //          .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        //          .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then((onSubscriptionComplete) => updateUserSubscriptionStatus())
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setError(error.message);
          setIsLoading(false);
        })
        .finally(() => {
          if (closeModal) {
            closeModal();
          }
        });
    },
    [
      closeModal,
      customerId,
      priceId,
      selectedPlan,
      updateUserSubscriptionStatus,
    ],
  );

  // useEffect(() => {
  //   if (selectedPlan === 'trial') {
  //     const values = {};
  //     const cardDetail = {};
  //     let paymentMethodId = null;
  //     createSubscription({ paymentMethodId, cardDetail, values });
  //   }
  // }, [createSubscription, selectedPlan]);

  const onFinish = async (values) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // If a previous payment was attempted, get the latest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus',
    );
    setIsLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setIsLoading(false);
    } else {
      const paymentMethodId = paymentMethod.id;
      const cardDetail = paymentMethod.card;
      if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
        // Update the payment method and retry invoice payment
        // const invoiceId = localStorage.getItem('latestInvoiceId');
        // retryInvoiceWithNewPaymentMethod({
        //   customerId,
        //   paymentMethodId,
        //   invoiceId,
        //   priceId,
        // });
      } else {
        // Create the subscription
        createSubscription({
          paymentMethodId,
          cardDetail,
          values,
        });
      }
    }
  };

  return (
    <div className={styles.memberFormBox}>
      {
        // subscriptionPaymentComplete ? (
        //   <PaymentComplete history={history} membershipType={selectedPlan} />
        // ) :
        selectedPlan === 'yearly' || selectedPlan === 'monthly' ? (
          <MembershipFormPage
            onFinish={onFinish}
            plan={selectedPlan}
            isLoading={isLoading}
            price={price}
            isReferral={isReferral}
            balance={balance}
            error={error}
            setError={(val) => setError(val)}
            isNewUser={isNewUser}
          />
        ) : (
          <span className={styles.spinnerStyle2}>
            <BounceLoader color={green} loading={true} size={50} />
          </span>
        )
      }
    </div>
  );
};

export default MembershipForm;
