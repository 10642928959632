import React, { useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Tabs, Button, Modal, notification } from 'antd';
import { get } from 'lodash';
import queryString from 'query-string';
import { useSelector, shallowEqual } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { DeleteIcon } from '../../../components';
import { deleteUserPortfolio } from '../../../graphql';
import { green, red, errorHandler } from '../../../utils';
import HealthCheck from '../HealthCheck';
//import { PortfolioSnapShotType } from '../portfolio.types';
// import PortfolioSidebar from './PortfolioSidebar';
// import PortfolioSnapshot from './PortfolioSnapshot';
import PortfolioMovement from '../PortfolioMovement';
import StocksTable from '../StocksTable';
import AddNewPortfolio from './AddNewPortfolio';
import styles from './style.module.scss';
import UpdateRealStock from './UpdateRealStock';

const { TabPane } = Tabs;

interface Props extends RouteComponentProps<any> {
  history: Object;
  portfolioType: any;
  isLoading: boolean;
  setFetchPortfolioData: Function;
  fetchPortfolioData: any;
  portfolioQueryData: Object;
  afterDeleteHandler: Function;
}

const RealPortfolioPage: React.FC<Props> = ({
  history,
  portfolioType,
  isLoading,
  setFetchPortfolioData,
  fetchPortfolioData,
  portfolioQueryData,
  afterDeleteHandler,
}) => {
  const {
    portfolioMovementData,
    portfolioData,
    portfolioSnapshot,
    wallet,
    portfolioList,
  } = useSelector(
    ({ portfolio, user }) => ({
      portfolioMovementData: get(portfolio, `movement.${portfolioType}`, []),
      portfolioData: get(portfolio, `data.real`, []),
      portfolioSnapshot: get(portfolio, 'snapshot', {}),
      wallet: get(user, 'wallet', {}),
      portfolioList: get(portfolio, `portfolios`, []),
    }),
    shallowEqual,
  );
  const [openAddStock, setOpenAddStock] = useState(false);

  const portfolioName = get(portfolioQueryData, 'name', '');
  const [openEditPortfolioName, setOpenEditPortfolioName] =
    useState<boolean>(false);

  const marketValue = get(wallet, `${portfolioType}.currentValue`, 0);
  const investedValue = get(wallet, `${portfolioType}.investedValue`, 0);
  const yesterdayValue = get(wallet, `${portfolioType}.yd_value`, 0);

  const summary = get(portfolioData, `data`, []).filter(
    (i) => i.symbol === 'Total',
  );

  const handleTabChange = (key) => {
    const path =
      `/portfolio/Portfolio/${portfolioType}/` +
      queryString.stringify(
        {
          type: key,
          name: portfolioName,
        },
        {
          skipNull: true,
        },
      );
    history.push(path);
  };
  // const AvailableFund = get(wallet, `${portfolioType}.remainingBalance`, 0);
  let portfolioSnapshotData = get(
    portfolioSnapshot,
    `${portfolioType}.symbol`,
    [],
  );

  const handleUpdatePortfolioCancel = () => {
    setOpenAddStock(false);
    setFetchPortfolioData(new Date());
  };

  const onDelete = () =>
    Modal.confirm({
      title: 'Delete',
      content: (
        <p>
          Are you sure you want to delete portfolio{' '}
          <span style={{ color: green }}>{`${portfolioName}`}</span> ?{' '}
        </p>
      ),
      onOk: () =>
        handleDelete({
          name: portfolioName,
        }),
    });

  const handleDelete = (payload) => {
    const deletePortfolioName = portfolioList.find(
      (p) => p.name.trim() === payload.name.trim(),
    );
    payload.name = deletePortfolioName.name;
    deleteUserPortfolio(payload)
      .then((res) => {
        notification['success']({
          message: 'Portfolio Deleted',
        });

        history.push('/portfolio/Portfolio/real');
        afterDeleteHandler();
        // setFetchPortfolioData(new Date());
      })
      .catch(errorHandler);
  };

  const handleCancelEditPortfolioName = () => {
    setOpenEditPortfolioName(false);
    history.go(0);
    // setFetchPortfolioData(new Date());
  };

  return (
    <div>
      <div className={styles.PortfolioPageBox}>
        {isLoading && (
          <span className={styles.spinnerStyle}>
            <BounceLoader color={green} loading={true} size={50} />
          </span>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 36,
          }}
        >
          {get(portfolioData, 'data', []).length > 0 ? (
            <PortfolioMovement
              portfolioSnapshotData={portfolioSnapshotData}
              marketValue={marketValue}
              investedValue={investedValue}
              yesterdayValue={yesterdayValue}
              summary={summary}
              portfolioMovementData={portfolioMovementData}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 300,
                margin: 'auto',
                width: 900,
                padding: 20,
              }}
            >
              <Button
                size="large"
                onClick={() => {
                  setOpenAddStock(true);
                }}
                disabled={isLoading}
              >
                Add Stocks
              </Button>
            </div>
          )}
          {portfolioName && (
            <>
              <Button
                size="large"
                onClick={() => {
                  onDelete();
                }}
                shape="circle"
                disabled={isLoading}
              >
                <DeleteIcon style={{ color: red }} size={50} />
              </Button>
              <Button
                size="large"
                onClick={() => {
                  setOpenEditPortfolioName(true);
                }}
                shape="circle"
                disabled={isLoading}
                style={{ marginLeft: 20 }}
              >
                <EditOutlined style={{ color: green }} size={50} />
              </Button>
            </>
          )}
        </div>

        <Tabs
          defaultActiveKey={get(portfolioQueryData, 'type', 'stock')}
          className={styles.vCenterTabsNew}
          centered
          onChange={handleTabChange}
          id="stocks"
        >
          <TabPane tab="Stocks" key="stock">
            <div>
              <p
                style={{
                  color: 'var(--whitecolor)',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
              >
                <span style={{ color: 'red' }}>*</span>The Default Sort is based
                on the Todays returns column
              </p>
            </div>
            <StocksTable
              history={history}
              portfolioType={portfolioType}
              portfolioData={portfolioData}
              isLoading={isLoading}
              setFetchPortfolioData={setFetchPortfolioData}
              portfolioName={portfolioName}
              setOpenAddStock={setOpenAddStock}
            />
          </TabPane>
          <TabPane tab="Health Check" key="health_check">
            <HealthCheck
              history={history}
              portfolioType={portfolioType}
              fetchData={fetchPortfolioData}
              portfolioQueryData={portfolioQueryData}
              portfolioName={portfolioName}
            />
          </TabPane>
        </Tabs>
        {openAddStock && (
          <UpdateRealStock
            visible={openAddStock}
            handleCancel={handleUpdatePortfolioCancel}
            data={get(portfolioData, 'data', []).filter(
              (item) => item.symbol !== 'Total' && item.no_of_shares > 0,
            )}
            activeKey={'add'}
            portfolioName={portfolioName}
          />
        )}
        {openEditPortfolioName && (
          <AddNewPortfolio
            handleCancel={handleCancelEditPortfolioName}
            visible={openEditPortfolioName}
            isNew={false}
            portfolioList={portfolioList.filter(
              (p) => p.name === portfolioName,
            )}
            history={history}
            editing={true}
          />
        )}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default RealPortfolioPage;
