/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';

import { get } from 'lodash';
import BounceLoader from 'react-spinners/BounceLoader';

import { portFolioSearchByInst } from '../../../graphql';
import { green, errorHandler } from '../../../utils';
import HealthCheckTable from './HealthCheckTable';
import styles from './style.module.scss';

interface Props {
  history: Object;
  portfolioType: string;
  fetchData: any;
}

const HealthCheckByIns: React.FC<Props> = ({
  history,
  portfolioType,
  fetchData,
  portfolioName,
}) => {
  const arrangeColumns = (columns = {}) => {
    const formattedColumns = Object.keys(columns).map((col, index) => ({
      key: col,
      name: col,
      value: columns[col],
      visible: true,
      index: col === 'symbol' || col === 'symbols' ? 0 : index + 1,
    }));
    return formattedColumns;
  };

  const [tableData, setTableData] = useState(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [tableColumns, setTableColumns] = useState();
  const [noOfQtrs, setNoOfQtrs] = useState(2);
  const [fetchPortfolioData] = useState<Date>(new Date());
  useEffect(() => {
    setLoadingData(true);
    const apis = [
      portFolioSearchByInst({
        type: portfolioType,
        noOfQtrs: noOfQtrs,
        name: portfolioName,
      }),
    ];
    Promise.all(apis)
      .then((res) => {
        setTableData(res[0]);
        setTableColumns(arrangeColumns(get(res[0], 'columns')));
      })
      .catch(errorHandler)
      .finally(() => {
        setLoadingData(false);
      });
  }, [fetchPortfolioData, portfolioType, fetchData, noOfQtrs, portfolioName]);

  return (
    <div style={{ minHeight: 400 }} id="healthCheck">
      {loadingData ? (
        <div className={styles.loaderStyle}>
          <BounceLoader color={green} loading={loadingData} size={150} />
        </div>
      ) : (
        <>
          <HealthCheckTable
            data={tableData}
            selectedKey={1}
            isLoading={loadingData}
            history={history}
            setTableColumns={setTableColumns}
            tableColumns={tableColumns}
            showNoOfQtrs={true}
            onNoQtrsChange={setNoOfQtrs}
            noOfQtrs={noOfQtrs}
            healthCheckType="institutions"
          />
        </>
      )}
    </div>
  );
};

export default HealthCheckByIns;
