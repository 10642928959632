import initialState from '../initialState';
// Actions

const SET_RECENT_SEARCHES = 'search/SET_RECENT_SEARCHES';
const SET_SAVED_SEARCHES = 'search/SET_SAVED_SEARCHES';
const RESET_SEARCH = 'search/RESET_SEARCH';

// Reducer
export default function reducer(state = initialState.search, action = {}) {
  switch (action.type) {
    case SET_RECENT_SEARCHES:
      return {
        ...state,
        recentSearches: action.payload,
      };
    case SET_SAVED_SEARCHES:
      return {
        ...state,
        savedSearches: action.payload,
      };
    case RESET_SEARCH:
      return {};
    default:
      return state;
  }
}

// Action Creators

export function setRecentSearches(data) {
  return {
    type: SET_RECENT_SEARCHES,
    payload: data,
  };
}

export function setSavedSearches(data) {
  return {
    type: SET_SAVED_SEARCHES,
    payload: data,
  };
}

export function resetSearch() {
  return {
    type: RESET_SEARCH,
  };
}
