import React, { useEffect } from 'react';

const Section = ({
  activeCharacter,
  setActiveCharacter,
  pageHeight = 500,
  refs,
  id,
  name,
  children,
}) => {
  const observerMargin = Math.floor(pageHeight / 2);
  const activeClass = activeCharacter === id ? 'character-block--active' : '';
  useEffect(() => {
    const observerConfig = {
      rootMargin: `-${
        pageHeight % 2 === 0 ? observerMargin - 1 : observerMargin
      }px 0px -${observerMargin}px 0px`,
    };
    const handleIntersection = function (entries) {
      entries.forEach((entry) => {
        if (entry.target.id !== activeCharacter && entry.isIntersecting) {
          setActiveCharacter(entry.target.id);
        }
      });
    };
    const observer = new IntersectionObserver(
      handleIntersection,
      observerConfig,
    );
    observer.observe(refs[name].current);
    return () => observer.disconnect(); // Clenaup the observer if component unmount.
  }, [
    activeCharacter,
    setActiveCharacter,
    observerMargin,
    refs,
    pageHeight,
    name,
  ]);

  return (
    <div className={`character-block ${activeClass}`} ref={refs[name]} id={id}>
      {children}
    </div>
  );
};

export default Section;
