import React from 'react';

import { Table } from 'antd';
import BounceLoader from 'react-spinners/BounceLoader';

import { green } from '../../utils';
import { formatTotalRow } from '../../utils';
import styles from './style.module.scss';

interface Props {
  columns: {};
  scroll: {};
  dataSource: [];
  pagination: boolean;
  bordered: boolean;
  loading: boolean;
  id: string;
  rowClassName: string;
  className: string;
  title: string | any;
  onChange: Function;
  style: {};
  summary: [];
  showHeader?: boolean;
  source?: string;
}

const AntTable: React.FC<Props> = ({
  columns,
  scroll,
  dataSource,
  pagination,
  bordered,
  loading,
  id,
  rowClassName,
  className,
  title,
  onChange,
  style,
  summary,
  showHeader = true,
  source,
}) => {
  const tableLoading = {
    spinning: loading,
    indicator: (
      <span className={styles.spinnerStyle}>
        <BounceLoader color={green} loading={true} size={50} />
      </span>
    ),
  };
  // const handleChange = (pagination, filters, sorter, extra) => {
  //   const { field, order } = sorter;
  //
  //   onChange(pagination, filters, sorter, extra);
  // };
  return (
    <Table
      scroll={scroll}
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
      size="small"
      bordered={bordered}
      loading={tableLoading}
      id={id}
      rowClassName={rowClassName}
      className={className}
      title={title}
      showSorterTooltip={false}
      onChange={onChange}
      style={style}
      showHeader={showHeader}
      summary={
        !summary
          ? () => {}
          : () => {
              return (
                <Table.Summary.Row>
                  {formatTotalRow(summary[0] ? summary[0] : [], source).map(
                    (r, i) => (
                      <Table.Summary.Cell index={i} key={i}>
                        {r}
                      </Table.Summary.Cell>
                    ),
                  )}
                </Table.Summary.Row>
              );
            }
      }
    />
  );
};

export default AntTable;
