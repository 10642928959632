import React from 'react';

import { InputNumber } from 'antd';

import { formatter } from './FilterForm.helper';

const MinField = ({
  minFieldName,
  maxFieldname,
  Form,
  field,
  currentField,
  errorMessage,
  setCurrentField,
  showDollar,
  min,
}) => {
  return (
    <Form.Item
      name={field.name}
      label={field.label}
      style={{ marginBottom: 5 }}
      rules={[
        ({ getFieldValue }) => ({
          validator(rule, value) {
            if (getFieldValue(maxFieldname)) {
              if (value && getFieldValue(maxFieldname) <= value) {
                return currentField === rule.field
                  ? Promise.reject(errorMessage)
                  : Promise.resolve();
              } else {
                return Promise.resolve();
              }
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <InputNumber
        placeholder={field.placeholder}
        // type="number"
        style={{ width: '90%' }}
        min={min || min === 0 ? min : null}
        formatter={(n) => formatter(n, showDollar)}
        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        onFocus={() => setCurrentField(minFieldName)}
      />
    </Form.Item>
  );
};

export default MinField;
