import React from 'react';

import { Checkbox } from 'antd';
import { get } from 'lodash';
function DOWJonesFilter({
  className,
  filterOptions,
  onDOWJONESChange,
  onNasdaqChange,
  onSPChange,
}) {
  return (
    <div className={className}>
      <Checkbox
        checked={get(filterOptions, 'isDow', false)}
        onChange={onDOWJONESChange}
      >
        DOW JONES
      </Checkbox>
      <Checkbox
        checked={get(filterOptions, 'isNasdaq', false)}
        onChange={onNasdaqChange}
      >
        NASDAQ100
      </Checkbox>
      <Checkbox
        checked={get(filterOptions, 'isSP', false)}
        onChange={onSPChange}
      >
        S&P 500
      </Checkbox>
    </div>
  );
}
export default DOWJonesFilter;
