import React from 'react';

import { Form, Drawer, Button, Checkbox, Row, Col } from 'antd';

type TableColumn = {
  index: number;
  key: string;
  name: string;
  value: string;
  visible: boolean;
};
interface Props {
  visible: boolean;
  onCancel: Function;
  onColumnFilter: Function;
  tableColumns: Array<TableColumn>;
}

const ColumnVisibility: React.FC<Props> = ({
  visible,
  onCancel,
  onColumnFilter,
  tableColumns,
}) => {
  const columnsList = tableColumns.filter((col) => col.value);
  const onFinish = (values) => {
    const updatedColumn = tableColumns.map((col) => ({
      ...col,
      visible: values[col.key],
    }));
    onColumnFilter(updatedColumn);
    onCancel();
  };

  const getInitialValues = () => {
    const values = {};
    tableColumns.map((col) => (values[col.key] = col.visible));
    return values;
  };
  return (
    <Drawer
      title="Columns Visibility"
      placement="right"
      onClose={onCancel}
      visible={visible}
      getContainer={false}
      closable={true}
      style={{
        // top: 220,
        //height: 'calc(100% - 220px)',
        height: '100%',
        boxShadow:
          '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
      }}
      // mask={false}
      bodyStyle={{
        paddingTop: 5,
      }}
      //width={450}
      id="vColumnVisibilityBlock"
    >
      <Form
        name="column-form"
        onFinish={onFinish}
        wrapperCol={{ span: 24 }}
        initialValues={getInitialValues()}
        style={{ height: '100%' }}
      >
        <Row
          span={24}
          style={{
            maxHeight: 'calc(100% - 50px)',
            overflow: 'auto',
          }}
        >
          {columnsList.map((column) => (
            <Col key={column.key} span={12}>
              <Form.Item
                style={{ marginBottom: 10 }}
                name={column.name}
                valuePropName="checked"
              >
                <Checkbox
                  checked={column.visible}
                  disabled={
                    column.key === 'symbol' || column.key === 'cnst_count'
                  }
                >
                  {column.value}
                </Checkbox>
              </Form.Item>
            </Col>
          ))}
        </Row>
        <Row
          style={{
            height: '50px',
          }}
          span={24}
          justify="center"
        >
          <Form.Item colon={false} style={{ marginBottom: 10, marginTop: 10 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Drawer>
  );
};

export default ColumnVisibility;
