import React, { useState, useCallback, useEffect, useContext } from 'react';

// import { InfoCircleOutlined } from '@ant-design/icons';
import {
  FilterOutlined,
  SettingOutlined,
  ArrowLeftOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import {
  Row,
  Col,
  Breadcrumb,
  Select,
  Tag,
  Button,
  Input,
  Tabs,
  Checkbox,
  Modal,
  // Tooltip,
} from 'antd';
import { debounce, get } from 'lodash';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  Table,
  ColumnVisibility,
  Pagination,
  PieChart,
  MultiBarChart,
  DraggableModal,
  DOWJonesFilter,
} from '../../components';
import { getIIRQuarters } from '../../graphql';
import { setIIRQuarters } from '../../redux/reducers/report';
import { useTheme } from '../../ThemeContext';
import {
  green,
  white,
  black1,
  red,
  typeOptions,
  categoryOptions,
  formatColumn,
  formatTableData2,
  getLast5Dates,
  currencyFormat,
  getTimeRange,
  durationOptions,
  statusTxnOptions,
  getCurrentSECDateRange,
  formatNumber2,
} from '../../utils';
import { getLastQtrByIdx } from '../../utils';
import CommonHeader from './CommonHeader';
import SaveReport from './SaveReport';
import {
  getSearchReportTitle,
  NonClickAbleFilter,
  // MARK_MINERVINI_STRATEGY_CRITERIA,
} from './SearchReport.constants';
import { SearchReportContext } from './SearchReportContext';
import SearchReportFilterForm from './SearchReportFilterForm';
import styles from './style.module.scss';

const { Option } = Select;
const { TabPane } = Tabs;

const CommonSearchPage = () => {
  const {
    searchData = {},
    isLoading,
    changePageNo,
    changePageSize,
    pageSize,
    pageNo,
    onSort,
    reportInfo,
    onQuarterChange,
    quartersOptions,
    noOfQuartersForShares,
    onNoOfWeeklyTYDWeekChange,
    onNoOfWeekChange,
    onNoOfDaysChange,
    onTypeChange,
    onDurationChange,
    onStatusTxnChange,
    onCategoryChange,
    onSPChange,
    onNasdaqChange,
    onDOWJONESChange,
    onSoryByVolChange,
    selectedNoOfQtrs,
    noOfWeeks,
    noOfDays,
    currentSearchCriteria,
    reportType,
    reportSubType,
    history,
    handleISRTOIIR,
    reportTitle,
    fetchReportName,
    fetchingName,
    onFilter,
    filterOptions,
    queryData,
    sortDetail,

    tableColumns,
    onColumnFilter,
    changeSearchText,
    onNoQtrsChange,

    noOfWeeksList,
    noOfWeeklyYTDWeeksList,
    noOfDaysList,
    noOfDaysForVolumeList,
    selectedDateIndex,
    onDateSelect,
    error,
    searchText,
    trendingStockType,
    setTrendingStockType,
    setTopGainersType,
    topGainersType,
    selectedQuarter,
    setFilterOptions,
    isIRR,
    isIAR,
    setIsIAR,
    handleISRTOStatusReport,
    // uniqueVals,
  } = useContext(SearchReportContext);
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const [openFilterForm, setOpenFilterForm] = useState<boolean>(false);
  const [openColumnFilter, setOpenColumnFilter] = useState<boolean>(false);
  const [showAllocation, setShowAllocation] = useState<boolean>(false);
  const [showGraph, setShowGraph] = useState<boolean>(false);
  const [graphType, setGraphType] = useState('Inst Shares');
  const [showMinerviniText, setShowMinerviniText] = useState<boolean>(false);
  const [pageHeight, setPageHeight] = useState<number>();
  const reportCode =
    reportSubType === 'INSIDER_DAILY_REPORT'
      ? 'DIL'
      : reportSubType === 'DAILY_REPORT'
      ? 'SDY'
      : '';
  useEffect(() => {
    setPageHeight(window.innerHeight);
    window.addEventListener('resize', (e) => {
      setTimeout(() => {
        setPageHeight(window.innerHeight);
      }, 300);
    });
  }, []);
  const formatLargeNumbers = (n: number) => {
    if (n === 0) return '0';

    const sign = n < 0 ? '-' : '';
    const absNumber = Math.abs(n);

    const denominations = [
      { value: 1e12, symbol: 'T' }, // Trillions
      { value: 1e9, symbol: 'B' }, // Billions
      { value: 1e6, symbol: 'M' }, // Millions
      { value: 1e3, symbol: 'K' }, // Thousands
      { value: 1, symbol: '' }, // Base case (no abbreviation)
    ];
    if (absNumber < 1) {
      return `${sign}${Math.abs(n).toFixed(2)}`;
    }

    // Handle large numbers with abbreviations (K, M, B, T)
    for (const { value, symbol } of denominations) {
      if (absNumber >= value) {
        const formattedNumber = (absNumber / value).toFixed(1);
        return `${sign}${formattedNumber.replace(/\.0$/, '')}${symbol}`;
      }
    }

    return `${sign}${n}`;
  };
  const CustomCursor = (props) => {
    const { x, y, width, height } = props;

    return (
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill="green" // You can set this to any color
        opacity={0.2} // Set the desired opacity here (e.g., 0.2)
        stroke="none"
        pointerEvents="none" // Ensures it doesn't interfere with mouse events
      />
    );
  };
  const CustomLegend = (props) => {
    const { payload } = props;

    return (
      <div
        className="custom-legend"
        style={{ display: 'flex', fontSize: '14px', justifyContent: 'center' }}
      >
        {payload.map((entry, index) => (
          <div
            key={`legend-item-${index}`}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {/* Only render the legend text, without the icon */}
            <span style={{ color: '#8884d8', marginLeft: '8px' }}>
              {entry.value}
            </span>
          </div>
        ))}
      </div>
    );
  };
  function onGraphTypeChange(key) {
    setGraphType(key);
  }
  // const quarterStrings = {
  //   lastQtr1: getDisplayLastQtrByIdx(1),
  //   lastQtr2: getDisplayLastQtrByIdx(2),
  //   lastQtr3: getDisplayLastQtrByIdx(3),
  //   lastQtr4: getDisplayLastQtrByIdx(4),
  //   lastQtr5: getDisplayLastQtrByIdx(5),
  //   lastQtr6: getDisplayLastQtrByIdx(6),
  //   lastQtr7: getDisplayLastQtrByIdx(7),
  //   lastQtr8: getDisplayLastQtrByIdx(8),
  //   lastQtr9: getDisplayLastQtrByIdx(9),
  // };
  const getGraphData = (TData, columnData) => {
    console.log('TDATA here', TData);
    if (graphType === 'Inst Shares') {
      const transformData = (data) => {
        return data.map((group) => {
          const resultObj = {};
          group.forEach((item) => {
            switch (item.key) {
              case 'name':
                resultObj.name = item.value;
                break;
              case 'symbol':
                resultObj.symbol = item.value;
                break;
              case 'lastQtr1Shares':
                resultObj.lastQtr1Shares = item.value;
                break;
              case 'lastQtr2Shares':
                resultObj.lastQtr2Shares = item.value;
                break;
              // Add more cases if you want to extract more fields.
              default:
                break;
            }
          });
          return resultObj; // Return the transformed object
        });
      };
      const transformedData = transformData(TData);
      return transformedData;
    }
    if (graphType === 'Total Shares') {
      const transformData = (data) => {
        return data.map((group) => {
          const resultObj = {};
          group.forEach((item) => {
            switch (item.key) {
              case 'name':
                resultObj.name = item.value;
                break;
              case 'symbol':
                resultObj.symbol = item.value;
                break;
              case 'totalLastQtr1Shares':
                resultObj.totalLastQtr1Shares = item.value;
                break;
              case 'totalLastQtr2Shares':
                resultObj.totalLastQtr2Shares = item.value;
                break;
              // Add more cases if you want to extract more fields.
              default:
                break;
            }
          });
          return resultObj; // Return the transformed object
        });
      };
      const transformedData = transformData(TData);
      return transformedData;
    }
    if (graphType === 'Inst Counts') {
      const transformData = (data) => {
        return data.map((group) => {
          const resultObj = {};
          group.forEach((item) => {
            switch (item.key) {
              case 'name':
                resultObj.name = item.value;
                break;
              case 'symbol':
                resultObj.symbol = item.value;
                break;
              case 'lastQtr1Count':
                resultObj.lastQtr1Count = item.value;
                break;
              case 'lastQtr2Count':
                resultObj.lastQtr2Count = item.value;
                break;
              // Add more cases if you want to extract more fields.
              default:
                break;
            }
          });
          return resultObj; // Return the transformed object
        });
      };
      const transformedData = transformData(TData);
      return transformedData;
    }
    return {};
  };
  const getSingleGraphData = (TData) => {
    if (!['Inst Counts', 'Inst Shares', 'Total Shares'].includes(graphType)) {
      const transformData = (data) => {
        return data.map((group) => {
          const resultObj = {};
          group.forEach((item) => {
            switch (item.key) {
              case 'symbol':
                resultObj.symbol = item.value;
                break;
              case graphType:
                resultObj[graphType] = item.value;
                break;
              default:
                break;
            }
          });
          return resultObj; // Return the transformed object
        });
      };
      const transformedData = transformData(TData);
      const processedData = transformedData.map((item) => {
        const value = item[graphType];
        return {
          ...item,
          fill: value < 0 ? 'red' : '#8884d8', // Red if negative, blue if positive
        };
      });
      return processedData;
    }
    return {};
  };

  const CustomYAxisTick = ({ x, y, payload }: any) => {
    const number = payload.value;

    return (
      <text
        x={x}
        y={y}
        textAnchor="middle"
        fill="#1abd50"
        fontSize={14}
        fontWeight="bold"
      >
        {formatLargeNumbers(number)}
      </text>
    );
  };

  const {
    data = [],
    lastUpdatedTime = '',
    sectorAllocation = [],
    compareSectorAllocation = [],
  } = searchData;
  const filerTitle = searchData.reportType;
  let total = get(searchData, 'total', data ? data.length : 0);
  const {
    breadcrumb = [],
    showQuarterDropDown = false,
    showShareQuarter = false,
    showDates = false,
    showSP500 = false,
    // showNoOfWeeks = false,
  } = reportInfo;

  const onPaginationChange = (page, pageSize, redirect) => {
    if (!redirect) {
      changePageSize(pageSize);
      changePageNo(page);
    }
  };
  const handleSort = (sortBy: string, sortOrder: string, multi: string) => {
    onSort(sortBy, sortOrder, multi);
  };
  const onTableChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    let index = sortDetail.findIndex((d) => d.sortBy === field);
    if (index > -1) {
      let newOrder =
        order === 'ascend' ? 'ASC' : order === 'descend' ? 'DESC' : null;
      handleSort(field, newOrder, false);
    } else {
      if (order === 'ascend') {
        handleSort(field, 'ASC', false);
      } else if (order === 'descend') {
        handleSort(field, 'DESC', false);
      } else {
        handleSort(field, null, false);
      }
    }
  };
  const onChange = (event) => {
    const { value } = event.target;
    debouncedSave(value);
  };
  function getDisplayableQtr(qtrStr) {
    const qtrString = qtrStr.split('_');
    return qtrString[1] + '-' + qtrString[0];
  }
  const onMinerviniChange = (key) => {
    const filters = {
      type: reportType.toLowerCase(),
      subtype: key.toLowerCase(),
    };

    history.push({
      pathname:
        `/search/report/` +
        queryString.stringify(filters, {
          skipNull: true,
        }),

      state: { prevPage: 'search' },
    });
  };
  const debouncedSave = useCallback(
    debounce((value) => changeSearchText(value), 500),
  );
  // const handleFilterValueClick = (name, value) => {
  //   let updatedFilters = { ...filterOptions };
  //   if (name === 'symbols') {
  //     updatedFilters = {
  //       ...filterOptions,
  //       symbols: updatedFilters.symbols.filter((s) => s !== value),
  //     };
  //   } else {
  //     delete updatedFilters[name];
  //   }
  //   onFilter(updatedFilters);
  // };
  const mandatoryFilters = NonClickAbleFilter(reportSubType || reportType);
  const isFilterEmpty = Object.keys(filterOptions).filter((d) =>
    d === 'symbols' ||
    d === 'multiSector' ||
    d === 'maxPossibleGrowth' ||
    d === 'minPossibleGrowth' ||
    d === 'minRank' ||
    d === 'maxRank' ||
    d === 'week' ||
    d === 'minStartPrice' ||
    d === 'maxStartPrice' ||
    d === 'minEndPrice' ||
    d === 'maxEndPrice' ||
    d === 'excludeMultiSector' ||
    d === 'multiIndustry' ||
    d === 'multiStatus' ||
    d === 'multiMarketCap' ||
    d === 'institutions'
      ? filterOptions[d] && filterOptions[d].length === 0
        ? undefined
        : filterOptions[d]
      : d === 'fiveYrGrowth' ||
        d === 'instCount' ||
        d === 'oneYrGrowth' ||
        d === 'ytd' ||
        d === 'ownership' ||
        d === 'instSharesChange' ||
        d === 'instCountChange'
      ? filterOptions[d].max || filterOptions[d].min
        ? filterOptions[d].max || filterOptions[d].min
        : undefined
      : mandatoryFilters.includes(d)
      ? undefined
      : filterOptions[d],
  );

  const handleFilterValueClick = (name, value) => {
    let updatedFilters = { ...filterOptions };
    if (name === 'symbols') {
      updatedFilters = {
        ...filterOptions,
        symbols: Array.isArray(updatedFilters.symbols)
          ? updatedFilters.symbols.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'institutions') {
      updatedFilters = {
        ...filterOptions,
        institutions: Array.isArray(updatedFilters.institutions)
          ? updatedFilters.institutions.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'multiSector') {
      updatedFilters = {
        ...filterOptions,
        multiSector: Array.isArray(updatedFilters.multiSector)
          ? updatedFilters.multiSector.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxPossibleGrowth') {
      updatedFilters = {
        ...filterOptions,
        maxPossibleGrowth: Array.isArray(updatedFilters.maxPossibleGrowth)
          ? updatedFilters.maxPossibleGrowth.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minPossibleGrowth') {
      updatedFilters = {
        ...filterOptions,
        minPossibleGrowth: Array.isArray(updatedFilters.minPossibleGrowth)
          ? updatedFilters.minPossibleGrowth.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minRank') {
      updatedFilters = {
        ...filterOptions,
        minRank: Array.isArray(updatedFilters.minRank)
          ? updatedFilters.minRank.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxRank') {
      updatedFilters = {
        ...filterOptions,
        maxRank: Array.isArray(updatedFilters.maxRank)
          ? updatedFilters.maxRank.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minPrevQInstCount') {
      updatedFilters = {
        ...filterOptions,
        minPrevQInstCount: Array.isArray(updatedFilters.minPrevQInstCount)
          ? updatedFilters.minPrevQInstCount.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxPrevQInstCount') {
      updatedFilters = {
        ...filterOptions,
        maxPrevQInstCount: Array.isArray(updatedFilters.maxPrevQInstCount)
          ? updatedFilters.maxPrevQInstCount.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minAum') {
      updatedFilters = {
        ...filterOptions,
        minAum: Array.isArray(updatedFilters.minAum)
          ? updatedFilters.minAum.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxAum') {
      updatedFilters = {
        ...filterOptions,
        maxAum: Array.isArray(updatedFilters.maxAum)
          ? updatedFilters.maxAum.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minPosReturnsPerc') {
      updatedFilters = {
        ...filterOptions,
        minPosReturnsPerc: Array.isArray(updatedFilters.minPosReturnsPerc)
          ? updatedFilters.minPosReturnsPerc.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxPosReturnsPerc') {
      updatedFilters = {
        ...filterOptions,
        maxPosReturnsPerc: Array.isArray(updatedFilters.maxPosReturnsPerc)
          ? updatedFilters.maxPosReturnsPerc.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minNegReturnsPerc') {
      updatedFilters = {
        ...filterOptions,
        minNegReturnsPerc: Array.isArray(updatedFilters.minNegReturnsPerc)
          ? updatedFilters.minNegReturnsPerc.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxNegReturnsPerc') {
      updatedFilters = {
        ...filterOptions,
        maxNegReturnsPerc: Array.isArray(updatedFilters.maxNegReturnsPerc)
          ? updatedFilters.maxNegReturnsPerc.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minNegReturnsStockCount') {
      updatedFilters = {
        ...filterOptions,
        minNegReturnsStockCount: Array.isArray(
          updatedFilters.minNegReturnsStockCount,
        )
          ? updatedFilters.minNegReturnsStockCount.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxNegReturnsStockCount') {
      updatedFilters = {
        ...filterOptions,
        maxNegReturnsStockCount: Array.isArray(
          updatedFilters.maxNegReturnsStockCount,
        )
          ? updatedFilters.maxNegReturnsStockCount.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minPosReturnsStockCount') {
      updatedFilters = {
        ...filterOptions,
        minPosReturnsStockCount: Array.isArray(
          updatedFilters.minPosReturnsStockCount,
        )
          ? updatedFilters.minPosReturnsStockCount.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxPosReturnsStockCount') {
      updatedFilters = {
        ...filterOptions,
        maxPosReturnsStockCount: Array.isArray(
          updatedFilters.maxPosReturnsStockCount,
        )
          ? updatedFilters.maxPosReturnsStockCount.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minTotalCount') {
      updatedFilters = {
        ...filterOptions,
        minTotalCount: Array.isArray(updatedFilters.minTotalCount)
          ? updatedFilters.minTotalCount.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxTotalCount') {
      updatedFilters = {
        ...filterOptions,
        maxTotalCount: Array.isArray(updatedFilters.maxTotalCount)
          ? updatedFilters.maxTotalCount.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minPrevAum') {
      updatedFilters = {
        ...filterOptions,
        minPrevAum: Array.isArray(updatedFilters.minPrevAum)
          ? updatedFilters.minPrevAum.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxPrevAum') {
      updatedFilters = {
        ...filterOptions,
        maxPrevAum: Array.isArray(updatedFilters.maxPrevAum)
          ? updatedFilters.maxPrevAum.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'hedgeCount') {
      updatedFilters = {
        ...filterOptions,
        hedgeCount: Array.isArray(updatedFilters.hedgeCount)
          ? updatedFilters.hedgeCount.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minMarketCap') {
      updatedFilters = {
        ...filterOptions,
        minMarketCap: Array.isArray(updatedFilters.minMarketCap)
          ? updatedFilters.minMarketCap.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxMarketCap') {
      updatedFilters = {
        ...filterOptions,
        maxMarketCap: Array.isArray(updatedFilters.maxMarketCap)
          ? updatedFilters.maxMarketCap.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'week') {
      updatedFilters = {
        ...filterOptions,
        week: Array.isArray(updatedFilters.week)
          ? updatedFilters.week.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minStartPrice') {
      updatedFilters = {
        ...filterOptions,
        minStartPrice: Array.isArray(updatedFilters.minStartPrice)
          ? updatedFilters.minStartPrice.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxStartPrice') {
      updatedFilters = {
        ...filterOptions,
        maxStartPrice: Array.isArray(updatedFilters.maxStartPrice)
          ? updatedFilters.maxStartPrice.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minEndPrice') {
      updatedFilters = {
        ...filterOptions,
        minEndPrice: Array.isArray(updatedFilters.minEndPrice)
          ? updatedFilters.minEndPrice.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxEndPrice') {
      updatedFilters = {
        ...filterOptions,
        maxEndPrice: Array.isArray(updatedFilters.maxEndPrice)
          ? updatedFilters.maxEndPrice.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'excludeMultiSector') {
      updatedFilters = {
        ...filterOptions,
        excludeMultiSector: Array.isArray(updatedFilters.excludeMultiSector)
          ? updatedFilters.excludeMultiSector.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'multiIndustry') {
      updatedFilters = {
        ...filterOptions,
        multiIndustry: Array.isArray(updatedFilters.multiIndustry)
          ? updatedFilters.multiIndustry.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'multiStatus') {
      updatedFilters = {
        ...filterOptions,
        multiStatus: Array.isArray(
          updatedFilters.multiStatus || queryData.multiStatus,
        )
          ? (updatedFilters.multiStatus || queryData.multiStatus).filter(
              (s) => s !== value,
            )
          : undefined,
      };
    } else if (name === 'country') {
      updatedFilters = {
        ...filterOptions,
        country: Array.isArray(updatedFilters.country)
          ? updatedFilters.country.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'multiMarketCap') {
      updatedFilters = {
        ...filterOptions,
        multiMarketCap: Array.isArray(updatedFilters.multiMarketCap)
          ? updatedFilters.multiMarketCap.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minYTD') {
      delete updatedFilters.ytd.min;
    } else if (name === 'maxYTD') {
      delete updatedFilters.ytd.max;
    } else if (name === 'min1Yr') {
      delete updatedFilters.oneYrGrowth.min;
    } else if (name === 'max1Yr') {
      delete updatedFilters.oneYrGrowth.max;
    } else if (name === 'minOwnership') {
      delete updatedFilters.ownership.min;
    } else if (name === 'min5Yr') {
      delete updatedFilters.fiveYrGrowth.min;
    } else if (name === 'minInstCount') {
      delete updatedFilters.instCount.min;
    } else if (name === 'maxInstCount') {
      delete updatedFilters.instCount.max;
    } else {
      delete updatedFilters[name];
    }
    onFilter(updatedFilters);
  };

  const y = pageHeight - 290;
  const institutionsName = get(queryData, 'institutionsName', []);
  const compareIns = Array.isArray(institutionsName)
    ? institutionsName.map((ins, i) => ({
        name: ins,
        cik: get(queryData, 'institutions', [])[i],
      }))
    : [];
  const handleCompareInsClick = (i) => {
    const newInsCiks = [];
    const newInsNames = [];
    compareIns.forEach((ins) => {
      if (ins.cik !== i.cik) {
        newInsCiks.push(ins.cik);
        newInsNames.push(ins.name);
      }
    });
    let newFilters = {
      type: reportType.toLowerCase(),
      subtype: reportSubType.toLowerCase(),
      institutions: newInsCiks,
      institutionsName: newInsNames,
    };

    history.push({
      pathname:
        `/search/report/` +
        queryString.stringify(newFilters, {
          skipNull: true,
        }),
      // state: { prevPage: 'search' },
    });
  };

  const ISRTOIIIRHandler = (iirData) =>
    Modal.confirm({
      title: 'Open Individual Institution Report',
      content: (
        <p>{`Are you sure you want to open ${iirData.institutionsName[0]} Report ?`}</p>
      ),
      onOk: () => handleISRTOIIRRedirect(iirData),
    });
  const ISRTOStatusReport = (statusData) =>
    Modal.confirm({
      title: 'Open Status Report',
      content: (
        <p>{`Are you sure you want to open ${statusData.multistatus} Report ?`}</p>
      ),
      onOk: () => handleStatusReportRedirect(statusData),
    });
  const goBack = () => {
    // console.log(history);
    // history.goBack();
    if (isIAR) {
      onQuarterChange(quartersOptions[0].value);
      setFilterOptions({});
      onSort(null, null, false);
      onPaginationChange(1, 20, true);
      changeSearchText('');
      onNoQtrsChange(2);
      setIsIAR(false);
      const IRRfilters = {
        type: 'INSTITUTION'.toLowerCase(),
        subtype: 'INSTITUTION_ALLOCATION_REPORT'.toLowerCase(),
        quarter: quartersOptions[0].value,
        noOfQuartersForShares: 2,
      };
      history.push(`/search/report/` + queryString.stringify(IRRfilters));
    } else if (isIRR) {
      onQuarterChange(quartersOptions[0].value);
      setFilterOptions({});
      onSort(null, null, false);
      onPaginationChange(1, 20, true);
      changeSearchText('');
      onNoQtrsChange(2);
      const IRRfilters = {
        type: 'INSTITUTION'.toLowerCase(),
        subtype: 'INSTITUTION_PERFORMANCE_REPORT'.toLowerCase(),
        quarter: quartersOptions[0].value,
        noOfQuartersForShares: 2,
      };
      history.push(`/search/report/` + queryString.stringify(IRRfilters));
    } else {
      if (reportSubType === 'INSTITUTION_ALLOCATION_REPORT') {
        history.push(`/search/`);
      } else if (reportSubType === 'INDIVIDUAL_STATUS_REPORT') {
        setFilterOptions({});
        history.goBack();
      } else {
        history.goBack();
      }
    }
  };

  const handleStatusReportRedirect = (statusData) => {
    setFilterOptions({});
    handleISRTOStatusReport();
    setTimeout(() => {
      history.push(`/search/report/` + queryString.stringify(statusData));
    }, 1000);
  };
  const handleISRTOIIRRedirect = (iirData) =>
    getIIRQuarters({
      context: iirData.subtype.toUpperCase(),
      ciks: iirData.institutions,
    }).then((res) => {
      dispatch(setIIRQuarters(iirData.institutions, res));
      setFilterOptions({});
      handleISRTOIIR();
      setTimeout(() => {
        history.push(`/search/report/` + queryString.stringify(iirData));
      }, 1000);
    });
  const handleReset = () => {
    onFilter({ noOfWeeks: null, noOfDays: null }, [], false, true);
    window.location.reload();
  };
  let note = {
    TRENDING_STOCK: ' The Default Sort is based on the Returns %  column',
    PRICE_HISTORY: ' The Default Sort is based on the Date  column',
    TS_WEEKLY_REPORT: ' The Default Sort is based on the Returns % column',
    INDIVIDUAL_INSTITUTION_REPORT:
      ' The Default Sort is based on the Market Value',
    VERIFY_SYMBOLS_VS_INSTITUTIONS: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    MOVING_AVERAGES: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    DOW_DAILY_PRICE_UPTREND: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    DOW_DAILY_PRICE_DOWNTREND: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    DOW_DAILY_PRICE_VOLUME_UPTREND: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    DOW_DAILY_PRICE_VOLUME_DOWNTREND: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    DOW_THEORY_UPTREND: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    DOW_THEORY_DOWNTREND: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    TOP_TWENTY_FIVE_SYMBOLS: ` The Default Sort is based on the Approx. Trxn Value`,
    ALL_SYMBOLS: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    C52WKHL: ` The Default Sort is based on the Times`,
    COMPARE_INSTITUTIONS: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    VERIFY_INSTITUTIONS_VS_SYMBOLS: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    INSTITUTION_ALLOCATION_REPORT: ` The Default Sort is based on the Total Stocks`,
    INSTITUTION_PERFORMANCE_REPORT: ` ${
      filterOptions.status === 'INACTIVE'
        ? 'The Default Sort is based on the Negative returns%'
        : 'The Default Sort is based on the  Positive returns%'
    } `,
    INCREASED: ` ${
      filterOptions.criterion === 'SHARES'
        ? ''
        : ` The Default Sort is based on the ${getDisplayableQtr(
            selectedQuarter,
          )} Inst Counts`
    } `,
    DECREASED: ` ${
      filterOptions.criterion === 'SHARES'
        ? ''
        : ` The Default Sort is based on the ${getDisplayableQtr(
            selectedQuarter,
          )} Inst Counts`
    } `,
    NO_CHANGE: ` ${
      filterOptions.criterion === 'SHARES'
        ? ''
        : ` The Default Sort is based on the ${getDisplayableQtr(
            selectedQuarter,
          )} Inst Counts`
    } `,
    NEWLY_BOUGHT: ` ${
      filterOptions.criterion === 'SHARES'
        ? ''
        : ` The Default Sort is based on the ${getDisplayableQtr(
            selectedQuarter,
          )} Inst Counts`
    } `,
    DAILY_REPORT: 'The Default Sort is based on the Sec Reported date & time',
    INSIDER_DAILY_REPORT:
      'The Default Sort is based on the Sec Reported date & time',
    DETAIL_SUMMARY_REPORT:
      'The Default Sort is based on the Sec Reported date & time',
    INSIDER_DETAIL_SUMMARY_REPORT:
      'The Default Sort is based on the Sec Reported date & time',
    BY_APPROX_TXN_VAL: 'The Default Sort is based on the Approx. Trxn Value',
    SYMBOL_SUMMARY:
      reportType === 'SEC_13_DG'
        ? 'The Default Sort is based on the SC 13D/G count'
        : reportType === 'MUTUAL_FUNDS'
        ? 'The Default Sort is based on the Shares value'
        : null,
    BY_FUND_SYMBOL: 'The Default Sort is based on the Shares value',
    BY_FUND_NAME: 'The Default Sort is based on the Shares value',
    INSIDER_SYMBOL_SUMMARY:
      'The Default Sort is based on the Sec Insiders count',
    DAILY_REPORT_MUTUAL_FUNDS: 'The Default Sort is based on the Shares value',
    SYMBOL_SUMMARY_MUTUAL_FUNDS:
      'The Default Sort is based on the Shares value',
    ALL_INSTITUTIONS: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    OPTION_ALL_INSTITUTION: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    OPTION_BY_INSTITUTION: `The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    SECTOR_TRENDING: `The Default Sort is based on the %Change`,
    TOP_10_HEDGE: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    ALLOCATIONS: ` The Default Sort is based on the Sector column`,
    CRITERIA_MET: ` The Default Sort is based on the RSI column`,
    EXIT_REPORT: ` The Default Sort is based on the RSI column`,
    PRICE: ` The Default Sort is based on the ${getDisplayableQtr(
      selectedQuarter,
    )} Inst Counts`,
    // SHARES
  };
  const showDetail = (rec, type) => {
    history.push(`/daytrend/${rec['symbol']}`);
  };
  return (
    <div className={styles.vReportsNewPage1}>
      <div className={styles.vGoBackSaveBar}>
        <p
          onClick={() =>
            get(history, 'location.state.prevPage', '') === 'search'
              ? history.push({
                  pathname: '/search',
                  state: {
                    type: reportType,
                    subType: reportSubType,
                    prevPage: 'searchReport',
                  },
                })
              : goBack()
          }
          className={styles.vGoBackBtnBlock}
        >
          {history.length > 2 && (
            <span className={styles.vGoBackBtn}>
              <ArrowLeftOutlined /> Go Back
            </span>
          )}
        </p>
        {reportType === 'MARK_MINERVINI_STRATEGY' && (
          <h2
            style={{
              fontSize: '18px',
              width: '800px',
              color: '#2fc058',
              textAlign: 'center',
            }}
          >
            Learning from one of America’s best investors: The Mark Minervini
            Strategy
          </h2>
        )}

        {!isLoading && (
          <div className={styles.SaveReportBoxNew2}>
            <SaveReport
              searchCriteria={currentSearchCriteria}
              reportType={reportType}
              reportSubType={reportSubType}
              reportTitle={reportTitle}
              fetchReportName={fetchReportName}
              fetchingName={fetchingName}
            />
          </div>
        )}
        {isLoading && <div className={styles.SaveReportBoxNew2}></div>}
      </div>
      <div className={styles.symbolSearchContentBox}>
        <Breadcrumb>
          {breadcrumb.map((item) => (
            <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className={styles.titleContainer}>
          <div className={styles.titleContent}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <h1 hidden={reportType === 'MARK_MINERVINI_STRATEGY'}>
                  {getSearchReportTitle(
                    reportSubType || reportType,
                    get(queryData, 'institutionsName', ''),
                    get(queryData, 'filerName', ''),
                    get(queryData, 'symbols', ''),
                    get(
                      filterOptions,
                      'startDate',
                      reportSubType === 'TOP_GAINERS'
                        ? moment(getTimeRange('1D', true).start)
                        : moment().subtract(1, 'year'),
                    ),
                    lastUpdatedTime,
                    get(queryData, 'criterion', ''),
                    reportSubType === 'TS_WEEKLY_REPORT'
                      ? data.length
                        ? data[0][3].value
                        : undefined
                      : get(
                          filterOptions,
                          'endDate',
                          reportSubType === 'TOP_GAINERS'
                            ? moment(getTimeRange('1D', true).end)
                            : moment(),
                        ),
                    get(searchData, 'reportType', ''),
                    reportType,
                    topGainersType,
                    get(queryData, 'fundSymbols', ''),
                    filerTitle,
                    get(filterOptions, 'status', 'INCREASED'),
                    get(queryData, 'multiStatus', 'INCREASED'),
                    get(searchData, 'total', null),
                  ).toUpperCase()}
                </h1>
                <p style={{ color: 'var(--whitecolor)', fontSize: '12px' }}>
                  <span style={{ color: 'red' }}>
                    {note[reportSubType || reportType]?.length > 2 ? '*' : ''}
                  </span>{' '}
                  {note[reportSubType || reportType]}
                </p>
                <br />
                <span
                  hidden={
                    (trendingStockType !== 'GAINERS' &&
                      trendingStockType !== 'DECLINERS') ||
                    reportSubType !== 'TRENDING_STOCK'
                  }
                >
                  {' '}
                  Note: Excluded Symbols Below $1 Current Price
                </span>
                {/*{reportType === 'MARK_MINERVINI_STRATEGY' && (*/}
                {/*  <Tooltip*/}
                {/*    placement="top"*/}
                {/*    title={MARK_MINERVINI_STRATEGY_CRITERIA}*/}
                {/*    color="var(--headerbgm)"*/}
                {/*    overlayInnerStyle={{*/}
                {/*      width: 500,*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <span*/}
                {/*      style={{*/}
                {/*        cursor: 'pointer',*/}
                {/*        fontWeight: 'bold',*/}
                {/*        margin: 10,*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <InfoCircleOutlined style={{ color: green }} />*/}
                {/*    </span>*/}
                {/*  </Tooltip>*/}
                {/*)}*/}
              </div>
              {reportType === 'MARK_MINERVINI_STRATEGY' && (
                <>
                  <button
                    style={{
                      backgroundColor: '#414a4c',
                      width: '200px',
                      margin: 'auto',
                    }}
                    onClick={() => setShowMinerviniText(!showMinerviniText)}
                  >
                    {showMinerviniText
                      ? 'Click here to Hide Text'
                      : 'Click here to learn more'}
                  </button>
                  <div
                    hidden={!showMinerviniText}
                    style={{
                      textAlign: 'left',
                      width: 800,
                      fontSize: '15px',
                      color: 'var(--whitecolor)',
                    }}
                  >
                    <br />
                    <span style={{ fontSize: '15px' }}>
                      Have you ever been fearful of losing money in the stock
                      market? Well, we can help you overcome that with proven
                      tactics from one of America’s best investors. Mark
                      Minervini is one of the most successful investors of the
                      last 30 years, consistently outperforms the stock market,
                      and is a two time winner of the U.S. Investing
                      Championship.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
                      Here are his criteria for investing in a stock:
                    </span>{' '}
                    <br />
                    <br />
                    <ul>
                      <li style={{ fontSize: '15px', height: '1px' }}>
                        The current stock price is trading above the 50 Day
                        Moving Average (DMA), as well as the 150 DMA and 200 DMA
                      </li>
                      <br />{' '}
                      <li style={{ fontSize: '15px', height: '1px' }}>
                        The 50 DMA is above the 150 DMA, and the 150 DMA is
                        above the 200 DMA
                      </li>
                      <br />
                      <li style={{ fontSize: '15px', height: '1px' }}>
                        The 200 DMA is trending up for at least 1 month (22
                        active trading days)
                      </li>
                      <br />
                      <li style={{ fontSize: '15px', height: '25px' }}>
                        {' '}
                        The current stock price is 25% above the 52 week low
                        (the lowest price the stock has been in the past year)
                        and within 25% of the 52 week high (the highest price
                        the stock has been in the past year
                      </li>
                      <br />
                      <li style={{ fontSize: '15px', height: '1px' }}>
                        {' '}
                        The stock has a Relative Strength Index (RSI) of 70
                      </li>
                    </ul>
                    <br />
                    <p style={{ fontSize: '15px' }}>
                      These criteria all measure the strength and upward trend
                      of a stock. Below, you’ll find the stocks that meet these
                      criteria so you can determine if they’re a good investment
                      for you.
                    </p>
                    <br />
                    <div style={{ fontSize: '15px' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '15px' }}>
                        Note:
                      </span>{' '}
                      <br />
                      <br /> * Daily moving average measures the price of a
                      stock over a given period of time.
                      <br /> * Relative strength index measures the current
                      strength of a stock based on its recent closing prices
                      compared to its historical averages.
                      <br />* The percentages in the report are calculated
                      relative to the current price.
                      <br />* The sorting order in the below columns is
                      determined by the values presented as percentages.
                    </div>
                  </div>
                </>
              )}
              {reportType === 'THINKSABIO_DIAMONDS' && (
                <>
                  <div
                    style={{
                      textAlign: 'left',
                      width: 800,
                      fontSize: '15px',
                      color: 'var(--whitecolor)',
                    }}
                  >
                    <div style={{ fontSize: '15px' }}>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '13px',
                          // marginLeft: '300px',
                          textAlign: 'center',
                          marginBottom: '20px',
                          marginTop: '-10px',
                        }}
                      >
                        {reportSubType === 'THINKSABIO_DIAMONDS'
                          ? 'Quarterly % Shares Changes Surpassing the 50% Mark'
                          : reportSubType === 'THINKSABIO_DIAMONDS_PV'
                          ? 'Unveiling the Quarterly Price and Volume Winners from Previous to latest quarter in our report'
                          : 'Unmasking the Surge in Counts, Shares, Approx. Invested value,Price, and Volume (CSAPV) for Selected Stocks'}
                      </p>{' '}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          color: 'white',
                        }}
                      >
                        <button
                          style={{
                            backgroundColor: '#414a4c',
                            alignItems: 'center',
                            width: '200px',
                            margin: 'auto',
                            marginBottom: '20px',
                          }}
                          onClick={() =>
                            setShowMinerviniText(!showMinerviniText)
                          }
                        >
                          {showMinerviniText
                            ? 'Click here to Hide Text'
                            : 'Click here to learn more'}
                        </button>
                      </div>
                      {reportSubType === 'THINKSABIO_DIAMONDS' ? (
                        <ul hidden={!showMinerviniText}>
                          <li>
                            Have you ever felt unsure of which stocks to invest
                            in ? If so, one way to find the right stock is to
                            look at which stocks are seeing more investment
                            among institutions and thier smart money.
                            <br />
                          </li>
                          <li>
                            The ThinkSabio Diamond report shows the stocks that
                            are seeing a large increase in investment (Atleast
                            50% change in shares bought by institutions compared
                            to the previous quarter), as well as other useful
                            information (like pricing). This report gives you a
                            wide range of data to help you pick the right crown
                            jewel for your stock portfolio!
                          </li>
                          <li>
                            Applied specific conditions to avoid stocks with low
                            institution count and stocks heavily influenced by
                            one or two institutions our dedication is to
                            construct a well-balanced stock report
                          </li>
                          <li>
                            This Report is sorted based on "Approx.{' '}
                            {getDisplayableQtr(selectedQuarter)} invested value"
                            initially
                          </li>
                          <li>
                            Modify the %shares change filtering to suit your
                            individual preferences.
                          </li>
                        </ul>
                      ) : reportSubType === 'THINKSABIO_DIAMONDS_PV' ? (
                        <ul hidden={!showMinerviniText}>
                          <li>
                            Have you ever felt unsure of which stocks to invest
                            in? If so, one way to find the right stocks is to
                            look at which stocks are seeing more investment
                            among their institutions and their smart money.
                            <br />
                          </li>
                          <li>
                            Thinksabio Diamonds report is A Comprehensive Review
                            of Quarterly Price and Volume Upswings in the Stock
                            Market.
                          </li>
                          <li>
                            Applied specific conditions to avoid stocks with low
                            institution count and stocks heavily influenced by
                            one or two institutions our dedication is to
                            construct a well-balanced stock report
                          </li>
                          <li>
                            This Report is sorted based on " % Avg. price change
                            ({getLastQtrByIdx(2).split('_')[1]}-
                            {getLastQtrByIdx(1).split('_')[1]})" initially
                          </li>
                        </ul>
                      ) : (
                        <ul hidden={!showMinerviniText}>
                          <li>
                            Have you ever felt unsure of which stocks to invest
                            in? If so, one way to find the right stocks is to
                            look at which stocks are seeing more investment
                            among their institutions and their smart money.
                            <br />
                          </li>
                          <li>
                            Thinksabio Diamonds report is A Comprehensive Review
                            of Quarterly Counts, Shares, Approx. Invested value,
                            Price and Volume Upswings in the Stock Market.
                          </li>
                          <li>
                            Applied specific conditions to avoid stocks with low
                            institution count and stocks heavily influenced by
                            one or two institutions our dedication is to
                            construct a well-balanced stock report
                          </li>
                          <li>
                            This Report is sorted based on "Approx.{' '}
                            {getDisplayableQtr(selectedQuarter)} invested value"
                            initially
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </>
              )}
              {reportSubType === 'TS_WEEKLY_REPORT' && (
                <div
                  style={{
                    textAlign: 'left',
                    marginLeft: '58px',
                    color: 'var(--whitecolor)',
                  }}
                >
                  <span style={{ fontSize: '12px' }}>
                    * Consistenly Going Up Since Atleast 2 Weeks
                    <br />
                  </span>
                  <span style={{ fontSize: '12px' }}>* End price &gt; $5</span>
                  {/*<br />*/}
                  {/*<span>*/}
                  {/*  *This Report is sorted based on % returns initially*/}
                  {/*</span>*/}
                </div>
              )}
              {reportSubType === 'C52WKHL' && (
                <span style={{ fontSize: '12px', marginTop: '-20px' }}>
                  Stocks that have doubled from 52-Week Low or increased Atleast
                  by 50%
                </span>
              )}
            </div>
            {(reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT' ||
              reportSubType === 'ALLOCATIONS') && (
              <span
                className={styles.pieChart}
                onClick={() => setShowAllocation(true)}
              >
                <PieChart
                  height={100}
                  width={100}
                  radius={20}
                  dataKey={
                    reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT'
                      ? 'allocation'
                      : 'allocationPercentage'
                  }
                  data={
                    sectorAllocation && sectorAllocation.length
                      ? sectorAllocation
                      : []
                  }
                />
              </span>
            )}
            {!isLoading && reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT' && (
              <span
                onClick={() => setShowGraph(true)}
                style={{ cursor: 'pointer', fontWeight: 'bold' }}
              >
                <BarChartOutlined
                  style={{ color: '#8884d8', fontSize: '36px' }}
                />
              </span>
            )}
            {reportSubType === 'COMPARE_INSTITUTIONS' && (
              <span
                className={styles.pieChart}
                onClick={() => setShowAllocation(true)}
              >
                <MultiBarChart
                  insData={
                    compareSectorAllocation && compareSectorAllocation.length
                      ? compareSectorAllocation
                      : []
                  }
                  height={60}
                  width={100}
                  showLegend={false}
                />
              </span>
            )}
          </div>
          {(reportSubType === 'BY_APPROX_TXN_VAL' ||
            reportSubType === 'INDIVIDUAL_STATUS_REPORT') && (
            <>
              <span
                hidden={reportSubType === 'INDIVIDUAL_STATUS_REPORT'}
                style={{
                  fontSize: '15px',
                  color: 'var(--whitecolor)',
                }}
              >
                ( Period :{' '}
                {moment(getCurrentSECDateRange().start).format('MMMM DD, YYYY')}{' '}
                to{' '}
                {moment(lastUpdatedTime)
                  .tz('America/New_York')
                  .format('MMMM DD, YYYY')}{' '}
                )
              </span>
            </>
          )}
        </div>

        {isFilterEmpty.length > 0 && (
          <div className={styles.filterValueBox}>
            <CommonHeader
              handleClick={handleFilterValueClick}
              handleReset={handleReset}
            />
          </div>
        )}
        {reportSubType === 'COMPARE_INSTITUTIONS' &&
          (compareIns || []).map((ins) => (
            <Tag
              key={ins.cik}
              closable={compareIns.length > 2}
              onClose={() => handleCompareInsClick(ins)}
              color={'success'}
            >
              {ins.name}
            </Tag>
          ))}
      </div>
      <Row span={24} justify="space-between">
        <Row
          span={16}
          justify="space-between"
          style={{ paddingTop: 10 }}
          className={styles.vCommonBLock}
        >
          {reportSubType !== 'ALLOCATIONS' &&
            reportSubType !== 'TOP_TWENTY_FIVE_SYMBOLS' &&
            reportSubType !== 'INSTITUTION_ALLOCATION_REPORT' &&
            reportSubType !== 'INDIVIDUAL_STATUS_REPORT' &&
            reportSubType !== 'SYMBOL_SUMMARY_MUTUAL_FUNDS' && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <Input
                  placeholder="search..."
                  onChange={onChange}
                  className={styles.VdailySearch}
                  allowClear
                />
              </div>
            )}
          {showQuarterDropDown && (
            <div
              className={styles.reportOptionBlock}
              style={{ marginRight: 20 }}
            >
              <label className={styles.vlabelfull}>{`Quarter: `}</label>
              <Select
                defaultValue={quartersOptions[0] ? quartersOptions[0].name : ''}
                value={
                  selectedQuarter ||
                  (quartersOptions[0] ? quartersOptions[0].name : '')
                }
                style={{ width: 150 }}
                onChange={(value) => onQuarterChange(value)}
                placeholder="Select Quarter"
                disabled={isLoading}
                className={styles.vSelectSearchnew}
              >
                {quartersOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </div>
          )}
        </Row>
        <Row span={8} justify="end" style={{ paddingTop: 10 }}>
          {showDates && (
            <div className={styles.datesArray} id="reportDate">
              {getLast5Dates(reportCode).map((dateIndex) => (
                <div
                  key={dateIndex}
                  className={styles.dateBox}
                  onClick={() => onDateSelect(dateIndex)}
                  style={{
                    backgroundColor:
                      selectedDateIndex === dateIndex ? green : white,
                    color: selectedDateIndex === dateIndex ? white : black1,
                    borderWidth: selectedDateIndex === dateIndex ? 0 : 1,
                  }}
                >
                  <p className={styles.day}>
                    {moment()
                      .tz('America/New_York')
                      .add(dateIndex, 'days')
                      .format('ddd')}
                  </p>
                  <p className={styles.date}>
                    {`${moment()
                      .tz('America/New_York')
                      .add(dateIndex, 'days')
                      .format('MM')}/${moment()
                      .tz('America/New_York')
                      .add(dateIndex, 'days')
                      .format('DD')}`}
                  </p>
                </div>
              ))}
            </div>
          )}
          {(showSP500 ||
            reportSubType === 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS') && (
            <DOWJonesFilter
              className={styles.spBox}
              filterOptions={filterOptions}
              onDOWJONESChange={onDOWJONESChange}
              onNasdaqChange={onNasdaqChange}
              onSPChange={onSPChange}
            />
          )}

          {(reportSubType === 'DOW_DAILY_PRICE_VOLUME_UPTREND' ||
            reportSubType === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND') && (
            <div className={styles.spBox}>
              <Checkbox
                checked={get(filterOptions, 'sortByVol', false)}
                onChange={onSoryByVolChange}
              >
                Sort By Volume
              </Checkbox>
            </div>
          )}
          {reportSubType === 'ALLOCATIONS' && (
            <div
              className={styles.reportOptionBlock}
              style={{ marginRight: 20 }}
            >
              <Select
                defaultValue={get(
                  filterOptions,
                  'category',
                  'All Institutions',
                )}
                style={{ width: 180 }}
                onChange={(value) => onCategoryChange(value)}
                placeholder="Select Category"
                disabled={isLoading}
                className={styles.vSelectSearchnew}
              >
                {(categoryOptions || []).map((option) => (
                  <Option key={option.name} value={option.name}>
                    {option.value}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          {reportSubType === 'C52WKHL' && (
            <div
              className={styles.reportOptionBlock}
              style={{ marginRight: 20 }}
            >
              Gain Type:
              <Select
                defaultValue={get(filterOptions, 'category', 'fifty')}
                style={{ width: 180 }}
                onChange={(value) => onCategoryChange(value)}
                placeholder="Select Type"
                disabled={isLoading}
                className={styles.vSelectSearchnew}
              >
                <Option key="50 % Gain Stocks" value="fifty">
                  50 % Gain Stocks
                </Option>
                <Option key="100 % Gain Stocks" value="hundred">
                  100 % Gain Stocks
                </Option>
              </Select>
            </div>
          )}
          {(reportSubType === 'INSIDER_DAILY_REPORT' ||
            reportSubType === 'INSIDER_ALL_CUMULATIVE_REPORT' ||
            reportSubType === 'INSIDER_DETAIL_SUMMARY_REPORT') && (
            <div
              className={styles.reportOptionBlock}
              style={{ marginRight: 20 }}
            >
              <Select
                defaultValue={get(filterOptions, 'type', 'All')}
                style={{ width: 80 }}
                onChange={(value) => onTypeChange(value)}
                placeholder="Select No Of Weeks"
                disabled={isLoading}
                className={styles.vSelectSearchnew}
              >
                {(typeOptions || []).map((option) => (
                  <Option key={option.name} value={option.name}>
                    {option.value}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          {false && (
            <div
              className={styles.reportOptionBlock}
              style={{ marginRight: 20 }}
            >
              <Select
                defaultValue={get(filterOptions, 'type', '1 Year')}
                style={{ width: 110 }}
                onChange={(value) => onDurationChange(value)}
                placeholder="Select Duration"
                disabled={isLoading}
                className={styles.vSelectSearchnew}
              >
                {(durationOptions || []).map((option) => (
                  <Option key={option.name} value={option.value}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          {reportSubType === 'BY_APPROX_TXN_VAL' && (
            <div
              className={styles.reportOptionBlock}
              style={{ marginRight: 20 }}
            >
              <Select
                defaultValue={
                  filterOptions.status === 'DECREASED' ? 'SELL' : 'BUY'
                }
                style={{ width: 110 }}
                onChange={(value) => onStatusTxnChange(value)}
                placeholder="Select Status"
                disabled={isLoading}
                className={styles.vSelectSearchnew}
              >
                {(statusTxnOptions || []).map((option) => (
                  <Option key={option.name} value={option.value}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          {reportSubType === 'INSTITUTION_PERFORMANCE_REPORT' && (
            <div
              className={styles.reportOptionBlock}
              style={{ marginRight: 20 }}
            >
              <Select
                defaultValue={
                  filterOptions.status === 'NON ACTIVE' ? 'INACTIVE' : 'ACTIVE'
                }
                style={{ width: 110 }}
                onChange={(value) => onStatusTxnChange(value)}
                placeholder="Select Criterion"
                disabled={isLoading}
                className={styles.vSelectSearchnew}
              >
                <Option key={'ACTIVE'} value={'ACTIVE'}>
                  {'ACTIVE'}
                </Option>
                <Option key={'NON ACTIVE'} value={'INACTIVE'}>
                  {'SOLD'}
                </Option>
              </Select>
            </div>
          )}
          {reportSubType === 'DOW_HIGH_LEVEL_REPORT' &&
            get(filterOptions, 'symbols', []).length === 0 &&
            searchText.length === 0 && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <label className={styles.vlabelfull}>{`#Weeks: `}</label>
                <Select
                  style={{ width: 80 }}
                  onChange={(value) => onNoOfWeekChange(value)}
                  placeholder={'No of Weeks'}
                  disabled={isLoading}
                  value={get(filterOptions, 'noOfWeeks', null)}
                  defaultValue={get(filterOptions, 'noOfWeeks', null)}
                  className={styles.vSelectSearchnew}
                >
                  <Option key={''} value={null}>
                    {'ALL'}
                  </Option>
                  {get(noOfWeeksList, 'Increased', []).map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          {reportSubType === 'DOW_DOWN_TREND_REPORT' &&
            get(filterOptions, 'symbols', []).length === 0 &&
            searchText.length === 0 && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <label className={styles.vlabelfull}>{`#Weeks: `}</label>
                <Select
                  style={{ width: 80 }}
                  onChange={(value) => onNoOfWeekChange(value)}
                  placeholder={'No of Weeks'}
                  disabled={isLoading}
                  value={get(filterOptions, 'noOfWeeks', null)}
                  defaultValue={get(filterOptions, 'noOfWeeks', null)}
                  className={styles.vSelectSearchnew}
                >
                  <Option key={''} value={null}>
                    {'ALL'}
                  </Option>
                  {get(noOfWeeksList, 'Decreased', []).map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          {reportSubType === 'DOW_THEORY_UPTREND' &&
            get(filterOptions, 'symbols', []).length === 0 &&
            searchText.length === 0 && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <label className={styles.vlabelfull}>{`#Weeks: `}</label>
                <Select
                  style={{ width: 60 }}
                  onChange={(value) => onNoOfWeekChange(value)}
                  placeholder={'No of Weeks'}
                  disabled={isLoading}
                  defaultValue={noOfWeeks}
                  className={styles.vSelectSearchnew}
                >
                  {get(noOfWeeksList, 'Increased', []).map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          {reportSubType === 'TS_WEEKLY_REPORT' &&
            get(filterOptions, 'symbols', []).length === 0 &&
            searchText.length === 0 && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <label className={styles.vlabelfull}>{`#Weeks: `}</label>
                <Select
                  style={{ width: 60 }}
                  onChange={(value) => onNoOfWeekChange(value)}
                  placeholder={'No of Weeks'}
                  disabled={isLoading}
                  defaultValue={noOfWeeks}
                  className={styles.vSelectSearchnew}
                >
                  {get(searchData, 'cnstDays', []).map((option) => (
                    <Option key={option} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </div>
            )}

          {reportSubType === 'DOW_THEORY_DOWNTREND' &&
            get(filterOptions, 'symbols', []).length === 0 &&
            searchText.length === 0 && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <label className={styles.vlabelfull}>{`#Weeks: `}</label>
                <Select
                  style={{ width: 60 }}
                  onChange={(value) => onNoOfWeekChange(value)}
                  placeholder={'No of Weeks'}
                  disabled={isLoading}
                  defaultValue={noOfWeeks}
                  className={styles.vSelectSearchnew}
                >
                  {get(noOfWeeksList, 'Decreased', []).map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          {reportSubType === 'WEEKLY_YTD' &&
            get(filterOptions, 'symbols', []).length === 0 &&
            searchText.length === 0 && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <label className={styles.vlabelfull}>{`#Weeks: `}</label>
                <Select
                  style={{ width: 60 }}
                  onChange={(value) => onNoOfWeeklyTYDWeekChange(value)}
                  placeholder={'No of Weeks'}
                  disabled={isLoading}
                  value={
                    get(filterOptions, 'noOfWeeklyYTDWeeks', null)
                      ? get(filterOptions, 'noOfWeeklyYTDWeeks', null)
                      : get(noOfWeeklyYTDWeeksList, 'Increased.0.value', null)
                  }
                  defaultValue={
                    get(filterOptions, 'noOfWeeklyYTDWeeks', null)
                      ? get(filterOptions, 'noOfWeeklyYTDWeeks', null)
                      : get(noOfWeeklyYTDWeeksList, 'Increased.0.value', null)
                  }
                  className={styles.vSelectSearchnew}
                >
                  <Option key={'ALL'} value={'ALL'}>
                    {'ALL'}
                  </Option>
                  {get(noOfWeeklyYTDWeeksList, 'Increased', []).map(
                    (option) => (
                      <Option key={option.name} value={option.name}>
                        {option.value}
                      </Option>
                    ),
                  )}
                </Select>
              </div>
            )}
          {reportSubType === 'DOW_DAILY_PRICE_UPTREND' &&
            get(filterOptions, 'symbols', []).length === 0 &&
            searchText.length === 0 && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <label className={styles.vlabelfull}>{`#Days: `}</label>
                <Select
                  style={{ width: 60 }}
                  onChange={(value) => onNoOfDaysChange(value)}
                  placeholder={'No of Days'}
                  disabled={isLoading}
                  defaultValue={noOfDays}
                  className={styles.vSelectSearchnew}
                >
                  {get(noOfDaysList, 'Increased', []).map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </div>
            )}

          {reportSubType === 'DOW_DAILY_PRICE_DOWNTREND' &&
            get(filterOptions, 'symbols', []).length === 0 &&
            searchText.length === 0 && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <label className={styles.vlabelfull}>{`#Days: `}</label>
                <Select
                  style={{ width: 60 }}
                  onChange={(value) => onNoOfDaysChange(value)}
                  placeholder={'No of Days'}
                  disabled={isLoading}
                  defaultValue={noOfDays}
                  className={styles.vSelectSearchnew}
                >
                  {get(noOfDaysList, 'Decreased', []).map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          {reportSubType === 'DOW_DAILY_PRICE_VOLUME_UPTREND' &&
            get(filterOptions, 'symbols', []).length === 0 &&
            searchText.length === 0 && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <label className={styles.vlabelfull}>{`#Days: `}</label>
                <Select
                  style={{ width: 60 }}
                  onChange={(value) => onNoOfDaysChange(value)}
                  placeholder={'No of Days'}
                  disabled={isLoading}
                  defaultValue={noOfDays}
                  className={styles.vSelectSearchnew}
                >
                  {get(noOfDaysForVolumeList, 'Increased', []).map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </div>
            )}

          {reportSubType === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND' &&
            get(filterOptions, 'symbols', []).length === 0 &&
            searchText.length === 0 && (
              <div
                className={styles.reportOptionBlock}
                style={{ marginRight: 20 }}
              >
                <label className={styles.vlabelfull}>{`#Days: `}</label>
                <Select
                  style={{ width: 60 }}
                  onChange={(value) => onNoOfDaysChange(value)}
                  placeholder={'No of Days'}
                  disabled={isLoading}
                  defaultValue={noOfDays}
                  className={styles.vSelectSearchnew}
                >
                  {get(noOfDaysForVolumeList, 'Decreased', []).map((option) => (
                    <Option key={option.name} value={option.name}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          {/*{reportSubType === 'CRITERIA_MET' &&*/}
          {/*  get(filterOptions, 'symbols', []).length === 0 &&*/}
          {/*  searchText.length === 0 && (*/}
          {/*    <div*/}
          {/*      className={styles.reportOptionBlock}*/}
          {/*      style={{ marginRight: 20 }}*/}
          {/*    >*/}
          {/*      <label className={styles.vlabelfull}>{`#Days: `}</label>*/}
          {/*      <Select*/}
          {/*        style={{ width: 60 }}*/}
          {/*        onChange={(value) => onNoOfDaysChange(value)}*/}
          {/*        placeholder={'No of Days'}*/}
          {/*        disabled={isLoading}*/}
          {/*        defaultValue={''}*/}
          {/*        className={styles.vSelectSearchnew}*/}
          {/*      >*/}
          {/*        <Option key={null} value={null}></Option>*/}
          {/*        {uniqueVals.map((option) => (*/}
          {/*          <Option key={option} value={option}>*/}
          {/*            {option}*/}
          {/*          </Option>*/}
          {/*        ))}*/}
          {/*      </Select>*/}
          {/*    </div>*/}
          {/*  )}*/}
          <div className={styles.vMobFilterNew}>
            {!(
              reportSubType === 'OPTION_ALL_INSTITUTION' ||
              reportSubType === 'TOP_TWENTY_FIVE_SYMBOLS' ||
              reportSubType === 'INDIVIDUAL_STATUS_REPORT' ||
              reportSubType === 'OPTION_BY_INSTITUTION'
            ) && (
              <Button
                icon={<FilterOutlined />}
                size={20}
                onClick={() => {
                  setOpenFilterForm(!openFilterForm);
                  setOpenColumnFilter(false);
                }}
                //style={{ marginRight: 14 }}
                className={styles.CommonBtnsFilter}
                disabled={isLoading}
              >
                Filter/Sort
              </Button>
            )}
            {/* )} */}
            <Button
              icon={<SettingOutlined />}
              size={20}
              onClick={() => {
                setOpenColumnFilter(!openColumnFilter);
                setOpenFilterForm(false);
              }}
              disabled={isLoading}
            >
              Column Visibility
            </Button>
          </div>

          {openFilterForm && (
            <SearchReportFilterForm
              visible={openFilterForm}
              onCancel={() => setOpenFilterForm(false)}
              onFilter={onFilter}
              filterOptions={filterOptions}
              queryData={queryData}
              reportSubType={reportSubType}
              trendingStockType={trendingStockType}
              columns={tableColumns.filter(
                (col) => col.visible === true && col.value,
              )}
              sortDetail={sortDetail}
              reportType={reportType}
              selectedNoOfQtrs={selectedNoOfQtrs}
            />
          )}
          {openColumnFilter && (
            <ColumnVisibility
              visible={openColumnFilter}
              onCancel={() => setOpenColumnFilter(false)}
              // onFilter={onFilter}
              tableColumns={tableColumns}
              onColumnFilter={onColumnFilter}
            />
          )}
          {showShareQuarter && (
            <div
              className={styles.reportOptionBlock}
              style={{ marginLeft: 20 }}
            >
              <label
                style={{ color: green }}
                className={styles.vlabelfull}
              >{`View #Qtrs: `}</label>
              <Select
                defaultValue={selectedNoOfQtrs}
                style={{ width: 60 }}
                onChange={(value) => onNoQtrsChange(value)}
                placeholder="Select No of Quarter"
                disabled={isLoading}
                className={styles.vSelectSearchnew}
              >
                {noOfQuartersForShares.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
          )}
        </Row>
      </Row>
      <Row span={24} justify="space-between">
        {reportType === 'MARK_MINERVINI_STRATEGY' && (
          <Tabs
            defaultActiveKey={reportSubType}
            style={{ width: '100%' }}
            onChange={(key) => onMinerviniChange(key)}
            centered
            id="trendingStockTab"
          >
            <TabPane tab="Meet Criteria" key="CRITERIA_MET"></TabPane>
            <TabPane tab="No Longer Meet Criteria" key="EXIT_REPORT"></TabPane>
          </Tabs>
        )}
        {reportSubType === 'TRENDING_STOCK' && (
          <Tabs
            defaultActiveKey={trendingStockType}
            style={{ width: '100%' }}
            onChange={(key) => setTrendingStockType(key)}
            centered
            id="trendingStockTab"
          >
            <TabPane tab="Gainers" key="GAINERS"></TabPane>
            <TabPane tab="Penny Gainers" key="PENNY_GAINERS"></TabPane>
            <TabPane tab="Decliners" key="DECLINERS"></TabPane>
            <TabPane tab="Penny Decliners" key="PENNY_DECLINERS"></TabPane>
            <TabPane tab="High Volume" key="ACTIVES"></TabPane>
            <TabPane tab="52 W High" key="FIFTY_TWO_WEEK_HIGH"></TabPane>
            <TabPane tab="52 W Low" key="FIFTY_TWO_WEEK_LOW"></TabPane>
          </Tabs>
        )}
        {reportSubType === 'TOP_GAINERS' && (
          <Tabs
            defaultActiveKey={topGainersType}
            style={{ width: '100%' }}
            onChange={(key) => setTopGainersType(key)}
            centered
            id="trendingStockTab"
          >
            <TabPane tab="Gainers" key="GAINERS"></TabPane>
            <TabPane tab="Decliners" key="DECLINERS"></TabPane>
          </Tabs>
        )}
      </Row>
      <Row span={24} id={reportSubType ? reportSubType : reportType}>
        <Col span={24} style={{ marginTop: 20 }}>
          {error && error !== 'inActive' && (
            <p style={{ color: red }}>{error}</p>
          )}
          {(!isLoading && error !== 'inActive') || (data || []).length > 0 ? (
            <Table
              style={{
                width:
                  reportSubType === 'SYMBOL_SUMMARY_MUTUAL_FUNDS'
                    ? '55%'
                    : reportSubType === 'BY_FUND_SYMBOL'
                    ? '90%'
                    : reportSubType === 'TOP_TWENTY_FIVE_SYMBOLS'
                    ? '60%'
                    : reportSubType === 'TS_WEEKLY_REPORT'
                    ? '80%'
                    : reportSubType === 'PRICE_HISTORY'
                    ? '30%'
                    : reportType === 'MARK_MINERVINI_STRATEGY'
                    ? '80%'
                    : reportSubType === 'INDIVIDUAL_STATUS_REPORT' &&
                      (queryData.multiStatus === 'NO_CHANGE' ||
                        queryData.multiStatus === 'NEWLY_BOUGHT')
                    ? '30%'
                    : reportSubType === 'INDIVIDUAL_STATUS_REPORT'
                    ? '50%'
                    : reportSubType === 'INSTITUTION_PERFORMANCE_REPORT'
                    ? '55%'
                    : '',
                margin:
                  reportSubType === 'SYMBOL_SUMMARY_MUTUAL_FUNDS' ||
                  reportSubType === 'INSTITUTION_PERFORMANCE_REPORT' ||
                  reportSubType === 'TOP_TWENTY_FIVE_SYMBOLS' ||
                  reportType === 'MARK_MINERVINI_STRATEGY' ||
                  reportSubType === 'TS_WEEKLY_REPORT' ||
                  reportSubType === 'INDIVIDUAL_STATUS_REPORT' ||
                  reportSubType === 'PRICE_HISTORY' ||
                  reportSubType === 'BY_FUND_SYMBOL'
                    ? 'auto'
                    : '',
              }}
              loading={isLoading}
              scroll={{ y: y }}
              // scroll={{ x: true }}
              columns={formatColumn(
                (tableColumns || []).filter(
                  (col) => col.visible !== false && col.value,
                ),
                reportSubType === 'TOP_TWENTY_FIVE_SYMBOLS' ? false : true,
                sortDetail,
                history,
                reportSubType === 'TRENDING_STOCK' ? showDetail : false,
                reportSubType === 'DETAIL_SUMMARY_REPORT'
                  ? 'SDL'
                  : reportSubType === 'BY_APPROX_TXN_VAL'
                  ? 'SAV'
                  : reportType === 'MARK_MINERVINI_STRATEGY'
                  ? 'MMS'
                  : reportSubType === 'INDIVIDUAL_STATUS_REPORT'
                  ? 'IST'
                  : '',
                reportSubType === 'TRENDING_STOCK' &&
                  trendingStockType === 'ACTIVES'
                  ? 'TS_ACTIVES_REPORT'
                  : reportType === 'MARK_MINERVINI_STRATEGY'
                  ? 'MMS'
                  : reportType === 'THINKSABIO_DIAMONDS'
                  ? 'diamonds'
                  : reportSubType === 'INSTITUTION_PERFORMANCE_REPORT' ||
                    reportSubType === 'INSTITUTION_ALLOCATION_REPORT' ||
                    reportSubType === 'TOP_TWENTY_FIVE_SYMBOLS'
                  ? 'isr'
                  : reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT'
                  ? 'IIR'
                  : reportSubType === 'INSIDER_ALL_CUMULATIVE_REPORT'
                  ? reportSubType
                  : 'search_report',
                null,
                null,
                theme,
                ISRTOIIIRHandler,
                reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT',
                filterOptions,
                selectedQuarter,
                ISRTOStatusReport,
                get(queryData, 'multiStatus', 'INCREASED'),
              )}
              dataSource={formatTableData2(data)}
              pagination={false}
              size="small"
              bordered={true}
              // onChange={handleTableOnChange}
              rowClassName={styles.tableHeader}
              className={styles.TabularData}
              showSorterTooltip={false}
              onChange={onTableChange}
              id="allPicksId"
              // sticky={true}
            />
          ) : error === 'inActive' ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '20px',
              }}
            >
              {error && (
                <p style={{ color: red }}>
                  The Trending report will be available from 04.00 AM onwards
                </p>
              )}
            </div>
          ) : (
            <div className={styles.loaderStyle}>
              <BounceLoader color={green} loading={isLoading} size={150} />
            </div>
          )}
        </Col>
      </Row>

      {reportSubType !== 'TOP_TWENTY_FIVE_SYMBOLS' &&
        reportSubType !== 'INDIVIDUAL_STATUS_REPORT' && (
          <Pagination
            pageSize={pageSize}
            total={total || data.length}
            pageNo={pageNo}
            onChange={onPaginationChange}
            loading={isLoading}
            showPagination={true}
          />
        )}
      {(reportSubType === 'TOP_TWENTY_FIVE_SYMBOLS' ||
        reportSubType === 'INDIVIDUAL_STATUS_REPORT') &&
        total > 0 && (
          <p style={{ marginLeft: 306, marginBottom: 15, marginTop: 15 }}>
            {reportSubType === 'TOP_TWENTY_FIVE_SYMBOLS'
              ? `Showing 1 to ${total} of ${formatNumber2(total)} entries`
              : ' '}{' '}
          </p>
        )}
      {showGraph && reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT' ? (
        <DraggableModal
          visible={showGraph}
          title={
            reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT' ? (
              <p
                style={{ margin: 0, textAlign: 'center' }}
              >{`Graphical Respresentations For 
            ${get(queryData, 'institutionsName', '')} 
            (Total AUM : ${currencyFormat(
              get(sectorAllocation, '0.total', 0),
              false,
              false,
              2,
              false,
              true,
            )} As of ${selectedQuarter.replace('_', '-')} )`}</p>
            ) : (
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  cursor: 'all-scroll',
                }}
              >{`${get(
                filterOptions,
                'category',
                'All Institutions',
              )} Sector Wise Allocation for  ${selectedQuarter.replace(
                '_',
                '-',
              )} Quarter`}</p>
            )
          }
          width={1100}
          onCancel={() => setShowGraph(false)}
          footer={null}
        >
          <div style={{ color: 'var(--whitecolor)' }}>
            Graph Type
            <Select
              defaultValue={graphType}
              style={{ width: 200, marginBottom: '10px' }}
              onChange={(key) => onGraphTypeChange(key)}
              placeholder="Select to Get Graph"
              disabled={isLoading}
            >
              <Option key={'Inst Shares'} value={'Inst Shares'}>
                {'Inst Shares'}
              </Option>
              <Option key={'Inst Counts'} value={'Inst Counts'}>
                {'Inst Counts'}
              </Option>
              <Option key={'Total Shares'} value={'Total Shares'}>
                {'Total Shares'}
              </Option>
              <Option
                key={'Total Shares Change'}
                value={'lastQtrTotalSharesChange'}
              >
                {'Total Shares Change'}
              </Option>
              <Option key={'Current Price'} value={'lastSale'}>
                {'Current Price'}
              </Option>
              <Option key={'Possible Growth'} value={'possGrowth'}>
                {'Possible Growth'}
              </Option>
              <Option key={'Market Value'} value={'marketValue'}>
                {'Market Value'}
              </Option>
              <Option
                key={'Approx Inv Value'}
                value={'lastQtr1ApproxInvestedValue'}
              >
                {'Approx Inv Value'}
              </Option>
              <Option key={'Shares Change'} value={'lastQtrSharesChange'}>
                {'Shares Change'}
              </Option>
              <Option key={'Count Change'} value={'lastQtrCountChange'}>
                {'Count Change'}
              </Option>
              <Option key={'shares Diff'} value={'sharesDiff'}>
                {'shares Diff'}
              </Option>
              <Option key={'Avg Price'} value={'lastQtr1Avg'}>
                {'Avg Price'}
              </Option>
              <Option key={'% Allocation'} value={'allocationPerc'}>
                {'% Allocation'}
              </Option>
              <Option key={'1Yr%'} value={'oneYrGrowth'}>
                {'1Yr%'}
              </Option>
              <Option key={'5Yr%'} value={'fiveYrGrowth'}>
                {'5Yr%'}
              </Option>
              <Option key={'YTD'} value={'ytd'}>
                {'YTD'}
              </Option>
              <Option key={'52 Wk High'} value={'c52WkHigh'}>
                {'52 Wk High'}
              </Option>
              <Option key={'Rank'} value={'rank'}>
                {'Rank'}
              </Option>
            </Select>
          </div>
          <br />
          {['Inst Counts', 'Inst Shares', 'Total Shares'].includes(
            graphType,
          ) ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  data={
                    getGraphData(
                      get(searchData, 'data', []),
                      get(searchData, 'columns'),
                    ) || []
                  }
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  isAnimationActive={true}
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis
                    dataKey="symbol"
                    tick={{ fontWeight: 'bold', fontSize: 14, fill: '#1abd50' }}
                  />
                  <YAxis tick={<CustomYAxisTick />} tickMargin={20} />
                  <Tooltip
                    formatter={(value: any) => formatLargeNumbers(value)}
                    cursor={<CustomCursor />}
                  />
                  <Legend />
                  <Bar
                    dataKey={
                      graphType === 'Inst Shares'
                        ? 'lastQtr1Shares'
                        : graphType === 'Inst Counts'
                        ? 'lastQtr1Count'
                        : graphType === 'Total Shares'
                        ? 'totalLastQtr1Shares'
                        : ''
                    }
                    fill="#8884d8"
                    barSize={20}
                    animationDuration={500}
                    animationEasing="ease-in"
                  />
                  <Bar
                    dataKey={
                      graphType === 'Inst Shares'
                        ? 'lastQtr2Shares'
                        : graphType === 'Inst Counts'
                        ? 'lastQtr2Count'
                        : graphType === 'Total Shares'
                        ? 'totalLastQtr2Shares'
                        : ''
                    }
                    fill="#82ca9d"
                    barSize={20}
                    animationDuration={500}
                    animationEasing="ease-in"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  data={
                    getSingleGraphData(
                      get(searchData, 'data', []),
                      get(searchData, 'columns'),
                    ) || []
                  }
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  isAnimationActive={true}
                  onMouseMove={(state) => {
                    console.log('Mouse moved on chart:', state); // Debugging the mouse move event
                  }}
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis
                    dataKey="symbol"
                    tick={{
                      fontWeight: 'bold',
                      fontSize: 14,
                      fill: '#1abd50',
                    }}
                  />
                  <YAxis
                    tick={<CustomYAxisTick />}
                    tickMargin={20}
                    domain={[
                      Math.min(
                        ...getSingleGraphData(
                          get(searchData, 'data', []),
                          get(searchData, 'columns'),
                        ).map((d) => parseFloat(d[graphType])),
                      ),
                      Math.max(
                        ...getSingleGraphData(
                          get(searchData, 'data', []),
                          get(searchData, 'columns'),
                        ).map((d) => parseFloat(d[graphType])),
                      ),
                    ]}
                  />
                  <Tooltip
                    formatter={(value: any) => formatLargeNumbers(value)}
                    cursor={<CustomCursor />}
                  />
                  <Legend content={<CustomLegend />} />
                  <Bar
                    dataKey={graphType}
                    fill={(data) => (data.fill ? data.fill : '#8884d8')}
                    barSize={20}
                    animationDuration={500}
                    animationEasing="ease-out"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </DraggableModal>
      ) : (
        ''
      )}
      {showAllocation &&
        (reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT' ||
        reportSubType === 'ALLOCATIONS' ? (
          <DraggableModal
            visible={showAllocation}
            title={
              reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT' ? (
                <p
                  style={{ margin: 0, textAlign: 'center' }}
                >{`Sector Wise Allocation for 
            ${get(queryData, 'institutionsName', '')} 
            (Total AUM : ${currencyFormat(
              get(sectorAllocation, '0.total', 0),
              false,
              false,
              2,
              false,
              true,
            )} As of ${selectedQuarter.replace('_', '-')} )`}</p>
              ) : (
                <p
                  style={{
                    margin: 0,
                    textAlign: 'center',
                    cursor: 'all-scroll',
                  }}
                >{`${get(
                  filterOptions,
                  'category',
                  'All Institutions',
                )} Sector Wise Allocation for  ${selectedQuarter.replace(
                  '_',
                  '-',
                )} Quarter`}</p>
              )
            }
            width={1100}
            onCancel={() => setShowAllocation(false)}
            footer={null}
          >
            <PieChart
              height={500}
              width={1050}
              radius={150}
              data={
                sectorAllocation && sectorAllocation.length
                  ? sectorAllocation
                  : []
              }
              dataKey={
                reportSubType === 'INDIVIDUAL_INSTITUTION_REPORT'
                  ? 'allocation'
                  : 'allocationPercentage'
              }
            />
          </DraggableModal>
        ) : (
          <DraggableModal
            visible={showAllocation}
            title={
              <p
                style={{ margin: 0, textAlign: 'center' }}
              >{`Sector Wise Allocation for 
            Compare Inst
            As of ${selectedQuarter.replace('_', '-')}`}</p>
            }
            width="100%"
            style={{ top: 20 }}
            onCancel={() => setShowAllocation(false)}
            footer={null}
          >
            <div style={{ border: '1px solid red' }}>
              <MultiBarChart
                insData={
                  compareSectorAllocation && compareSectorAllocation.length
                    ? compareSectorAllocation
                    : []
                }
                height={700}
                width={'100%'}
                showLegend={true}
              />
            </div>
          </DraggableModal>
        ))}
    </div>
  );
};

export default CommonSearchPage;
