import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';
import { round } from 'lodash';

import styles from '../Tools.StopLossCalc/styles.module.scss';

interface Props {
  record: Object;
  alerts: Object;
  tabNum: string;
}

const StopLossCalc: React.FC<Props> = ({ record, tabNum, alerts }) => {
  useEffect(() => {}, []);
  const [form] = Form.useForm();
  const [stopLossAmt, setStopLossAmt] = useState([]);
  const [afterLossAmt, setAfterLossAmt] = useState([]);
  const reset = () => {
    form.resetFields();
    setAfterLossAmt(null);
    setStopLossAmt(null);
  };
  const onFinish = (values) => {
    const { invested, stopLossPerc } = values;
    const stopLossPrice = invested - (stopLossPerc / 100) * invested;
    setAfterLossAmt(stopLossPrice);
    setStopLossAmt(round(invested - stopLossPrice, 4));
  };
  return (
    <>
      <div>
        <div
          style={{
            marginLeft: '200px',
            marginTop: '10px',
            marginBottom: '50px',
          }}
        >
          <div className={styles.loaderStyle}>
            <span
              style={{
                color: 'var(--whitecolor)',
                fontSize: '20px',
                marginLeft: '200px',
                fontWeight: 'bold',
              }}
            >
              Stop Loss Calculator
            </span>
            <Form
              layout="vertical"
              className={styles.referForm}
              name="tools-form"
              form={form}
              onFinish={onFinish}
            >
              <Row
                span={12}
                className="gutter-row"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Col span={10} style={{ marginRight: '70px' }}>
                    <Form.Item
                      name="invested"
                      label="Investment Amount ($)"
                      style={{ marginBottom: 5 }}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value && !getFieldValue('invested')) {
                              return Promise.reject(
                                'Invested Amount is required',
                              );
                            } else {
                              if (value < 0) {
                                return Promise.reject(
                                  'Invested Amount should be greater than 0',
                                );
                              }
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        style={{
                          width: '95%',
                          height: '50px',
                          marginTop: '20px',
                        }}
                        placeholder="Invested"
                        type={'number'}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10} style={{ marginRight: '70px' }}>
                    <Form.Item
                      name="stopLossPerc"
                      label="Stop Loss (%)"
                      style={{ marginBottom: 5 }}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value && !getFieldValue('stopLossPerc')) {
                              return Promise.reject('Stop Loss % is required');
                            } else {
                              if (value < 0) {
                                return Promise.reject(
                                  'Stop Loss % should be greater than 0',
                                );
                              }
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        style={{
                          width: '95%',
                          height: '50px',
                          marginTop: '20px',
                        }}
                        placeholder="Stop Loss %"
                        type={'number'}
                      />
                    </Form.Item>
                  </Col>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '20px',
                  }}
                >
                  <div className={styles.btnSectionBox}>
                    <Button
                      type="primary"
                      style={{
                        width: '200px',
                        height: '35px',
                        marginTop: '10px',
                        marginLeft: '120px',
                        marginRight: '60px',
                      }}
                      htmlType="submit"
                    >
                      Calculate
                    </Button>
                  </div>
                  <div className={styles.btnSectionBox} onClick={reset}>
                    <Button
                      type="primary"
                      style={{
                        width: '200px',
                        height: '35px',
                        marginTop: '10px',
                        marginBottom: '20px',
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
                {/* </Form.Item> */}
                {/*{error && <span style={{ color: red }}>{error}</span>}*/}
              </Row>
            </Form>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '20px',
              }}
            >
              <div style={{ marginRight: '100px' }}>
                <span
                  style={{
                    color: 'var(--whitecolor)',
                    fontSize: '15px',
                    marginBottom: '50px',
                  }}
                >
                  Stop Loss Amount
                </span>
                <br />
                <span style={{ color: 'var(--whitecolor)' }}>
                  <Input
                    style={{
                      width: '300px',
                      marginTop: '10px',
                      height: '50px',
                    }}
                    placeholder="Stop Loss Amount"
                    value={stopLossAmt}
                  />{' '}
                  $
                </span>
              </div>
              <div>
                <span
                  style={{
                    color: 'var(--whitecolor)',
                    fontSize: '15px',
                    marginBottom: '50px',
                  }}
                >
                  Amount After Stop Loss
                </span>
                <br />
                <span style={{ color: 'var(--whitecolor)' }}>
                  <Input
                    style={{
                      width: '300px',
                      marginTop: '10px',
                      height: '50px',
                    }}
                    placeholder="After Stop Loss"
                    value={afterLossAmt}
                  />{' '}
                  $
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StopLossCalc;
