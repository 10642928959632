import React, { useEffect } from 'react';

import { Layout as AntLayout } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';

import { Footer, Header } from '../components';
import { isStudentUrl } from '../utils';
import { setLocationTitle } from '../utils/LocationHelper';
import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {
  component: any;
  authState: string;
  location: Object;
  user: Object;
}

const NonSubscribedLayout: React.FC<Props> = ({
  component: Component,
  authState,
  location,
  user,
  history,
  ...props
}) => {
  const { subscriptionStatus } = useSelector(
    ({ user }) => ({
      subscriptionStatus: get(
        user,
        'profile.stripeCustomer.subscription_status',
        'N/A',
      ),
    }),
    shallowEqual,
  );

  const path = get(location, 'pathname');
  useEffect(() => {
    setLocationTitle(location);
  }, [location]);
  return (
    <Route
      {...props}
      render={(matchProps) =>
        authState === 'signedIn' ? (
          subscriptionStatus === 'ACTIVE' ||
          subscriptionStatus === 'TRIAL' ||
          isStudentUrl() ? (
            <Redirect to="/dashboard" />
          ) : (
            <AntLayout className={styles.defaultContainer}>
              <Header {...matchProps} {...props} nonSubscribed={true} />
              <Component user={user} {...matchProps} {...props} />
              <Footer />
            </AntLayout>
          )
        ) : (
          <Redirect to={authState === 'signIn' ? '/' : path ? path : '/'} />
        )
      }
    />
  );
};

export default NonSubscribedLayout;
