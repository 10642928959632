import React from 'react';

import { Drawer, Avatar, Switch, Image } from 'antd';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';

import { green } from '../../utils';
import { sidebarLinks } from './DashboardHeader.constant';
import styles from './style.module.scss';

interface Props {
  showSidebar: boolean;
  setShowSidebar: Function;
  menuLinkStyle: Object;
  name: string;
  signOut: Function;
  onThemeChange: Function;
  theme: String;
  lastName: string;
  userType: string;
  profilePic: Object;
  match: Object;
  isStudent: boolean;
}
const Sidebar: React.FC<Props> = ({
  showSidebar,
  setShowSidebar,
  menuLinkStyle,
  signOut,
  name,
  userType,
  onThemeChange,
  theme,
  lastName,
  profilePic,
  match,
  isStudent,
}) => {
  const selectedKeys = sidebarLinks(isStudent && userType !== 'paid')
    .filter(({ text }) => RegExp(text).test(get(match, 'path')))
    .map(({ text }) => text);
  if (selectedKeys.length > 1) selectedKeys.shift();

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={() => setShowSidebar(false)}
      visible={showSidebar}
      getContainer={false}
      style={{ position: 'absolute', height: '100vh' }}
      onClick={() => setShowSidebar(false)}
    >
      {sidebarLinks(isStudent && userType !== 'paid').map(
        ({ name, path, text }) => (
          <NavLink key={path} to={path} activeClassName={styles.navActiveClass}>
            <div
              style={{
                ...menuLinkStyle,
                // color: selectedKeys.includes(path) ? green : black,
                // background: white,
                fontSize: 15,
                justifyContent: 'start',
              }}
              className={styles.vnewSidebar}
            >
              {name}
            </div>
          </NavLink>
        ),
      )}
      <div
        style={{
          ...menuLinkStyle,
          // color: black,
          // background: white,
          fontSize: 15,
          justifyContent: 'space-between',
          cursor: 'pointer',
          paddingRight: 20,
        }}
        className={styles.vnewSidebar}
      >
        <span>{`Dark Mode`}</span>
        <Switch defaultChecked={theme === 'dark'} onChange={onThemeChange} />
      </div>
      <div
        onClick={signOut}
        style={{
          ...menuLinkStyle,
          // color: black,
          // background: white,
          background: 'transparent',
          fontSize: 15,
          justifyContent: 'start',
          cursor: 'pointer',
        }}
        className={styles.vnewSidebar}
      >
        Sign Out
      </div>

      <div
        style={{
          ...menuLinkStyle,
          color: green,
          //background: white,
          background: 'transparent',
          fontSize: 15,
          justifyContent: 'start',
          marginTop: 30,
        }}
      >
        {profilePic.image ? (
          <div className={styles.profilePic}>
            <Image key={profilePic.time} src={profilePic.image} />
          </div>
        ) : (
          <Avatar size={32} style={{ paddingLeft: 15 }}>
            {`${name[0]}${lastName ? lastName[0] : ''}`}
          </Avatar>
        )}
        <span style={{ paddingLeft: 10, textTransform: 'capitalize' }}>
          {name}
        </span>
      </div>
    </Drawer>
  );
};

export default Sidebar;
