import React from 'react';

import { Layout, Row, Tabs } from 'antd';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

// import DailyPick from './DailyPick';
import AllPicks from './AllPicks';
import AnyTimePicks from './AnyTimePicks';
import BioPicks from './BioPicks';
import CatalystPicks from './CatalystPicks';
import QuarterlyPick from './QuarterlyPick';
import styles from './style.module.scss';
import TodaysPick from './TodaysPick';

const { Content } = Layout;
const { TabPane } = Tabs;
// const { Title } = Typography;
interface Props extends RouteComponentProps<any> {
  history: Object;
  match: Object;
}

const PicksPage: React.FC<Props> = ({ match, history }) => {
  const type = get(match, 'params.type', 'today');
  return (
    <Content className={styles.picksContainer}>
      <Row justify="start" span={24} className={styles.pickTabsV}>
        <Tabs
          defaultActiveKey="today"
          activeKey={type === 'catalyst' ? 'bio' : type}
          style={{ width: '100%' }}
          onChange={(key) => history.push(`/our/picks/${key}`)}
          centered
        >
          <TabPane tab="Today's" key="today">
            <TodaysPick history={history} match={match} />
          </TabPane>
          <TabPane tab="All Picks" key="all">
            <AllPicks history={history} match={match} />
          </TabPane>
          <TabPane tab="Top Quarterly" key="quarterly">
            <QuarterlyPick history={history} match={match} />
          </TabPane>

          <TabPane tab="Ever Green" key="anytime">
            <AnyTimePicks history={history} match={match} />
          </TabPane>

          <TabPane tab="Bio Picks" key="bio">
            <Tabs
              defaultActiveKey="bio"
              activeKey={type}
              style={{ width: '100%' }}
              onChange={(key) => history.push(`/our/picks/${key}`)}
              centered
            >
              <TabPane tab="TS Bio pick" key="bio">
                <BioPicks history={history} match={match} />
              </TabPane>
              <TabPane tab="Upcoming Catalyst" key="catalyst">
                <CatalystPicks history={history} match={match} />
              </TabPane>
            </Tabs>
          </TabPane>
        </Tabs>
      </Row>
    </Content>
  );
};

export default PicksPage;
