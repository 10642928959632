import React, { useEffect, useState } from 'react';

import { Button, Typography } from 'antd';
import { get } from 'lodash';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { Table } from '../../components';
import { getPickDetail } from '../../graphql';
import { useTheme } from '../../ThemeContext';
import {
  red,
  green,
  errorHandler,
  formatColumn,
  formatNumber2,
} from '../../utils';
import { listItems, listShareItems } from './Pick.constant';
import styles from './style.module.scss';

const { Paragraph } = Typography;

interface Props {
  onClose: Function;
  date: string;
  symbol: string;
}

const PickDetail: React.FC<Props> = ({ onClose, date, symbol }) => {
  const { theme } = useTheme();
  const [pickDetail, setPickDetail] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    setIsLoading(true);
    const apis = [getPickDetail({ date: date, symbol: symbol })];
    Promise.all(apis)
      .then((res) => {
        setPickDetail(res[0]);
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [date, symbol]);
  const { data = {}, displayNames = {} } = pickDetail;
  const handleClick = (symbol) => {
    const filters = {
      type: 'SYMBOL'.toLowerCase(),
      subtype: 'INDIVIDUAL_STATISTICAL_REPORT'.toLowerCase(),
      symbols: symbol,
    };
    history.push(`/search/report/` + queryString.stringify(filters));
  };

  const getValue = (type: String) => (
    <h5
      style={{
        color: get(data, type, 'N/A') > 0 ? green : red,
      }}
    >
      {`${formatNumber2(get(data, type))} ${
        get(data, type, 'N/A') !== 'N/A' ? '%' : ''
      }`}
    </h5>
  );

  const getColumns = () =>
    listItems.map((item) => ({
      title: displayNames[item],
      dataIndex: item,
      key: item,
    }));
  const getShareColumns = () =>
    listShareItems.map((item) => ({
      title: displayNames[item],
      dataIndex: item,
      key: item,
    }));
  const getTableData = [
    {
      lastQtr1Count: get(data, 'lastQtr1Count'),
      lastQtr3Count: get(data, 'lastQtr3Count'),
      lastQtr4Count: get(data, 'lastQtr4Count'),
      lastQtr5Count: get(data, 'lastQtr5Count'),
      lastQtr6Count: get(data, 'lastQtr6Count'),
      lastQtr2Count: get(data, 'lastQtr2Count'),
    },
  ];
  const getTableSharesData = [
    {
      lastQtr1Shares: get(data, 'lastQtr1Shares'),
      lastQtr3Shares: get(data, 'lastQtr3Shares'),
      lastQtr4Shares: get(data, 'lastQtr4Shares'),
      lastQtr5Shares: get(data, 'lastQtr5Shares'),
      lastQtr6Shares: get(data, 'lastQtr6Shares'),
      lastQtr2Shares: get(data, 'lastQtr2Shares'),
    },
  ];
  const reasonList = (data.reason || '').split('●');
  return (
    <>
      {!isLoading ? (
        <>
          <div className={styles.title}>
            <h4>{get(data, 'symbol', 'N/A')}</h4>
            <p>{get(data, 'name', 'N/A')}</p>
            <Paragraph ellipsis={{ rows: 2, expandable: true }}>
              {get(data, 'description', '')}
            </Paragraph>
          </div>
          <div className={styles.viewBtnSection}>
            <Button
              type="primary"
              onClick={() => handleClick(symbol)}
              disabled={isLoading}
              className={styles.vViewFullBtn}
            >
              View Full Statistical Report
            </Button>
          </div>
          {/* <div className={styles.title}>
            <h4>{get(data, 'symbol', 'N/A')}</h4>
            <p>{get(data, 'name', 'N/A')}</p>
            
          </div> */}
          <div className={styles.rates}>
            <div>
              {getValue('highestGrowthSinceRecommended')}
              <p>
                Highest Growth <br /> Since Posted
              </p>
            </div>
            <div>
              {getValue('ytd')}
              <p>YTD</p>
            </div>
            <div>
              {getValue('oneYrGrowth')}
              <p>1 Yr %</p>
            </div>
            <div>
              {getValue('fiveYrGrowth')}
              <p>5 Yr %</p>
            </div>
          </div>
          <div className={styles.question}>
            <h5>Why ThinkSabio Pick?</h5>
            {reasonList.length > 1 ? (
              <ul>
                <Paragraph ellipsis={{ rows: 4, expandable: true }}>
                  {reasonList
                    .filter((l) => l.length > 0)
                    .map((l, i) => (
                      <li key={i}>{l}</li>
                    ))}
                </Paragraph>
              </ul>
            ) : (
              <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                {get(data, 'reason', 'N/A')}
              </Paragraph>
            )}
          </div>
          <div className={styles.points} id="isr">
            <h5>Here are the latest 6 Quarter of Institution Count.</h5>
            <Table
              // scroll={{ x: true }}
              columns={formatColumn(
                getColumns(),
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                theme,
              )}
              dataSource={getTableData}
              loading={isLoading}
              pagination={false}
              size="small"
              bordered={true}
              rowClassName={styles.tableHeader}
              className={styles.TabularData}
              id="allPicksDetailsId"
            />
            <h5>Here are the latest 6 Quarter of Institution Shares.</h5>
            <Table
              // scroll={{ x: true }}
              columns={formatColumn(
                getShareColumns(),
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                theme,
              )}
              dataSource={getTableSharesData}
              loading={isLoading}
              pagination={false}
              size="small"
              bordered={true}
              rowClassName={styles.tableHeader}
              className={styles.TabularData}
              id="allPicksDetailsId"
            />
          </div>
        </>
      ) : (
        <div className={styles.loaderStyle}>
          <BounceLoader color={green} loading={isLoading} size={150} />
        </div>
      )}
    </>
  );
};

export default PickDetail;
