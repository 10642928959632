import { REPORT_CODE_NAME_MAP } from './LocationHelperConstants';

export const setLocationTitle = (location: any, additionalParam: any) => {
  const locationTitleItems = getLocationTitle(location, additionalParam);
  const locationTitle = locationTitleItems.join(' | ');
  if (locationTitle !== document.title) {
    document.title = locationTitle;
  }
};

export const getLocationTitle = (
  location: any,
  additionalParameter: any,
): string[] => {
  const { path, params } = getLocationDetails(location);
  console.log(path, params);
  const pathArray = path.split('/');

  if (path === '/reports') {
    if (additionalParameter) {
      const reportType = ('_' + additionalParameter)
        .toLowerCase()
        .replace(/_+(\w|$)/g, function ($$, $1) {
          return ' ' + $1.toUpperCase();
        });
      return ['Search', REPORT_CODE_NAME_MAP[params['reportCode']], reportType];
    }
    return ['Reports', REPORT_CODE_NAME_MAP[params['reportCode']]];
  } else if (path === '/search') {
    return ['Search'];
  } else if (path === '/search/report') {
    const subType = ('_' + (params['subtype'] || params['type']))
      .toLowerCase()
      .replace(/_+(\w|$)/g, function ($$, $1) {
        return ' ' + $1.toUpperCase();
      });
    if (additionalParameter) {
      const reportType = ('_' + additionalParameter)
        .toLowerCase()
        .replace(/_+(\w|$)/g, function ($$, $1) {
          return ' ' + $1.toUpperCase();
        });
      return ['Search', subType, reportType];
    }
    return ['Search', subType];
  } else if (path.includes('trending')) {
    const pathArray = path.split('/');
    return [
      'Trending',
      ('_' + pathArray[2])
        .toLowerCase()
        .replace(/_+(\w|$)/g, function ($$, $1) {
          return ' ' + $1.toUpperCase();
        }),
      ('_' + pathArray[3])
        .toLowerCase()
        .replace(/_+(\w|$)/g, function ($$, $1) {
          return ' ' + $1.toUpperCase();
        }),
    ];
  } else if (path.includes('our')) {
    const pathArray = path.split('/');
    return [
      'Our Picks',
      ('_' + pathArray[pathArray.length - 1])
        .toLowerCase()
        .replace(/_+(\w|$)/g, function ($$, $1) {
          return ' ' + $1.toUpperCase();
        }),
    ];
  } else if (path.includes('portfolio')) {
    const pathArray = path.split('/');
    return [
      'Portfolio',
      ('_' + pathArray[2])
        .toLowerCase()
        .replace(/_+(\w|$)/g, function ($$, $1) {
          return ' ' + $1.toUpperCase();
        }),
    ];
  } else if (path.includes('university')) {
    const pathArray = path.split('/');
    if (pathArray.length === 3) {
      return [
        'University',
        ('_' + pathArray[2])
          .toLowerCase()
          .replace(/_+(\w|$)/g, function ($$, $1) {
            return ' ' + $1.toUpperCase();
          }),
      ];
    } else if (pathArray.length === 4) {
      return [
        'University',
        ('_' + pathArray[2])
          .toLowerCase()
          .replace(/_+(\w|$)/g, function ($$, $1) {
            return ' ' + $1.toUpperCase();
          }),
        ('_' + pathArray[3])
          .toLowerCase()
          .replace(/_+(\w|$)/g, function ($$, $1) {
            return ' ' + $1.toUpperCase();
          }),
      ];
    } else if (pathArray.length === 3) {
      return [
        'University',
        ('_' + pathArray[2])
          .toLowerCase()
          .replace(/_+(\w|$)/g, function ($$, $1) {
            return ' ' + $1.toUpperCase();
          }),
      ];
    }
    return ['University'];
  } else if (pathArray.length === 2) {
    return [pathArray[1]];
  } else {
    return ['ThinkSabio'];
  }
};
const getLocationDetails = (location: any) => {
  const pathname = location.pathname;
  const hasParams = pathname.includes('=');

  let path = pathname;
  if (hasParams) {
    const pathWithFirstFilter = pathname.substring(0, pathname.indexOf('='));
    path = pathWithFirstFilter.substring(
      0,
      pathWithFirstFilter.lastIndexOf('/'),
    );
  }

  const params: any = {};
  if (hasParams) {
    pathname
      .replace(path + '/', '')
      .split('&')
      .forEach((fv: string) => {
        const filterValue = fv.split('=');
        params[filterValue[0]] = filterValue[1];
      });
  }
  return { path, params };
};
