// @flow
import React, { useState, useEffect } from 'react';

import { Drawer, Typography } from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Pagination, Table } from '../../components';
import { useTheme } from '../../ThemeContext';
import { formatColumn } from '../../utils';
import { columns } from './Pick.constant';
import PickDetail from './PickDetail';
import styles from './style.module.scss';
const { Paragraph } = Typography;

interface Props {
  data: Object;
  isLoading: boolean;
  type: string;
  sortDetail: Array;
  setPageSize: Function;
  setPageNo: Function;
  setSortDetail: Function;
  pageSize: number;
  pageNo: number;
  history: Object;
}

const PicksList = ({
  isLoading,
  data,
  type,
  sortDetail,
  setPageSize,
  setPageNo,
  setSortDetail,
  pageSize,
  pageNo,
  history,
}: Props) => {
  const { theme } = useTheme();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showCatalystDetail, setshowCatalystDetail] = useState<boolean>(false);
  const [record, setRecord] = useState({});
  const [pageHeight, setPageHeight] = useState<number>();

  useEffect(() => {
    setPageHeight(window.innerHeight);
    window.addEventListener('resize', (e) => {
      setTimeout(() => {
        setPageHeight(window.innerHeight);
      }, 300);
    });
  }, []);

  const showDetail = (rec, type) => {
    console.log('record', rec);
    setRecord(rec);
    if (type === 'catalyst') {
      setshowCatalystDetail(true);
    } else {
      setShowDetails(true);
    }
  };
  const onPaginationChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setPageNo(page);
  };

  const handleSort = (sortBy, sortOrder, multi) => {
    if (sortOrder) {
      setSortDetail([
        {
          sortBy: sortBy,
          sortOrder: sortOrder,
        },
      ]);
    } else {
      setSortDetail([]);
    }
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    let index = sortDetail.findIndex((d) => d.sortBy === field);
    if (index > -1) {
      let newOrder =
        order === 'ascend' ? 'ASC' : order === 'descend' ? 'DESC' : null;
      handleSort(field, newOrder, false);
    } else {
      if (order === 'ascend') {
        handleSort(field, 'ASC', false);
      } else if (order === 'descend') {
        handleSort(field, 'DESC', false);
      } else {
        handleSort(field, null, false);
      }
    }
  };
  const y = pageHeight - 290;
  const total = get(data[0], 'total', 0);
  return (
    <>
      <Table
        scroll={{ y: y }}
        columns={formatColumn(
          columns(type.toUpperCase()),
          true,
          sortDetail,
          history,
          showDetail,
          null,
          null,
          null,
          null,
          theme,
        )}
        dataSource={data}
        loading={isLoading}
        pagination={false}
        size="small"
        bordered={true}
        rowClassName={styles.tableHeader}
        className={styles.TabularData}
        onChange={onTableChange}
        id="allPicksId"
        // sticky={true}
      />
      <Pagination
        pageSize={pageSize}
        total={total}
        pageNo={pageNo}
        onChange={onPaginationChange}
        loading={isLoading}
        showPagination={true}
      />
      {showDetails && (
        <Drawer
          title="Pick Detail"
          width={720}
          onClose={() => setShowDetails(false)}
          visible={true}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <PickDetail
            onClose={() => setShowDetails(false)}
            date={moment(get(record, 'date')).format('YYYY-MM-DD')}
            symbol={get(record, 'symbol', 'N/A')}
          />
        </Drawer>
      )}
      {showCatalystDetail && (
        <Drawer
          title="Upcoming Catalyst"
          width={720}
          onClose={() => setshowCatalystDetail(false)}
          visible={true}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Paragraph>{get(record, 'upcoming_catalyst', '-')}</Paragraph>
        </Drawer>
      )}
    </>
  );
};

export default PicksList;
