import React, { createContext, useState, useContext } from 'react';

import { themeColours } from './utils';

type ThemeName = 'light' | 'dark';
type ThemeContextType = {
  theme: ThemeName;
  setTheme: (name: ThemeName) => void;
};
const ThemeContext = createContext<ThemeContextType>(!undefined);

type Props = {
  children: React.ReactNode;
};
export const ThemeProvider = ({ children }: Props) => {
  const [themeName, setThemeName] = useState<ThemeName>('dark');

  const setTheme = (name: ThemeName) => {
    // document.body.style.setProperty('--color', themeColours[name].color);
    document.body.style.setProperty('--nodata', themeColours[name].noData);
    document.body.style.setProperty('--inputbgm', themeColours[name].inputBgm);
    document.body.style.setProperty('--btncolor', themeColours[name].btnColor);
    document.body.style.setProperty('--graybody', themeColours[name].grayBody);
    document.body.style.setProperty('--tbodytd', themeColours[name].tbodyTd);
    document.body.style.setProperty('--tdborder', themeColours[name].tdBorder);
    document.body.style.setProperty('--newgray', themeColours[name].newGray);
    document.body.style.setProperty('--D8d8', themeColours[name].D8d8);
    document.body.style.setProperty('--greenbgm', themeColours[name].greenBgm);
    document.body.style.setProperty(
      '--blackNewColor',
      themeColours[name].blackNewColor,
    );

    document.body.style.setProperty('--E6border', themeColours[name].E6Border);
    document.body.style.setProperty('--spanbgm', themeColours[name].spanBgm);
    document.body.style.setProperty('--tdhover', themeColours[name].tdHover);
    document.body.style.setProperty('--scroll', themeColours[name].scroll);
    document.body.style.setProperty('--scroll2', themeColours[name].scrollNew);
    document.body.style.setProperty(
      '--paymentColor',
      themeColours[name].paymentColor,
    );
    document.body.style.setProperty(
      '--careticoncolor',
      themeColours[name].caretIconColor,
    );
    document.body.style.setProperty(
      '--tfootercolor',
      themeColours[name].tfooterColor,
    );
    document.body.style.setProperty(
      '--lightborder',
      themeColours[name].lightBorder,
    );
    document.body.style.setProperty(
      '--greenthead',
      themeColours[name].greenThead,
    );
    document.body.style.setProperty(
      '--rightNewThBorder',
      themeColours[name].rightNewThBorder,
    );
    document.body.style.setProperty(
      '--whiteBodyBgm',
      themeColours[name].whiteBodyBgm,
    );
    document.body.style.setProperty(
      '--searchshadow',
      themeColours[name].searchShadow,
    );
    document.body.style.setProperty(
      '--tabborder',
      themeColours[name].tabBorder,
    );
    document.body.style.setProperty(
      '--newpagination',
      themeColours[name].newPagination,
    );
    document.body.style.setProperty(
      '--newTbodyColor',
      themeColours[name].newTbodyColor,
    );
    document.body.style.setProperty(
      '--newTheadColor',
      themeColours[name].newTheadColor,
    );
    document.body.style.setProperty(
      '--spanborder',
      themeColours[name].spanBorder,
    );
    document.body.style.setProperty(
      '--inputwhite',
      themeColours[name].inputWhite,
    );
    document.body.style.setProperty(
      '--transparent',
      themeColours[name].transparent,
    );
    document.body.style.setProperty(
      '--blacknewcolor',
      themeColours[name].blackNewColor,
    );
    document.body.style.setProperty(
      '--greencolor',
      themeColours[name].greenColor,
    );
    document.body.style.setProperty(
      '--inputbgmnum',
      themeColours[name].inputBgmNum,
    );
    document.body.style.setProperty(
      '--rightthborder',
      themeColours[name].rightThBorder,
    );
    document.body.style.setProperty(
      '--rightborder',
      themeColours[name].rightBorder,
    );
    document.body.style.setProperty(
      '--graycolor1',
      themeColours[name].grayColor1,
    );
    document.body.style.setProperty(
      '--blackth',
      themeColours[name].blackThColor,
    );
    document.body.style.setProperty(
      '--graythead',
      themeColours[name].grayThead,
    );
    document.body.style.setProperty(
      '--buttonborder',
      themeColours[name].buttonBorder,
    );
    document.body.style.setProperty(
      '--inputshadow',
      themeColours[name].inputShadow,
    );
    document.body.style.setProperty(
      '--graycolor',
      themeColours[name].grayColor,
    );
    document.body.style.setProperty(
      '--fontweight',
      themeColours[name].fontWeight,
    );
    document.body.style.setProperty(
      '--headershadow',
      themeColours[name].headerShadow,
    );
    document.body.style.setProperty(
      '--headerbgm',
      themeColours[name].headerBgm,
    );
    document.body.style.setProperty(
      '--whitecolor',
      themeColours[name].whiteColor,
    );
    document.body.style.setProperty(
      '--whiteborder',
      themeColours[name].whiteBorder,
    );
    document.body.style.setProperty(
      '--grayborder',
      themeColours[name].grayBorder,
    );
    // new one
    document.body.style.setProperty(
      '--newboxshadow',
      themeColours[name].newBoxShadow,
    );
    document.body.style.setProperty(
      '--background-color',
      themeColours[name].backgroundColor,
    );
    // document.body.style.setProperty('--border', themeColours[name].border);
    // document.body.style.setProperty('--lightcolor', themeColours[name].light);
    document.body.style.setProperty('--newcolor', themeColours[name].newColor);
    document.body.style.setProperty('--datepick', themeColours[name].datePick);
    // document.body.style.setProperty('--thborder', themeColours[name].thBorder);
    document.body.style.setProperty('--thbottom', themeColours[name].thBottom);
    document.body.style.setProperty('--datebox', themeColours[name].dateBox);
    document.body.style.setProperty('--button', themeColours[name].button);
    document.body.style.setProperty('--drawer', themeColours[name].drawer);
    document.body.style.setProperty('--bgGrey', themeColours[name].bgGrey);
    document.body.style.setProperty('--thead', themeColours[name].thead);
    document.body.style.setProperty('--shadow', themeColours[name].shadow);
    document.body.style.setProperty('--span', themeColours[name].span);
    document.body.style.setProperty('--newthead', themeColours[name].newThead);
    document.body.style.setProperty('--newtbody', themeColours[name].newTbody);
    document.body.style.setProperty('--tbody', themeColours[name].tbody);
    document.body.style.setProperty(
      '--pagination',
      themeColours[name].pagination,
    );
    document.body.style.setProperty(
      '--bordertransaction',
      themeColours[name].borderTransaction,
    );
    document.body.style.setProperty(
      '--sorterColor',
      themeColours[name].sorterColor,
    );
    document.body.style.setProperty(
      '--transaction',
      themeColours[name].transaction,
    );
    document.body.style.setProperty(
      '--blackcolor',
      themeColours[name].blackColor,
    );
    document.body.style.setProperty(
      '--disclaimer',
      themeColours[name].disclaimer,
    );
    document.body.style.setProperty(
      '--newborder',
      themeColours[name].newBorder,
    );
    document.body.style.setProperty(
      '--breadcrumb',
      themeColours[name].breadCrumb,
    );
    document.body.style.setProperty(
      '--newbutton',
      themeColours[name].newButton,
    );
    document.body.style.setProperty(
      '--background',
      themeColours[name].background,
    );
    document.body.style.setProperty(
      '--buttonColor',
      themeColours[name].buttonColor,
    );
    document.body.style.setProperty(
      '--borderright',
      themeColours[name].borderRight,
    );
    document.body.style.setProperty(
      '--borderbottom',
      themeColours[name].borderBottom,
    );
    document.body.style.setProperty(
      '--theadColor',
      themeColours[name].theadColor,
    );
    document.body.style.setProperty(
      '--lightdark',
      themeColours[name].lightDark,
    );
    document.body.style.setProperty(
      '--portfolio',
      themeColours[name].portfolio,
    );
    setThemeName(name);
  };
  return (
    <ThemeContext.Provider value={{ theme: themeName, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
