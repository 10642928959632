import React, { useState, useContext } from 'react';

import {
  SyncOutlined,
  CloseCircleOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { Row, Tag, Breadcrumb, Collapse, Button } from 'antd';
import { get } from 'lodash';
import queryString from 'query-string';
import BounceLoader from 'react-spinners/BounceLoader';

import { Table } from '../../components';
import { useTheme } from '../../ThemeContext';
import { green, formatTableData } from '../../utils';
import SaveReport from './SaveReport';
import { getCompareSymbolTablesData } from './SearchReport.helper';
import { SearchReportContext } from './SearchReportContext';
import styles from './style.module.scss';
const { Panel } = Collapse;

const SymbolCompare = () => {
  const {
    searchData = {},
    reportInfo,
    isLoading,
    currentSearchCriteria,
    reportType,
    reportSubType,
    reportTitle,
    fetchReportName,
    history,
    setIsLoading,
    fetchingName,
  } = useContext(SearchReportContext);
  const [openKeys, setOpenKeys] = useState(['1']);
  const { theme } = useTheme();

  const { symbols, title, breadcrumb = [] } = reportInfo;
  let { qtlyIndividualHedgeShrs, allQuarterlyData } =
    getCompareSymbolTablesData(searchData, theme, history);
  const handleClick = (selectedSymbol) => {
    const filters = {
      type: reportType.toLowerCase(),
      subtype:
        symbols.length > 2
          ? reportSubType.toLowerCase()
          : 'INDIVIDUAL_STATISTICAL_REPORT'.toLowerCase(),
      symbols: symbols.filter((symbol) => symbol !== selectedSymbol),
    };
    setIsLoading(true);
    history.push(
      `/search/report/` +
        queryString.stringify(filters, {
          skipNull: true,
        }),
    );
  };

  const onPanelChange = (keys) => {
    setOpenKeys(keys);
  };
  const openAllPanel = () => {
    let keys = new Array(allQuarterlyData.length)
      .fill(0)
      .map((_, i) => (i + 1).toString());

    setOpenKeys(keys);
  };

  return (
    <div className={styles.compareSymReport}>
      <div className={styles.vGoBackSaveBar}>
        <p
          onClick={() =>
            get(history, 'location.state.prevPage', '') === 'search'
              ? history.push({
                  pathname: '/search',
                  state: { type: reportType, subType: reportSubType },
                })
              : history.goBack()
          }
          className={styles.vGoBackBtnBlock}
        >
          <span className={styles.vGoBackBtn}>
            <ArrowLeftOutlined /> Go Back
          </span>
        </p>
        {!isLoading && (
          <div className={styles.SaveReportBoxNew2}>
            <SaveReport
              searchCriteria={currentSearchCriteria}
              reportType={reportType}
              reportSubType={reportSubType}
              reportTitle={reportTitle}
              fetchReportName={fetchReportName}
              fetchingName={fetchingName}
            />
          </div>
        )}
      </div>
      <div className={styles.symbolSearchContentBox}>
        <Breadcrumb>
          {breadcrumb.map((item) => (
            <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className={styles.titleContainer}>
          <h2
            style={{
              color: 'var(--whitecolor)',
            }}
          >
            {title}
          </h2>
        </div>
        {symbols.map((symbol) => (
          <Tag
            key={symbol}
            icon={
              isLoading ? (
                <SyncOutlined spin />
              ) : (
                <CloseCircleOutlined onClick={() => handleClick(symbol)} />
              )
            }
            color={isLoading ? 'processing' : 'success'}
            id="vSuccesstag"
          >
            {symbol}
          </Tag>
        ))}
      </div>

      {!isLoading && (
        <Row justify="end" span={24} style={{ marginBottom: 10 }}>
          {openKeys.length !== allQuarterlyData.length ? (
            <Button onClick={openAllPanel}>Open All</Button>
          ) : (
            <Button onClick={() => setOpenKeys([])}>Close All</Button>
          )}
        </Row>
      )}
      <Row span={24} id="isr">
        {isLoading && (
          <div className={styles.loaderStyle}>
            <BounceLoader color={green} loading={isLoading} size={150} />
          </div>
        )}
        <Collapse
          defaultActiveKey={['1']}
          expandIconPosition="left"
          style={{ width: '100%' }}
          activeKey={openKeys}
          onChange={onPanelChange}
        >
          {allQuarterlyData.map((field, i) => (
            <Panel
              style={{ color: 'red' }}
              header={field.title.toUpperCase()}
              key={i + 1}
              id="vPanelChange"
            >
              {field.type === 'single' && (
                <div className={styles.searchPaneTable}>
                  <Table
                    scroll={{ x: true }}
                    loading={isLoading}
                    columns={field.columns}
                    dataSource={formatTableData(field.data)}
                    pagination={false}
                    size="small"
                    bordered={true}
                    rowClassName={styles.tableHeader}
                    className={styles.TabularData}
                    id="allPicksId"
                  />
                </div>
              )}
              {field.type === 'nested' && (
                <Collapse defaultActiveKey="1">
                  {qtlyIndividualHedgeShrs.map((subField, j) => (
                    <Panel
                      key={j + 1}
                      header={subField.subTitle.toUpperCase()}
                      // key="1"
                    >
                      <div className={styles.searchPaneTable}>
                        <Table
                          scroll={{ x: true }}
                          loading={isLoading}
                          columns={subField.columns}
                          dataSource={formatTableData(subField.data)}
                          pagination={false}
                          size="small"
                          bordered={true}
                          rowClassName={styles.tableHeader}
                          className={styles.TabularData}
                        />
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              )}
            </Panel>
          ))}
        </Collapse>
      </Row>
    </div>
  );
};

export default SymbolCompare;
