import { API } from 'aws-amplify';

import { getUserPaymentCard } from './queries';
export const getCustomerSubscriptionInfo = async () =>
  API.graphql({
    query: `query GetUser {
        getUser {
            stripeCustomer {
              stripe_customer_id
              stripe_subscription_id
              stripe_price_id
              stripe_payment_method_id
              interval
              subscription_status
              is_cancelled
              current_period_end
            }
        }
      }`,
  }).then(({ data }) => data.getUser);

export const getCustomerPaymentCardInfo = async () =>
  API.graphql({
    query: getUserPaymentCard,
  }).then(({ data }) => data.getUserPaymentCard);
