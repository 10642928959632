import React, { useState } from 'react';

import { SettingOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Modal, notification } from 'antd';
import { get } from 'lodash';

import { Table, ColumnVisibility, Pagination } from '../../components';
import { addSymbolToTsWatchlist } from '../../graphql';
import { useTheme } from '../../ThemeContext';
import {
  formatColumn,
  // formatTableData,
  formatNumber2,
  formatTableData,
  green,
} from '../../utils';
import StockNote from '../Portfolio/StockNote';
import styles from './Virtual/style.module.scss';
// const searchType = {
//   1: 'By Institution',
//   2: 'By Sector',
// };

interface Props {
  data: Object;
  selectedKey: number;
  isLoading: boolean;
  history: Object;
  tableColumns: any;
  setTableColumns: Function;
  onNoQtrsChange: Function;
  showNoOfQtrs: boolean;
  noOfQtrs: number;
  healthCheckType: string;
  showPagination: boolean;
  pageSize: number;
  pageNo: number;
  setFetchPortfolioData: Function;
  onPaginationChange: Function;
}

const WatchListTable: React.FC<Props> = ({
  data,
  isLoading,
  history,
  setTableColumns,
  healthCheckType,
  setFetchPortfolioData,
  showPagination,
  pageSize,
  pageNo,
  onPaginationChange,
}) => {
  const { theme } = useTheme();
  const [openColumnFilter, setOpenColumnFilter] = useState<boolean>(false);
  const [tData, setTData] = useState(get(data, 'data', []));
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [record, setRecord] = useState({});
  const [sortDetail, setSortDetail] = useState([
    {
      sortBy: 'dateAdded',
      sortOrder: 'DESC',
    },
  ]);

  const onColumnFilter = (filteredColumns) => {
    setTableColumns(filteredColumns);
  };
  const showDetail = (rec) => {
    tData.forEach((i) => {
      if (i.symbol === rec['symbol']) {
        rec['id'] = i.id;
        return;
      }
    });
    setRecord(rec);
    setShowDetails(true);
  };
  const arrangeColumns = (columns = {}) => {
    const formattedColumns = Object.keys(columns).map((col, index) => ({
      key: col,
      name: col,
      title: col === 'delete' ? 'Delete' : null,
      value: columns[col],
      visible: true,
      index: col === 'symbol' || col === 'symbols' ? 0 : index + 1,
    }));
    return formattedColumns;
  };
  let tableData = get(data, 'data', []);
  const tableColumns = arrangeColumns(get(data, 'columns'));
  // const totalEntries = get(data, 'total', tableData.length);
  const [total, setTotal] = useState(get(data, 'total', tableData.length));
  const onDelete = (row) =>
    Modal.confirm({
      title: 'Delete',
      content: (
        <p>
          Are you sure you want to delete{' '}
          <span style={{ color: green }}>{`${row.symbol}`}</span> ?{' '}
        </p>
      ),
      onOk: () =>
        handleDelete({
          symbol: row.symbol,
          type: 'delete',
        }),
    });

  const onChange = (event) => {
    const { value } = event.target;
    const tblData = tableData.filter(
      (item) =>
        item?.symbol?.toUpperCase().includes(value.toUpperCase()) === true,
    );
    tableData = [...tblData];
    setTotal(tblData.length);
    setTData([...tblData]);
    console.log(tData);
  };

  const handleSort = (sortBy: string, sortOrder: string) => {
    // onSort(sortBy, sortOrder, multi);
    setSortDetail([
      {
        sortBy: sortBy,
        sortOrder: sortOrder,
      },
    ]);
  };
  const getSortedData = (data) => {
    const sortObj = sortDetail[0];
    const newData = data.sort((a, b) => {
      let keyA =
        sortObj.sortBy === 'dateAdded'
          ? new Date(a[sortObj.sortBy])
          : a[sortObj.sortBy];
      let keyB =
        sortObj.sortBy === 'dateAdded'
          ? new Date(b[sortObj.sortBy])
          : b[sortObj.sortBy];
      // Compare the 2 dates
      if (sortObj.sortOrder === 'ASC') {
        if (!isNaN(+keyA)) {
          return keyA - keyB;
        } else {
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        }
      } else {
        if (!isNaN(+keyA)) {
          return keyB - keyA;
        } else {
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        }
      }
    });

    // const newData = orderBy(
    //   data,
    //   [sortObj.sortBy],
    //   [sortObj.sortOrder.toLowerCase()],
    // );
    console.log(newData);
    return newData;
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    if (order === 'ascend') {
      handleSort(field, 'ASC');
    } else {
      handleSort(field, 'DESC');
    }
  };

  const handleDelete = async (payload) => {
    const addSymbolResponse = await addSymbolToTsWatchlist(payload);
    if (addSymbolResponse.symbolAdded) {
      await notification.success({
        message: 'Symbol Deleted Successfully',
      });
      window.location.reload();
      // setAddSymbolLoad(false);
    } else {
      notification.error({
        message: 'Failed to Delete Symbol',
      });
    }
  };
  return (
    <div
      className={styles.tableContainer}
      id={healthCheckType}
      style={
        healthCheckType === 'sector'
          ? {
              maxWidth: 600,
              margin: 'auto',
            }
          : {}
      }
    >
      <div>
        <Input
          placeholder="search..."
          onChange={onChange}
          style={{ width: '200px', marginBottom: '20px' }}
          allowClear
        />
      </div>
      <div
        className={styles.vHealthTitleBarNew}
        style={{ marginBottom: '50px' }}
      >
        <Button
          icon={<SettingOutlined />}
          size={20}
          onClick={() => {
            setOpenColumnFilter(!openColumnFilter);
          }}
          disabled={isLoading}
        >
          Column Visibility
        </Button>
      </div>
      <div hidden={tableData.length === 0}>
        <Table
          style={{ width: '90%', margin: 'auto' }}
          // scroll={{ y: 500 }}
          columns={formatColumn(
            (tableColumns || []).filter((col) => col.visible !== false),
            true,
            sortDetail,
            history,
            showDetail,
            false,
            'watchList',
            onDelete,
            null,
            theme,
          )}
          dataSource={formatTableData(getSortedData(tData))}
          loading={isLoading}
          pagination={false}
          size="small"
          bordered={true}
          rowClassName={styles.tableHeader}
          className={styles.TabularData}
          onChange={onTableChange}
          // onChange={onTableChange}
          id="allPicksId"
          // sticky={true}
        />
        {showPagination ? (
          <Pagination
            pageSize={pageSize}
            total={total}
            pageNo={pageNo}
            onChange={onPaginationChange}
            loading={isLoading}
            showPagination={true}
            hideDisclaimer={true}
          />
        ) : (
          total > 0 && (
            <p style={{ marginLeft: '80px', color: 'var(--whitecolor)' }}>
              {`Showing 1 to ${total} of ${formatNumber2(total)} entries`}{' '}
            </p>
          )
        )}

        {openColumnFilter && (
          <ColumnVisibility
            visible={openColumnFilter}
            onCancel={() => setOpenColumnFilter(false)}
            // onFilter={onFilter}
            tableColumns={tableColumns}
            onColumnFilter={onColumnFilter}
          />
        )}
      </div>
      {showDetails && (
        <Drawer
          title="Watchlist Note"
          width={720}
          onClose={() => setShowDetails(false)}
          visible={true}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <StockNote
            onClose={() => setShowDetails(false)}
            // date={moment(get(record, 'date')).format('YYYY-MM-DD')}
            data={record}
            source={'watchlist'}
            setFetchPortfolioData={setFetchPortfolioData}
            editable={true}
          />
        </Drawer>
      )}
    </div>
  );
};

export default WatchListTable;
