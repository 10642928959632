import React from 'react';

import { NavLink } from 'react-router-dom';

import styles from './style.module.scss';

const PromoCode = () => {
  return (
    <div className={styles.freeTrialCardContainer}>
      <div className={styles.freeTrialCard}>
        <div className={styles.paymentCardContent}>
          <p className={styles.cardTitle}>Promo Code Sign up</p>
          <div className={styles.fee}>
            <p className={styles.amount}>No Credit Card required</p>
          </div>
          <h6>Full access to Web and Telegram Groups</h6>
          <NavLink
            to={{
              pathname: '/sign-up',
              state: {
                name: {
                  properties: {
                    name: 'promo',
                  },
                },
              },
            }}
          >
            <p
              className={styles.membershipBtn}
              // onClick={() => onPlanSelect(type)}
            >
              Get Free Access
            </p>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PromoCode;
