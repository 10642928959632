import React, { useState } from 'react';

import { Form, Row, Col, Button, InputNumber } from 'antd';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';

import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {
  reportType: string;
}
const PriceSearch: React.FC<Props> = ({ history, reportType }) => {
  const [form] = Form.useForm();
  const [currentField, setCurrentField] = useState('');

  const onFinish = (values) => {
    const { minPrice, maxPrice } = values;
    const filters = {
      minPrice: minPrice.toFixed(2),
      maxPrice: maxPrice.toFixed(2),
      type: reportType.toLowerCase(),
    };
    // history.push(
    //   `/search/report/` +
    //     queryString.stringify(filters, {
    //       skipNull: true,
    //     }),
    // );
    history.push({
      pathname:
        `/search/report/` +
        queryString.stringify(filters, {
          skipNull: true,
        }),

      state: { prevPage: 'search' },
    });
  };

  const onValuesChange = (changedValues) => {
    if (changedValues.minPrice) {
      form.validateFields(['maxPrice'], { force: true });
    }
    if (changedValues.maxPrice) {
      form.validateFields(['minPrice'], { force: true });
    }
  };
  return (
    <Form
      name="price-filter-form"
      onFinish={onFinish}
      labelCol={{ span: 24, padding: 0 }}
      wrapperCol={{ span: 24 }}
      form={form}
      initialValues={{}}
      onValuesChange={onValuesChange}
      // onFieldsChange={(field) =>
      //   setCurrentField(field[0] ? field[0].name[0] : '')
      // }
    >
      <Row span={24}>
        <Col span={12} style={{ padding: 10, paddingLeft: 0 }}>
          <Form.Item
            name={'minPrice'}
            label={'Min Price'}
            style={{ width: '100%', margin: 0 }}
            className={styles.vFormSearhNew}
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value && !getFieldValue('maxPrice')) {
                    return Promise.reject('Min Price is required');
                  }
                  if (getFieldValue('maxPrice')) {
                    if (value && getFieldValue('maxPrice') <= value) {
                      return currentField === rule.field
                        ? Promise.reject(
                            'Min Price should be less than Max Price',
                          )
                        : Promise.resolve();
                    } else {
                      return Promise.resolve();
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              placeholder={'Min Price'}
              // type="number"
              style={{ width: '100%' }}
              min={0}
              formatter={(n) => {
                if (n?.toString().indexOf('.') > -1) {
                  const [p1, p2] = n?.toString().split('.');
                  let a = `$ ${p1}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  if (p2) {
                    const b =
                      p2.length > 2
                        ? p2.toString().substring(0, 2)
                        : p2.toString();
                    a = a + '.' + b;
                  } else {
                    a = a + '.';
                  }
                  return a;
                } else {
                  return `$ ${n}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                }
              }}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onFocus={() => setCurrentField('minPrice')}
              size="large"
            />
          </Form.Item>
        </Col>
        {/* <Col span={12} style={{ padding: 10, paddingLeft: 0 }}>
          <Slider
            range
            defaultValue={[10, 10000]}
            max={10000000}
            min={0}
            step="100"
          />
        </Col> */}
        <Col span={12} style={{ padding: 10, paddingLeft: 0 }}>
          <Form.Item
            name={'maxPrice'}
            label={'Max Price'}
            style={{ width: '100%', margin: 0 }}
            className={styles.vFormSearhNew}
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value && !getFieldValue('minPrice')) {
                    return Promise.reject('Max Price is required');
                  }
                  if (getFieldValue('minPrice')) {
                    if (getFieldValue('minPrice') >= value) {
                      return currentField === rule.field
                        ? Promise.reject(
                            'Max Price should be greater than Min Price',
                          )
                        : Promise.resolve();
                    } else {
                      return Promise.resolve();
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              placeholder={'Max Price'}
              // type="number"
              style={{ width: '100%' }}
              min={0}
              formatter={(n) => {
                if (n?.toString().indexOf('.') > -1) {
                  const [p1, p2] = n?.toString().split('.');
                  let a = `$ ${p1}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  if (p2) {
                    const b =
                      p2.length > 2
                        ? p2.toString().substring(0, 2)
                        : p2.toString();
                    a = a + '.' + b;
                  } else {
                    a = a + '.';
                  }
                  return a;
                } else {
                  return `$ ${n}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                }
              }}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onFocus={() => setCurrentField('maxPrice')}
              size="large"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row span={24} justify="start" className={styles.buttonSectionBox}>
        <Form.Item colon={false} style={{ margin: 0, marginTop: 20 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};
export default PriceSearch;
