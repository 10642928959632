import React, { useEffect, useState } from 'react';

import {
  // LoadingOutlined,
  // PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Input,
  Form,
  Button,
  Select,
  Row,
  Col,
  message,
  Upload,
  Image,
  DatePicker,
  Checkbox,
  InputNumber,
} from 'antd';
import { get, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import { getSchoolList } from '../../api/RestApi';
import { getSignedUrl, getUserPersonalInfo } from '../../graphql';
import { saveStudentInfo, savePersonalInfo } from '../../graphql';
import { setStudentInfo, setPersonalData } from '../../redux/reducers/user';
import { green, usStates, grades, errorHandler } from '../../utils';
import styles from './style.module.scss';

const { Option } = Select;

const StudentProfile: React.FC<Props> = () => {
  const { personalData, studentInfo } = useSelector(
    ({ user }) => ({
      personalData: get(user, 'personalData', {}),
      studentInfo: get(user, 'studentInfo', {}),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [avatarLoading, setAvatarLoading] = useState<boolean>(false);
  const [imageObj, setImageObj] = useState({
    url: get(studentInfo, 'student_picture_id', ''),
    time: +new Date(),
  });
  const [avatarObj, setAvatarObj] = useState({
    url: get(personalData, 'additionalInfo.image_url', ''),
    time: +new Date(),
  });
  const [savingData, setSavingData] = useState<boolean>(false);
  const [schoolList, setSchoolList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [countyList, setCountyList] = useState<Array<object>>([]);
  const [selectedState, setSelectedState] = useState<string>('');

  const onSchoolSearch = async (value) => {
    setFetching(true);
    const schoolState = form.getFieldValue('schoolState');
    const res = await getSchoolList(schoolState, value);
    if (res) {
      setSchoolList(get(res, 'data.schoolList', []));
    }
    setFetching(false);
  };

  useEffect(() => {
    console.log(get(studentInfo, 'school_state', ''));
    console.log(selectedState);
    getCounties(
      selectedState ? selectedState : get(studentInfo, 'school_state', ''),
    );
  }, [selectedState, studentInfo]);

  const onFinish = (values) => {
    setIsLoading(true);
    const {
      phone,
      schoolState,
      schoolName,
      schoolCounty,
      studentId,
      grade,
      graduationDate,
      birthYear,
    } = values;
    const studentData = {
      id: get(studentInfo, 'id', null),
      school_state: schoolState,
      school_county: schoolCounty,
      school_name: schoolName,
      student_id: studentId,
      grade: grade,
      graduation_date: graduationDate.format('YYYY-MM-DD'),
      birth_year: birthYear.format('YYYY'),
      student_picture_id: get(imageObj, 'url', ''),
    };
    const userInfo = {
      mobile: phone,
      image_url: get(avatarObj, 'url', ''),
    };

    const apis = [
      saveStudentInfo(studentData),
      savePersonalInfo(userInfo, {}),
      getUserPersonalInfo(),
    ];
    setSavingData(true);
    Promise.all(apis)
      .then((res) => {
        const {
          first_name = '',
          last_name = '',
          email = '',
          type = '',
          additionalInfo = {},
          userBillingAddress = {},
        } = res[2];
        const data = {
          userBillingAddress: userBillingAddress ? userBillingAddress : {},
          additionalInfo: additionalInfo ? additionalInfo : {},
          first_name: first_name,
          last_name: last_name,
          email: email,
          type: type,
          date: new Date(),
        };
        dispatch(setPersonalData(data));
        dispatch(setStudentInfo(studentData));
        message.success('Personal Info Updated');
      })
      .catch(errorHandler)
      .finally(() => {
        setSavingData(false);
        window.location.reload();
      });
    setIsLoading(false);
  };

  const getCounties = async (selectedStateAbbrevation: string) => {
    const where = encodeURIComponent(
      JSON.stringify({
        stateAbbreviation: selectedStateAbbrevation,
      }),
    );
    const response = await fetch(
      `https://parseapi.back4app.com/classes/Area?limit=4000&keys=countyName&where=${where}`,
      {
        headers: {
          'X-Parse-Application-Id': 'VWAH9UbFty9tuCJVHIJPjYvH8OGcNyUTMkHH3UvL', // application id
          'X-Parse-Master-Key': 'UsYwiuputxOcEcYTZqWKshopMgEjElqA4U4Mcy9V', //readonly master key
        },
      },
    );
    const data = await response.json();
    setCountyList(data.results);
  };

  const getInitialValues = () => {
    return {
      name: `${get(personalData, 'first_name', '')} ${get(
        personalData,
        'last_name',
        '',
      )}`,
      email: get(personalData, 'email', ''),
      phone: get(personalData, 'additionalInfo.mobile', ''),
      schoolState: get(studentInfo, 'school_state', ''),
      schoolCounty: get(studentInfo, 'school_county', ''),
      schoolName: get(studentInfo, 'school_name', ''),
      grade: get(studentInfo, 'grade', ''),
      studentId: get(studentInfo, 'student_id', ''),
      graduationDate: moment(get(studentInfo, 'graduation_date', '')),
      birthYear: moment(get(studentInfo, 'birth_year', '')),
    };
  };
  const onDrop = async (acceptedFiles) => {
    setImageLoading(true);
    const signedUrls = await getSignedUrl({
      fileName: acceptedFiles.file.name,
    });

    const response = await fetch(signedUrls.url, {
      method: 'PUT',
      body: acceptedFiles.file,
    });
    if (response.status === 200) {
      setImageObj({
        url: signedUrls.accessUrl,
        time: +new Date(),
      });
    }
    setImageLoading(false);
  };
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setImageLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      console.log(info.file.originFileObj);
    }
  };

  const onDropAvatar = async (acceptedFiles) => {
    setAvatarLoading(true);
    const signedUrls = await getSignedUrl({
      fileName: acceptedFiles.file.name,
    });

    const response = await fetch(signedUrls.url, {
      method: 'PUT',
      body: acceptedFiles.file,
    });
    if (response.status === 200) {
      setAvatarObj({
        url: signedUrls.accessUrl,
        time: +new Date(),
      });
    }
    setAvatarLoading(false);
  };
  const handleChangeAvatar = (info) => {
    if (info.file.status === 'uploading') {
      setAvatarLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      console.log(info.file.originFileObj);
    }
  };

  return (
    <div className={styles.clientContainer}>
      <div className={styles.searchPaneTitle}>
        <h2>Personal info</h2>
        <p>This will help us to give you better recommendations</p>
      </div>
      <div className={styles.searchPaneForm}>
        {isLoading && isEmpty(personalData) ? (
          <div className={styles.loaderStyle}>
            <BounceLoader color={green} loading={isLoading} size={150} />
          </div>
        ) : (
          <Form
            onFinish={onFinish}
            layout="vertical"
            name="personal-info-form"
            initialValues={getInitialValues()}
            id="vFormId"
            form={form}
          >
            <Row className={styles.vPersonalInfo} gutter={12}>
              <Col span={12}>
                <Form.Item label="Full Name" name="name">
                  <Input disabled style={{ width: 270 }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Email" name="email">
                  <Input disabled style={{ width: 270 }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    // { required: true, message: 'Mobile Number is required' },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || value.toString().length === 10) {
                          return Promise.resolve();
                        }

                        return Promise.reject('Incorrect Mobile Number');
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    // type="number"
                    formatter={(value: string) =>
                      `${value}`.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                    }
                    parser={(value: string) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder="Mobile Number"
                    style={{ width: 270 }}
                    // max={9999999999}
                    size="middle"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="schoolState"
                  label="School State"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your school state',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={'Please Select Your State'}
                    style={{ width: 270 }}
                    onChange={(value: [string]) => {
                      console.log(value);
                      setSelectedState(value);
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {usStates &&
                      usStates.map((i) => (
                        <Option key={i.abbreviation} value={i.abbreviation}>
                          {i.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="schoolCounty"
                  label="School County"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your school County',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={'Please Select Your County'}
                    style={{ width: 270 }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countyList &&
                      countyList.map((i) => (
                        <Option key={i.countyName} value={i.countyName}>
                          {i.countyName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="schoolName"
                  label="What is your School Name ?"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your School Name',
                    },
                  ]}
                  style={{ width: '100%' }}
                >
                  <Select
                    showSearch
                    style={{ width: 270 }}
                    placeholder="Type School Name"
                    onSearch={onSchoolSearch}
                    filterOption={false}
                    allowClear={true}
                    showArrow={false}
                    notFoundContent={
                      fetching ? (
                        <span className={styles.spinnerStyle}>
                          <BounceLoader
                            color={green}
                            loading={true}
                            size={50}
                          />
                        </span>
                      ) : null
                    }
                  >
                    {schoolList &&
                      schoolList.map((school) => (
                        <Option key={school.schoolid} value={school.schoolName}>
                          <span>{school.schoolName}</span>
                          <br />
                          <span>{`${school.address.street}, ${school.address.city}`}</span>
                          <br />
                          <span>{`${school.address.state} - ${school.address.zip} `}</span>
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="grade"
                  label="Grade"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Grade',
                    },
                  ]}
                >
                  <Select style={{ width: 270 }}>
                    {grades &&
                      grades.map((i) => (
                        <Option key={i.grade} value={i.grade}>
                          {i.grade}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="graduationDate"
                  label="Graduation Date"
                  style={{}}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Graduation Date',
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: 270 }}
                    format="MMM, DD YYYY"
                    disabledDate={(currentDate: Date) =>
                      currentDate < moment().tz('America/New_York')
                    }
                    picker="date"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="birthYear"
                  label="Birth Year"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Birth Year',
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: 270 }}
                    picker="year"
                    disabledDate={(currentDate: Date) =>
                      currentDate > moment().tz('America/New_York')
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12} style={{ justifyContent: 'center' }}>
              <div
                style={{
                  marginBottom: '20px !important',
                  minHeight: '0px !important',
                }}
              >
                <Checkbox
                  style={{
                    marginLeft: '10px',
                    fontSize: '11px',
                    width: '520px',
                  }}
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                  }}
                >
                  I certify under oath that the information provided herewith,
                  and in any attached documents, is true and correct. I
                  understand that providing falsified information will lead to
                  "account termination".
                </Checkbox>
              </div>
            </Row>
            <Row gutter={12} style={{ justifyContent: 'center' }}>
              <Form.Item className={styles.personalBtn}>
                <Button
                  disabled={!checked}
                  type="primary"
                  loading={savingData}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </Row>
          </Form>
        )}
        <div>
          <div className={styles.profilePicUpload}>
            {avatarObj.url && (
              <div className={styles.profilePic} key={avatarObj.time}>
                <Image width={200} src={avatarObj.url} />
              </div>
            )}
            <div
              style={{
                marginTop: '30px',
              }}
            >
              <Upload
                name="avatar"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={(a) => onDropAvatar(a)}
                onChange={handleChangeAvatar}
              >
                <Button loading={avatarLoading} icon={<UploadOutlined />}>
                  {!avatarLoading ? 'Profile Pic' : 'Uploading'}
                </Button>
              </Upload>
            </div>
          </div>
          <div className={styles.profilePicUpload}>
            {imageObj.url && (
              <div className={styles.profilePic} key={imageObj.time}>
                <Image width={200} src={imageObj.url} />
              </div>
            )}
            <div
              style={{
                marginTop: '30px',
              }}
            >
              <Upload
                name="studentId"
                className="student-id-uploader"
                showUploadList={false}
                customRequest={(a) => onDrop(a)}
                onChange={handleChange}
              >
                <Button loading={imageLoading} icon={<UploadOutlined />}>
                  {!imageLoading ? 'Student Id' : 'Uploading'}
                </Button>
              </Upload>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
