import React, { useState, useEffect } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { notification, Tabs } from 'antd';
import { camelCase, startCase } from 'lodash';
import { useHistory } from 'react-router-dom';
// import { get } from 'lodash';
// import { useSelector, shallowEqual } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import { getStripeInfo, donation } from '../../api/RestApi';
import DonateForm from '../../components/DonateForm';
import TSDonateForm from '../../components/TSDonateForm';
// import { getHeros } from '../../graphql';
import { errorHandler, green } from '../../utils';
import styles from './styles.module.scss';

const Donate: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stripeInfo, setStripeInfo] = useState({});
  const [error, setError] = useState<boolean>(false);
  const [tab, setTab] = useState('YKR_SCHOOL');
  // const [heroes, setHeroes] = useState([]);
  const history = useHistory();
  const { TabPane } = Tabs;

  useEffect(() => {
    // const fetchHeroes = async () => {
    //   const res = await getHeros();
    //   return res;
    // if (res) {
    //   console.log(res);
    //   setHeroes(res);
    // }
    // };
    const promises = [getStripeInfo() /*, fetchHeroes()*/];
    setIsLoading(true);
    Promise.all(promises)
      .then((res) => {
        setStripeInfo(res[0].data);
        // setHeroes(res[1]);
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const { publishable_key } = stripeInfo;
  console.log(publishable_key);
  const stripePromise = loadStripe(publishable_key);

  const handleDonation = ({ paymentMethodId, cardDetail, values }) => {
    const {
      cardHolderName,
      country,
      address,
      donationCause,
      state,
      city,
      amount,
      donorName,
      tsHeroName,
      anonymous,
      email,
    } = values;

    const { exp_month, exp_year, funding, last4 } = cardDetail;
    const payload = {
      paymentMethodId: paymentMethodId,
      amount: amount,
      donorName: startCase(camelCase(donorName)),
      tsHeroName: startCase(camelCase(tsHeroName)),
      anonymous: startCase(camelCase(anonymous)),
      email: email,
      name: cardHolderName,
      donationCause: donationCause,
      card_details: {
        name: cardHolderName,
        country: country,
        address: address,
        state: state,
        city: city,
        last4: last4,
        expiry_month: exp_month,
        expiry_year: exp_year,
        funding: funding,
      },
    };

    const promises = [donation(payload)];
    setIsLoading(true);

    Promise.all(promises)
      .then((response) => {
        console.log('Res', response);
        notification['success']({
          message: 'Donation Successfull',
        });
      })
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
        history.go(0);
      });
  };
  const handleMainTabChange = (key: string) => {
    setTab(key);
  };

  return publishable_key ? (
    <Elements stripe={stripePromise}>
      <div
        style={{ marginTop: 100, marginLeft: 50 }}
        className={styles.vGoBackSaveBar}
      >
        <p onClick={() => history.push('/')} className={styles.vGoBackBtnBlock}>
          <span className={styles.vGoBackBtn}>
            <ArrowLeftOutlined /> Go Back
          </span>
        </p>
      </div>
      <Tabs
        defaultActiveKey={tab}
        className={styles.vCenterTabsNew}
        centered
        //id="vTabs1"
        id="ts_heroes"
        onChange={handleMainTabChange}
        // hideAdd={portfolioList.length >= 6}
      >
        <TabPane closable={false} tab={'TS HEROES'} key={'TS_HEROES'}></TabPane>
        <TabPane
          closable={false}
          tab={'YKR SCHOLARSHIP'}
          key={'YKR_SCHOOL'}
        ></TabPane>
      </Tabs>
      {tab === 'YKR_SCHOOL' ? (
        <div
          style={{ height: '75vh', overflow: 'auto', marginTop: 0 }}
          className={styles.donatePage}
        >
          <DonateForm
            handleDonation={handleDonation}
            setError={setError}
            isLoading={isLoading}
            error={error}
            setIsLoading={setIsLoading}
          />
        </div>
      ) : (
        <div
          style={{ height: '75vh', overflow: 'auto', marginTop: 0 }}
          className={styles.donatePageTS}
        >
          <TSDonateForm
            handleDonation={handleDonation}
            setError={setError}
            isLoading={isLoading}
            error={error}
            // heroes={heroes}
            setIsLoading={setIsLoading}
          />
        </div>
      )}
    </Elements>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '670px',
        width: '100%',
      }}
    >
      <BounceLoader color={green} loading={true} size={250} />
    </div>
  );
};

export default Donate;
