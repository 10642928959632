import React, { useEffect } from 'react';

import { Form, Row, Button } from 'antd';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';

import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {
  reportType: string;
  reportSubType: string;
  prevPage: string;
}

const ThinksabioDiamondsSearch: React.FC<Props> = ({
  history,
  reportType,
  reportSubType,
  prevPage,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (
      reportSubType === 'THINKSABIO_DIAMONDS' &&
      prevPage !== 'searchReport'
    ) {
      const filters = {
        type: reportType.toLowerCase(),
        subtype: reportSubType.toLowerCase(),
      };
      history.push({
        pathname:
          `/search/report/` +
          queryString.stringify(filters, {
            skipNull: true,
          }),
        state: { prevPage: 'search' },
      });
    }
  }, [history, prevPage, reportSubType, reportType]);

  const onFinish = (values) => {
    const filters = {
      type: reportType.toLowerCase(),
      subtype: reportSubType.toLowerCase(),
    };

    history.push({
      pathname:
        `/search/report/` +
        queryString.stringify(filters, {
          skipNull: true,
        }),

      state: { prevPage: 'search' },
    });
  };

  return (
    <Form
      name="price-filter-form"
      onFinish={onFinish}
      labelCol={{ span: 24, padding: 0 }}
      wrapperCol={{ span: 24 }}
      form={form}
      initialValues={{}}
      // onValuesChange={onValuesChange}
      // onFieldsChange={(field) =>
      //   setCurrentField(field[0] ? field[0].name[0] : '')
      // }
    >
      <Row span={24} justify="start" className={styles.buttonSectionBox}>
        <Form.Item colon={false} style={{ margin: 0, marginTop: 20 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};
export default ThinksabioDiamondsSearch;
