import momentBus from 'moment-business-days';
import moment from 'moment-timezone';
import { getHolidays, isHoliday } from 'nyse-holidays';
import { v4 as uuidv4 } from 'uuid';

// import { sectorColors } from './constants';

// export const getMonthYearName = (number: number) =>
//   moment().subtract(number, 'month').startOf('month').format('MMMM ') +
//   moment().subtract(number, 'month').startOf('month').format('YYYY ');

// export const getPrevious12Month = () =>
//   [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) =>
//     moment().subtract(month, 'month').startOf('month').format('MMMM '),
//   );

// const months = [
//   'December',
//   'November',
//   'October',
//   'September',
//   'August',
//   'July',
//   'June',
//   'May',
//   'April',
//   'March',
//   'February',
//   'January',
// ];
// const Quarters = (year: String) => {
//   const shortYear = year.slice(-2);
//   return [
//     `Q1(Jan'${shortYear} - Mar'${shortYear})`,
//     `Q2(Apr'${shortYear} - Jun'${shortYear})`,
//     `Q3(Jul'${shortYear} - Sept'${shortYear})`,
//     `Q4(Oct'${shortYear} - Dec'${shortYear})`,
//   ];
// };

// export const getMonths = (year: String) => {
//   const currentMonth = parseInt(moment().format('MM'));
//   const currentYear = moment().format('YYYY');
//   if (currentYear === year) {
//     return [...Array(currentMonth)]
//       .map((u, i) => i)
//       .map((month) =>
//         moment().subtract(month, 'month').startOf('month').format('MMMM'),
//       );
//   } else {
//     return months;
//   }
// };
// export const getQuarters = (year: String) => {
//   const currentQuarter = moment().quarter();
//   const currentYear = moment().format('YYYY');
//   if (currentYear === year) {
//     return Quarters(year)
//       .slice(0, currentQuarter - 1)
//       .reverse();
//   } else {
//     return Quarters(year).reverse();
//   }
// };

// export const getPrevious3Years = () =>
//   [0, 1, 2].map((year) => moment().subtract(year, 'year').format('YYYY'));

export const getPrecision = (value: number | String, precision?: number) => {
  const number = Number(value);
  return Number.isInteger(number)
    ? 0
    : precision
    ? Math.abs(number.toFixed(2)) > 0
      ? 2
      : Math.abs(number.toFixed(3)) > 0
      ? 3
      : Math.abs(number.toFixed(4)) > 0
      ? 4
      : 5
    : 2;
};

export const getPrecision3 = (value: number | String, precision?: number) => {
  const number = Number(value);
  let p = precision;
  if (number < 1 && number >= 0.01) {
    p = 2;
  }
  if (number < 0.01 && number >= 0.001) {
    p = 3;
  }
  if (number < 0.001 && number >= 0.0001) {
    p = 4;
  }
  return Number.isInteger(number)
    ? 0
    : precision && number < 1
    ? Math.min(number.toString().split('.')[1].length, p)
    : 2;
};

export const getPrecision2 = (value: number) => {
  const number = Number(value);
  return Number.isInteger(number) ? 0 : 2;
};

export const formatNumber = (num: number | String, precision?: number) => {
  const number = Number(num);
  if (num) {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: getPrecision(number, precision),
    }).format(number);
  } else if (num === '0' || num === 0) {
    return number;
  } else return '-';
};

export const formatNumber2 = (
  num: number | String,
  precision?: number,
  compact?: boolean,
) => {
  let number = Number(num);
  number = number.toFixed(2);
  if (num) {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: precision ? precision : getPrecision2(number),
      notation: compact ? 'compact' : 'standard',
    }).format(number);
  } else if (num === '0' || num === 0) {
    return number;
  } else return '-';
};

export const formatNumberWithPercent = (num: number | string) => {
  let number = Number(num);
  number = number.toFixed(2);
  if (num) {
    return `${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
    }).format(number)} %`;
  } else if (num === '0' || num === 0) {
    return `0 %`;
  } else return '-';
};
export const currencyFormat = (
  num: number | string,
  returnBlank?: boolean,
  returnNA?: boolean,
  precision?: number,
  show?: boolean,
  compact?: boolean,
) => {
  const number = Number(num);
  //  console.log('currencyFormat', number, getPrecision(number, precision));
  if (num) {
    const p = getPrecision(number, precision);
    if (show) {
      console.log('p', p, number);
    }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: Math.max(p, 2),
      notation: compact ? 'compact' : 'standard',
    }).format(number);
  } else if (num === '0' || num === 0) {
    return num;
  } else if (returnBlank) {
    return;
  } else if (returnNA) {
    return 'N/A';
  } else return '-';
};
export const sharesCurrencyFormat = (
  num: number | string,
  returnBlank?: boolean,
  returnNA?: boolean,
  precision?: number,
  show?: boolean,
  compact?: boolean,
) => {
  const number = Number(num);
  //  console.log('currencyFormat', number, getPrecision(number, precision));
  if (num) {
    const p = getPrecision(number, precision);
    if (show) {
      console.log('p', p, number);
    }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: Math.max(p, 0),
      notation: compact ? 'compact' : 'standard',
    }).format(number);
  } else if (num === '0' || num === 0) {
    return num;
  } else if (returnBlank) {
    return;
  } else if (returnNA) {
    return 'N/A';
  } else return '-';
};

export const currencyFormat4 = (
  num: number | string,
  returnBlank?: boolean,
  returnNA?: boolean,
  precision?: number,
  show?: boolean,
) => {
  const number = Number(num);
  // console.log('currencyFormat4', number, getPrecision(number, precision));
  if (num) {
    const p = getPrecision(number, precision);
    if (show) {
      console.log('p', p, number);
    }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: Math.max(p, 2),
      notation: 'compact',
    }).format(number);
  } else if (num === '0' || num === 0) {
    return num;
  } else if (returnBlank) {
    return;
  } else if (returnNA) {
    return 'N/A';
  } else return '-';
};

export const currencyFormat2 = (
  num: number | string,
  precision: number,
  show?: boolean,
) => {
  const number = Number(num);
  // console.log('currencyFormat2', number, getPrecision(number, precision));
  if (num) {
    const p = getPrecision3(number, precision);
    if (show) {
      console.log('p', p, number);
    }
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: Math.max(p, 2),
    }).format(number);
  } else if (num === '0' || num === 0) {
    return num;
  } else return '-';
};
export const currencyFormat3 = (num: number | string, compact?: boolean) => {
  const number = Number(num);
  // console.log('currencyFormat3', number, getPrecision(number, precision));
  if (num) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      notation: compact ? 'compact' : 'standard',
    }).format(Math.round(number));
  } else if (num === '0' || num === 0) {
    return num;
  } else return '-';
};

// export const findSectorColor = (sector: String) => {
//   const sec = sectorColors.find((sec) => sec.value === sector);
//   return sec ? sec.color : '';
// };

export const birthYears = () => {
  const years = [];
  for (let i = 0; i < 100; i++) {
    years.push(moment().subtract(i, 'year').get('year'));
  }
  return years;
};

export const formatTableData = (data: Array<Object>) => {
  let formattedTableRowData = (data || []).map((dataRow) => ({
    key: `${uuidv4()}`,
    ...dataRow,
  }));
  return formattedTableRowData;
};

export const formatTableData2 = (dataSource: Array<[]>) => {
  let formattedTableRowData = (dataSource || []).map((dataRow) => {
    let data = {
      key: `${uuidv4()}`,
    };
    dataRow.forEach((row) => {
      const { key, value } = row;
      data[key] = value;
    });
    return data;
  });
  return formattedTableRowData;
};

// export const getNameInitials = (name: String) => {
//   let initials = name.match(/\b\w/g) || [];
//   initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
//   return initials;
// };

//export const sinceTime = (time: Date) => moment(time).fromNow();

// export const PlayNotificationSound = () => {
//   let button = document.createElement('BUTTON');
//   document.body.appendChild(button);
//   button.onclick = function () {
//     const audio = new Audio();
//     audio.src = swiftly;
//     // audio.autoplay = true;
//     audio.play();
//   };
//   button.click();

//   document.body.removeChild(button);
// };

export const getUpto4decimalValue = (value: number) =>
  (Math.floor(value * 10000) / 10000).toFixed(4);

// export const getLast5Dates = () => {
//   let current_day = moment().tz('America/New_York').day();
//   switch (current_day) {
//     case 0:
//       return [-6, -5, -4, -3, -2];
//     case 1:
//       return [-6, -5, -4, -3, 0];
//     case 2:
//       return [-6, -5, -4, -1, 0];
//     case 3:
//       return [-6, -5, -2, -1, 0];
//     case 4:
//       return [-6, -3, -2, -1, 0];
//     case 5:
//       return [-4, -3, -2, -1, 0];
//     case 6:
//       return [-5, -4, -3, -2, -1];
//   }
// };

export const getLast5Dates = (code) => {
  const values = [];
  let i = 0;
  while (values.length < 5 && i > -10) {
    let current_day = moment().tz('America/New_York').add(i, 'day');
    // console.log('current_day', current_day.format('YYYY-MM-DD'));
    const isBusinessDay = momentBus(current_day, 'YYYY-MM-DD').isBusinessDay();
    // console.log('isBusinessDay', isBusinessDay);
    if (!isBusinessDay) {
      i--;
    } else {
      const isNyseHoliday = isHoliday(moment(current_day).toDate());
      // console.log('isNyseHoliday', isNyseHoliday);
      if (isNyseHoliday && code !== 'SDY' && code !== 'DIL') {
        i--;
      } else {
        values.push(i);
        i--;
      }
    }
  }
  // console.log('values', values);
  return values.reverse();
};

const getCurrentQtrString = () => {
  const date = new Date();
  return date.getFullYear() + '_Q' + (Math.floor(date.getMonth() / 3) + 1);
};

const getLastQtrString = () => {
  const date = new Date();
  // If it's first qtr, pick last year's last qtr
  if (date.getMonth() < 3) {
    return date.getFullYear() - 1 + '_Q4';
  }
  return date.getFullYear() + '_Q' + Math.floor(date.getMonth() / 3);
};
const getLastQtrStringByCurrQtrStr = (quarter) => {
  const c = quarter.split('_Q');
  const year = +c[0];
  const qtr = +c[1];
  let lastqtr = '';
  if (qtr === 1) {
    lastqtr = year - 1 + '_Q4';
  } else if (qtr === 2) {
    lastqtr = year + '_Q1';
  } else if (qtr === 3) {
    lastqtr = year + '_Q2';
  } else if (qtr === 4) {
    lastqtr = year + '_Q3';
  }
  return lastqtr;
};

const getQtrEndDate = (quarter: string) => {
  const c = quarter.split('_Q');
  const year = +c[0];
  const qtr = +c[1];
  let mmddStr = '';
  if (qtr === 1) {
    mmddStr = '03-31';
  } else if (qtr === 2) {
    mmddStr = '06-30';
  } else if (qtr === 3) {
    mmddStr = '09-30';
  } else if (qtr === 4) {
    mmddStr = '12-31';
  }

  return year + '-' + mmddStr;
};

const getQtrStartDate = (quarter: string) => {
  if (!quarter) {
    quarter = getCurrentQtrString();
  }
  const c = quarter.split('_Q');
  const year = +c[0];
  const qtr = +c[1];
  let mmddStr = '';
  if (qtr === 1) {
    mmddStr = '01-01';
  } else if (qtr === 2) {
    mmddStr = '04-01';
  } else if (qtr === 3) {
    mmddStr = '07-01';
  } else if (qtr === 4) {
    mmddStr = '10-01';
  }

  return year + '-' + mmddStr;
};

export const getCurrentSECDateRange = () => {
  const currQtrStr = getCurrentQtrString();
  const lastQtrStr = getLastQtrStringByCurrQtrStr(currQtrStr);
  const lastQtrEndDate = getQtrEndDate(lastQtrStr);
  const currQtrEndDate = getQtrEndDate(currQtrStr);
  const currentDate = moment.utc();
  if (currentDate.diff(moment(lastQtrEndDate), 'days') < 45) {
    return {
      start: getQtrStartDate(lastQtrStr),
      end: moment(lastQtrEndDate).add(45, 'days').format('MMMM DD, YYYY'),
    };
  } else {
    return {
      start: getQtrStartDate(currQtrStr),
      end: moment(currQtrEndDate).add(45, 'days').format('MMMM DD, YYYY'),
    };
  }
};

// export const currencyFormatter = (value) => {
//   return new Intl.NumberFormat('en-us', {
//     style: 'currency',
//     currency: 'USD',
//   }).format(value);
// };

// export const currencyParser = (val) => {
//   try {
//     // for when the input gets clears
//     if (typeof val === 'string' && !val.length) {
//       val = '0.0';
//     }

//     // detecting and parsing between comma and dot
//     var group = new Intl.NumberFormat('en-us').format(1111).replace(/1/g, '');
//     var decimal = new Intl.NumberFormat('en-us').format(1.1).replace(/1/g, '');
//     var reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
//     reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
//     //  => 1232.21 €

//     // removing everything except the digits and dot
//     reversedVal = reversedVal.replace(/[^0-9.]/g, '');
//     //  => 1232.21

//     // appending digits properly
//     const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
//     const needsDigitsAppended = digitsAfterDecimalCount > 2;

//     if (needsDigitsAppended) {
//       reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
//     }

//     return Number.isNaN(reversedVal) ? 0 : reversedVal;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const NonSortableFieldsIIR = [
  'lastQtr1HedgeHeroStatus',
  'detail',
  'sharesChange',
  'changeStatus',
  'changeCountStatus',
];
export const NonSortableFields = [
  'lastQtr1HedgeHeroStatus',
  'detail',
  'sharesChange',
  'status',
  'specialStatus',
  'changeStatus',
  'changeCountStatus',
];

const getPreviousWorkingDate = (from, num) => {
  let r = momentBus(from, 'YYYY-MM-DD')
    .businessSubtract(num)
    .format('YYYY-MM-DD');
  // console.log('r', r);
  const holidays = [];
  const y1 = moment(r, 'YYYY-MM-DD').year();
  const y2 = moment(from, 'YYYY-MM-DD').year();
  holidays.push(...getHolidays(y1));
  if (y1 !== y2) {
    holidays.push(...getHolidays(y2));
  }
  holidays.push({
    name: 'Juneteenth',
    date: moment(`${moment().year()}-06-19T05:00:00.000Z`).tz(
      'America/New_York',
    ),
    dateString: `${moment().year()}-06-19`,
  });
  const counts = holidays.filter(
    (d) => d.date <= moment(from) && moment(d.date) >= moment(r),
  );
  // console.log('counts', counts);
  if (counts.length) {
    return momentBus(from, 'YYYY-MM-DD')
      .businessSubtract(num + counts.length)
      .format('YYYY-MM-DD');
  } else {
    return r;
  }
};

export const getNextWorkingDate = (from) => {
  let r = momentBus(from, 'YYYY-MM-DD').nextBusinessDay().format('YYYY-MM-DD');
  const holidays = [];
  const y1 = moment(r, 'YYYY-MM-DD').year();
  const y2 = moment(from, 'YYYY-MM-DD').year();
  holidays.push(...getHolidays(y1));
  if (y1 !== y2) {
    holidays.push(...getHolidays(y2));
  }
  holidays.push({
    name: 'Juneteenth',
    date: moment(`${moment().year()}-06-19T05:00:00.000Z`).tz(
      'America/New_York',
    ),
    dateString: `${moment().year()}-06-19`,
  });
  const counts = holidays.filter(
    (d) => d.date <= moment(r) && d.date >= moment(from),
  );
  if (counts.length) {
    return momentBus(from, 'YYYY-MM-DD')
      .businessSubtract(counts.length)
      .format('YYYY-MM-DD');
  } else {
    return r;
  }
};

const getQueryDate = (marketOpen = false, startAt4 = false) => {
  let queryDate = '';
  const tmz = 'America/New_York';
  const fmt = 'YYYY-MM-DD HH:mm:ss';

  const today = moment().tz(tmz).format('YYYY-MM-DD');

  const start = startAt4
    ? moment.tz(`${today} 04:00:00`, fmt, tmz)
    : moment.tz(`${today} 09:30:00`, fmt, tmz);
  const end = moment.tz(`${today} 23:59:59`, fmt, tmz);

  const currentDateTime = moment.tz(tmz);

  const isDay = currentDateTime.isBetween(start, end);
  const day = moment(today, 'YYYY-MM-DD').day();

  if (day === 0 || day === 6 || !isDay) {
    queryDate = momentBus(today, 'YYYY-MM-DD')
      .prevBusinessDay()
      .format('YYYY-MM-DD');
  } else {
    queryDate = today;
  }

  const holidays = getHolidays(moment(queryDate, 'YYYY-MM-DD').year());
  holidays.push({
    name: 'Juneteenth',
    date: moment(`${moment().year()}-06-19T05:00:00.000Z`).tz(
      'America/New_York',
    ),
    dateString: `${moment().year()}-06-19`,
  });
  // console.log('holidays', holidays);
  const counts = holidays.findIndex((d) =>
    moment(queryDate).isSame(d.date, 'day'),
  );
  // console.log('counts', counts);
  if (counts >= 0) {
    return momentBus(queryDate, 'YYYY-MM-DD')
      .prevBusinessDay()
      .format('YYYY-MM-DD');
  } else {
    return queryDate;
  }
};

export const getTimeRange = (range, startAt4) => {
  const queryDate = getQueryDate(true, startAt4);
  // console.log(
  //   'queryDate',
  //   queryDate,
  //   getHolidays(moment(queryDate, 'YYYY-MM-DD').year()),
  // );
  switch (range) {
    case '1D':
      return {
        end: queryDate,
        start: getPreviousWorkingDate(queryDate, 1),
      };
    case '5D':
      return {
        end: queryDate,
        start: getPreviousWorkingDate(queryDate, 5),
      };
    case '1M':
      return {
        end: queryDate,
        start: getNextWorkingDate(
          moment()
            .tz('America/New_York')
            .subtract(1, 'month')
            .format('YYYY-MM-DD'),
        ),
      };
    case '3M':
      return {
        end: queryDate,
        start: getNextWorkingDate(
          moment()
            .tz('America/New_York')
            .subtract(3, 'month')
            .format('YYYY-MM-DD'),
        ),
      };
    case '6M':
      return {
        end: queryDate,
        start: getNextWorkingDate(
          moment()
            .tz('America/New_York')
            .subtract(6, 'month')
            .format('YYYY-MM-DD'),
        ),
      };
    case '1Y':
      return {
        end: queryDate,
        start: getNextWorkingDate(
          moment()
            .tz('America/New_York')
            .subtract(1, 'year')
            .format('YYYY-MM-DD'),
        ),
      };
    case '3Y':
      return {
        end: queryDate,
        start: getNextWorkingDate(
          moment()
            .tz('America/New_York')
            .subtract(3, 'year')
            .format('YYYY-MM-DD'),
        ),
      };
    case 'YTD':
      return {
        end: queryDate,
        start: moment()
          .tz('America/New_York')
          .startOf('year')
          .add(2, 'day')
          .format('YYYY-MM-DD'),
      };
    default:
      return {
        end: '',
        start: '',
      };
  }
};

export const getTimeFrameFromDate = (startDate, endDate) => {
  let res = null;
  const timeArr = ['1D', '5D', '1M', '3M', '6M', '1Y', '3Y', 'YTD'];
  for (let i = 0; i < timeArr.length; i++) {
    const { end, start } = getTimeRange(timeArr[i]);
    if (
      moment(startDate).isSame(start, 'day') &&
      moment(endDate).isSame(end, 'day')
    ) {
      res = timeArr[i];
      break;
    }
  }
  return res;
};

const getCurrentQtrForDisplayingData = (skip45DaysConstraint) => {
  const lastQtrStr = getLastQtrString();
  const lastQtrEndDate = getQtrEndDate(lastQtrStr);
  const currentDate = moment.utc();
  if (
    !skip45DaysConstraint &&
    currentDate.diff(moment(lastQtrEndDate), 'days') < 45
  ) {
    return getLastQtrString();
  } else {
    return getCurrentQtrString();
  }
};

export const getLastQtrByIdx = (lastIdx, useCurrent, skip45DaysConstraint) => {
  let currQtr;
  if (useCurrent) {
    currQtr = getCurrentQtrForDisplayingData(skip45DaysConstraint);
  } else {
    currQtr = getCurrentQtrForDisplayingData();
  }

  const currYr = currQtr.split('_')[0];
  const currQtrIdx = currQtr.split('_')[1].split('Q')[1];

  let resultYr = '';
  let resultQtr = '';

  if (lastIdx < currQtrIdx) {
    resultYr = currYr;
    resultQtr = 'Q' + (currQtrIdx - lastIdx);
  } else {
    let yrsToSubtract =
      (lastIdx - currQtrIdx + 1) % 4 === 0
        ? Math.floor((lastIdx - currQtrIdx + 1) / 4)
        : Math.floor((lastIdx - currQtrIdx + 1) / 4) + 1;
    resultYr = +currYr - yrsToSubtract;
    const qtrVar = (lastIdx - currQtrIdx) % 4;
    switch (qtrVar) {
      case 0:
        resultQtr = 'Q4';
        break;
      case 1:
        resultQtr = 'Q3';
        break;
      case 2:
        resultQtr = 'Q2';
        break;
      case 3:
        resultQtr = 'Q1';
        break;
    }
  }

  return resultYr + '_' + resultQtr;
};
export const getDisplayLastQtrByIdx = (
  lastIdx,
  useCurrent,
  skip45DaysConstraint,
) => {
  let currQtr;
  if (useCurrent) {
    currQtr = getCurrentQtrForDisplayingData(skip45DaysConstraint);
  } else {
    currQtr = getCurrentQtrForDisplayingData();
  }

  const currYr = currQtr.split('_')[0];
  const currQtrIdx = currQtr.split('_')[1].split('Q')[1];

  let resultYr = '';
  let resultQtr = '';

  if (lastIdx < currQtrIdx) {
    resultYr = currYr;
    resultQtr = 'Q' + (currQtrIdx - lastIdx);
  } else {
    let yrsToSubtract =
      (lastIdx - currQtrIdx + 1) % 4 === 0
        ? Math.floor((lastIdx - currQtrIdx + 1) / 4)
        : Math.floor((lastIdx - currQtrIdx + 1) / 4) + 1;
    resultYr = +currYr - yrsToSubtract;
    const qtrVar = (lastIdx - currQtrIdx) % 4;
    switch (qtrVar) {
      case 0:
        resultQtr = 'Q4';
        break;
      case 1:
        resultQtr = 'Q3';
        break;
      case 2:
        resultQtr = 'Q2';
        break;
      case 3:
        resultQtr = 'Q1';
        break;
    }
  }

  return resultQtr + '_' + resultYr.toString().slice(-2);
};

export const isStudentUrl = (): boolean => {
  return window.location.hostname.split('.')[0].includes('student');
};

export const colRegexMatch = (c, regex, colName) => {
  const dm = c.match(regex);
  if (dm) {
    const qtr = getLastQtrByIdx(+dm[1]);
    const val =
      qtr.split('_')[1] + '-' + qtr.split('_')[0].substring(2) + colName;
    return val;
  }
  return c;
};
