import React, { useState, useEffect } from 'react';

import {
  Button,
  Form,
  Input,
  Radio,
  Select,
  Row,
  Col,
  InputNumber,
  message,
} from 'antd';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import { getUserFinancialInfo, saveFinancialInfo } from '../../graphql';
import { setFinancialData } from '../../redux/reducers/user';
import { green, errorHandler } from '../../utils';
import styles from './style.module.scss';

const { Option } = Select;
const { TextArea } = Input;

const FinancialProfile: React.FC<Props> = ({ isStudent }) => {
  const { financialInfo } = useSelector(
    ({ user }) => ({
      financialInfo: get(user, 'financialData', {}),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [savingData, setSavingData] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    const apis = [getUserFinancialInfo()];
    Promise.all(apis)
      .then((res) => {
        const info = get(res[0], 'financialInfo', {});
        // setFinancialInfo(info ? info : {});
        dispatch(setFinancialData(info ? info : {}));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  const onFinish = (values: {}) => {
    const payload = {
      expected_return_range: get(values, 'expectedReturnRange', ''),
      id: get(financialInfo, 'id', null),
      income_range: get(values, 'incomeRange', ''),
      investment_exp: get(values, 'investmentExp', ''),
      lost_money: get(values, 'lostMoney', ''),
      experiences: get(values, 'otherDetails', ''),
      investment_range: get(values, 'investmentRange', '').toFixed(2),
      has_portfolio: get(financialInfo, 'has_portfolio', false),
    };

    const apis = [saveFinancialInfo(payload)];
    setSavingData(true);
    Promise.all(apis)
      .then((data) => {
        message.success('Financial Info Updated');
        // setFinancialInfo(data[0].financialInfo);
        dispatch(setFinancialData(data[0].financialInfo));
        // if (closeModal) {
        //   closeModal();
        // }
      })
      .catch(errorHandler)
      .finally(() => {
        setSavingData(false);
      });
  };

  const getInitialValues = () => {
    return {
      incomeRange: get(financialInfo, 'income_range', ''),
      expectedReturnRange: get(financialInfo, 'expected_return_range', ''),
      investmentRange: get(financialInfo, 'investment_range', ''),
      investmentExp: get(financialInfo, 'investment_exp', ''),
      lostMoney: get(financialInfo, 'lost_money', ''),
      otherDetails: get(financialInfo, 'experiences', ''),
      // hasPortfolio: get(financialInfo, 'has_portfolio', false),
    };
  };

  // const isHasPortfolioDisabled = () => {
  //   // const { has_portfolio } = financialInfo;
  //   // return has_portfolio === true || has_portfolio === false ? true : false;
  //   return false;
  // };

  return (
    <div className={styles.clientContainer}>
      <Row justify="space-between" style={{ marginBottom: 20 }}>
        <Col>
          <div className={styles.searchPaneTitle}>
            <h2>Financial info</h2>
            <p>This will help us to give you better recommendations</p>
          </div>
          {isLoading && isEmpty(financialInfo) ? (
            <div className={styles.loaderStyle}>
              <BounceLoader color={green} loading={isLoading} size={150} />
            </div>
          ) : (
            <Form
              onFinish={onFinish}
              layout="vertical"
              name="financial-info-form"
              initialValues={getInitialValues()}
              id="vFormId"
            >
              <Form.Item
                label="Income Range"
                name="incomeRange"
                rules={[
                  { required: true, message: 'Income Range is required' },
                ]}
              >
                <Select
                  placeholder="Income Range"
                  className={styles.vFinancialForm}
                >
                  <Option value="Below $5,000">Below $5,000</Option>
                  <Option value="$5,000-$10,000">$5,000-$10,000</Option>
                  <Option value="Above $10,000">Above $10,000</Option>
                  <Option value="none">None</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Expected Return Range"
                name="expectedReturnRange"
                rules={[
                  {
                    required: true,
                    message: 'Expected Return Range is required',
                  },
                ]}
              >
                <Select
                  placeholder="Expected Return Range"
                  //style={{ width: 400 }}
                  className={styles.vFinancialForm}
                >
                  <Option value="Below $5,000">Below $5,000</Option>
                  <Option value="$5,000-$10,000">$5,000-$10,000</Option>
                  <Option value="Above $10,000">Above $10,000</Option>
                  <Option value="none">None</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Investment Range"
                name="investmentRange"
                rules={[
                  { required: true, message: 'Investment Range is required' },
                ]}
              >
                <InputNumber
                  // type="number"
                  // defaultValue={1000}
                  min={0}
                  placeholder="Investment Range"
                  formatter={(n) => {
                    if (n?.toString().indexOf('.') > -1) {
                      const [p1, p2] = n?.toString().split('.');
                      let a = `$ ${p1}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                      if (p2) {
                        const b =
                          p2.length > 2
                            ? p2.toString().substring(0, 2)
                            : p2.toString();
                        a = a + '.' + b;
                      } else {
                        a = a + '.';
                      }
                      return a;
                    } else {
                      return `$ ${n}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    }
                  }}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  //style={{ width: 400 }}
                  className={styles.vFinancialForm}
                />
              </Form.Item>

              {/* <Form.Item
              label="Are you currently holding any stocks in any other platform?"
              name="hasPortfolio"
              rules={[
                {
                  required: true,
                  message: 'Select an option',
                },
              ]}
            >
              <Radio.Group disabled={isHasPortfolioDisabled()}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item> */}

              <Form.Item name="investmentExp" label="Investment Experience">
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="lostMoney"
                label="Have you ever lost money in Stocks"
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="otherDetails"
                className={styles.financialTextArea}
              >
                <TextArea
                  rows={4}
                  placeholder="Care to share your experience"
                />
              </Form.Item>
              <Form.Item className={styles.financialBtn}>
                <Button
                  //style={{ width: 200 }}
                  type="primary"
                  loading={savingData}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FinancialProfile;
