import React from 'react';

import {
  UserOutlined,
  LockOutlined,
  GoogleOutlined,
  ArrowLeftOutlined,
  // createFromIconfontCN,
} from '@ant-design/icons';
import { Form, Input, Button, Row, Col } from 'antd';
import { Link } from 'react-router-dom';

import { isStudentUrl, red } from '../../utils';
import styles from './styles.module.scss';

// const IconFont = createFromIconfontCN({
//   scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
// });

interface Props {
  setFormType: (val: any) => void;
  onFinish: (val: any) => void;
  setError: (val: any) => void;
  error: string;
  isLoading: boolean;
  googleSignIn: (val: any) => void;
  facebookSignIn: (val: any) => void;
  history: Object;
}
const isStudent = isStudentUrl();

const LogInForm: React.FC<Props> = ({
  // setFormType,
  onFinish,
  setError,
  error,
  isLoading,
  googleSignIn,
  facebookSignIn,
  history,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <div className={styles.loginImageContent}>
          <img
            alt="logo"
            src={
              'https://thinksabio-asset.s3.amazonaws.com/ThinkSabio_signup_edited.png'
            }
          />
          {/* <div className={styles.imageBottomOntext}>
            <h4>ThinkSabio</h4>
          </div> */}
        </div>
        <div className={styles.loginFormContent}>
          <div className={styles.logoContent}>
            <h4>
              <Link to="/">
                {' '}
                <ArrowLeftOutlined />{' '}
              </Link>
            </h4>
            <div className={styles.logoImage}>
              <Link to="/">
                {' '}
                <img
                  alt="logo"
                  src="https://thinksabio-asset.s3.amazonaws.com/TSLOGO.png"
                />
              </Link>
            </div>
          </div>
          <div className={styles.formContentBox}>
            <Form
              name="normal_login"
              className={styles.loginForm}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onValuesChange={() => setError('')}
            >
              <span style={{ color: red }}>{error}</span>
              <Form.Item
                name="username"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Email!',
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Enter Email" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                ]}
                style={{ marginBottom: 10 }}
              >
                <Input
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Enter Password"
                />
              </Form.Item>
              <p className={styles.forgetPasswordtext}>
                <Link to="/forgot-password"> Forgot password? </Link>
              </p>
              <Form.Item>
                <Button
                  // type="primary"
                  // htmlType="submit"
                  // className={styles.loginFormButton}
                  // loading={isLoading}
                  htmlType="submit"
                  loading={isLoading}
                >
                  Login
                </Button>
              </Form.Item>
              {!isStudent && (
                <>
                  <p className={styles.ortext}>
                    <hr />
                    <span>OR</span>
                    <hr />
                  </p>
                  <Form.Item>
                    <Row span={24} justify="end">
                      {/* <Col span={12}>
                    <Button
                      size="large"
                      style={{ width: '90%', marginRight: '10%' }}
                      onClick={facebookSignIn}
                    >
                      <IconFont type="icon-facebook" /> Facebook
                    </Button>
                  </Col> */}
                      <Col span={24}>
                        <Button
                          size="large"
                          className={styles.googleBtn}
                          onClick={googleSignIn}
                        >
                          <GoogleOutlined />
                          Log In with Google
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </>
              )}

              <Form.Item>
                <Row span={24} justify="end">
                  <Col span={24}>
                    <p className={styles.anAccountYet}>
                      Don’t have an account yet?
                      <Link to="/sign-up"> Create Account</Link>
                    </p>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogInForm;
