import React, { useState, useEffect } from 'react';

import { Typography } from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { getRecentPayments } from '../../api/RestApi';
import { Table } from '../../components';
import { setRecentPaymentData } from '../../redux/reducers/user';
import { useTheme } from '../../ThemeContext';
import { errorHandler, formatColumn, currencyFormat } from '../../utils';
import { paymentHistoryColumns } from './Payments.constants';
import styles from './style.module.scss';

const { Title } = Typography;
interface Props {
  customer: Object;
}
interface PaymentMethod {
  card: {
    brand: string;
    last4: string;
  };
}

interface PaymentType {
  invoice: string;
  amount: number;
  description: string;
  status: string;
  date: Date;
  payment_method: PaymentMethod;
}
const PaymentHistory: React.FC<Props> = ({ customer }) => {
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const { recentPayments } = useSelector(
    ({ user }) => ({
      recentPayments: get(user, 'recentPayments', []),
    }),
    shallowEqual,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getRecentPayments({
      customerId: get(customer, 'stripe_customer_id', ''),
    })
      .then((res) => {
        const formattedData = get(res, 'data', []).map(
          (payment: PaymentType) => ({
            invoiceId: get(payment, 'invoice', ''),
            amount: currencyFormat(
              get(payment, 'amount', 0) / 100,
              false,
              false,
            ),
            description: get(payment, 'description', ''),
            date: moment(get(payment, 'date', '')).format('DD MMM YYYY'),
            status: get(payment, 'status', '').toUpperCase(),
            payment_card: `${get(
              payment,
              'payment_method.card.brand',
              '',
            )}_${get(payment, 'payment_method.card.last4', '')}`.toUpperCase(),
          }),
        );
        dispatch(setRecentPaymentData(formattedData));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [customer, dispatch]);
  return (
    <>
      {recentPayments.length > 0 && (
        <>
          <Title level={3} style={{ marginBottom: 5 }}>
            Recent Payments
          </Title>

          <Table
            // columns={paymentHistoryColumns}
            columns={formatColumn(
              paymentHistoryColumns,
              true,
              [],
              null,
              null,
              null,
              null,
              null,
              null,
              theme,
            )}
            dataSource={recentPayments}
            scroll={{ y: 500 }}
            loading={isLoading}
            pagination={false}
            size="small"
            bordered={true}
            rowClassName={styles.tableHeader}
            className={styles.TabularData}
            id="allPicksId"
          />
        </>
      )}
    </>
  );
};

export default PaymentHistory;
