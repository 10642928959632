import React from 'react';

import { Modal, Tabs } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';

import styles from '../style.module.scss';
import AddRealStock from './AddRealStock';
import DeleteRealStocks from './DeleteRealStocks';
import EditRealStock from './EditRealStock';
import MoveStock from './MoveStock';

const { TabPane } = Tabs;

interface Props {
  handleCancel: Function;
  visible: boolean;
  data: Array<{}>;
  activeKey: string;
  portfolioName: string;
}

const UpdateRealStock: React.FC<Props> = ({
  visible,
  handleCancel,
  data,
  activeKey,
  portfolioName,
}) => {
  const { portfolioList } = useSelector(
    ({ portfolio }: any) => ({
      portfolioList: get(portfolio, `portfolios`, []),
    }),
    shallowEqual,
  );
  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={null}
      bodyStyle={{ minHeight: 300, paddingTop: 0 }}
      className={styles.vPortFoiloTypeN2}
      width={800}
    >
      <Tabs defaultActiveKey={activeKey} centered>
        <TabPane tab="Add" key="add">
          <AddRealStock
            portfolioName={portfolioName}
            handleCancel={handleCancel}
            data={data}
          />
        </TabPane>
        {data.length > 0 && (
          <TabPane tab="Edit" key="edit">
            <EditRealStock
              data={data}
              handleCancel={handleCancel}
              portfolioName={portfolioName}
            />
          </TabPane>
        )}
        {portfolioList.length > 1 && data.length > 0 && (
          <TabPane tab="Move" key="move">
            <MoveStock
              data={data}
              handleCancel={handleCancel}
              portfolioList={portfolioList}
              portfolioName={portfolioName}
            />
          </TabPane>
        )}
        {data.length > 0 && (
          <TabPane tab="Delete" key="delete">
            <DeleteRealStocks
              data={data}
              handleCancel={handleCancel}
              portfolioName={portfolioName}
            />
          </TabPane>
        )}
      </Tabs>
    </Modal>
  );
};

export default UpdateRealStock;
