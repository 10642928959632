import { allStatusOptions } from '../../utils';

export const alertFormFields = (type) => {
  const list = [
    {
      label: 'Symbols',
      type: 'input',
      size: 24,
      visible:
        type === 'price' ||
        type === 'sec13dg' ||
        type === 'quarterly' ||
        type === 'dow' ||
        type === 'volume',
    },

    {
      label: 'Institution',
      type: 'input',
      size: 24,
      visible: type === 'sec13dg' || type === 'quarterly',
    },
    // {
    //   label: 'Sector',
    //   type: 'sectorDropdown',
    //   name: 'sector',
    //   data: [],
    //   placeholder: 'Sector',
    //   size: 12,
    //   visible:
    //     type === 'price' ||
    //     type === 'sec13dg' ||
    //     type === 'quarterly' ||
    //     type === 'dow' ||
    //     type === 'volume',
    // },
    {
      label: 'Inst Share #',
      type: 'objectDropdown',
      name: 'instShareCount',
      data: allStatusOptions,
      placeholder: 'Inst share #',
      size: 12,
      visible: type === 'sec13dg' || type === 'quarterly',
    },
    {
      label: 'Inst Counts #',
      type: 'objectDropdown',
      name: 'instCount',
      data: allStatusOptions,
      placeholder: 'Inst Counts #',
      size: 12,
      visible: type === 'quarterly',
    },
    // {
    //   label: 'Invested Dollars',
    //   type: 'range',
    //   name: 'investedDollars',
    //   placeholder: 'Invested Dollars',
    //   size: 12,
    // },
    {
      label: 'Dow Weeks',
      type: 'dropdown',
      name: 'dowWeeks',
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      placeholder: 'Dow weeks',
      size: 12,
      visible: type === 'dow',
    },
    // {
    //   name: 'minPrice',
    //   placeholder: 'Min Price',
    //   label: 'Min Price',
    //   type: 'range',
    //   allowDecimal: true,
    //   visible: type === 'price',
    // },
    // {
    //   name: 'maxPrice',
    //   placeholder: 'Max Price',
    //   label: 'Max Price',
    //   allowDecimal: true,
    //   type: 'range',
    //   visible: type === 'price',
    // },
    {
      name: 'price',
      placeholder: 'Price',
      label: 'Price',
      allowDecimal: true,
      type: 'range',
      visible: type === 'price',
    },
    {
      name: 'minVolume',
      placeholder: 'Min Volume',
      label: 'Min Volume',
      allowDecimal: true,
      type: 'range',
      visible: type === 'volume',
    },
  ];
  return list.filter((d) => d.visible);
};

export const alertAdvancedFormFields = [];

export const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Symbols',
    dataIndex: 'symbols',
    key: 'symbols',
  },
  {
    title: 'Institutions',
    dataIndex: 'institutions',
    key: 'institutions',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Last Modified Date',
    dataIndex: 'last_modified_at',
    key: 'last_modified_at',
  },
  {
    title: 'Transaction Value',
    dataIndex: 'trxn_val_min',
    key: 'trxn_val_min',
  },
  {
    title: 'Action',
    dataIndex: 'edit_delete',
    key: 'edit_delete',
  },
  // {
  //   title: 'Action',
  //   dataIndex: 'delete',
  //   key: 'delete',
  // },
];

export const ALERT_TYPES = {
  SEC13DG: 'SEC13DG',
  INSIDER: 'INSIDER',
};
