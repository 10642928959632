/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';

import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import { portFolioSearchBySector } from '../../../graphql';
import { green, errorHandler } from '../../../utils';
import HealthCheckTable from './HealthCheckTable';
import styles from './style.module.scss';

interface Props {
  history: Object;
  portfolioType: string;
  fetchData: any;
  portfolioName: string;
}

const HealthCheckBySector: React.FC<Props> = ({
  history,
  portfolioType,
  fetchData,
  portfolioName,
}) => {
  const dispatch = useDispatch();
  const arrangeColumns = (columns = {}) => {
    const formattedColumns = Object.keys(columns).map((col, index) => ({
      key: col,
      name: col,
      value: columns[col],
      visible: true,
      index: col === 'symbol' || col === 'symbols' ? 0 : index + 1,
    }));
    return formattedColumns;
  };

  const [tableData, setTableData] = useState(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [tableColumns, setTableColumns] = useState();
  const [fetchPortfolioData] = useState<Date>(new Date());
  useEffect(() => {
    setLoadingData(true);
    const apis = [
      portFolioSearchBySector({ type: portfolioType, name: portfolioName }),
    ];
    Promise.all(apis)
      .then((res) => {
        setTableData(res[0]);
        setTableColumns(arrangeColumns(get(res[0], 'columns')));
      })
      .catch(errorHandler)
      .finally(() => {
        setLoadingData(false);
      });
  }, [dispatch, fetchPortfolioData, portfolioType, fetchData, portfolioName]);

  return (
    <div style={{ minHeight: 400 }} id="healthCheck">
      {loadingData ? (
        <div className={styles.loaderStyle}>
          <BounceLoader color={green} loading={loadingData} size={150} />
        </div>
      ) : (
        <>
          <HealthCheckTable
            data={tableData}
            selectedKey={2}
            isLoading={loadingData}
            history={history}
            setTableColumns={setTableColumns}
            tableColumns={tableColumns}
            healthCheckType="sector"
          />
        </>
      )}
    </div>
  );
};

export default HealthCheckBySector;
