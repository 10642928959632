import { get } from 'lodash';

import initialState from '../initialState';
// Actions
const SET_USER_PROFILE = 'user/SET_USER_PROFILE';
const SET_USER_INFO = 'user/SET_USER_INFO';
const SET_LOGGED_IN_USER_INFO = 'user/SET_LOGGED_IN_USER_INFO';
const RESET_USER = 'user/RESET_USER';
const SET_STRIPE_DATA = 'user/SET_STRIPE_DATA';
const SET_USER_ALERTS = 'user/SET_USER_ALERTS';
const SET_PICK_URL = 'user/SET_PICK_URL';
const SET_JOIN_TELEGRAM_LINK = 'user/SET_JOIN_TELEGRAM_LINK';
const SET_TELEGRAM_GROUPS = 'user/SET_TELEGRAM_GROUPS';
const SET_FINANCIAL_INFO = 'user/SET_FINANCIAL_INFO';
const SET_PERSONAL_INFO = 'user/SET_PERSONAL_INFO';
const SET_WALLET_BALANCES = 'user/SET_WALLET_BALANCES';
const SET_RECENT_PAYMENT_DATA = 'user/SET_RECENT_PAYMENT_DATA';
const SET_STUDENT_INFO = 'user/SET_STUDENT_INFO';

interface LOGGED_IN_USER {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  phone_number: string;
  phone_number_verified: boolean;
  sub: string;
  type: string;
}

type ACTION_TYPE =
  | { type: 'user/SET_USER_PROFILE'; payload: Object }
  | { type: 'user/SET_USER_INFO'; payload: Object }
  | { type: 'user/SET_LOGGED_IN_USER_INFO'; payload: LOGGED_IN_USER }
  | { type: 'user/SET_STRIPE_DATA'; payload: LOGGED_IN_USER }
  | { type: 'user/SET_USER_ALERTS'; payload: any }
  | { type: 'user/SET_PICK_URL'; payload: string }
  | { type: 'user/SET_TELEGRAM_GROUPS'; payload: Object }
  | { type: 'user/SET_JOIN_TELEGRAM_LINK'; payload: string }
  | { type: 'user/SET_RECENT_PAYMENT_DATA'; payload: Object }
  | { type: 'user/SET_FINANCIAL_INFO'; payload: Object }
  | { type: 'user/SET_PERSONAL_INFO'; payload: Object }
  | { type: 'user/SET_WALLET_BALANCES'; payload: Object }
  | { type: 'user/SET_STUDENT_INFO'; payload: Object }
  | { type: 'user/RESET_USER' };

// Reducer
export default function reducer(
  state = initialState.user,
  action: ACTION_TYPE,
) {
  switch (action.type) {
    case SET_LOGGED_IN_USER_INFO:
      return {
        ...state,
        loggedInUser: action.payload,
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case SET_STRIPE_DATA:
      return {
        ...state,
        stripeInfo: action.payload,
      };
    // case SET_USER_CHAT_TOKEN:
    //   return {
    //     ...state,
    //     chatToken: action.payload,
    //   };
    case SET_USER_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      };
    case SET_PICK_URL:
      return {
        ...state,
        pages: {
          ...state.pages,
          ourPicks: action.payload,
        },
      };
    case SET_JOIN_TELEGRAM_LINK:
      return {
        ...state,
        joinTelegramLink: action.payload,
      };
    case SET_TELEGRAM_GROUPS:
      return {
        ...state,
        telegramGroups: get(action, 'payload.data', []),
      };
    case SET_RECENT_PAYMENT_DATA:
      return {
        ...state,
        recentPayments: action.payload,
      };
    case SET_FINANCIAL_INFO:
      return {
        ...state,
        financialData: action.payload,
      };
    case SET_PERSONAL_INFO:
      return {
        ...state,
        personalData: action.payload,
      };
    case SET_WALLET_BALANCES:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          [action.payload.type]: action.payload.data,
        },
      };
    case SET_STUDENT_INFO:
      return {
        ...state,
        studentInfo: action.payload,
      };
    case RESET_USER:
      return {};
    default:
      return state;
  }
}

// Action Creators

export function setUserProfile(data: Object) {
  return {
    type: SET_USER_PROFILE,
    payload: data,
  };
}

// export function setUserInfo(data: Object) {
//   return {
//     type: SET_USER_INFO,
//     payload: data,
//   };
// }

export function setLoggedInUserInfo(data: LOGGED_IN_USER) {
  return {
    type: SET_LOGGED_IN_USER_INFO,
    payload: data,
  };
}

export function resetUser() {
  return {
    type: RESET_USER,
  };
}

export function setStripeInfo(data) {
  return {
    type: SET_STRIPE_DATA,
    payload: data,
  };
}

// export function setUserChatToken(data) {
//   return {
//     type: SET_USER_CHAT_TOKEN,
//     payload: data,
//   };
// }

export function setUserAlerts(data) {
  return {
    type: SET_USER_ALERTS,
    payload: data,
  };
}

// export function setOurPickURL(data) {
//   return {
//     type: SET_PICK_URL,
//     payload: data,
//   };
// }

export function setJoinTelegramLink(data) {
  return {
    type: SET_JOIN_TELEGRAM_LINK,
    payload: data,
  };
}

export function setTelegramGroups(data) {
  return {
    type: SET_TELEGRAM_GROUPS,
    payload: data,
  };
}

export function setFinancialData(data: Object) {
  return {
    type: SET_FINANCIAL_INFO,
    payload: data,
  };
}

export function setPersonalData(data: Object) {
  return {
    type: SET_PERSONAL_INFO,
    payload: data,
  };
}

export function setRecentPaymentData(data: Object) {
  return {
    type: SET_RECENT_PAYMENT_DATA,
    payload: data,
  };
}

export function setUserWalletBalance(data: Object, type) {
  return {
    type: SET_WALLET_BALANCES,
    payload: { type: type, data: data },
  };
}

export function setStudentInfo(data: Object) {
  return {
    type: SET_STUDENT_INFO,
    payload: data,
  };
}
