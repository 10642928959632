import React, { useEffect, useContext, useState } from 'react';

import { Input, Row } from 'antd';
import { get } from 'lodash';

import { Table, Pagination } from '../../components';
import { getWeeklyGainsData } from '../../graphql';
import { useTheme } from '../../ThemeContext';
import { errorHandler, formatColumn, formatTableData } from '../../utils';
import { SearchReportContext } from './SearchReportContext';
import styles from './style.module.scss';

const WeeklyGainsDataView = () => {
  const { history } = useContext(SearchReportContext);
  const { theme } = useTheme();
  const [loading, setLoading] = useState();
  const [weeklyGainData, setWeeklyGainData] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortDetail, setSortDetail] = useState([]);
  const [searchedText, setSearchedText] = useState('');
  useEffect(() => {
    setLoading(true);
    const promises = [
      getWeeklyGainsData({
        filters: {},
        searchText: searchedText,
        sort: sortDetail,
        page: pageNo,
        size: pageSize,
      }),
    ];
    Promise.all(promises)
      .then((res) => {
        setWeeklyGainData(res[0]);
      })
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  }, [pageNo, pageSize, sortDetail, searchedText]);

  const tableColumns = get(weeklyGainData, 'columns', []);
  const tableData = get(weeklyGainData, 'data', []);
  const total = get(weeklyGainData, 'total', 0);

  const formatColumnsToArray = (columns: Object) => {
    const formattedColumns = Object.keys(columns).map((col, index) => ({
      key: col,
      name: col,
      value: columns[col],
      visible: true, //findColumnVisibility(col),
      index: col === 'symbol' ? 0 : index + 1,
      sort: false,
      // col !== 'status' || col !== 'specialStatus' || col !== 'changeStatus',
    }));
    return formattedColumns;
  };

  const onPaginationChange = (page: number, pageSize: number) => {
    changePageSize(pageSize);
    changePageNo(page);
  };

  const changePageNo = (newPageNo: number) => {
    setPageNo(newPageNo);
  };
  const changePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    let index = sortDetail.findIndex((d) => d.sortBy === field);
    if (index > -1) {
      let newOrder =
        order === 'ascend' ? 'ASC' : order === 'descend' ? 'DESC' : null;
      handleSort(field, newOrder, false);
    } else {
      if (order === 'ascend') {
        handleSort(field, 'ASC', false);
      } else if (order === 'descend') {
        handleSort(field, 'DESC', false);
      } else {
        handleSort(field, null, false);
      }
    }
  };

  const handleSort = (sortBy: string, sortOrder: string, multi: boolean) => {
    if (multi) {
      let newSort = sortDetail;
      if (sortOrder) {
        newSort = sortDetail.filter((s) => s.sortBy !== sortBy);
        newSort.push({
          sortBy: sortBy,
          sortOrder: sortOrder,
        });
      } else {
        newSort = sortDetail.filter((s) => s.sortBy !== sortBy);
      }
      setSortDetail([...newSort]);
    } else {
      if (sortOrder) {
        setSortDetail([
          {
            sortBy: sortBy,
            sortOrder: sortOrder,
          },
        ]);
      } else {
        setSortDetail([]);
      }
    }
    // setFetchData(new Date());
  };

  const onChange = (event) => {
    const { value } = event.target;
    setSearchedText(value);
  };

  return (
    <>
      <div style={{ marginRight: 20, width: '150px' }}>
        <Input
          placeholder="search..."
          onChange={onChange}
          className={styles.VdailySearch}
          allowClear
        />
      </div>
      {weeklyGainData && (
        <>
          <Row justify="space-between">
            <h3
              className={styles.sectorComponent}
              style={{ margin: 'auto', marginBottom: '10px' }}
            >
              {`Overview of Symbols Weekly Gain `}
            </h3>
          </Row>
          <Table
            style={{
              width: '400px',
              margin: 'auto',
              height: '420px',
              overflowX: 'auto',
            }}
            loading={loading}
            columns={formatColumn(
              formatColumnsToArray(tableColumns),
              true,
              sortDetail,
              history,
              false,
              null,
              'WEEKLY_GAINS_REPORT',
              null,
              null,
              theme,
            )}
            // scroll={{ y: y }}
            dataSource={formatTableData(tableData)}
            pagination={false}
            size="small"
            bordered={true}
            onChange={onTableChange}
            rowClassName={styles.tableHeader}
            className={styles.TabularData}
            showSorterTooltip={false}
            id="allPicksId"
          />
          <Pagination
            pageSize={pageSize}
            total={total}
            pageNo={pageNo}
            onChange={onPaginationChange}
            loading={loading}
            showPagination={true}
            size={'small'}
          />
        </>
      )}
    </>
  );
};

export default WeeklyGainsDataView;
