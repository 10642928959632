import React, { useEffect, useState } from 'react';

import {
  WarningOutlined,
  InfoCircleOutlined,
  DownOutlined,
  SettingOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { Row, Col, Modal, Dropdown, Button, Menu, Form, Radio } from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import {
  PaymentCard,
  UpdatePaymentCard,
  SubscribePlanModal,
} from '../../components';
import { isReferralBoolean } from '../../graphql';
// import { useTheme } from '../../ThemeContext';
import { orange, green, red } from '../../utils';
import PaymentHistory from './PaymentHistory';
// import PlanCard from './PlanCard';
import styles from './style.module.scss';

// import UpdateCard from './UpdateCardInfo';
interface StripePriceType {
  interval: string;
  price: string;
}
interface Customer {
  interval: string;
  subscription_status: string;
  is_cancelled: boolean;
  current_period_end: string;
}
interface Props {
  cancelSubscription: Function;
  stripePrices: Array<StripePriceType>;
  selectedPlan: { price: string; interval: string };
  customer: Customer;
  updateSubscription: Function;
}
const PaymentProfilePage: React.FC<Props> = ({
  cancelSubscription,
  stripePrices,
  selectedPlan,
  customer,
  updateSubscription,
}) => {
  useEffect(() => {
    setIsReferralLoading(true);
    getReferralBoolean().then((res) => {
      console.log('res', res.referral_to_claimed);
      setIsReferral(!res.referral_to_claimed);
      const bal = res.creditBalance;
      // const bal = -999;
      setBalance(bal !== 0 ? bal / 100 : 0);
      setIsReferralLoading(false);
    });
  }, []);
  // const { theme } = useTheme();
  const [form] = Form.useForm();
  const [openUpdateCard, setOpenUpdateCard] = useState(false);
  const [balance, setBalance] = useState(0);
  const [openViewCard, setOpenViewCard] = useState(false);
  const [subscribePlanType, setSubscribePlanType] = useState();
  const [isReferralLoading, setIsReferralLoading] = useState(true);
  const [isReferral, setIsReferral] = useState(false);

  const {
    interval,
    subscription_status,
    is_cancelled = false,
    current_period_end,
    stripe_customer_id,
  } = customer;
  // const { price } = selectedPlan;
  //let planText = '';
  // let activePlanType = '';
  // if (subscription_status === 'TRIAL') {
  //   activePlanType = 'trial';
  // }
  //  if (subscription_status === 'ACTIVE') {
  // if (interval === 'MONTHLY') {
  //   activePlanType = 'monthly';
  // }
  // if (interval === 'YEARLY') {
  //   activePlanType = 'yearly';
  // }
  // }
  const { personalData } = useSelector(
    ({ user }) => ({
      personalData: get(user, 'personalData', {}),
    }),
    shallowEqual,
  );
  const email = get(personalData, 'email', '');
  const getReferralBoolean = async () => {
    return await isReferralBoolean({ email: email });
  };
  const getNewPlanPriceId = () => {
    let newPlan = [];
    if (interval === 'MONTHLY') {
      newPlan = stripePrices.find(
        (plan) => plan.interval === 'YEARLY' && !plan.is_new,
      );
    } else {
      newPlan = stripePrices.find(
        (plan) => plan.interval === 'MONTHLY' && !plan.is_new,
      );
    }

    return newPlan.stripe_price_id;
  };

  const changePlan = () =>
    Modal.confirm({
      title: interval === 'MONTHLY' ? 'Upgrade Plan' : 'Change Plan',
      content: (
        <p>
          {interval === 'MONTHLY'
            ? 'Are you sure you want to upgrade to yearly plan ?'
            : 'Are you sure you want to switch to monthly plan ?'}
        </p>
      ),
      onOk: () => updateSubscription(getNewPlanPriceId()),
      okText: 'Confirm',
    });

  const onFinish = (values) => {
    const { reason, otherInput } = values;
    if (
      (!reason && !otherInput) ||
      (reason === 'Other' && !otherInput) ||
      (reason !== 'Other' && otherInput)
    ) {
      cancelPlan(true, true);
      return;
    } else {
      const cancellationReason = reason ? reason : otherInput;
      cancelPlan(false, false);
      cancelSubscription(false, cancellationReason);
      Modal.destroyAll();
    }
  };

  const cancelPlan = (modalVisible: boolean, reasonError) => {
    Modal.confirm({
      title: 'Cancel Subscription',
      width: '700px',
      visible: modalVisible,
      content: (
        <>
          <p style={{ fontSize: '1.2rem' }}>
            Are you sure you want to cancel your subscription plan?
            <a href="/tnc" target="_blank">
              {' '}
              Read Our Cancellation Policy.
            </a>
            <p
              style={{
                width: '600px',
                color: 'white',
                marginTop: '10px',
                fontSize: '1rem',
              }}
            >
              Your feedback is appreciated. Please tell us why you are
              considering cancellation:
            </p>
            <Form
              form={form}
              id={'cancel-form'}
              onFinish={onFinish}
              layout="vertical"
            >
              <Col span={12}>
                <Form.Item name="reason" label="">
                  <Radio.Group>
                    <Radio value="I don’t think the website is helpful">
                      I don’t think the website is helpful
                    </Radio>
                    <Radio value="I found a more useful website">
                      I found a more useful website
                    </Radio>
                    <Radio value="It is too expensive">
                      It is too expensive
                    </Radio>
                    <Radio value="The website has technical issues">
                      The website has technical issues
                    </Radio>
                    <Radio value="No longer need the website">
                      No longer need the website
                    </Radio>
                    <Radio value="Other">Other (please specify)</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="" name="otherInput">
                  <textarea
                    style={{
                      width: 500,
                      height: 50,
                      marginLeft: '20px',
                      marginTop: '10px',
                    }}
                  />
                </Form.Item>
              </Col>
            </Form>
            <Form.Item hidden={true} className={styles.personalBtn}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </p>
          {reasonError && (
            <p style={{ color: red }}>
              Please Select a Reason For Cancellation
            </p>
          )}
        </>
      ),
      onOk: form.submit,
      okText: 'Confirm',
    });
  };

  const paymentSettingDropDownMenu = (
    <Menu>
      <Menu.Item disabled={is_cancelled}>
        <span onClick={is_cancelled ? () => {} : () => setOpenViewCard(true)}>
          View Payment Card
        </span>
      </Menu.Item>
      <Menu.Item disabled={is_cancelled}>
        <span onClick={is_cancelled ? () => {} : () => setOpenUpdateCard(true)}>
          Update Payment Method
        </span>
      </Menu.Item>
    </Menu>
  );

  const subscribePlan = (planType) => {
    setSubscribePlanType(planType);
  };
  return (
    <div className={styles.clientContainer}>
      {!isReferralLoading ? (
        <>
          <Row span={24} justify="space-between">
            {subscription_status === 'TRIAL' ? (
              <Col xs={24} sm={24} md={24} lg={14}>
                <div className={styles.paymentTabPlanBox1}>
                  {isReferral ? (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <p className={styles.oldPriceText}>$99.99</p>
                          <h3 className={styles.price}>$90</h3>
                        </div>
                        <div>
                          <p style={{ fontSize: '22px', color: 'green' }}>
                            Referral Discount
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <h3>{'$99.99'}</h3>
                  )}
                  <h4>YEARLY</h4>
                  {/*<p className={styles.coupon_code}>*/}
                  {/*  <span className={styles.coupon_code_value}>$99.99</span>*/}
                  {/*  <span className={styles.coupon_code_text}>YEARLY</span>*/}
                  {/*</p>*/}
                  {/*<p className={styles.coupon_code}>*/}
                  {/*  <span className={styles.coupon_code_value}>$99.99</span>*/}
                  {/*  <span className={styles.coupon_code_text}>*/}
                  {/*    With Coupon Code:{' '}*/}
                  {/*    <span*/}
                  {/*      style={{*/}
                  {/*        color: theme === 'dark' ? yellow : green,*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      TSSAVE200*/}
                  {/*    </span>*/}
                  {/*  </span>*/}
                  {/*</p>*/}
                  {/* <span className={styles.discountRibbon}>{`Most Popular`}</span> */}
                  <div className={styles.buttonContainer}>
                    <Button
                      type="primary"
                      onClick={() => subscribePlan('yearly')}
                      shape="round"
                      disabled={is_cancelled}
                    >
                      Subscribe
                    </Button>
                  </div>
                  {isReferral ? (
                    <div className={styles.ribbonUpText}>
                      <span>{`Save ${10}%`}</span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className={styles.paymentTabPlanBox1}>
                  <h3 style={{ marginLeft: isReferral ? '65px' : '' }}>
                    {'$9.99'}
                  </h3>
                  {/*<h3>{'$9.99'}</h3>*/}
                  <h4>MONTHLY</h4>
                  <div className={styles.buttonContainer}>
                    <Button
                      shape="round"
                      type="primary"
                      onClick={() => subscribePlan('monthly')}
                    >
                      Subscribe
                    </Button>
                  </div>
                </div>

                <div className={styles.paymentTabPlanBox2}>
                  <h4> Free Trial</h4>
                  <h5>
                    This is your <br /> current plan
                  </h5>
                </div>
              </Col>
            ) : (
              <Col xs={24} sm={24} md={24} lg={14}>
                <div className={styles.paymentTabPlanBox1}>
                  <h3>{'$9.99'}</h3>
                  <h4>MONTHLY</h4>
                  {interval === 'MONTHLY' ? (
                    <h5>
                      This is your <br /> current plan
                    </h5>
                  ) : (
                    <div className={styles.buttonContainer}>
                      <Button
                        disabled={
                          is_cancelled ||
                          !(
                            moment() >
                            moment(current_period_end).subtract(2, 'month')
                          )
                        }
                        shape="round"
                        type="primary"
                        onClick={changePlan}
                      >
                        Switch
                      </Button>
                    </div>
                  )}
                </div>
                <div className={styles.paymentTabPlanBox1}>
                  {/* <h3>{`$99.99`}</h3> */}
                  <h3>{'$99.99'}</h3>
                  <h4>YEARLY</h4>
                  <span
                    className={styles.discountRibbon}
                  >{`Most Popular`}</span>
                  {interval === 'YEARLY' ? (
                    <h5>
                      This is your <br /> current plan
                    </h5>
                  ) : (
                    <div className={styles.upgradeBtn}>
                      <Button
                        type="primary"
                        onClick={changePlan}
                        shape="round"
                        disabled={is_cancelled}
                      >
                        Upgrade
                      </Button>
                      <p className={styles.saveMonthsText}>Save 2 Months</p>
                    </div>
                  )}
                </div>
              </Col>
            )}
            <Col xs={24} sm={24} md={24} lg={10}>
              <div className={styles.paymentSettingPoint}>
                <div className={styles.featuresSection}>
                  <Col span={24}>
                    <p style={{ color: green, fontSize: 20, width: '120%' }}>
                      <InfoCircleOutlined style={{ color: green }} />
                      {`Available TS Credit Balance : $${
                        balance === 0 ? balance : -balance
                      }`}
                    </p>
                  </Col>
                  <h3 className={styles.titleFeature}>On Subscribing:</h3>
                  <ul>
                    {/* <li>
      <CheckCircleOutlined /> Life Time Price is applicable only for
      Early ThinkSabiens & if No cancellation between Renewal
      (Monthly/Yearly).
    </li> */}
                    <li>
                      <CheckCircleOutlined /> Subscription will be Automatic
                      Renewal (Monthly/Yearly) based on your current plan.
                    </li>
                    <li>
                      <CheckCircleOutlined /> Payments processed are
                      Non-Refundable (Monthly/Yearly).
                    </li>
                    <li>
                      <CheckCircleOutlined /> Cancel anytime with one Click,
                      upon cancellation you’re not Billed on your Next Renewal
                      Date
                    </li>
                    <li>
                      <CheckCircleOutlined /> Read Full{' '}
                      <Link
                        to="/tnc"
                        // onClick={() => setOpenTnc(true)}
                        className={styles.tnc}
                      >
                        Terms and Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            {subscription_status !== 'TRIAL' && (
              <div className={styles.paymentSettingBtn}>
                <Dropdown
                  overlay={paymentSettingDropDownMenu}
                  placement="bottomCenter"
                >
                  <Button>
                    <SettingOutlined />
                    Payment Setting
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            )}
          </Row>
          <Row justify="space-between">
            {subscription_status === 'TRIAL' ? (
              <>
                <Col span={24}>
                  <p style={{ color: green }}>
                    <InfoCircleOutlined style={{ color: green }} />
                    {` Your Free trial ends on ${moment(
                      current_period_end,
                    ).format('MMM DD, YYYY')}`}
                  </p>
                </Col>
                {/*<Col span={24}>*/}
                {/*  <p className={styles.couponCode}>*/}
                {/*    Use Coupon code<span>{` TSSAVE200 `}</span> to save{' '}*/}
                {/*    <span>{` 67% `}</span> for limited period of time on yearly*/}
                {/*    subscription*/}
                {/*  </p>*/}
                {/*</Col>*/}
              </>
            ) : is_cancelled ? (
              <p style={{ color: orange }}>
                <WarningOutlined style={{ color: orange }} />
                {` Your Subscription will end on ${moment(
                  current_period_end,
                ).format('MMM DD, YYYY')}`}{' '}
              </p>
            ) : (
              <>
                <p style={{ color: green }}>
                  <InfoCircleOutlined style={{ color: green }} />
                  {` Your Subscription will renew on ${moment(
                    current_period_end,
                  ).format('MMM DD, YYYY')}`}{' '}
                </p>
              </>
            )}
          </Row>
          {!is_cancelled && (
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
                color: 'yellow',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#2B343D',
                  height: '50px',
                  width: '150px',
                  border: '1.1px solid #1abd50',
                  borderRadius: '15px',
                }}
                onClick={() => cancelPlan(true)}
              >
                Cancel Subscription
              </span>
            </Row>
          )}
          <Row span={24} justify="space-between">
            <PaymentHistory customer={customer} />
          </Row>
          {openUpdateCard && (
            <UpdatePaymentCard
              visible={openUpdateCard}
              selectedPlan={interval}
              handleCancel={() => setOpenUpdateCard(false)}
              customerId={stripe_customer_id}
            />
          )}
          {openViewCard && (
            <PaymentCard
              visible={openViewCard}
              handleCancel={() => setOpenViewCard(false)}
            />
          )}
          {subscribePlanType && (
            <SubscribePlanModal
              visible={true}
              handleCancel={() => setSubscribePlanType()}
              selectedPlan={subscribePlanType}
            />
          )}
        </>
      ) : (
        <>
          <span className={styles.spinnerStyle}>
            <BounceLoader color={green} loading={true} size={50} />
          </span>
        </>
      )}
    </div>
  );
};

export default PaymentProfilePage;
