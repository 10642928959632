import React, { useState } from 'react';

import {
  Row,
  Col,
  Form,
  Button,
  InputNumber,
  notification,
  Input,
  Checkbox,
  Modal,
} from 'antd';

// import { DeleteIcon } from '../../../components';
import { savePortfolioInfo } from '../../../graphql';
import { errorHandler } from '../../../utils';
import styles from './style.module.scss';
interface Props {
  handleCancel: Function;
  data: Array<{}>;
  portfolioName: string;
}

const DeleteRealStock: React.FC<Props> = ({
  handleCancel,
  data,
  portfolioName,
}) => {
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState(
    (data || []).map((item) => ({
      name: item.id,
      shares: item.no_of_shares,
      price: item.avg_cost,
      symbol: item.symbol,
      id: item.id,
      hidden: false,
    })),
  );

  const onFinish = () => {
    const payload = formFields
      .filter((field) => field.hidden)
      .map((field) => ({
        company: {
          symbol: field.symbol,
        },
        id: field.id,
        delete: field.hidden,
      }));

    onDelete(payload);
  };

  const onDelete = (payload) =>
    Modal.confirm({
      title: 'Delete',
      content: <p>Are you sure you want to delete?</p>,
      onOk: () => handleDelete(payload),
    });

  const handleDelete = (payload) =>
    savePortfolioInfo(payload, 'REAL', portfolioName)
      .then(() => {
        notification['success']({
          message: 'Portfolio Updated successfully',
        });
        handleCancel();
      })
      .catch(errorHandler);

  const onChange = (name) => {
    let fields = formFields.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          hidden: !item.hidden,
        };
      } else return item;
    });

    setFormFields(fields);
  };

  return (
    <Form form={form} name="add-form" onFinish={onFinish} autoComplete="off">
      <Row span={24}>
        <Col span={4} className={styles.updateFormLabel} style={{ padding: 0 }}>
          Select
        </Col>
        <Col span={10} className={styles.updateFormLabel}>
          Symbol
        </Col>
        <Col className={styles.updateFormLabel} span={5}>
          No of Shares
        </Col>
        <Col className={styles.updateFormLabel} span={5}>
          Avg Cost
        </Col>
      </Row>
      <Form.List name="portfolio">
        {(fields) => (
          <div style={{ maxHeight: 400, minHeight: 200, overflow: 'auto' }}>
            {formFields.map((field, i) => {
              const { name, symbol, shares, price, hidden } = field;
              return (
                <Row span={24} className={styles.sortFormScroll} key={name}>
                  <Col span={4}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'price']}
                      //  label={i === 0 ? 'Avg Cost' : null}
                      fieldKey={[field.fieldKey, 'price']}
                      // rules={[
                      //   { required: true, message: 'Avg Cost is required' },
                      // ]}
                      initialValue={price}
                      style={{ width: '90%' }}
                    >
                      <Checkbox
                        checked={hidden}
                        onChange={() => onChange(field.name)}
                        // disabled={column.key === 'symbol'}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      {...field}
                      // label={i === 0 ? 'Symbol' : null}
                      name={[field.name, 'symbol']}
                      fieldKey={[field.fieldKey, 'symbol']}
                      // rules={[
                      //   { required: true, message: 'Symbol is required' },
                      // ]}
                      initialValue={symbol}
                      style={{ width: '90%' }}
                    >
                      <Input disabled size="middle" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'shares']}
                      //  label={i === 0 ? 'No of Shares' : null}
                      fieldKey={[field.fieldKey, 'shares']}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'No of Shares is required',
                      //   },
                      // ]}
                      initialValue={shares}
                      style={{ width: '90%' }}
                    >
                      <InputNumber
                        min={0}
                        // ype="number"
                        // type="number"
                        placeholder="No of Shares"
                        style={{ width: '100%' }}
                        disabled
                        //onChange={(value) => onSharesChange(value, name)}
                        formatter={(n) => {
                          if (n?.toString().indexOf('.') > -1) {
                            const [p1, p2] = n?.toString().split('.');
                            let a = `${p1}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            );
                            if (p2) {
                              const b =
                                p2.length > 2
                                  ? p2.toString().substring(0, 2)
                                  : p2.toString();
                              a = a + '.' + b;
                            } else {
                              a = a + '.';
                            }
                            return a;
                          } else {
                            return `${n}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'price']}
                      //  label={i === 0 ? 'Avg Cost' : null}
                      fieldKey={[field.fieldKey, 'price']}
                      // rules={[
                      //   { required: true, message: 'Avg Cost is required' },
                      // ]}
                      initialValue={price}
                      style={{ width: '90%' }}
                    >
                      <InputNumber
                        // min={0.0009}
                        // type="number"
                        disabled
                        // onChange={(value) => onPriceChange(value, name)}
                        placeholder="Avg Cost"
                        style={{ width: '100%' }}
                        formatter={(n) => {
                          if (n?.toString().indexOf('.') > -1) {
                            const [p1, p2] = n?.toString().split('.');
                            let a = `$ ${p1}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            );
                            if (p2) {
                              const b =
                                p2.length >= 4
                                  ? p2.toString().substring(0, 4)
                                  : p2.toString();
                              a = a + '.' + b;
                            } else {
                              a = a + '.';
                            }
                            return a;
                          } else {
                            return `$ ${n}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            );
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              );
            })}
          </div>
        )}
      </Form.List>
      <Row
        span={24}
        justify="center"
        // className={styles.buttonSectionBoxNew}
      >
        <Form.Item>
          <Button
            disabled={formFields.filter((field) => field.hidden).length === 0}
            type="primary"
            htmlType="submit"
          >
            Delete
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default DeleteRealStock;
