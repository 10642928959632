import React from 'react';

import { Tabs, Layout } from 'antd';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

// import TrendingInstitutions from './TrendingInstitutions';
import DailyTrendingData from './DailyTrendingData';
import MonthlyTrendingData from './MonthlyTrendingData';
import QuarterlyTrendingData from './QuarterlyTrendingData';
import styles from './styles.module.scss';

const { TabPane } = Tabs;
const { Content } = Layout;

// interface Props{

// };

const TrendingData: React.FC<RouteComponentProps> = ({ history, match }) => {
  const type = get(match, 'params.type', 'symbol').toUpperCase();
  const subType = get(match, 'params.subType', 'DAILY').toUpperCase();
  return (
    <Content className={styles.trendingContainer}>
      <Tabs
        defaultActiveKey={type}
        style={{ width: '100%' }}
        centered
        onChange={(key) => history.push(`/trending/${key.toLowerCase()}/daily`)}
        id="#vTabsTrending"
      >
        <TabPane
          tab="By Symbol"
          key="SYMBOL"
          className={styles.vInvestorFixedDiv}
        >
          <Tabs
            defaultActiveKey={subType}
            style={{ width: '100%' }}
            onChange={(key) =>
              history.push(`/trending/symbol/${key.toLowerCase()}`)
            }
            centered
          >
            <TabPane tab="Daily" key="DAILY">
              <DailyTrendingData type={type} history={history} />
            </TabPane>
            <TabPane tab="Monthly" key="MONTHLY">
              <MonthlyTrendingData type={type} history={history} />
            </TabPane>
            <TabPane tab="Quarterly" key="QUARTERLY">
              <QuarterlyTrendingData type={type} history={history} />
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane
          tab="By Institution"
          key="INSTITUTION"
          className={styles.vInvestorFixedDiv}
        >
          <Tabs
            defaultActiveKey={subType}
            style={{ width: '100%' }}
            onChange={(key) => history.push(`/trending/institution/${key}`)}
            centered
          >
            <TabPane tab="Daily" key="DAILY">
              <DailyTrendingData type={type} history={history} />
            </TabPane>
            <TabPane tab="Monthly" key="MONTHLY">
              <MonthlyTrendingData type={type} history={history} />
            </TabPane>
            <TabPane tab="Quarterly" key="QUARTERLY">
              <QuarterlyTrendingData type={type} history={history} />
            </TabPane>
          </Tabs>
        </TabPane>
      </Tabs>
    </Content>
  );
};

export default TrendingData;
