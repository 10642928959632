import React, { useState } from 'react';

import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  Select,
  message,
  Tabs,
  Input,
} from 'antd';
import BounceLoader from 'react-spinners/BounceLoader';

import {
  createTSAlert,
  esSearchCompany,
  esSearchAllInstitution,
} from '../../graphql';
import {
  green,
  red,
  errorHandler,
  insiderStatusOptions,
  allStatusOptions,
} from '../../utils';
// import { alertFormFields } from './Alerts.Constants';
import { ALERT_TYPES } from './Alerts.Constants';
import styles from './style.module.scss';

const { Option } = Select;
const { TabPane } = Tabs;

interface Props {
  visible: boolean;
  handleCancel: Function;
  sectorOptions: Array;
}

const CreateAlert: React.FC<Props> = ({ visible, handleCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [symbolData, setSymbolData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [institutionsData, setInstitutionsData] = useState([]);
  const [loadingCik, setLoadingCik] = useState<boolean>(false);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  const [error, setError] = useState('');

  const fetchSymbols = async (value) => {
    setFetching(true);
    const res = await esSearchCompany(value);
    if (res) {
      // const filterRes = res.filter((r) => !addedSymbols.includes(r.symbol));
      setSymbolData(res);
    }
    setFetching(false);
  };

  const fetchInstitution = async (value) => {
    setLoadingCik(true);
    const res = await esSearchAllInstitution(value);
    setInstitutionsData(res);
    setLoadingCik(false);
  };

  const onSec13DGFinish = (values) => {
    onFinish(ALERT_TYPES.SEC13DG, values);
  };

  const onInsiderFinish = (values) => {
    onFinish(ALERT_TYPES.INSIDER, values);
  };

  const onFinish = (type, values) => {
    const { symbols, institutions, name, sharesActivity } = values;
    if (!symbols && !institutions) {
      setError('Please provide at least 1 Symbol or Institution');
    } else {
      const ciks = [];
      // eslint-disable-next-line
      (selectedInstitutions || []).map((inst) => {
        const value = inst.split('-#-');
        ciks.push(value[0]);
        // insNames.push(value[1]);
      });
      const payload = {
        type,
        options: {
          symbols: symbols,
          ciks: ciks,
          name: name,
          status: sharesActivity,
        },
      };
      const apis = [createTSAlert(payload)];
      setLoading(true);
      Promise.all(apis)
        .then((data) => {
          message.success('Alert Created');
          handleCancel();
        })
        .catch(errorHandler)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSelect = (value) => {
    const ins = selectedInstitutions;
    ins.push(value.key);
    setSelectedInstitutions(ins);
    setInstitutionsData([]);
  };

  const handleDeSelect = (value) => {
    const ins = selectedInstitutions.filter((i) => i !== value.key);
    setSelectedInstitutions(ins);
  };
  return (
    <Modal
      title={`Create Alert`}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      bodyStyle={{ paddingTop: 0 }}
    >
      <Tabs defaultActiveKey={ALERT_TYPES.SEC13DG}>
        <TabPane tab="By SEC13D/G" key={ALERT_TYPES.SEC13DG}>
          <Form
            name={`Create Alert`}
            onFinish={onSec13DGFinish}
            labelCol={{ span: 24, padding: 0 }}
            wrapperCol={{ span: 24 }}
            form={form}
            initialValues={{
              sharesActivity: allStatusOptions[0].value,
            }}
            onValuesChange={() => setError('')}
          >
            <Row span={24}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label={'Alert Name'}
                  className={styles.vFormSearhNew}
                  rules={[
                    {
                      required: true,
                      message: 'Alert Name is required',
                    },
                  ]}
                  size="large"
                >
                  <Input
                    placeholder="Enter alert Name"
                    className={styles.vCreateName}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="symbols"
                  label={'Select Symbols(Select upto 5 Symbols)'}
                  className={styles.vFormSearhNew}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length > 5) {
                          return Promise.reject('Select upto 5 symbols');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select
                    placeholder="Enter valid symbols"
                    mode={'multiple'}
                    notFoundContent={
                      fetching ? (
                        <span className={styles.spinnerStyle}>
                          <BounceLoader
                            color={green}
                            loading={true}
                            size={50}
                          />
                        </span>
                      ) : null
                    }
                    onSearch={fetchSymbols}
                    showSearch={true}
                    allowClear={true}
                    showArrow={false}
                    filterOption={false}
                    onSelect={() => setSymbolData([])}
                    size="large"
                  >
                    {symbolData.map((d) => (
                      <Option key={d.symbol} className={styles.optionStyle}>
                        <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                        {d.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="institutions"
                  className={styles.vFormSearhNew}
                  label={'Select Institution(select upto 5 Institutions)'}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length > 5) {
                          return Promise.reject('Select upto 5 Institution');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    placeholder="Enter Institution"
                    notFoundContent={
                      loadingCik ? (
                        <span className={styles.spinnerStyle}>
                          <BounceLoader
                            color={green}
                            loading={true}
                            size={50}
                          />
                        </span>
                      ) : null
                    }
                    filterOption={false}
                    onSearch={fetchInstitution}
                    size="large"
                    onSelect={(value, key) => handleSelect(key)}
                    onDeselect={(value, key) => handleDeSelect(key)}
                  >
                    {institutionsData.map((institution) => (
                      <Option
                        key={institution.cik + '-#-' + institution.name}
                        value={institution.name}
                      >
                        {institution.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="sharesActivity"
                  label={'Shares Activity'}
                  className={styles.vFormSearhNew}
                >
                  <Select
                    showSearch
                    placeholder={'Shares Activity'}
                    size="large"
                    //style={{ width: 'calc(100% - 10px)' }}
                  >
                    {allStatusOptions.map((item) => (
                      <Option key={item.value} value={item.value}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {error && <span style={{ color: red }}>{error}</span>}
            <Row span={24} justify="start">
              <Form.Item colon={false} style={{ margin: 0, marginTop: 20 }}>
                <Button type="primary" loading={loading} htmlType="submit">
                  Create Alert
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </TabPane>
        <TabPane tab="By Insider" key={ALERT_TYPES.INSIDER}>
          <Form
            name={`Create-Alert`}
            onFinish={onInsiderFinish}
            labelCol={{ span: 24, padding: 0 }}
            wrapperCol={{ span: 24 }}
            form={form}
            initialValues={{
              sharesActivity: insiderStatusOptions[0].value,
            }}
            onValuesChange={() => setError('')}
          >
            <Row span={24}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label={'Alert Name'}
                  className={styles.vFormSearhNew}
                  rules={[
                    {
                      required: true,
                      message: 'Alert Name is required',
                    },
                  ]}
                  size="large"
                >
                  <Input
                    placeholder="Enter alert Name"
                    className={styles.vCreateName}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="symbols"
                  label={'Select Symbols(Select upto 5 Symbols)'}
                  className={styles.vFormSearhNew}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length > 5) {
                          return Promise.reject('Select upto 5 symbols');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select
                    placeholder="Enter valid symbols"
                    mode={'multiple'}
                    notFoundContent={
                      fetching ? (
                        <span className={styles.spinnerStyle}>
                          <BounceLoader
                            color={green}
                            loading={true}
                            size={50}
                          />
                        </span>
                      ) : null
                    }
                    onSearch={fetchSymbols}
                    showSearch={true}
                    allowClear={true}
                    showArrow={false}
                    filterOption={false}
                    onSelect={() => setSymbolData([])}
                    size="large"
                  >
                    {symbolData.map((d) => (
                      <Option key={d.symbol} className={styles.optionStyle}>
                        <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                        {d.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="sharesActivity"
                  label={'Activity'}
                  className={styles.vFormSearhNew}
                >
                  <Select showSearch placeholder={'Activity'} size="large">
                    {insiderStatusOptions.map((item) => (
                      <Option key={item.value} value={item.value}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {error && <span style={{ color: red }}>{error}</span>}
            <Row span={24} justify="start">
              <Form.Item colon={false} style={{ margin: 0, marginTop: 20 }}>
                <Button type="primary" loading={loading} htmlType="submit">
                  Create Alert
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default CreateAlert;
