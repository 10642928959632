import { API } from 'aws-amplify';

import {
  getSuccessStories,
  getPopularCompaniesReport,
  getVideos,
} from './queries';

export const fetchPopularCompanies = (value) =>
  API.graphql({
    variables: { request: value },
    query: getPopularCompaniesReport,
  }).then(({ data }) => data.getPopularCompaniesReport);

export const fetchSuccessStories = (values) =>
  API.graphql({
    variables: { request: values },
    query: getSuccessStories,
  }).then(({ data }) => data.getSuccessStories);

export const fetchVideos = (values) =>
  API.graphql({
    variables: { request: values },
    query: getVideos,
  }).then(({ data }) => data.getVideos);
