import React from 'react';

import { Result } from 'antd';

const PageNotFound: React.FC = () => {
  return (
    <div style={{ marginTop: 100 }}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<a href="/">Back Home</a>}
      />
    </div>
  );
};

export default PageNotFound;
