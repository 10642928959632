/* @flow */
import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import state from './initialState';
import picks from './reducers/picks';
import portfolio from './reducers/portfolio';
import report from './reducers/report';
import search from './reducers/search';
import trending from './reducers/trending';
import user from './reducers/user';

function configureStore(initialState = state) {
  const reducer = combineReducers({
    user,
    report,
    search,
    portfolio,
    picks,
    // tour,
    trending,
  });

  const store = createStore(
    persistReducer(
      {
        key: 'root',
        storage,
        whitelist: [
          'user',
          'report',
          'search',
          'portfolio',
          'picks',
          'trending',
        ],
      },
      reducer,
    ),
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__(),
  );

  const persistor = persistStore(store);
  return { store, persistor };
}

export default configureStore;
