export const formatter = (n, showDollar) => {
  if (n?.toString().indexOf('.') > -1) {
    const [p1, p2] = n?.toString().split('.');
    let a = showDollar
      ? `$ ${p1}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : `${p1}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (p2) {
      const b = p2.length > 2 ? p2.toString().substring(0, 2) : p2.toString();
      a = a + '.' + b;
    } else {
      a = a + '.';
    }
    return a;
  } else {
    return showDollar
      ? `$ ${n}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : `${n}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

export const insiderTitles = [
  {
    name: 'CEO: Chief Executive officer',
    value: 'CEO',
  },
  {
    name: 'CFO : Chief Financial Officer',
    value: 'CFO',
  },
  {
    name: 'COO: Chief Operating Officer',
    value: 'COO',
  },
  {
    name: 'CBO : Chief Business Officer',
    value: 'CBO',
  },
  {
    name: 'GC: General Counsel',
    value: 'GC',
  },
  {
    name: 'CCO : Chief Commercial Officer',
    value: 'CCO',
  },
  {
    name: 'CMO: Chief Marketing Officer',
    value: 'CMO',
  },
  {
    name: 'CAO : Chief Accounting Officer',
    value: 'CAO',
  },
  {
    name: 'VP: Vice President',
    value: 'VP',
  },
  {
    name: 'SVP : Senior Vice president',
    value: 'SVP',
  },
  {
    name: 'EVP : Executive vice president',
    value: 'EVP',
  },
  {
    name: 'CO-CEO :CO-Chief Executive officer',
    value: 'CO-CEO',
  },
  {
    name: 'Founder',
    value: 'Founder',
  },
  {
    name: 'President',
    value: 'President',
  },
  {
    name: 'Chairman',
    value: 'Chairman',
  },
  {
    name: 'Director',
    value: 'Director',
  },
  {
    name: 'Co-Founder',
    value: 'Co-Founder',
  },
  {
    name: 'Others',
    value: 'others',
  },
];

export const maxFieldNames = {
  minInvestedAmt: 'maxInvestedAmt',
  minInvestedAmtReturns: 'maxInvestedAmtReturns',
  minInvestedReturnsPerc: 'maxInvestedReturnsPerc',
  minTotalPFvalue: 'maxTotalPFvalue',
  minTotalPFReturnsPerc: 'maxTotalPFReturnsPerc',
};
export const minFieldNames = {
  maxInvestedAmt: 'minInvestedAmt',
  maxInvestedAmtReturns: 'minInvestedAmtReturns',
  maxInvestedReturnsPerc: 'minInvestedReturnsPerc',
  maxTotalPFvalue: 'minTotalPFvalue',
  maxTotalPFReturnsPerc: 'minTotalPFReturnsPerc',
};
