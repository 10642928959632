import React, { useEffect, useState } from 'react';

import { Tabs, Layout } from 'antd';
// import { get } from 'lodash';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

import { Pagination, Table } from '../../components';
import {
  fetchStudentPerformanceCountyReports,
  fetchStudentPerformanceGradeReports,
  fetchStudentPerformanceSchoolReports,
  fetchStudentPerformanceStudentReports,
} from '../../graphql/gamification';
import { useTheme } from '../../ThemeContext';
import { errorHandler, formatColumn, usStates } from '../../utils';
import { arrangeColumns } from '../University/PopularStocks/PopularStocks';
import MapChart from './MapChart';
import styles from './styles.module.scss';

// const columns = [
//   {
//     title: 'Rank',
//     dataIndex: 'type',
//     key: 'type',
//   },
//   {
//     title: 'State',
//     dataIndex: 'name',
//     key: 'name',
//   },
//   {
//     title: 'Initial Investment',
//     dataIndex: 'symbols',
//     key: 'symbols',
//   },
//   {
//     title: 'Current Market Value',
//     dataIndex: 'institutions',
//     key: 'institutions',
//   },
//   {
//     title: '%Returns',
//     dataIndex: 'status',
//     key: 'status',
//   },
//   {
//     title: '#Students',
//     dataIndex: 'last_modified_at',
//     key: 'last_modified_at',
//   },
// ];

const { TabPane } = Tabs;
const { Content } = Layout;

// interface Props{

// };

const GamificationByCounty: React.FC<RouteComponentProps> = () => {
  const [type, setType] = useState('GEOGRAPHIES');
  const [subType, setSubType] = useState('BY_COUNTY');
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [sortDetail, setSortDetail] = useState<Array>([]);
  const { theme } = useTheme();
  const location = useLocation();
  const history = useHistory();
  const stateName = location.state?.name?.properties?.name || 'COLORADO';
  const getStateAbbr = () => {
    let abbr = '';
    usStates.forEach((i) => {
      if (i.name.toLowerCase() === stateName.toLowerCase()) {
        abbr = i.abbreviation;
      }
    });
    return abbr;
  };
  const stateAbbreviation = getStateAbbr();

  useEffect(() => {
    setIsLoading(true);
    let payload = {
      page: pageNo,
      size: pageSize,
      sortBy: sortDetail[0]?.sortBy
        ? sortDetail[0].sortBy
        : 'student_performance_rank',
      sortOrder: sortDetail[0]?.sortOrder ? sortDetail[0].sortOrder : 'asc',
    };
    if (type !== 'INDIVIDUAL') {
      payload = { ...payload, schoolState: stateAbbreviation };
    }
    const tableFunction = (subType) => {
      if (type === 'INDIVIDUAL') {
        return fetchStudentPerformanceStudentReports({
          ...payload,
          filters: {},
        });
      }
      switch (subType) {
        case 'BY_COUNTY':
          return fetchStudentPerformanceCountyReports(payload);
        case 'BY_SCHOOL':
          return fetchStudentPerformanceSchoolReports(payload);
        case 'BY_GRADE':
          return fetchStudentPerformanceGradeReports(payload);
      }
    };
    const promises = [tableFunction(subType)];
    Promise.all(promises)
      .then((res) => {
        const rawTableData = res[0];
        for (const r of rawTableData.data) {
          usStates.forEach((i) => {
            if (i.abbreviation === r['school_state']) {
              r['school_state'] = i.name;
              return;
            }
          });
        }
        setTableData(res[0]);
        // res[0].data.school_state
        const { columns = [] } = res[0];
        setTableColumns(arrangeColumns(columns));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    location.state,
    pageNo,
    pageSize,
    stateAbbreviation,
    subType,
    type,
    sortDetail,
  ]);

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setPageNo(page);
  };

  const setSubTypeFunction = (key) => {
    setSubType(key);
    setSortDetail([
      {
        sortBy: 'student_performance_rank',
        sortOrder: 'asc',
      },
    ]);
    setPageSize(10);
    setPageNo(1);
  };

  const setTypeFunction = (key) => {
    if (type === 'INDIVIDUAL') {
      history.push('/GamificationByState');
    }
    setType(key);
    setSortDetail([
      {
        sortBy: 'student_performance_rank',
        sortOrder: 'asc',
      },
    ]);
    setPageSize(10);
    setPageNo(1);
  };

  const handleSort = (sortBy, sortOrder) => {
    if (sortOrder) {
      setSortDetail([
        {
          sortBy: sortBy,
          sortOrder: sortOrder,
        },
      ]);
    } else {
      setSortDetail([]);
    }
  };

  const handleTableOnChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    let index = sortDetail.findIndex((d) => d.sortBy === field);
    if (index > -1) {
      let newOrder =
        order === 'ascend' ? 'ASC' : order === 'descend' ? 'DESC' : null;
      handleSort(field, newOrder, false);
    } else {
      if (order === 'ascend') {
        handleSort(field, 'ASC', false);
      } else if (order === 'descend') {
        handleSort(field, 'DESC', false);
      } else {
        handleSort(field, null, false);
      }
    }
  };

  const { data, total } = tableData;
  return (
    <Content className={styles.trendingContainer}>
      <Tabs
        defaultActiveKey={type}
        style={{ width: '100%' }}
        centered
        onChange={(key) => setTypeFunction(key)}
        id="#vTabsTrending"
      >
        <TabPane
          tab="By Geographies"
          key="GEOGRAPHIES"
          className={styles.vInvestorFixedDiv}
        >
          <NavLink
            to="/GamificationByState"
            activeClassName={styles.navActiveClass}
          >
            <button className={styles.back}>BACK</button>
          </NavLink>
          <h1
            style={{
              textAlign: 'center',
              color: 'var(--whitecolor)',
            }}
          >
            TS-YOUTH HEROES PERFORMANCE
          </h1>
          <MapChart />
          <h1
            style={{
              textAlign: 'center',
              color: 'var(--whitecolor)',
            }}
          >
            {stateName}
          </h1>
          <Tabs
            defaultActiveKey={subType}
            style={{ width: '100%' }}
            centered
            onChange={(key) => setSubTypeFunction(key)}
          >
            <TabPane tab="By County" key="BY_COUNTY">
              <div style={{ width: '50%', margin: 'auto' }}>
                <Table
                  scroll={{ x: true }}
                  loading={isLoading}
                  columns={formatColumn(
                    tableColumns,
                    true,
                    sortDetail,
                    history,
                    false,
                    null,
                    'alert',
                    null,
                    null,
                    theme,
                  )}
                  dataSource={data}
                  pagination={false}
                  size="small"
                  bordered={true}
                  rowClassName={styles.tableHeader}
                  className={styles.TabularData}
                  id="allPicksId"
                  onChange={handleTableOnChange}
                />
                <div style={{ width: '100%' }}>
                  <Pagination
                    pageSize={pageSize}
                    total={total}
                    onChange={onPaginationChange}
                    pageNo={pageNo}
                    loading={isLoading}
                    showPagination={true}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tab="By School" key="BY_SCHOOL">
              <div style={{ width: '50%', margin: 'auto' }}>
                <Table
                  scroll={{ x: true }}
                  loading={isLoading}
                  columns={formatColumn(
                    tableColumns,
                    true,
                    sortDetail,
                    history,
                    false,
                    null,
                    'alert',
                    null,
                    null,
                    theme,
                  )}
                  dataSource={data}
                  pagination={false}
                  size="small"
                  bordered={true}
                  rowClassName={styles.tableHeader}
                  className={styles.TabularData}
                  id="allPicksId"
                  onChange={handleTableOnChange}
                />
                <div style={{ width: '100%' }}>
                  <Pagination
                    pageSize={pageSize}
                    total={total}
                    onChange={onPaginationChange}
                    pageNo={pageNo}
                    loading={isLoading}
                    showPagination={true}
                  />
                </div>
              </div>
            </TabPane>
            <TabPane tab="By Grade" key="BY_GRADE">
              <div style={{ width: '65%', margin: 'auto' }}>
                <Table
                  scroll={{ x: true }}
                  loading={isLoading}
                  columns={formatColumn(
                    tableColumns,
                    true,
                    sortDetail,
                    history,
                    false,
                    null,
                    'alert',
                    null,
                    null,
                    theme,
                  )}
                  dataSource={data}
                  pagination={false}
                  size="small"
                  bordered={true}
                  rowClassName={styles.tableHeader}
                  className={styles.TabularData}
                  id="allPicksId"
                  onChange={handleTableOnChange}
                />
                <div style={{ width: '100%' }}>
                  <Pagination
                    pageSize={pageSize}
                    total={total}
                    onChange={onPaginationChange}
                    pageNo={pageNo}
                    loading={isLoading}
                    showPagination={true}
                  />
                </div>
              </div>
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane
          tab="By Individual"
          key="INDIVIDUAL"
          className={styles.vInvestorFixedDiv}
        >
          <div className={styles.searchPaneTable}>
            <h1
              style={{
                textAlign: 'center',
                color: 'var(--whitecolor)',
              }}
            >
              TS-YOUTH HEROES PERFORMANCE
            </h1>
            <Table
              scroll={{ x: true }}
              loading={isLoading}
              columns={formatColumn(
                tableColumns,
                true,
                sortDetail,
                history,
                false,
                null,
                'alert',
                null,
                null,
                theme,
              )}
              dataSource={data}
              pagination={false}
              size="small"
              bordered={true}
              rowClassName={styles.tableHeader}
              className={styles.TabularData}
              id="allPicksId"
              onChange={handleTableOnChange}
            />
            <div style={{ width: '100%' }}>
              <Pagination
                pageSize={pageSize}
                total={total}
                onChange={onPaginationChange}
                pageNo={pageNo}
                loading={isLoading}
                showPagination={true}
              />
            </div>
          </div>
        </TabPane>
      </Tabs>
    </Content>
  );
};

export default GamificationByCounty;
