import React, { useState } from 'react';

import { Drawer, Tabs } from 'antd';
import { get } from 'lodash';

import { FilterForm, SortForm } from '../../components';
import { filterFields } from './Pick.constant';

const { TabPane } = Tabs;

interface Props {
  visible: boolean;
  onCancel: Function;
  onFilter: Function;
  filterOptions: Object;
  sectorOptions: Object;
  columns: Array;
  sortDetail: Array;
  handleSortReset: Function;
  handleSortSubmit: Function;
  type: string;
}

const PickFilterForm: React.FC<Props> = ({
  visible,
  onCancel,
  onFilter,
  filterOptions,
  columns,
  sortDetail,
  // handleSortReset,
  // handleSortSubmit,
  type,
}) => {
  const initialFields = () => {
    let fieldArr = sortDetail.map((item, index) => ({
      sortBy: get(item, 'sortBy'),
      sortOrder: get(item, 'sortOrder'),
      index: index,
      name: index,
    }));
    if (fieldArr.length === 0) {
      fieldArr.push({
        sortBy: '',
        sortOrder: 'DESC',
        index: fieldArr.length,
        name: fieldArr.length,
      });
    }

    return fieldArr;
  };

  const [formFields, setFormFields] = useState(initialFields());
  const [submitForm, setSubmitForm] = useState(false);

  const onReset = () => {
    onFilter({}, []);
    // handleSortReset();
    onCancel();
  };

  const onFilterFinish = (values) => {
    const sort = [];
    formFields.forEach((s) => {
      if (s.sortBy) {
        sort.push({
          sortOrder: s.sortOrder,
          sortBy: s.sortBy,
        });
      }
    });
    onFilter(values, sort);
    onCancel();
  };

  const onSortFinish = (values) => {
    setSubmitForm(true);
  };

  return (
    <Drawer
      placement="right"
      onClose={onCancel}
      visible={visible}
      getContainer={false}
      closable={true}
      style={{
        // top: 220,
        //height: 'calc(100% - 220px)',
        height: '100%',
        boxShadow:
          '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
      }}
      // mask={false}
      bodyStyle={{
        paddingTop: 5,
      }}
      //width={450}
      id="vFillerSortSideDiv"
    >
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Filter" key="1">
          <FilterForm
            onReset={onReset}
            onFilter={onFilterFinish}
            filterOptions={filterOptions}
            fields={filterFields(type.toUpperCase())}
            source="picks"
            submitForm={submitForm}
          />
        </TabPane>
        <TabPane tab="Sort" key="2">
          <SortForm
            columns={columns()}
            formFields={formFields}
            setFormFields={setFormFields}
            onReset={onReset}
            onSubmit={onSortFinish}
          />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default PickFilterForm;
