export const black: string = '#000000';
export const white: string = '#ffffff';
export const blue: string = '#054fff';
export const green: string = '#1ABD50';
export const red: string = '#f94312';
export const black1: string = '#495057';
export const black2: string = '#000034';
export const orange: string = '#f5a623';
export const grey: string = '#727272';
export const lightBlue: string = '#ebf1ff';
export const lightBlueBg: string = '#E6F9EC';
export const lightGrey: string = '#bfbfbf';
export const darkGrey: string = '#21272C';
export const newGrey: string = '#46515c';
export const greyBorderColor: string = '#89969F';
export const lightBlue2: string = '#EFF2F7';
export const newGreen: string = 'rgba(26, 189, 80, 0.75)';
export const Shadow: string =
  '0px 7.19081px 14.3816px rgba(18, 38, 63, 0.0313726)';
export const lightGreen: string = '#edfcf0';

export const dark_primary: string = '#2B343D';
export const yellow: string = '#fef001';
export const greenTextColor: string = '#2FC058';
export const lightHover: string = '#EBEBEB';
export const darkHover: string = '#363E46';

export const themeColours = {
  light: {
    // color: '#343434',
    newColor: '#21272C',
    // new one
    backgroundColor: '#F0F0F0',
    // newBoxShadow: '0px 2px 3px #00000029',
    newBoxShadow: '0px 0px 3px 3px #00000029',
    grayBorder: '#CED4DA',
    tabBorder: '#00000047',
    whiteBorder: '#F5F6F6',
    whiteColor: '#000',
    noData: '#ADB2B7',
    grayThead: '#F6F7F7',
    // greenThead: '#1abd5061',
    greenThead: '#9fddb3',
    grayBody: '#000',
    headerBgm: '#fff',
    headerShadow: '0px 3px 6px #00000029',
    // fontWeight: 'bold',
    fontWeight: '400',
    grayColor: '#CED4DA',
    inputShadow: '0px 1px 2px #00000029',
    inputBgm: '#FFFFFF',
    btnColor: '#000',
    buttonBorder: '#CED4DA',
    blackThColor: '#000',
    tbodyTd: '#fff',
    tdBorder: '#dce0e0',
    caretIconColor: '#000',
    tfooterColor: '#c5c5c5',
    rightBorder: 'transparent',
    rightThBorder: '#0000000d',
    rightNewThBorder: '#0000000d',
    lightBorder: '#dce0e0',
    inputBgmNum: '#fff',
    grayColor1: '#000',
    greenColor: '#fff',
    greenBgm: '#1ABD50',
    blackNewColor: '#fff',
    newGray: '#1ABD50',
    transparent: '#1ABD50',
    inputWhite: '#fff',
    D8d8: '#000',
    E6Border: '#E6E6E6',
    paymentColor: '#84dea170',
    // spanBgm: '#fff',
    spanBgm: '#1abd5082',
    spanBorder: '#CED4DA',
    newTheadColor: '#CED4DA',
    newTbodyColor: '#fafafa',
    tdHover: '#EBEBEB',
    newPagination: '#2FC058',
    span: '#000',
    scroll: '#88868775',
    scrollNew: '#46515c80',
    whiteBodyBgm: '#fff',
    // new one
    // light: '#B3E9C3',
    lightDark: '#B3E9C3',
    // border: '#BEBEBE',
    bgGrey: '#FAFAFA',
    thead: '#fafafa',
    theadColor: '#343434',
    borderRight: 'none',
    borderBottom: '1.5px solid #BEBEBE',
    // thBorder: 'none',
    thBottom: 'none',
    pagination: '#f4f4f4',
    drawer: '#fff',
    button: '#1ABD50',
    newButton: '#1ABD50',
    buttonColor: '#fff',
    datePick: '#fff',
    dateBox: '#B3E9C3',
    background: '#fff',
    shadow: 'none',
    breadCrumb: '#000',
    newBorder: '#BEBEBE',
    newThead: '#ced4da',
    tbody: '#fafafa',
    disclaimer: '#000',
    newTbody: '#fff',
    blackColor: '#1ABD50',
    transaction: '#f2f2f2',
    borderTransaction: '#BEBEBE',
    sorterColor: '#fff',
    portfolio: '#000',
  },
  dark: {
    // color: '#fff',
    newColor: '#1ABD50',
    // new one
    backgroundColor: '#21272C',
    newBoxShadow: 'none',
    grayBorder: '#46515C',
    tabBorder: '#46515C',
    whiteBorder: '#46515C',
    whiteColor: '#fff',
    noData: '#d8d8d8',
    grayThead: '#46515C',
    greenThead: '#46515C',
    grayBody: '#fff',
    headerBgm: '#2B343D',
    headerShadow: 'none',
    fontWeight: '400',
    grayColor: '#fff',
    inputShadow: 'none',
    inputBgm: '#46515C',
    btnColor: '#1ABD50',
    buttonBorder: '#2B343D',
    blackThColor: 'rgb(254, 240, 1)',
    tbodyTd: '#21272C',
    tdBorder: '#495057',
    rightBorder: '#495057',
    caretIconColor: '#bfbfbf',
    rightThBorder: '#8c86865e',
    rightNewThBorder: '#8c86865e',
    tfooterColor: '#46515C',
    // lightBorder: 'none',
    lightBorder: '#495057',
    grayColor1: '#d8d8d8',
    inputBgmNum: 'transparent',
    greenColor: '#1ABD50',
    greenBgm: '#1ABD50',
    blackNewColor: '#000',
    newGray: '#46515C',
    transparent: 'transparent',
    inputWhite: '#46515C',
    D8d8: '#d8d8d8',
    E6Border: '#46515C',
    spanBgm: '#57bd522b',
    spanBorder: '#1ABD50',
    newTheadColor: '#46515C',
    newTbodyColor: '#21272C',
    tdHover: '#363E46',
    newPagination: '#2B343D',
    span: '#2FC058',
    scroll: '#ffffff7a',
    scrollNew: '#46515C',
    whiteBodyBgm: '#21272C',
    paymentColor: '#234234',
    // new one
    // light: '#46515C',
    lightDark: '#2B343D',
    // border: '#46515C',
    bgGrey: '#363E46',
    thead: '#46515C',
    theadColor: 'rgb(254, 240, 1)',
    borderRight: '#495057',
    borderBottom: '1px solid #495057',
    // thBorder: '#8c86865e',
    thBottom: '#2B343D',
    pagination: '#2B343D',
    drawer: '#2B343D',
    button: '#2B343D',
    newButton: '#46515C',
    buttonColor: '#1ABD50',
    datePick: '#46515C',
    dateBox: '#fff',
    background: '#21272C',
    shadow: '0 0 0 2px rgb(26 189 80 / 20%)',
    breadCrumb: '#D8D8D8',
    newBorder: '#46515C',
    newThead: '#46515C',
    tbody: '#21272C',
    disclaimer: '#fef001',
    newTbody: '#363E46',
    blackColor: '#000',
    transaction: '#363E46',
    borderTransaction: '#495057',
    sorterColor: '#2FC058',
    greenTextColor: '#2FC058',
    portfolio: '#1ABD50',
  },
};
