import React from 'react';

import { Form, Row, Button } from 'antd';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';

import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {
  reportType: string;
  reportSubType: string;
}
const InternalReportSearch: React.FC<Props> = ({
  history,
  reportType,
  reportSubType,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const filters = {
      type: reportType.toLowerCase(),
      subtype: reportSubType.toLowerCase(),
    };
    history.push({
      pathname:
        `/search/report/` +
        queryString.stringify(filters, {
          skipNull: true,
        }),

      state: { prevPage: 'search' },
    });
  };
  return (
    <Form
      name="sector-filter-form"
      onFinish={onFinish}
      labelCol={{ span: 24, padding: 0 }}
      wrapperCol={{ span: 24 }}
      form={form}
      initialValues={{}}
    >
      <Row span={24} justify="start" className={styles.buttonSectionBox}>
        <Form.Item colon={false} style={{ margin: 0, marginTop: 20 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};
export default InternalReportSearch;
