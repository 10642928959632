import React, { useEffect, useState } from 'react';

import { FilterOutlined } from '@ant-design/icons';
import { Tabs, Layout, Button } from 'antd';
import { isEmpty } from 'lodash';
import { NavLink } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { Pagination, Table } from '../../components';
import {
  fetchStudentPerformanceStateReports,
  fetchStudentPerformanceStudentReports,
} from '../../graphql/gamification';
import { useTheme } from '../../ThemeContext';
import { errorHandler, formatColumn, usStates } from '../../utils';
import FilterForm from '../reports/FilterForm';
import FilteredValues from '../trendingData/FilteredValues';
import { arrangeColumns } from '../University/PopularStocks/PopularStocks';
import MapChart from './MapChart';
import styles from './styles.module.scss';

// const columns = [
//   {
//     title: 'Rank',
//     dataIndex: 'type',
//     key: 'type',
//   },
//   {
//     title: 'State',
//     dataIndex: 'name',
//     key: 'name',
//   },
//   {
//     title: 'Initial Investment',
//     dataIndex: 'symbols',
//     key: 'symbols',
//   },
//   {
//     title: 'Current Market Value',
//     dataIndex: 'institutions',
//     key: 'institutions',
//   },
//   {
//     title: '%Returns',
//     dataIndex: 'status',
//     key: 'status',
//   },
//   {
//     title: '#Students',
//     dataIndex: 'last_modified_at',
//     key: 'last_modified_at',
//   },
// ];

const { TabPane } = Tabs;
const { Content } = Layout;

// interface Props{

// };

const GamificationByState: React.FC<RouteComponentProps> = ({ history }) => {
  const [type, setType] = useState('GEOGRAPHIES');
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [openFilterForm, setOpenFilterForm] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [sortDetail, setSortDetail] = useState<Array>([]);
  const [content, setContent] = useState('');
  const [filters, setFilters] = useState({});
  console.log(content);
  useEffect(() => {
    setIsLoading(true);
    const payload = {
      page: pageNo,
      size: pageSize,
      sortBy: sortDetail[0]?.sortBy
        ? sortDetail[0].sortBy
        : 'student_performance_rank',
      sortOrder: sortDetail[0]?.sortOrder ? sortDetail[0].sortOrder : 'asc',
      filters: filters,
    };
    const promises = [
      type === 'GEOGRAPHIES'
        ? fetchStudentPerformanceStateReports(payload)
        : fetchStudentPerformanceStudentReports(payload),
    ];
    Promise.all(promises)
      .then((res) => {
        const rawTableData = res[0];
        for (const r of rawTableData.data) {
          usStates.forEach((i) => {
            if (i.abbreviation === r['school_state']) {
              r['school_state'] = i.name;
              return;
            }
          });
        }
        if (type === 'INDIVIDUAL') {
          res[0].columns.student_name = 'Student Name / Portfolio Snapshot';
        }
        setTableData(res[0]);
        // res[0].data.school_state
        const { columns = [] } = res[0];
        setTableColumns(arrangeColumns(columns));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [pageNo, pageSize, type, sortDetail, filters]);

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setPageNo(page);
  };
  const getStateData = (stateabbr) => {
    let stateData = '';
    usStates.forEach((i) => {
      if (i.abbreviation.toLowerCase() === stateabbr.toLowerCase()) {
        stateData = { stateName: i.name, id: i.id };
      }
    });
    return stateData;
  };

  const buttonComponent = (className, stateabbr) => {
    const stateData = getStateData(stateabbr);
    return (
      <NavLink
        to={{
          pathname: '/GamificationByCounty',
          state: {
            name: {
              properties: {
                name: stateData['stateName'],
              },
              id: stateData['id'],
            },
          },
        }}
      >
        <button className={className}>{stateabbr}</button>
      </NavLink>
    );
  };

  const setTypeFunction = (key) => {
    setType(key);
    setFilters({});
    setSortDetail([
      {
        sortBy: 'student_performance_rank',
        sortOrder: 'asc',
      },
    ]);
    setPageSize(10);
    setPageNo(1);
  };

  const onFilter = (val, sort) => {
    setFilters(val);
    if (sort && Array.isArray(sort)) {
      setSortDetail(sort);
    }
  };

  const handleSort = (sortBy, sortOrder) => {
    if (sortOrder) {
      setSortDetail([
        {
          sortBy: sortBy,
          sortOrder: sortOrder,
        },
      ]);
    } else {
      setSortDetail([]);
    }
  };

  const handleReset = () => {
    setFilters({});
  };

  const handleFilterValueClick = (name, value) => {
    const updatedFilters = { ...filters };
    delete updatedFilters[name];
    setFilters(updatedFilters);
    setOpenFilterForm(false);
  };

  const handleTableOnChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    let index = sortDetail.findIndex((d) => d.sortBy === field);
    if (index > -1) {
      let newOrder =
        order === 'ascend' ? 'ASC' : order === 'descend' ? 'DESC' : null;
      handleSort(field, newOrder, false);
    } else {
      if (order === 'ascend') {
        handleSort(field, 'ASC', false);
      } else if (order === 'descend') {
        handleSort(field, 'DESC', false);
      } else {
        handleSort(field, null, false);
      }
    }
  };

  const { data, total } = tableData;
  return (
    <Content className={styles.trendingContainer}>
      <Tabs
        defaultActiveKey={type}
        style={{ width: '100%' }}
        centered
        onChange={(key) => setTypeFunction(key)}
        id="#vTabsTrending"
      >
        <TabPane
          tab="By Geographies"
          key="GEOGRAPHIES"
          className={styles.vInvestorFixedDiv}
        >
          <div style={{ position: 'relative' }}>
            <h1
              style={{
                textAlign: 'center',
                color: 'var(--whitecolor)',
              }}
            >
              TS-YOUTH HEROES PERFORMANCE
            </h1>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div style={{ margin: 'auto' }}>
                <MapChart setTooltipContent={setContent} />
                <ReactTooltip className={styles.tooltip}>
                  {content}
                </ReactTooltip>
              </div>
              <div
                style={{
                  width: '120px',
                  border: '1px solid white',
                  height: '250px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  marginRight: '5%',
                  marginTop: '5%',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                >
                  {buttonComponent('className', 'VT')}
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                >
                  {buttonComponent('className', 'NH')}
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                >
                  {buttonComponent('className', 'MA')}
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                >
                  {buttonComponent('className', 'RI')}
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                >
                  {buttonComponent('className', 'CT')}
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                >
                  {buttonComponent('className', 'NJ')}
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                >
                  {buttonComponent('className', 'MD')}
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                >
                  {buttonComponent('className', 'DC')}
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                >
                  {buttonComponent('className', 'DE')}
                </div>
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                >
                  {buttonComponent('className', 'HI')}
                </div>
              </div>
            </div>
          </div>
          <h1
            style={{
              textAlign: 'center',
              color: 'var(--whitecolor)',
            }}
          >
            Heroes By State
          </h1>
          <div style={{ width: '45%', margin: 'auto' }}>
            <Table
              scroll={{ x: true }}
              loading={isLoading}
              columns={formatColumn(
                tableColumns,
                true,
                sortDetail,
                history,
                false,
                null,
                'alert',
                null,
                null,
                theme,
              )}
              dataSource={data}
              pagination={false}
              size="small"
              bordered={true}
              id="allPicksId"
              onChange={handleTableOnChange}
            />
            <div style={{ width: '140%' }}>
              <Pagination
                pageSize={pageSize}
                total={total}
                onChange={onPaginationChange}
                pageNo={pageNo}
                loading={isLoading}
                showPagination={true}
              />
            </div>
          </div>
        </TabPane>
        <TabPane
          tab="By Individual"
          key="INDIVIDUAL"
          className={styles.vInvestorFixedDiv}
        >
          <div>
            <h1
              style={{
                textAlign: 'center',
                color: 'var(--whitecolor)',
              }}
            >
              TS-YOUTH HEROES PERFORMANCE
            </h1>
            {!tableData?.data?.length ? (
              <span
                style={{
                  color: '#2FC058',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '100px',
                  fontSize: 30,
                }}
              >
                OOPS! No Positive Portfolio returns for the day.
              </span>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                  }}
                >
                  <div className={styles.filterValueBox}>
                    {!isEmpty(filters) &&
                      Object.values(filters).filter((d) => d).length > 0 && (
                        <FilteredValues
                          filterOptions={filters}
                          handleClick={handleFilterValueClick}
                          handleReset={handleReset}
                        />
                      )}
                  </div>
                  <Button
                    icon={<FilterOutlined />}
                    size={20}
                    onClick={() => {
                      setOpenFilterForm(!openFilterForm);
                    }}
                    //style={{ marginRight: 20 }}
                    className={styles.Vfilterbtn}
                    disabled={false}
                    id="reportColumnFilters"
                  >
                    Filter/Sort
                  </Button>
                </div>
                {openFilterForm && (
                  <FilterForm
                    visible={openFilterForm}
                    onCancel={() => setOpenFilterForm(false)}
                    onFilter={onFilter}
                    filterOptions={filters}
                    reportCode={'student'}
                    columns={tableColumns}
                    sortDetail={[
                      {
                        sortBy: sortDetail[0]?.sortBy
                          ? sortDetail[0]?.sortBy
                          : '',
                        sortOrder: sortDetail[0]?.sortOrder
                          ? sortDetail[0]?.sortOrder
                          : '',
                      },
                    ]}
                    // handleSortReset={() => setSortDetail([])}
                    // handleSortSubmit={(val) => setSortDetail(val)}
                  />
                )}
                <Table
                  scroll={{ x: true }}
                  loading={isLoading}
                  columns={formatColumn(
                    tableColumns,
                    true,
                    sortDetail,
                    history,
                    false,
                    null,
                    'alert',
                    null,
                    null,
                    theme,
                    null,
                    null,
                  )}
                  dataSource={data}
                  pagination={false}
                  size="small"
                  bordered={true}
                  id="allPicksId"
                  onChange={handleTableOnChange}
                />
                <div style={{ width: '100%' }}>
                  <Pagination
                    pageSize={pageSize}
                    total={total}
                    onChange={onPaginationChange}
                    pageNo={pageNo}
                    loading={isLoading}
                    showPagination={true}
                  />
                </div>
              </>
            )}
          </div>
        </TabPane>
      </Tabs>
    </Content>
  );
};

export default GamificationByState;
