import React from 'react';

import { get } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';

import { isStudentUrl } from '../../utils';
import StudentPrivacyPolicy from './StudentPrivacyPolicy';
import UserPrivacyPolicy from './UserPrivacyPolicy';

const PrivacyPolicy: React.FC<Props> = ({ history }) => {
  const { personalData } = useSelector(
    ({ user }) => ({
      personalData: get(user, 'personalData', {}),
    }),
    shallowEqual,
  );
  let isStudent = false;
  if (personalData['type']) {
    const userType = personalData['type'];

    isStudent = userType === 'student';
  } else {
    isStudent = isStudentUrl();
  }

  return isStudent ? (
    <StudentPrivacyPolicy history={history} />
  ) : (
    <UserPrivacyPolicy history={history} />
  );
};

export default PrivacyPolicy;
