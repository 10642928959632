/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const env_awsmobile = {
    test: {
        "aws_project_region": "us-east-1",
        "aws_appsync_graphqlEndpoint": "https://34kgxnzbgjfgngbl27tykpssmm.appsync-api.us-east-1.amazonaws.com/graphql",
        "aws_appsync_region": "us-east-1",
        "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
        "aws_appsync_apiKey": "da2-li4axw2lbnfbfflnkrtmvrbxim",
        "aws_cloud_logic_custom": [
            {
                "name": "ThinkSabioRESTAPI",
                "endpoint": "https://i8v85xkvyi.execute-api.us-east-1.amazonaws.com/test",
                "region": "us-east-1"
            }
        ],
        "aws_cognito_identity_pool_id": "us-east-1:5982b8e2-4a9d-4a44-98d2-36b181641e0d",
        "aws_cognito_region": "us-east-1",
        "aws_user_pools_id": "us-east-1_2YnPYeIHV",
        "aws_user_pools_web_client_id": "7dmhp9bf815m5dimpf8lsoa8to",
        "oauth": {
            "domain": "thinksabio-new-test.auth.us-east-1.amazoncognito.com",
            "scope": [
                "phone",
                "email",
                "openid",
                "profile",
                "aws.cognito.signin.user.admin"
            ],
            "redirectSignIn": "https://www.test.thinksabio.com/",
            "redirectSignOut": "https://www.test.thinksabio.com/",
            "responseType": "code"
        },
        "federationTarget": "COGNITO_USER_POOLS",
        "aws_cognito_username_attributes": [
            "EMAIL"
        ],
        "aws_cognito_social_providers": [
            "FACEBOOK",
            "GOOGLE"
        ],
        "aws_cognito_signup_attributes": [
            "EMAIL",
            "FAMILY_NAME",
            "GIVEN_NAME"
        ],
        "aws_cognito_mfa_configuration": "OFF",
        "aws_cognito_mfa_types": [
            "SMS"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": []
        },
        "aws_cognito_verification_mechanisms": [
            "EMAIL"
        ]
    },
    staging: {
        "aws_project_region": "us-east-1",
        "aws_appsync_graphqlEndpoint": "https://lcqkey77crcwlkhsnpray5gpou.appsync-api.us-east-1.amazonaws.com/graphql",
        "aws_appsync_region": "us-east-1",
        "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
        "aws_appsync_apiKey": "da2-dtbxpciisvbvhgwvgocyxfdfnq",
        "aws_cloud_logic_custom": [
            {
                "name": "ThinkSabioRESTAPI",
                "endpoint": "https://9psyajspw0.execute-api.us-east-1.amazonaws.com/staging",
                "region": "us-east-1"
            }
        ],
        "aws_cognito_identity_pool_id": "us-east-1:cf2f1227-b5bb-435a-9c7a-95cbd0dd577d",
        "aws_cognito_region": "us-east-1",
        "aws_user_pools_id": "us-east-1_ozvs6PZmk",
        "aws_user_pools_web_client_id": "krn4kr6ilef18jlv3ssrqakqu",
        "oauth": {
            "domain": "thinksabio-new-staging.auth.us-east-1.amazoncognito.com",
            "scope": [
                "phone",
                "email",
                "openid",
                "profile",
                "aws.cognito.signin.user.admin"
            ],
            "redirectSignIn": "https://www.stage.thinksabio.com/",
            "redirectSignOut": "https://www.stage.thinksabio.com/",
            "responseType": "code"
        },
        "federationTarget": "COGNITO_USER_POOLS",
        "aws_cognito_username_attributes": [
            "EMAIL"
        ],
        "aws_cognito_social_providers": [
            "FACEBOOK",
            "GOOGLE"
        ],
        "aws_cognito_signup_attributes": [
            "EMAIL",
            "FAMILY_NAME",
            "GIVEN_NAME"
        ],
        "aws_cognito_mfa_configuration": "OFF",
        "aws_cognito_mfa_types": [
            "SMS"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": []
        },
        "aws_cognito_verification_mechanisms": [
            "EMAIL"
        ]
    },
    production: {
        aws_project_region: 'us-east-1',
        aws_appsync_graphqlEndpoint:
          'https://7f53cjqiujhkjgi774ghipem5m.appsync-api.us-east-1.amazonaws.com/graphql',
        aws_appsync_region: 'us-east-1',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        aws_appsync_apiKey: 'da2-wdkwgajhnzbxfaqp5pehpj5zym',
        aws_cloud_logic_custom: [
            {
                name: 'ThinkSabioRESTAPI',
                endpoint:
                  'https://q4sm5umu35.execute-api.us-east-1.amazonaws.com/production',
                region: 'us-east-1',
            },
        ],
        aws_cognito_identity_pool_id:
          'us-east-1:1689cf91-819d-4c55-810e-2fafbc33678a',
        aws_cognito_region: 'us-east-1',
        aws_user_pools_id: 'us-east-1_3MY1hoKUN',
        aws_user_pools_web_client_id: 'jfq6a102rke21vam0k706h2nb',
        oauth: {
            domain: 'thinksabio-new-production.auth.us-east-1.amazoncognito.com',
            scope: [
                'phone',
                'email',
                'openid',
                'profile',
                'aws.cognito.signin.user.admin',
            ],
            redirectSignIn: 'https://www.thinksabio.com/',
            redirectSignOut: 'https://www.thinksabio.com/',
            responseType: 'code',
        },
        federationTarget: 'COGNITO_USER_POOLS',
        aws_cognito_username_attributes: ['EMAIL'],
        aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE'],
        aws_cognito_signup_attributes: ['EMAIL', 'FAMILY_NAME', 'GIVEN_NAME'],
        aws_cognito_mfa_configuration: 'OFF',
        aws_cognito_mfa_types: ['SMS'],
        aws_cognito_password_protection_settings: {
            passwordPolicyMinLength: 8,
            passwordPolicyCharacters: [],
        },
        aws_cognito_verification_mechanisms: ['EMAIL'],
    }
}


export default env_awsmobile;
