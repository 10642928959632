import React from 'react';

import { Link } from 'react-router-dom';

import { TelegramGroupsData } from '../../../utils';
import styles from './style.module.scss';

const TelegramGroups: React.FC<Props> = ({ history, match }) => {
  const { params } = match;
  const { type } = params;
  const { title, groups, image_url } = TelegramGroupsData[type];
  return (
    <div className={styles.telegramPage}>
      <div className={styles.universityHeaderView}>
        <h1>{title}</h1>
        <button onClick={() => history.goBack()}>BACK</button>
      </div>
      <div className={styles.telegram}>
        <div className={styles.groups}>
          {groups.map(({ name, number }) => (
            <div key={name} className={styles.border1}>
              <div className={styles.header}>
                <h3>{number}</h3>
              </div>
              <div className={styles.para}>
                <h4>{name}</h4>
              </div>
            </div>
          ))}
          <Link to="/community">
            Go to Community page to join Telegram Groups
          </Link>
        </div>
        <div className={styles.image}>
          <img src={image_url} alt="card" />
        </div>
      </div>
    </div>
  );
};

export default TelegramGroups;
