import React, { useState, useRef } from 'react';

import { Modal } from 'antd';
import Draggable from 'react-draggable';

const DraggableModal = ({
  visible,
  title,
  width,
  onCancel,
  footer,
  children,
}) => {
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <>
      <Modal
        title={title}
        visible={visible}
        //   onOk={handleOk}
        onCancel={onCancel}
        modalRender={(modal) => (
          <Draggable
            //disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        width={width}
        footer={footer}
      >
        {children}
      </Modal>
    </>
  );
};

export default DraggableModal;
