import React, { useState, useEffect, useCallback } from 'react';

import { FilterOutlined } from '@ant-design/icons';
import { Row, Col, DatePicker, Typography, Button, Input, Select } from 'antd';
import { get, debounce } from 'lodash';
import moment from 'moment-timezone';
// import queryString from 'query-string';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import { getOurPicks } from '../../graphql';
import { esSearchCompany } from '../../graphql';
import { noData } from '../../images';
import { setBioPicks } from '../../redux/reducers/picks';
import { green, errorHandler } from '../../utils';
import FilteredValues from './FilteredValues';
import { columns, FilterType } from './Pick.constant';
import PickFilter from './PickFilter';
import PicksList from './PickList';
import styles from './style.module.scss';

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface Props {
  history: Object;
  match: Object;
}
const BioPick: React.FC<Props> = ({ history, match }) => {
  const { data, picksQueryData } = useSelector(
    ({ picks }) => ({
      data: get(picks, 'bio', []),
      picksQueryData: {},
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [date, setDate] = useState([
    get(picksQueryData, 'pickFrom', null)
      ? moment(get(picksQueryData, 'pickFrom', null)).tz('America/New_York')
      : moment().tz('America/New_York').subtract(7, 'days'),
    get(picksQueryData, 'pickTo', null)
      ? moment(get(picksQueryData, 'pickTo', null)).tz('America/New_York')
      : moment().tz('America/New_York').subtract(1, 'days'),
  ]);
  const [openFilterForm, setOpenFilterForm] = useState<boolean>(false);
  const [filters, setFilters] = useState(get(picksQueryData, 'filters', {}));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortDetail, setSortDetail] = useState<Array>(
    get(picksQueryData, 'sort', []),
  );
  const [searchText, setSearchText] = useState<string>(
    get(picksQueryData, 'searchText', ''),
  );
  const [pageNo, setPageNo] = useState<number>(get(picksQueryData, 'page', 1));
  const [pageSize, setPageSize] = useState<number>(
    get(picksQueryData, 'size', 20),
  );
  const [type, setType] = useState(get(picksQueryData, 'type', 'symbol'));
  const [selectedSymbols, setSelectedSymbols] = useState(
    get(picksQueryData, 'symbols', []),
  );
  const [month, setMonth] = useState(
    get(picksQueryData, 'month', moment().format('MMM')),
  );
  const [year, setYear] = useState(
    get(picksQueryData, 'year', moment().get('year')),
  );
  const [symbolData, setSymbolData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [fetchData, setFetchData] = useState<Date>(new Date());
  useEffect(() => {
    setIsLoading(true);
    let baseFilter = {};
    if (type === 'year') {
      baseFilter = {
        year: parseInt(year),
      };
    }
    if (type === 'month') {
      baseFilter = {
        year: parseInt(year),
        month: parseInt(moment().month(month).format('MM')),
      };
    }
    if (type === 'range') {
      baseFilter = {
        pickFrom: date[0].format('YYYY-MM-DD'),
        pickTo: date[1].format('YYYY-MM-DD'),
      };
    }
    let allFilters = {
      ...baseFilter,
      ...filters,
    };
    if (type === 'symbol') {
      allFilters = {
        ...allFilters,
        symbols: selectedSymbols,
      };
    }

    const apis = [
      getOurPicks({
        request: {
          type: 'BIO',
          filters: allFilters,
          page: pageNo,
          size: pageSize,
          searchText: searchText,
          sort: sortDetail.filter((i) => i.sortBy),
        },
      }),
    ];

    Promise.all(apis)
      .then((data) => {
        dispatch(setBioPicks(data[0]));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    date,
    dispatch,
    history,
    month,
    pageNo,
    pageSize,
    searchText,
    selectedSymbols,
    type,
    year,
    fetchData,
    // filters,
    // sortDetail,
  ]);
  const onChange = (date, dateString) => {
    if (type === 'month') {
      const dateDate = dateString.split(' ');
      setYear(dateDate[1]);
      setMonth(dateDate[0]);
      setPageNo(1);
    }
    if (type === 'year') {
      setYear(dateString);
      setPageNo(1);
    }
    if (type === 'range') {
      setDate(date);
      setPageNo(1);
    }
  };
  const onFilter = (val: FilterType, sort) => {
    if (sort && Array.isArray(sort)) {
      setSortDetail(sort);
    }
    setFilters(val);
    setFetchData(new Date());
  };
  const changeSearchText = (value: string) => {
    setPageNo(1);
    setSearchText(value);
    // setFilterOptions({});
  };

  const debouncedSave = useCallback(
    debounce((value) => changeSearchText(value), 500),
  );

  const onTextChange = (event) => {
    const { value } = event.target;
    debouncedSave(value);
  };

  const changeType = (val) => {
    setType(val);
    setPageNo(1);
  };
  const handleFilterValueClick = (name, value) => {
    let updatedFilters = { ...filters };
    if (name === 'symbols') {
      updatedFilters = {
        ...filters,
        symbols: updatedFilters.symbols.filter((s) => s !== value),
      };
    } else if (name === 'sector') {
      updatedFilters = {
        ...filters,
        sector: Array.isArray(updatedFilters.sector)
          ? updatedFilters.sector.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'multiMarketCap') {
      updatedFilters = {
        ...filters,
        multiMarketCap: Array.isArray(updatedFilters.multiMarketCap)
          ? updatedFilters.multiMarketCap.filter((s) => s !== value)
          : undefined,
      };
    } else {
      delete updatedFilters[name];
    }

    setFilters(updatedFilters);
    setFetchData(new Date());
    setOpenFilterForm(false);
  };

  const fetchSymbols = async (value) => {
    setFetching(true);
    const res = await esSearchCompany(value);
    if (res) {
      setSymbolData(res);
    }
    setFetching(false);
  };
  const onSymbolSelect = (symbol) => {
    const newSymbols = [...selectedSymbols];
    newSymbols.push(symbol);
    setSelectedSymbols(newSymbols);
    setSymbolData([]);
  };

  const onSymbolDeSelect = (symbol) => {
    const symbols =
      (selectedSymbols || []).filter((sym) => sym !== symbol) || [];
    setSelectedSymbols(symbols);
  };

  const isFilterEmpty = Object.keys(filters).filter((d) =>
    d === 'symbols' || d === 'sector'
      ? filters[d] && filters[d].length === 0
        ? undefined
        : filters[d]
      : filters[d],
  );
  const handleReset = () => {
    setFilters({});
    setFetchData(new Date());
  };
  const handleSort = (val) => {
    setSortDetail(val);
    setFetchData(new Date());
  };

  return (
    <>
      <Row justify="space-between">
        <Col className={styles.vSiteCalendarDemoCard1}>
          <Select
            value={type}
            onChange={changeType}
            className={styles.vDateRangeSelectBlockNew}
          >
            {/* <Option value="date">Date</Option> */}
            <Option value="range">Date Range</Option>
            <Option value="month">Month</Option>
            <Option value="year">Year</Option>
            <Option value="symbol">Symbol</Option>
          </Select>

          {type === 'month' && (
            <DatePicker
              picker="month"
              onChange={onChange}
              disabledDate={(currentDate) => currentDate > moment()}
              allowClear={false}
              // defaultValue={moment()}
              defaultValue={moment().set('year', year).set('month', month)}
              format={'MMM YYYY'}
              className={styles.vDateBlocknew2}
            />
          )}
          {type === 'year' && (
            <DatePicker
              picker="year"
              onChange={onChange}
              disabledDate={(currentDate) => currentDate > moment()}
              allowClear={false}
              // defaultValue={moment()}
              defaultValue={moment().set('year', year)}
              format={'YYYY'}
            />
          )}
          {type === 'range' && (
            <RangePicker
              disabledDate={(currentDate) =>
                currentDate > moment().tz('America/New_York')
              }
              style={{ width: '100%' }}
              onChange={onChange}
              value={date}
              allowClear={false}
              format="DD MMM YYYY"
            />
          )}
          {type === 'symbol' && (
            <Select
              placeholder="Enter valid symbols"
              mode="multiple"
              style={{ width: 300 }}
              notFoundContent={
                fetching ? (
                  <span className={styles.spinnerStyle}>
                    <BounceLoader color={green} loading={true} size={50} />
                  </span>
                ) : null
              }
              defaultValue={selectedSymbols}
              onSearch={fetchSymbols}
              filterOption={false}
              showSearch
              allowClear={selectedSymbols.length > 1}
              onSelect={(key) => onSymbolSelect(key)}
              onDeselect={(key) => onSymbolDeSelect(key)}
              onClear={() => setSelectedSymbols([])}
              size="middle"
            >
              {symbolData.map((d) => (
                <Option key={d.symbol} className={styles.optionStyle}>
                  <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                  {d.name}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col className={styles.vFilterNewV2}>
          <div className={styles.reportOptionBlock}>
            <Input
              placeholder="search..."
              onChange={onTextChange}
              className={styles.VdailySearch}
              allowClear
            />
          </div>
          <Button
            icon={<FilterOutlined />}
            size={20}
            onClick={() => {
              setOpenFilterForm(!openFilterForm);
            }}
            //style={{ marginRight: 20 }}
            className={styles.Vfilterbtn}
            disabled={false}
            id="reportColumnFilters"
          >
            Filter/Sort
          </Button>
        </Col>
      </Row>
      <div className={styles.pickUpSectionBox}>
        <div className={styles.pickupLeftBox}>
          {type === 'range' && (
            <Title level={5}>{`Picks for ${date[0].format(
              'DD MMM YYYY',
            )} - ${date[1].format('DD MMM YYYY')}`}</Title>
          )}
          {type === 'month' && (
            <Title level={5}>{`Picks for ${month} ${year}`}</Title>
          )}
          {type === 'year' && <Title level={5}>{`Picks for  ${year}`}</Title>}
          {type === 'symbol' && <Title level={5}>{`Picks By Symbol`}</Title>}
        </div>
        <div className={styles.pickupRightBox}>
          {isFilterEmpty.length > 0 && (
            <FilteredValues
              filterOptions={filters}
              handleClick={handleFilterValueClick}
              handleReset={handleReset}
            />
          )}
        </div>
      </div>
      {data.length > 0 && (
        <PicksList
          isLoading={isLoading}
          data={data}
          type="bio"
          setSortDetail={(val) => handleSort(val)}
          setSearchText={(val) => setSearchText(val)}
          setPageNo={(val) => setPageNo(val)}
          setPageSize={(val) => setPageSize(val)}
          sortDetail={sortDetail}
          pageSize={pageSize}
          pageNo={pageNo}
          history={history}
        />
      )}
      {data.length === 0 && !isLoading && (
        <Row justify="space-between" span={24}>
          <div className={styles.noDataBox}>
            <div className={styles.noDataBoxContent}>
              <img src={noData} alt="no_data" />
              <p className={styles.vNoPicks}>No Picks</p>
            </div>
          </div>
        </Row>
      )}

      {openFilterForm && (
        <PickFilter
          visible={openFilterForm}
          onCancel={() => setOpenFilterForm(false)}
          onFilter={onFilter}
          filterOptions={filters}
          columns={columns}
          sortDetail={sortDetail}
          // handleSortReset={() => setSortDetail([])}
          // handleSortSubmit={(val) => setSortDetail(val)}
          type="BIO"
        />
      )}
    </>
  );
};

export default BioPick;
