export const errorHandler = (error: any) => {
  const { message = '' } = error;
  if (
    error === 'No current user' ||
    message === 'Refresh Token has been revoked'
  ) {
    window.location.reload();
  }

  console.log(error);
};
