import React from 'react';

import { Form, Row, Button } from 'antd';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';

import styles from './style.module.scss';

// const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  reportType: string;
  reportSubType: string;
}
const SecInsiderSearch: React.FC<Props> = ({
  history,
  reportType,
  reportSubType,
}) => {
  const [form] = Form.useForm();
  // const [symbolData, setSymbolData] = useState([]);
  // const [fetching, setFetching] = useState<boolean>(false);

  const onFinish = (values) => {
    // const { insiderName, startDate, endDate, symbols } = values;
    const filters = {
      type: reportType.toLowerCase(),
      subtype: reportSubType.toLowerCase(),
      // secTo: moment().format('YYYY-MM-DD'),
    };
    // if (insiderName) {
    //   filters.insiderName = insiderName;
    // }
    // if (startDate) {
    //   filters.startDate = startDate.format('YYYY-MM-DD');
    // }
    // if (endDate) {
    //   filters.endDate = endDate.format('YYYY-MM-DD');
    // }
    // if (symbols) {
    //   filters.symbols = symbols;
    // }
    // history.push(
    //   `/search/report/` +
    //     queryString.stringify(filters, {
    //       skipNull: true,
    //     }),
    // );
    history.push({
      pathname:
        `/search/report/` +
        queryString.stringify(filters, {
          skipNull: true,
        }),

      state: { prevPage: 'search' },
    });
  };

  // const fetchSymbols = async (value) => {
  //   setFetching(true);
  //   const res = await esSearchCompany(value);
  //   if (res) {
  //     setSymbolData(res);
  //   }
  //   setFetching(false);
  // };
  return (
    <Form
      name="sector-filter-form"
      onFinish={onFinish}
      labelCol={{ span: 24, padding: 0 }}
      wrapperCol={{ span: 24 }}
      form={form}
      initialValues={{}}
    >
      {/* <Row span={24}>
        {reportSubType !== 'INSIDER_SYMBOL_SUMMARY' && (
          <Col span={24}>
            <Form.Item
              name="insiderName"
              label="Insider Name"
              style={{ width: '100%' }}
              className={styles.vFormSearhNew}
              // rules={[
              //   {
              //     required: true,
              //     message: `Name is required`,
              //   },
              // ]}
            >
              <Input
                placeholder="Insider Name"
                style={{ width: '100%' }}
                size="large"
              />
            </Form.Item>
          </Col>
        )}

        {reportSubType !== 'INSIDER_SYMBOL_SUMMARY' && (
          <Col span={24}>
            <Form.Item
              name="symbols"
              label="Search Symbols"
              style={{ width: '100%' }}
              className={styles.vFormSearhNew}
            >
              <Select
                placeholder="Enter valid symbols"
                mode={'multiple'}
                notFoundContent={
                  fetching ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                onSearch={fetchSymbols}
                showSearch={true}
                allowClear={true}
                showArrow={false}
                filterOption={false}
                onSelect={() => setSymbolData([])}
                size="large"
              >
                {symbolData.map((d) => (
                  <Option key={d.symbol} className={styles.optionStyle}>
                    <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                    {d.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {reportSubType === 'INSIDER_DETAIL_SUMMARY_REPORT' && (
          <Col span={12}>
            <Form.Item
              name="startDate"
              label="Start Date"
              style={{ width: '100%', marginBottom: 5 }}
            >
              <DatePicker
                disabledDate={(currentDate: Date) =>
                  currentDate > moment().tz('America/New_York') ||
                  currentDate <=
                    moment().tz('America/New_York').subtract(1, 'year')
                }
                // onChange={onChange}
                // defaultValue={date}
                clearIcon={false}
                // format={'DD MMM YYYY'}
                className={styles.vDatePickerNew1}
              />
            </Form.Item>
          </Col>
        )}
        {reportSubType === 'INSIDER_DETAIL_SUMMARY_REPORT' && (
          <Col span={12}>
            <Form.Item
              name="endDate"
              label="End Date"
              style={{ width: '100%', marginBottom: 5 }}
            >
              <DatePicker
                disabledDate={(currentDate: Date) =>
                  currentDate > moment().tz('America/New_York') ||
                  currentDate <=
                    moment().tz('America/New_York').subtract(1, 'year')
                }
                // onChange={onChange}
                // defaultValue={date}
                clearIcon={false}
                // format={'DD MMM YYYY'}
                className={styles.vDatePickerNew1}
              />
            </Form.Item>
          </Col>
        )}
      </Row> */}
      <Row span={24} justify="start" className={styles.buttonSectionBox}>
        <Form.Item colon={false} style={{ margin: 0, marginTop: 20 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};
export default SecInsiderSearch;
