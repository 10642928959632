import initialState from '../initialState';
// Actions

const SET_DAILY_DATA = 'trending/SET_DAILY_DATASET_DAILY_DATA';
const SET_MONTHLY_DATA = 'trending/SET_MONTHLY_DATA';
const SET_QUARTERLY_DATA = 'trending/SET_QUARTERLY_DATA';
const RESET_TRENDING_DATA = 'trending/RESET_TRENDING_DATA';
// const SET_TRENDING_QUERY_DATA = 'trending/SET_TRENDING_QUERY_DATA';

// Reducer
export default function reducer(state = initialState.trending, action = {}) {
  switch (action.type) {
    case SET_DAILY_DATA:
      return {
        ...state,
        daily: {
          ...state.daily,
          [action.payload.type]: action.payload.data,
        },
      };
    case SET_MONTHLY_DATA:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          [action.payload.type]: action.payload.data,
        },
      };
    case SET_QUARTERLY_DATA:
      return {
        ...state,
        quarterly: {
          ...state.quarterly,
          [action.payload.type]: action.payload.data,
        },
      };
    // case SET_TRENDING_QUERY_DATA:
    //   return {
    //     ...state,
    //     queryData: {
    //       ...state.queryData,
    //       [action.payload.type]: action.payload.data,
    //     },
    //   };
    case RESET_TRENDING_DATA:
      return {};
    default:
      return state;
  }
}

// Action Creators

export function setDailyTrendingData(data, type) {
  return {
    type: SET_DAILY_DATA,
    payload: { type: type, data: data },
  };
}

export function setMonthlyTrendingData(data, type) {
  return {
    type: SET_MONTHLY_DATA,
    payload: { type: type, data: data },
  };
}

export function setQuarterlyTrendingData(data, type) {
  return {
    type: SET_QUARTERLY_DATA,
    payload: { type: type, data: data },
  };
}

// export function setTrendingQueryData(data, type) {
//   return {
//     type: SET_TRENDING_QUERY_DATA,
//     payload: { type: type, data: data },
//   };
// }

export function resetTrendingData() {
  return {
    type: RESET_TRENDING_DATA,
  };
}
