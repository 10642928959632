import React from 'react';

import { NavLink } from 'react-router-dom';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import {
  intern1,
  intern10,
  intern2,
  intern4,
  intern5,
  intern7,
  intern9,
} from '../../images';

const internsName = {
  intern1Main: 'Keerthi @Intern Thinksabio',
  intern2Main: 'Sivani @Intern Thinksabio',
  intern3Main: 'Haren @Intern Thinksabio',
  intern4Main: 'Hardik @ Intern Thinksabio',
  intern5Main: 'Saanvi @ Intern Thinksabio',
  intern6Main: 'Aarushi @Intern Thinksabio',
  intern7Main: 'Jia @Intern Thinksabio',
  intern8Main: 'Garuav @Intern Thinksabio',
  intern9Main: 'Harshini @Intern Thinksabio',
  intern10Main: 'Hariharan @Intern Thinksabio',
  intern1: 'Institutional Presentation By Keerthi Padavala',
  intern2: 'Institutional Presentation By Sivani Pasam',
  intern3: 'Institutional Presentation By Haren Cherivi',
  intern4: 'Institutional Presentation By Hardik Uppal',
  intern5: 'Institutional Presentation By Saanvi Gireesh',
  intern6: 'Institutional Presentation By Aarushi Manikkam',
  intern7: 'Institutional Presentation By Jia Varshney',
  intern8: 'Institutional Presentation By Garuav Nair',
  intern9: 'Institutional Presentation By Harshini Dandapani',
  intern10: 'Institutional Presentation By Hariharan Ashok',
};
const getHtmlCode = (intern) => {
  switch (intern) {
    case 'intern1':
    case 'intern1Main':
      return (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{
                height: '300px',
                width: '300px',
                marginTop: '20px',
                marginBottom: '50px',
              }}
              alt="intern1"
              src={intern1}
            />
          </div>
          <p
            style={{
              color: '#1abd50',
              fontSize: '1.2rem',
              width: '1200px',
              margin: 'auto',
            }}
          >
            This week, on Tuesday (June 28, 2022), we had presentations on
            institutions in our daily stand-up. This was the first big project
            our interns worked on after using the virtual portfolio and learning
            about the ThinkSabio website. There were a lot of engaging
            conversations as people shared their insights about successful
            investors.
            <br />
            <br />
            Millennium and Tiger Global - Gaurav and Aarushi
            <br />
            <br />
            We started our presentations with Gaurav and Aarushi, who presented
            Millennium and Tiger Global. Learning about Millennium's
            multi-strategy hedge fund offering was interesting because all big
            institutions have proven to be successful with their earnings, but
            seeing the thought process behind the successful decision allowed me
            to gain insight into the management firm's growth. After listening
            to the presentation on Tiger Global, I was very impressed to find
            that it funds more startups than any other investor and invests
            almost half of its assets into the technology sector. Most big
            companies ignore the power of small companies, but Tiger Global was
            different from the normal, making it unique and fascinating.
            <br />
            <br />
            Bluecrest and Renaissance - Haren
            <br />
            <br />
            Following the first group's presentations, we heard from Haren about
            Bluecrest and Renaissance Technologies. Bluecrest was an intriguing
            company because it has many of its stocks in the financial services
            sector, which made it distinct from the other institutions that
            invested mainly in technology companies. Everyone was extremely
            excited to learn about Renaissance Technologies since it is known
            for its ability to use mathematics and technology to consistently
            produce great returns. Moreover, its founder, Jim Simons, inspired
            the foundation of ThinkSabio.
            <br />
            <br />
            Citadel - Rishi
            <br />
            <br />
            Subsequently, Rishi presented about Citadel. Citadel stuck out from
            the institutions due to its high employee satisfaction rate.
            Needless to say, large companies are risk-averse, which makes them
            stressful places to work. However, Citadel has proven to be a
            wonderful place to work from its rank on the Fortune list of 50 best
            workplaces for new college grads.
            <br />
            <br />
            Bridgewater - Teja
            <br />
            <br />
            Our presentations continued with Teja discussing Bridgewater. We
            were all blown away by the facts presented about Bridgewater
            considering that it currently is the world's largest hedge fund with
            around $223 billion AUM. Earning such amounts of money is not a
            simple task, but hearing about the growth of the company, the
            company's history, and its strategies of the company, shows us that
            it is not impossible.
            <br />
            <br />
            Two Sigma - me
            <br />
            <br />
            We ended our session with my presentation about Two Sigma. The
            investment strategies impressed me because Two Sigma's scientific
            approach is successful due to talented scientists who make up
            two-thirds of the 1600+ employees in the company and have 4800+
            years of research. In addition, when I was researching Two Sigma, I
            was very surprised that the company has a number of Employee
            Resource Groups that encourage employees to gather together as a
            community to share experiences and combine efforts to drive change
            since large companies tend to ignore individual employee needs.
            <br />
            <br />
            Sai inputs
            <br />
            <br />
            We are all very grateful that we conducted this session with Sai.
            Throughout the meeting, the team had many intuitive conversations
            with Sai about the falling market, the benefits of diversity in
            investments, and the learnings we can take away from hedge funds. On
            top of that, Sai recommended the interns read inspiring books about
            investing, including The Man Who Solved the Market about Jim Simons
            (Renaissance Technologies' founder) and Principles by Ray Dalio
            (Bridgewater's founder)
            <br />
            <br />
            Listening to the presentations from all the interns was very
            knowledgeable, and I really enjoyed hearing from all the interns
            about their research. Learning about different hedge funds and their
            strategies gave me multiple views on how large companies grow and
            become successful. Thank you to everyone who made this session
            possible! I am looking forward to having more interactive sessions
            with the interns, and I can't wait to change the team will make
            together!
            <br />
            <br />
            Keerthi Padavala (Lead Intern)
            <br />
            <br />
          </p>
        </>
      );
    case 'intern2':
    case 'intern2Main':
      return (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{
                height: '300px',
                width: '300px',
                marginTop: '20px',
                marginBottom: '50px',
              }}
              alt="intern2"
              src={intern2}
            />
          </div>
          <p
            style={{
              color: '#1abd50',
              fontSize: '1.2rem',
              width: '1200px',
              margin: 'auto',
            }}
          >
            I think the designs were really nice and the slides were easy to
            read. The designs were neat and there wasn't an overwhelming amount
            of information on the slides themselves. The illustrations on some
            of them were also really nice. The presenters also didn't talk too
            fast or too slow. They made sure the information would be understood
            by the audience and they did good research, and I learned some
            things. I felt like the presentations were really informative and I
            think they contributed a lot of knowledge. I felt as if the
            presenters worked hard on the presentations, and they made sure to
            get all the basic points down. Something specific I learned was that
            Millennium started in New York in 1989 and it was started by Israel
            A. Englander and Ronald Shear. I also learned that the company is
            worth around $57.5 billion right now. The presentations were all
            very informative and I feel as if they gave me a lot more insight. I
            think some information could be a little condensed but overall, they
            were all really good.
            <br />
            <br />
          </p>
        </>
      );
    case 'intern3':
    case 'intern3Main':
      return (
        <>
          <p
            style={{
              color: '#1abd50',
              fontSize: '1.2rem',
              width: '1200px',
              margin: 'auto',
            }}
          >
            I thought the presentations were very interesting since I was able
            to learn some new things from my peers. Everyone was interacting
            very well and were bouncing knowledge off of each other which I
            thought was helpful especially if your passions and interests lie
            within investing and business. Overall, it was a super fun
            experience for me to learn new things.
            <br />
            <br />
          </p>
        </>
      );
    case 'intern4':
    case 'intern4Main':
      return (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{
                height: '300px',
                width: '300px',
                marginTop: '20px',
                marginBottom: '50px',
              }}
              alt="intern4"
              src={intern4}
            />
          </div>
          <p
            style={{
              color: '#1abd50',
              fontSize: '1.2rem',
              width: '1200px',
              margin: 'auto',
            }}
          >
            At first, I was extremely excited to explore institutions and see
            what institutions my group would get assigned. When I heard that we
            were assigned Tiger Global and Millennium, I was a little worried. I
            didn’t have prior knowledge of the institutions; I had never even
            heard of the institutions. So, I decided to research. With the
            research, I worked with my group to create an eleven-slide
            presentation for each of the institutions. I learned a lot of new
            things during this process. Some of the things I learned include
            investment strategies, history, and AUM for both the institutions.
            <br />
            <br />
            I was, unfortunately, unable to make it to the presentations, but I
            did watch the recording. My group and everyone else did an amazing
            job presenting. The presentations were informative and engaging. I
            definitely learned a lot about other institutions such as their
            histories and investments.
            <br />
            <br />
            Overall, this project was extremely fun and informational. Not only
            did I learn a lot, but I also had fun. It was fun working and
            engaging with new people. This project allowed me to expand my
            knowledge of stocks and get closer with other interns. I would
            definitely want to do more projects like this one. My favorite part
            of the project was working with others to create the presentation.
            Because we were open to each other’s ideas, we were able to work
            effectively. I am super excited to work on more projects!
            <br />
            <br />
          </p>
        </>
      );
    case 'intern5':
    case 'intern5Main':
      return (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{
                height: '300px',
                width: '300px',
                marginTop: '20px',
                marginBottom: '50px',
              }}
              alt="intern5"
              src={intern5}
            />
          </div>
          <p
            style={{
              color: '#1abd50',
              fontSize: '1.2rem',
              width: '1200px',
              margin: 'auto',
            }}
          >
            My greatest takeaway is the importance in learning from hedge funds
            and seeing how they adapt to the current market trends, prevent
            failures, and how they chose what to invest in. I also learned to
            take a chance in learning from the decisions of hedge funds because
            they have more to lose, therefore its slightly easier for us
            beginners to take inspiration from them. I was taught tidbits of
            information about a few successful hedge companies such as data
            about their growth, start up, market value, and investment
            strategies. For instance, the hedge company Millennium Management
            teaches its clients to invest through multiple successful strategies
            but is not FDIC insured. Another, Tiger Global is a hedge fund
            management firm devoted to investing in the private equity market.
            This equity trading corporation is largely successful due to the
            vast number of startups it funds in the US. However, they are
            heavily impacted by selloffs. Additionally, Bluecrest Capital
            focuses on a macro-orientated strategy and invests in financial
            services largely more than in the technology sector. A similarity in
            these companies’ success lies in diversifying their stocks to reduce
            risk in case their stock falls so that their portfolio can remain
            stable and to maintain a consistent flow of money. One of the
            largest and most successful hedge companies-Renaissance
            Technologies, trades largely based on the statistics that show the
            likelihood of success from trading or investing in a stock. It is
            one of the top hedge funds in terms of generating profits for it
            share holders and has the highest return on investment rate in
            history. The founder discovered a pattern using his advanced math
            background. Lastly, the hedge fund Citadel does a lot of high
            frequency trading and competes in the global market even thought it
            appears they mostly focuses on the larger companies to invest in.
            Citadel tries to maximize on its returns through trading and allows
            its employees to be creative, therefore it is positively viewed as a
            good environment to work in. Currently many investors and hedge
            companies believe that China will have the next leading
            market/economy so many companies like Bridgewater are investing in
            Chinese companies to diversify their portfolios. Also, Bridgewater
            company has a lot of influence throughout the world because of its
            positive impact for helping other businesses. Lastly, Two Sigma
            Investments is highly dependent on machine learning. In conclusion,
            many of these hedge companies use powerful resources such as
            employees, money, connections, diversification, and patterns to stay
            afloat. If such successful companies know how to maneuver their
            assets and clients while being successful in our fluctuating market,
            then we should learn from their decisions, patterns, and
            transactions.
            <br />
            <br />
          </p>
        </>
      );
    case 'intern6':
    case 'intern6Main':
      return (
        <>
          <p
            style={{
              color: '#1abd50',
              fontSize: '1.2rem',
              width: '1200px',
              margin: 'auto',
            }}
          >
            After researching the hedge funds Tiger Global and Millennium, there
            was a lot of information I took away regarding how they run their
            company as well as how companies navigate the stock market to help
            investors make the best investments. I specifically enjoyed
            researching each companies investment strategies as how the
            investment strategies play a huge role in the success of these
            companies. In addition, I further researched these investment
            strategies especially ones Millennium uses and saw how those
            strategies could help me as an investor. Overall I enjoyed
            researching Tiger Global and Millennium and following along the
            other hedge funds discussed as well.
            <br />
            <br />
          </p>
        </>
      );
    case 'intern7':
    case 'intern7Main':
      return (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{
                height: '300px',
                width: '300px',
                marginTop: '20px',
                marginBottom: '50px',
              }}
              alt="intern7"
              src={intern7}
            />
          </div>
          <p
            style={{
              color: '#1abd50',
              fontSize: '1.2rem',
              width: '1200px',
              margin: 'auto',
            }}
          >
            Learning about different hedge fund companies was very interesting.
            If you're a large company, any risks that fail will negatively
            affects the company and its workers greatly, so the company has to
            make its investing decisions with great consideration. Seeing the
            different methods that these companies used was enlightening. Though
            not all of them could be applied to the average investor, they are
            still valuable to learn and understand. When the risks taken did not
            pay off, at the very least, something is learned, and as someone
            said in their presentations, learning means that all is not lost.
            <br />
            <br />
            The general trend in the presentations we saw was that the companies
            would not be the most enjoyable to work in, as hedge companies
            require a lot of skill and have a high risk factor. However,
            according to one of the presenters and a few people I know, Citadel
            is a nice place to work. Doing some research of my own, I found out
            that 84% of their employees enjoy their job, compared to the average
            57% enjoyment of a company. Citadels base salary also starts out
            relatively high, and as it is more expensive to live in Chicago
            their salary options seem reasonable. To conclude, though not
            perfect, Citadel is a good company to work at and something can be
            learned by learning it's story.
            <br />
            <br />
          </p>
        </>
      );
    case 'intern8':
    case 'intern8Main':
      return (
        <>
          <p
            style={{
              color: '#1abd50',
              fontSize: '1.2rem',
              width: '1200px',
              margin: 'auto',
            }}
          >
            When my group and I made our presentations we learned a lot of
            valuable information and I will be sharing that information with you
            in this blog post. One of the most important things that I have
            learnt is the way that these companies invest their money. They
            don´t put all their money into one specific place, they diversify
            their investments. They also wait for a while before taking their
            investments out. They think long term rather than short term. We
            learned that the biggest thing that made them grow was patience.
            Another thing that made them grow was knowledge. Once they put their
            knowledge and their patience together they were able to succeed.
            Another thing that I learned was that recently they started
            investing a majority of their money in technology companies, and
            that was where most of the growth was coming from. I have learned
            that these companies make decisions as a group and get insight from
            many different people, as opposed to people who invest by
            themselves. This makes them more likely to succeed because they are
            able to learn from other people. The most important asset that they
            have is their knowledge and their patience. They were able to
            capitalize on their knowledge, and helped their company grow.
            <br />
            <br />
          </p>
        </>
      );
    case 'intern9':
    case 'intern9Main':
      return (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{
                height: '300px',
                width: '300px',
                marginTop: '20px',
                marginBottom: '50px',
              }}
              alt="intern9"
              src={intern9}
            />
          </div>
          <p
            style={{
              color: '#1abd50',
              fontSize: '1.2rem',
              width: '1200px',
              margin: 'auto',
            }}
          >
            I thought these slides were really helpful! Specifically, one thing
            I learned after watching the first presentation was that Millennium
            management started out in New York and started with 20 employees.
            The management is a multi strategy hedge fund offering. This was
            just one of the many facts I learned throughout the presentations.
            All the slides were really nice and made it so it was
            understandable. Something that I really liked was how all the slides
            were paced and how they looked. They were easy on the eye for views
            and simple to understand as the information was very concise and
            straight to the point. However, something that I would say that
            would improve the quality and understandability for viewers is if
            the people presenting talked a little more on the topic and were
            more brief. As the video is quite lengthy with multiple
            presentations, it would’ve been nicer if the information was said a
            little more faster
            <br />
            <br />
          </p>
        </>
      );
    case 'intern10':
    case 'intern10Main':
      return (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{
                height: '300px',
                width: '300px',
                marginTop: '20px',
                marginBottom: '50px',
              }}
              alt="intern10"
              src={intern10}
            />
          </div>
          <p
            style={{
              color: '#1abd50',
              fontSize: '1.2rem',
              width: '1200px',
              margin: 'auto',
            }}
          >
            Investing is a way that people and companies can make or lose money.
            People use a lot of websites to invest in the stock market and see
            what companies are rising and falling. One of these websites is
            called Thinksabio and not only does it allow people to invest their
            money in stock portfolios, but it also allows kids to make their own
            virtual portfolios so that they can learn about the stock market
            without losing any money. This is a great website to teach young
            adults and aspiring investors how to earn money from stocks. Here’s
            how the website can teach teenagers how to invest and trade stocks
            while also learning from their mistakes so that they can prepare
            themselves for the real market. There are two versions of the
            website, one that is used by regular traders and one that is used
            for educational purposes during internship programs in the summer.
            The website has two versions of stock portfolios, one is a practice
            portfolio for beginners that allows them to invest virtual money in
            real life companies and the stock prices of those companies are also
            affected by real life rises and falls. Then there is the actual
            portfolio which allows investors and stock traders to buy and sell
            stocks from different companies and allow them to grow their money,
            like any other banking and investment website and app. The website
            also allows users to diversify different stocks so that they can
            invest in the best ones and not make any poor choices, thus allowing
            investors who are members of the website to make the best choices
            without any sense of uneasiness. Personally, I think that the
            website is great for me because it allows me to use the virtual
            portfolio to invest in stocks that are from real life companies and
            the performance of the companies also affects the virtual portfolio.
            This allows learning investors how the market can change at times
            and what they can do to prevent their money from losing their value
            and falling
            <br />
            <br />
          </p>
        </>
      );
  }
};
// const internHtml = {
//   intern1: ,
// };

const BlogPage: React.FC<RouteComponentProps> = () => {
  window.scrollTo(0, 0);
  const location = useLocation();
  const intern = location.state?.name?.properties?.name;
  const page = location.state?.name?.properties?.page;
  console.log(intern);
  return (
    <div style={{ minHeight: '635px' }}>
      <p
        style={{
          top: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#1abd50',
          marginTop: '100px',
          fontFamily: `'Jost', sans-serif`,
          fontSize: '3rem',
          lineHeight: '1.1',
          fontWeight: 'bold',
        }}
      >
        {internsName[intern]}
      </p>
      <NavLink
        to={{
          pathname: page === 'main' ? '/Blog' : '/BlogSubPage',
          state: {
            name: {
              properties: {
                name: 'ip',
              },
            },
          },
        }}
      >
        <button
          style={{
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#1abd50',
            fontSize: '1.1rem',
            margin: 'auto',
            marginBottom: '30px',
            height: '50px',
            width: '100px',
            borderRadius: '10px',
          }}
        >
          <strong>Back</strong>
        </button>
      </NavLink>
      {intern ? getHtmlCode(intern) : ''})
    </div>
  );
};

export default BlogPage;
