import React from 'react';

import { get } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';

import { isStudentUrl } from '../../utils';
import StudentTermsAndConditions from './StudentTerms&Conditions';
import styles from './style.module.scss';
import UserTermsAndConditions from './UserTerms&Conditions';

const Tnc: React.FC<Props> = ({ history }) => {
  const { personalData } = useSelector(
    ({ user }) => ({
      personalData: get(user, 'personalData', {}),
    }),
    shallowEqual,
  );
  let isStudent = false;
  if (personalData['type']) {
    const userType = personalData['type'];

    isStudent = userType === 'student';
  } else {
    isStudent = isStudentUrl();
  }
  return (
    <div className={styles.termandConditionBox}>
      {isStudent ? (
        <StudentTermsAndConditions history={history} />
      ) : (
        <UserTermsAndConditions history={history} />
      )}
    </div>
  );
};

export default Tnc;
