import React, { useState, useContext } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Row, Breadcrumb, Collapse, Button, Tag } from 'antd';
import { get } from 'lodash';
import BounceLoader from 'react-spinners/BounceLoader';

import { Table } from '../../components';
import { useTheme } from '../../ThemeContext';
import { green, formatTableData } from '../../utils';
import SaveReport from './SaveReport';
import { getOptionBySymbolTablesData } from './SearchReport.constants';
import { SearchReportContext } from './SearchReportContext';
import styles from './style.module.scss';
const { Panel } = Collapse;

const OptionBySymbol = () => {
  const {
    searchData = {},
    reportInfo,
    isLoading,
    currentSearchCriteria,
    reportType,
    reportSubType,
    reportTitle,
    fetchReportName,
    history,
    fetchingName,
  } = useContext(SearchReportContext);
  const [openKeys, setOpenKeys] = useState(['1']);
  const { theme } = useTheme();
  const { symbol, title, breadcrumb = [] } = reportInfo;
  let tableData = getOptionBySymbolTablesData(searchData, symbol, theme);
  const onPanelChange = (keys) => {
    setOpenKeys(keys);
  };
  const openAllPanel = () => {
    let keys = new Array(tableData.length)
      .fill(0)
      .map((_, i) => (i + 1).toString());

    setOpenKeys(keys);
  };

  return (
    <div className={styles.compareSymReport}>
      <div className={styles.vGoBackSaveBar}>
        <p
          onClick={() =>
            get(history, 'location.state.prevPage', '') === 'search'
              ? history.push({
                  pathname: '/search',
                  state: { type: reportType, subType: reportSubType },
                })
              : history.goBack()
          }
          className={styles.vGoBackBtnBlock}
        >
          <span className={styles.vGoBackBtn}>
            <ArrowLeftOutlined /> Go Back
          </span>
        </p>
        {!isLoading && (
          <div className={styles.SaveReportBoxNew2}>
            <SaveReport
              searchCriteria={currentSearchCriteria}
              reportType={reportType}
              reportSubType={reportSubType}
              reportTitle={reportTitle}
              fetchReportName={fetchReportName}
              fetchingName={fetchingName}
            />
          </div>
        )}
      </div>
      <div className={styles.symbolSearchContentBox}>
        <Breadcrumb>
          {breadcrumb.map((item) => (
            <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className={styles.titleContainer}>
          <h2>{title}</h2>
        </div>

        <Tag
          key={symbol}
          // icon={isLoading ? <SyncOutlined spin /> : <CloseCircleOutlined />}
          color={isLoading ? 'processing' : 'success'}
          id="vSuccesstag"
        >
          {symbol}
        </Tag>
      </div>

      {!isLoading && (
        <Row justify="end" span={24} style={{ marginBottom: 10 }}>
          {openKeys.length !== tableData.length ? (
            <Button onClick={openAllPanel}>Open All</Button>
          ) : (
            <Button onClick={() => setOpenKeys([])}>Close All</Button>
          )}
        </Row>
      )}
      <Row span={24} id="isr">
        {isLoading && (
          <div className={styles.loaderStyle}>
            <BounceLoader color={green} loading={isLoading} size={150} />
          </div>
        )}
        <Collapse
          defaultActiveKey={['1']}
          expandIconPosition="left"
          style={{ width: '100%' }}
          activeKey={openKeys}
          onChange={onPanelChange}
        >
          {tableData.map((field, i) => (
            <Panel
              style={{ color: 'red' }}
              header={field.title.toUpperCase()}
              key={i + 1}
              id="vPanelChange"
            >
              <div className={styles.searchPaneTable}>
                <Table
                  scroll={{ x: true }}
                  loading={isLoading}
                  columns={field.columns}
                  dataSource={formatTableData(field.data)}
                  pagination={false}
                  size="small"
                  bordered={true}
                  rowClassName={styles.tableHeader}
                  className={styles.TabularData}
                  id="allPicksId"
                />
              </div>
            </Panel>
          ))}
        </Collapse>
      </Row>
    </div>
  );
};

export default OptionBySymbol;
