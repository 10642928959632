import React from 'react';

import { Row, Pagination } from 'antd';

import { formatNumber2 } from '../../utils';
import styles from './style.module.scss';

interface Props {
  loading: boolean;
  onChange: Function;
  pageNo: number;
  pageSize: number;
  total: number;
  showPagination: boolean;
  hideDisclaimer?: boolean;
  reportId: string;
  size?: string;
}

const TablePagination: React.FC<Props> = ({
  total,
  pageSize,
  onChange,
  pageNo,
  reportId,
  size = 'default',
}) => {
  return (
    <Row
      span={24}
      justify="center"
      style={{
        marginTop: 20,
        marginBottom: 10,
        //minWidth: 900
      }}
      id={reportId}
    >
      <>
        <div className={styles.paginationBox}>
          <Pagination
            total={total}
            pageSize={pageSize}
            current={pageNo}
            onChange={onChange}
            size={size}
            responsive={true}
            showSizeChanger
            pageSizeOptions={[10, 20, 50, 100, 500]}
          />
          {total > 0 && (
            <p>
              {`Showing ${(pageNo - 1) * pageSize + 1} to ${
                pageSize * pageNo > total ? total : pageSize * pageNo
              } of ${formatNumber2(total)} entries`}{' '}
            </p>
          )}
        </div>
      </>
    </Row>
  );
};

export default TablePagination;
