import React, { useState } from 'react';

import { Tabs } from 'antd';
import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import ApproxTxn from '../Alerts.ApproxTxn/ApproxTxn';
import Insiders from '../Alerts.Insiders/Insiders';
import Institutions from '../Alerts.Institutions/Institutions';
import SavedAlerts from '../Alerts.Saved/Alerts';
import Tickers from '../Alerts.Tickers/Tickers';
const { TabPane } = Tabs;

interface Props extends RouteComponentProps<any> {
  onStateChange: Function;
}
const Setting: React.FC<Props> = ({ history }) => {
  const [type, setType] = useState('1');
  const [record2, setRecord2] = useState({});
  const [record3, setRecord3] = useState({});
  const [record4, setRecord4] = useState({});
  const [record5, setRecord5] = useState({});
  const { alerts } = useSelector(
    ({ user }) => ({
      alerts: get(user, 'alerts', {}),
    }),
    shallowEqual,
  );
  const setTypeFunction = (key, record) => {
    setType(key);
    console.log(record);
    if (key === '2') {
      setRecord2(record);
    } else if (key === '3') {
      setRecord3(record);
    } else if (key === '4') {
      setRecord4(record);
    } else if (key === '5') {
      setRecord5(record);
    }
  };

  const onTypeChangeFunction = (k) => {
    if (k === '1' && k !== type) {
      window.location.reload();
    }
    setType(k);
  };
  return (
    <>
      <div id="SettingBoxTabs">
        <div>
          <h4
            style={{
              color: 'var(--whitecolor)',
              textAlign: 'center',
              marginTop: '100px',
              marginLeft: '200px',
            }}
          >
            Manage Your Alerts
          </h4>
        </div>
        <Tabs
          defaultActiveKey={type}
          activeKey={type}
          tabPosition="left"
          id="settingTab"
          onChange={(key) => onTypeChangeFunction(key)}
          style={{ paddingTop: 0 }}
        >
          <TabPane tab="Saved Alerts" key="1">
            <SavedAlerts
              history={history}
              tabNum={type}
              setKey={setTypeFunction}
            />
          </TabPane>
          <TabPane tab="SEC By Symbols" key="2">
            <Tickers record={record2} tabNum={type} alerts={alerts} />
          </TabPane>
          <TabPane tab="SEC By  Institutions" key="3">
            <Institutions record={record3} tabNum={type} alerts={alerts} />
          </TabPane>
          <TabPane tab="SEC By  Aprx Trxn Value" key="4">
            <ApproxTxn record={record4} tabNum={type} alerts={alerts} />
          </TabPane>
          <TabPane tab="Insiders" key="5">
            <Insiders record={record5} tabNum={type} alerts={alerts} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default Setting;
