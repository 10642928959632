/* eslint-disable no-restricted-globals */
import React from 'react';

import { Tabs } from 'antd';
import { get } from 'lodash';
import queryString from 'query-string';

import HealthCheckByFavIns from './HealthCheckByFavIns';
import HealthCheckByIns from './HealthCheckByIns';
import HealthCheckBySector from './HealthCheckBySector';

const { TabPane } = Tabs;

interface Props {
  history: Object;
  portfolioType: string;
  fetchData: any;
  portfolioQueryData: Object;
  portfolioName: string;
}

const HealthCheck: React.FC<Props> = ({
  history,
  portfolioType,
  fetchData,
  portfolioQueryData,
  portfolioName,
}) => {
  const handleTabChange = (key) => {
    const path =
      `/portfolio/Portfolio/${portfolioType}/` +
      queryString.stringify(
        {
          health_check: key,
          type: 'health_check',
          name: portfolioName,
        },
        {
          skipNull: true,
        },
      );
    history.push(path);
  };
  function getDisplayableQtr(qtrStr) {
    const qtrString = qtrStr.split('_');
    return qtrString[1] + '-' + qtrString[0];
  }
  const getLastQtrString = () => {
    const date = new Date();
    // If it's first qtr, pick last year's last qtr
    if (date.getMonth() < 3) {
      return date.getFullYear() - 1 + '_Q4';
    }
    return date.getFullYear() + '_Q' + Math.floor(date.getMonth() / 3);
  };
  return (
    <Tabs
      defaultActiveKey={get(portfolioQueryData, 'health_check', 'institutions')}
      centered
      onChange={handleTabChange}
      id="healthCheck"
    >
      <TabPane tab="By Institution" key="institutions">
        <div>
          <p
            style={{
              color: 'var(--whitecolor)',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            <span style={{ color: 'red' }}>*</span>`The Default Sort is based on
            the {getDisplayableQtr(getLastQtrString())} Inst Counts`
          </p>
        </div>
        <HealthCheckByIns
          history={history}
          portfolioType={portfolioType}
          fetchData={fetchData}
          portfolioName={portfolioName}
        />
      </TabPane>
      <TabPane tab="By Sector" key="sector">
        <div>
          <p
            style={{
              color: 'var(--whitecolor)',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            <span style={{ color: 'red' }}>*</span>The Default Sort is based on
            the Allocation
          </p>
        </div>
        <HealthCheckBySector
          history={history}
          portfolioType={portfolioType}
          fetchData={fetchData}
          portfolioName={portfolioName}
        />
      </TabPane>
      <TabPane tab="By Favorite Institution" key="favorite_institutions">
        <HealthCheckByFavIns
          history={history}
          portfolioType={portfolioType}
          portfolioName={portfolioName}
        />
      </TabPane>
    </Tabs>
  );
};

export default HealthCheck;
