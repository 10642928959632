import React, { useEffect, useState } from 'react';

import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { round } from 'lodash';
import moment from 'moment/moment';
import { getHolidays } from 'nyse-holidays';
import BounceLoader from 'react-spinners/BounceLoader';

import { esSearchCompany, getSymbolDetail } from '../../graphql';
import { getPricesOnDates } from '../../graphql';
import { errorHandler, green, red } from '../../utils';
import styles from '../refer/styles.module.scss';

interface Props {
  record: Object;
  alerts: Object;
  tabNum: string;
}

const WhatIfCalc: React.FC<Props> = ({ record, tabNum, alerts }) => {
  useEffect(() => {}, []);
  const [form] = Form.useForm();
  const [fetching, setFetching] = useState<boolean>(false);
  const [symbolData, setSymbolData] = useState([]);
  const [symbolDetail, setSymbolDetail] = useState([]);
  const [perc, setPerc] = useState(null);
  const [profit, setProfit] = useState(null);
  const [disableStart, setDisableStart] = useState<Date>(null);
  const [startPriceDate, setStartPriceDate] = useState<Date>(null);
  const { Option } = Select;

  const fetchSymbols = async (value: Object) => {
    setFetching(true);
    const res = await esSearchCompany(value);
    if (res) {
      setSymbolData(res);
    }
    setFetching(false);
  };

  const symbolDataSet = async (v) => {
    setSymbolData([]);
    const apis = [await getSymbolDetail(v)];
    Promise.all(apis)
      .then((res) => {
        setSymbolDetail(res[0].latestPrice / 100);
        setStartPriceDate(res[0].startDate);
      })
      .catch(errorHandler)
      .finally(() => {});
  };

  const onFinish = async (values) => {
    const { fromDate, toDate, symbols, invested } = values;

    const apis = [
      await getPricesOnDates({
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
        symbol: symbols,
      }),
    ];
    Promise.all(apis)
      .then((res) => {
        const fromPrice = res[0].data[0].fromPrice / 100;
        const toPrice = res[0].data[0].toPrice / 100;
        const division = toPrice / fromPrice;
        const total = invested * division;
        console.log('profit', total - invested);
        console.log('profit %', ((total - invested) * 100) / invested);
        setPerc(round(((total - invested) * 100) / invested, 4));
        setProfit(round(total - invested, 4));
        console.log(invested);
      })
      .catch(errorHandler)
      .finally(() => {});
  };
  const checkData = (currentDate) => {
    const year = moment(currentDate).year();
    const holidays = getHolidays(year).map((d) => d.dateString);
    let result =
      holidays.includes(moment(currentDate).format('YYYY-MM-DD')) ||
      moment(currentDate).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD');
    let result1 = disableStart
      ? moment(currentDate).format('YYYY-MM-DD') <
        moment(disableStart).add(1, 'days').format('YYYY-MM-DD')
      : false;
    return result || result1;
  };
  const checkDataFrom = (currentDate) => {
    const year = moment(currentDate).year();
    const holidays = getHolidays(year).map((d) => d.dateString);
    let result = holidays.includes(moment(currentDate).format('YYYY-MM-DD'));
    return result;
  };
  const reset = () => {
    form.resetFields();
    setPerc(null);
    setProfit(null);
  };

  return (
    <>
      <div>
        <div style={{ marginLeft: '200px', marginBottom: '50px' }}>
          <div className={styles.loaderStyle}>
            <span
              style={{
                color: 'var(--whitecolor)',
                fontSize: '20px',
                fontWeight: 'bold',
                marginBottom: '20px',
                marginLeft: '230px',
              }}
            >
              Back Tester
            </span>
            <Form
              layout="vertical"
              className={styles.referForm}
              name="tools-form"
              form={form}
              onFinish={onFinish}
            >
              <Row
                span={12}
                className="gutter-row"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Col span={10} style={{ marginRight: '70px' }}>
                    <Form.Item
                      name="symbols"
                      label={'Add Symbol'}
                      className={styles.vFormSearhNew}
                      rules={[
                        {
                          required: true,
                          message: `Symbol is required`,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Enter valid symbol"
                        notFoundContent={
                          fetching ? (
                            <span className={styles.spinnerStyle}>
                              <BounceLoader
                                color={green}
                                loading={true}
                                size={50}
                              />
                            </span>
                          ) : null
                        }
                        onSearch={fetchSymbols}
                        showSearch={true}
                        allowClear={true}
                        showArrow={false}
                        filterOption={false}
                        onSelect={(v) => symbolDataSet(v)}
                        size="large"
                        style={{
                          width: '270px',
                          marginTop: '20px',
                          marginBottom: '20px',
                        }}
                      >
                        {symbolData.map((d) => (
                          <Option key={d.symbol} className={styles.optionStyle}>
                            <span className={styles.optionSymbol}>
                              {d.symbol}
                            </span>{' '}
                            {d.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <p hidden={symbolDetail !== 0} style={{ color: red }}>
                      {'Please Enter a Valid Symbol'}
                    </p>
                  </Col>
                  <Col span={10} style={{ marginRight: '70px' }}>
                    <Form.Item
                      name="invested"
                      label="Investment Amount ($)"
                      style={{ marginBottom: 5 }}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value && !getFieldValue('invested')) {
                              return Promise.reject(
                                'Invested Amount is required',
                              );
                            } else {
                              if (value < 0) {
                                return Promise.reject(
                                  'Invested Amount should be greater than 0',
                                );
                              }
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        style={{
                          width: '270px',
                          height: '50px',
                          marginTop: '20px',
                        }}
                        placeholder="Invested"
                      />
                    </Form.Item>
                  </Col>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Col span={10} style={{ marginRight: '70px' }}>
                    <Form.Item
                      name="fromDate"
                      label="From Date"
                      style={{ width: '100%', marginBottom: 5 }}
                      rules={[
                        {
                          required: true,
                          message: `From Date is required`,
                        },
                      ]}
                    >
                      <DatePicker
                        // onChange={onChange}
                        // defaultValue={date}
                        clearIcon={false}
                        style={{ height: '50px', width: '270px' }}
                        // format={'DD MMM YYYY'}
                        className={styles.vDatePickerNew1}
                        onChange={(e) => setDisableStart(e)}
                        disabledDate={(currentDate: Date) =>
                          currentDate > moment().tz('America/New_York') ||
                          moment(currentDate).day() === 0 ||
                          moment(currentDate).day() === 6 ||
                          moment(currentDate) < moment(startPriceDate) ||
                          checkDataFrom(currentDate)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10} style={{ marginRight: '70px' }}>
                    <Form.Item
                      name="toDate"
                      label="To Date"
                      style={{ width: '100%', marginBottom: 5 }}
                      rules={[
                        {
                          required: true,
                          message: `To Date is required`,
                        },
                      ]}
                    >
                      <DatePicker
                        // onChange={onChange}
                        // defaultValue={date}
                        style={{ height: '50px', width: '270px' }}
                        disabledDate={(currentDate: Date) =>
                          moment(currentDate).day() === 0 ||
                          moment(currentDate).day() === 6 ||
                          checkData(currentDate)
                        }
                        clearIcon={false}
                        // format={'DD MMM YYYY'}
                        className={styles.vDatePickerNew1}
                      />
                    </Form.Item>
                  </Col>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '20px',
                  }}
                >
                  <div className={styles.btnSectionBox}>
                    <Button
                      type="primary"
                      disabled={symbolDetail === 0}
                      style={{
                        width: '200px',
                        height: '35px',
                        marginTop: '10px',
                        marginLeft: '100px',
                        marginRight: '60px',
                      }}
                      htmlType="submit"
                    >
                      Calculate
                    </Button>
                  </div>
                  <div className={styles.btnSectionBox} onClick={reset}>
                    <Button
                      type="primary"
                      style={{
                        width: '200px',
                        height: '35px',
                        marginTop: '10px',
                        marginBottom: '20px',
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '120px' }}>
                    <span
                      style={{
                        color: 'var(--whitecolor)',
                        fontSize: '15px',
                        marginBottom: '50px',
                      }}
                    >
                      Profit / Loss Amount
                    </span>
                    <br />
                    <span style={{ color: 'var(--whitecolor)' }}>
                      <Input
                        style={{
                          width: '270px',
                          marginTop: '10px',
                          height: '50px',
                        }}
                        placeholder="Profit/Loss Amount"
                        value={profit}
                      />{' '}
                      $
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        color: 'var(--whitecolor)',
                        fontSize: '15px',
                        marginBottom: '50px',
                      }}
                    >
                      Profit / Loss %
                    </span>
                    <br />
                    <span style={{ color: 'var(--whitecolor)' }}>
                      <Input
                        style={{
                          width: '270px',
                          marginTop: '10px',
                          height: '50px',
                        }}
                        placeholder="Profit/Loss %"
                        value={perc}
                      />{' '}
                      %
                    </span>
                  </div>
                </div>
                {/* </Form.Item> */}
                {/*{error && <span style={{ color: red }}>{error}</span>}*/}
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatIfCalc;
