import React, { useContext } from 'react';

import { ShareAltOutlined } from '@ant-design/icons';
import { Cascader, Button, message } from 'antd';
import moment from 'moment-timezone';

import { green, white, black1, getLast5Dates } from '../../utils';
import { ReportContext } from './ReportContext';
import { reportOptions, cascaderValueByCode } from './Reports.constants';
import styles from './style.module.scss';

const ReportHeader: React.FC<Props> = () => {
  const {
    reportType,
    changeReportType,

    handleTableSelectChange,
    changePageNo,

    changeSearchText,

    reportCode,
    setSelectedDateIndex,
    selectedDateIndex,
    isLoading,
    setTableColumns,
  } = useContext(ReportContext);

  // let [datesArray] = useState(getLast5Dates(reportCode));
  // const [datesArray, setDatesArray] = useState<Array>(
  //   getLast5Dates(reportCode),
  // );

  const onChange = (value: Array<string>) => {
    const type = value[0];
    changeReportType(type);
    handleTableSelectChange(value[value.length - 1]);
    setTableColumns([]);
    changeSearchText('');
  };

  const onDateSelect = (index: number) => {
    setSelectedDateIndex(index);
    changePageNo(1);
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    message.success('Report Url Copied');
  };

  return (
    <div className={styles.reportHeader}>
      <Cascader
        disabled={isLoading}
        options={reportOptions}
        value={cascaderValueByCode(reportCode)}
        //defaultValue={['Daily', 'All Institution']}
        //style={{ minWidth: 430 }}
        className={styles.VdailyInput}
        onChange={(e) => onChange(e)}
        allowClear={false}
        placeholder="Please select"
        expandTrigger="hover"
        popupClassName={styles.cascaderPopup}
      />
      <div className={styles.dates}>
        <div className={styles.datesContent}>
          {reportType === 'daily' &&
            reportCode !== 'SDL' &&
            reportCode !== 'SAV' &&
            reportCode !== 'QTBS' &&
            reportCode !== 'BFN' &&
            reportCode !== 'AMFR' &&
            reportCode !== 'SSMF' &&
            reportCode !== 'SSL' &&
            reportCode !== 'DISS' &&
            reportCode !== 'AID' &&
            reportCode !== 'DDTDV' &&
            reportCode !== 'DDTD' &&
            reportCode !== 'DDTU' &&
            reportCode !== 'DDTUV' && (
              <div className={styles.datesArray} id="reportDate">
                {getLast5Dates(reportCode).map((dateIndex) => (
                  <div
                    key={dateIndex}
                    className={styles.dateBox}
                    onClick={() => onDateSelect(dateIndex)}
                    style={{
                      backgroundColor:
                        selectedDateIndex === dateIndex ? green : white,
                      color: selectedDateIndex === dateIndex ? white : black1,
                      borderWidth: selectedDateIndex === dateIndex ? 0 : 1,
                    }}
                  >
                    <p
                      style={{
                        color: selectedDateIndex === dateIndex ? white : black1,
                      }}
                      className={styles.day}
                    >
                      {moment()
                        .tz('America/New_York')
                        .add(dateIndex, 'days')
                        .format('ddd')}
                    </p>
                    <p
                      style={{
                        color: selectedDateIndex === dateIndex ? white : black1,
                      }}
                      className={styles.date}
                    >
                      {`${moment()
                        .tz('America/New_York')
                        .add(dateIndex, 'days')
                        .format('MM')}/${moment()
                        .tz('America/New_York')
                        .add(dateIndex, 'days')
                        .format('DD')}`}
                    </p>
                  </div>
                ))}
              </div>
            )}
          <Button
            type="primary"
            shape="circle"
            icon={<ShareAltOutlined />}
            size="middle"
            onClick={() => handleShare()}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportHeader;
