import React, { useState, useEffect } from 'react';

import BounceLoader from 'react-spinners/BounceLoader';

import { fetchVideos } from '../../../graphql';
import { errorHandler, green } from '../../../utils';
import { ExpertData } from '../../../utils';
import styles from './style.module.scss';

const Experts: React.FC<Props> = ({ history, match }) => {
  const [ownerName, setOwnerName] = useState('ALL');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { params } = match;
  const { type } = params;
  const { title, experts } = ExpertData[type];
  useEffect(() => {
    setIsLoading(true);
    const payload = {
      page: 1,
      size: 50,
      type: type,
      subType: 'EXPERT_TALK',
      owner: ownerName.toUpperCase() === 'ALL' ? '' : ownerName,
    };
    const promises = [fetchVideos(payload)];
    Promise.all(promises)
      .then((res) => {
        setData(res[0]);
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [ownerName, type]);
  const getVideoId = (url: string) => {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  };
  return (
    <div className={styles.expertsPage}>
      <div className={styles.universityHeaderView}>
        <h1>{title}</h1>
        <button onClick={() => history.goBack()}>BACK</button>
      </div>
      <div className={styles.expertBtnSection}>
        {experts.map((ex) => (
          <button
            className={
              ownerName === ex ? styles.expertBtnActive : styles.expertBtn
            }
            onClick={() => setOwnerName(ex)}
            key={ex}
          >
            {ex}
          </button>
        ))}
      </div>
      {isLoading && (
        <div className={styles.loaderStyle}>
          <BounceLoader color={green} loading={isLoading} size={150} />
        </div>
      )}
      <div className={styles.expertBodyView}>
        <div className={styles.videoBoxs}>
          <div className={styles.embeddedVideoBox}>
            {data.map(({ url, id, description = '' }) => (
              <div key={id}>
                <iframe
                  className={styles.embeddedVideo}
                  src={`https://www.youtube.com/embed/${getVideoId(
                    url,
                  )}?rel=0&amp;showinfo=0&autoplay=0&loop=0"`}
                  //src={url}
                  allowFullScreen="true"
                  frameBorder="0"
                  title="video"
                  style={{
                    height: '250px',
                  }}
                ></iframe>
                <p className={styles.videoDesc}>{description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experts;
