import React, { useState } from 'react';

import { Modal, Button, Form, Input, notification, Row } from 'antd';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import { addNewPortfolio, getUserPortfolios } from '../../../graphql';
import { setUserPortfoliosList } from '../../../redux/reducers/portfolio';
import { red, errorHandler } from '../../../utils';
import styles from './style.module.scss';

interface Props {
  visible: boolean;
  handleCancel: Function;
  isNew: boolean;
  portfolioList: Array;
  history: any;
  editing: boolean;
}

const AddNewPortfolio: React.FC<Props> = ({
  visible,
  handleCancel,
  isNew,
  portfolioList,
  history,
  editing,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const onFinish = (values: { name: string }) => {
    const { name } = values;
    getUserPortfolios().then((res) => {
      let result = res.map(({ name }) =>
        name.trim().replace(' ', '').toLowerCase(),
      );
      setLoading(true);
      setError('');
      if (
        result.includes(name.trim().replace(' ', '').toLowerCase()) &&
        isNew
      ) {
        setLoading(false);
        setError('portfolio with same name Exists');
        return;
      } else {
        addNewPortfolio({
          name: name,
          id: isNew ? null : portfolioList[0].id,
        })
          .then((data) => {
            notification['success']({
              message: editing
                ? 'Portfolio Updated Successfully'
                : 'Portfolio Added Successfully',
            });
            getUserPortfolios().then((res) => {
              dispatch(setUserPortfoliosList(res));
              const path =
                `/portfolio/Portfolio/real/` +
                queryString.stringify(
                  {
                    name: name,
                  },
                  {
                    skipNull: true,
                  },
                );
              history.push(path);
              handleCancel();
            });
          })
          .catch(errorHandler)
          .finally(() => {
            //setLoading(false);
          });
      }
    });
  };

  return (
    <Modal
      title={`${isNew ? 'Add Portfolio' : 'Name Your Portfolio'}`}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      bodyStyle={{ paddingTop: 0 }}
      closable={isNew}
      width={550}
    >
      {portfolioList.length < 6 ? (
        <Form
          name="save-form"
          onFinish={onFinish}
          labelCol={{ span: 24, padding: 0 }}
          wrapperCol={{ span: 24 }}
          size="small"
          style={{ padding: 20 }}
          onValuesChange={() => setError('')}
          initialValues={
            editing
              ? {
                  name: portfolioList[0].name,
                }
              : {}
          }
          form={form}
        >
          {!isNew && (
            <p className={styles.addPortfolioHeading}>
              We have recently made some changes to Portfolio tab. You are now
              able to add multiple portfolio to My Universe and track individual
              performance all in one place. To Proceed further please give a
              portfolio name(Ex. Webull, Robinhood) for the existing stocks.
              Later you can move stocks from one portfolio to another
            </p>
          )}
          <Form.Item
            label="Give a Name"
            name="name"
            rules={[{ required: true, message: 'Please enter the Name!' }]}
          >
            <Input
              maxLength={20}
              placeholder="Name"
              size="middle"
              style={{ fontSize: 14 }}
            />
          </Form.Item>
          {error && <p style={{ color: red }}>{error}</p>}
          <Row
            span={24}
            justify="center"
            // className={styles.buttonSectionBoxNew}
          >
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{
                  height: 38,
                  width: 120,
                  fontSize: 14,
                }}
              >
                Save
              </Button>
            </Form.Item>
          </Row>
        </Form>
      ) : (
        <p
          className={styles.addPortfolioHeading}
          style={{
            marginTop: 20,
          }}
        >
          You have reached a maximum limit of 6 portfolios. Currently We do not
          support more than 6 Portfolios
        </p>
      )}
    </Modal>
  );
};

export default AddNewPortfolio;
