import React, { useState, useEffect } from 'react';

import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { getCustomerSubscriptionInfo } from '../../graphql';
import { setUserProfile } from '../../redux/reducers/user';
import HomePage from './HomePage2';

interface Props extends RouteComponentProps<any> {
  onStateChange: Function;
  history: Object;
}

const Home: React.FC<Props> = ({ history, onStateChange, match }) => {
  // let path = get(match, 'path', '');
  const dispatch = useDispatch();
  // const getFormState = () => {
  //   switch (path) {
  //     case '/forgot-password/:username/:code':
  //       return 'forgotPassword';
  //     case '/verify/:username/:code':
  //       return 'verifyAccount';
  //     case '/membership':
  //       return 'membership';
  //     default:
  //       return 'signup';
  //   }
  // };
  // const [formType, setFormType] = useState<string>(getFormState());
  const [user, setUser] = useState({});
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async (res) => {
        setUser({
          ...get(res, 'attributes', {}),
          groups: get(
            res,
            'signInUserSession.accessToken.payload.cognito:groups',
            [],
          ),
        });
        const user = await getCustomerSubscriptionInfo();
        dispatch(setUserProfile(user));
      })
      .catch();
  }, [dispatch]);

  return (
    <>
      <HomePage history={history} match={match} user={user} />
    </>
  );
};

export default Home;
