import React, { useState, useEffect } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { subscribePlan } from '../../api/RestApi';
import { MembershipForm } from '../../components';
import { getPrices, getCustomerSubscriptionInfo } from '../../graphql';
import { setUserProfile } from '../../redux/reducers/user';
import { errorHandler } from '../../utils';
import MembershipPage from './MembershipPage';
import MembershipPageTrial from './MembershipPageTrial';
import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {}

const Membership: React.FC<Props> = ({ history }) => {
  const { customer, personalData } = useSelector(
    ({ user }) => ({
      customer: get(user, 'profile.stripeCustomer', {}),
      personalData: get(user, 'personalData', {}),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const userType = personalData['type']?.split(' ')[1];

  const [selectedPlan, setSelectedPlan] = useState();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [startFreeTrialLoader, setStartFreeTrialLoader] =
    useState<boolean>(false);
  const [stripeInfo, setStripeInfo] = useState({});
  const customerId = get(customer, 'stripe_customer_id', '');
  const subscriptionStatus = get(customer, 'subscription_status', null);
  useEffect(() => {
    const promises = [getPrices({})];
    setIsLoading(true);
    Promise.all(promises)
      .then((res) => {
        setStripeInfo(res[0]);
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onPlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  const { key, prices = [] } = stripeInfo;
  const getProductInfo = () => {
    if (selectedPlan === 'monthly') {
      return prices.find(
        (price: Object) => price.interval === 'MONTHLY' && !price.is_new,
      );
    }
    if (selectedPlan === 'yearly') {
      return prices.find(
        (price: Object) => price.interval === 'YEARLY' && !price.is_new,
      );
    }
    if (selectedPlan === 'trial') {
      return prices.find((price: Object) => price.interval === 'TRIAL');
    }
    return {};
  };

  const updateUserSubscriptionStatus = () => {
    const promises = [getCustomerSubscriptionInfo()];
    setIsLoading(true);
    Promise.all(promises)
      .then((res) => {
        dispatch(setUserProfile(res[0]));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createSubscription = ({ paymentMethodId, cardDetail, values }) => {
    const { cardHolderName, country, address, state, city, promoCode } = values;
    const { exp_month, exp_year, funding, last4 } = cardDetail;
    const priceId = get(
      prices.find(
        (price: Object) =>
          price.interval ===
          (userType === 'promo' ? personalData['type'].split(' ')[0] : 'TRIAL'),
      ),
      'stripe_price_id',
      'N/A',
    );
    const payload = {
      customerId: customerId,
      paymentMethodId: paymentMethodId,
      priceId: priceId,
      isTrial: 'trial',
      promoCode: promoCode,
      card_details: {
        name: cardHolderName,
        country: country,
        address: address,
        state: state,
        city: city,
        last4: last4,
        expiry_month: exp_month,
        expiry_year: exp_year,
        funding: funding,
      },
    };
    const promises = [subscribePlan(payload)];
    setStartFreeTrialLoader(true);
    Promise.all(promises)
      .then((response) => {
        return response;
      })
      // If the card is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the additional details we need.
      .then((result) => {
        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result,
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      //          .then(handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      //          .then(handleRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .then((onSubscriptionComplete) => updateUserSubscriptionStatus())
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        setStartFreeTrialLoader(false);
      })
      .finally(() => {
        setStartFreeTrialLoader(false);
      });
  };

  const startFreeTrial = () => {
    const values = {};
    const cardDetail = {};
    let paymentMethodId = null;
    createSubscription({ paymentMethodId, cardDetail, values });
  };
  const { stripe_payment_method_id } = customer;
  const isNewUser =
    stripe_payment_method_id === null ||
    stripe_payment_method_id === undefined ||
    customer.interval === 'YEARLY';
  return (
    <>
      {subscriptionStatus !== 'INACTIVE' ? (
        <div className={styles.MembershipTrialCardSection}>
          <div className={styles.getStartedForFreeBox}>
            <h2 className={styles.insteadText2}>
              {' '}
              Explore the unique capabilities of our ThinkSabio.{' '}
            </h2>
            <Button
              loading={startFreeTrialLoader}
              type="primary"
              onClick={() => startFreeTrial()}
              className={styles.startFreeTrialButton}
            >
              {userType === 'promo'
                ? 'Start your Promo Subscription'
                : 'Start your 7-Days Free Trial'}
            </Button>
          </div>
        </div>
      ) : (
        <>
          {!selectedPlan &&
            (isNewUser ? (
              <MembershipPageTrial
                onPlanSelect={onPlanSelect}
                stripePrices={prices}
                stripePKey={key}
                isLoading={isLoading}
                customer={customer}
              />
            ) : (
              <MembershipPage
                onPlanSelect={onPlanSelect}
                stripePrices={prices}
                stripePKey={key}
                isLoading={isLoading}
                customer={customer}
              />
            ))}
          {selectedPlan && (
            <div className={styles.MembershipFormSection}>
              <div className={styles.memberFormHeader}>
                <span
                  onClick={() => setSelectedPlan(null)}
                  className={styles.membershipBackIcon}
                >
                  <ArrowLeftOutlined /> Go Back
                </span>

                <h5 className={styles.letsFinishPowerText}>
                  Please take pride in joining our ThinkSabio Community
                </h5>
              </div>
              <MembershipForm
                selectedPlan={selectedPlan}
                stripePKey={key}
                product={getProductInfo()}
                history={history}
                isNewUser={isNewUser}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Membership;
