import React, { useState, useEffect } from 'react';

import { message, Modal } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import { Table } from '../../components';
import { getMyAlerts, deleteMyAlert } from '../../graphql';
import { setUserAlerts } from '../../redux/reducers/user';
import { useTheme } from '../../ThemeContext';
import { green, errorHandler, formatColumn, formatNumber2 } from '../../utils';
import { columns } from '../Alerts/Alerts.Constants';
import CreateAlert from '../Alerts/CreateAlert';
import styles from './style.module.scss';

interface Props {
  history: Object;
  setKey: Function;
  tabNum: string;
}

const Alerts: React.FC<Props> = ({ history, setKey, tabNum }) => {
  const { alerts } = useSelector(
    ({ user }) => ({
      alerts: get(user, 'alerts', []),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchAlerts, setFetchAlerts] = useState(new Date());
  useEffect(() => {
    setIsLoading(true);
    const apis = [getMyAlerts()];
    Promise.all(apis)
      .then((res) => {
        dispatch(setUserAlerts(res[0]));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, fetchAlerts, tabNum]);

  // const showModal = () => {
  //   setVisible(true);
  // };

  const handleCancel = () => {
    setVisible(false);
    setFetchAlerts(new Date());
  };
  const data = alerts.map((item) => ({
    ...item,
    symbols: item.symbols.filter((i) => i.symbol).map((d) => d.symbol),
    institutions: item.institutions.filter((i) => i.cik).map((d) => d.name),
  }));

  const onDelete = (row) =>
    Modal.confirm({
      title: 'Delete',
      content: (
        <p>
          Are you sure you want to delete{' '}
          <span style={{ color: green }}>{`${row.name}`}</span> ?{' '}
        </p>
      ),
      onOk: () =>
        handleDelete({
          name: row.name,
          type: row.type,
        }),
    });

  const handleDelete = (payload) =>
    deleteMyAlert(payload)
      .then((res) => {
        message.success('Alert Deleted');
        setFetchAlerts(new Date());
      })
      .catch(errorHandler);

  const onEdit = (record) => {
    let key = '1';
    if (record.type === 'INSIDER') {
      key = '5';
    } else {
      if (record.institutions.length) {
        if (record.institutions[0] === 'ALL') {
          key = '2';
        } else {
          key = '3';
        }
      } else {
        if (record.symbols.length) {
          key = '2';
        } else {
          key = '4';
        }
      }
    }
    setKey(key, record);
    // setSelectedRecordForEdit(alerts.find((d) => d.name === record.name));
  };
  const total = data ? data.length : 0;
  return (
    <div
      className={styles.clientContainer}
      style={{ marginTop: '20px !important' }}
    >
      {/*<div className={styles.searchPaneTitle}>*/}
      {/* <h2>Coming Soon</h2> */}
      {/*<h2>Manage Your Alerts</h2>*/}
      {/*<Button type="primary" onClick={showModal}>*/}
      {/*  Create Alert*/}
      {/*</Button>*/}
      {/*</div>*/}
      {data.length === 0 && (
        <div className={styles.loaderStyle}>
          <BounceLoader color={green} loading={isLoading} size={150} />
        </div>
      )}
      {data.length > 0 && (
        <Table
          columns={formatColumn(
            columns,
            false,
            [],
            history,
            false,
            null,
            'alert',
            onDelete,
            onEdit,
            theme,
          )}
          dataSource={data}
          loading={isLoading}
          pagination={false}
          size="small"
          bordered={true}
          rowClassName={styles.tableHeader}
          className={styles.TabularData}
          // onChange={onTableChange}
          id="allPicksId"
          // sticky={true}
        />
      )}
      {total > 0 && (
        <p>{`Showing 1 to ${total} of ${formatNumber2(total)} entries`} </p>
      )}

      {visible && <CreateAlert handleCancel={handleCancel} visible={visible} />}
    </div>
  );
};

export default Alerts;
