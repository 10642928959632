import React, { useState } from 'react';

import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  Row,
  Col,
  Input,
  Form,
  Select,
  DatePicker,
  Upload,
  message,
  Button,
  Modal,
  Image,
  Progress,
  Checkbox,
  notification,
} from 'antd';
import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { useSelector, shallowEqual } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { getSchoolList } from '../../api/RestApi';
import { getSignedUrl, saveStudentInfo } from '../../graphql';
import { setStudentInfo } from '../../redux/reducers/user';
import {
  greenTextColor,
  newGrey,
  green,
  red,
  white,
  usStates,
  grades,
  errorHandler,
} from '../../utils';
import styles from './style.module.scss';

const { Dragger } = Upload;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 36,
  },
  wrapperCol: {
    span: 36,
  },
};

interface Props extends RouteComponentProps<any> {}

const StudentForm: React.FC<Props> = () => {
  const { studentInfo } = useSelector(
    ({ user }) => ({
      studentInfo: get(user, 'studentInfo', {}),
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [schoolList, setSchoolList] = useState([]);
  const [error, setError] = useState('');
  const [step, setStep] = useState(0);
  const [modalVisible, setModalVisible] = useState<boolean>(true);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [availableStudentData, setAvailableStudentData] = useState<object>({});

  const [countyList, setCountyList] = useState<Array<object>>([]);
  const [imageObj, setImageObj] = useState({ url: '', time: 0 });
  const [checked, setChecked] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [studentData, setStudentData] = useState({
    grade: 'Freshman - 9',
    schoolState: null,
    schoolCounty: null,
    schoolName: null,
    studentId: null,
    graduationDate: null,
    birthYear: null,
  });

  const onStudentInfoChange = (val) => {
    const newInfo = { ...studentData, ...val };
    setStudentData(newInfo);
  };

  const onSchoolSearch = async (value) => {
    setFetching(true);
    const { schoolState } = studentData;
    const res = await getSchoolList(schoolState, value);
    if (res) {
      setSchoolList(get(res, 'data.schoolList', []));
    }
    setFetching(false);
  };

  const handleChange = (info) => {
    const { status } = info.file;

    if (status === 'uploading') {
      setImageLoading(true);
    }
    if (status !== 'uploading') {
      console.log(info.file);
    }
    if (status === 'done') {
      setImageLoading(false);

      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      setImageLoading(false);

      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const getCounties = async (selectedStateAbbrevation: string) => {
    const where = encodeURIComponent(
      JSON.stringify({
        stateAbbreviation: selectedStateAbbrevation,
      }),
    );
    const response = await fetch(
      `https://parseapi.back4app.com/classes/Area?limit=4000&keys=countyName&where=${where}`,
      {
        headers: {
          'X-Parse-Application-Id': 'VWAH9UbFty9tuCJVHIJPjYvH8OGcNyUTMkHH3UvL', // application id
          'X-Parse-Master-Key': 'UsYwiuputxOcEcYTZqWKshopMgEjElqA4U4Mcy9V', //readonly master key
        },
      },
    );
    const data = await response.json();
    setCountyList(data.results);
  };

  const onDrop = async (acceptedFiles) => {
    setImageLoading(true);
    const signedUrls = await getSignedUrl({
      fileName: acceptedFiles.file.name,
    });

    const response = await fetch(signedUrls.url, {
      method: 'PUT',
      body: acceptedFiles.file,
    });
    if (response.status === 200) {
      setImageObj({ url: signedUrls.accessUrl, time: +new Date() });
    }
    setImageLoading(false);
  };

  const handleSubmit = () => {
    if (!checked) {
      setError('Please accept the agreement');
      return;
    }
    const {
      schoolState,
      schoolCounty,
      schoolName,
      grade,
      studentId,
      graduationDate,
      birthYear,
    } = studentData;
    let payload = {
      id: get(studentInfo, 'id', null),
      school_state: schoolState,
      school_county: schoolCounty,
      school_name: schoolName,
      school_email: availableStudentData['custom:schoolemail'],
      grade: grade,
      graduation_date: graduationDate.format('YYYY-MM-DD'),
      birth_year: birthYear.format('YYYY'),
      student_id: studentId,
      student_picture_id: imageObj.url,
    };
    const apis = [saveStudentInfo(payload)];
    Promise.all(apis)
      .then((res) => {
        dispatch(setStudentInfo(studentData));
        notification['success']({
          message: 'Student Info Updated',
        });
        setModalVisible(false);
      })
      .catch(errorHandler);
  };
  const StepBackward = () => {
    if (!(step <= 0)) {
      step === 7 && availableStudentData['custom:studentprofile']
        ? setStep(step - 2)
        : setStep(step - 1);
    }
  };
  const ValidateStep = async () => {
    const {
      schoolState,
      schoolCounty,
      schoolName,
      grade,
      studentId,
      graduationDate,
      birthYear,
    } = studentData;
    switch (step) {
      case 0: {
        if (schoolState) {
          stepForward();
        } else {
          form.validateFields(['schoolState']);
        }
        break;
      }
      case 1: {
        if (schoolCounty) {
          stepForward();
        } else {
          form.validateFields(['schoolCounty']);
        }
        break;
      }
      case 2: {
        if (schoolName) {
          stepForward();
        } else {
          form.validateFields(['schoolName']);
        }
        break;
      }
      case 3: {
        if (grade) {
          stepForward();
        } else {
          form.validateFields(['grade']);
        }
        break;
      }
      case 4: {
        if (studentId) {
          stepForward();
        } else {
          form.validateFields(['studentId']);
        }
        break;
      }
      case 5: {
        if (graduationDate) {
          stepForward();
        } else {
          form.validateFields(['graduationDate']);
        }
        const studentIdUploaded = await Auth.currentAuthenticatedUser();
        if (studentIdUploaded.attributes['custom:studentprofile']) {
          imageObj.url = studentIdUploaded.attributes['custom:studentprofile'];
          setAvailableStudentData(studentIdUploaded.attributes);
          stepForward(6);
        }
        break;
      }
      case 6: {
        if (imageObj.url) {
          stepForward();
        } else {
          form.validateFields(['uploadStudentId']);
        }
        break;
      }
      case 7: {
        if (birthYear) {
          stepForward();
        } else {
          form.validateFields(['birthYear']);
        }
        break;
      }
      default: {
        break;
      }
    }
  };
  const stepForward = (stepValue?) => {
    if (!(step >= 8)) {
      stepValue ? setStep(stepValue + 1) : setStep(step + 1);
    }
    stepValue = null;
  };

  return (
    <>
      <div>
        <Modal
          width={700}
          centered
          visible={modalVisible}
          footer={null}
          closable={false}
          bodyStyle={{ minHeight: 500 }}
          title="We need little more Information from you!"
        >
          <Form
            className={styles.studentFormCustom}
            {...layout}
            layout={'horizontal'}
            name="student-form"
            onValuesChange={(value) => onStudentInfoChange(value)}
            size="large"
            form={form}
            initialValues={studentData}
          >
            <Row span={24}>
              <Col
                className="gutter-row"
                span={18}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {step === 0 && (
                  <Form.Item
                    name="schoolState"
                    label="Which State are you from ?"
                    rules={[
                      {
                        required: true,
                        message: 'Please select your school state',
                      },
                    ]}
                    style={{ width: '100%' }}
                  >
                    <Select
                      showSearch
                      placeholder={'Please Select Your State'}
                      onChange={(value: [string]) => getCounties(value)}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {usStates &&
                        usStates.map((i) => (
                          <Option key={i.abbreviation} value={i.abbreviation}>
                            {i.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                {step === 1 && (
                  <Form.Item
                    name="schoolCounty"
                    label="Which County are you from ?"
                    rules={[
                      {
                        required: true,
                        message: 'Please select your school County',
                      },
                    ]}
                    style={{ width: '100%' }}
                  >
                    <Select
                      showSearch
                      placeholder={'Please Select Your County'}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {countyList &&
                        countyList.map((i) => (
                          <Option key={i.countyName} value={i.countyName}>
                            {i.countyName}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                {step === 2 && (
                  <Form.Item
                    name="schoolName"
                    label="What is your School Name ?"
                    rules={[
                      {
                        required: true,
                        message: 'Please select School Name',
                      },
                    ]}
                    style={{ width: '100%' }}
                  >
                    <Select
                      showSearch
                      placeholder="Type School Name"
                      onSearch={onSchoolSearch}
                      filterOption={false}
                      allowClear={true}
                      showArrow={false}
                      notFoundContent={
                        fetching ? (
                          <span className={styles.spinnerStyle}>
                            <BounceLoader
                              color={green}
                              loading={true}
                              size={50}
                            />
                          </span>
                        ) : null
                      }
                    >
                      {schoolList &&
                        schoolList.map((school) => (
                          <Option
                            key={school.schoolid}
                            value={school.schoolName}
                          >
                            <span>{school.schoolName}</span>
                            <br />
                            <span>{`${school.address.street}, ${school.address.city}`}</span>
                            <br />
                            <span>{`${school.address.state} - ${school.address.zip} `}</span>
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                {step === 3 && (
                  <Form.Item
                    name="grade"
                    label="Which Grade are you in ?"
                    rules={[
                      {
                        required: true,
                        message: 'Please Select Grade',
                      },
                    ]}
                    style={{ width: '100%' }}
                  >
                    <Select>
                      {grades &&
                        grades.map((i) => (
                          <Option key={i.grade} value={i.grade}>
                            {i.grade}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                {step === 4 && (
                  <Form.Item
                    name="studentId"
                    label="Please fill your Student ID"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Student ID',
                      },
                    ]}
                    style={{ width: '100%' }}
                  >
                    <Input placeholder="Enter Student ID" autoComplete="off" />
                  </Form.Item>
                )}
                {step === 5 && (
                  <Form.Item
                    name="graduationDate"
                    label="What is your Graduation Date?"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Graduation Date',
                      },
                    ]}
                    style={{ width: '100%' }}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      format="MMM, DD YYYY"
                      disabledDate={(currentDate: Date) =>
                        currentDate < moment().tz('America/New_York')
                      }
                      picker="date"
                    />
                  </Form.Item>
                )}
                {step === 6 && (
                  <Form.Item
                    name="uploadStudentId"
                    label="Please Upload Your Student ID"
                    rules={[
                      {
                        required: true,
                        message: 'Please Upload Your Student ID',
                      },
                    ]}
                    style={{ width: '100%' }}
                  >
                    <Dragger
                      name="studentId"
                      showUploadList={false}
                      customRequest={(a) => onDrop(a)}
                      onChange={handleChange}
                      style={{
                        backgroundColor: '#46515c',
                        color: white,
                        margin: 'auto',
                      }}
                    >
                      {imageObj.url !== '' ? (
                        <div
                          key={imageObj.time}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Image width={200} height={100} src={imageObj.url} />
                        </div>
                      ) : (
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                      )}
                      <p
                        className="ant-upload-text"
                        style={{ padding: '20px', color: 'white' }}
                      >
                        {!imageLoading ? (
                          ' Click or drag your Student Photo ID to this area to upload'
                        ) : (
                          <LoadingOutlined style={{ fontSize: '25px' }} />
                        )}
                      </p>
                    </Dragger>
                  </Form.Item>
                )}
                {step === 7 && (
                  <Form.Item
                    name="birthYear"
                    label="In which year were you born ?"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your Birth Year',
                      },
                    ]}
                    style={{ width: '100%' }}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      picker="year"
                      disabledDate={(currentDate: Date) =>
                        currentDate >
                        moment().subtract(10, 'years').tz('America/New_York')
                      }
                    />
                  </Form.Item>
                )}
              </Col>
              <Col
                span={6}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 50,
                }}
              >
                <Button
                  onClick={() => {
                    StepBackward();
                  }}
                  disabled={step === 0}
                >
                  <ArrowLeftOutlined />
                </Button>
                {step !== 7 ? (
                  <Button
                    style={{ marginLeft: '5px' }}
                    onClick={() => {
                      ValidateStep();
                    }}
                  >
                    <ArrowRightOutlined />
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{
                      height: '40px',
                      padding: '0px 10px',
                      marginLeft: '5px',
                    }}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </Button>
                )}
              </Col>
            </Row>

            <Row span={24}>
              <Col span={18}>
                <Progress
                  percent={parseInt((step / 7) * 100)}
                  steps={7}
                  strokeColor={greenTextColor} //#1ABD50
                  trailColor={newGrey}
                  // width={'320px'}
                  style={{
                    // width: 350,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              </Col>
            </Row>

            {step === 7 && (
              <Row span={24} style={{ marginTop: 20 }}>
                <Checkbox
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                    if (e.target.checked) {
                      setError('');
                    }
                  }}
                >
                  <p
                    style={{
                      marginLeft: 10,
                      fontSize: 16,
                    }}
                  >
                    I certify under oath that the information provided herewith,
                    and in any attached documents, is true and correct. I
                    understand that providing falsified information will lead to
                    "account termination".
                  </p>
                </Checkbox>
                {error && <span style={{ color: red }}>{error}</span>}
              </Row>
            )}
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default StudentForm;
