import React from 'react';

import { Select } from 'antd';
import { green } from 'utils/index';
const { Option } = Select;
function DaysDropDown({
  className,
  options,
  defaultValue,
  isLoading,
  onChange,
  labelClassName,
  selectClassName,
  isweek,
}) {
  return (
    <div className={className} style={{ marginRight: 20 }}>
      <label style={{ color: green }} className={labelClassName}>
        {isweek ? `#Weeks: ` : `#Days: `}
      </label>
      <Select
        defaultValue={defaultValue}
        style={{ width: 60 }}
        onChange={onChange}
        placeholder="Select No Of Days"
        disabled={isLoading}
        className={selectClassName}
      >
        {options.map((option) => (
          <Option key={option.name} value={option.name}>
            {option.value}
          </Option>
        ))}
      </Select>
    </div>
  );
}
export default DaysDropDown;
