import React from 'react';

// import { Card } from 'antd';

import styles from './style.module.scss';

interface Props {
  btnText: string;
  title: string;
  type: string;
  onPlanSelect: Function;
  popular: boolean;
  billedText: string;
  discountedPrice: string;
  percentOff: string;
}
const MembershipCard: React.FC<Props> = ({
  btnText,
  title,
  type,
  onPlanSelect,
  popular,
  billedText,
  discountedPrice,
  // realPrice,
  percentOff,
}) => {
  return (
    <div className={styles.paymentCard}>
      <div className={styles.paymentCardContent}>
        <p className={styles.cardTitle}>{title}</p>
        {/* <p className={styles.cardSubTitle}>(Early ThinkSabiens)</p> */}
        <div className={styles.fee}>
          {/* <span className={styles.currency}>$</span> */}
          <span className={styles.amount}>{discountedPrice}</span>
          <span className={styles.validity}>&nbsp;/Month*</span>
        </div>
        {/* <p className={styles.oldPriceText}>
          Reg: <span>{realPrice}</span>
        </p> */}
        {/* <h4>Price Locked</h4> */}
        {/* <span className={styles.amount}>For Lifetime</span> */}
        {/* <p className={styles.ifSubcripitionText}>
          If Subscribed by 31st December, 2022
        </p> */}
        <h6>{billedText}</h6>
        <p
          className={styles.membershipBtn}
          style={{ cursor: btnText ? 'pointer' : 'auto' }}
          onClick={() => onPlanSelect(type)}
        >
          {btnText}
        </p>
        {popular && (
          <span className={styles.discountRibbon}>{`Most Popular`}</span>
        )}
        {percentOff && (
          <div className={styles.ribbonUpText}>
            <span>{`Save ${percentOff}%`}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MembershipCard;
