import { API } from 'aws-amplify';

import { ourpicks, ourpicksDetail } from './queries';

export const getOurPicks = (input) =>
  API.graphql({
    variables: {
      ...input,
    },
    query: ourpicks,
  }).then(({ data }) => data.ourpicks);

export const getPickDetail = async (payload: Object) =>
  API.graphql({
    variables: { request: payload },
    query: ourpicksDetail,
  }).then(({ data }) => data.ourpicksDetail);
