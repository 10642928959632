import React, { useEffect, useState } from 'react';

import { SafetyCertificateOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import { isReferralBoolean } from '../../graphql';
import { green } from '../../utils';
import PaymentForm from '../PaymentForm';
import styles from './style.module.scss';

interface Props {
  onFinish: Function;
  plan: string;
  isLoading: boolean;
  isReferral: boolean;
  balance: string;
  // price: string;
  error: string;
  setError: Function;
  isNewUser: Boolean;
}

const MembershipFormPage: React.FC<Props> = ({
  onFinish,
  plan,
  isLoading,
  // price,
  setError,
  error,
  isNewUser,
}) => {
  const [isReferral, setIsReferral] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const price = plan === 'yearly' ? 99.99 : 9.99;
  const { personalData } = useSelector(
    ({ user }) => ({
      personalData: get(user, 'personalData', {}),
    }),
    shallowEqual,
  );
  const email = get(personalData, 'email', '');
  const getReferralBoolean = async () => {
    return await isReferralBoolean({ email: email });
  };
  useEffect(() => {
    getReferralBoolean().then((res) => {
      setIsModalLoading(false);
      console.log('res', res.referral_to_claimed);
      setIsReferral(!res.referral_to_claimed);
      const bal = res.creditBalance;
      setBalance(bal !== 0 ? bal / 100 : 0);
    });
  }, [isReferral]);
  const hiddenVar = (!isReferral && balance === 0) || plan !== 'yearly';
  return (
    <Row span={24} justify="space-between">
      {!isModalLoading ? (
        <>
          <Col span={24} className={styles.feeContentContainer}>
            <div className={styles.planName}>
              <p className={styles.planText}>
                {`${plan.toUpperCase()} MEMBERSHIP`}
              </p>
              <span
                hidden={hiddenVar}
                className={styles.oldPriceText}
                style={{ marginTop: '15px' }}
              >
                ${`${price}`}
              </span>
              <div className={styles.feeBox}>
                {/* <span className={styles.currency}>$</span> */}
                <span className={styles.price}>
                  $
                  {isReferral
                    ? `${
                        plan === 'yearly'
                          ? `${
                              Math.round((price - 9.99 + balance) * 100) / 100
                            }`
                          : `${
                              price +
                              (-balance > 9.99 ? -9.99 : balance) +
                              (balance !== 0 ? 0.5 : 0)
                            }`
                      }`
                    : `${
                        Math.round(
                          (price +
                            (-balance > 9.99 && `$${price}` === `$9.99`
                              ? -9.99
                              : balance) +
                            (balance !== 0 && `$${price}` === `$9.99`
                              ? 0.5
                              : 0)) *
                            100,
                        ) / 100
                      }`}
                </span>
                <span className={styles.validity}>
                  &nbsp;{plan === 'yearly' ? '/Year' : '/Month'}
                </span>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <PaymentForm
              onFinish={onFinish}
              isLoading={isLoading}
              error={error}
              setError={setError}
              plan={plan}
              isNewUser={isNewUser}
              btnText="Start My Subscription"
            />
          </Col>
          <Row>
            <p className={styles.securePayment}>
              Secure Payments <SafetyCertificateOutlined />{' '}
            </p>
            <p className={styles.securePaymentDesc}>
              All payments are secured by{' '}
              <a
                href="https://stripe.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stripe
              </a>
              . We do not store your payment information. We care about your
              security.
            </p>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <span
              style={{
                height: 500,
                width: '100%',
                marginTop: '260px',
                marginLeft: '210px',
              }}
            >
              <BounceLoader color={green} loading={true} size={80} />
            </span>
          </Row>
        </>
      )}
    </Row>
  );
};

export default MembershipFormPage;
