import React, { useState, useEffect } from 'react';

import {
  Table,
  Row,
  Empty,
  Collapse,
  Button,
  List,
  Checkbox,
  Select,
} from 'antd';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  YAxis,
} from 'recharts';

import { getSavedSearchName } from '../../graphql';
// import { ultimateImg } from '../../images';
import { useTheme } from '../../ThemeContext';
import {
  green,
  formatTotalRow,
  formatTableData,
  currencyFormat,
  getDisplayLastQtrByIdx,
  errorHandler,
} from '../../utils';
import {
  getGraphQlQueryByType,
  getSearchSymbolTablesData,
} from '../searchReport/SearchReport.helper';
import styles from '../searchReport/style.module.scss';

const { Panel } = Collapse;
interface Props extends RouteComponentProps<any> {
  authState: string;
}
const formatLargeNumbers = (n: number) => {
  if (n === 0) return '0';

  const sign = n < 0 ? '-' : '';
  const absNumber = Math.abs(n);

  const denominations = [
    { value: 1e12, symbol: 'T' }, // Trillions
    { value: 1e9, symbol: 'B' }, // Billions
    { value: 1e6, symbol: 'M' }, // Millions
    { value: 1e3, symbol: 'K' }, // Thousands
    { value: 1, symbol: '' }, // Base case (no abbreviation)
  ];
  if (absNumber < 1) {
    return `${sign}${Math.abs(n).toFixed(2)}`;
  }

  // Handle large numbers with abbreviations (K, M, B, T)
  for (const { value, symbol } of denominations) {
    if (absNumber >= value) {
      const formattedNumber = (absNumber / value).toFixed(1);
      return `${sign}${formattedNumber.replace(/\.0$/, '')}${symbol}`;
    }
  }

  return `${sign}${n}`;
};

// Custom Y-Axis tick component
const CustomYAxisTick = ({ x, y, payload }: any) => {
  const number = payload.value;

  return (
    <text
      x={x}
      y={y}
      textAnchor="middle"
      fill="#1abd50"
      fontSize={14}
      fontWeight="bold"
    >
      {formatLargeNumbers(number)}
    </text>
  );
};
const CustomCursor = (props) => {
  const { x, y, width, height } = props;

  return (
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill="green" // You can set this to any color
      opacity={0.2} // Set the desired opacity here (e.g., 0.2)
      stroke="none"
      pointerEvents="none" // Ensures it doesn't interfere with mouse events
    />
  );
};
const SearchSymbolOpenPage: React.FC<Props> = ({
  history,
  match,
  authState,
}) => {
  const { theme } = useTheme();
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openKeys, setOpenKeys] = useState(['3']);
  const [sortDetail, setSortDetail] = useState([]);
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [isDpChecked, setIsDpChecked] = useState<boolean>(true);
  const [quarterlyGraphType, setQuarterlyGraphType] = useState('Avg Price');
  const [dpGraphType, setDpGraphType] = useState('Price');
  const urlSplit = window.location.href.split('/');
  const symbol = urlSplit[urlSplit.length - 1];
  const reportType = 'SYMBOL';
  const reportSubType = 'INDIVIDUAL_STATISTICAL_OPEN_REPORT';
  let tablesData = getSearchSymbolTablesData(
    searchData,
    symbol,
    sortDetail,
    theme,
    history,
    null,
    null,
  );
  useEffect(() => {
    console.log('Open Report', authState);
    if (authState === 'signedIn') {
      setTimeout(() => {
        history.push({
          pathname: `/search/report/subtype=individual_statistical_report&symbols=${
            urlSplit[urlSplit.length - 1]
          }&type=symbol`,
          state: { prevPage: 'reports' },
        });
      }, 1000);
    }
    const { query, queryData } = getGraphQlQueryByType(
      reportType,
      reportSubType,
      { symbols: symbol },
      null,
      null,
      null,
      null,
      null,
      null,
      sortDetail,
      null,
      null,
      null,
      null,
      null,
      null,
    );
    if (queryData && queryData.filter && queryData.filters.type) {
      delete queryData.filters.type;
    }
    const promises = [
      query,
      getSavedSearchName(
        JSON.stringify({
          type: reportType,
          subType: reportSubType,
          request: queryData,
        }),
      ),
    ];
    setIsLoading(true);
    Promise.all(promises)
      .then((res) => {
        setIsLoading(true);
        setSearchData(res[0]);
      })
      .catch((err) => {
        errorHandler(err);
        setSearchData({});
      })
      .finally(() => {
        setIsLoading(false);
      });
    console.log('ISR report');
  }, [authState]);

  const onPanelChange = (keys) => {
    setOpenKeys(keys);
  };
  const { Option } = Select;
  function onCheckBoxChange() {
    setIsChecked(!isChecked);
  }
  function onDpCheckBoxChange() {
    setIsDpChecked(!isDpChecked);
  }
  // const CustomTooltip = ({ payload, label }) => {
  //   if (payload && payload.length) {
  //     const { value } = payload[0];
  //     console.log('Payload in tooltip:', payload);
  //     return (
  //       <div
  //         style={{
  //           backgroundColor: 'white',
  //           padding: '5px',
  //           border: '1px solid #ccc',
  //         }}
  //       >
  //         <p style={{ margin: 0, fontWeight: 'bold', color: '#1abd50' }}>
  //           {label}
  //         </p>
  //         <p style={{ margin: 0, fontSize: '14px' }}>
  //           Volume: {currencyFormat3(value)} {/* Format the value */}
  //         </p>
  //       </div>
  //     );
  //   }
  //   return null;
  // };
  function onQuarterlyGraphTypeChange(key) {
    setQuarterlyGraphType(key);
    // setGraphData(getGraphData(tablesData));
  }
  function onDpGraphTypeChange(key) {
    setDpGraphType(key);
    // setGraphData(getGraphData(tablesData));
  }
  const openAllPanel = () => {
    let keys = new Array(tablesData.length)
      .fill(0)
      .map((_, i) => (i + 1).toString());

    setOpenKeys(keys);
  };
  const tableLoading = {
    spinning: isLoading,
    indicator: (
      <span className={styles.spinnerStyle}>
        <BounceLoader color={green} loading={true} size={50} />
      </span>
    ),
  };

  const onTableChange = (pagination, filters, sorter, extra, key) => {
    const { field, order } = sorter;
    setSortDetail([
      {
        key: key,
        sortBy: field,
        sortOrder: order === 'ascend' ? 'ASC' : 'DESC',
      },
    ]);
  };
  const redirectToSignUp = () => {
    history.push({
      pathname: `/sign-up`,

      state: { prevPage: '/reports/isr/TSLA' },
    });
  };
  function convertCurrencyToNumber(currencyString) {
    const multipliers = {
      K: 1e3, // Thousand
      M: 1e6, // Million
      B: 1e9, // Billion
      T: 1e12, // Trillion
    };

    const sign = currencyString.startsWith('-') ? -1 : 1;
    const cleanString = currencyString.replace(/[^0-9A-Za-z.,]/g, '');

    const match = cleanString.match(/^([\d.,]+)([A-Za-z]+)?$/);

    if (!match) {
      throw new Error('Invalid currency format');
    }

    let amount = parseFloat(match[1].replace(/,/g, ''));
    const suffix = match[2]?.toUpperCase();

    if (!suffix || !multipliers[suffix]) {
      return sign * amount;
    }

    return sign * amount * multipliers[suffix];
  }
  const redirectToImage = (image) => {
    window.open(image, '_blank');
    window.location.reload();
  };
  const quarterlyGraphTypes = {
    'Avg Price': 'avg_price',
    'Avg Volume': 'avg_volume',
    'Inst Counts': 'inst_counts',
    'Inst Shares': 'inst_shares',
    '% SHRS Change': 'perc_shares_change',
    'Approx Inv value': 'approx_inv_value',
    Price: 'price',
    'Price Diff': 'price_diff',
    'Price Diff %': 'price_diff_perc',
    Volume: 'volume',
    'Volume Diff': 'volume_diff',
    'Volume Diff %': 'volume_diff_perc',
  };
  const quarterStrings = {
    lastQtr1: getDisplayLastQtrByIdx(1),
    lastQtr2: getDisplayLastQtrByIdx(2),
    lastQtr3: getDisplayLastQtrByIdx(3),
    lastQtr4: getDisplayLastQtrByIdx(4),
    lastQtr5: getDisplayLastQtrByIdx(5),
    lastQtr6: getDisplayLastQtrByIdx(6),
    lastQtr7: getDisplayLastQtrByIdx(7),
    lastQtr8: getDisplayLastQtrByIdx(8),
    lastQtr9: getDisplayLastQtrByIdx(9),
  };
  const getGraphData = (TData, reportName, type) => {
    const dataForGraph1 = TData.find(
      (item) => item.name === reportName || item.key.includes(reportName),
    );
    const dataForGraph = dataForGraph1?.data?.find((item) =>
      type === 'Approx Inv value'
        ? item.type?.props?.children?.trim() === type
        : item.type === type,
    );
    const graphTypeName = quarterlyGraphTypes[type];
    if (dataForGraph) {
      const transformedData = Object.keys(dataForGraph)
        .filter((key) =>
          reportName === 'quarterlyData'
            ? key.startsWith('lastQtr')
            : key.startsWith('lastDay'),
        ) // Filter only lastQtr keys
        .map((key) => ({
          name: reportName === 'quarterlyData' ? quarterStrings[key] : key,
          [graphTypeName]:
            quarterlyGraphType === 'Avg Price' ||
            quarterlyGraphType === '% SHRS Change'
              ? parseFloat(
                  dataForGraph[key].props.children.replace(/[^0-9.-]+/g, ''),
                )
              : quarterlyGraphType === 'Approx Inv value'
              ? Number(
                  convertCurrencyToNumber(dataForGraph[key].props.children),
                )
              : Number(dataForGraph[key]),
        }));
      const processedData = transformedData.map((item) => {
        const value = item[quarterlyGraphTypes[quarterlyGraphType]];
        return {
          ...item,
          fill: value < 0 ? 'red' : '#8884d8', // Red if negative, blue if positive
        };
      });
      console.log(processedData);
      return processedData;
    }
    return {};
  };
  const getDpGraphData = (TData, reportName, type) => {
    const dataForGraph1 = TData.find(
      (item) => item.name === reportName || item.key.includes(reportName),
    );
    const dataForGraph = dataForGraph1?.data?.find(
      (item) => item.type === type,
    );
    const transformObject = (input) => {
      return Object.entries(input).reduce((acc, [key, value]) => {
        const dataIndex = value.dataIndex;
        const title = value.title.props.children;

        // Set the new object with dataIndex as key and title as value
        acc[dataIndex] = title;
        return acc;
      }, {});
    };
    const outputObject = transformObject(dataForGraph1.columns);
    const graphTypeName = quarterlyGraphTypes[type];
    if (dataForGraph) {
      const transformedData = Object.keys(dataForGraph)
        .filter((key) => key.startsWith('lastDay')) // Filter only lastQtr keys
        .map((key) => ({
          name: outputObject[key],
          [graphTypeName]:
            dpGraphType === 'Price' ||
            dpGraphType === 'Price Diff' ||
            dpGraphType === 'Volume Diff' ||
            dpGraphType === 'Volume Diff %' ||
            dpGraphType === 'Price Diff %'
              ? parseFloat(
                  dataForGraph[key].props.children.replace(/[^0-9.-]+/g, ''),
                )
              : Number(dataForGraph[key]),
        }));
      const processedData = transformedData.map((item) => {
        const value = item[quarterlyGraphTypes[dpGraphType]];
        return {
          ...item,
          fill: value < 0 ? 'red' : '#8884d8', // Red if negative, blue if positive
        };
      });
      console.log(processedData);
      return processedData;
    }
    return {};
  };
  const getSortedData = (key, data) => {
    const sortObj = sortDetail.find((d) => d.key === key);
    if (sortObj) {
      const newData = data.sort((a, b) => {
        let keyA =
          sortObj.sortBy === 'dateReported' ||
          sortObj.sortBy === 'transactionDate' ||
          sortObj.sortBy === 'reportDate'
            ? new Date(a[sortObj.sortBy])
            : a[sortObj.sortBy];
        let keyB =
          sortObj.sortBy === 'dateReported' ||
          sortObj.sortBy === 'transactionDate' ||
          sortObj.sortBy === 'reportDate'
            ? new Date(b[sortObj.sortBy])
            : b[sortObj.sortBy];
        // Compare the 2 dates
        if (sortObj.sortOrder === 'ASC') {
          if (!isNaN(+keyA)) {
            return keyA - keyB;
          } else {
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          }
        } else {
          if (!isNaN(+keyA)) {
            return keyB - keyA;
          } else {
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          }
        }
      });

      // const newData = orderBy(
      //   data,
      //   [sortObj.sortBy],
      //   [sortObj.sortOrder.toLowerCase()],
      // );
      console.log(newData);
      return newData;
    }
    return data;
  };

  return (
    <div style={{ marginTop: '85px' }} className={styles.isrReport}>
      <div>
        <div className={styles.vSavedBlockedNew}>
          {!isLoading && (
            <>
              <p style={{ fontSize: 20, textTransform: 'uppercase' }}>
                {' '}
                {` Individual Statistical Report for (${symbol})`}
              </p>
            </>
          )}
          {!isLoading && (
            <Row justify="end" span={24} style={{ marginBottom: 20 }}>
              {openKeys.length !== tablesData.length ? (
                <Button onClick={openAllPanel}>Open All</Button>
              ) : (
                <Button onClick={() => setOpenKeys([])}>Close All</Button>
              )}
            </Row>
          )}

          {/*<div className={styles.titleContainer}>*/}
          {/*  /!* <h2 style={{ marinTop: 0 }}>{title}</h2> *!/*/}
          {/*</div>*/}
        </div>
      </div>
      <Row style={{ marginTop: '-20px' }} span={24} id="isr">
        {isLoading && (
          <div className={styles.loaderStyle}>
            <BounceLoader color={green} loading={isLoading} size={150} />
          </div>
        )}

        {tablesData.length > 0 && (
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition="left"
            style={{ width: '100%' }}
            activeKey={openKeys}
            onChange={onPanelChange}
          >
            {tablesData.map(
              (field, i) =>
                field.data.length > 0 && (
                  <Panel
                    style={{ color: 'red' }}
                    header={field.title.toUpperCase()}
                    key={i + 1}
                    id="vPanelChange"
                    // extra={
                    //   i === 0 ? (
                    //     <Button onClick={openAllPanel}>Open All</Button>
                    //   ) : (
                    //     ''
                    //   )
                    // }
                  >
                    <div style={{ paddingRight: 400 }}>
                      {/*10 DAY PRICE DETAILS FOR*/}
                      {field.title.includes('Comparison by Quarterly Price') ? (
                        <>
                          <Checkbox
                            checked={isChecked}
                            onChange={onCheckBoxChange}
                          >
                            Graph View
                          </Checkbox>
                          <Select
                            hidden={!isChecked}
                            defaultValue={quarterlyGraphType}
                            style={{ width: 200, marginBottom: '10px' }}
                            onChange={(key) => onQuarterlyGraphTypeChange(key)}
                            placeholder="Select to Get Graph"
                            disabled={isLoading}
                          >
                            <Option key={'Avg Price'} value={'Avg Price'}>
                              {'Avg Price'}
                            </Option>
                            <Option key={'Avg Volume'} value={'Avg Volume'}>
                              {'Avg Volume'}
                            </Option>
                            <Option key={'Inst Counts'} value={'Inst Counts'}>
                              {'Inst Counts'}
                            </Option>
                            <Option key={'Inst Shares'} value={'Inst Shares'}>
                              {'Inst Shares'}
                            </Option>
                            <Option
                              key={'% SHRS Change'}
                              value={'% SHRS Change'}
                            >
                              {'% SHRS Change'}
                            </Option>
                            <Option
                              key={'Approx Inv value'}
                              value={'Approx Inv value'}
                            >
                              {'Approx Inv value'}
                            </Option>
                          </Select>
                        </>
                      ) : field.title.includes('10 Day Price Details For') ? (
                        <>
                          <Checkbox
                            checked={isDpChecked}
                            onChange={onDpCheckBoxChange}
                          >
                            Graph View
                          </Checkbox>
                          <Select
                            hidden={!isDpChecked}
                            defaultValue={dpGraphType}
                            style={{ width: 200, marginBottom: '10px' }}
                            onChange={(key) => onDpGraphTypeChange(key)}
                            placeholder="Select to Get Graph"
                            disabled={isLoading}
                          >
                            <Option key={'Price'} value={'Price'}>
                              {'Price'}
                            </Option>
                            <Option key={'Price Diff'} value={'Price Diff'}>
                              {'Price Diff'}
                            </Option>
                            <Option key={'Price Diff %'} value={'Price Diff %'}>
                              {'Price Diff %'}
                            </Option>
                            <Option key={'Volume'} value={'Volume'}>
                              {'Volume'}
                            </Option>
                            <Option key={'Volume Diff'} value={'Volume Diff'}>
                              {'Volume Diff'}
                            </Option>
                            <Option
                              key={'Volume Diff %'}
                              value={'Volume Diff %'}
                            >
                              {'Volume Diff %'}
                            </Option>
                          </Select>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                    {field.type === 'table' && (
                      <div
                        hidden={
                          (isChecked &&
                            field.title.includes(
                              'Comparison by Quarterly Price',
                            )) ||
                          (isDpChecked &&
                            field.title.includes('10 Day Price Details For'))
                        }
                        className={styles.searchPaneTable}
                      >
                        <Table
                          scroll={{ x: true }}
                          loading={tableLoading}
                          columns={field.columns}
                          dataSource={formatTableData(
                            getSortedData(field.name, field.data),
                          )}
                          showSorterTooltip={false}
                          pagination={false}
                          size="small"
                          bordered={true}
                          rowClassName={styles.tableHeader}
                          className={styles.TabularData}
                          onChange={(a, b, c, d) =>
                            onTableChange(a, b, c, d, field.name)
                          }
                          id="allPicksId"
                          summary={
                            !field.summary
                              ? () => {}
                              : () => {
                                  return (
                                    <Table.Summary.Row>
                                      {formatTotalRow(field.summary[0]).map(
                                        (r, i) => (
                                          <Table.Summary.Cell index={i} key={i}>
                                            {r}
                                          </Table.Summary.Cell>
                                        ),
                                      )}
                                    </Table.Summary.Row>
                                  );
                                }
                          }
                        />
                        {field.name === 'ownersInfo' && (
                          <p
                            style={{
                              fontSize: 12,
                            }}
                          >
                            *Data Source{' '}
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={field.dataSource}
                              style={{
                                textDecoration: 'underline',
                                fontSize: 12,
                                color: green,
                              }}
                            >
                              SEC
                            </a>
                          </p>
                        )}
                        {field.name === 'mutualFundQuarterlyShares' && (
                          <p
                            style={{
                              textDecoration: 'underline',
                              fontSize: 12,
                              color: green,
                              marginTop: 10,
                              cursor: 'pointer',
                              textAlign: 'center',
                            }}
                            onClick={() => {
                              const filters = {
                                subtype:
                                  'SYMBOL_SUMMARY_MUTUAL_FUNDS'.toLowerCase(),
                                type: 'MUTUAL_FUNDS'.toLowerCase(),
                                symbols: symbol,
                              };
                              if (history) {
                                history.push(
                                  `/search/report/` +
                                    queryString.stringify(filters),
                                );
                              }
                            }}
                          >
                            View All
                          </p>
                        )}
                        {field.name === 'insiderTransactions' && (
                          <p
                            style={{
                              textDecoration: 'underline',
                              fontSize: 12,
                              color: green,
                              marginTop: 10,
                              cursor: 'pointer',
                              textAlign: 'center',
                            }}
                            onClick={() => {
                              const filters = {
                                type: 'SEC_INSIDERS'.toLowerCase(),
                                subtype:
                                  'INSIDER_DETAIL_SUMMARY_REPORT'.toLowerCase(),
                                symbols: symbol,
                              };
                              if (history) {
                                history.push(
                                  `/search/report/` +
                                    queryString.stringify(filters),
                                );
                              }
                            }}
                          >
                            View All
                          </p>
                        )}
                      </div>
                    )}
                    {field.type === 'table' &&
                      field.title.includes('RECENT TRANSACTIONS') && (
                        <p
                          style={{
                            color: 'var(--whitecolor)',
                            marginLeft: '710px',
                            marginTop: '5px',
                          }}
                        >
                          {' '}
                          Total :{' '}
                          {currencyFormat(
                            field.total || 0,
                            false,
                            false,
                            2,
                            false,
                            true,
                          )}
                        </p>
                      )}
                    {/*{field.type === 'table' &&*/}
                    {/*  field.title.includes('Comparison by Quarterly Price') && (*/}
                    {/*    <p*/}
                    {/*      style={{*/}
                    {/*        color: 'var(--whitecolor)',*/}
                    {/*        marginLeft: '100px',*/}
                    {/*        marginTop: '5px',*/}
                    {/*        fontSize: '10px',*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      Formula For Approx Invested Value : (Shares Diff * Qtr*/}
                    {/*      Avg Price)*/}
                    {/*    </p>*/}
                    {/*  )}*/}
                    {field.type === 'table' &&
                      field.title.includes('Comparison by Quarterly Price') && (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          hidden={!isChecked}
                        >
                          <ResponsiveContainer width="50%" height={300}>
                            <BarChart
                              data={getGraphData(
                                tablesData,
                                'quarterlyData',
                                quarterlyGraphType,
                              )}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                              isAnimationActive={true}
                              onMouseMove={(state) => {
                                console.log('Mouse moved on chart:', state); // Debugging the mouse move event
                              }}
                            >
                              <CartesianGrid strokeDasharray="1 1" />
                              <XAxis
                                dataKey="name"
                                tick={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  fill: '#1abd50',
                                }}
                              />
                              <YAxis
                                tick={<CustomYAxisTick />}
                                tickMargin={20}
                              />
                              <Tooltip
                                formatter={(value: any) =>
                                  formatLargeNumbers(value)
                                }
                                cursor={<CustomCursor />}
                              />
                              <Legend
                                wrapperStyle={{
                                  color: '#8884d8',
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                }} // Set Legend label color to green
                                itemStyle={{
                                  fontSize: '14px',
                                  color: '#8884d8',
                                }}
                              />
                              <Bar
                                dataKey={
                                  quarterlyGraphTypes[quarterlyGraphType]
                                }
                                fill={(data) =>
                                  data.fill ? data.fill : '#8884d8'
                                }
                                // fill="#8884d8"
                                barSize={20}
                                animationDuration={500}
                                animationEasing="ease-out"
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      )}
                    {field.type === 'table' &&
                      field.title.includes('10 Day Price Details For') && (
                        <div
                          style={{
                            display: 'flex',
                            margin: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          hidden={!isDpChecked}
                        >
                          <ResponsiveContainer width="50%" height={300}>
                            <BarChart
                              data={getDpGraphData(
                                tablesData,
                                'dailyPrices',
                                dpGraphType,
                              )}
                              margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                              isAnimationActive={true}
                              onMouseMove={(state) => {
                                console.log('Mouse moved on chart:', state); // Debugging the mouse move event
                              }}
                            >
                              <CartesianGrid strokeDasharray="1 1" />
                              <XAxis
                                dataKey="name"
                                tick={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  fill: '#1abd50',
                                }}
                              />
                              <YAxis
                                tick={<CustomYAxisTick />}
                                tickMargin={20}
                              />
                              <Tooltip
                                formatter={(value: any) =>
                                  formatLargeNumbers(value)
                                }
                                cursor={<CustomCursor />}
                              />
                              <Legend
                                wrapperStyle={{
                                  color: '#8884d8',
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                }} // Set Legend label color to green
                                itemStyle={{
                                  fontSize: '14px',
                                  color: '#8884d8',
                                }}
                              />
                              <Bar
                                dataKey={quarterlyGraphTypes[dpGraphType]}
                                fill={(data) =>
                                  data.fill ? data.fill : '#8884d8'
                                }
                                // fill="#8884d8"
                                barSize={20}
                                animationDuration={500}
                                animationEasing="ease-out"
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      )}

                    {field.type === 'news' && (
                      <List
                        size="small"
                        // header={}
                        bordered
                        dataSource={field.data}
                        renderItem={(item: {}) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={item.url}
                                  >
                                    <p className={styles.newsTitle}>
                                      {item.title}
                                    </p>
                                  </a>

                                  <p className={styles.news_time}>
                                    {`${item.source} - ${moment(item.timestamp)
                                      .tz('America/New_York')
                                      .format('hh:mm A')},

                                ${moment(item.timestamp)
                                  .tz('America/New_York')
                                  .format('DD MMM YYYY')}`}
                                  </p>
                                </>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    )}
                  </Panel>
                ),
            )}
          </Collapse>
        )}
        {tablesData.length === 3 && !isLoading && (
          <div
            style={{
              margin: 'auto',
              fontSize: '30px',
            }}
          >
            <p>
              <a
                style={{ fontSize: '35px' }}
                onClick={redirectToSignUp}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </a>{' '}
              to Register and get full access to this report and many{' '}
              <Tooltip
                placement="bottom"
                title={
                  <>
                    <p>
                      Below are a few of our unprecedented reports. Click on the
                      report name to get a glimpse of the report in a new tab.
                    </p>
                    <div
                      // className={styles.navLinks}
                      style={{
                        padding: '0px 30px 0px 30px',
                        borderRadius: '5px',
                        backgroundColor: 'gainsboro',
                        marginBottom: '10px',
                      }}
                    >
                      <div style={{ padding: '10px', color: 'black' }}>
                        <span
                          onClick={() =>
                            redirectToImage(
                              '/static/media/diamondsScreenshot.4f6f1385.png',
                            )
                          }
                        >
                          ThinkSabio Diamonds
                        </span>
                      </div>
                    </div>
                    <div
                      // className={styles.navLinks}
                      style={{
                        padding: '0px 30px 0px 30px',
                        borderRadius: '5px',
                        backgroundColor: 'gainsboro',
                        marginBottom: '10px',
                      }}
                    >
                      <div
                        onClick={() =>
                          redirectToImage(
                            '/static/media/ultimateReport.5b266ae8.png',
                          )
                        }
                        style={{ padding: '10px', color: 'black' }}
                      >
                        <span>Ultimate Reports</span>
                      </div>
                    </div>
                    <div
                      // className={styles.navLinks}
                      style={{
                        padding: '0px 30px 0px 30px',
                        borderRadius: '5px',
                        backgroundColor: 'gainsboro',
                        borderColor: 'red !important',
                      }}
                    >
                      <div style={{ padding: '10px', color: 'black' }}>
                        {/*<Tooltip*/}
                        {/*  overlayStyle={{*/}
                        {/*    width: '100%',*/}
                        {/*    height: '100%',*/}
                        {/*  }}*/}
                        {/*  placement="bottom"*/}
                        {/*  title={*/}
                        {/*    <div*/}
                        {/*      style={{*/}
                        {/*        display: 'flex',*/}
                        {/*        justifyContent: 'center',*/}
                        {/*      }}*/}
                        {/*    >*/}
                        {/*      <img*/}
                        {/*        style={{*/}
                        {/*          height: '100%',*/}
                        {/*          width: '100%',*/}
                        {/*          marginTop: '20px',*/}
                        {/*          marginBottom: '50px',*/}
                        {/*        }}*/}
                        {/*        alt="dss"*/}
                        {/*        src={ultimateImg}*/}
                        {/*      />*/}
                        {/*    </div>*/}
                        {/*  }*/}
                        {/*>*/}
                        <span
                          onClick={() =>
                            redirectToImage(
                              '/static/media/markMinervini.048c00ec.png',
                            )
                          }
                        >
                          Mark Minervini Strategy Report
                        </span>
                        {/*</Tooltip>*/}
                      </div>
                    </div>
                  </>
                }
              >
                <span style={{ textDecoration: 'underline' }}>
                  unprecedented Reports
                </span>
              </Tooltip>
            </p>
          </div>
        )}
      </Row>
      <Row span={24} justify="center">
        {tablesData.length === 0 && !isLoading && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Row>
    </div>
  );
};

export default SearchSymbolOpenPage;
