import React, { useState, useEffect } from 'react';

import { get } from 'lodash';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import {
  getQuarters,
  getNoOfWeeksDropdown,
  getNoOfDowdaysDropdown,
  getNoOfDowDaysForPriceAndVolDropdown,
} from '../../graphql';
import {
  setReportNoOfWeeks,
  setReportNoOfDowDays,
  setReportNoOfDowDaysForVolume,
} from '../../redux/reducers/report';
import { errorHandler, getLast5Dates } from '../../utils';
import { setLocationTitle } from '../../utils/LocationHelper';
import { ReportContext } from './ReportContext';
import { getAPIBasedOnType } from './Reports.constants';
import ReportsPage from './ReportsPage';

interface SortType {
  sortOrder: string;
  sortBy: string;
}

interface Props extends RouteComponentProps<any> {}

const Reports: React.FC<Props> = ({ history, match }) => {
  const dispatch = useDispatch();
  const { quarters, noOfWeeksList, noOfDaysList, noOfDaysForVolumeList } =
    useSelector(
      ({ report }) => ({
        quarters: get(report, 'quarters', []),
        noOfWeeksList: get(report, 'noOfWeeks', []),
        noOfDaysList: get(report, 'noOfDays', []),
        noOfDaysForVolumeList: get(report, 'noOfDaysForVolume', []),
      }),
      shallowEqual,
    );
  const [reportQueryData, setReportQueryData] = useState<string>(
    queryString.parse(get(match, 'params.reportQueryData', {})),
  );
  // const urlQueryData = queryString.parse(reportQueryData);
  const filters = queryString.parse(get(reportQueryData, 'filters', {}));
  const getSOrtValuesFromUrl = () => {
    const sortValues = get(reportQueryData, 'sort', []);
    if (typeof sortValues === 'string') {
      return [queryString.parse(sortValues)];
    } else if (Array.isArray(sortValues)) {
      return sortValues.map((val) => queryString.parse(val));
    } else return [];
  };
  const [reportType, setReportType] = useState<string>(
    get(reportQueryData, 'reportType', 'daily'),
  );
  const [reportCode, setReportCode] = useState<string>(
    get(reportQueryData, 'reportCode', 'AID'),
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState({});
  const [pageNo, setPageNo] = useState<number>(
    Number(get(reportQueryData, 'page', 1)),
  );
  const [pageSize, setPageSize] = useState<number>(
    Number(get(reportQueryData, 'size', 20)),
  );
  const [searchText, setSearchText] = useState<string>(
    get(reportQueryData, 'searchText', ''),
  );
  const [filterOptions, setFilterOptions] = useState({
    ...filters,
    isNasdaq: false,
    isDow: false,
    isSP: false,
  });
  const [cik, setCik] = useState<string | null>(
    get(reportQueryData, 'cik', null),
  );
  const [quartersOptions, setQuartersOptions] = useState(quarters);
  const [error, setError] = useState('');
  const [selectedQuarter, setSelectedQuarters] = useState<string>(
    get(reportQueryData, 'quarter', get(quarters[0], 'value', '')),
  );
  // const [noOfQtrs, setNoOfQtrs] = useState<number>(2);

  // const [tourState, setTourState] = useState(getTourInitialState());

  const [tableColumns, setTableColumns] = useState([]);
  const [selectedDateIndex, setSelectedDateIndex] = useState<number>(
    parseInt(
      get(
        reportQueryData,
        'selectedDate',
        Math.max(...getLast5Dates(reportCode)),
      ),
    ),
  );
  const [sortDetail, setSortDetail] = useState<Array<SortType>>(
    getSOrtValuesFromUrl(),
  );
  const [fetchData, setFetchData] = useState<Date>(new Date());

  const [trendingStockType, setTrendingStockType] = useState<string>(
    get(reportQueryData, 'trendingStockType', 'GAINERS'),
  );

  // const [institutions, setInstitutions] = useState([]);

  const findColumnVisibility = (col: string) => {
    if (tableColumns.length === 0) {
      return true;
    } else {
      let oldCol = tableColumns.find((oldCol: Object) => oldCol.key === col);
      if (oldCol) {
        return oldCol.visible;
      } else {
        const colsVisible = get(reportQueryData, 'visible', []);
        if (colsVisible.length > 0) {
          return colsVisible.includes(col);
        }
      }
      return true;
    }
  };

  const formatColumns = (columns: Object) => {
    const formattedColumns = Object.keys(columns).map((col, index) => ({
      key: col,
      name: col,
      value: columns[col],
      visible: findColumnVisibility(col),
      index: col === 'symbol' ? 0 : index + 1,
      sort:
        col !== 'status' || col !== 'specialStatus' || col !== 'changeStatus',
    }));
    return formattedColumns;
  };

  useEffect(() => {
    setLocationTitle(window.location, trendingStockType);
    const interval = setInterval(() => {
      const { lastUpdatedTime } = tableData;
      var tmz = 'America/New_York';
      var fmt = 'HH:mm:ss';

      var start = moment.tz('04:10:00', fmt, tmz);
      var end = moment.tz('20:00:00', fmt, tmz);
      const isBetween04And2000 = moment
        .tz(moment.now(), 'America/New_York')
        .isBetween(start, end);

      const diff = moment()
        .tz('America/New_York')
        .diff(moment(lastUpdatedTime).tz('America/New_York'), 'seconds');
      console.log('Checking Diff', diff, isBetween04And2000);
      if (reportCode === 'TS' && diff >= 360 && isBetween04And2000) {
        setFetchData(new Date());
        console.log('active');
      } else if (reportCode === 'TS' && !isBetween04And2000) {
        console.log('inActive');
        setError('inActive');
      }
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [reportCode, tableData, trendingStockType]);

  useEffect(() => {
    if (quarters.length === 0) {
      setIsLoading(true);
      getQuarters()
        .then((res) => {
          setQuartersOptions(res);
          setSelectedQuarters(res[0].value);
        })
        .catch(errorHandler)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [quarters.length]);

  useEffect(() => {
    if (reportCode === 'DTU' || reportCode === 'DTD') {
      // setIsLoading(true);
      getNoOfWeeksDropdown()
        .then((res) => {
          dispatch(setReportNoOfWeeks(res));
        })
        .catch(errorHandler)
        .finally(() => {
          // setIsLoading(false);
        });
    }
  }, [dispatch, reportCode]);
  useEffect(() => {
    setPageNo(1);
    setPageSize(20);
  }, [trendingStockType]);

  useEffect(() => {
    onFilter(
      reportCode === 'DR' ? { category: filterOptions.category } : {},
      [],
      true,
    );
    const urlSplit = window.location.href.split('/');
    if (window.location.href.split('/')[4] === 'isr') {
      setTimeout(() => {
        history.push({
          pathname: `/search/report/subtype=individual_statistical_report&symbols=${
            urlSplit[urlSplit.length - 1]
          }&type=symbol`,
          state: { prevPage: 'search' },
        });
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (reportCode === 'DDTU' || reportCode === 'DDTD') {
      // setIsLoading(true);
      getNoOfDowdaysDropdown()
        .then((res) => {
          dispatch(setReportNoOfDowDays(res));
        })
        .catch(errorHandler)
        .finally(() => {
          // setIsLoading(false);
        });
    }
  }, [dispatch, reportCode]);

  useEffect(() => {
    if (reportCode === 'DDTUV' || reportCode === 'DDTDV') {
      getNoOfDowDaysForPriceAndVolDropdown()
        .then((res) => {
          dispatch(setReportNoOfDowDaysForVolume(res));
        })
        .catch(errorHandler)
        .finally(() => {});
    }
  }, [dispatch, reportCode]);

  useEffect(() => {
    const newUrlData = queryString.parse(
      get(match, 'params.reportQueryData', {}),
    );
    if (reportCode !== get(newUrlData, 'reportCode', 'AID')) {
      changeReportType(get(newUrlData, 'reportType', 'daily'));
      handleTableSelectChange(get(newUrlData, 'reportCode', 'AID'));
      setTableColumns([]);
      changeSearchText('');
      setReportQueryData(newUrlData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  // useEffect(() => {
  //   const code = get(urlQueryData, 'reportCode', 'AID');
  //   const type = get(urlQueryData, 'reportType', 'daily');
  //   if (reportCode !== code) {
  //     changeReportType(type);
  //     handleTableSelectChange(code);
  //     setTableColumns([]);
  //     changeSearchText('');
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [urlQueryData]);

  useEffect(() => {
    setError('');
    const queryData = {
      filters: filterOptions,
      page: pageNo,
      size: pageSize,
      searchText: searchText,
      isTop10: false,
      cik: cik,
      quarter:
        reportType === 'quarterly' || reportCode === 'QTBS'
          ? selectedQuarter
          : null,
      sort: sortDetail,
      // institutions: institutions,
    };

    // getQueryData(filterOptions);
    const path =
      '/reports/' +
      queryString.stringify(
        {
          reportCode: reportCode,
          reportType: reportType,
          filters: queryString.stringify(filterOptions, { skipNull: true }),
          page: pageNo,
          size: pageSize,
          searchText: searchText,
          isTop10: false,
          cik: cik,
          // institutions: institutions,
          selectedDate: selectedDateIndex,
          quarter:
            reportType === 'quarterly' || reportCode === 'QTBS'
              ? selectedQuarter
              : null,
          sort: sortDetail.map((sort) => queryString.stringify(sort)),
          visible: tableColumns
            .filter((col) => col.visible === true)
            .map((filteredObj) => filteredObj.key),
        },
        {
          skipNull: true,
        },
      );
    history.push(path);
    const promises = [
      getAPIBasedOnType(
        queryData,
        reportCode,
        selectedDateIndex,
        trendingStockType,
      ),
    ];
    setIsLoading(true);
    const { lastUpdatedTime } = tableData;
    var tmz = 'America/New_York';
    var fmt = 'HH:mm:ss';

    var start = moment.tz('04:00:00', fmt, tmz);
    var end = moment.tz('20:00:00', fmt, tmz);
    const isBetween04And2000 = moment
      .tz(moment.now(), 'America/New_York')
      .isBetween(start, end);

    const diff = moment()
      .tz('America/New_York')
      .diff(moment(lastUpdatedTime).tz('America/New_York'), 'seconds');
    console.log('Checking Diff', diff, isBetween04And2000);
    if (reportCode === 'TS' && diff >= 360 && isBetween04And2000) {
      console.log('active');
    } else if (reportCode === 'TS' && !isBetween04And2000) {
      console.log('inActive');
      setError('inActive');
    }
    Promise.all(promises)
      .then((res) => {
        if (reportCode === 'TS') {
          let columnsArray = Object.entries(res[0].columns);
          columnsArray.splice(1, 0, ['note', 'Day Trend']);
          res[0].columns = Object.fromEntries(columnsArray);
          res[0].data = res[0].data.map((item) => {
            return { ...item, note: null };
          });
        }
        const { columns = [] } = res[0];
        setTableData(res[0]);
        setTableColumns(formatColumns(columns));
      })
      .catch((error) => {
        errorHandler(error);
        setTableData({});
        setError('Error While Fetching Data. Please try again');
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNo,
    pageSize,
    reportCode,
    reportType,
    searchText,
    selectedQuarter,
    selectedDateIndex,
    // filterOptions,
    cik,
    // sortDetail,
    fetchData,
    trendingStockType,
  ]);

  const handleTableSelectChange = (code) => {
    setReportCode(code);
    if (code === 'ITT') {
      setCik('1037389');
    }
    if (code === 'IS') {
      setCik('1601086');
    }
    setFilterOptions({});
    setFetchData(new Date());
  };

  const changeReportType = (val) => {
    setIsLoading(true);
    setReportType(val);
    setFilterOptions({});
    setPageNo(1);
    setPageSize(20);
    setSortDetail([]);
    setSearchText('');
    setCik(null);
    setTableData({});
    setSelectedDateIndex(Math.max(...getLast5Dates(reportCode)));
    setSelectedQuarters(get(quarters[0], 'value', ''));
    setFetchData(new Date());
  };

  const changePageNo = (newPageNo: number) => {
    setPageNo(newPageNo);
  };
  const changePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
  };
  const changeSearchText = (value: string) => {
    setPageNo(1);
    setSearchText(value);
    // setFilterOptions({});
  };

  const onFilter = (newFilters, sort, reset) => {
    setFilterOptions({
      criterion: filterOptions.criterion,
      noOfWeeks: filterOptions.noOfWeeks,
      noOfDays: filterOptions.noOfDays,
      type: filterOptions.type,
      category: filterOptions.category,
      status: filterOptions.status,
      noOfQuarters: filterOptions.noOfQuarters,
      isSP: reset ? false : filterOptions.isSP,
      isNasdaq: reset ? false : filterOptions.isNasdaq,
      isDow: reset ? false : filterOptions.isDow,
      sortByVol: reset ? false : filterOptions.sortByVol,
      ...newFilters,
    });
    if (sort && Array.isArray(sort)) {
      setSortDetail(sort);
    }
    setFetchData(new Date());

    // setInstitutions(ins);
  };

  const onQuarterChange = (quarter) => {
    setSelectedQuarters(quarter);
  };
  const onNoOfQtrsChange = (val) => {
    setFilterOptions({
      ...filterOptions,
      noOfQuarters: val,
    });
    setFetchData(new Date());
  };
  const onCriteriaChange = (val) => {
    setFilterOptions({
      ...filterOptions,
      criterion: val,
    });
    setFetchData(new Date());
  };

  const onNoOfWeeksChange = (val) => {
    setFilterOptions({
      ...filterOptions,
      noOfWeeks: val,
    });
    setFetchData(new Date());
  };
  const onNoOfDaysChange = (val) => {
    setFilterOptions({
      ...filterOptions,
      noOfDays: val,
    });
    setFetchData(new Date());
  };

  const onTypeChange = (val) => {
    setFilterOptions({
      ...filterOptions,
      type: val,
    });
    setFetchData(new Date());
  };
  const onCategoryChange = (val) => {
    setFilterOptions({
      ...filterOptions,
      category: val,
    });
    if (reportCode === 'DR') {
      setSortDetail([]);
    }
    setFetchData(new Date());
  };
  const onDurationChange = (val: string) => {
    let startingDate = moment(new Date())
      .subtract(1, 'year')
      .format('YYYY-MM-DD');
    if (val === '1Week') {
      startingDate = moment(new Date())
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
    }
    if (val === '2Weeks') {
      startingDate = moment(new Date())
        .subtract(2, 'week')
        .format('YYYY-MM-DD');
    }
    if (val === '1Month') {
      startingDate = moment(new Date())
        .subtract(1, 'month')
        .format('YYYY-MM-DD');
    }
    if (val === '2Months') {
      startingDate = moment(new Date())
        .subtract(2, 'month')
        .format('YYYY-MM-DD');
    }
    if (val === '3Months') {
      startingDate = moment(new Date())
        .subtract(3, 'month')
        .format('YYYY-MM-DD');
    }
    if (val === '6Months') {
      startingDate = moment(new Date())
        .subtract(6, 'month')
        .format('YYYY-MM-DD');
    }
    setFilterOptions({
      ...filterOptions,
      startDate: startingDate,
    });
    setFetchData(new Date());
  };
  const onStatusTxnChange = (val: string) => {
    setFilterOptions({
      ...filterOptions,
      status: val,
    });
    setFetchData(new Date());
  };
  const onFilerNameChange = (val) => {
    setFilterOptions({
      ...filterOptions,
      filerName: val,
    });
    setFetchData(new Date());
  };

  const onMutualFundsSymbolChange = (val) => {
    setFilterOptions({
      ...filterOptions,
      symbols: val,
    });
    setFetchData(new Date());
  };

  const onSort = (sortBy, sortOrder, multi) => {
    if (multi) {
      let newSort = sortDetail;
      if (sortOrder) {
        newSort = sortDetail.filter((s) => s.sortBy !== sortBy);
        newSort.push({
          sortBy: sortBy,
          sortOrder: sortOrder,
        });
      } else {
        newSort = sortDetail.filter((s) => s.sortBy !== sortBy);
      }
      setSortDetail([...newSort]);
    } else {
      if (sortOrder) {
        setSortDetail([
          {
            sortBy: sortBy,
            sortOrder: sortOrder,
          },
        ]);
      } else {
        setSortDetail([]);
      }
    }
    setFetchData(new Date());
  };

  const onSPChange = (e) => {
    setFilterOptions({
      ...filterOptions,
      isSP: e.target.checked,
    });
    setFetchData(new Date());
  };

  const onNasdaqChange = (e) => {
    setFilterOptions({
      ...filterOptions,
      isNasdaq: e.target.checked,
    });
    setFetchData(new Date());
  };

  const onDOWJONESChange = (e) => {
    setFilterOptions({
      ...filterOptions,
      isDow: e.target.checked,
    });
    setFetchData(new Date());
  };
  const onSoryByVolChange = (e) => {
    setFilterOptions({
      ...filterOptions,
      sortByVol: e.target.checked,
    });
    setFetchData(new Date());
  };

  const onColumnFilter = (filteredColumns) => {
    const path =
      '/reports/' +
      queryString.stringify(
        {
          reportCode: reportCode,
          reportType: reportType,
          filters: queryString.stringify(filterOptions, { skipNull: true }),
          page: pageNo,
          size: pageSize,
          searchText: searchText,
          isTop10: false,
          cik: cik,
          selectedDate: selectedDateIndex,
          quarter:
            reportType === 'quarterly' || reportCode === 'QTBS'
              ? selectedQuarter
              : null,
          sort: sortDetail.map((sort) => queryString.stringify(sort)),
          visible: filteredColumns
            .filter((col) => col.visible === true)
            .map((filteredObj) => filteredObj.key),
        },
        {
          skipNull: true,
        },
      );
    history.push(path);
    setTableColumns(filteredColumns);
  };
  return (
    <ReportContext.Provider
      value={{
        reportType,
        changeReportType,
        isLoading,
        tableData,
        handleTableSelectChange,
        changePageNo,
        changePageSize,
        pageSize,
        pageNo,
        searchText,
        changeSearchText,
        onFilter,
        quartersOptions,
        onQuarterChange,
        selectedQuarter,
        onSort,
        tableColumns,
        onColumnFilter,
        reportCode,
        setSelectedDateIndex,
        selectedDateIndex,
        history,
        filterOptions,
        cik,
        sortDetail,
        setTableColumns,
        setCik,
        onNoOfQtrsChange,
        onCriteriaChange,
        setSortDetail,
        onNoOfWeeksChange,
        onNoOfDaysChange,
        onTypeChange,
        onCategoryChange,
        onDurationChange,
        onStatusTxnChange,
        onFilerNameChange,
        onMutualFundsSymbolChange,
        noOfWeeksList,
        noOfDaysList,
        noOfDaysForVolumeList,
        error,
        trendingStockType,
        setTrendingStockType,
        onSPChange,
        onNasdaqChange,
        onDOWJONESChange,
        onSoryByVolChange,
      }}
    >
      <ReportsPage />
    </ReportContext.Provider>
  );
};

export default Reports;
