import React, { useState } from 'react';

import { Empty, Popconfirm, Modal, message } from 'antd';
import moment from 'moment-timezone';

import { DeleteIcon } from '../../components';
import { deleteAllRecentSearch } from '../../graphql';
import { red, errorHandler } from '../../utils';
import styles from './style.module.scss';

interface Props {
  data: Array<Object>;
  isLoading: Boolean;
  handleDelete: Function;
  setFetchData: Function;
  redirectToReportPage: Function;
}

const RecentSearches: React.FC<Props> = ({
  data,
  isLoading,
  handleDelete,
  redirectToReportPage,
  setFetchData,
}) => {
  const [visible, setVisible] = useState<number>(0);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const onRecentSearchClick = (search, name) => {
    const { type, subType, request = {}, symbols } = search;
    const { filters = {} } = request;
    redirectToReportPage(type, subType, filters, symbols, name);
  };

  const handleOk = (search_criteria) => {
    setConfirmLoading(true);
    handleDelete(
      {
        search_criteria: JSON.stringify(JSON.parse(search_criteria)),
      },
      'recent',
      () => {
        setVisible(0);
        setConfirmLoading(false);
      },
    );
  };

  const handleCancel = () => {
    setVisible(0);
  };
  const showPopconfirm = (number) => {
    setVisible(number);
  };

  const handleAllDelete = () =>
    Modal.confirm({
      title: 'Delete All ',
      content: <p>Are you sure you want to delete All Recent Searches</p>,
      onOk: () => handleDeleteAll(),
    });

  const handleDeleteAll = () =>
    deleteAllRecentSearch()
      .then((res) => {
        message.success('Recent Searches deleted successfully');
        setFetchData(new Date());
      })
      .catch(errorHandler);
  return (
    <>
      {data.length > 0 ? (
        <>
          {(data || []).map((search, i) => {
            return (
              <div
                key={`recentSearches-${i}`}
                className={styles.dashboardReportsListNew}
              >
                <div className={styles.dashboardReportsContent}>
                  <div
                    className={styles.reportsDetails}
                    onClick={() =>
                      onRecentSearchClick(
                        JSON.parse(search.search_criteria),
                        search.displayText,
                      )
                    }
                  >
                    <p>
                      {moment(search.created_at).format('DD MMM YYYY HH:MM:SS')}
                    </p>
                    <h4>{search.title}</h4>
                    <p>{search.displayText}</p>
                  </div>
                </div>
                <div className={styles.reportsBtn2}>
                  <Popconfirm
                    title="Are you sure delete this search?"
                    visible={visible === i + 1}
                    onConfirm={() => handleOk(search.search_criteria)}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteIcon
                      style={{ color: red }}
                      size={15}
                      onClick={() => showPopconfirm(i + 1)}
                    />
                  </Popconfirm>
                </div>
              </div>
            );
          })}
          <div
            className={styles.vDeleteAllNew}
            key="delete"
            onClick={handleAllDelete}
          >
            Delete All
          </div>
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
    // </Card>
  );
};

export default RecentSearches;
