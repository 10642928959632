import React from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Modal, Button } from 'antd';

import styles from './style.module.scss';

interface Props {
  isVisible: boolean;
  onCancel: Function;
  onAgree: Function;
  showFooter: boolean;
}
const CommunityGuidelines: React.FC = ({
  isVisible,
  onCancel,
  onAgree,
  showFooter,
}: Props) => {
  return (
    <Modal
      visible={isVisible}
      title="THINKSABIO, INC. COMMUNITY Terms & Conditions:"
      width={'70%'}
      bodyStyle={{ padding: '1rem' }}
      onCancel={onCancel}
      footer={
        showFooter
          ? [
              <Button key="cancel" onClick={onCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={onAgree}>
                I Agree
              </Button>,
            ]
          : null
      }
    >
      <div className={styles.featuresSection}>
        <h2 className={styles.titleFeature}>
          THINKSABIO, INC. COMMUNITY TERMS & CONDITIONS:
        </h2>
        <h2 className={styles.titleFeature}>Last Modified: August 31, 2021</h2>

        <ul>
          <li>
            <CheckCircleOutlined /> Do not verbally abuse, attack, embarrass, or
            threaten anyone else in the chat room, no matter what they might say
            to you.
          </li>
          <li>
            <CheckCircleOutlined />
            Do not use harmful, abusive, obscene, offensive, or sexually
            explicit language.
          </li>
          <li>
            <CheckCircleOutlined /> Do not post, promote, or distribute content
            that is illegal.
          </li>
          <li>
            <CheckCircleOutlined /> Do not harass, threaten, embarrass, or do
            anything else to another chatter that is unwanted. This means: don’t
            say bad things about them; don’t attack their character, race,
            heritage, etc. If you disagree with someone, respond to the subject,
            not the person.
          </li>
          <li>
            <CheckCircleOutlined />
            Do not disrupt the flow of chat in chat rooms with vulgar language,
            abusiveness, hitting the return key repeatedly or inputting large
            streams of text so the screen goes by too fast to read, etc.
          </li>
          <li>
            <CheckCircleOutlined /> Do not pretend to be anyone whom you are
            not. You may not impersonate another chatter, or any THINKSABIO
            employee or administrator.
          </li>
          <li>
            <CheckCircleOutlined /> Foul language, will not be tolerated unless
            all letters of the word are replaced by substitute characters.
            Example ($#!^ is acceptable. Sh!t is not. In no way should there
            EVER be letters or numbers used.) Also prolific foul language use,
            such as #*()$% ^!*()# *^() it all to #@**, will not be tolerated
            either, regardless of covering up the entire word.
          </li>
          <li>
            <CheckCircleOutlined /> If someone is sharing any
            content/message/opinions which you may disagree with, it is okay to
            disagree constructively but no hate speech or personal attacks are
            allowed.
          </li>
          <li>
            <CheckCircleOutlined /> Never type your messages in using all
            capital letters. This is considered to be shouting or yelling at
            another person, and this is not acceptable. If you want to
            *emphasize* a word or phrase, simply type an asterisk in before and
            then directly after your word or phrase.
          </li>
          <li>
            <CheckCircleOutlined />
            Posting content/Ideas that are available on any social media or
            other websites is allowed but NOT promoting their landing pages or
            posting information about any individual person.
          </li>
          <li>
            <CheckCircleOutlined /> Always cooperate with all THINKSABIO
            employees or administrators.
          </li>
          <li>
            <CheckCircleOutlined /> Anyone caught coming back in a chat room
            under a different username after being banned will be immediately
            denied access to the Website.
          </li>
          <li>
            All information shared via the Chat Function is strictly for
            educational and illustrative purposes only and doesn't imply any
            recommendation or buy or sell signals. You are solely responsible
            for your investment decisions. All investments involves risk, losses
            may exceed the principal invested, and the past performance of a
            security, industry, sector, market, or financial product does not
            guarantee future results or returns.
          </li>
        </ul>
      </div>
    </Modal>
  );
};

export default CommunityGuidelines;
