export const paymentHistoryColumns = [
  {
    title: 'Invoice Id',
    dataIndex: 'invoiceId',
    key: 'invoiceId',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Payment Card',
    key: 'payment_card',
    dataIndex: 'payment_card',
  },
  // {
  //   title: 'Card',
  //   key: 'card_last4',
  //   dataIndex: 'card_last4',
  // },
  {
    title: 'Payment Status',
    key: 'status',
    dataIndex: 'status',
  },
  {
    title: 'Amount',
    key: 'amount',
    dataIndex: 'amount',
  },
];
