import React, { useEffect } from 'react';

import { Form, Select, Row, Col, Button } from 'antd';
import { get } from 'lodash';
import queryString from 'query-string';
import { useSelector, shallowEqual } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import styles from './style.module.scss';

const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  reportType: string;
  reportSubType: string;
}
const SectorSearch: React.FC<Props> = ({
  history,
  reportType,
  reportSubType,
  prevPage,
}) => {
  const { sectorOptions } = useSelector(
    ({ report }) => ({
      sectorOptions: get(report, 'sectors', {}),
    }),
    shallowEqual,
  );
  const [form] = Form.useForm();

  useEffect(() => {
    if (
      (reportSubType === 'SECTOR_TRENDING' ||
        reportSubType === 'ALLOCATIONS') &&
      prevPage !== 'searchReport'
    ) {
      const filters = {
        type: reportType.toLowerCase(),
        subtype: reportSubType.toLowerCase(),
      };
      history.push({
        pathname:
          `/search/report/` +
          queryString.stringify(filters, {
            skipNull: true,
          }),
        state: { prevPage: 'search' },
      });
    }
  }, [history, prevPage, reportSubType, reportType]);

  const onFinish = (values) => {
    const { multiSector } = values;
    const filters = {
      type: reportType.toLowerCase(),
      subtype: reportSubType.toLowerCase(),
    };
    switch (reportSubType) {
      case 'ALL_INSTITUTIONS':
      case 'TOP_10_HEDGE':
        filters.multiSector = multiSector;
        break;
      case 'ALLOCATIONS':
      case 'SECTOR_TRENDING':
        break;
      default:
        break;
    }
    // history.push(
    //   `/search/report/` +
    //     queryString.stringify(filters, {
    //       skipNull: true,
    //     }),
    // );
    history.push({
      pathname:
        `/search/report/` +
        queryString.stringify(filters, {
          skipNull: true,
        }),

      state: { prevPage: 'search' },
    });
  };

  // const changeSearchType = (type) => {
  //   setSearchReportType(type);
  // };

  return (
    <Form
      name="sector-filter-form"
      onFinish={onFinish}
      labelCol={{ span: 24, padding: 0 }}
      wrapperCol={{ span: 24 }}
      form={form}
    >
      <Row span={24}>
        {/* <Col span={24}>
          <Form.Item name="searchType" label="Choose Action">
            <Select
              placeholder="Choose Action"
              onChange={(value) => changeSearchType(value)}
              size="middle"
            >
              {sectorSearchOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col> */}

        {(reportSubType === 'ALL_INSTITUTIONS' ||
          reportSubType === 'TOP_10_HEDGE') && (
          <Col span={24}>
            <Form.Item
              name="multiSector"
              label="Sector"
              style={{ margin: 0 }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Sector is required`,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Sector"
                size="large"
                // mode="multiple"
              >
                {sectorOptions.map((p, i) => (
                  <Option key={p.value + i} value={p.value}>
                    {p.name.toUpperCase()}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row span={24} justify="start" className={styles.buttonSectionBox}>
        <Form.Item colon={false} style={{ margin: 0, marginTop: 20 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default SectorSearch;
