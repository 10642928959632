// export const pickText = [
//   'Today’s Pick',
//   'Yesterday’s Pick',
//   "Day Before's Pick",
// ];

// export const Professions = ['Teacher', 'Engineer', 'Lawyer'];

// export const sectorOptions = [
//   'Basic Industries',
//   'Capital Goods',
//   'Consumer Durables',
//   'Consumer Non-Durables',
//   'Consumer Services',
//   'Energy',
//   'Finance',
//   'Health Care',
//   'Miscellaneous',
//   'Public Utilities',
//   'Technology',
//   'Transportation',
// ];

// export const sectorColors = [
//   {
//     value: 'Basic Industries',
//     color: 'magenta',
//   },
//   {
//     value: 'Basic Materials',
//     color: 'magenta',
//   },
//   {
//     value: 'Capital Goods',
//     color: 'red',
//   },
//   {
//     value: 'Consumer Goods',
//     color: 'volcano',
//   },
//   {
//     value: 'Consumer Durables',
//     color: 'volcano',
//   },
//   {
//     value: 'Consumer Non-Durables',
//     color: 'volcano',
//   },
//   {
//     value: 'Consumer Non Durables',
//     color: 'volcano',
//   },
//   {
//     value: 'Consumer Services',
//     color: 'volcano',
//   },
//   {
//     value: 'Consumer Cyclical',
//     color: 'volcano',
//   },
//   {
//     value: 'Consumer Defensive',
//     color: 'volcano',
//   },
//   {
//     value: 'Conglomerates',
//     color: 'lime',
//   },
//   {
//     value: 'Communication Services',
//     color: 'orange',
//   },
//   {
//     value: 'Communications',
//     color: 'orange',
//   },
//   {
//     value: 'Commercial Services',
//     color: 'gold',
//   },

//   {
//     value: 'Energy',
//     color: 'green',
//   },
//   {
//     value: 'Energy Minerals',
//     color: 'green',
//   },
//   {
//     value: 'Finance',
//     color: 'cyan',
//   },
//   {
//     value: 'Financial Services',
//     color: 'cyan',
//   },
//   {
//     value: 'Financial',
//     color: 'cyan',
//   },
//   {
//     value: 'Health Care',
//     color: 'blue',
//   },
//   {
//     value: 'Healthcare',
//     color: 'blue',
//   },
//   {
//     value: 'Health Technology',
//     color: 'blue',
//   },

//   {
//     value: 'Industrials',
//     color: 'geekblue',
//   },
//   {
//     value: 'Industrial Goods',
//     color: 'geekblue',
//   },
//   {
//     value: 'Miscellaneous',
//     color: 'purple',
//   },

//   {
//     value: 'Public Utilities',
//     color: 'geekblue',
//   },

//   {
//     value: 'Process Industries',
//     color: 'geekblue',
//   },
//   {
//     value: 'Producer Manufacturing',
//     color: 'geekblue',
//   },
//   {
//     value: 'Real Estate',
//     color: 'geekblue',
//   },
//   {
//     value: 'Services',
//     color: 'orange',
//   },
//   {
//     value: 'Technology',
//     color: 'purple',
//   },
//   {
//     value: 'Technology Services',
//     color: 'purple',
//   },
//   {
//     value: 'Transportation',
//     color: 'olive',
//   },
//   {
//     value: 'Utilities',
//     color: 'geekblue',
//   },
//   {
//     value: 'Others',
//     color: 'cyan',
//     style: {
//       color: '#eb2f96',
//       background: '#fff0f6',
//       borderColor: '#ffadd2',
//     },
//   },
// ];

export const insiderStatusOptions = [
  { name: 'All', value: 'ALL' },
  { name: 'Bought', value: 'INCREASED' },
  { name: 'Sold', value: 'DECREASED' },
];

export const statusOptions = [
  // { name: 'All', value: 'ALL' },
  { name: 'Increased', value: 'INCREASED' },
  { name: 'Reduced', value: 'DECREASED' },
  { name: 'No Change', value: 'NO_CHANGE' },
  { name: 'Newly Bought', value: 'NEWLY_BOUGHT' },
  { name: 'Sold Off', value: 'SOLD_OFF' },
];
export const statusOptionsDowHighLevelReport = [
  // { name: 'All', value: 'ALL' },
  { name: 'Down', value: 'DOWN' },
  { name: 'Up', value: 'UP' },
];

export const allStatusOptions = [
  { name: 'All', value: 'ALL' },
  { name: 'Increased', value: 'INCREASED' },
  { name: 'Reduced', value: 'DECREASED' },
  { name: 'No Change', value: 'NO_CHANGE' },
  { name: 'Newly Bought', value: 'NEWLY_BOUGHT' },
  { name: 'Sold Off', value: 'SOLD_OFF' },
];

export const marketCapOptions = [
  // { name: 'All', value: 'ALL' },
  { name: 'Micro Cap', value: 'MICRO' },
  { name: 'Small Cap', value: 'SMALL' },
  { name: 'Mid Cap', value: 'MID' },
  { name: 'Large Cap', value: 'LARGE' },
];

export const tsConvictionOptions = [
  { name: 'High', value: 'HIGH' },
  { name: 'Medium', value: 'MEDIUM' },
  { name: 'Low', value: 'LOw' },
];

export const statusOptions2 = [
  // { name: 'All', value: 'ALL' },
  { name: 'Increased', value: 'INCREASED' },
  { name: 'Reduced', value: 'DECREASED' },
  { name: 'No Change', value: 'NO_CHANGE' },
  { name: 'Newly Bought', value: 'NEWLY_BOUGHT' },
  { name: 'Sold Off', value: 'SOLD_OFF' },
  { name: 'Consistently Increased', value: 'CONSISTENTLY_INCREASED' },
  { name: 'Consistently Decreased', value: 'CONSISTENTLY_DECREASED' },
];
export const statusOptions3 = [
  { name: 'Added', value: 'ADDED' },
  { name: 'Removed', value: 'REMOVED' },
  { name: 'Unchanged', value: 'UNCHANGED' },
];
export const criterionOptions = ['COUNT', 'SHARES', 'BOTH'];

export const noOfQtrList = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const Professions = [
  'Accountant',
  'Artist',
  'Business & Finance',
  'Economist',
  'Education & Training',
  'Engineer',
  'Farming & Fishing',
  'Financial Manager',
  'Food & Serving',
  'Healthcare',
  'IT & Human Resources',
  'Journalist',
  'Labourer',
  'Lawyer',
  'Legal',
  'Medical & Emergency',
  'Office & Administrative',
  'Others',
  'Sports',
  'Technician',
];

export const typeOptions = [
  { name: '', value: 'All' },
  { name: 'Buy', value: 'Buy' },
  { name: 'Sell', value: 'Sell' },
];
export const durationOptions = [
  { name: '1 Year', value: '' },
  { name: '1 Week', value: '1Week' },
  { name: '2 Weeks', value: '2Weeks' },
  { name: '1 Month', value: '1Month' },
  { name: '2 Months', value: '2Months' },
  { name: '3 Months', value: '3Months' },
  { name: '6 Months', value: '6Months' },
];

export const categoryOptions = [
  { name: 'Top 10 Hedge Funds', value: 'Top 10 Hedge Funds' },
  { name: 'All Institutions', value: 'All Institutions' },
];
export const statusTxnOptions = [
  { name: 'BUY', value: 'INCREASED' },
  { name: 'SELL', value: 'DECREASED' },
];

export const usStates = [
  {
    name: 'ALABAMA',
    abbreviation: 'AL',
    lat: 32.31823,
    lon: -86.902298,
    id: '01',
    showMarker: true,
  },
  {
    name: 'ALASKA',
    abbreviation: 'AK',
    lat: 66.160507,
    lon: -153.369141,
    id: '02',
    showMarker: true,
  },
  {
    name: 'AMERICAN SAMOA',
    abbreviation: 'AS',
    // lat: 14.271,
    // lon: -170.1322
    showMarker: true,
  },
  {
    name: 'ARIZONA',
    abbreviation: 'AZ',
    lat: 34.048927,
    lon: -111.093735,
    id: '04',
    showMarker: true,
  },
  {
    name: 'ARKANSAS',
    abbreviation: 'AR',
    lat: 34.799999,
    lon: -92.199997,
    id: '05',
    showMarker: true,
  },
  {
    name: 'CALIFORNIA',
    abbreviation: 'CA',
    lat: 36.778259,
    lon: -119.417931,
    id: '06',
    showMarker: true,
  },
  {
    name: 'COLORADO',
    abbreviation: 'CO',
    lat: 39.113014,
    lon: -105.358887,
    id: '08',
    showMarker: true,
  },
  {
    name: 'CONNECTICUT',
    abbreviation: 'CT',
    lat: 41.599998,
    lon: -72.699997,
    id: '09',
    showMarker: false,
  },
  {
    name: 'DELAWARE',
    abbreviation: 'DE',
    lat: 39,
    lon: -75.5,
    id: '10',
    showMarker: false,
  },
  {
    name: 'DISTRICT OF COLUMBIA',
    abbreviation: 'DC',
    lat: 38.9072,
    lon: -77.0369,
    id: '11',
    showMarker: false,
  },
  {
    name: 'FEDERATED STATES OF MICRONESIA',
    abbreviation: 'FM',
    // lat: 7.4256,
    // lon: -150.5508
    showMarker: true,
  },
  {
    name: 'FLORIDA',
    abbreviation: 'FL',
    lat: 27.994402,
    lon: -81.760254,
    id: '12',
    showMarker: true,
  },
  {
    name: 'GEORGIA',
    abbreviation: 'GA',
    lat: 33.247875,
    lon: -83.441162,
    id: '13',
    showMarker: true,
  },
  {
    name: 'GUAM',
    abbreviation: 'GU' /*lat: 13.4443, lon: -144.7937*/,
    showMarker: true,
  },
  {
    name: 'HAWAII',
    abbreviation: 'HI',
    lat: 19.741755,
    lon: -155.844437,
    id: '15',
    showMarker: false,
  },
  {
    name: 'IDAHO',
    abbreviation: 'ID',
    lat: 44.068203,
    lon: -114.742043,
    id: '16',
    showMarker: true,
  },
  {
    name: 'ILLINOIS',
    abbreviation: 'IL',
    lat: 40,
    lon: -89,
    id: '17',
    showMarker: true,
  },
  {
    name: 'INDIANA',
    abbreviation: 'IN',
    lat: 40.273502,
    lon: -86.126976,
    id: '18',
    showMarker: true,
  },
  {
    name: 'IOWA',
    abbreviation: 'IA',
    lat: 42.032974,
    lon: -93.581543,
    id: '19',
    showMarker: true,
  },
  {
    name: 'KANSAS',
    abbreviation: 'KS',
    lat: 38.5,
    lon: -98,
    id: '20',
    showMarker: true,
  },
  {
    name: 'KENTUCKY',
    abbreviation: 'KY',
    lat: 37.839333,
    lon: -84.27002,
    id: '21',
    showMarker: true,
  },
  {
    name: 'LOUISIANA',
    abbreviation: 'LA',
    lat: 30.39183,
    lon: -92.329102,
    id: '22',
    showMarker: true,
  },
  {
    name: 'MAINE',
    abbreviation: 'ME',
    lat: 45.367584,
    lon: -68.972168,
    id: '23',
    showMarker: true,
  },
  {
    name: 'MARSHALL ISLANDS',
    abbreviation: 'MH',
    // lat: 7.1315,
    // lon: -171.1845
    showMarker: true,
  },
  {
    name: 'MARYLAND',
    abbreviation: 'MD',
    lat: 39.045753,
    lon: -76.641273,
    id: '24',
    showMarker: false,
  },
  {
    name: 'MASSACHUSETTS',
    abbreviation: 'MA',
    lat: 42.407211,
    lon: -71.382439,
    id: '25',
    showMarker: false,
  },
  {
    name: 'MICHIGAN',
    abbreviation: 'MI',
    lat: 44.182205,
    lon: -84.506836,
    id: '26',
    showMarker: true,
  },
  {
    name: 'MINNESOTA',
    abbreviation: 'MN',
    lat: 46.39241,
    lon: -94.63623,
    id: '27',
    showMarker: true,
  },
  {
    name: 'MISSISSIPPI',
    abbreviation: 'MS',
    lat: 33,
    lon: -90,
    id: '09',
    showMarker: true,
  },
  {
    name: 'MISSOURI',
    abbreviation: 'MO',
    lat: 38.573936,
    lon: -92.60376,
    id: '28',
    showMarker: true,
  },
  {
    name: 'MONTANA',
    abbreviation: 'MT',
    lat: 46.96526,
    lon: -109.533691,
    id: '30',
    showMarker: true,
  },
  {
    name: 'NEBRASKA',
    abbreviation: 'NE',
    lat: 41.5,
    lon: -100,
    id: '31',
    showMarker: true,
  },
  {
    name: 'NEVADA',
    abbreviation: 'NV',
    lat: 39.876019,
    lon: -117.224121,
    id: '32',
    showMarker: true,
  },
  {
    name: 'NEW HAMPSHIRE',
    abbreviation: 'NH',
    lat: 44,
    lon: -71.5,
    id: '33',
    showMarker: false,
  },
  {
    name: 'NEW JERSEY',
    abbreviation: 'NJ',
    lat: 39.833851,
    lon: -74.871826,
    id: '34',
    showMarker: false,
  },
  {
    name: 'NEW MEXICO',
    abbreviation: 'NM',
    lat: 34.307144,
    lon: -106.018066,
    id: '35',
    showMarker: true,
  },
  {
    name: 'NEW YORK',
    abbreviation: 'NY',
    lat: 43,
    lon: -75,
    id: '36',
    showMarker: true,
  },
  {
    name: 'NORTH CAROLINA',
    abbreviation: 'NC',
    lat: 35.782169,
    lon: -80.793457,
    id: '37',
    showMarker: true,
  },
  {
    name: 'NORTH DAKOTA',
    abbreviation: 'ND',
    lat: 47.650589,
    lon: -100.437012,
    id: '38',
    showMarker: true,
  },
  {
    name: 'NORTHERN MARIANA ISLANDS',
    abbreviation: 'MP',
    // lat: 15.0979,
    // lon: -145.6739
    showMarker: true,
  },
  {
    name: 'OHIO',
    abbreviation: 'OH',
    lat: 40.367474,
    lon: -82.996216,
    id: '39',
    showMarker: true,
  },
  {
    name: 'OKLAHOMA',
    abbreviation: 'OK',
    lat: 36.084621,
    lon: -96.921387,
    id: '40',
    showMarker: true,
  },
  {
    name: 'OREGON',
    abbreviation: 'OR',
    lat: 44,
    lon: -120.5,
    id: '41',
    showMarker: true,
  },
  {
    name: 'PALAU',
    abbreviation: 'PW' /*lat: 7.51498, lon: -134.58252*/,
    showMarker: true,
  },
  {
    name: 'PENNSYLVANIA',
    abbreviation: 'PA',
    lat: 41.203323,
    lon: -77.194527,
    id: '42',
    showMarker: true,
  },
  {
    name: 'PUERTO RICO',
    abbreviation: 'PR' /*lat: 18.46633, lon: -66.10572*/,
    showMarker: true,
  },
  {
    name: 'RHODE ISLAND',
    abbreviation: 'RI',
    lat: 41.742325,
    lon: -71.742332,
    id: '44',
    showMarker: false,
  },
  {
    name: 'SOUTH CAROLINA',
    abbreviation: 'SC',
    lat: 33.836082,
    lon: -81.163727,
    id: '45',
    showMarker: true,
  },
  {
    name: 'SOUTH DAKOTA',
    abbreviation: 'SD',
    lat: 44.5,
    lon: -100,
    id: '46',
    showMarker: true,
  },
  {
    name: 'TENNESSEE',
    abbreviation: 'TN',
    lat: 35.860119,
    lon: -86.660156,
    id: '47',
    showMarker: true,
  },
  {
    name: 'TEXAS',
    abbreviation: 'TX',
    lat: 31,
    lon: -100,
    id: '48',
    showMarker: true,
  },
  {
    name: 'UTAH',
    abbreviation: 'UT',
    lat: 39.41922,
    lon: -111.950684,
    id: '49',
    showMarker: true,
  },
  {
    name: 'VERMONT',
    abbreviation: 'VT',
    lat: 44,
    lon: -72.699997,
    id: '50',
    showMarker: false,
  },
  {
    name: 'VIRGIN ISLANDS',
    abbreviation: 'VI',
    lat: 34.297878,
    lon: -83.824066,
    id: '78',
    showMarker: true,
  },
  {
    name: 'VIRGINIA',
    abbreviation: 'VA',
    lat: 37.926868,
    lon: -78.024902,
    id: '51',
    showMarker: true,
  },
  {
    name: 'WASHINGTON',
    abbreviation: 'WA',
    lat: 47.751076,
    lon: -120.740135,
    id: '53',
    showMarker: true,
  },
  {
    name: 'WEST VIRGINIA',
    abbreviation: 'WV',
    lat: 39,
    lon: -80.5,
    id: '54',
    showMarker: true,
  },
  {
    name: 'WISCONSIN',
    abbreviation: 'WI',
    lat: 44.5,
    lon: -89.5,
    id: '55',
    showMarker: true,
  },
  {
    name: 'WYOMING',
    abbreviation: 'WY',
    lat: 43.07597,
    lon: -107.290283,
    id: '56',
    showMarker: true,
  },
];

export const grades = [
  { grade: 'Freshman - 9' },
  { grade: 'Sophomore - 10' },
  { grade: 'Junior - 11' },
  { grade: 'Senior - 12' },
];

export const inValidDomains = [
  'gmail',
  'googlemail',
  'yahoo',
  'ymail',
  'hotmail',
  'rocketmail',
  'outlook',
  'icloud',
  'zohomail',
  'trashmail',
  'gmx',
  'lycos',
  'tutanota',
  'protonmail',
  'mail',
  'safe-mail',
  'hushmail',
  'hush',
  'thunderbird',
  'fastmail',
  '123mail',
  'ionos',
  'aol',
  'rackspace',
  'yandex',
  'airmail',
  'mailgun',
  'mailfence',
  'posteo',
  'soverin',
  'disroot',
  'startmail',
  '0815',
  '0wnd',
  '0wnd',
  '10minutemail',
  '10minutemail',
  '123-m',
  '1fsdfdsfsdf',
  '1pad',
  '20minutemail',
  '21cn',
  '2fdgdfgdfgdf',
  '2prong',
  '30minutemail',
  '33mail',
  '3trtretgfrfe',
  '4gfdsgfdgfd',
  '4warding',
  '5ghgfhfghfgh',
  '6hjgjhgkilkj',
  '6paq',
  '7tags',
  '9ox',
  'a-bc',
  'agedmail',
  'ama-trade',
  'amilegit',
  'amiri',
  'amiriindustries',
  'anonmails',
  'anonymbox',
  'antichef',
  'antichef',
  'antireg',
  'antispam',
  'antispammail',
  'armyspy',
  'artman-conception',
  'azmeil',
  'baxomale',
  'beefmilk',
  'bigstring',
  'binkmail',
  'bio-muesli',
  'bobmail',
  'bodhi',
  'bofthew',
  'bootybay',
  'boun',
  'bouncr',
  'breakthru',
  'brefmail',
  'bsnow',
  'bspamfree',
  'bugmenot',
  'bund',
  'burstmail',
  'buymoreplays',
  'byom',
  'c2',
  'card',
  'casualdx',
  'cek',
  'centermail',
  'centermail',
  'chammy',
  'childsavetrust',
  'chogmail',
  'choicemail1',
  'clixser',
  'cmail',
  'cmail',
  'coldemail',
  'cool',
  'courriel',
  'courrieltemporaire',
  'crapmail',
  'cust',
  'cuvox',
  'd3p',
  'dacoolest',
  'dandikmail',
  'dayrep',
  'dcemail',
  'deadaddress',
  'deadspam',
  'delikkt',
  'despam',
  'despammed',
  'devnullmail',
  'dfgh',
  'digitalsanctuary',
  'dingbone',
  'disposableaddress',
  'disposableemailaddresses',
  'disposableinbox',
  'dispose',
  'dispostable',
  'dodgeit',
  'dodgit',
  'donemail',
  'dontreg',
  'dontsendmespam',
  'drdrb',
  'dump-email',
  'dumpandjunk',
  'dumpyemail',
  'e-mail',
  'e-mail',
  'e4ward',
  'easytrashmail',
  'einmalmail',
  'einrot',
  'eintagsmail',
  'emailgo',
  'emailias',
  'emaillime',
  'emailsensei',
  'emailtemporanea',
  'emailtemporanea',
  'emailtemporar',
  'emailtemporario',
  'emailthe',
  'emailtmp',
  'emailwarden',
  'emailx',
  'emailxfer',
  'emeil',
  'emeil',
  'emz',
  'ero-tube',
  'evopo',
  'explodemail',
  'express',
  'eyepaste',
  'fakeinbox',
  'fakeinformation',
  'fansworldwide',
  'fantasymail',
  'fightallspam',
  'filzmail',
  'fivemail',
  'fleckens',
  'frapmail',
  'friendlymail',
  'fuckingduh',
  'fudgerub',
  'fyii',
  'garliclife',
  'gehensiemirnichtaufdensack',
  'get2mail',
  'getairmail',
  'getmails',
  'getonemail',
  'giantmail',
  'girlsundertheinfluence',
  'gishpuppy',
  'gmial',
  'goemailgo',
  'gotmail',
  'gotmail',
  'gotti',
  'great-host',
  'greensloth',
  'grr',
  'gsrv',
  'guerillamail',
  'guerillamail',
  'guerrillamail',
  'guerrillamail',
  'guerrillamail',
  'guerrillamail',
  'guerrillamail',
  'guerrillamail',
  'guerrillamailblock',
  'gustr',
  'harakirimail',
  'hat-geld',
  'hatespam',
  'herp',
  'hidemail',
  'hidzz',
  'hmamail',
  'hopemail',
  'ieh-mail',
  'ikbenspamvrij',
  'imails',
  'inbax',
  'inbox',
  'inboxalias',
  'inboxclean',
  'inboxclean',
  'infocom',
  'instant-mail',
  'ip6',
  'irish2me',
  'iwi',
  'jetable',
  'jetable',
  'jetable',
  'jetable',
  'jnxjn',
  'jourrapide',
  'jsrsolutions',
  'kasmail',
  'kaspop',
  'killmail',
  'killmail',
  'klassmaster',
  'klzlk',
  'koszmail',
  'kurzepost',
  'lawlita',
  'letthemeatspam',
  'lhsdv',
  'lifebyfood',
  'link2mail',
  'litedrop',
  'lol',
  'lolfreak',
  'lookugly',
  'lortemail',
  'lr78',
  'lroid',
  'lukop',
  'm21',
  'mail-filter',
  'mail-temporaire',
  'mail',
  'mail',
  'mail',
  'mail1a',
  'mail21',
  'mail2rss',
  'mail333',
  'mailbidon',
  'mailbiz',
  'mailblocks',
  'mailbucket',
  'mailcat',
  'mailcatch',
  'mailde',
  'mailde',
  'maildrop',
  'maileimer',
  'mailexpire',
  'mailfa',
  'mailforspam',
  'mailfreeonline',
  'mailguard',
  'mailin8r',
  'mailinater',
  'mailinator',
  'mailinator',
  'mailinator',
  'mailinator2',
  'mailincubator',
  'mailismagic',
  'mailme',
  'mailme24',
  'mailmetrash',
  'mailmoat',
  'mailms',
  'mailnesia',
  'mailnull',
  'mailorg',
  'mailpick',
  'mailrock',
  'mailscrap',
  'mailshell',
  'mailsiphon',
  'mailtemp',
  'mailtome',
  'mailtothis',
  'mailtrash',
  'mailtv',
  'mailtv',
  'mailzilla',
  'makemetheking',
  'manybrain',
  'mbx',
  'mega',
  'meinspamschutz',
  'meltmail',
  'messagebeamer',
  'mezimages',
  'ministry-of-silly-walks',
  'mintemail',
  'misterpinball',
  'moncourrier',
  'monemail',
  'monmail',
  'monumentmail',
  'mt2009',
  'mt2014',
  'mycard',
  'mycleaninbox',
  'mymail-in',
  'mypacks',
  'mypartyclip',
  'myphantomemail',
  'mysamp',
  'mytempemail',
  'mytempmail',
  'mytrashmail',
  'nabuma',
  'neomailbox',
  'nepwk',
  'nervmich',
  'nervtmich',
  'netmails',
  'netmails',
  'neverbox',
  'nice-4u',
  'nincsmail',
  'nnh',
  'no-spam',
  'noblepioneer',
  'nomail',
  'nomail',
  'nomail2me',
  'nomorespamemails',
  'nospam',
  'nospam4',
  'nospamfor',
  'nospammail',
  'notmailinator',
  'nowhere',
  'nowmymail',
  'nurfuerspam',
  'nus',
  'objectmail',
  'obobbo',
  'odnorazovoe',
  'oneoffemail',
  'onewaymail',
  'onlatedotcom',
  'opayq',
  'ordinaryamerican',
  'otherinbox',
  'ovpn',
  'owlpic',
  'pancakemail',
  'pcusers',
  'pjjkp',
  'plexolan',
  'poczta',
  'politikerclub',
  'poofy',
  'pookmail',
  'privacy',
  'privatdemail',
  'proxymail',
  'prtnx',
  'putthisinyourspamdatabase',
  'putthisinyourspamdatabase',
  'qq',
  'quickinbox',
  'rcpt',
  'reallymymail',
  'realtyalerts',
  'recode',
  'recursor',
  'reliable-mail',
  'rhyta',
  'rmqkr',
  'royal',
  'rtrtr',
  's0ny',
  'safe-mail',
  'safersignup',
  'safetymail',
  'safetypost',
  'saynotospams',
  'schafmail',
  'schrott-email',
  'secretemail',
  'secure-mail',
  'senseless-entertainment',
  'services391',
  'sharklasers',
  'shieldemail',
  'shiftmail',
  'shitmail',
  'shitware',
  'shmeriously',
  'shortmail',
  'sibmail',
  'sinnlos-mail',
  'slapsfromlastnight',
  'slaskpost',
  'smashmail',
  'smellfear',
  'snakemail',
  'sneakemail',
  'sneakmail',
  'snkmail',
  'sofimail',
  'solvemail',
  'sogetthis',
  'soodonims',
  'spam4',
  'spamail',
  'spamarrest',
  'spambob',
  'spambog',
  'spambox',
  'spamcannon',
  'spamcannon',
  'spamcon',
  'spamcorptastic',
  'spamcowboy',
  'spamcowboy',
  'spamcowboy',
  'spamday',
  'spamex',
  'spamfree',
  'spamfree24',
  'spamfree24',
  'spamfree24',
  'spamgoes',
  'spamgourmet',
  'spamgourmet',
  'spamgourmet',
  'spamherelots',
  'spamherelots',
  'spamhereplease',
  'spamhereplease',
  'spamhole',
  'spamify',
  'spaml',
  'spammotel',
  'spamobox',
  'spamslicer',
  'spamspot',
  'spamthis',
  'spamtroll',
  'speed',
  'spoofmail',
  'stuffmail',
  'super-auswahl',
  'supergreatmail',
  'supermailer',
  'superrito',
  'superstachel',
  'suremail',
  'talkinator',
  'teewars',
  'teleworm',
  'teleworm',
  'temp-mail',
  'temp-mail',
  'tempe-mail',
  'tempemail',
  'tempemail',
  'tempemail',
  'tempemail',
  'tempinbox',
  'tempinbox',
  'tempmail',
  'tempmaildemo',
  'tempmailer',
  'tempmailer',
  'tempomail',
  'temporaryemail',
  'temporaryforwarding',
  'temporaryinbox',
  'temporarymailaddress',
  'tempthe',
  'thankyou2010',
  'thc',
  'thelimestones',
  'thisisnotmyrealemail',
  'thismail',
  'throwawayemailaddress',
  'tilien',
  'tittbit',
  'tizi',
  'tmailinator',
  'toomail',
  'topranklist',
  'tradermail',
  'trash-mail',
  'trash-mail',
  'trash-mail',
  'trash2009',
  'trashdevil',
  'trashemail',
  'trashmail',
  'trashmail',
  'trashmail',
  'trashmail',
  'trashmail',
  'trashmail',
  'trashymail',
  'trialmail',
  'trillianpro',
  'twinmail',
  'tyldd',
  'uggsrock',
  'umail',
  'uroid',
  'us',
  'venompen',
  'veryrealemail',
  'viditag',
  'viralplays',
  'vpn',
  'vsimcard',
  'vubby',
  'wasteland',
  'webemail',
  'weg-werf-email',
  'wegwerf-emails',
  'wegwerfadresse',
  'wegwerfemail',
  'wegwerfemail',
  'wegwerfmail',
  'wegwerfmail',
  'wegwerfmail',
  'wegwerfmail',
  'wh4f',
  'whyspam',
  'willhackforfood',
  'willselfdestruct',
  'winemaven',
  'wronghead',
  'www',
  'www',
  'wwwnew',
  'xagloo',
  'xemaps',
  'xents',
  'xmaily',
  'xoxy',
  'yep',
  'yogamaven',
  'yourdomain',
  'yuurok',
  'z1p',
  'za',
  'zehnminuten',
  'zehnminutenmail',
  'zippymail',
  'zoemail',
  'zomg',
];

//const validDomains = ['pss', 'rsd', 'iusd'];

export const BarColors = [
  '#4F81BD',
  '#C0504D',
  '#9ABB59',
  '#8063A1',
  '#4BACC6',
  '#F89645',
  '#2C4C75',
  '#772B29',
  '#d26e00',
  '#ff5987',
  '#be82be',
  '#8761ec',
  '#007ff4',
  '#82c8d2',
  '#800000',
  '#9A6324',
  '#808000',
  '#f032e6',
  '#469990',
  '#dcbeff',
  '#f58231',
  '#ffe119',
  '#bfef45',
  '#3cb44b',
  '#42d4f4',
];

export const cntRegex = 'lastQtr([0-9]*)Count$';
export const shrsRegex = 'lastQtr([0-9]*)Shares$';
