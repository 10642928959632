import React, { useEffect, useState } from 'react';

import { Form, Row, Button, Col, Select } from 'antd';
import { get } from 'lodash';
import queryString from 'query-string';
import { shallowEqual, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { getFilerNames, getMutualFundSymbols } from '../../graphql';
import { green } from '../../utils';
import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {
  reportType: string;
  reportSubType: string;
}
const { Option } = Select;
const MutualFundsSearch: React.FC<Props> = ({
  history,
  reportType,
  reportSubType = '',
  prevPage,
}) => {
  const [form] = Form.useForm();
  const [fundNameData, setFundNameData] = useState([]);
  const [fundSymbolData, setFundSymbolData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    if (
      reportSubType !== 'BY_FUND_NAME' &&
      reportSubType !== 'SYMBOL_SUMMARY_MUTUAL_FUNDS' &&
      reportSubType !== 'BY_FUND_SYMBOL' &&
      prevPage !== 'searchReport'
    ) {
      const filters = {
        type: reportType.toLowerCase(),
        subtype: reportSubType.toLowerCase(),
      };
      history.push({
        pathname:
          `/search/report/` +
          queryString.stringify(filters, {
            skipNull: true,
          }),
        state: { prevPage: 'search' },
      });
    }
  }, [history, prevPage, reportSubType, reportType]);
  const { mutualFundBySymbolOptions } = useSelector(
    ({ report }) => ({
      mutualFundBySymbolOptions: get(report, 'fundSymbols', {}),
    }),
    shallowEqual,
  );

  const fetchFilerNames = async (value) => {
    setFetching(true);
    const res = await getFilerNames(value);
    if (res) {
      setFundNameData(res);
    }
    setFetching(false);
  };
  const fetchFundSymbols = async (value) => {
    setFetching(true);
    const res = await getMutualFundSymbols(value);
    if (res) {
      setFundSymbolData(res);
    }
    setFetching(false);
  };

  const onFinish = (values) => {
    const { fund, symbol, fundSymbol } = values;
    const filters = {
      type: reportType.toLowerCase(),
      subtype: reportSubType.toLowerCase(),
    };
    switch (reportSubType) {
      case 'BY_FUND_NAME':
        filters.filerName = fund;
        break;
      case 'SYMBOL_SUMMARY_MUTUAL_FUNDS':
        filters.symbols = symbol;
        break;
      case 'BY_FUND_SYMBOL':
        filters.fundSymbols = fundSymbol;
        break;
      default:
        break;
    }
    history.push({
      pathname:
        `/search/report/` +
        queryString.stringify(filters, {
          skipNull: true,
        }),
      state: { prevPage: 'search' },
    });
  };

  return (
    <Form
      name="mutual-fund-filter-form"
      onFinish={onFinish}
      labelCol={{ span: 24, padding: 0 }}
      wrapperCol={{ span: 24 }}
      form={form}
    >
      <Row span={24}></Row>
      <Row span={24} justify="start" className={styles.buttonSectionBox}>
        {reportSubType === 'BY_FUND_NAME' && (
          <Col span={24}>
            <Form.Item
              name="fund"
              label="Search Fund Name"
              style={{ width: '100%' }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Fund Name is required`,
                },
              ]}
            >
              <Select
                placeholder="Enter valid Fund Names"
                // mode={'multiple'}
                notFoundContent={
                  fetching ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                onSearch={fetchFilerNames}
                showSearch={true}
                allowClear={true}
                showArrow={false}
                filterOption={false}
                onSelect={() => setFundNameData([])}
                size="large"
              >
                {fundNameData.map((p, i) => (
                  <Option key={p.value + i} value={p.value}>
                    {p.name.toUpperCase()}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {reportSubType === 'BY_FUND_SYMBOL' && (
          <Col span={24}>
            <Form.Item
              name="fundSymbol"
              label="Search Symbol"
              style={{ width: '100%' }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Mutual Fund Symbol is required`,
                },
              ]}
            >
              <Select showSearch placeholder="Mutual Fund Symbol" size="large">
                {mutualFundBySymbolOptions.map((p, i) => (
                  <Option key={p.value + i} value={p.value}>
                    {p.name.toUpperCase()}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {reportSubType === 'SYMBOL_SUMMARY_MUTUAL_FUNDS' && (
          <Col span={24}>
            <Form.Item
              name="symbol"
              label="Search Symbol"
              style={{ width: '100%' }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Symbol is required`,
                },
              ]}
            >
              <Select
                notFoundContent={
                  fetching ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                onSearch={fetchFundSymbols}
                showSearch={true}
                allowClear={true}
                showArrow={false}
                filterOption={false}
                onSelect={() => setFundSymbolData([])}
                size="large"
              >
                {fundSymbolData.map((p, i) => (
                  <Option key={p.value + i} value={p.value}>
                    {p.name.toUpperCase()}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col span={24}>
          <Form.Item
            colon={false}
            className={styles.vAdvanceterSubmit}
            style={{ margin: 0, marginTop: 20 }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default MutualFundsSearch;
