import React from 'react';

import { Tag } from 'antd';
import moment from 'moment-timezone';

import { formatNumber2, currencyFormat } from '../../utils';
import { NonClickAbleFilter, filtersNotToShow } from './Reports.constants';
import styles from './style.module.scss';

interface Props {
  filterOptions: Object;
  handleReset: Function;
  handleClick: Function;
  reportCode: string;
}

const FilteredValues: React.FC<Props> = ({
  filterOptions,
  handleReset,
  handleClick,
  reportCode,
}) => {
  const {
    maxPrice,
    min1Yr,
    max1Yr,
    min5Yr,
    minInstCount,
    minCount,
    maxCount,
    minAllocatedPerc,
    maxAllocatedPerc,
    minInstShares,
    maxInstShares,
    minAproxTxnValue,
    minAproxInvValue,
    maxAproxTxnValue,
    maxLastQ2InstCount,
    minLastQ2InstCount,
    minPrice,
    minStartPrice,
    maxStartPrice,
    minEndPrice,
    maxEndPrice,
    minYTD,
    maxYTD,
    sector,
    symbols,
    noOfQuarters,
    criterion,
    minPerChange,
    minPercentage,
    maxPercentage,
    minSharesChange,
    minTotalSharesChange,
    maxTotalSharesChange,
    minVolumeChange,
    maxVolumeChange,
    minAvgChange,
    maxAvgChange,
    minCountChange,
    maxCountChange,
    minPossibleGrowth,
    maxPossibleGrowth,
    minRank,
    maxRank,
    minInvestedAmt,
    maxInvestedAmt,
    minInvestedAmtReturns,
    minInvestedReturnsPerc,
    minTotalPFvalue,
    minTotalPFReturnsPerc,
    maxInvestedAmtReturns,
    maxInvestedReturnsPerc,
    maxTotalPFvalue,
    maxTotalPFReturnsPerc,
    filerName,
    seriesName,
    maxSharesChange,
    type,
    week,
    minGrowth,
    endDate,
    startDate,
    fromDate,
    minVolDiff,
    maxVolDiff,
    minOwnership,
    date,
    multiStatus,
    multiMarketCap,
    multiSector,
    excludeMultiSector,
    multiIndustry,
    insiderName,
    insiderTitle,
    isTenPercent,
    minInsiderTransactionValue,
    maxInsiderTransactionValue,
    minPerReturn,
    minSecInstCount,
    minSecInsiderCount,
    minSec13dCount,
    minSharesChangePer,
    minOneDayChange,
    maxOneDayChange,
    minGain,
    maxGain,
    minLoss,
    maxLoss,
    minDayGain,
    maxDayGain,
    minDayLoss,
    maxDayLoss,
    minVolGain,
    maxVolGain,
    minVolLoss,
    maxVolLoss,
    maxInstCount,
    minPrevQInstCount,
    country,
    minAum,
    maxAum,
    minPosReturnsPerc,
    maxPosReturnsPerc,
    minNegReturnsPerc,
    maxNegReturnsPerc,
    minNegReturnsStockCount,
    maxNegReturnsStockCount,
    minPosReturnsStockCount,
    maxPosReturnsStockCount,
    minTotalCount,
    maxTotalCount,
    minPrevAum,
    maxPrevAum,
    hedgeCount,
    minMarketCap,
    maxMarketCap,
    maxPrevQInstCount,
    institutions,
    minTop10HedgeFundsIn_NC_NB_CNT,
    maxTop10HedgeFundsIn_NC_NB_CNT,
    minTop10InstIn_NC_NB_CNT,
    maxTop10InstIn_NC_NB_CNT,
  } = filterOptions;
  const data = [
    {
      label: 'Insider',
      value: insiderName,
      type: 'string',
      name: 'insiderName',
    },
    {
      label: 'Insider Title',
      value: insiderTitle,
      type: 'string',
      name: 'insiderTitle',
    },
    {
      label: 'Is 10% Owner',
      value: isTenPercent,
      type: 'string',
      name: 'isTenPercent',
    },

    {
      label: 'Sector',
      value: sector,
      type: 'string',
      name: 'sector',
    },
    {
      label: 'Industry',
      value: multiIndustry,
      type: 'multi',
      name: 'multiIndustry',
    },
    {
      label: 'Sector',
      value: multiSector,
      type: 'multi',
      name: 'multiSector',
    },
    {
      label: 'Exclude Sector',
      value: excludeMultiSector,
      type: 'multi',
      name: 'excludeMultiSector',
    },
    {
      label: 'Min 1 Day Change',
      value: minOneDayChange,
      type: 'percent',
      name: 'minOneDayChange',
    },
    {
      label: 'Max 1 Day Change',
      value: maxOneDayChange,
      type: 'percent',
      name: 'maxOneDayChange',
    },
    {
      label: 'Min % Gain',
      value: minGain,
      type: 'percent',
      name: 'minGain',
    },
    {
      label: 'Max % Gain',
      value: maxGain,
      type: 'percent',
      name: 'maxGain',
    },
    {
      label: 'Min % Loss',
      value: minLoss,
      type: 'percent',
      name: 'minLoss',
    },
    {
      label: 'Max % Loss',
      value: maxLoss,
      type: 'percent',
      name: 'maxLoss',
    },
    {
      label: 'Min % Gain',
      value: minDayGain,
      type: 'percent',
      name: 'minDayGain',
    },
    {
      label: 'Max % Gain',
      value: maxDayGain,
      type: 'percent',
      name: 'maxDayGain',
    },
    {
      label: 'Min % Loss',
      value: minDayLoss,
      type: 'percent',
      name: 'minDayLoss',
    },
    {
      label: 'Max % Loss',
      value: maxDayLoss,
      type: 'percent',
      name: 'maxDayLoss',
    },
    {
      label: 'Min Vol % Gain',
      value: minVolGain,
      type: 'percent',
      name: 'minVolGain',
    },
    {
      label: 'Max Vol % Gain',
      value: maxVolGain,
      type: 'percent',
      name: 'maxVolGain',
    },
    {
      label: 'Min Vol % Loss',
      value: minVolLoss,
      type: 'percent',
      name: 'minVolLoss',
    },
    {
      label: 'Max Vol % Loss',
      value: maxVolLoss,
      type: 'percent',
      name: 'maxVolLoss',
    },
    {
      label: 'Min % Return',
      value: minPerReturn,
      type: 'percent',
      name: 'minPerReturn',
    },
    {
      label: 'Min Sec Ins Count',
      value: minSecInstCount,
      type: 'string',
      name: 'minSecInstCount',
    },
    {
      label: 'Min Sec Insider Count',
      value: minSecInsiderCount,
      type: 'string',
      name: 'minSecInsiderCount',
    },

    {
      label: 'Min SEC 13D/G Count',
      value: minSec13dCount,
      type: 'string',
      name: 'minSec13dCount',
    },
    {
      label: 'Min Price',
      value: minPrice,
      type: 'currency',
      name: 'minPrice',
    },
    {
      label: 'Min Start Price',
      value: minStartPrice,
      type: 'currency',
      name: 'minStartPrice',
    },
    {
      label: 'Max Start Price',
      value: maxStartPrice,
      type: 'currency',
      name: 'maxStartPrice',
    },
    {
      label: 'Min End Price',
      value: minEndPrice,
      type: 'currency',
      name: 'minEndPrice',
    },
    {
      label: 'Max End Price',
      value: maxEndPrice,
      type: 'currency',
      name: 'maxEndPrice',
    },
    {
      label: 'Min Transaction Value',
      value: minInsiderTransactionValue
        ? currencyFormat(
            minInsiderTransactionValue,
            false,
            false,
            2,
            false,
            true,
          )
        : minInsiderTransactionValue,
      type: 'string',
      name: 'minInsiderTransactionValue',
    },
    {
      label: 'Max Transaction Value',
      value: maxInsiderTransactionValue
        ? currencyFormat(
            maxInsiderTransactionValue,
            false,
            false,
            2,
            false,
            true,
          )
        : maxInsiderTransactionValue,
      type: 'string',
      name: 'maxInsiderTransactionValue',
    },
    {
      label: 'Date',
      value: date,
      type: 'date',
      name: 'date',
    },
    {
      label: 'Min % Own',
      value: minOwnership,
      type: 'percent',
      name: 'minOwnership',
    },

    {
      label: 'Max Price',
      value: maxPrice,
      type: 'currency',
      name: 'maxPrice',
    },
    {
      label: 'Min YTD',
      value: minYTD,
      type: 'percent',
      name: 'minYTD',
    },
    {
      label: 'Max YTD',
      value: maxYTD,
      type: 'percent',
      name: 'maxYTD',
    },
    {
      label: 'Min 1yr Growth',
      value: min1Yr,
      type: 'percent',
      name: 'min1Yr',
    },
    {
      label: 'Max 1yr Growth',
      value: max1Yr,
      type: 'percent',
      name: 'max1Yr',
    },

    {
      label: 'Min 5yr Growth',
      value: min5Yr,
      type: 'percent',
      name: 'min5Yr',
    },
    {
      label: 'Min Inst count',
      value: minInstCount,
      type: 'string',
      name: 'minInstCount',
    },
    {
      label: 'Max Inst count',
      value: maxInstCount,
      type: 'string',
      name: 'maxInstCount',
    },
    {
      label: 'Min count',
      value: minCount,
      type: 'string',
      name: 'minCount',
    },
    {
      label: 'Max count',
      value: maxCount,
      type: 'string',
      name: 'maxCount',
    },
    {
      label: 'Min Allocated %',
      value: minAllocatedPerc,
      type: 'string',
      name: 'minAllocatedPerc',
    },
    {
      label: 'Max Allocated %',
      value: maxAllocatedPerc,
      type: 'string',
      name: 'maxAllocatedPerc',
    },
    {
      label: 'Min Inst Shares',
      value: minInstShares
        ? currencyFormat(minInstShares, false, false, 2, false, true)
        : minInstShares,
      type: 'string',
      name: 'minInstShares',
    },
    {
      label: 'Max Inst Shares',
      value: maxInstShares
        ? currencyFormat(maxInstShares, false, false, 2, false, true)
        : maxInstShares,
      type: 'string',
      name: 'maxInstShares',
    },
    {
      label: 'Min Approx. trxn value',
      value: minAproxTxnValue
        ? currencyFormat(minAproxTxnValue, false, false, 2, false, true)
        : minAproxTxnValue,
      type: 'string',
      name: 'minAproxTxnValue',
    },
    {
      label: 'Min Approx. Inv value',
      value: minAproxInvValue
        ? currencyFormat(minAproxInvValue, false, false, 2, false, true)
        : minAproxInvValue,
      type: 'string',
      name: 'minAproxInvValue',
    },
    {
      label: 'Max Approx. trxn value',
      value: maxAproxTxnValue
        ? currencyFormat(maxAproxTxnValue, false, false, 2, false, true)
        : maxAproxTxnValue,
      type: 'string',
      name: 'maxAproxTxnValue',
    },
    {
      label: 'Min Prev Q Inst count',
      value: minPrevQInstCount,
      type: 'string',
      name: 'minPrevQInstCount',
    },
    {
      label: 'Max Prev Q Inst count',
      value: maxPrevQInstCount,
      type: 'string',
      name: 'maxPrevQInstCount',
    },
    {
      label: 'Min -ve Returns Stock Count',
      value: minNegReturnsStockCount,
      type: 'string',
      name: 'minNegReturnsStockCount',
    },
    {
      label: 'Max -ve Returns Stock Count',
      value: maxNegReturnsStockCount,
      type: 'string',
      name: 'maxNegReturnsStockCount',
    },
    {
      label: 'Min Total Count',
      value: minTotalCount,
      type: 'string',
      name: 'minTotalCount',
    },
    {
      label: 'Max Total Count',
      value: maxTotalCount,
      type: 'string',
      name: 'maxTotalCount',
    },
    {
      label: 'Min -ve Returns %',
      value: minNegReturnsPerc,
      type: 'percent',
      name: 'minNegReturnsPerc',
    },
    {
      label: 'Max -ve Returns %',
      value: maxNegReturnsPerc,
      type: 'percent',
      name: 'maxNegReturnsPerc',
    },
    {
      label: 'Min +ve Returns %',
      value: minPosReturnsPerc,
      type: 'percent',
      name: 'minPosReturnsPerc',
    },
    {
      label: 'Max +ve Returns %',
      value: maxPosReturnsPerc,
      type: 'percent',
      name: 'maxPosReturnsPerc',
    },
    {
      label: 'Min +ve Returns Stock Count',
      value: minPosReturnsStockCount,
      type: 'string',
      name: 'minPosReturnsStockCount',
    },
    {
      label: 'Max +ve Returns Stock Count',
      value: maxPosReturnsStockCount,
      type: 'string',
      name: 'maxPosReturnsStockCount',
    },
    {
      label: 'Country',
      value: country,
      type: 'string',
      name: 'country',
    },
    {
      label: 'Min AUM',
      value: minAum
        ? currencyFormat(minAum, false, false, 2, false, true)
        : minAum,
      type: 'string',
      name: 'minAum',
    },
    {
      label: 'Max AUM',
      value: maxAum
        ? currencyFormat(maxAum, false, false, 2, false, true)
        : maxAum,
      type: 'string',
      name: 'maxAum',
    },
    {
      label: 'Min Prev AUM',
      value: minPrevAum
        ? currencyFormat(minPrevAum, false, false, 2, false, true)
        : minPrevAum,
      type: 'string',
      name: 'minPrevAum',
    },
    {
      label: 'Max Prev AUM',
      value: maxPrevAum
        ? currencyFormat(maxPrevAum, false, false, 2, false, true)
        : maxPrevAum,
      type: 'string',
      name: 'maxPrevAum',
    },
    {
      label: 'Hedge Count',
      value: hedgeCount,
      type: 'string',
      name: 'hedgeCount',
    },
    {
      label: 'Min MarketCap',
      value: minMarketCap,
      type: 'string',
      name: 'minMarketCap',
    },
    {
      label: 'Max MarketCap',
      value: maxMarketCap,
      type: 'string',
      name: 'maxMarketCap',
    },
    {
      label: 'Min Prev Q Inst count',
      value: minLastQ2InstCount,
      type: 'string',
      name: 'minLastQ2InstCount',
    },
    {
      label: 'Max Prev Q Inst count',
      value: maxLastQ2InstCount,
      type: 'string',
      name: 'maxLastQ2InstCount',
    },
    {
      label: 'Min Top-10 HF NB/INCR/NC',
      value: minTop10HedgeFundsIn_NC_NB_CNT,
      type: 'string',
      name: 'minTop10HedgeFundsIn_NC_NB_CNT',
    },
    {
      label: 'Max Top-10 HF NB/INCR/NC',
      value: maxTop10HedgeFundsIn_NC_NB_CNT,
      type: 'string',
      name: 'maxTop10HedgeFundsIn_NC_NB_CNT',
    },
    {
      label: 'Min Top-10 Inst NB/INCR/NC',
      value: minTop10InstIn_NC_NB_CNT,
      type: 'string',
      name: 'minTop10InstIn_NC_NB_CNT',
    },
    {
      label: 'Max Top-10 Inst NB/INCR/NC',
      value: maxTop10InstIn_NC_NB_CNT,
      type: 'string',
      name: 'maxTop10InstIn_NC_NB_CNT',
    },
    {
      label: '#Qtrs',
      value: noOfQuarters,
      type: 'string',
      name: 'noOfQuarters',
    },
    {
      label: 'Criterion',
      value: criterion,
      type: 'string',
      name: 'criterion',
    },
    {
      label: 'Status',
      value: multiStatus,
      type: 'multi',
      name: 'multiStatus',
    },
    {
      label: 'Market Cap',
      value: multiMarketCap,
      type: 'multi',
      name: 'multiMarketCap',
    },

    {
      label: 'Min Shares % Change',
      value: minSharesChangePer,
      type: 'percent',
      name: 'minSharesChangePer',
    },

    {
      label: 'Min % Change',
      value: minPerChange,
      type: 'percent',
      name: 'minPerChange',
    },
    {
      label: 'Min % of Allocation',
      value: minPercentage,
      type: 'percent',
      name: 'minPercentage',
    },
    {
      label: 'Max % of Allocation',
      value: maxPercentage,
      type: 'percent',
      name: 'maxPercentage',
    },
    {
      label: 'Min % Count Change',
      value: minCountChange,
      type: 'percent',
      name: 'minCountChange',
    },
    {
      label: 'Max % Count Change',
      value: maxCountChange,
      type: 'percent',
      name: 'maxCountChange',
    },
    {
      label: 'Mutual Fund',
      value: filerName,
      type: 'multi',
      name: 'filerName',
    },
    {
      label: 'Series Name',
      value: seriesName,
      type: 'multi',
      name: 'seriesName',
    },
    {
      label: 'Min Invested Amt',
      value: minInvestedAmt,
      type: 'string',
      name: 'minInvestedAmt',
    },
    {
      label: 'Max Invested Amt',
      value: maxInvestedAmt,
      type: 'string',
      name: 'maxInvestedAmt',
    },
    {
      label: 'Min Invested Amt Returns',
      value: minInvestedAmtReturns,
      type: 'string',
      name: 'minInvestedAmtReturns',
    },
    {
      label: 'Min Invested Returns Perc',
      value: minInvestedReturnsPerc,
      type: 'string',
      name: 'minInvestedReturnsPerc',
    },
    {
      label: 'Min Total PF value',
      value: minTotalPFvalue,
      type: 'string',
      name: 'minTotalPFvalue',
    },
    {
      label: 'Min Total PF Returns Perc',
      value: minTotalPFReturnsPerc,
      type: 'string',
      name: 'minTotalPFReturnsPerc',
    },
    {
      label: 'Max Invested Amt Returns',
      value: maxInvestedAmtReturns,
      type: 'string',
      name: 'maxInvestedAmtReturns',
    },
    {
      label: 'Max Invested Returns Perc',
      value: maxInvestedReturnsPerc,
      type: 'string',
      name: 'maxInvestedReturnsPerc',
    },
    {
      label: 'Max Total PF value',
      value: maxTotalPFvalue,
      type: 'string',
      name: 'maxTotalPFvalue',
    },
    {
      label: 'Max Total PF Returns Perc',
      value: maxTotalPFReturnsPerc,
      type: 'string',
      name: 'maxTotalPFReturnsPerc',
    },

    {
      label: 'Min Rank',
      value: minRank,
      type: 'string',
      name: 'minRank',
    },
    {
      label: 'Max Rank',
      value: maxRank,
      type: 'string',
      name: 'maxRank',
    },
    {
      label: 'Min % Possible Growth',
      value: minPossibleGrowth,
      type: 'percent',
      name: 'minPossibleGrowth',
    },
    {
      label: 'Max % Possible Growth',
      value: maxPossibleGrowth,
      type: 'percent',
      name: 'maxPossibleGrowth',
    },
    {
      label: 'Min % Shares Change',
      value: minSharesChange,
      type: 'percent',
      name: 'minSharesChange',
    },
    {
      label: 'Max % Shares Change',
      value: maxSharesChange,
      type: 'percent',
      name: 'maxSharesChange',
    },
    {
      label: 'Min % Total Shares Change',
      value: minTotalSharesChange,
      type: 'percent',
      name: 'minTotalSharesChange',
    },
    {
      label: 'Max % Total Shares Change',
      value: maxTotalSharesChange,
      type: 'percent',
      name: 'maxTotalSharesChange',
    },
    {
      label: 'Min % Avg Volume Change',
      value: minVolumeChange,
      type: 'percent',
      name: 'minVolumeChange',
    },
    {
      label: 'Max % Avg Volume Change',
      value: maxVolumeChange,
      type: 'percent',
      name: 'maxVolumeChange',
    },
    {
      label: 'Min % Avg Price Change',
      value: minAvgChange,
      type: 'percent',
      name: 'minAvgChange',
    },
    {
      label: 'Max % Avg Price Change',
      value: maxAvgChange,
      type: 'percent',
      name: 'maxAvgChange',
    },
    {
      label: 'Type',
      value: type,
      type: 'string',
      name: 'type',
    },
    {
      label: 'Week',
      value: week,
      type: 'string',
      name: 'week',
    },
    {
      label: 'Min Growth',
      value: minGrowth,
      type: 'percent',
      name: 'minGrowth',
    },
    {
      label: 'Start Date',
      value: startDate,
      type: 'date',
      name: 'startDate',
    },
    {
      label: 'From Date',
      value: fromDate,
      type: 'date',
      name: 'fromDate',
    },
    {
      label: 'End Date',
      value: endDate,
      type: 'date',
      name: 'endDate',
    },
    {
      label: 'Min Vol Diff %',
      value: minVolDiff,
      type: 'percent',
      name: 'minVolDiff',
    },
    {
      label: 'Max Vol Diff %',
      value: maxVolDiff,
      type: 'percent',
      name: 'maxVolDiff',
    },
  ];
  const nonClickableValue = NonClickAbleFilter(reportCode);
  const isVisible = filtersNotToShow(reportCode);
  const isCloseAble = (name) => {
    return !nonClickableValue.includes(name);
  };
  const showFilter = (name) => {
    return !isVisible.includes(name);
  };
  return (
    <>
      FILTERS:
      <Tag
        onClick={handleReset}
        color="red"
        style={{ margin: 5 }}
        className={styles.vClearAllNew}
      >
        CLEAR ALL
      </Tag>
      {symbols &&
        (Array.isArray(symbols) ? (
          symbols.map((symbol) => (
            <Tag
              key={symbol}
              closable
              onClose={() => handleClick('symbols', symbol)}
              color={'success'}
            >
              {symbol}
            </Tag>
          ))
        ) : (
          <Tag
            key={symbols}
            color={'success'}
            closable
            onClose={() => handleClick('symbols', symbols)}
          >
            {symbols}
          </Tag>
        ))}
      {institutions &&
        (Array.isArray(institutions) ? (
          institutions.map((ins) => (
            <Tag
              key={ins}
              closable
              onClose={() => handleClick('institutions', ins)}
              color={'success'}
            >
              {ins}
            </Tag>
          ))
        ) : (
          <Tag
            key={institutions}
            color={'success'}
            closable
            onClose={() => handleClick('institutions', institutions)}
          >
            {institutions}
          </Tag>
        ))}
      {/* eslint-disable-next-line */}
      {data.map((item) => {
        const { name, value, type, label } = item;
        if ((value || value === 0) && showFilter(name)) {
          switch (type) {
            case 'currency':
              return (
                <Tag
                  closable={isCloseAble(name)}
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${currencyFormat(value, false, false)}`}</Tag>
              );
            case 'percent':
              return (
                <Tag
                  closable={isCloseAble(name)}
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${formatNumber2(value)}%`}</Tag>
              );
            case 'bool':
              return (
                <Tag
                  closable={isCloseAble(name)}
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${value ? 'Yes' : 'No'}`}</Tag>
              );
            case 'string':
              return (
                <Tag
                  closable={isCloseAble(name)}
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${value}`}</Tag>
              );
            case 'date':
              return (
                <Tag
                  closable={isCloseAble(name)}
                  onClose={() => handleClick(name, value)}
                  color="success"
                >{`${label} : ${moment(value).format('MMMM DD, YYYY')}`}</Tag>
              );
            case 'multi':
              return Array.isArray(value) ? (
                value.map((item) => (
                  <Tag
                    key={item}
                    closable
                    onClose={() => handleClick(name, item)}
                    color={'success'}
                  >
                    {name === 'multiStatus' && item === 'DECREASED'
                      ? 'REDUCED'
                      : name === 'multiStatus' && item === 'NO_CHANGE'
                      ? 'NO CHANGE'
                      : name === 'multiStatus' && item === 'NEWLY_BOUGHT'
                      ? 'NEWLY BOUGHT'
                      : name === 'multiStatus' && item === 'SOLD_OFF'
                      ? 'SOLD OFF'
                      : name === 'multiSector' && item === ''
                      ? 'ALL'
                      : name === 'excludeMultiSector'
                      ? `Ex:${item}`
                      : item}
                  </Tag>
                ))
              ) : (
                <Tag
                  key={value}
                  color={'success'}
                  closable
                  onClose={() => handleClick(name, value)}
                >
                  {value}
                </Tag>
              );
          }
        }
      })}
    </>
  );
};

export default FilteredValues;
