import React, { useState, useEffect } from 'react';

import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { Dropdown, Form, Input, Button, message as AntMessage } from 'antd';

import { saveSearch } from '../../graphql';
import { red, errorHandler } from '../../utils';

const SaveReport = ({
  searchCriteria,
  reportType,
  reportSubType,
  reportTitle,
}) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportSaved, setReportSaved] = useState(reportTitle ? true : false);
  useEffect(() => {
    if (reportTitle) {
      setReportSaved(true);
    }
  }, [reportTitle]);

  const onFinish = (values) => {
    const { name } = values;
    const payload = {
      name: name,
      search_criteria: JSON.stringify({
        type: reportType,
        subType: reportSubType,
        request: searchCriteria,
      }),
    };

    setIsLoading(true);
    saveSearch(payload)
      .then((res) => {
        const { message } = res;
        if (message === 'Saved successfully') {
          setReportSaved(true);
          AntMessage.success('Report Saved');
          // fetchReportName();
        } else if (message === 'Duplicate Name') {
          setError('Duplicate Name!. Please provide different name');
        } else {
          setError('Error in Saving Search');
        }
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const menu = (
    <Form
      name="save-form"
      onFinish={onFinish}
      labelCol={{ span: 24, padding: 0 }}
      wrapperCol={{ span: 24 }}
      size="small"
      style={{ padding: 20 }}
      onValuesChange={() => setError('')}
      initialValues={{}}
    >
      <Form.Item
        label="Give a Name"
        name="name"
        rules={[{ required: true, message: 'Please enter the Name!' }]}
      >
        <Input placeholder="Name" size="middle" style={{ fontSize: 14 }} />
      </Form.Item>
      {error && <p style={{ color: red }}>{error}</p>}
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{
            height: 38,
            width: 120,
            fontSize: 14,
          }}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <div style={{ width: 150 }}>
      {!reportSaved ? (
        <Dropdown
          overlay={menu}
          visible={showForm}
          placement="bottomRight"
          trigger={['click']}
          overlayStyle={{
            top: 70,
          }}
          onVisibleChange={(e) => setShowForm(e)}
        >
          <p className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <HeartOutlined /> Save Search
          </p>
        </Dropdown>
      ) : (
        <p>
          <HeartFilled /> Saved Search
        </p>
      )}
    </div>
  );
};

export default SaveReport;
