import React, { useState } from 'react';

import {
  Modal,
  Button,
  Form,
  Row,
  Col,
  notification,
  Checkbox,
  Input,
} from 'antd';

import { addSymbolToTsWatchlist } from '../../graphql';
// import { green, red, allStatusOptions } from '../../utils';
import styles from './style.module.scss';

// const { Option } = Select;

interface Props {
  visible: boolean;
  handleCancel: Function;
  data: Array<{}>;
}

const TSWatchlistDelSymbol: React.FC<Props> = ({
  visible,
  handleCancel,
  data,
}) => {
  const [form] = Form.useForm();
  // const [symbolData, setSymbolData] = useState([]);
  // // const [addSymbolLoad, setAddSymbolLoad] = useState([]);
  // const [fetching, setFetching] = useState<boolean>(false);
  // const [error, setError] = useState('');

  const [formFields, setFormFields] = useState(
    (data.data || []).map((item) => ({
      symbol: item.symbol,
      costWhenAdded: '$' + item.priceOnDateAdded,
      price: '$' + item.lastSale,
      hidden: false,
    })),
  );

  const onChange = (name) => {
    let fields = formFields.map((item) => {
      if (item.symbol === name) {
        return {
          ...item,
          hidden: !item.hidden,
        };
      } else return item;
    });

    setFormFields(fields);
  };
  const onFinish = () =>
    Modal.confirm({
      title: 'Delete',
      content: <p>Are you sure you want to delete?</p>,
      onOk: () => handleDelete(),
    });

  const handleDelete = async () => {
    let symbols = [];
    formFields.map((item) => {
      if (item.hidden === true) {
        symbols.push(item.symbol);
      }
    });
    const payload = {
      symbol: symbols,
      type: 'delete',
    };
    form.resetFields();
    handleCancel();
    const addSymbolResponse = await addSymbolToTsWatchlist(payload);
    if (addSymbolResponse.symbolAdded) {
      await notification.success({
        message: 'Symbols Deleted Successfully',
      });
      handleCancel();
      window.location.reload();
      // setAddSymbolLoad(false);
    } else {
      notification.error({
        message: 'Failed to Delete Symbols',
      });
      handleCancel();
    }
  };
  return (
    <Modal
      title={`Delete Symbol`}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      bodyStyle={{ paddingTop: 0 }}
    >
      <Form form={form} name="add-form" onFinish={onFinish} autoComplete="off">
        <Row span={24}>
          <Col
            span={4}
            className={styles.updateFormLabel}
            style={{ padding: 0 }}
          >
            Select
          </Col>
          <Col className={styles.updateFormLabel} span={5}>
            Symbol
          </Col>
          <Col className={styles.updateFormLabel} span={8}>
            Cost When Added
          </Col>
          <Col className={styles.updateFormLabel} span={5}>
            Last Sale
          </Col>
        </Row>
        <Form.List name="portfolio">
          {(fields) => (
            <div style={{ maxHeight: 400, minHeight: 200, overflow: 'auto' }}>
              {formFields.map((field, i) => {
                const { symbol, price, costWhenAdded, hidden } = field;
                return (
                  <Row span={24} className={styles.sortFormScroll} key={symbol}>
                    <Col span={4}>
                      <Form.Item
                        {...field}
                        name={[field.symbol, 'symbol']}
                        //  label={i === 0 ? 'Avg Cost' : null}
                        fieldKey={[field.fieldKey, 'symbol']}
                        // rules={[
                        //   { required: true, message: 'Avg Cost is required' },
                        // ]}
                        initialValue={symbol}
                        style={{ width: '90%' }}
                      >
                        <Checkbox
                          checked={hidden}
                          onChange={() => {
                            onChange(field.symbol);
                          }}
                          // disabled={column.key === 'symbol'}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        {...field}
                        // label={i === 0 ? 'Symbol' : null}
                        name={[field.symbol, 'symbol']}
                        fieldKey={[field.fieldKey, 'symbol']}
                        // rules={[
                        //   { required: true, message: 'Symbol is required' },
                        // ]}
                        initialValue={symbol}
                        style={{ width: '90%' }}
                      >
                        <Input
                          disabled
                          size="middle"
                          style={{ width: '80%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...field}
                        // label={i === 0 ? 'Symbol' : null}
                        name={[field.costWhenAdded, 'costWhenAdded']}
                        fieldKey={[field.costWhenAdded, 'costWhenAdded']}
                        // rules={[
                        //   { required: true, message: 'Symbol is required' },
                        // ]}
                        initialValue={costWhenAdded}
                        style={{ width: '90%' }}
                      >
                        <Input
                          disabled
                          size="middle"
                          style={{ width: '80%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        {...field}
                        // label={i === 0 ? 'Symbol' : null}
                        name={[field.price, 'price']}
                        fieldKey={[field.price, 'price']}
                        // rules={[
                        //   { required: true, message: 'Symbol is required' },
                        // ]}
                        initialValue={price}
                        style={{ width: '90%' }}
                      >
                        <Input
                          disabled
                          size="middle"
                          style={{ width: '80%' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              })}
            </div>
          )}
        </Form.List>
        <Row
          span={24}
          justify="center"
          // className={styles.buttonSectionBoxNew}
        >
          <Form.Item>
            <Button
              disabled={formFields.filter((field) => field.hidden).length === 0}
              type="primary"
              htmlType="submit"
            >
              Delete
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default TSWatchlistDelSymbol;
