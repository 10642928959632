import React from 'react';

import { Tabs, Typography } from 'antd';

import { Category1FAQs, Category3FAQs } from './FAQ.constant';
import styles from './styles.module.scss';
const { TabPane } = Tabs;
const { Paragraph } = Typography;

const FAQ: React.FC = () => {
  return (
    <div className={styles.faqContentBox}>
      <h2 className={styles.faqsHeaderText}>FAQ’s</h2>
      <Tabs tabPosition="left" className={styles.faqsTabsBox}>
        <TabPane tab="Getting Started" key="1">
          <div className={styles.tab1Content}>
            {Category1FAQs.map((faq) => (
              <div key={faq.question}>
                <h4>{faq.question}</h4>
                <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                  {faq.answer}
                  {faq.extra
                    ? faq.extra.map((i) => (
                        <a
                          key={i}
                          href={i}
                          style={{
                            paddingLeft: 5,
                            textDecoration: 'underline',
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {i}
                        </a>
                      ))
                    : ''}
                </Paragraph>
              </div>
            ))}
          </div>
        </TabPane>
        {/* <TabPane tab="Payments & Charges" key="2">
          <div className={styles.tab1Content}>
            {Category2FAQs.map((faq) => (
              <div key={faq.question}>
                <h4>{faq.question}</h4>
                <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                  {faq.answer}
                </Paragraph>
              </div>
            ))}
          </div>
        </TabPane> */}
        <TabPane tab="Reports & Data" key="3">
          <div className={styles.tab1Content}>
            {Category3FAQs.map((faq) => (
              <div key={faq.question}>
                <h4>{faq.question}</h4>
                <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                  {faq.answer}
                </Paragraph>
              </div>
            ))}
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default FAQ;
