import React, { useState, useEffect } from 'react';

import { Form, Select, Row, Col, Button } from 'antd';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { esSearchCompany, esSearchInstitution } from '../../graphql';
import { green } from '../../utils';
import styles from './style.module.scss';

const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  reportType: string;
  reportSubType: string;
}

const SymbolSearch: React.FC<Props> = ({
  history,
  reportType,
  reportSubType,
  prevPage,
}) => {
  const [form] = Form.useForm();
  // const [searchReportType, setSearchReportType] = useState(
  //   symbolSearchOptions[0].value,
  // );
  const [symbolData, setSymbolData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [institutionsData, setInstitutionsData] = useState([]);
  const [loadingCik, setLoadingCik] = useState<boolean>(false);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);

  useEffect(() => {
    if (
      (reportSubType === 'DOW_THEORY_UPTREND' ||
        reportSubType === 'TS_WEEKLY_REPORT' ||
        reportSubType === 'DOW_THEORY_DOWNTREND' ||
        reportSubType === 'DOW_DAILY_PRICE_UPTREND' ||
        reportSubType === 'DOW_DAILY_PRICE_DOWNTREND' ||
        reportSubType === 'DOW_DAILY_PRICE_VOLUME_UPTREND' ||
        reportSubType === 'DOW_DAILY_PRICE_VOLUME_DOWNTREND' ||
        reportSubType === 'ALL_SYMBOLS' ||
        reportSubType === 'C52WKHL' ||
        reportSubType === 'TRENDING_STOCK') &&
      prevPage !== 'searchReport'
    ) {
      const filters = {
        type: reportType.toLowerCase(),
        subtype: reportSubType.toLowerCase(),
      };
      history.push({
        pathname:
          `/search/report/` +
          queryString.stringify(filters, {
            skipNull: true,
          }),
        state: { prevPage: 'search' },
      });
    }
  }, [history, prevPage, reportSubType, reportType]);

  const fetchSymbols = async (value) => {
    setFetching(true);
    const res = await esSearchCompany(value);
    if (res) {
      setSymbolData(res);
    }
    setFetching(false);
  };

  const fetchInstitution = async (value) => {
    setLoadingCik(true);
    const res = await esSearchInstitution(value);
    setInstitutionsData(res);
    setLoadingCik(false);
  };

  const onFinish = (values) => {
    const { symbol } = values;
    const filters = {
      type: reportType.toLowerCase(),
      subtype: reportSubType.toLowerCase(),
    };
    const ciks = [];
    const names = [];
    // eslint-disable-next-line array-callback-return
    (selectedInstitutions || []).map((inst) => {
      const value = inst.split(',');
      ciks.push(value[0]);
      names.push(value[1]);
    });

    switch (reportSubType) {
      case 'INDIVIDUAL_STATISTICAL_REPORT':
      case 'PRICE_HISTORY':
      case 'COMPARE_SYMBOLS':
      case 'MOVING_AVERAGES':
        filters.symbols = symbol;
        break;
      case 'VERIFY_SYMBOLS_VS_INSTITUTIONS':
        filters.symbols = symbol;
        filters.institutions = ciks;
        filters.institutionsName = names;
        break;
      case 'DOW_THEORY_UPTREND':
      case 'TS_WEEKLY_REPORT':
      case 'DOW_THEORY_DOWNTREND':
      case 'DOW_DAILY_PRICE_UPTREND':
      case 'DOW_DAILY_PRICE_DOWNTREND':
      case 'ALL_SYMBOLS':
      case 'C52WHL':
      case 'TOP_TWENTY_FIVE_SYMBOLS':
      case 'TRENDING_STOCK':
        break;
      default:
        break;
    }
    // history.push({
    //   pathname: '/search',
    //   state: { type: reportType, subType: reportSubType },
    // });
    history.push({
      pathname:
        `/search/report/` +
        queryString.stringify(filters, {
          skipNull: true,
        }),

      state: { prevPage: 'search' },
    });
  };

  // const changeSymbolSearchOptionType = (type) => {
  //   setSearchReportType(type);
  // };

  const handleSelect = (value) => {
    const ins = selectedInstitutions;
    ins.push(value.key);
    setSelectedInstitutions(ins);
    setInstitutionsData([]);
  };

  const handleDeSelect = (value) => {
    const ins = selectedInstitutions.filter((i) => i !== value.key);
    setSelectedInstitutions(ins);
  };

  return (
    <Form
      name="symbol-filter-form"
      onFinish={onFinish}
      labelCol={{ span: 24, padding: 0 }}
      wrapperCol={{ span: 24 }}
      form={form}
    >
      <Row span={24}>
        {/* <Col span={24}>
          <Form.Item name="searchType" label="Choose Action">
            <Select
              placeholder="Choose Action"
              onChange={(value) => changeSymbolSearchOptionType(value)}
              size="middle"
            >
              {symbolSearchOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col> */}

        {reportSubType === 'COMPARE_SYMBOLS' && (
          <Col span={24}>
            <Form.Item
              name="symbol"
              label={'Choose Symbols to compare'}
              className={styles.vFormSearhNew}
              // style={{ margin: 0 }}
              // rules={[
              //   {
              //     required: true,
              //     message: `Symbol is required`,
              //   },
              // ]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value) {
                      return Promise.reject('Symbols is required');
                    }
                    if (value && value.length < 2) {
                      return Promise.reject('2 Symbols are required');
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                placeholder="Enter valid symbols"
                mode={'multiple'}
                notFoundContent={
                  fetching ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                onSearch={fetchSymbols}
                showSearch={true}
                allowClear={true}
                showArrow={false}
                filterOption={false}
                onSelect={() => setSymbolData([])}
                size="large"
              >
                {symbolData.map((d) => (
                  <Option key={d.symbol} className={styles.optionStyle}>
                    <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                    {d.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {(reportSubType === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' ||
          reportSubType === 'MOVING_AVERAGES') && (
          <Col span={24}>
            <Form.Item
              name="symbol"
              label={'Enter Symbols'}
              // style={{ margin: 0 }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Symbol is required`,
                },
              ]}
            >
              <Select
                placeholder="Enter valid symbols"
                mode={'multiple'}
                notFoundContent={
                  fetching ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                onSearch={fetchSymbols}
                showSearch={true}
                allowClear={true}
                showArrow={false}
                filterOption={false}
                onSelect={() => setSymbolData([])}
                size="large"
              >
                {symbolData.map((d) => (
                  <Option key={d.symbol} className={styles.optionStyle}>
                    <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                    {d.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {(reportSubType === 'INDIVIDUAL_STATISTICAL_REPORT' ||
          reportSubType === 'PRICE_HISTORY') && (
          <Col span={24}>
            <Form.Item
              name="symbol"
              label={'Enter Symbol'}
              // style={{ margin: 0 }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Symbol is required`,
                },
              ]}
            >
              <Select
                placeholder="Enter valid symbol"
                // mode={
                //   reportSubType !== 'INDIVIDUAL_STATISTICAL_REPORT'
                //     ? 'multiple'
                //     : null
                // }
                notFoundContent={
                  fetching ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                onSearch={fetchSymbols}
                showSearch={true}
                allowClear={true}
                showArrow={false}
                filterOption={false}
                // open={dropdownOpen}
                // onSelect={() => setDropdownOpen(false)}
                size="large"
              >
                {symbolData.map((d) => (
                  <Option key={d.symbol} className={styles.optionStyle}>
                    <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                    {d.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {reportSubType === 'VERIFY_SYMBOLS_VS_INSTITUTIONS' && (
          <Col span={24}>
            <Form.Item
              name="institutions"
              label="Enter Institutions"
              // style={{ margin: 0 }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Institution is required`,
                },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="Enter Here"
                notFoundContent={
                  loadingCik ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                filterOption={false}
                onSearch={fetchInstitution}
                size="large"
                onSelect={(value, key) => handleSelect(key)}
                onDeselect={(value, key) => handleDeSelect(key)}
              >
                {institutionsData.map((institution) => (
                  <Option
                    key={institution.cik + ',' + institution.name}
                    value={institution.name}
                  >
                    {institution.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {/* {reportSubType === 'MOVING_AVERAGES' && (
          <Col span={24}>
            <Form.Item
              name="days"
              label="Days Ago"
              // style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `Days Ago is required`,
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Days Ago"
                filterOption={false}
                onSearch={fetchInstitution}
                size="middle"
              >
                {daysAgo.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )} */}
        {/* {searchReportType === 'DOW_THEORY' && (
          <Col span={24}>
            <Form.Item
              name="weeks"
              label="No of Weeks"
              // style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `No of Weeks is required`,
                },
              ]}
            >
              <InputNumber
                placeholder={'No of Weeks'}
                type="number"
                style={{ width: '100%' }}
                size="middle"
              />
            </Form.Item>
          </Col>
        )} */}
      </Row>
      <Row span={24} justify="start" className={styles.buttonSectionBox}>
        <Form.Item colon={false} className={styles.vBtnadjestment}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};
export default SymbolSearch;
