import React, { useState } from 'react';

import { Row, Col } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';

import { MembershipCard } from '../../components';
import MembershipPageTnC from './MembershipTnC';
import styles from './style.module.scss';

type StripePriceType = {
  interval: string;
  price: string;
};
interface Props {
  onPlanSelect: Function;
  stripePrices: Array<StripePriceType>;
}

const MembershipPage: React.FC<Props> = ({ onPlanSelect, stripePrices }) => {
  const { customer } = useSelector(
    ({ user }) => ({
      customer: get(user, 'profile.stripeCustomer', {}),

      // stripeInfo: get(user, 'stripeInfo', {}),
    }),
    shallowEqual,
  );

  const [showBothCard, setShowBothCard] = useState<boolean>(false);
  //  const subscriptionStatus = get(customer, 'subscription_status', null);

  // const cards1 = [
  //   {
  //     btnText: 'Pay & Continue',
  //     plan: stripePrices.find(
  //       (price) => price.interval === 'YEARLY' && price.is_new,
  //     ),
  //     validity: '/annually',
  //     title: '12-Months',
  //     type: 'yearly',
  //     show: true,
  //     popular: true,
  //     billedText: `*Billed $299.99 Annually`,
  //     // realPrice: '$39.99',
  //     discountedPrice: '$24.99',
  //     percentOff: '17',
  //   },
  //   {
  //     btnText: 'Pay & Continue',
  //     plan: stripePrices.find(
  //       (price) => price.interval === 'MONTHLY' && price.is_new,
  //     ),
  //     validity: '/month',
  //     title: '1-Month',
  //     type: 'monthly',
  //     show: showBothCard,
  //     popular: false,
  //     billedText: `*Billed $29.99 Monthly`,
  //     // realPrice: '$39.99',
  //     discountedPrice: '$29.99',
  //     percentOff: null, //'0',
  //   },
  // ];

  const cards = [
    {
      btnText: 'Pay & Continue',
      plan: stripePrices.find(
        (price) => price.interval === 'YEARLY' && !price.is_new,
      ),
      validity: '/annually',
      title: '12-months',
      type: 'yearly',
      show: true,
      popular: true,
      billedText: `*Billed $99.99 Annually`,
      // realPrice: '$39.99',
      discountedPrice: '$8.33',
      percentOff: '17',
      // coupon: true,
      height: 400,
    },
    {
      btnText: 'Pay & Continue',
      plan: stripePrices.find(
        (price) => price.interval === 'MONTHLY' && !price.is_new,
      ),
      validity: '/month',
      title: '1-Month',
      type: 'monthly',
      show: showBothCard,
      popular: false,
      billedText: `*Billed $9.99 Monthly`,
      // realPrice: '$39.99',
      discountedPrice: '$9.99',
      // percentOff: null, //'0',
      coupon: false,
      height: 400,
    },
  ];
  const isMonthly = customer.interval === 'MONTHLY';
  console.log(isMonthly);
  const displayCards = cards;

  return (
    <div className={styles.MembershipCardSection}>
      <h5 className={styles.choosePlanText}>Choose a plan that suits you</h5>

      <Row justify="center" className={styles.cardRow}>
        {displayCards
          .filter((card) => card.show)
          .map((card) => (
            <Col key={card.type} className={styles.cardCol}>
              <MembershipCard
                // membership={membership}
                // setMembership={(val) => setMembership(val)}
                //validity={card.validity}
                btnText={card.btnText}
                title={card.title}
                type={card.type}
                onPlanSelect={onPlanSelect}
                popular={card.popular}
                billedText={card.billedText}
                discountedPrice={card.discountedPrice}
                realPrice={card.realPrice}
                percentOff={card.percentOff}
              />
            </Col>
          ))}
      </Row>

      {!showBothCard && (
        <p className={styles.insteadText}>
          Instead go for{' '}
          <span onClick={() => setShowBothCard(true)}> Monthly Plan </span>
        </p>
      )}
      <MembershipPageTnC />
    </div>
  );
};
export default MembershipPage;
