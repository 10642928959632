import React, { useEffect, useState } from 'react';

import {
  // LoadingOutlined,
  // PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Input,
  Form,
  Radio,
  Button,
  Select,
  Row,
  Col,
  InputNumber,
  message,
  Upload,
  Image,
} from 'antd';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import {
  getUserPersonalInfo,
  savePersonalInfo,
  getSignedUrl,
} from '../../graphql';
import { setPersonalData } from '../../redux/reducers/user';
import {
  green,
  Professions,
  countryCodes,
  errorHandler,
  birthYears,
} from '../../utils';
import styles from './style.module.scss';

const { Option } = Select;

const PersonalProfile: React.FC<Props> = () => {
  const { personalData } = useSelector(
    ({ user }) => ({
      personalData: get(user, 'personalData', {}),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [imageObj, setImageObj] = useState({
    url: get(personalData, 'additionalInfo.image_url', ''),
    time: +new Date(),
  });
  const [savingData, setSavingData] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    const apis = [getUserPersonalInfo()];
    Promise.all(apis)
      .then((res) => {
        const {
          id = '',
          first_name = '',
          last_name = '',
          email = '',
          additionalInfo = {},
          userBillingAddress = {},
        } = res[0];
        if (res) {
          const data = {
            userBillingAddress: userBillingAddress ? userBillingAddress : {},
            additionalInfo: additionalInfo ? additionalInfo : {},
            id: id,
            first_name: first_name,
            last_name: last_name,
            email: email,
            date: +new Date(),
          };
          setImageObj({
            url: get(additionalInfo, 'image_url', ''),
            time: +new Date(),
          });
          dispatch(setPersonalData(data));
        } else {
          //setPersonalInfo({});
        }
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  const onFinish = (values) => {
    const {
      phone,
      country,
      martialStatus,
      qualification,
      race,
      profession,
      birthYear,
      gender,
      address,
      city,
      state,
      zip,
    } = values;
    const userInfo = {
      mobile: phone,
      id: get(personalData, 'additionalInfo.id', null),
      gender: gender ? gender : null,
      marital_status: martialStatus ? martialStatus : null,
      country: country,
      profession: profession,
      birth_year: birthYear,
      qualification: qualification,
      race: race,
      image_url: get(imageObj, 'url', ''),
    };
    const addressInfo = {
      id: get(personalData, 'additionalInfo.id', null),
      address: address,
      city: city,
      state: state,
      zip: zip,
      country: country,
    };
    const apis = [savePersonalInfo(userInfo, addressInfo)];
    setSavingData(true);
    Promise.all(apis)
      .then((res) => {
        const { additionalInfo = {}, userBillingAddress = {} } = res[0];
        const data = {
          userBillingAddress: userBillingAddress ? userBillingAddress : {},
          additionalInfo: additionalInfo ? additionalInfo : {},
          first_name: get(personalData, 'first_name', ''),
          last_name: get(personalData, 'last_name', ''),
          email: get(personalData, 'email', ''),
          date: +new Date(),
        };
        dispatch(setPersonalData(data));
        message.success('Personal Info Updated');
      })
      .catch(errorHandler)
      .finally(() => {
        setSavingData(false);
      });
  };
  const getInitialValues = () => {
    return {
      phone: get(personalData, 'additionalInfo.mobile', ''),
      martialStatus: get(personalData, 'additionalInfo.marital_status', ''),
      qualification: get(personalData, 'additionalInfo.qualification', ''),
      race: get(personalData, 'additionalInfo.race', ''),
      profession: get(personalData, 'additionalInfo.profession', ''),
      birthYear: get(personalData, 'additionalInfo.birth_year', ''),
      gender: get(personalData, 'additionalInfo.gender', ''),
      name: `${get(personalData, 'first_name', '')} ${get(
        personalData,
        'last_name',
        '',
      )}`,
      email: get(personalData, 'email', ''),
      address: get(personalData, 'userBillingAddress.address', ''),
      city: get(personalData, 'userBillingAddress.city', ''),
      state: get(personalData, 'userBillingAddress.state', ''),
      zip: get(personalData, 'userBillingAddress.zip', ''),
      country: get(personalData, 'userBillingAddress.country', ''),
    };
  };
  const onDrop = async (acceptedFiles) => {
    setImageLoading(true);
    const signedUrls = await getSignedUrl({
      fileName: acceptedFiles.file.name,
    });

    const response = await fetch(signedUrls.url, {
      method: 'PUT',
      body: acceptedFiles.file,
    });
    if (response.status === 200) {
      setImageObj({
        url: signedUrls.accessUrl,
        time: +new Date(),
      });
    }
    setImageLoading(false);
  };
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setImageLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      console.log(info.file.originFileObj);
    }
  };
  // const uploadButton = (
  //   <div>
  //     {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
  //     <div style={{ marginTop: 8 }}>
  //       {imageLoading ? 'Uploading' : 'Upload'}
  //     </div>
  //   </div>
  // );
  return (
    <div className={styles.clientContainer}>
      <div className={styles.searchPaneTitle}>
        <h2>Personal info</h2>
        <p>This will help us to give you better recommendations</p>
      </div>
      <div className={styles.searchPaneForm}>
        {isLoading && isEmpty(personalData) ? (
          <div className={styles.loaderStyle}>
            <BounceLoader color={green} loading={isLoading} size={150} />
          </div>
        ) : (
          <Form
            onFinish={onFinish}
            layout="vertical"
            name="personal-info-form"
            initialValues={getInitialValues()}
            id="vFormId"
          >
            <Row className={styles.vPersonalInfo} gutter={12}>
              <Col span={12}>
                <Form.Item label="Full Name" name="name">
                  <Input disabled style={{ width: 270 }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Email" name="email">
                  <Input disabled style={{ width: 270 }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    // { required: true, message: 'Mobile Number is required' },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || value.toString().length === 10) {
                          return Promise.resolve();
                        }

                        return Promise.reject('Incorrect Mobile Number');
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    // type="number"
                    formatter={(value: string) =>
                      `${value}`.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                    }
                    parser={(value: string) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder="Mobile Number"
                    style={{ width: 270 }}
                    // max={9999999999}
                    size="middle"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Profession"
                  name="profession"
                  // rules={[
                  //   { required: true, message: 'Profession is required' },
                  // ]}
                >
                  {/* <Input
                    size="middle"
                    placeholder="Profession"
                    style={{ width: 270 }}
                  /> */}
                  <Select
                    placeholder="Profession"
                    showSearch
                    style={{ width: 270 }}
                    size="middle"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {Professions.map((profession) => (
                      <Option key={profession} value={profession}>
                        {profession}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Address"
                  name="address"
                  // rules={[{ required: true, message: 'Address is required' }]}
                >
                  <Input
                    size="middle"
                    placeholder="Address"
                    style={{ width: 270 }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="City"
                  name="city"
                  // rules={[{ required: true, message: 'City is required' }]}
                >
                  <Input
                    size="middle"
                    placeholder="City"
                    style={{ width: 270 }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="State"
                  name="state"
                  // rules={[{ required: true, message: 'State is required' }]}
                >
                  <Input
                    size="middle"
                    placeholder="State"
                    style={{ width: 270 }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Zip"
                  name="zip"
                  // rules={[{ required: true, message: 'Zip is required' }]}
                >
                  <Input
                    size="middle"
                    placeholder="Zip"
                    style={{ width: 270 }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Country"
                  name="country"
                  // rules={[{ required: true, message: 'Country is required' }]}
                >
                  <Select
                    placeholder="Select Country"
                    showSearch
                    style={{ width: 270 }}
                    size="middle"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countryCodes.map((country) => (
                      <Option key={country.code} value={country.name}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Marital Status"
                  name="martialStatus"
                  // rules={[
                  //   { required: true, message: 'Marital Status is required' },
                  // ]}
                >
                  <Select
                    size="middle"
                    placeholder="Marital Status"
                    style={{ width: 270 }}
                  >
                    <Option value="MARRIED">MARRIED</Option>
                    <Option value="UNMARRIED">UNMARRIED</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Education Qualification"
                  name="qualification"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Education Qualification is required',
                  //   },
                  // ]}
                >
                  <Select
                    size="middle"
                    placeholder="Education Qualification"
                    style={{ width: 270 }}
                  >
                    <Option value="UG">UG</Option>
                    <Option value="PG">PG</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Race"
                  name="race"
                  // rules={[{ required: true, message: 'Race is required' }]}
                >
                  <Select
                    size="middle"
                    placeholder="Race"
                    style={{ width: 270 }}
                  >
                    <Option value="Black">Black</Option>
                    <Option value="White">White</Option>
                    <Option value="Brown">Brown</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Birth Year"
                  name="birthYear"
                  // rules={[
                  //   { required: true, message: 'Birth Year is required' },
                  // ]}
                >
                  <Select
                    size="middle"
                    placeholder="Birth Year"
                    style={{ width: 270 }}
                  >
                    {birthYears().map((prof) => (
                      <Option key={prof} value={prof}>
                        {prof}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  // rules={[{ required: true, message: 'Gender is required' }]}
                >
                  <Radio.Group>
                    <Radio value="M">Male</Radio>
                    <Radio value="F">Female</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item className={styles.personalBtn}>
              <Button type="primary" loading={savingData} htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        )}
        <div className={styles.profilePicUpload}>
          {imageObj.url && (
            <div className={styles.profilePic} key={imageObj.time}>
              <Image width={200} src={imageObj.url} />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Upload
              name="avatar"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={(a) => onDrop(a)}
              onChange={handleChange}
            >
              <Button loading={imageLoading} icon={<UploadOutlined />}>
                {!imageLoading ? 'Click to Upload' : 'Uploading'}
              </Button>
            </Upload>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalProfile;
