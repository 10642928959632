import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { get } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import PaymentProfile from './PaymentProfile';
// import { get } from 'http';

interface Props extends RouteComponentProps<any> {}
const PaymentProfileContainer: React.FC<Props> = ({ history }) => {
  const { customer, stripeInfo } = useSelector(
    ({ user }) => ({
      customer: get(user, 'profile.stripeCustomer', {}),
      stripeInfo: get(user, 'stripeInfo', {}),
    }),
    shallowEqual,
  );
  const { key, prices = [] } = stripeInfo;
  const stripePromise = loadStripe(key);
  return (
    <Elements stripe={stripePromise}>
      <PaymentProfile
        customer={customer}
        history={history}
        stripePrices={prices}
      />
    </Elements>
  );
};

export default PaymentProfileContainer;
