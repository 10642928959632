import React, { useRef } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';

import styles from '../searchReport/style.module.scss';
// import { get } from 'lodash';
// import SaveReport from '../searchReport/SaveReport';

// import { background7 } from '../../images';
// import { NavLink } from 'react-router-dom';
// import { isStudentUrl } from '../../utils';
// import styles from '../home/style2.module.scss';

const WhoWeAre: React.FC<Props> = ({ history }) => {
  const vidRef = useRef();
  window.scrollTo(0, 0);
  const location = useLocation();
  const subTab = location.state?.name?.properties?.name;
  return (
    <>
      <div
        style={{ marginTop: 100, marginLeft: 50 }}
        className={styles.vGoBackSaveBar}
      >
        <p onClick={() => history.goBack()} className={styles.vGoBackBtnBlock}>
          <span className={styles.vGoBackBtn}>
            <ArrowLeftOutlined /> Go Back
          </span>
        </p>
      </div>
      <div style={{ minHeight: '500px' }}>
        <p
          className={styles.Heading}
          style={{
            top: '20px',
            display: 'flex',
            justifyContent: 'center',
            color: '#1abd50',
            // marginTop: '100px',
            fontFamily: `'Jost', sans-serif`,
            fontSize: '4rem',
            lineHeight: '1.1',
            fontWeight: 'bold',
          }}
        >
          {subTab}
        </p>
        {subTab === 'Overview' ? (
          <div style={{ color: 'var(--whitecolor)' }}>
            <h3 style={{ textAlign: 'center', color: 'var(--whitecolor)' }}>
              What is ThinkSabio ?
            </h3>
            <div className={styles.overviewParagraph}>
              ThinkSabio is an AI-based easy-to-use stock analytical tool
              developed using Data Science AI & ML to provide data intelligence
              in the Stock Market. We have access to data on thousands of
              stocks, institutional investors, and Insiders as well as 40
              methods of forecasting future stock value. We summarize this data
              into simple, actionable insights that people of any income or
              educational level can understand. ThinkSabio gives people the
              tools to make informed stock market decisions that can help them
              get a high return on investment and build a bright financial
              future.{' '}
            </div>
            <h3 style={{ textAlign: 'center', color: 'var(--whitecolor)' }}>
              Why is ThinkSabio’s information valuable to subscribers?
            </h3>
            <div className={styles.overviewParagraph}>
              Our information from institutional investors gives subscribers the
              highest quality information possible. These institutions have
              experts in economics, data analysis, mathematics, and artificial
              intelligence (among many other fields). By using ThinkSabio, your
              employees can follow the investing models and achieve the success
              of the largest and most successful institutional investors.
            </div>
            <div className={styles.overviewParagraph}>
              ThinkSabio makes this data easily accessible to subscribers, helps
              them make informed stock market decisions that can help them get a
              high return on investment, and gives them the tools to build a
              bright financial future. We’ve helped thousands of subscribers
              invest wisely, and we would be happy to do the same for you.
            </div>
          </div>
        ) : subTab === 'Our Features' ? (
          <div style={{ color: 'var(--whitecolor)' }}>
            <h3 className={styles.ourfeaturesh3}>
              Our platform gives you detailed insights on the stock market so
              you can learn from the most successful investors and build a
              valuable portfolio.{' '}
            </h3>
            <ul>
              <li
                className={styles.ourfeaturestext}
                style={{
                  marginTop: 50,
                }}
              >
                Use the investing tactics of one of America’s most successful
                stock traders. The Mark Minervini Report helps you pick stocks
                based on the criteria of Mark Minervini, a 30-year veteran of
                Wall Street who consistently generates some of the highest
                returns in the industry.
              </li>
              <li className={styles.ourfeaturestext}>
                Analyze the portfolio holdings of esteemed hedge funds like
                Citadel Advisors and Renaissance Technologies who manage
                hundreds of millions of dollars in financial assets. (Find these
                insights through our IIR Report)
              </li>
              <li className={styles.ourfeaturestext}>
                See which stocks are bought and sold the most among major
                institutional investors with our ISR Report. This helps you see
                how large companies and investors feel about stocks and if they
                will achieve long-term growth.
              </li>
            </ul>
            <div
              className={styles.ourfeaturestext}
              style={{
                marginTop: 20,
              }}
            >
              By subscribing to ThinkSabio, you learn from the most successful
              investors, copy their success, and gain the roadmap to financial
              prosperity. We do this while offering transparency, innovation,
              and unwavering dedication to helping you achieve your financial
              objectives.
            </div>
            <div
              className={styles.ourfeaturestext}
              style={{
                marginBottom: 50,
                marginTop: 20,
              }}
            >
              Subscribe now so you can explore and learn valuable investing
              knowledge.
            </div>
          </div>
        ) : subTab === 'Success Stories' ? (
          <div
            className={styles.successStories}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '80rem',
              paddingLeft: '50px',
              paddingRight: '50px',
              margin: 'auto',
              marginTop: '10px',
              color: 'var(--whitecolor)',
            }}
          >
            <div
              className={styles.successVideo}
              style={{
                margin: 'auto',
                width: '600px',
                // marginLeft: '30px',
                color: 'var(--whitecolor)',
              }}
            >
              <video
                style={{ margin: 'auto' }}
                width="100%"
                height="100%"
                src="https://thinksabio-asset.s3.amazonaws.com/ts_testimonial.mp4"
                controls={true}
                disablePictureInPicture
                controlsList="nodownload"
                playsInline
                id="myVideo"
                poster={
                  'https://thinksabio-asset.s3.amazonaws.com/Thinksabio_poster.png'
                }
                ref={vidRef}
              >
                <source
                  src="https://thinksabio-asset.s3.amazonaws.com/ts_testimonial.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div>
              <h3
                className={styles.successh3}
                style={{
                  color: 'var(--whitecolor)',
                  fontWeight: 'bold',
                  marginTop: '20px',
                  marginBottom: '30px',
                }}
              >
                How we’ve helped our subscribers invest wisely and achieve their
                investing goals
              </h3>
              <li
                className={styles.successtext}
                style={{ fontSize: '20px', marginBottom: '20px' }}
              >
                <span style={{ fontWeight: 'bold' }}>Bala Krishna:</span> I had
                no knowledge of the stock market before I started using
                ThinkSabio. I started my small trading account in July 2020 with
                $6,000 and have since gained 250%.
              </li>
              <li
                className={styles.successtext}
                style={{ fontSize: '20px', marginBottom: '20px' }}
              >
                {' '}
                <span style={{ fontWeight: 'bold' }}>Surendra:</span> Using
                ThinkSabio helped me achieve a 30% profit from my investments,
                and the team’s consistent support has been a huge help in my
                continued investing efforts.
              </li>
              <li style={{ fontSize: '20px', marginBottom: '20px' }}>
                {' '}
                <span style={{ fontWeight: 'bold' }}>Srinivas:</span> After
                joining ThinkSabio, my portfolio value grew ten times greater.
              </li>
              <li
                className={styles.successtext}
                style={{ fontSize: '20px', marginBottom: '20px' }}
              >
                {' '}
                <span style={{ fontWeight: 'bold' }}>Kaylan Boddapati:</span> I
                have been in the market or trading for almost 8 years. I joined
                ThinkSabio one year ago, and I can say with absolute certainty
                that I have made more in the last year working with ThinkSabio
                than in all my previous years investing.
              </li>
              <li
                className={styles.successtext}
                style={{ fontSize: '20px', marginBottom: '20px' }}
              >
                {' '}
                <span style={{ fontWeight: 'bold' }}>Abishek:</span> I want to
                thank the customer support team at ThinkSabio. Admins are
                available 24/7, and whenever we request any data, they provide
                it instantly.
              </li>
              <li
                className={styles.successtext}
                style={{ fontSize: '20px', marginBottom: '20px' }}
              >
                <span style={{ fontWeight: 'bold' }}>Venu Gopal Reddy:</span>{' '}
                After one year of working with ThinkSabio, my portfolio recorded
                its highest returns ever. I want to thank ThinkSabio and its
                amazing platform for helping people like me achieve financial
                liberty.
              </li>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default WhoWeAre;
