import React, { useState } from 'react';

import { InputNumber, Button, Select, Form, notification, Modal } from 'antd';
import { get } from 'lodash';

import { BuyOrSellStock } from '../../graphql';
import {
  errorHandler,
  currencyFormat,
  getUpto4decimalValue,
  red,
} from '../../utils';
import styles from './style.module.scss';

const { Option } = Select;

interface Props {
  investIn: string;
  setInvestIn: Function;
  type: string;
  stockDataFromPortfolio: Object;
  wallet: Object;
  symbol: string;
  setFetchPortfolioData: Function;
  currentPrice: number;
  portfolioType: string;
  loading: boolean;
}

interface PayloadType {
  price: number;
  quantity: number;
  symbol: string;
  type: string;
  portfolioType: string;
}

const StockPageSidebarVirtual: React.FC<Props> = ({
  investIn,
  setInvestIn,
  type,
  stockDataFromPortfolio,
  wallet,
  symbol,
  setFetchPortfolioData,
  currentPrice,
  loading,
  history,
}) => {
  const [form] = Form.useForm();
  const [shares, setShares] = useState(0);
  const [amount, setAmount] = useState(0);
  const [dollarError, setDollarError] = useState('');
  const [sharesError, setSharesError] = useState('');
  const availableShare = get(stockDataFromPortfolio, 'no_of_shares', 0);
  const onInputChange = (value, name) => {
    var regExp = /^[0-9]*$/;
    let valueStr1 = value?.toString();
    var valueStr = valueStr1.replaceAll(',', '');
    valueStr = valueStr.replaceAll('.', '');
    valueStr = valueStr?.substr(name === 'dollars' ? 2 : 0, valueStr.length);
    let match = valueStr.match(regExp);
    if (!match && name === 'dollars') {
      setDollarError('special characters and alphabets not allowed');
      return;
    } else if (!match && name === 'shares') {
      setSharesError('special characters and alphabets not allowed');
      return;
    } else {
      setDollarError('');
      setSharesError('');
    }
  };
  const onFinish = (values: { shares: number; amount: number }) => {
    if (sharesError || dollarError) {
      return;
    }
    const { shares, amount } = values;
    let payload = {};
    if (investIn === 'share') {
      payload = {
        price: currentPrice,
        quantity: shares,
        symbol: symbol,
        type: type === 'buy' ? 'A' : 'D',
        portfolioType: 'virtual',
      };
    }
    if (investIn === 'dollar') {
      payload = {
        price: currentPrice,
        quantity: getUpto4decimalValue(amount / currentPrice),
        symbol: symbol,
        type: type === 'buy' ? 'A' : 'D',
        portfolioType: 'virtual',
      };
    }
    handleReviewOrder(payload);
    // apis.push(BuyOrSellStock(payload));
    // setLoading(true);
    // Promise.all(apis)
    //   .then((res) => {
    //     form.resetFields();
    //     notification['success']({
    //       message: 'Portfolio Updated successfully',
    //     });
    //   })
    //   .catch(errorHandler)
    //   .finally(() => {
    //     setFetchPortfolioData();
    //     setLoading(false);
    //   });
  };

  const sellAllStocks = (modalVisible: boolean) => {
    Modal.confirm({
      title: 'Sell Stocks',
      width: '700px',
      visible: modalVisible,
      content: (
        <>
          <p style={{ fontSize: '1.2rem' }}>
            Are you sure you want Sell All Stocks For {symbol}?
          </p>
        </>
      ),
      onOk: sellAll,
      okText: 'Confirm',
    });
  };

  const sellAll = () => {
    let payload = {};
    payload = {
      price: currentPrice,
      quantity: availableShare,
      symbol: symbol,
      type: type === 'buy' ? 'A' : 'D',
      portfolioType: 'virtual',
    };
    updatePortfolio(payload);
    Modal.destroyAll();
  };

  const updatePortfolio = (payload: PayloadType) =>
    BuyOrSellStock(payload)
      .then((res) => {
        if (res.status === 'SUCCESS') {
          notification['success']({
            message: 'Portfolio Updated successfully',
          });
          history.push(`/portfolio/Portfolio/virtual`);
        } else {
          notification['error']({
            message: 'Portfolio Updated Failed',
          });
        }
      })
      .catch(errorHandler);

  const handleReviewOrder = (payload: PayloadType) =>
    Modal.confirm({
      title: 'Review Order',
      content: `Are you sure you want to ${
        type === 'buy' ? 'Buy' : 'Sell'
      } ${symbol} ?`,
      onOk: () => updatePortfolio(payload),
    });

  const AvailableFund = get(wallet, 'remainingBalance', 0);
  return (
    <>
      <div className={styles.vInvestBlockInfoStocks}>
        <div>Invest In :</div>
        <div>
          <Select
            defaultValue="share"
            style={{ width: 120 }}
            onChange={(val) => setInvestIn(val)}
          >
            <Option value="share">Shares</Option>
            <Option value="dollar">Dollars</Option>
          </Select>
        </div>
      </div>
      <Form
        onFinish={onFinish}
        layout="vertical"
        name="stock-form"
        form={form}
        className={styles.vVirtualFormBlock}
      >
        {investIn === 'share' && (
          <>
            <div className={styles.vSharesBlockInfoStocks}>
              <div>Shares :</div>
              <div>
                <Form.Item
                  name="shares"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value) {
                          return Promise.reject('Shares is required');
                        } else if (type === 'sell') {
                          if (value > availableShare) {
                            return Promise.reject(
                              `Max available Shares is ${availableShare}`,
                            );
                          } else {
                            return Promise.resolve();
                          }
                        } else if (type === 'buy') {
                          if (value * currentPrice > AvailableFund) {
                            return Promise.reject(
                              `Max Fund available is ${currencyFormat(
                                AvailableFund,
                                false,
                                false,
                              )}`,
                            );
                          } else {
                            return Promise.resolve();
                          }
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    placeholder="0"
                    min={0}
                    onChange={(val) => setShares(val)}
                    onInput={(value) => onInputChange(value, 'shares')}
                    // type="number"
                    // placeholder="No of Shares"
                    style={{ width: '100%' }}
                    formatter={(n) => {
                      if (n?.toString().indexOf('.') > -1) {
                        const [p1, p2] = n?.toString().split('.');
                        let a = `${p1}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        if (p2) {
                          const b =
                            p2.length > 2
                              ? p2.toString().substring(0, p2.length)
                              : p2.toString();
                          a = a + '.' + b;
                        } else {
                          a = a + '.';
                        }
                        return a;
                      } else {
                        return `${n}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                      }
                    }}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                {sharesError && (
                  <p style={{ color: red, fontSize: 10 }}>{sharesError}</p>
                )}
              </div>
            </div>
            <div className={styles.vMarketPriceBlock}>
              <div className={styles.vMarketTitle}>Market Price</div>
              <div>{`${currencyFormat(currentPrice, false, false)}`}</div>
            </div>
            <Form.Item>
              <div className={styles.vEstimationBLock}>
                <div>{`Estimated ${type === 'buy' ? 'Cost' : 'Credit'}`}</div>
                <div>{`${currencyFormat(
                  shares * currentPrice,
                  false,
                  false,
                )}`}</div>
              </div>
            </Form.Item>
          </>
        )}
        {investIn === 'dollar' && (
          <>
            <div className={styles.vSharesBlockInfoStocks}>
              <div>Amount :</div>
              <div>
                <Form.Item
                  name="amount"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value) {
                          return Promise.reject('Amount is required');
                        } else if (type === 'sell') {
                          if (value / currentPrice > availableShare) {
                            return Promise.reject(
                              `Max available Shares is ${availableShare}`,
                            );
                          } else {
                            return Promise.resolve();
                          }
                        } else if (type === 'buy') {
                          if (value > AvailableFund) {
                            return Promise.reject(
                              `Virtual Portfolio Max Fund available is ${currencyFormat(
                                AvailableFund,
                              )}`,
                            );
                          } else {
                            return Promise.resolve();
                          }
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    placeholder="$0.00"
                    min={0}
                    onChange={(val) => setAmount(val)}
                    onInput={(value) => onInputChange(value, 'dollars')}
                    // type="number"
                    // placeholder="No of Shares"
                    style={{ width: '100%' }}
                    formatter={(n) => {
                      if (n?.toString().indexOf('.') > -1) {
                        const [p1, p2] = n?.toString().split('.');
                        let a = `$ ${p1}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        if (p2) {
                          const b =
                            p2.length > 2
                              ? p2.toString().substring(0, p2.length)
                              : p2.toString();
                          a = a + '.' + b;
                        } else {
                          a = a + '.';
                        }
                        return a;
                      } else {
                        return `$ ${n}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                      }
                    }}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                {dollarError && (
                  <p style={{ color: red, fontSize: 10 }}>{dollarError}</p>
                )}
              </div>
            </div>
            <div className={styles.vMarketPriceBlock}>
              <div className={styles.vMarketTitle}>Est Quantity :</div>
              <div>{getUpto4decimalValue(amount / currentPrice)}</div>
            </div>
          </>
        )}
        <div className={styles.vOrderSharebtn}>
          <Form.Item>
            <Button
              disabled={currentPrice === 0}
              type="primary"
              htmlType="submit"
            >
              Review Order
            </Button>
          </Form.Item>
        </div>
      </Form>
      <div className={styles.vShareAvalibleNew}>
        {type === 'sell' && availableShare > 0 && !loading && (
          <>
            <Button
              style={{
                width: '150px',
                height: '50px',
                fontSize: '1.2rem',
                marginBottom: '20px',
              }}
              onClick={() => sellAllStocks(true)}
              type="primary"
              htmlType={'submit'}
            >
              Sell All
            </Button>
            <p>
              <span>{availableShare}</span> Shares Available
            </p>
          </>
        )}
        {type === 'buy' && !loading && (
          <p>
            Fund Available:{' '}
            <span>{currencyFormat(AvailableFund, false, false)}</span>
          </p>
        )}
      </div>
    </>
  );
};

export default StockPageSidebarVirtual;
