import { API } from 'aws-amplify';

import { createAlert, updateAlert, deleteAlert } from './mutations';
import { getUserAlerts } from './queries';

export const createTSAlert = async (payload: Object) =>
  API.graphql({
    variables: { request: payload },
    query: createAlert,
  }).then(({ data }) => data.createAlert);

export const updateMyAlerts = async (payload: Object) =>
  API.graphql({
    variables: { request: payload },
    query: updateAlert,
  }).then(({ data }) => data.updateAlert);

export const deleteMyAlert = async (payload: Object) =>
  API.graphql({
    variables: { request: payload },
    query: deleteAlert,
  }).then(({ data }) => data.deleteAlert);

export const getMyAlerts = async () =>
  API.graphql({
    // variables: { request: payload },
    query: getUserAlerts,
  }).then(({ data }) => data.getUserAlerts);
