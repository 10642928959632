import React from 'react';

import { CardElement } from '@stripe/react-stripe-js';

import './CardSectionStyles.css';
import { useTheme } from '../../ThemeContext';
import { black1, white } from '../../utils';

const CardElementInput: React.FC<Props> = () => {
  const { theme } = useTheme();
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: theme === 'dark' ? white : black1,
        fontFamily: '"Oxygen", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
        border: '1px solid #d9d9d9',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  return <CardElement options={CARD_ELEMENT_OPTIONS} />;
};

export default CardElementInput;
