import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, Row } from 'antd';
import { round } from 'lodash';

import styles from '../refer/styles.module.scss';

interface Props {
  record: Object;
  alerts: Object;
  tabNum: string;
}

const PercentCalc: React.FC<Props> = () => {
  useEffect(() => {}, []);
  const [form] = Form.useForm();
  const [perc, setPerc] = useState(null);
  const onFinish = (values) => {
    const { from, to } = values;
    console.log(((to - from) * 100) / from);
    const percentage = ((to - from) * 100) / from;
    setPerc(round(percentage, 4));
  };
  const reset = () => {
    form.resetFields();
    setPerc(null);
  };
  const onChange = () => {
    setPerc(null);
  };

  return (
    <>
      <div>
        <div style={{ marginLeft: '150px', marginTop: '20px' }}>
          <div className={styles.loaderStyle}>
            <span
              style={{
                color: 'var(--whitecolor)',
                fontSize: '20px',
                marginLeft: '150px',
                fontWeight: 'bold',
              }}
            >
              What is the Percentage Increase/Decrease
            </span>
            <Form
              layout="vertical"
              className={styles.referForm}
              name="refer-form"
              form={form}
              onFinish={onFinish}
              onChange={onChange}
            >
              <Row
                span={12}
                className="gutter-row"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <Col span={10} style={{ marginRight: '10px' }}>
                    <Form.Item
                      name="from"
                      label="From"
                      style={{ marginBottom: 5 }}
                      rules={[
                        {
                          required: true,
                          message: `From is required`,
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: '80%',
                        }}
                        placeholder="From"
                        type={'number'}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10} style={{ marginRight: '70px' }}>
                    <Form.Item
                      name="to"
                      label="To"
                      style={{ marginBottom: 5 }}
                      rules={[
                        {
                          required: true,
                          message: `To is required`,
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: '80%',
                        }}
                        placeholder="To"
                        type={'number'}
                      />
                    </Form.Item>
                  </Col>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '210px',
                  }}
                >
                  <div className={styles.btnSectionBox}>
                    <Button
                      type="primary"
                      style={{ width: '120px', marginTop: '10px' }}
                      htmlType="submit"
                    >
                      Calculate
                    </Button>
                  </div>
                  <div className={styles.btnSectionBox} onClick={reset}>
                    <Button
                      type="primary"
                      style={{
                        width: '120px',
                        marginTop: '10px',
                        marginBottom: '20px',
                        marginLeft: '20px',
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
                {/* </Form.Item> */}
                {/*{error && <span style={{ color: red }}>{error}</span>}*/}
              </Row>
            </Form>
            {/*<span style={{ color: 'var(--whitecolor)', fontSize: '20px' }}>*/}
            {/*  {' '}*/}
            {/*  {perc && perc > 0*/}
            {/*    ? `Increased By ${perc}%`*/}
            {/*    : perc && perc < 0*/}
            {/*    ? `Decreased By ${-perc}%`*/}
            {/*    : ''}*/}
            {/*</span>*/}
            <span
              style={{
                color: 'var(--whitecolor)',
                fontSize: '15px',
                marginBottom: '50px',
                marginLeft: '190px',
              }}
            >
              Result
            </span>
            <br />
            <span style={{ color: 'var(--whitecolor)' }}>
              <Input
                style={{
                  width: '38%',
                  marginTop: '10px',
                  marginLeft: '190px',
                }}
                placeholder="Percentage"
                value={perc}
              />{' '}
              %
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PercentCalc;
