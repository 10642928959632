import React, { useEffect, useState } from 'react';

// import RangeSlider from 'react-range-slider-input';
// import 'react-range-slider-input/dist/style.css';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Col, Form, Row, Select, Button, message, Input, Checkbox } from 'antd';
import 'antd/dist/antd.css';
import 'rsuite/dist/rsuite.css';
// import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
// import { v4 as uuidv4 } from 'uuid';

import { createTSAlert, esSearchCompany, updateMyAlerts } from '../../graphql';
import { errorHandler, green, red } from '../../utils';
import styles from '../refer/styles.module.scss';

interface Props {
  record: Object;
  alerts: Object;
  tabNum: string;
}

const Tickers: React.FC<Props> = ({ record, tabNum, alerts }) => {
  const [symbols, setSymbols] = useState([]);
  const [name, setName] = useState('');
  let symbolValue = record && record.symbols ? record.symbols : undefined;
  let nameValue = record && record.name ? record.name : undefined;
  useEffect(() => {
    if (record && record.name && tabNum === '2') {
      if (record && record.symbols) {
        setSymbols(record.symbols);
      }
      if (record && record.name) {
        setName(record.name);
      }
      if (record && record.trxn_val_min) {
        setTxnValue(record.trxn_val_min / 1000000);
      }
      if (record && record?.type === 'SEC13DG') {
        if (
          record &&
          record.status &&
          ((record.status.includes('INCREASED') &&
            record.status.includes('DECREASED') &&
            record.status.includes('SOLD_OFF') &&
            record.status.includes('NEWLY_BOUGHT')) ||
            record.status.includes('ALL'))
        ) {
          setIsAll(true);
        } else {
          if (record.status.includes('INCREASED')) {
            setIsIncreased(true);
          }
          if (record.status.includes('DECREASED')) {
            setIsDecreased(true);
          }
          if (record.status.includes('SOLD_OFF')) {
            setIsSoldOff(true);
          }
          if (record.status.includes('NEWLY_BOUGHT')) {
            setIsNewlyBought(true);
          }
        }
      }
      console.log(symbols);
    }
  }, [record, tabNum, symbols]);
  interface FormType {
    email: string;
    name: string;
  }
  const [form] = Form.useForm();
  const [txnValue, setTxnValue] = React.useState<number>(0);

  const handleChange = (event: Event, newValue: number) => {
    setTxnValue(newValue);
  };
  const handleInputChange = (event: Event) => {
    let { value } = event.target;
    value = value < 1000 ? value : 1000;
    value = value < 0 ? 0 : value;
    setTxnValue(value);
  };

  const [symbolData, setSymbolData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<string>(' ');
  const [saving, setIsSaving] = useState<boolean>(false);
  const [isIncreased, setIsIncreased] = useState(false);
  const [isDecreased, setIsDecreased] = useState(false);
  const [isSoldOff, setIsSoldOff] = useState(false);
  const [isNewlyBought, setIsNewlyBought] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const { Option } = Select;
  const marks = [
    {
      value: 0,
      label: '0M',
    },
    {
      value: 1000,
      label: '1000M',
    },
  ];

  const onFinish = (values: FormType) => {
    if ((!record || !record.name) && alerts.length) {
      // values.alertName
      const alertExsists = alerts.map((a) => {
        if (a.name === values.alertName) {
          return true;
        }
      });
      if (alertExsists.includes(true)) {
        setError('Alert Already Exsists with given name');
        return;
      } else {
        setError(' ');
      }
    }
    setIsSaving(true);
    console.log(values);
    let status = [];
    if (isAll) {
      status = ['ALL'];
    } else {
      if (isDecreased && isIncreased && isSoldOff && isNewlyBought) {
        status = ['ALL'];
      } else {
        if (isIncreased) {
          status.push('INCREASED');
        }
        if (isDecreased) {
          status.push('DECREASED');
        }
        if (isSoldOff) {
          status.push('SOLD_OFF');
        }
        if (isNewlyBought) {
          status.push('NEWLY_BOUGHT');
        }
      }
    }
    const type = 'SEC13DG';
    const payload = {
      type,
      options: {
        symbols: values.symbols,
        name: values.alertName,
        trxn_val_min: txnValue * 1000000,
        status: status,
      },
    };
    const apis =
      record && record.name
        ? [updateMyAlerts(payload)]
        : [createTSAlert(payload)];
    Promise.all(apis)
      .then((data) => {
        message.success('Alert Created');
      })
      .catch(errorHandler)
      .finally(() => {
        form.resetFields();
        setIsSaving(false);
        setIsAll(false);
        setIsDecreased(false);
        setIsIncreased(false);
        setIsNewlyBought(false);
        setIsSoldOff(false);
        window.location.reload();
      });
    console.log(payload);
  };
  const fetchSymbols = async (value: Object) => {
    setFetching(true);
    const res = await esSearchCompany(value);
    if (res) {
      setSymbolData(res);
    }
    setFetching(false);
  };

  const onIncreasedChange = (e) => {
    setIsIncreased(e.target.checked);
  };
  const onDecreasedChange = (e) => {
    setIsDecreased(e.target.checked);
  };
  const onSoldOffChange = (e) => {
    setIsSoldOff(e.target.checked);
  };
  const onNewlyBoughtChange = (e) => {
    setIsNewlyBought(e.target.checked);
  };
  const onAllChange = (e) => {
    setIsAll(e.target.checked);
  };

  return (
    <>
      <div>
        {saving && (
          <div style={{ marginLeft: '300px', marginTop: '50px' }}>
            <div className={styles.loaderStyle}>
              <BounceLoader color={green} loading={saving} size={150} />
            </div>
          </div>
        )}
        {!saving && (
          <Form
            onFinish={onFinish}
            layout="vertical"
            className={styles.referForm}
            name="ticker-form"
            form={form}
          >
            <Row
              span={12}
              className="gutter-row"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Col span={10} style={{ marginRight: '70px' }}>
                <Form.Item
                  name="alertName"
                  label="Alert Name"
                  initialValue={nameValue || name}
                  rules={[
                    {
                      required: true,
                      message: `Name is required`,
                    },
                  ]}
                >
                  <Input
                    style={{
                      width: '95%',
                      marginTop: '10px',
                      marginBottom: error ? '0' : '20px',
                    }}
                    onChange={() => {
                      setError('');
                    }}
                    id="id1"
                    placeholder="Give a Name for your alert"
                  />
                </Form.Item>
                {error && (
                  <p style={{ color: red, marginBottom: '20px' }}>{error}</p>
                )}
                <Form.Item
                  name="symbols"
                  label={'Add Symbols'}
                  initialValue={symbolValue || symbols}
                  className={styles.vFormSearhNew}
                  rules={[
                    {
                      required: true,
                      message: `Symbol is required`,
                    },
                  ]}
                >
                  <Select
                    placeholder="Enter valid symbols"
                    mode={'multiple'}
                    notFoundContent={
                      fetching ? (
                        <span className={styles.spinnerStyle}>
                          <BounceLoader
                            color={green}
                            loading={true}
                            size={50}
                          />
                        </span>
                      ) : null
                    }
                    onSearch={fetchSymbols}
                    showSearch={true}
                    allowClear={true}
                    showArrow={false}
                    filterOption={false}
                    onSelect={() => setSymbolData([])}
                    size="large"
                    style={{ width: 'calc(100% - 10px)', marginTop: '10px' }}
                  >
                    {symbolData.map((d) => (
                      <Option key={d.symbol} className={styles.optionStyle}>
                        <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                        {d.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <p
                style={{
                  color: 'var(--whitecolor)',
                  marginBottom: '10px',
                  marginTop: '20px',
                }}
              >
                Shares Activity
              </p>
              <div
                style={{
                  border: '1px solid green',
                  marginBottom: '30px',
                  paddingTop: '20px',
                  width: '400px',
                }}
              >
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                  }}
                >
                  <Form.Item name="all" style={{ marginLeft: '10px' }}>
                    <Checkbox checked={isAll} onChange={onAllChange}>
                      All
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="increased" style={{ marginLeft: '10px' }}>
                    <Checkbox
                      disabled={isAll}
                      checked={isIncreased}
                      onChange={onIncreasedChange}
                    >
                      Increased
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="decreased" style={{ marginLeft: '10px' }}>
                    <Checkbox
                      disabled={isAll}
                      checked={isDecreased}
                      onChange={onDecreasedChange}
                    >
                      Decreased
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                  }}
                >
                  <Form.Item name="soldOff" style={{ marginLeft: '10px' }}>
                    <Checkbox
                      disabled={isAll}
                      checked={isSoldOff}
                      onChange={onSoldOffChange}
                    >
                      Sold Off
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="newlyBought" style={{ marginLeft: '10px' }}>
                    <Checkbox
                      disabled={isAll}
                      checked={isNewlyBought}
                      onChange={onNewlyBoughtChange}
                    >
                      Newly Bought
                    </Checkbox>
                  </Form.Item>
                </Col>
              </div>
              {/* <Form.Item
                style={{ width: '100%', marginBottom: 0, textAlign: 'center' }}
              > */}
              <div>
                <p style={{ color: 'var(--whitecolor)', marginBottom: '40px' }}>
                  Transaction Value ( in Millions )
                  <span style={{ marginLeft: '100px' }}>
                    <span style={{ color: '#1abd50', fontSize: 15 }}>$</span>
                    <Input
                      onChange={handleInputChange}
                      placeholder={0}
                      onKeyDown={(e) => {
                        if (e.key === '.') {
                          e.preventDefault();
                        }
                      }}
                      type="number"
                      style={{
                        width: 95,
                        fontSize: 15,
                      }}
                      value={txnValue !== 0 ? txnValue : ''}
                      pattern="[0-9]"
                      defaultValue={txnValue !== 0 ? txnValue : ''}
                      className={styles.VdailySearch}
                    />
                  </span>
                </p>
                <div style={{ width: '600px' }}>
                  {/*<RangeSlider id="range-slider" />*/}
                  <Box sx={{ width: 400, color: 'var(--whitecolor)' }}>
                    <Slider
                      getAriaLabel={() => 'value range'}
                      value={txnValue}
                      step={1}
                      min={0}
                      max={1000}
                      marks={marks}
                      onChange={handleChange}
                      valueLabelDisplay="on"
                    />
                  </Box>
                </div>
                <div className={styles.btnSectionBox}>
                  <Button
                    type="primary"
                    style={{ width: '150px', marginTop: '50px' }}
                    htmlType="submit"
                  >
                    Apply
                  </Button>
                </div>
              </div>
              {/* </Form.Item> */}
              {/*{error && <span style={{ color: red }}>{error}</span>}*/}
            </Row>
          </Form>
        )}
      </div>
    </>
  );
};

export default Tickers;
