import React from 'react';

import Icon from '@ant-design/icons';

const deleteSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16.359"
    viewBox="0 0 15 16.359"
  >
    <g
      id="Group_167"
      data-name="Group 167"
      transform="translate(287.026 12.817)"
    >
      <path
        id="Path_116"
        data-name="Path 116"
        d="M-285.661-3.576V-8.633c0-.1,0-.091-.089-.091h-.914a.342.342,0,0,1-.361-.36q0-.328,0-.656a.347.347,0,0,1,.354-.355c.147,0,.294,0,.441,0h2.725a.374.374,0,0,1,.048,0,.076.076,0,0,0,.088-.058c.1-.242.2-.483.3-.724.143-.342.285-.685.43-1.026a1.29,1.29,0,0,1,.281-.4,1.752,1.752,0,0,1,.771-.46,1.3,1.3,0,0,1,.36-.051h3.4a1.455,1.455,0,0,1,.7.188,1.657,1.657,0,0,1,.65.6,2.336,2.336,0,0,1,.145.311l.651,1.556c.026.062.026.062.094.062h3.177a.467.467,0,0,1,.143.018.339.339,0,0,1,.241.316c0,.032,0,.064,0,.1,0,.2,0,.394,0,.591a.349.349,0,0,1-.35.351c-.312,0-.623,0-.935,0-.072,0-.072,0-.072.071q0,1,0,2,0,4,0,7.992a2.619,2.619,0,0,1-.2,1.026,2.147,2.147,0,0,1-.5.734,1.525,1.525,0,0,1-.791.418.931.931,0,0,1-.165.018h-.086q-4.4,0-8.8,0a1.476,1.476,0,0,1-1.007-.391A2.076,2.076,0,0,1-285.5,2.3a2.506,2.506,0,0,1-.15-.712c-.005-.077-.007-.154-.007-.231Q-285.661-1.111-285.661-3.576Zm1.377-.019q0,1.416,0,2.833,0,1.064,0,2.128a1.249,1.249,0,0,0,.025.262,1.1,1.1,0,0,0,.194.439.193.193,0,0,0,.2.1h8.675a.4.4,0,0,1,.043,0,.124.124,0,0,0,.1-.043.849.849,0,0,0,.169-.253,1.3,1.3,0,0,0,.109-.549q0-4.98,0-9.959c0-.095.007-.087-.087-.087h-9.341c-.1,0-.093-.011-.093.093Q-284.284-6.113-284.284-3.595Zm4.755-6.5h2.327c.041,0,.045-.008.031-.045-.028-.07-.057-.139-.085-.209l-.387-.945a.159.159,0,0,0-.04-.063.314.314,0,0,0-.216-.09q-1.341,0-2.682,0h-.58a.166.166,0,0,0-.069.008.307.307,0,0,0-.179.167c-.15.366-.3.731-.456,1.1-.034.081-.039.079.053.079Z"
        fill="#db4141"
      />
      <path
        id="Path_117"
        data-name="Path 117"
        d="M139.8,263.511q0,1.263,0,2.527a.426.426,0,0,1-.007.1.284.284,0,0,1-.274.223q-.294,0-.588,0a.281.281,0,0,1-.275-.232.554.554,0,0,1-.007-.1q0-2.138,0-4.277,0-.386,0-.772a.327.327,0,0,1,.036-.16.269.269,0,0,1,.243-.151c.2,0,.395,0,.592,0a.284.284,0,0,1,.28.277c0,.021,0,.042,0,.063Q139.8,262.256,139.8,263.511Z"
        transform="translate(-415.981 -266.628)"
        fill="#db4141"
      />
      <path
        id="Path_118"
        data-name="Path 118"
        d="M18.284,263.535q0,1.265,0,2.531a.375.375,0,0,1-.015.128.28.28,0,0,1-.262.19q-.294,0-.588,0a.284.284,0,0,1-.279-.273c0-.018,0-.036,0-.054q0-2.524,0-5.048a.377.377,0,0,1,.017-.133.284.284,0,0,1,.264-.189q.292,0,.583,0a.281.281,0,0,1,.28.276c0,.024,0,.048,0,.072Q18.284,262.285,18.284,263.535Z"
        transform="translate(-297.238 -266.654)"
        fill="#db4141"
      />
      <path
        id="Path_119"
        data-name="Path 119"
        d="M-104.366,263.513q0-1.27,0-2.54a.3.3,0,0,1,.061-.2.268.268,0,0,1,.209-.106q.3,0,.6,0a.284.284,0,0,1,.273.271c0,.018,0,.036,0,.054q0,2.524,0,5.048a.442.442,0,0,1-.007.1.282.282,0,0,1-.276.225q-.292,0-.583,0a.279.279,0,0,1-.278-.273c0-.024,0-.048,0-.072Q-104.366,264.767-104.366,263.513Z"
        transform="translate(-178.5 -266.636)"
        fill="#db4141"
      />
    </g>
  </svg>
);
const DeleteIcon = (props) => <Icon component={deleteSvg} {...props} />;

export default DeleteIcon;
