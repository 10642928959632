export const REPORT_CODE_NAME_MAP: any = {
  TS: 'Trending Stocks',
  SDY: 'Sec Latest Report',
  SSL: 'Sec By Symbol',
  SDL: 'Sec Daily Report',
  SAV: 'Sec By Approx Trx Val',
  QTBS: 'Latest Top Institutional Quarterly Buys/Sells',
  DRMF: 'Daily Mutual Funds Report',
  BFN: 'By Fund Name',
  SSMF: 'Symbol Summary Mutual Funds',
  AID: 'All Institutions',
  ADMA: 'Moving Averages',
  ADUD: 'Under a Dollar',
  DIL: 'Insider Latest Report',
  DISS: 'Insider By Symbol',
  DIA: 'Insider ALl',
  DTU: 'TS Weekly Uptrend',
  DTD: 'TS Weekly Downtrend',
  DDTU: 'TS Daily Price Uptrend',
  DDTD: 'TS Daily Price Downtrend',
  DDTUV: 'TS Daily Price & Volume Uptrend',
  DDTDv: 'TS Daily Price & Volume Downtrend',
  AINB: 'All Institution Newly Bought',
  AIU: 'All Institution Ultimate Reports',
  AIO: 'All Institution Other Reports',
  TTNB: 'Top Ten Hedge Newly Bought',
  TTU: 'Top Ten Hedge Ultimate Reports',
  TTO: 'Top Ten Hedge Other Reports',
  ITT: 'Individual Top Ten Hedge',
  IS: 'Special Reports',
  DR: 'Dividends Summary',
  SR: 'Sector wise allocation',
};
