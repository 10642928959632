import React, { useState, useContext } from 'react';

import { ArrowLeftOutlined, FilterOutlined } from '@ant-design/icons';
import { Row, Col, Breadcrumb, Button } from 'antd';
import { get, round } from 'lodash';
import moment from 'moment-timezone';
import BounceLoader from 'react-spinners/BounceLoader';

import { HorizontalBarChart } from '../../components';
import { green, red, black1, white, getTimeRange } from '../../utils';
import SaveReport from './SaveReport';
import { getSearchReportTitle } from './SearchReport.constants';
import { SearchReportContext } from './SearchReportContext';
import SearchReportFilterForm from './SearchReportFilterForm';
import SectorSymbolOverview from './SectorSymbolOverview';
import styles from './style.module.scss';

const SectorOverview = () => {
  const {
    searchData = {},
    isLoading,
    reportInfo,
    currentSearchCriteria,
    reportType,
    reportSubType,
    history,
    reportTitle,
    fetchReportName,
    fetchingName,
    filterOptions,
    queryData,
    error,
    onFilter,
    sortDetail,
    selectedNoOfQtrs,
    tableColumns,
    timeframe,
    selectedSector,
    setSelectedSector,
  } = useContext(SearchReportContext);
  const [openFilterForm, setOpenFilterForm] = useState<boolean>(false);
  // const [selectedSector, setSelectedSector] = useState('');
  const { data = [], lastUpdatedTime = '' } = searchData;
  const { breadcrumb = [] } = reportInfo;
  const barGraphData = data.reduce((pv, cv, index) => {
    const obj = {};
    cv.forEach((d) => {
      obj[d.key] = isNaN(d.value) ? d.value : +d.value; // d.value;
    });
    const fvalue = `$${Math.abs(round(obj.priceDiff, 2))}(${Math.abs(
      round(obj.perChange, 2),
    )}%)`;
    pv.push({
      ...obj,
      symbol_sector: `${obj.sector} (${obj.symbol})`,
      index: obj.symbol === 'SPY' ? 0 : index + 1,
      formatted_value: obj.priceDiff >= 0 ? `+${fvalue}` : `-${fvalue}`,
    });
    return pv;
  }, []);
  if (!selectedSector && barGraphData.length) {
    const firsItem = barGraphData.find((d) => d.index === 1);
    if (firsItem) {
      setSelectedSector(firsItem.sector);
    }
  }
  const setTimeFrame = (value) => {
    const dateRange = getTimeRange(value);
    onFilter(
      {
        ...filterOptions,
        endDate: dateRange.end,
        startDate: dateRange.start,
      },
      null,
      value,
    );
  };

  return (
    <div className={styles.vReportsNewPage1}>
      <div className={styles.vGoBackSaveBar}>
        <p
          onClick={() =>
            get(history, 'location.state.prevPage', '') === 'search'
              ? history.push({
                  pathname: '/search',
                  state: {
                    type: reportType,
                    subType: reportSubType,
                    prevPage: 'searchReport',
                  },
                })
              : history.goBack()
          }
          className={styles.vGoBackBtnBlock}
        >
          {history.length > 2 && (
            <span className={styles.vGoBackBtn}>
              <ArrowLeftOutlined /> Go Back
            </span>
          )}
        </p>

        {!isLoading && (
          <div className={styles.SaveReportBoxNew2}>
            <SaveReport
              searchCriteria={currentSearchCriteria}
              reportType={reportType}
              reportSubType={reportSubType}
              reportTitle={reportTitle}
              fetchReportName={fetchReportName}
              fetchingName={fetchingName}
            />
          </div>
        )}
      </div>
      <div className={styles.symbolSearchContentBox}>
        <Breadcrumb>
          {breadcrumb.map((item) => (
            <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>

        <div className={styles.trendingSectorTitleContainer}>
          <h2>
            {getSearchReportTitle(
              reportSubType || reportType,
              get(queryData, 'institutionsName', ''),
              null,
              null,
              get(filterOptions, 'startDate', moment().subtract(1, 'year')),
              lastUpdatedTime,
              get(queryData, 'criterion', ''),
              get(filterOptions, 'endDate', moment()),
              get(searchData, 'reportType', ''),
            ).toUpperCase()}
          </h2>

          <div className={styles.vMobFilterNew}>
            <Button
              icon={<FilterOutlined />}
              // size={}
              onClick={() => {
                setOpenFilterForm(!openFilterForm);
              }}
              //style={{ marginRight: 14 }}
              className={styles.CommonBtnsFilter}
              disabled={isLoading}
            >
              Filter/Sort
            </Button>
          </div>
        </div>
      </div>
      <Row span={24} id={reportSubType}>
        <Col span={24} style={{ marginTop: 20 }}>
          {error && <p style={{ color: red }}>{error}</p>}
          {!isLoading || (data || []).length > 0 ? (
            <Row justify="start">
              {barGraphData.length ? (
                <Col sm={{ span: 24 }} xl={{ span: 12 }} style={{ border: 1 }}>
                  <div
                    className={styles.datesArray2}
                    id="reportDate"
                    style={{ marginBottom: 20 }}
                  >
                    <h3 style={{ margin: 0 }}>Select Timeframe</h3>
                    {['1D', '5D', '1M', '3M', '6M', 'YTD', '1Y'].map(
                      (range) => (
                        <div
                          key={range}
                          className={styles.dateRangeBox}
                          onClick={() => setTimeFrame(range)}
                          style={{
                            backgroundColor:
                              timeframe === range ? green : white,
                            color: timeframe === range ? white : black1,
                            borderWidth: timeframe === range ? 0 : 1,
                          }}
                        >
                          <p className={styles.day}>{range}</p>
                        </div>
                      ),
                    )}
                  </div>
                  {isLoading && (
                    <div className={styles.miniLoaderStyle}>
                      <BounceLoader
                        color={green}
                        loading={isLoading}
                        size={50}
                      />
                    </div>
                  )}
                  <HorizontalBarChart
                    data={barGraphData.sort((a, b) => a.index - b.index)}
                    onChange={(d) => setSelectedSector(d)}
                  />
                </Col>
              ) : (
                ''
              )}

              <Col sm={{ span: 24 }} xl={{ span: 12 }} style={{ border: 1 }}>
                <SectorSymbolOverview
                  selectedSector={selectedSector}
                  history={history}
                  isLoading={isLoading}
                  timeFrame={timeframe}
                  filterOptions={filterOptions}
                  barGraphData={barGraphData}
                />
              </Col>
            </Row>
          ) : (
            <div className={styles.loaderStyle}>
              <BounceLoader color={green} loading={isLoading} size={150} />
            </div>
          )}
        </Col>
        {openFilterForm && (
          <SearchReportFilterForm
            visible={openFilterForm}
            onCancel={() => setOpenFilterForm(false)}
            onFilter={onFilter}
            filterOptions={filterOptions}
            queryData={queryData}
            reportSubType={reportSubType}
            columns={tableColumns.filter(
              (col) => col.visible === true && col.value,
            )}
            sortDetail={sortDetail}
            // handleSortReset={() => setSortDetail([])}
            // handleSortSubmit={(val) => setSortDetail(val)}
            reportType={reportType}
            selectedNoOfQtrs={selectedNoOfQtrs}
          />
        )}
      </Row>
    </div>
  );
};

export default SectorOverview;
