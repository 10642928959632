import React, { useState } from 'react';

import { API, graphqlOperation } from 'aws-amplify';
import { get } from 'lodash';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';

import { notifications } from '../../graphql/subscriptions';
import { botAvatar } from '../../images';

const CustomChatbot: React.FC<Props> = () => {
  const [closeBot, setCloseBot] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  // let appText1 = appText;
  // setText(appText);
  const { loggedInUser, personalData } = useSelector(
    ({ user }) => ({
      loggedInUser: get(user, 'loggedInUser', {}),
      personalData: get(user, 'personalData', {}),
    }),
    shallowEqual,
  );
  const email = get(personalData, 'email', '');
  console.log(email);

  const stockInfo = (value) => {
    setText(value.value.data.notifications.body);
    setCloseBot(false);
    // appText1 = undefined;
  };
  if (text !== 'Hello') {
    const subscription = API.graphql(
      graphqlOperation(notifications, { email: 'vishal.0694@gmail.com' }),
    );
    subscription.subscribe({
      next: (value) => {
        stockInfo(value);
      },
      error: (error) => console.warn(error),
    });
    // stockInfo(appText);
  }

  const greeting = () => {
    const hour = moment().hour();

    if (hour > 16) {
      return 'Good evening';
    }

    if (hour > 11) {
      return 'Good afternoon';
    }

    return 'Good morning';
  };

  return (
    <>
      {loggedInUser.given_name && !closeBot && text ? (
        <>
          <div
            style={{
              backgroundColor: '#2B343D',
              borderRadius: '30px 30px 0 30px',
              border: '3px solid #1abd50',
              position: 'fixed',
              top: text.length > 1 ? 350 : 430,
              right: 130,
              height: text.length > 1 ? 190 : 130,
              width: text.length > 1 ? 320 : 200,
            }}
          >
            <p
              style={{
                color: 'black',
                backgroundColor: 'white',
                fontSize: 15,
                fontFamily: 'Century Gothic',
                marginLeft: 20,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 10,
                border: '1px solid #495057',
                borderRadius: '20px 20px 20px 0',
                width: '125px',
                marginTop: 20,
              }}
            >
              {greeting()} {loggedInUser.given_name}
            </p>
            <span
              style={{
                position: 'fixed',
                right: 150,
                top: text.length > 1 ? 360 : 430,
                fontSize: '20px',
                border: 'none',
                color: '#1abd50',
                cursor: 'pointer',
              }}
              onClick={() => setCloseBot(true)}
            >
              x
            </span>
            <p
              style={{
                border: text.length ? '1px solid #495057' : '',
                color: 'black',
                fontSize: 15,
                backgroundColor: text.length ? 'white' : '',
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: '20px 20px 20px 0',
                marginLeft: '20px',
                marginRight: '30px',
              }}
            >
              {text}
            </p>
          </div>
          <button
            style={{
              backgroundColor: 'transparent',
              position: 'fixed',
              top: 550,
              right: 50,
              height: 60,
              float: 'right',
              borderRadius: '50%',
            }}
          >
            <img
              style={{ height: 58, width: 50, zIndex: 3 }}
              src={botAvatar}
              alt="Bot"
            />
          </button>
        </>
      ) : (
        ''
      )}
    </>
  );
};
export default CustomChatbot;
