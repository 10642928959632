import React, { useState, useEffect } from 'react';

// import { csv } from 'd3-fetch';
import { scaleQuantize } from 'd3-scale';
import { useLocation } from 'react-router-dom';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';

import { fetchStudentPerformanceCountyReports } from '../../graphql/gamification';
import { errorHandler, usStates } from '../../utils';
import styles from './styles.module.scss';

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/counties-10m.json';

const colorScale = scaleQuantize()
  .domain([1, 10])
  .range([
    '#ea2f17',
    '#c25108',
    '#d85d0d',
    '#f36d16',
    '#21a9e5',
    '#3757d2',
    '#203db2',
    '#203db2',
    '#182c83',
    '#11205b',
    '#0c1532',
  ]);

const MapChart = () => {
  const [data, setData] = useState([]);
  const location = useLocation();

  const stateName = location.state?.name?.properties?.name || 'COLORADO';
  const stateId = location.state?.name?.id || '08';
  const getStateAbbr = () => {
    let stateData = '';
    usStates.forEach((i) => {
      if (i.name.toLowerCase() === stateName.toLowerCase()) {
        stateData = { abbr: i.abbreviation, lat: i.lat, lon: i.lon };
      }
    });
    return stateData;
  };
  const stateData = getStateAbbr();

  useEffect(() => {
    let payload = {
      page: 1,
      size: 2147483647,
      sortBy: 'student_performance_rank',
      sortOrder: 'asc',
      schoolState: stateData.abbr || 'CO',
    };
    const promises = [fetchStudentPerformanceCountyReports(payload)];
    Promise.all(promises)
      .then((res) => {
        const rawTableData = res[0];
        for (const r of rawTableData.data) {
          usStates.forEach((i) => {
            if (i.abbreviation === r['school_state']) {
              r['school_state'] = i.name;
              return;
            }
          });
        }
        setData(rawTableData.data);
      })
      .catch(errorHandler)
      .finally(() => {});
    // https://www.bls.gov/lau/
    // csv('/unemployement-by-county-2017.csv').then((counties) => {
    //   setData(counties);
    // });
  }, [stateData.abbr]);

  return (
    <>
      <ComposableMap
        projection="geoAlbersUsa"
        projectionConfig={{ center: [] }}
        className={styles.mapSize}
      >
        {/*height={300} width={1000}*/}
        <ZoomableGroup zoom={2} center={[stateData.lon, stateData.lat]}>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies
                .filter((geo) => geo.id.startsWith(`${stateId}`))
                .map((geo) => {
                  const cur = data.find(
                    (s) =>
                      s['school_county']
                        .replace(' County', '')
                        .toLowerCase() === geo.properties.name.toLowerCase(),
                  );
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={
                        cur?.percentage_returns
                          ? colorScale(cur.percentage_returns)
                          : 'grey'
                      }
                    />
                  );
                })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </>
  );
};

export default MapChart;
