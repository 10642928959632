import React, { useState, useEffect, useCallback } from 'react';

import { FilterOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Button, Input } from 'antd';
import { get, debounce } from 'lodash';
// import queryString from 'query-string';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { getOurPicks } from '../../graphql';
import { noData } from '../../images';
import { setAllTimePicks } from '../../redux/reducers/picks';
// import { setOurPickURL } from '../../redux/reducers/user';
import { errorHandler } from '../../utils';
import FilteredValues from './FilteredValues';
import { columns, OurPickType } from './Pick.constant';
import PickFilter from './PickFilter';
import PicksList from './PickList';
import styles from './style.module.scss';

// const { SubMenu } = Menu;
const { Title } = Typography;

interface Props {
  data: Array<OurPickType>;
  match: Object;
  history: Object;
  picksQueryData: Object;
}
const AnyTimePick: React.FC<Props> = ({ match, history }) => {
  const dispatch = useDispatch();

  const { data, picksQueryData } = useSelector(
    ({ picks }) => ({
      data: get(picks, 'alltime', []),
      picksQueryData: {}, // get(picks, 'queryData.alltime', {}),
    }),
    shallowEqual,
  );

  const [openFilterForm, setOpenFilterForm] = useState<boolean>(false);

  const [filters, setFilters] = useState(get(picksQueryData, 'filters', {}));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortDetail, setSortDetail] = useState<Array>(
    get(picksQueryData, 'sort', []),
  );
  const [searchText, setSearchText] = useState<string>(
    get(picksQueryData, 'searchText', ''),
  );
  const [fetchData, setFetchData] = useState<Date>(new Date());
  const [pageNo, setPageNo] = useState<number>(get(picksQueryData, 'page', 1));
  const [pageSize, setPageSize] = useState<number>(
    get(picksQueryData, 'size', 20),
  );
  useEffect(() => {
    setIsLoading(true);
    // dispatch(
    //   setPickQueryData(
    //     {
    //       filters: filters,
    //       page: pageNo,
    //       size: pageSize,
    //       searchText: searchText,
    //       sort: sortDetail,
    //     },
    //     'alltime',
    //   ),
    // );
    // history.push(path);
    const apis = [
      getOurPicks({
        request: {
          type: 'ANYTIME',
          filters: filters,
          page: pageNo,
          size: pageSize,
          searchText: searchText,
          sort: sortDetail.filter((i) => i.sortBy),
        },
      }),
    ];

    Promise.all(apis)
      .then((data) => {
        dispatch(setAllTimePicks(data[0]));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    // filters,
    history,
    pageNo,
    pageSize,
    searchText,
    fetchData,
    // fetchData,
    // sortDetail,
  ]);

  const onFilter = (val: FilterType, sort) => {
    if (sort && Array.isArray(sort)) {
      setSortDetail(sort);
    }
    setFilters(val);
    setFetchData(new Date());
  };
  const changeSearchText = (value: string) => {
    setPageNo(1);
    setSearchText(value);
    // setFilterOptions({});
  };

  const debouncedSave = useCallback(
    debounce((value) => changeSearchText(value), 500),
  );

  const onTextChange = (event) => {
    const { value } = event.target;
    debouncedSave(value);
  };
  const handleFilterValueClick = (name, value) => {
    let updatedFilters = { ...filters };
    if (name === 'symbols') {
      updatedFilters = {
        ...filters,
        symbols: updatedFilters.symbols.filter((s) => s !== value),
      };
    } else if (name === 'sector') {
      updatedFilters = {
        ...filters,
        sector: Array.isArray(updatedFilters.sector)
          ? updatedFilters.sector.filter((s) => s !== value)
          : undefined,
      };
    } else {
      delete updatedFilters[name];
    }

    setFilters(updatedFilters);
    setFetchData(new Date());
    setOpenFilterForm(false);
  };

  const isFilterEmpty = Object.keys(filters).filter((d) =>
    d === 'symbols' || d === 'sector'
      ? filters[d] && filters[d].length === 0
        ? undefined
        : filters[d]
      : filters[d],
  );
  const handleReset = () => {
    setFilters({});
    setFetchData(new Date());
  };
  const handleSort = (val) => {
    setSortDetail(val);
    setFetchData(new Date());
  };
  return (
    <>
      <Row justify="end">
        <Col className={styles.vFilterNewV2}>
          <div className={styles.reportOptionBlock}>
            <Input
              placeholder="search..."
              onChange={onTextChange}
              className={styles.VdailySearch}
              allowClear
            />
          </div>
          <Button
            icon={<FilterOutlined />}
            size="middle"
            onClick={() => {
              setOpenFilterForm(!openFilterForm);
            }}
            //style={{ marginRight: 20 }}
            className={styles.Vfilterbtn}
            disabled={false}
            id="reportColumnFilters"
          >
            Filter/Sort
          </Button>
        </Col>
      </Row>

      <div className={styles.pickUpSectionBox}>
        <div className={styles.pickupLeftBox}>
          <Title level={5}>{`Ever Green Picks`}</Title>
        </div>
        <div className={styles.pickupRightBox}>
          {isFilterEmpty.length > 0 && (
            <FilteredValues
              filterOptions={filters}
              handleClick={handleFilterValueClick}
              handleReset={handleReset}
            />
          )}
        </div>
      </div>

      {data.length > 0 && (
        <PicksList
          isLoading={isLoading}
          data={data}
          type="anytime"
          setSortDetail={(val) => handleSort(val)}
          setSearchText={(val) => setSearchText(val)}
          setPageNo={(val) => setPageNo(val)}
          setPageSize={(val) => setPageSize(val)}
          sortDetail={sortDetail}
          pageSize={pageSize}
          pageNo={pageNo}
          history={history}
        />
      )}
      {data.length === 0 && !isLoading && (
        <Row justify="space-between" span={24}>
          <div className={styles.noDataBox}>
            <div className={styles.noDataBoxContent}>
              <img src={noData} alt="no_data" />
              <p className={styles.vNoPicks}>No Picks For Today</p>
            </div>
          </div>
        </Row>
      )}
      {openFilterForm && (
        <PickFilter
          visible={openFilterForm}
          onCancel={() => setOpenFilterForm(false)}
          onFilter={onFilter}
          filterOptions={filters}
          columns={columns}
          sortDetail={sortDetail}
          type="ANYTIME"
          // handleSortReset={() => setSortDetail([])}
          // handleSortSubmit={(val) => setSortDetail(val)}
        />
      )}
    </>
  );
};

export default AnyTimePick;
