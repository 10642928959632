import React from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from './style.module.scss';

interface Props {}

const MembershipPageTnC: React.FC<Props> = () => {
  return (
    <div className={styles.featuresSection}>
      <h3 className={styles.titleFeature}>On Subscribing:</h3>
      <ul>
        {/* <li>
        <CheckCircleOutlined /> Life Time Price is applicable only for Early
        ThinkSabien & if No cancellation between Renewal ( Monthly/Yearly ).
      </li> */}
        <li>
          <CheckCircleOutlined /> Subscription will be Automatic Renewal (
          Monthly/Yearly ) based on your current plan.
        </li>
        <li>
          <CheckCircleOutlined /> Payments processed are Non-Refundable (
          Monthly/Yearly ).
        </li>
        <li>
          <CheckCircleOutlined /> Cancel anytime with one Click, upon
          cancellation you’re not Billed on your Next Renewal Date
        </li>
        <li>
          <CheckCircleOutlined /> Read Full{' '}
          <Link
            to="/tnc"
            // onClick={() => setOpenTnc(true)}
            className={styles.tnc}
          >
            Terms and Conditions
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default MembershipPageTnC;
