import React from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';

import styles from '../style.module.scss';

const UserTermsAndConditions: React.FC<Props> = ({ history }) => {
  return (
    <div className={styles.termandConditionContent}>
      <div style={{ marginLeft: 50 }} className={styles.vGoBackSaveBar}>
        <p onClick={() => history.goBack()} className={styles.vGoBackBtnBlock}>
          <span className={styles.vGoBackBtn}>
            <ArrowLeftOutlined /> Go Back
          </span>
        </p>
      </div>
      <h2>THINKSABIO, INC. WEBSITE TERMS OF USE</h2>
      <h3>Last Modified: August 31, 2021</h3>
      <h4>1. Acceptance of the Terms of Use</h4>
      <p>
        Welcome to the website for THINKSABIO, Inc. This website is a service of
        THINKSABIO, Inc, together with its affiliates, subsidiaries, agents, and
        licensors (collectively <strong>"THINKSABIO"</strong>) (THINKSABIO may
        also be referred to in this Terms of Use as <strong>"we," "us,"</strong>{' '}
        or <strong>"our"</strong>). For the purposes of these Terms of Use,
        unless otherwise specifically stated, the terms <strong>“you”</strong>{' '}
        or <strong>“your”</strong> shall collectively refer to you, the user of
        the Website (defined below) or any legal entity for whom you are an
        authorized employee or agent. These Terms of Use apply to all users who
        access or use the Website (defined below) in any capacity.
      </p>
      <p>
        The following terms and conditions (the <strong>“Terms of Use”</strong>)
        govern your access and use of the THINKSABIO website, including any
        content, functionality, services, or solutions offered on or through the
        THINKSABIO website, including the information collected from
        publicly-available sources and provided to users who utilize
        THINKSABIO’s subscription-based services (collectively the{' '}
        <strong>“Website”</strong>), as well as any electronic transmission
        sent, received, posted, accessed, or stored on or through the Website.
        By using this Website, you understand and agree that the Website is
        provided “AS-IS” and that THINKSABIO assumes no responsibility for the
        timeliness, efficiency, effectiveness, deletion, mis-delivery or failure
        of any communication or services provided. Certain products or services
        offered through the Website may have additional terms and conditions,
        which govern in the event of any inconsistency with the terms below.
        These Terms of Use form a legal agreement entered into between you and
        THINKSABIO (the <strong>“Agreement”</strong>). You represent and warrant
        that you have full power and authority to enter into this Agreement and
        in doing so will not violate any other agreement to which you are a
        party.
      </p>
      <p>
        Please read these Terms of Use carefully before you start to use the
        Website. These terms explain the policies that govern your access to and
        use of our Website, including the actions that we may take, within our
        sole discretion, for any use that we deem unacceptable. By using or
        accessing the Website you accept and agree to be bound and abide by
        these Terms of Use, all applicable laws and regulations, as well as our{' '}
        <a rel="noopener noreferrer" target="_blank" href="/privacy-policy">
          Privacy Policy
        </a>
        , which is incorporated herein by reference (collectively, the{' '}
        <strong>“Policy and Agreement”</strong>), as we may modify them from
        time to time. If you do not accept and agree with the Policy and
        Agreement, you shall not use or access the Website.
      </p>
      <h4>2. Eligibility</h4>
      <p>
        In order to use this Website, you need to: (1) be 13 years of age and
        (2) have the power to enter into a binding contract with us and not be
        barred from doing so under any applicable laws. THE WEBSITE IS NOT
        AVAILABLE TO PERSONS UNDER THE AGE OF 13. By accessing the Website, you
        represent and warrant that you are at least 13 years of age or older and
        are fully able and competent to enter into the terms, conditions,
        representations, and warranties set forth in the Policy and Agreement.
        You may not use or access the Website if you do not agree to the Policy
        and Agreement.
      </p>
      <h4>3. Non-United States Residents – Consent to Transfer Information</h4>
      <p>
        The Website is directed to eligible and authorized users in the United
        States. If you reside outside the United States and choose to use the
        Website or provide your information to us, please be aware that your
        information will be processed and maintained on servers or databases in
        a country other than your country of residence and that these countries
        may not provide the same level of protection of privacy and personal
        rights that you may enjoy in your country of residence. By accessing and
        using the Website, you consent to the collection and use of your
        information as described in the Policy and Agreement outside your
        country of residence, including the transfer of your information across
        international boundaries. If you do not want your information to be
        transferred, processed, or maintained outside your country of residence,
        you are not authorized to use the Website.
      </p>
      <p>
        THE WEBSITE IS INTENDED FOR USERS LOCATED IN THE UNITED STATES. IT IS
        NOT INTENDED FOR USERS LOCATED IN OTHER COUNTRIES, INCLUDING THE
        EUROPEAN UNION AND THE EUROPEAN ECONOMIC AREA. We make no claims that
        the Website or any of its content or services are accessible or
        appropriate outside of the United States. Access to the Website may not
        be legal by certain persons or in certain countries. If you access the
        Website from outside the United States, you do so on your own initiative
        and are responsible for compliance with local laws. By Accessing this
        website, you represent and warrant that (1) you are not located in a
        country that is subject to a U.S. Government embargo, or that has been
        designated by the U.S. Government as a “terrorist supporting” country;
        and (2) you are not listed on any U.S. Government list of prohibited or
        restricted parties.
      </p>
      <h4>4. Accessing the Website and Account Security</h4>
      <p>
        In order to access the Website or some of the resources the Website
        offers, you may be required to register and create an account and
        provide certain registration details or other information such as your
        full name, address, phone number, and email address. It is a condition
        of your use of the Website that all of the information you provide on
        the Website is correct, current, and complete. You are responsible for:
        (1) making all arrangements necessary for you to have access to the
        Website and (2) ensuring that all persons who access the Website through
        your internet connection are aware of the Policy and Agreement and
        comply with them.
      </p>
      <p>
        You will create or be provided with a username and password to access
        the Website. You must treat your username, password, or any other piece
        of information utilized as part of our security procedures as
        confidential, and you must not disclose it to any other person or
        entity. You acknowledge that your account is personal to you and agree
        not to provide any other person with access to this Website or portions
        of it using your username, password, or other security information. You
        agree to notify us immediately of any unauthorized access to or use of
        your username or password or any other breach of security. You also
        agree to ensure that you logout and exit from your account at the end of
        each session. You should use particular caution when accessing your
        account from a public or shared computer so that others are not able to
        view or record your username, password, or other personal information.
      </p>
      <p>
        We maintain the right to disable any username, password, or other
        identifier, whether chosen by you or provided by us, at any time if, in
        our opinion, you have violated any provision of the Policy and
        Agreement.
      </p>
      <p>
        We reserve the right to withdraw or amend this Website, and any service,
        product, solution, or material we provide on the Website. We will not be
        liable if for any reason all or part of the Website is unavailable at
        any time or for any period. From time to time, we may restrict access to
        some parts of the Website, or the entire Website, to you.
      </p>
      <h4>5. Authorized Users</h4>
      <p>
        If you create an account on the Website in the name of a legal entity,
        you represent that you have full corporate power and authority to enter
        into the Policy and Agreement on behalf of the legal entity and to bind
        the legal entity to the obligations in the Policy and Agreement. By
        using this Website and/or creating a user account, you represent and
        warrant that you meet all of the foregoing eligibility requirements. If
        you do not meet all of these requirements, you shall not be provided
        access to use the Website and you shall not otherwise access or use the
        Website.
      </p>
      <h4>6. No Investment Advice</h4>
      <p>
        THINKSABIO is not a registered investment adviser or broker-dealer and
        does not provide individualized securities advice. Rather, it is a
        subscription service that provides information about securities derived
        from public sources, including information publicly available from the
        Securities and Exchange Commission. THINKSABIO does not guarantee the
        accuracy of the information derived from third party sources.
        THINKSABIO's recommendations are based on the information from such
        sources, and are not tailored to meet any individual investor's
        investment needs or objectives. Users of this site are responsible for
        making their own trading decisions.
      </p>
      <h4>7. Technical Data; License to Your Data; Communications.</h4>
      <p>
        THINKSABIO shall have the right to collect and analyze data and other
        information relating to the provision, use and performance of the
        Website and related systems and technologies, and we will be free to (i)
        use such information and data to improve and enhance the Website and for
        other development, diagnostic, and corrective purposes in connection
        with the Website and other service offerings; (ii) disclose such data in
        aggregated or de-identified from in connection with its business; and
        (iii) otherwise use and disclose such data as set forth in our{' '}
        <a rel="noopener noreferrer" target="_blank" href="/privacy-policy">
          Privacy Policy
        </a>
        .
      </p>
      <p>
        In order for THINKSABIO to provide the Website and associated services,
        you grant us a non-exclusive right and license to use, copy, distribute,
        and transmit information entered by you in creating and registering for
        a user account on the Website as well as technical information collected
        via the Website to the extent necessary to provide the Website and our
        services.
      </p>
      <p>
        By providing your information via the Website, including while creating
        and registering for a user account, you authorize THINKSABIO, its
        affiliates, and their respective employees, agents, and contractors to
        initiate electronic communications by email, telephone calls to numbers
        (including to cellular phones) that are provided through the Website or
        as part of the registration process on the Website, and text messages
        (SMS or MMS) to cellular phone numbers that are provided through the
        Website or as part of registering and creating a user account to provide
        updates or other purposes related to the Website and services provided
        by THINKSABIO. Your carrier may charge for these incoming calls or
        messages. You consent to such communications, which may occur by use of
        an automatic telephone dialing system. Telephone calls may be recorded,
        standard data and message rates may apply for SMS and MMS alerts,
        whether you send or receive such messages, and you agree to be
        responsible for all such charges. Do not submit your information if you
        do not consent to being contacted by telephone, text, or email.
      </p>
      <h4>8. Changes to the Terms of Use</h4>
      <p>
        We may revise and update the Policy and Agreement from time to time in
        our sole discretion. All changes are effective immediately when we post
        them on the Website. Your continued use of the Website following the
        posting of revised Policy and Agreement means that you accept and agree
        to the changes. You are expected to check this page on the Website so
        you are aware of any changes, as they are binding on you. For certain
        types of updates and revisions, we may decide, in our sole discretion,
        to notify you of such changes via email or other method of written
        notice.{' '}
      </p>
      <h4>9. Website Ownership and Intellectual Property Rights</h4>
      <p>
        The Website and its entire contents, features, functionality, and
        services (including but not limited to all information, software, text,
        displays, images, video and audio, and the design, selection and
        arrangement thereof), are owned by and the exclusive property of
        THINKSABIO, its licensors or other providers of such material and are
        protected by United States and international copyright, trademark,
        patent, trade secret and other intellectual property or proprietary
        rights laws. You acknowledge and agree that you do not acquire any
        ownership rights to the Website, or any services offered through the
        Website, through the Policy and Agreement, or by use of the Website, and
        no use or other rights are granted with respect to these trademarks
        without the express, written approval of THINKSABIO.
      </p>
      <p>
        Additionally, logos, trademarks and service marks of third parties may
        also appear on this Website, and such are the exclusive property of
        their respective owners and no use or other rights are granted with
        respect to these trademarks without the express, written approval of the
        trademark/service mark owner. You may view, copy and print pages from
        the Website only: (1) for personal use, provided that you maintain all
        copyright, trademark, and other proprietary rights or notices; or (2)
        for communicating with THINKSABIO about a company product or service.
        You may not otherwise use, reproduce, download, store, post, broadcast,
        transmit, modify, sell or make available to the public content from the
        Website without the prior written approval of THINKSABIO.
      </p>
      <p>
        You acknowledge and agree that THINKSABIO has and retains exclusive and
        valid ownership of all anonymized statistical information regarding use
        of the Website. Any unauthorized use, reproduction, modification,
        distribution, transmission, republication, display or performance of the
        Website and any component thereof is strictly prohibited.
      </p>
      <p>
        If you print, copy, modify, download, or otherwise use or provide any
        other person with access to any part of the Website in breach of the
        Policy and Agreement, your right to use the Website will cease
        immediately and you must, at our option, return or destroy any copies of
        the materials you have made. No right, title or interest in or to the
        Website or any content on the Website is transferred to you, and all
        rights not expressly granted are reserved by THINKSABIO. Any use of the
        Website not expressly permitted by the Policy and Agreement is a breach
        of the Policy and Agreement and may violate copyright, trademark, and
        other laws.
      </p>
      <h4>10. Making Copyright Infringement Claims</h4>
      <p>
        THINKSABIO respects the rights of copyright holders. If you believe that
        content you own has been copied and made accessible in a manner that
        violates your intellectual property rights, please notify us
        immediately. You may submit a notification pursuant to the Digital
        Millennium Copyright Act (see 17 U.S.C. § 512(c)(3)) to our copyright
        agent at{' '}
        <a href="mailto:support@thinksabio.com">support@thinksabio.com</a>
      </p>
      <h4>11. Limited Right to Use</h4>
      <p>
        Subject to the terms and conditions of the Policy and Agreement,
        THINKSABIO grants you a limited, nonexclusive, non-transferable,
        non-sublicensable (except as otherwise provided herein) license to
        access and use the Website for use solely by User for User’s own
        personal use and not for republication, distribution, assignment,
        sublicense, sale, preparation of derivative works or other use. All
        rights with respect to the Website not explicitly granted herein are
        reserved to THINKSABIO.
      </p>
      <p>
        You agree not to reproduce, copy, duplicate, sell, resell or exploit for
        any commercial purposes, any portion of the Website, use of the Website,
        or access to the Website. You also agree not to forward, disseminate, or
        resell the contents of the Website without permission from THINKSABIO.
      </p>
      <h4>12. User Conduct</h4>
      <p>
        You may use the Website only for lawful purposes and in accordance with
        the Policy and Agreement. Transmitting, distributing, or storing any
        material that violates any applicable law or the Policy and Agreement is
        prohibited. You agree not to use the Website:
      </p>
      <ul>
        <li>
          <p>
            In any way that violates any applicable federal, state, local or
            international law or regulation (including, without limitation, ay
            laws regarding the export of data or software to and from the United
            States or other countries), or promote any illegal activity, or
            advocate, promote or assist any unlawful act.
          </p>
        </li>
        <li>
          <p>
            To transmit, or procure the sending of, any advertising or
            promotional material, including any “spam” or any other similar
            solicitation or post any material which is defamatory, obscene,
            indecent, abusive, offensive, harassing, violent, hateful,
            inflammatory, invasive of privacy or publicity rights, or otherwise
            objectionable.
          </p>
        </li>
        <li>
          <p>
            To impersonate or attempt to impersonate THINKSABIO, a THINKSABIO
            employee, another user or any other person or entity (including,
            without limitation, by using email addresses identifying information
            associated with any of the foregoing).
          </p>
        </li>
        <li>
          <p>
            To make any representations that are likely to deceive any person or
            give any impression that materials or content emanate from or are
            endorsed by us or any other person or entity, if this is not the
            case.
          </p>
        </li>
        <li>
          <p>
            To engage in any other conduct that restricts or inhibits anyone’s
            use or enjoyment of the Website, or which, as determined by us, may
            harm THINKSABIO or users of the Website or expose them to liability.
          </p>
        </li>
        <li>
          <p>
            To infringe any patent, trademark, trade secret, copyright or other
            intellectual property or other rights of any other person.
            Infringement may result from the unauthorized copying, distribution
            and/or posting of pictures, logos, software, articles, musical
            works, and videos.
          </p>
        </li>
        <li>
          <p>
            To post any content that violates the legal rights of others or
            contains any material that could give rise to any civil or criminal
            liability under applicable laws or regulations or that otherwise may
            be in conflict with the Policy and Agreement.
          </p>
        </li>
        <li>
          <p>
            For competitive research or to send or post commercial
            communications.
          </p>
        </li>
        <li>
          <p>To advertise or perform any commercial solicitation.</p>
        </li>
        <li>
          <p>
            To violate any U.S. export laws, including, without limitation,
            violations of the Export Administration Act and the Export
            Administration Regulations administered by the Department of
            Commerce.
          </p>
        </li>
        <p>Additionally, you agree not to:</p>
        <li>
          <p>
            Directly, indirectly, alone, or with another party, (i) copy,
            download, disassemble, reverse engineer, or decompile the Software
            or otherwise attempt to discover the source code or underlying ideas
            or algorithms of the Software; (ii) modify, create derivative works
            based upon, or translate the Software; (iii) transfer or otherwise
            grant any rights in the Software in any form to any other party, nor
            shall you attempt to do any of the foregoing or cause or permit any
            third party to do or attempt to do any of the foregoing, except as
            expressly permitted hereunder.
          </p>
        </li>
        <li>
          <p>
            Use the Website in any manner that could disable, overburden,
            damage, or impair the Website or interfere with any other party’s
            use of the Website, including their ability to engage in real time
            activities through the Website.
          </p>
        </li>
        <li>
          <p>
            Use any robot, spider or other automatic device, process or means to
            access the Website for any purpose, including monitoring or copying
            of the material on the Website.
          </p>
        </li>
        <li>
          <p>
            Use any manual process to monitor or copy any of the material on the
            Website or for any other unauthorized purpose without our prior
            written consent.
          </p>
        </li>
        <li>
          <p>
            Use any device, software, or routine that interferes with the proper
            working of the Website.
          </p>
        </li>
        <li>
          <p>
            Introduce any viruses, trojan horses, worms, logic bombs, keystroke
            logger, or other material which is malicious or technologically
            harmful.
          </p>
        </li>
        <li>
          <p>
            Attempt to gain unauthorized access to, interfere with, damage or
            disrupt any parts of the Website, the server on which the Website is
            stored, or any server, computer or database connected to or
            associated with the Website.
          </p>
        </li>
        <li>
          <p>
            Attack the Website via a denial-of-service attack or a distributed
            denial-of-service attack.
          </p>
        </li>
        <li>
          <p>Screen scrape, monitor, mine, copy, or mirror the Website.</p>
        </li>
        <li>
          <p>
            Conduct any systematic or automated data collection activities
            (including without limitation scraping, data mining, data
            extraction, and data harvesting) on or in relation to the Website
            without our express written consent.
          </p>
        </li>
        <li>
          <p>
            Otherwise attempt to interfere with the proper working of the
            Website.
          </p>
        </li>
      </ul>
      <h4>13. User Submissions</h4>
      <p>
        You may transmit or publish content through the Website. You understand
        and acknowledge that you, not THINKSABIO, shall be solely responsible
        for such content, including its legality, reliability, accuracy, and
        appropriateness, and the consequences of its transmission or
        publication. You represent and warrant that you own and control all
        rights in and to any content posted by you on the Website or are
        licensed to use and reproduce such content on the Website.
      </p>
      <p>
        If you make any copyrighted content available on the Website, you affirm
        that you have the authorization to make such content available. Further,
        by making any content available, you expressly agree that THINKSABIO
        will have the right to block access to or remove such content for any or
        no reason in its sole discretion.
      </p>
      <p>
        Any communication or material you transmit to THINKSABIO through this
        Website, by electronic mail or otherwise, including any data, questions,
        comments, ideas, suggestions, or the like is, and will be treated as,
        non-confidential and non-proprietary. Anything you transmit or post may
        be used by THINKSABIO for any purpose, including, but not limited to,
        reproduction, disclosure, transmission, publication, broadcast and
        posting. Furthermore, THINKSABIO is free to use any ideas, concepts,
        know-how, techniques or other information contained in any communication
        you send us for any purpose whatsoever including, but not limited to,
        developing, manufacturing and marketing products using such information.
      </p>
      <p>
        THINKSABIO will not compensate you in any way for any such information,
        nor will the information be returned to you. Any such submission
        constitutes your irrevocable assignment and transfer, without additional
        consideration, to THINKSABIO of all right, title and interest in all
        information included in the submission, including, but not limited to,
        all copyrights, trademarks and/or other intellectual property rights.
      </p>
      <h4>14. Chat Functionality</h4>
      <p>
        THINKSABIO may offer the ability for your to utilize a Chat function to
        interact with other Website members. All information and communications
        entered in the Chat must comply with these Terms of Use, including
        without limitation Section 12 User Conduct, and the provisions of this
        Section. THINKSABIO reserves the right to restrict or remove any User
        from the Chat function, or to deny access to the Website altogether,
        without notice, if the User violates any provision of these Terms of
        Use. No credits or refunds will be given to any User who has had access
        restricted, removed, or denied by THINKSABIO. Additionally, Users shall
        not reproduce, copy, duplicate, sell, resell or exploit for any
        commercial purposes, any information or functionality of the Chat
        function. You also agree not to forward, disseminate, or resell any
        information from the Chat function without permission from THINKSABIO.
        You may only use the Chat function for lawful purposes and in accordance
        with THINKSABIO’s Privacy Policy and Terms of Use. Transmitting,
        distributing, or storing any material that violates any applicable law
        or the Policy and Agreement is prohibited. THINKSABIO reserves the right
        to moderate, or delete any post or comments in its sole discretion. You
        agree not to use the Chat function:
      </p>
      <ul>
        <li>
          <p>
            In any way that violates any applicable federal, state, local or
            international law or regulation (including, without limitation, ay
            laws regarding the export of data or software to and from the United
            States or other countries), THINKSABIO’s Terms of Use (including the
            User Conduct provisions), or THINKSABIO’s Privacy Policy.
          </p>
        </li>

        <li>
          <p>
            To transmit, or procure the sending of, any advertising or
            promotional material, including any “spam” or any other similar
            solicitation or post any material which is defamatory, obscene,
            indecent, abusive, offensive, harassing, violent, hateful,
            inflammatory, invasive of privacy or publicity rights, or otherwise
            objectionable.
          </p>
        </li>

        <li>
          <p>
            To impersonate or attempt to impersonate THINKSABIO, a THINKSABIO
            employee, another user or any other person or entity (including,
            without limitation, by using email addresses identifying information
            associated with any of the foregoing).
          </p>
        </li>

        <li>
          <p>
            To make any representations that are likely to deceive any person or
            give any impression that materials or content emanate from or are
            endorsed by us or any other person or entity, if this is not the
            case.
          </p>
        </li>

        <li>
          <p>
            To engage in any other conduct that restricts or inhibits anyone’s
            use or enjoyment of the Chat functionality, or which, as determined
            by us, may harm THINKSABIO or users of the Chat functionality or
            expose them to liability.
          </p>
        </li>

        <li>
          <p>
            To infringe any patent, trademark, trade secret, copyright or other
            intellectual property or other rights of any other person.
            Infringement may result from the unauthorized copying, distribution
            and/or posting of pictures, logos, software, articles, musical
            works, and videos.
          </p>
        </li>

        <li>
          <p>
            To post any content that violates the legal rights of others or
            contains any material that could give rise to any civil or criminal
            liability under applicable laws or regulations or that otherwise may
            be in conflict with the Policy and Agreement.
          </p>
        </li>

        <li>
          <p>
            For competitive research or to send or post commercial
            communications.
          </p>
        </li>

        <li>
          <p>To advertise or perform any commercial solicitation.</p>
        </li>

        <li>
          <p>
            To violate any U.S. export laws, including, without limitation,
            violations of the Export Administration Act and the Export
            Administration Regulations administered by the Department of
            Commerce.
          </p>
        </li>
      </ul>
      <p>
        Additionally, you agree to comply with the following Chat Function
        Guidelines:{' '}
      </p>
      <ul>
        <li>
          <p>
            Do not verbally abuse, attack, embarrass, or threaten anyone else in
            the chat room, no matter what they might say to you.
          </p>
        </li>
        <li>
          <p>
            Do not use harmful, abusive, obscene, offensive, or sexually
            explicit language.
          </p>
        </li>
        <li>
          <p>Do not post, promote, or distribute content that is illegal.</p>
        </li>
        <li>
          <p>
            Do not harass, threaten, embarrass, or do anything else to another
            chatter that is unwanted. This means: don’t say bad things about
            them; don’t attack their character, race, heritage, etc. If you
            disagree with someone, respond to the subject, not the person.
          </p>
        </li>
        <li>
          <p>
            Do not disrupt the flow of chat in chat rooms with vulgar language,
            abusiveness, hitting the return key repeatedly or inputting large
            streams of text so the screen goes by too fast to read, etc.
          </p>
        </li>
        <li>
          <p>
            Do not pretend to be anyone whom you are not. You may not
            impersonate another chatter, or any THINKSABIO employee or
            administrator.
          </p>
        </li>
        <li>
          <p>
            Foul language, will not be tolerated unless all letters of the word
            are replaced by substitute characters. Example ($#!^ is acceptable.
            Sh!t is not. In no way should there EVER be letters or numbers
            used.) Also prolific foul language use, such as #*()$% ^!*()# *^()
            it all to #@**, will not be tolerated either, regardless of covering
            up the entire word.
          </p>
        </li>
        <li>
          <p>
            If someone is sharing any content/message/opinions which you may
            disagree with, it is okay to disagree constructively but no hate
            speech or personal attacks are allowed.
          </p>
        </li>
        <li>
          <p>
            Never type your messages in using all capital letters. This is
            considered to be shouting or yelling at another person, and this is
            not acceptable. If you want to *emphasize* a word or phrase, simply
            type an asterisk in before and then directly after your word or
            phrase.
          </p>
        </li>
        <li>
          <p>
            Posting content/Ideas that are available on any social media or
            other websites is allowed but NOT promoting their landing pages or
            posting information about any individual person.
          </p>
        </li>
        <li>
          <p>
            Always cooperate with all THINKSABIO employees or administrators.
          </p>
        </li>
        <li>
          <p>
            Anyone caught coming back in a chat room under a different username
            after being banned will be immediately denied access to the Website.
          </p>
        </li>
      </ul>
      <p>
        All information shared via the Chat Function is strictly for educational
        and illustrative purposes only and doesn't imply any recommendation or
        buy or sell signals. You are solely responsible for your investment
        decisions. All investments involves risk, losses may exceed the
        principal invested, and the past performance of a security, industry,
        sector, market, or financial product does not guarantee future results
        or returns.
      </p>
      <h4>15. Reliance on Information Posted</h4>
      <p>
        The information presented on or through the Website is made available
        solely for general information purposes. The content on the Website may
        be updated from time to time. It may not necessarily be complete or
        up-to-date at any given time. We do not warrant the accuracy,
        completeness or usefulness of this information. Any reliance you place
        on such information is strictly at your own risk. We disclaim all
        liability and responsibility arising from any reliance placed on such
        materials by you or any other visitor to the Website, or by anyone who
        may be informed of any of its contents.
      </p>
      <p>
        This Website includes content provided by third parties, including
        materials provided by other users. All statements and/or opinions
        expressed in these materials, and all articles and responses to
        questions and other content, other than the content provided by
        THINKSABIO, are solely the responsibility of the person or entity
        providing those materials. We are not responsible, or liable to you or
        any third party, for the content or accuracy of any materials provided
        by any third parties.
      </p>
      <h4>16. Linking To The Website And Content</h4>
      <p>
        To ensure that information regarding THINKSABIO products and services is
        communicated accurately to the public, please adhere to the following
        linking guidelines:
      </p>
      <p>
        You may not hyperlink to our Website or use any trademark, logo or other
        proprietary graphic appearing on this Website or that is owned by or
        licensed to THINKSABIO to link to the Website (or for any other purpose)
        without our prior written approval;
      </p>
      <p>THINKSABIO does not approve of any deep linking;</p>
      <p>
        THINKSABIO does not approve of inline linking or framing. Do not import
        content from the Website or link to images on servers on which the
        Website is hosted for the purpose of placing the content or images on
        any other web pages or sites; and
      </p>
      <p>THINKSABIO does not approve of remote or hot linking.</p>
      <h4>17. Payment and Cancellation </h4>
      <p>
        <b>17.1 Pricing. </b> THINKSABIO may offer free access to the Website,
        or may charge a subscription fee pursuant to its then-current pricing.
        THINKSABIO may change listed prices at any time, including changing from
        a free service to a paid service and charging for access to or use of
        the Website that was previously offered free of charge; provided,
        however, that THINKSABIO will provide you with prior notice and an
        opportunity to terminate this Agreement if THINKSABIO changes the price
        of a service to which you are subscribed and will not charge you for a
        previously free service unless you have been notified of the applicable
        fees and agreed to pay such fees.
      </p>
      <p>
        <b>17.2 Taxes. </b> You shall pay when due (and THINKSABIO at its
        discretion may collect and pay on your behalf) all taxes, levies, or
        assessments based on or in any way measured by this Agreement, the
        Website, and the services provided hereunder, excluding taxes based on
        THINKSABIO’s net income, but including sales and use taxes and personal
        property taxes, if any.
      </p>
      <p>
        <b>17.3 Payment. </b> Amounts due hereunder shall be paid in the manner
        established during registration or as subsequently established by access
        to your registration through the Website. If applicable, you authorize
        THINKSABIO to charge or debit automatically, using your selected payment
        method, all such amounts. You are responsible for providing to
        THINKSABIO and maintaining through the registration complete and
        accurate billing and contact information. All THINKSABIO subscriptions
        are billed on a recurring monthly or annual basis. User will be
        automatically billed each month or year until you manually cancel the
        subscription. THINKSABIO does not offer refunds, returns, or other
        credits for any payments, including for partial or unused months of a
        subscription. If purchasing a subscription from THINKSABIO for the first
        time, please make sure that the subscription is correct before
        proceeding. If you change to a different plan, remaining days are
        converted into an equivalent value of days on the new subscription.
        THINKSABIO does not offer refunds for any automatic recurring payments.
        If you have been billed for automatic renewal of the subscription, you
        may cancel the subscription to avoid billing for the next renewal date.
      </p>
      <p>
        <b>17.4 Cancellations. </b> You are responsible for canceling your own
        subscription. However, cancellations may only be submitted to THINKSABIO
        via methods designed by THINKSABIO.
      </p>
      <h4>18. DISCLAIMER OF WARRANTIES</h4>
      <p>
        THE WEBSITE, CONTENT, AND ALL MATERIALS ARE PROVIDED ON AN “AS IS” AND
        “AS AVAILABLE” BASIS, AND YOU ASSUME THE ENTIRE RISK AS TO THE QUALITY
        AND PERFORMANCE OF THE WEBSITE, THE CONTENT, AND ALL MATERIALS, YOUR USE
        OF THE WEBSITE, THE CONTENT, OR MATERIALS OBTAINED THROUGH THEM IS AT
        YOUR OWN RISK. THINKSABIO, ITS AGENTS, AND ITS LICENSORS DO NOT WARRANT
        THE ACCURACY, COMPLETENESS, CURRENCY, NONINFRINGEMENT, MERCHANTABILITY
        OR FITNESS FOR A PARTICULAR PURPOSE OF ANY CONTENT OR INFORMATION
        AVAILABLE THROUGH THE WEBSITE. YOU ACCESS SUCH CONTENT OR INFORMATION AT
        YOUR OWN RISK
      </p>
      <p>
        WE DISCLAIM ALL WARRANTIES, CONDITIONS, AND REPRESENTATIONS OF ANY KIND
        (EXPRESS OR IMPLIED, ORAL OR WRITTEN, STATUTORY OR OTHERWISE) WITH
        RESPECT TO THE WEBSITE, THE CONTENT, OR ANY PART THEREOF, INCLUDING,
        WITHOUT LIMITATION, ANY AND ALL IMPLIED WARRANTIES OR CONDITIONS OF
        TITLE, NON-INFRINGEMENT, MERCHANTABILITY, FITNESS OR SUITABILITY FOR ANY
        PURPOSE (WHETHER OR NOT WE KNOW, HAVE REASON TO KNOW, HAVE BEEN ADVISED,
        OR OTHERWISE IN FACT AWARE OF ANY SUCH PURPOSE), WHETHER ALLEGED TO
        ARISE BY LAW, BY REASON OF CUSTOM, OR USAGE IN THE TRADE, BY COURSE OF
        DEALING, OR OTHERWISE NEITHER US OR NOR ANY PERSON ASSOCIATED WITH US
        MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
        SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE
        OR THE CONTENT.
      </p>
      <p>
        YOU UNDERSTAND THAT YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
        COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM ANY MATERIAL
        DOWNLOADED FROM OR OTHERWISE PROVIDED THROUGH THE WEBSITE. YOU
        UNDERSTAND THAT WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES
        AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE WEBSITE WILL BE FREE
        OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE RESPONSIBLE FOR
        IMPLEMENTING SUFFICIENT PROCEDURES AND CHECKPOINTS TO SATISFY YOUR
        PARTICULAR REQUIREMENTS FOR ANTI-VIRUS PROTECTION AND ACCURACY OF DATA
        INPUT AND OUTPUT, AND FOR MAINTAINING A MEANS EXTERNAL TO OUR WEBSITE
        FOR ANY RECONSTRUCTION OF LOST DATA. FURTHERMORE, THINKSABIO DOES NOT
        REPRESENT OR WARRANT THAT THIS WEBSITE, THE CONTENT OR INFORMATION ON
        THIS WEBSITE AND/OR ITS FILES WILL BE SECURE AND/OR COMPATIBLE WITH YOUR
        BROWSER OR OTHER WEB SITE-ACCESSING PROGRAM. THINKSABIO DOES NOT
        GUARANTEE THAT ITS WEBSITE WILL BE ERROR-FREE, OR CONTINUOUSLY
        AVAILABLE, OR THAT IT WILL BE FREE OF VIRUSES OR OTHER HARMFUL
        COMPONENTS
      </p>
      <p>
        TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY
        LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
        RANSOMWARE, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY
        INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER
        PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR
        ITEMS OBTAINED THROUGH THE WEBSITE OR DUE TO YOUR DOWNLOADING OF ANY
        CONTENT POSTED ON IT, OR ON ANY SITE LINKED TO IT.
      </p>
      <p>
        THE INFORMATION PRESENTED ON OR THROUGH THE WEBSITE IS MADE AVAILABLE
        SOLELY FOR INFORMATIONAL PURPOSES. WE USE REASONABLE EFFORTS TO UPDATE
        THE INFORMATION ON THE WEBSITE, AND THE CONTENTS OF THE WEBSITE ARE
        SUBJECT TO CHANGE WITHOUT NOTICE. THINKSABIO DOES NOT WARRANT THE
        ACCURACY, COMPLETENESS, OR USEFULNESS OF THIS INFORMATION (INCLUDING ANY
        PRODUCT, SERVICE, DESCRIPTION, PHOTOGRAPH, FUNDRAISING CAMPAIGN, OR
        OTHER INFORMATION). ANY RELIANCE PLACED ON SUCH INFORMATION IS STRICTLY
        AT YOUR OWN RISK. THINKSABIO DISCLAIMS ALL LIABILITY AND RESPONSIBILITY
        ARISING FROM ANY RELIANCE PLACED ON SUCH MATERIALS BY YOU OR ANY OTHER
        USER OF THE WEBSITE.
      </p>
      <p>
        THE FOREGOING DOES NOT AFFECT ANY WARRANTY THAT CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW. IN SUCH INSTANCES, THE LIABILITY OF
        THINKSABIO, AND ITS AFFILIATES, AGENTS AND LICENSORS IS LIMITED TO THE
        FULLEST EXTENT PERMITTED BY SUCH LAW.
      </p>
      <h4>19. LIMITATION ON LIABILITY</h4>
      <p>
        UNDER NO CIRCUMSTANCES WILL THINKSABIO, ITS AFFILIATES OR THEIR
        LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS
        BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES THAT ARISE FROM THE USE
        OF THIS WEBSITE. THIS IS A COMPREHENSIVE LIMITATION OF LIABILITY THAT
        APPLIES TO ALL DAMAGES OF ANY KIND (FOR EXAMPLE, COMPENSATORY, SPECIAL,
        DIRECT, INDIRECT, PUNITIVE, OR CONSEQUENTIAL DAMAGES, LOSS OF DATA,
        INCOME OR PROFIT, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, INCREASED
        COSTS, DIMINUTION IN VALUE, LOSS OF USE, LOSS OF GOODWILL OR REPUTATION,
        USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY OR RECOVERY OF ANY
        DATA, OR BREACH OF DATA OR SYSTEM SECURITY, LOSS OF OR DAMAGE TO
        PROPERTY, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, AND
        CLAIMS OF THIRD PARTIES) WHETHER IN BREACH OF CONTRACT, TORT (INCLUDING
        NEGLIGENCE), STRICT LIABILITY, MISREPRESENTATIONS OR UNDER NAY OTHER
        LEGAL THEORY, EVEN IF WE ARE ADVISED BEFOREHAND OF THE POSSIBILITY OF
        SUCH DAMAGES. NOTWITHSTANDING THE FOREGOING, IN THE EVENT YOU ARE
        ENTITLED TO DAMAGES FOR ANY CLAIM ARISING FROM THE USE OF THIS WEBSITE,
        YOU AGREE THAT THE AGGREGATE LIABILITY OF THINKSABIO, ITS AFFILIATES OR
        THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR
        DIRECTORS, WILL NOT EXCEED THE GREATER OF AMOUNT YOU SPECIFICALLY PAID
        TO ACCESS THIS WEBSITE OR $100.00. BECAUSE SOME STATES DO NOT ALLOW THE
        EXCLUSION OR LIMITATION OF CERTAIN CATEGORIES OF DAMAGES, THE ABOVE
        LIMITATION MAY NOT APPLY TO YOU. IN SUCH STATES, THE LIABILITY OF
        THINKSABIO, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS,
        EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS IS LIMITED TO THE FULLEST
        EXTENT PERMITTED BY SUCH STATE LAW.
      </p>
      <h4>20. Indemnification</h4>
      <p>
        You agree to indemnify, defend, and hold harmless THINKSABIO and our
        successors and assigns and all of their respective officers, directors,
        agents, employees, and affiliates from and against any claims,
        liabilities, judgments, awards, losses, obligations, costs, expenses, or
        fees (including reasonable attorneys’ fees) arising out of or related to
        (i) your breach of the Policy and Agreement; (ii) your violation of any
        third party’s right including, without limitation, any copyright,
        trademark, property right, or privacy right, (iii) any violation of
        applicable law by you; (iv) information or content that you submit,
        post, transmit or make available through our Website; (v) your use of
        the Website; (vi) any claim that content provided by you caused damage
        or loss to a third party, or (vii) any of your acts or omissions. This
        defense and indemnification obligation will survive your use of the
        Website and any termination of the Policy and Agreement.
      </p>
      <h4>21. Governing Law and Jurisdiction</h4>
      <p>
        This Policy and Agreement is governed by and must be construed under the
        laws of the State of North Carolina, without regard to principles of
        conflicts of laws. By offering this Website and its contents, no
        solicitation is made by THINKSABIO to any person or entity to access
        and/or use this Website and its contents in jurisdictions where the
        provision of such Website and/or its contents is/are prohibited by law.
        If you elect to access, browse and/or use this Website and its contents
        from outside of the United States, you do so on your own initiative and
        you are responsible for compliance with local laws.
      </p>
      <p>
        All matters relating to the Website and the Policy and Agreement and any
        dispute or claim arising therefrom or related thereto (in each case,
        including non-contractual disputes or claims), shall be governed by and
        construed in accordance with the internal laws of the North Carolina
        without giving effect to any choice or conflict of law provision or rule
        (whether of the State of North Carolina or any other jurisdiction). Ay
        claims or actions regarding or arising out of the Policy and Agreement
        must be brought exclusively in a court of competent jurisdiction sitting
        in North Carolina, and each party to this Agreement submits to the
        jurisdiction of such courts for the purposes of all legal actions and
        proceedings arising out of or relating to the Policy and Agreement.
        Specifically, THINKSABIO and you specifically waive, to the fullest
        extent permitted by law, any objection that it may now or hereafter have
        to the laying of venue of any such action in such court and any claim
        that any such action, suit, or proceeding has been brought in an
        inconvenient forum.
      </p>
      <h4>22. LIMITATION ON TIME TO FILE CLAIMS</h4>
      <p>
        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
        THE POLICY AND AGREEMENT OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1)
        YEAR FROM THE DATE THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF
        ACTION IS PERMANENTLY WAIVED AND BARRED.
      </p>
      <h4>23. Waiver and Severability</h4>
      <p>
        No waiver by THINKSABIO of any term or condition set forth in the Policy
        and Agreement shall be deemed a further or continuing waiver of such
        term or condition or a waiver of any other term or condition, and any
        failure of THINKSABIO to assert a right or provision under the Policy
        and Agreement shall not constitute a waiver of such right or provision.
      </p>
      <p>
        If any provision of the Policy and Agreement is held by a court or other
        tribunal of competent jurisdiction to be invalid, illegal or
        unenforceable for any reason, such provision shall be eliminated or
        limited to the maximum extent such that the remaining provision of the
        Policy an Agreement will continue in full force and effect.
      </p>
      <h4>24. Entire Agreement</h4>
      <p>
        These Policy and Agreement constitute the sole and entire agreement
        between you and us with respect to the Website and supersede all
        previously written or oral agreements regarding the Website. However,
        the Policy and Agreement do not supersede or amend any other agreement
        you have with us.
      </p>
      <h4>25. Modification</h4>
      <p>
        We reserve the right to modify this Policy and Agreement at any time,
        effective upon its posting, as modified, on this Website. You agree to
        the Policy and Agreement, including any modifications, by accessing or
        using our Website. We may also terminate your access to this Website at
        any time and for any reason, with or without notice.
      </p>
      <h4>26. Miscellaneous</h4>
      <p>
        Neither the course of conduct between the parties nor trade practice
        will act to modify any provision in this Policy and Agreement.
        THINKSABIO may assign its rights and duties under these terms to any
        party at any time without notice to you. If any provision of this
        Agreement is deemed unlawful, void, or for any reason unenforceable,
        then that provision will be deemed severable from these terms and
        conditions so that it does not affect the validity and enforceability of
        any remaining provisions.
      </p>
      <h4>27. Privacy Policy</h4>
      <p>
        THINKSABIO respects the privacy of visitors to our Website. Please take
        a few minutes to review our{' '}
        <a rel="noopener noreferrer" target="_blank" href="/privacy-policy">
          Privacy Policy
        </a>
        .
      </p>
      <h4>28. Questions and Comments</h4>
      <p>
        We invite you to send in your questions or comments about our site,
        bring to our attention any material you believe to be inaccurate, or
        report any violations of the Policy and Agreement.
      </p>
      <p>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="mailto:support@thinksabio.com"
        >
          support@thinksabio.com
        </a>
      </p>
      <p>© 2021 THINKSABIO, INC.</p>
    </div>
  );
};

export default UserTermsAndConditions;
