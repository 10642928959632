import React from 'react';

import { get } from 'lodash';
import moment from 'moment';
import {
  LineChart,
  YAxis,
  XAxis,
  // ReferenceLine,
  Line,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import { green, currencyFormat } from '../../../utils';

interface Props {
  data: Array;
}

const LineGraph: React.FC<Props> = ({ data }) => {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const value = get(payload, '0.payload.value', 100);
      const time = get(payload, '0.payload.time');
      return (
        <div className="custom-tooltip" style={{ width: 150 }}>
          <p
            style={{
              fontSize: 10,
              marginBottom: 5,
            }}
            className="label"
          >{`Value: ${currencyFormat(value / 100)}`}</p>
          <p
            style={{
              fontSize: 10,
            }}
            className="label"
          >{`Time: ${moment
            .unix(time)
            .utc()
            .format('MMM DD, YYYY hh:mm A')}`}</p>
        </div>
      );
    }

    return null;
  };
  const width = data.length * (100 / 64);
  const values = data.map((d) => parseInt(d.value));
  const min = Math.min(...values);
  const max = Math.max(...values);

  // const PRE = data.filter((d) => d.market === 'open');
  // const POST = data.filter((d) => d.market === 'post');
  // console.log('PRE', PRE[0], data[22]);
  // console.log('POST', POST);
  // console.log('data', data);
  // console.log('min', min, max);
  return (
    <>
      <ResponsiveContainer width={`${width}%`} height={150}>
        <LineChart data={data}>
          {/* <XAxis dataKey="time" /> */}

          <Tooltip
            formatter={(value) => currencyFormat(value)}
            contentStyle={{
              backgroundColor: 'white',
            }}
            label={false}
            content={<CustomTooltip />}
          />
          {/* {PRE.length && !POST.length && (
            <ReferenceLine x={PRE[0].value} stroke={green} />
          )} */}
          <XAxis dataKey="value" />
          <Line
            type="monotone"
            dataKey="value"
            stroke={green}
            strokeWidth={2}
            dot={false}
            isAnimationActive={true}
          />
          <YAxis hide={true} domain={[min, max]} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default LineGraph;
