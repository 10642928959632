import React from 'react';

import { Link } from 'react-router-dom';

import styles from './style.module.scss';

const StaticFooter: React.FC<Props> = () => {
  return (
    <div className={styles.footerMainBar}>
      <p className={styles.disclaimerText}>
        <b>*Disclaimer: </b> ThinkSabio is not a registered investment adviser
        or broker-dealer and does not provide individualized securities advice.
        Rather, it is a subscription service that provides information about
        securities derived from public sources, including information publicly
        available from the Securities and Exchange Commission. ThinkSabio does
        not guarantee the accuracy of the information derived from third party
        sources. ThinkSabio's recommendations are based on the information from
        such sources, and are not tailored to meet any individual investor's
        investment needs or objectives. Users of this site are responsible for
        making their own trading decisions.
      </p>
      <div className={styles.homeFooterContent}>
        <p>ThinkSabio © 2021</p>
        <ul>
          <li>
            <Link to="/tnc">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact Us</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default StaticFooter;
