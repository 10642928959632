import React, { useState } from 'react';

import { SettingOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { get } from 'lodash';

import { Table, ColumnVisibility, Pagination } from '../../../components';
import { useTheme } from '../../../ThemeContext';
import {
  green,
  noOfQtrList,
  formatColumn,
  formatTableData,
  formatNumber2,
  formatTableData2,
} from '../../../utils';
import styles from './style.module.scss';

const { Option } = Select;
// const searchType = {
//   1: 'By Institution',
//   2: 'By Sector',
// };

interface Props {
  data: Object;
  selectedKey: number;
  isLoading: boolean;
  history: Object;
  tableColumns: any;
  setTableColumns: Function;
  onNoQtrsChange: Function;
  showNoOfQtrs: boolean;
  noOfQtrs: number;
  healthCheckType: string;
  showPagination: boolean;
  pageSize: number;
  pageNo: number;
  onPaginationChange: Function;
}

const HealthCheckTable: React.FC<Props> = ({
  data,
  isLoading,
  history,
  tableColumns,
  setTableColumns,
  onNoQtrsChange,
  showNoOfQtrs,
  noOfQtrs,
  healthCheckType,
  showPagination,
  pageSize,
  pageNo,
  onPaginationChange,
}) => {
  const { theme } = useTheme();
  const [openColumnFilter, setOpenColumnFilter] = useState<boolean>(false);

  const onColumnFilter = (filteredColumns) => {
    setTableColumns(filteredColumns);
  };
  const tableData = get(data, 'data', []);
  const total = get(data, 'total', tableData.length);
  return (
    <div
      className={styles.tableContainer}
      id={healthCheckType}
      style={
        healthCheckType === 'sector'
          ? {
              maxWidth: 600,
              margin: 'auto',
            }
          : {}
      }
    >
      <div className={styles.vHealthTitleBarNew}>
        {showNoOfQtrs && (
          <div className={styles.reportOptionBlock}>
            <label
              style={{ color: green }}
              className={styles.vlabelfull}
            >{`#Qtrs: `}</label>
            <Select
              defaultValue={noOfQtrs}
              style={{ width: 60 }}
              onChange={(value) => onNoQtrsChange(value)}
              placeholder="Select No of Quarter"
              disabled={isLoading}
              className={styles.vSelectSearchnew}
            >
              {noOfQtrList.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </div>
        )}
        <Button
          icon={<SettingOutlined />}
          size={20}
          onClick={() => {
            setOpenColumnFilter(!openColumnFilter);
          }}
          disabled={isLoading}
        >
          Column Visibility
        </Button>
      </div>
      <Table
        scroll={{ x: true }}
        columns={formatColumn(
          (tableColumns || []).filter(
            (col) => col.visible !== false && col.value,
          ),
          false,
          [],
          history,
          false,
          false,
          'health_check',
          null,
          null,
          theme,
        )}
        dataSource={
          healthCheckType === 'fav_institutions'
            ? formatTableData2(tableData)
            : formatTableData(tableData)
        }
        pagination={false}
        size="small"
        bordered={true}
        loading={isLoading}
        id="allPicksId"
      />
      {showPagination ? (
        <Pagination
          pageSize={pageSize}
          total={total}
          pageNo={pageNo}
          onChange={onPaginationChange}
          loading={isLoading}
          showPagination={true}
          hideDisclaimer={true}
        />
      ) : (
        total > 0 && (
          <p className={styles.showingResultText}>
            {`Showing 1 to ${total} of ${formatNumber2(total)} entries`}{' '}
          </p>
        )
      )}

      {openColumnFilter && (
        <ColumnVisibility
          visible={openColumnFilter}
          onCancel={() => setOpenColumnFilter(false)}
          // onFilter={onFilter}
          tableColumns={tableColumns}
          onColumnFilter={onColumnFilter}
        />
      )}
    </div>
  );
};

export default HealthCheckTable;
