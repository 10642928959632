import React from 'react';

import styles from './style.module.scss';

const data = [
  {
    url: 'stocks',
    title: 'STOCKS',
  },
  {
    url: 'options',
    title: 'OPTIONS',
  },
  {
    url: 'crypto',
    title: 'CRYPTO',
  },
];
const University: React.FC<Props> = ({ history }) => {
  return (
    <div className={styles.universityPage}>
      <div className={styles.universityHeaderView}></div>
      <div className={styles.universityBtnSection}>
        {data.map(({ url, title }) => (
          <button
            key={url}
            onClick={() => history.push(`/university/${url}`)}
            className={styles.btnTestimonials}
          >
            {title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default University;
