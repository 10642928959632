import React, { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { get } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { getPrices } from '../../graphql';
import { errorHandler, green } from '../../utils';
import MembershipForm from './MembershipForm';

interface Props extends RouteComponentProps<any> {
  selectedPlan: string;
  // stripePKey: string;
  product: Object;
  isNewUser: Boolean;
  closeModal?: Function;
}
const MembershipFormContainer: React.FC<Props> = ({
  selectedPlan,
  // stripePKey,
  product,
  isNewUser,
  closeModal,
}) => {
  useEffect(() => {
    const promises = [getPrices({})];
    Promise.all(promises)
      .then((res) => {
        setStripeInfo(res[0]);
      })
      .catch(errorHandler)
      .finally(() => {});
  }, []);
  const [stripeInfo, setStripeInfo] = useState({});
  const { key } = stripeInfo;
  const stripePromise = loadStripe(key);
  const { customerId } = useSelector(
    ({ user }) => ({
      customerId: get(user, 'profile.stripeCustomer.stripe_customer_id', 'N/A'),
    }),
    shallowEqual,
  );
  const priceId = get(product, 'stripe_price_id', 'N/A');
  const price = get(product, 'price', 'N/A');
  return key ? (
    <Elements stripe={stripePromise}>
      <MembershipForm
        selectedPlan={selectedPlan}
        customerId={customerId}
        priceId={priceId}
        price={price}
        isNewUser={product?.interval !== 'YEARLY' ? false : isNewUser}
        closeModal={closeModal}
      />
    </Elements>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '670px',
        width: '100%',
      }}
    >
      <BounceLoader color={green} loading={true} size={50} />
    </div>
  );
};

export default MembershipFormContainer;
