import React from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Button, Select, Row, Col } from 'antd';
// import { get } from 'lodash';

import { white, NonSortableFields, NonSortableFieldsIIR } from '../../utils';
import styles from './style.module.scss';
const { Option } = Select;

interface Props {
  onSubmit: Function;
  onReset: Function;
  columns: Array;
  isIIR: boolean;
  sortDetail: Array;
  closeDrawer: Function;
}

const SortForm: React.FC<Props> = ({
  onSubmit,
  onReset,
  columns,
  isIIR,
  sortDetail,
  closeDrawer,
  formFields,
  setFormFields,
}) => {
  const [form] = Form.useForm();
  console.log(columns);
  const nonSortFields = isIIR ? NonSortableFieldsIIR : NonSortableFields;

  const onFinish = (values) => {
    onSubmit(values.sort);
    // closeDrawer();
  };

  const handleReset = () => {
    onReset();
    // closeDrawer();
  };
  const addField = () => {
    setFormFields([
      ...formFields,
      {
        index: formFields.length,
        name: formFields.length,
        sortOrder: 'DESC',
      },
    ]);
  };
  const removeField = (index) => {
    let fields = formFields.filter((d) => d.index !== index);
    // fields = fields.map((item, i) => ({
    //   ...item,
    //   index: i,
    //   name: i,
    // }));
    setFormFields([...fields]);
  };
  const onSortBySelect = (field, index) => {
    let fields = formFields.map((item) => {
      if (item.index === index) {
        return {
          ...item,
          sortBy: field,
        };
      } else return item;
    });

    setFormFields(fields);
  };

  const onSortOrderSelect = (order, index) => {
    let fields = formFields.map((item) => {
      if (item.index === index) {
        return {
          ...item,
          sortOrder: order,
        };
      } else return item;
    });

    setFormFields(fields);
  };
  return (
    <Form form={form} name="sort-form" onFinish={onFinish} autoComplete="off">
      <Form.List name="sort">
        {(fields) => (
          <Row span={24} className={styles.sortFormScroll}>
            {formFields.map((field) => {
              const { index, sortBy, sortOrder } = field;
              return (
                <>
                  <Col span={11} key={index + sortBy + sortOrder}>
                    <Form.Item
                      {...field}
                      label="Sort By"
                      name={[field.name, 'sortBy']}
                      fieldKey={[field.fieldKey, 'sortBy']}
                      rules={[{ required: true, message: 'Missing Sort By' }]}
                      initialValue={sortBy}
                    >
                      <Select
                        // style={{ maxWidth: 160 }}
                        onSelect={(key) => onSortBySelect(key, index)}
                        // value={field.sortBy}
                        // defaultValue={field.sortBy}
                      >
                        {columns
                          .filter((d) => !nonSortFields.includes(d.name))
                          .map((item) => (
                            <Option key={item.key} value={item.key}>
                              {item.value}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'sortOrder']}
                      i
                      label="Sort Order"
                      fieldKey={[field.fieldKey, 'sortOrder']}
                      rules={[
                        { required: true, message: 'Missing Sort Order' },
                      ]}
                      initialValue={sortOrder}
                    >
                      <Select onSelect={(key) => onSortOrderSelect(key, index)}>
                        {['DESC', 'ASC'].map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ alignSelf: 'center' }}>
                    <MinusCircleOutlined
                      style={{ color: white }}
                      onClick={() => removeField(field.index)}
                    />
                  </Col>
                </>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                style={{ width: 140 }}
                onClick={() => addField()}
                block
                icon={<PlusOutlined />}
              >
                Add Sort field
              </Button>
            </Form.Item>
          </Row>
        )}
      </Form.List>
      <Form.Item className={styles.sortFormButton}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button
          htmlType="button"
          onClick={handleReset}
          // disabled={()}
        >
          Reset
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SortForm;
