import React, { useEffect, useState } from 'react';

import { scaleQuantize } from 'd3-scale';
import { NavLink } from 'react-router-dom';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';

import { fetchStudentPerformanceStateReports } from '../../graphql/gamification';
import { errorHandler, usStates } from '../../utils';
import styles from './styles.module.scss';

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3.0.0/states-10m.json';

const colorScale = scaleQuantize()
  .domain([-20, 20])
  // @ts-ignore
  .range([
    '#c25108',
    '#d85d0d',
    '#f36d16',
    '#21a9e5',
    '#3757d2',
    '#203db2',
    '#203db2',
    '#182c83',
    '#11205b',
    '#0c1532',
  ]);

const MapChart = ({ setTooltipContent }) => {
  const [data, setData] = useState([]);
  const payload = {
    page: 1,
    size: 2147483647,
    sortBy: 'student_performance_rank',
    sortOrder: 'asc',
    filters: {},
  };
  useEffect(() => {
    const promises = [fetchStudentPerformanceStateReports(payload)];
    Promise.all(promises)
      .then((res) => {
        const rawTableData = res[0];
        for (const r of rawTableData.data) {
          usStates.forEach((i) => {
            if (i.abbreviation === r['school_state']) {
              r['school_state'] = i.name;
            }
          });
        }
        setData(rawTableData.data);
      })
      .catch(errorHandler)
      .finally(() => {});

    // https://www.bls.gov/lau/
    // csv('/unemployement-by-county-2017.csv').then((counties) => {
    //   setData(counties);
    // });
  }, []);

  return (
    <>
      <ComposableMap
        data-tip=""
        projection="geoAlbersUsa"
        className={styles.mapSize}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              // console.log(geo.properties.name);
              // const cur = data.find((s) => s.id === geo.id);
              const cur = data.find(
                (s) =>
                  s['school_state'].toLowerCase() ===
                  geo.properties.name.toLowerCase(),
              );
              return (
                <NavLink
                  key={geo.rsmKey}
                  to={{
                    pathname: '/GamificationByCounty',
                    state: { name: geo },
                  }}
                  activeClassName={styles.navActiveClass}
                >
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={() => {
                      setTooltipContent(`${geo.properties.name}`);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent('');
                    }}
                    fill={
                      cur?.percentage_returns
                        ? colorScale(cur.percentage_returns)
                        : 'grey'
                    }
                  />
                </NavLink>
              );
            })
          }
        </Geographies>
        (
        {usStates
          .filter(
            (state) =>
              state.name &&
              state.lon &&
              state.lat &&
              state.abbreviation &&
              state.showMarker,
          )
          .map((state) => {
            return (
              <NavLink
                key={state}
                to={{
                  pathname: '/GamificationByCounty',
                  state: {
                    name: {
                      properties: {
                        name: state.name,
                      },
                      id: state.id,
                    },
                  },
                }}
                activeClassName={styles.navActiveClass}
              >
                <Marker
                  key={`marker-${state.name}`}
                  coordinates={[state.lon, state.lat]}
                  fill="#777"
                >
                  <text textAnchor="middle" fill="#FFFFFF">
                    {state.abbreviation}
                  </text>
                </Marker>
              </NavLink>
            );
          })}
        ){/*<Marker coordinates={[-80.793457, 35.782169]} fill="#777">*/}
        {/*  <text textAnchor="middle" fill="#F53">*/}
        {/*    North Carolina*/}
        {/*  </text>*/}
        {/*</Marker>*/}
      </ComposableMap>
    </>
  );
};

export default MapChart;
