import { API } from 'aws-amplify';

import {
  getStudentPerformanceCountyReports,
  getStudentPerformanceGradeReports,
  getStudentPerformanceSchoolReports,
  getStudentPerformanceStateReports,
  getStudentPerformanceStudentReports,
} from './queries';

export const fetchStudentPerformanceStateReports = (value) =>
  API.graphql({
    variables: { request: value },
    query: getStudentPerformanceStateReports,
  }).then(({ data }) => data.getStudentPerformanceStateReports);

export const fetchStudentPerformanceCountyReports = (value) =>
  API.graphql({
    variables: { request: value },
    query: getStudentPerformanceCountyReports,
  }).then(({ data }) => data.getStudentPerformanceCountyReports);

export const fetchStudentPerformanceSchoolReports = (value) =>
  API.graphql({
    variables: { request: value },
    query: getStudentPerformanceSchoolReports,
  }).then(({ data }) => data.getStudentPerformanceSchoolReports);

export const fetchStudentPerformanceGradeReports = (value) =>
  API.graphql({
    variables: { request: value },
    query: getStudentPerformanceGradeReports,
  }).then(({ data }) => data.getStudentPerformanceGradeReports);

export const fetchStudentPerformanceStudentReports = (value) =>
  API.graphql({
    variables: { request: value },
    query: getStudentPerformanceStudentReports,
  }).then(({ data }) => data.getStudentPerformanceStudentReports);
