import React, { useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form, Input, Button } from 'antd';
import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import { RouteComponentProps, Link } from 'react-router-dom';

import { forgotImage } from '../../images';
import { red } from '../../utils';
import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {}

const ForgotPassword: React.FC<Props> = ({ match }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccessMessage, setSuccessMessage] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const username = get(match, 'params.username', null);
  const code = get(match, 'params.code', null);
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onFinish = (values) => {
    const { Email, Password } = values;
    if (!code) {
      setIsLoading(true);
      Auth.forgotPassword(Email.toLowerCase())
        .then((data) => {
          setIsLoading(false);
          setSuccessMessage(true);
        })
        .catch((err) => {
          setError(
            err.message === 'Username/client id combination not found.'
              ? 'No user found for this email. Please enter a valid email.'
              : err.message,
          );
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      Auth.forgotPasswordSubmit(username.toLowerCase(), code, Password)
        .then((data) => {
          setIsLoading(false);
          setSuccessMessage(true);
        })
        .catch((err) => {
          setError(err.message);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.forgotPasswordContainer}>
        <div className={styles.forgotPasswordImage}>
          <img alt="forgot Password" src={forgotImage} />
        </div>
        <div className={styles.forgotPasswordText}>
          <div className={styles.logoTextRight}>
            <h4>
              <Link to="/">
                {' '}
                <ArrowLeftOutlined />{' '}
              </Link>
            </h4>
            <div className={styles.logoImage}>
              <Link to="/">
                {' '}
                <img
                  alt="logo"
                  src="https://thinksabio-asset.s3.amazonaws.com/TSLOGO.png"
                />
              </Link>
            </div>
          </div>
          <div className={styles.forgotFormBox}>
            {!showSuccessMessage && (
              <Form
                {...layout}
                name="sign-up-forgot"
                onFinish={onFinish}
                onValuesChange={() => setError('')}
              >
                <span style={{ color: red }}>{error}</span>
                {!code && (
                  <>
                    {/* <h3>Enter your email address to get reset link.</h3> */}
                    <h3>Forgot Password</h3>
                    <h4>
                      Enter the email associated to your account and we’ll send
                      an email with instructions to reset your password
                    </h4>
                    <Form.Item
                      // hasFeedback
                      name="Email"
                      label="Email"
                      rules={[
                        {
                          type: 'email',
                          required: true,
                          message: 'Please enter valid Email!',
                        },
                      ]}
                    >
                      <Input placeholder="Enter your Email Address" />
                    </Form.Item>
                  </>
                )}
                {code && (
                  <>
                    <span style={{ color: red }}>{error}</span>
                    <h3>Update your password here</h3>
                    <h4>
                      Please enter and confirm your new password below to access
                      your account.
                    </h4>
                    <Form.Item
                      label="Password"
                      name="Password"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            let regex =
                              /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,16}$/;
                            if (!value) {
                              return Promise.reject('Password is required');
                            }
                            if (!regex.test(value)) {
                              return Promise.reject(
                                'Password must be 8-16 characters and contain both numbers and letters/special characters.',
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Form.Item
                      name="confirm"
                      label="Confirm Password"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue('Password') === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              'The two passwords that you entered do not match!',
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password placeholder="Confirm Password" />
                    </Form.Item>
                  </>
                )}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%', height: 50 }}
                    loading={isLoading}
                  >
                    {code ? 'CHANGE PASSWORD' : 'GET RESET LINK'}
                  </Button>
                </Form.Item>
              </Form>
            )}

            {showSuccessMessage && (
              <div style={{ height: 150 }} className={styles.layout}>
                {!code && (
                  <p>
                    A verification link is sent to your email. Please click on
                    the link to update your password.
                  </p>
                )}
                {code && (
                  <>
                    <p>Your password has been updated successfully.</p>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
