import React, { useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Typography, notification, Form, Row, Col, Button, Input } from 'antd';
import { get } from 'lodash';

import { updateStockNote, updateWatchlistNote } from '../../graphql';
import { red, errorHandler } from '../../utils';
import styles from './style.module.scss';

const { TextArea } = Input;
const { Paragraph } = Typography;

interface Props {
  onClose: Function;
  data: object;
  source: string;
  setFetchPortfolioData: Function;
  editable: boolean;
}

const StockNote: React.FC<Props> = ({
  onClose,
  data,
  setFetchPortfolioData,
  source,
  editable,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [editing, setEditing] = useState<boolean>(false);

  const onFinish = (values) => {
    setError('');
    const { note } = values;
    const payload =
      source === 'watchlist'
        ? {
            symbol: get(data, 'symbol'),
            note: note,
          }
        : {
            id: get(data, 'id'),
            note: note,
          };
    setLoading(true);
    if (source === 'watchlist') {
      updateWatchlistNote(payload)
        .then((res) => {
          // form.resetFields();
          notification['success']({
            message: 'Note Updated successfully',
          });
          setFetchPortfolioData(new Date());
          onClose();
        })
        .catch(errorHandler)
        .finally(() => {
          setLoading(false);
        });
    } else {
      updateStockNote(payload)
        .then((res) => {
          // form.resetFields();
          notification['success']({
            message: 'Note Updated successfully',
          });
          setFetchPortfolioData(new Date());
          onClose();
        })
        .catch(errorHandler)
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const existingNote = get(data, 'note', '');
  return (
    <>
      <div className={styles.stockNote}>
        <div className={styles.symbol}>
          <h4>{get(data, 'symbol')}</h4>
          {existingNote && !editing && (
            <Button
              type="primary"
              onClick={() => setEditing(true)}
              shape="circle"
              icon={<EditOutlined />}
              disabled={!editable}
            />
          )}
        </div>

        {existingNote && !editing ? (
          <Paragraph ellipsis={{ rows: 15, expandable: true }}>
            {existingNote}
          </Paragraph>
        ) : (
          <Form
            onFinish={onFinish}
            layout="vertical"
            className={styles.contactUsForm}
            name="add-note-form"
            onValuesChange={() => setError('')}
            initialValues={{
              note: existingNote,
            }}
          >
            <Row span={24} className="gutter-row">
              <Col span={24}>
                <Form.Item
                  name="note"
                  label="Your Note"
                  rules={[
                    {
                      required: true,
                      message: 'Note is required',
                    },
                  ]}
                >
                  <TextArea
                    // rows={4}
                    style={{ width: '100%' }}
                    placeholder="Your Note"
                    autoSize={{ minRows: 4, maxRows: 25 }}
                    disabled={!editable}
                    // maxLength={10}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item style={{ width: '100%', marginBottom: 0 }}>
                  <Button
                    disabled={!editable}
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
                {error && <span style={{ color: red }}>{error}</span>}
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </>
  );
};

export default StockNote;
