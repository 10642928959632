import { Auth } from 'aws-amplify';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

const GTM_ID = 'GTM-K24GDM9';
const GA4_MEASUREMENT_ID = process.env.REACT_APP_GA_TAG;

export const initGoogleAnalytics = () => {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: GTM_ID,
    dataLayer: {
      measurementId: GA4_MEASUREMENT_ID,
    },
  };

  TagManager.initialize(tagManagerArgs);
};

export const googleAnalyticsUpdateUserInfo = async () => {
  const user = await Auth.currentUserInfo();
  const userId = user['username'];
  const userType = user['attributes']['custom:type'] || 'paid';

  TagManager.dataLayer({
    dataLayer: {
      event: 'login',
      userId,
      userType,
    },
  });
};
