import React from 'react';

import { Tabs } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import ChangePassword from '../ChangePassword';
import FinancialProfile from '../FinancialProfile';
import PaymentProfile from '../PaymentProfile';
import PersonalProfile from '../PersonalProfile';
import Refer from '../refer';
import Referrals from '../referrals';
import StudentProfile from '../StudentProfile/StudentProfile';
const { TabPane } = Tabs;

interface Props extends RouteComponentProps<any> {
  onStateChange: Function;
}
const Setting: React.FC<Props> = ({ history }) => {
  const { personalData, loggedInUser } = useSelector(
    ({ user }) => ({
      personalData: get(user, 'personalData', {}),
      loggedInUser: get(user, 'loggedInUser', {}),
    }),
    shallowEqual,
  );
  const userGroups = get(loggedInUser, 'groups', []);
  const userType = personalData['type'];
  const isStudent = userType === 'student';
  return (
    <div id="SettingBoxTabs">
      <Tabs tabPosition="left" id="settingTab">
        <TabPane tab="Personal Profile" key="1">
          {isStudent ? <StudentProfile /> : <PersonalProfile />}
        </TabPane>

        <TabPane tab="Financial Profile" key="2">
          <FinancialProfile isStudent={isStudent} />
        </TabPane>
        <TabPane tab="Change Password" key="3">
          <ChangePassword />
        </TabPane>
        <TabPane tab=" Referral & Rewards" key="4">
          <Refer />
        </TabPane>
        <TabPane tab=" View Referrals" key="6">
          <Referrals />
        </TabPane>
        {!(userGroups.includes('internal_team') || isStudent) && (
          <TabPane tab="Subscription" key="5">
            <PaymentProfile history={history} />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default Setting;
