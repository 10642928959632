import React from 'react';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  // CartesianGrid,
  Legend,
  // LabelList,
} from 'recharts';

import { useTheme } from '../../../ThemeContext';
import {
  BarColors,
  //  green,
  lightHover,
  darkHover,
  black,
  white,
} from '../../../utils';

// const renderCustomizedLabel = (props) => {
//   const { x, y, width, value } = props;
//   const radius = 20;
//   return value ? (
//     <g>
//       {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill={fill} /> */}
//       <text
//         x={x + width / 2}
//         y={y - radius}
//         fill={green}
//         textAnchor="middle"
//         dominantBaseline="middle"
//       >
//         {`${value}%`}
//       </text>
//     </g>
//   ) : (
//     ''
//   );
// };

const MultiBarChart = ({ insData, height, width, showLegend }) => {
  const { theme } = useTheme();

  const data = insData.map((item) => ({
    sector:
      item.sector === 'Communication Services'
        ? 'Comm. Services'
        : item.sector === 'Consumer Cyclical'
        ? 'Cons. Cyclical'
        : item.sector === 'Consumer Defensive'
        ? 'Cons. Defensive'
        : item.sector,
    ...item.ins.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.name]: curr.percentage,
        // [`${[curr.name]}_format`]: curr.percentage + '% -(100M)',
      }),
      {},
    ),
  }));
  let keys = [];
  if (data[0]) {
    keys = Object.keys(data[0]).filter(
      (d) => d !== 'sector' && !d.includes('_format'),
    );
  }

  // console.log('keys', keys, data);

  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        // width={1500}
        // height={600}
        data={data}
        margin={{
          top: 10,
          right: 10,
          left: 10,
          bottom: 5,
        }}
      >
        {showLegend && (
          <XAxis
            dataKey="sector"
            padding={{ left: 5, right: 5 }}
            width={5}
            stroke="#1ABD50"
          />
        )}
        {showLegend && <YAxis width={20} stroke="#1ABD50" />}
        {showLegend && (
          <Tooltip
            formatter={(value) => `${value}%`}
            // wrapperStyle={{
            //   backgroundColor: 'red',
            // }}
            cursor={{ fill: theme === 'dark' ? darkHover : lightHover }} // tdhover
            contentStyle={{
              backgroundColor: theme === 'dark' ? darkHover : lightHover,
            }}
            labelStyle={{
              color: theme === 'dark' ? white : black,
            }}
          />
        )}
        {showLegend && <Legend verticalAlign="top" />}
        {keys.map((k, i) => {
          return (
            <Bar
              key={i}
              barSize={12}
              // maxBarSize={100}
              dataKey={k}
              fill={BarColors[i]}
            >
              {/* {showLegend && (
                <LabelList
                  // dataKey={`${k}_format`}
                  dataKey={`${k}`}
                  position="top"
                  // formatter={(val) => `${val}%`}
                  content={renderCustomizedLabel}
                />
              )} */}
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MultiBarChart;
