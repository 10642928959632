import initialState from '../initialState';
// Actions

const SET_USER_PORTFOLIO_DATA = 'portfolio/SET_USER_PORTFOLIO_DATA';
const RESET_PORTFOLIO = 'portfolio/RESET_PORTFOLIO';
const SET_PORTFOLIO_HISTORY = 'portfolio/SET_PORTFOLIO_HISTORY';
const SET_PORTFOLIO_SNAPSHOT = 'portfolio/SET_PORTFOLIO_SNAPSHOT';
// const SET_PORTFOLIO_SETTING = 'portfolio/SET_PORTFOLIO_SETTING';
const SET_USER_PORTFOLIO_LIST = 'portfolio/SET_USER_PORTFOLIO_LIST';
const SET_FAV_INS_FOR_HEALTH_CHECK = 'portfolio/SET_FAV_INS_FOR_HEALTH_CHECK';
const SET_USER_PORTFOLIO_MOVEMENT_DATA =
  'portfolio/SET_USER_PORTFOLIO_MOVEMENT_DATA';

// const SET_USER_VIRTUAL_PORTFOLIO_DATA =
//   'portfolio/SET_USER_VIRTUAL_PORTFOLIO_DATA';

// Reducer
export default function reducer(
  state = initialState.portfolio,
  action: { type: string; payload: Object },
) {
  switch (action.type) {
    case SET_USER_PORTFOLIO_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.type]: action.payload.data,
        },
      };
    case SET_USER_PORTFOLIO_MOVEMENT_DATA:
      return {
        ...state,
        movement: {
          // ...state.data,
          [action.payload.type]: action.payload.data,
        },
      };

    // case SET_USER_VIRTUAL_PORTFOLIO_DATA:
    //   return {
    //     ...state,
    //     virtualPortfolio: action.payload,
    //   };
    case SET_PORTFOLIO_HISTORY:
      return {
        ...state,
        transactionHistory: action.payload,
      };
    case SET_PORTFOLIO_SNAPSHOT:
      return {
        ...state,
        snapshot: {
          ...state.snapshot,
          [action.payload.type]: action.payload.data,
        },
      };
    // case SET_PORTFOLIO_SETTING:
    //   return {
    //     ...state,
    //     alert: action.payload,
    //   };
    // case SET_FAVORITE_DATA:
    //   return {
    //     ...state,
    //     favorite: action.payload,
    //   };
    case SET_FAV_INS_FOR_HEALTH_CHECK:
      return {
        ...state,
        healthCheckByFavIns: action.payload,
      };
    case SET_USER_PORTFOLIO_LIST:
      return {
        ...state,
        portfolios: action.payload,
      };
    case RESET_PORTFOLIO:
      return {};
    default:
      return state;
  }
}

// Action Creators

export function setUserPortfolioData(data: Object, type: string) {
  return {
    type: SET_USER_PORTFOLIO_DATA,
    payload: { type: type.toLowerCase(), data: data },
  };
}

export function setUserPortfolioMovementData(data: Object, type: string) {
  return {
    type: SET_USER_PORTFOLIO_MOVEMENT_DATA,
    payload: { type: type, data: data },
  };
}

// export function setUserVirtualPortfolioData(data: Object) {
//   return {
//     type: SET_USER_VIRTUAL_PORTFOLIO_DATA,
//     payload: data,
//   };
// }

export function resetPortfolio() {
  return {
    type: RESET_PORTFOLIO,
  };
}

export function setPortfolioHistory(data: Object) {
  return {
    type: SET_PORTFOLIO_HISTORY,
    payload: data,
  };
}

export function setPortfolioSnapshot(data: Object, type: string) {
  return {
    type: SET_PORTFOLIO_SNAPSHOT,
    payload: { type: type, data: data },
  };
}

// export function setAlertInfo(data: Object) {
//   return {
//     type: SET_PORTFOLIO_SETTING,
//     payload: data,
//   };
// }
// export function join(data: Object) {
//   return {
//     type: SET_FAVORITE_DATA,
//     payload: data,
//   };
// }

export function setHealthCheckByFavIns(data: Object) {
  return {
    type: SET_FAV_INS_FOR_HEALTH_CHECK,
    payload: data,
  };
}

export function setUserPortfoliosList(data: Object) {
  return {
    type: SET_USER_PORTFOLIO_LIST,
    payload: data,
  };
}
