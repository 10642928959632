import React, { useState } from 'react';

import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Select,
  Form,
  Button,
  InputNumber,
  notification,
} from 'antd';
import BounceLoader from 'react-spinners/BounceLoader';
import { v4 as uuidv4 } from 'uuid';

import { savePortfolioInfo, esSearchActiveCompany } from '../../../graphql';
import { green, errorHandler, red } from '../../../utils';
import styles from './style.module.scss';

const { Option } = Select;

interface Props {
  handleCancel: Function;
  data: Array<{}>;
  portfolioName: string;
}

const AddRealStock: React.FC<Props> = ({
  handleCancel,
  data,
  portfolioName,
}) => {
  const [form] = Form.useForm();
  const [symbolData, setSymbolData] = useState([]);
  const [avgCostError, setAvgCostError] = useState('');
  const [sharesError, setSharesError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [formFields, setFormFields] = useState([
    {
      name: uuidv4(),
      shares: null,
      price: null,
      symbol: null,
    },
  ]);
  const existingSymbols = (data || []).map((item) => item.symbol);
  const fetchSymbols = async (value) => {
    setFetching(true);
    const res = await esSearchActiveCompany(value);
    if (res) {
      // const filteredData = (res || []).filter(
      //   (s) => !existingSymbols.includes(s.symbol),
      // );
      setSymbolData(res);
    }
    setFetching(false);
  };
  const addField = () => {
    setFormFields([
      ...formFields,
      {
        name: uuidv4(),
        shares: '',
        price: '',
        symbol: null,
      },
    ]);
  };
  const removeField = (name) => {
    let fields = formFields.filter((d) => d.name !== name);
    setFormFields([...fields]);
  };
  const onFinish = (values) => {
    if (sharesError || avgCostError) {
      return;
    }
    const payload = formFields.map((field) => ({
      company: {
        symbol: field.symbol,
      },
      no_of_shares: field.shares.toFixed(2),
      avg_cost: field.price.toFixed(4),
    }));
    setLoading(true);
    savePortfolioInfo(payload, 'REAL', portfolioName)
      .then((res) => {
        // form.resetFields();
        notification['success']({
          message: 'Portfolio Updated successfully',
        });
        handleCancel();
      })
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };
  const onSymbolSelect = (value, name) => {
    let fields = formFields.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          symbol: value,
        };
      } else return item;
    });

    setFormFields(fields);
  };
  const onPriceChange = (value, name) => {
    let fields = formFields.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          price: value,
        };
      } else return item;
    });

    setFormFields(fields);
  };
  const onInputChange = (value, name) => {
    var regExp = /^[0-9]*$/;
    let valueStr1 = value?.toString();
    var valueStr = valueStr1.replaceAll(',', '');
    valueStr = valueStr.replaceAll('.', '');
    valueStr = valueStr?.substr(name === 'avg' ? 2 : 0, valueStr.length);
    var match = valueStr.match(regExp);
    if (!match && name === 'avg') {
      setAvgCostError('special characters and alphabets not allowed');
      return;
    } else if (!match && name === 'shares') {
      setSharesError('special characters and alphabets not allowed');
      return;
    } else {
      setAvgCostError('');
      setSharesError('');
    }
  };

  const onSharesChange = (value, name) => {
    let fields = formFields.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          shares: value,
        };
      } else return item;
    });

    setFormFields(fields);
  };
  return (
    <Form form={form} name="add-form" onFinish={onFinish} autoComplete="off">
      <Row span={24}>
        <Col span={10} className={styles.updateFormLabel}>
          Symbol
        </Col>
        <Col className={styles.updateFormLabel} span={6}>
          No of Shares
        </Col>
        <Col className={styles.updateFormLabel} span={6}>
          Avg Cost (<span style={{ color: 'green' }}> $ </span>)
        </Col>
      </Row>
      <Form.List name="portfolio">
        {(fields) => (
          <div style={{ maxHeight: 400, overflow: 'auto' }}>
            {formFields.map((field, i) => {
              const { name, symbol, shares, price } = field;
              return (
                <Row span={24} className={styles.sortFormScroll} key={name}>
                  <Col span={10}>
                    <Form.Item
                      {...field}
                      // label={i === 0 ? 'Symbol' : null}
                      name={[field.name, 'symbol']}
                      fieldKey={[field.fieldKey, 'symbol']}
                      rules={[
                        { required: true, message: 'Symbol is required' },
                      ]}
                      initialValue={symbol}
                      style={{ width: '90%' }}
                    >
                      <Select
                        placeholder="Search symbol"
                        className={styles.vPortStockSearchBar}
                        onSelect={(key) => onSymbolSelect(key, name)}
                        notFoundContent={
                          fetching ? (
                            <span className={styles.spinnerStyle}>
                              <BounceLoader
                                color={green}
                                loading={true}
                                size={50}
                              />
                            </span>
                          ) : null
                        }
                        onSearch={fetchSymbols}
                        // onChange={(symbol) =>
                        //   history.push(`/stock/${symbol}/${portfolioType}`)
                        // }
                        filterOption={false}
                        showSearch
                        allowClear={true}
                        size="middle"
                        style={{ width: '100%' }}
                        showArrow={false}
                      >
                        {symbolData.map((d) => (
                          <Option
                            key={d.symbol}
                            className={styles.optionStyle}
                            disabled={existingSymbols.includes(d.symbol)}
                          >
                            <span
                              className={
                                existingSymbols.includes(d.symbol)
                                  ? styles.optionDisableSymbol
                                  : styles.optionSymbol
                              }
                            >
                              {d.symbol}
                            </span>{' '}
                            {existingSymbols.includes(d.symbol)
                              ? `${d.name} (Already Added)`
                              : d.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'shares']}
                      // label={i === 0 ? 'No of Shares' : null}
                      fieldKey={[field.fieldKey, 'shares']}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'No of Shares is required',
                      //   },
                      // ]}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || value === 0) {
                              return Promise.reject('No of Shares is required');
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                      initialValue={shares}
                      style={{ width: '90%' }}
                    >
                      <InputNumber
                        min={0}
                        maxLength={18}
                        // ype="number"
                        // type="number"
                        placeholder="No of Shares"
                        style={{ width: '100%' }}
                        onChange={(value) => onSharesChange(value, name)}
                        onInput={(value) => onInputChange(value, 'shares')}
                        formatter={(n) => {
                          if (n?.toString().indexOf('.') > -1) {
                            const [p1, p2] = n?.toString().split('.');
                            let a = `${p1}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            );
                            if (p2) {
                              const b =
                                p2.length > 2
                                  ? p2.toString().substring(0, 2)
                                  : p2.toString();
                              a = a + '.' + b;
                            } else {
                              a = a + '.';
                            }
                            return a;
                          } else {
                            return `${n}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                          }
                        }}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                    {sharesError && <p style={{ color: red }}>{sharesError}</p>}
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'price']}
                      // label={i === 0 ? 'Avg Cost' : null}
                      fieldKey={[field.fieldKey, 'price']}
                      rules={[
                        { required: true, message: 'Avg Cost is required' },
                      ]}
                      initialValue={price}
                      style={{ width: '90%' }}
                    >
                      <InputNumber
                        // min={0.0009}
                        // type="number"
                        maxLength={20}
                        onChange={(value) => onPriceChange(value, name)}
                        onInput={(value) => onInputChange(value, 'avg')}
                        placeholder="Avg Cost"
                        style={{ width: '100%' }}
                        formatter={(n) => {
                          if (n?.toString().indexOf('.') > -1) {
                            const [p1, p2] = n?.toString().split('.');
                            let a = `$ ${p1}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            );
                            if (p2) {
                              const b =
                                p2.length >= 4
                                  ? p2.toString().substring(0, 4)
                                  : p2.toString();
                              a = a + '.' + b;
                            } else {
                              a = a + '.';
                            }
                            return a;
                          } else {
                            return `$ ${n}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            );
                          }
                        }}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                    {avgCostError && (
                      <p style={{ color: red }}>{avgCostError}</p>
                    )}
                  </Col>

                  <Col span={1} style={{ alignSelf: 'flexStart' }}>
                    <PlusCircleOutlined
                      //style={{ color: white }}
                      className={styles.plusButton}
                      onClick={() => addField()}
                    />
                  </Col>
                  {formFields.length > 1 && (
                    <Col span={1} style={{ alignSelf: 'flexStart' }}>
                      <MinusCircleOutlined
                        className={styles.plusButton}
                        onClick={() => removeField(field.name)}
                      />
                    </Col>
                  )}
                </Row>
              );
            })}
          </div>
        )}
      </Form.List>
      <Row
        span={24}
        justify="center"
        // className={styles.buttonSectionBoxNew}
      >
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default AddRealStock;
