import React, { useState } from 'react';

import { Row, Col, Select, Modal } from 'antd';
import BounceLoader from 'react-spinners/BounceLoader';

import { esSearchActiveCompany } from '../../../graphql';
import { green } from '../../../utils';
import styles from './style.module.scss';
const { Option } = Select;

interface Props {
  handleCancel: Function;
  visible: boolean;
  portfolioType: string;
  history: any;
}

const AddStock: React.FC<Props> = ({
  visible,
  handleCancel,
  history,
  portfolioType,
}) => {
  const [symbolData, setSymbolData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const fetchSymbols = async (value) => {
    setFetching(true);
    const res = await esSearchActiveCompany(value);
    if (res) {
      setSymbolData(res);
    }
    setFetching(false);
  };
  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={'Select Stock'}
      bodyStyle={{ minHeight: 300 }}
      className={styles.vPortFoiloTypeN2}
    >
      <Row>
        <Col span={24} className={styles.addStockSearchTitle}>
          <h2>Search Stock to Buy/Sell it in your portfolio</h2>
        </Col>
        <Col span={24}>
          <Select
            placeholder="Search symbol"
            className={styles.vPortStockSearchBar}
            // ode="multiple"
            notFoundContent={
              fetching ? (
                <span className={styles.spinnerStyle}>
                  <BounceLoader color={green} loading={true} size={50} />
                </span>
              ) : null
            }
            onSearch={fetchSymbols}
            onChange={(symbol) =>
              history.push(`/stock/${symbol}/${portfolioType}`)
            }
            filterOption={false}
            showSearch
            allowClear={true}
            size="middle"
            style={{ width: '100%' }}
            showArrow={false}
          >
            {symbolData.map((d) => (
              <Option key={d.symbol} className={styles.optionStyle}>
                <span className={styles.optionSymbol}>{d.symbol}</span> {d.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddStock;
