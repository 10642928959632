import React from 'react';

import { Form, Input, Col, Row, Button, Radio } from 'antd';

import { red } from '../../utils';
import styles from './styles.module.scss';
const { TextArea } = Input;

interface Props {
  error: string;
  isLoading: boolean;
  submitFeedback: Function;
  setError: Function;
}
interface FormType {
  email: string;
  firstName: string;
  lastName: string;
  message: string;
  type: string;
}
const contactForm: React.FC<Props> = ({
  error,
  isLoading,
  submitFeedback,
  setError,
}) => {
  const onFinish = (values: FormType) => {
    setError('');
    const { email, firstName, lastName, message, type } = values;
    const payload = {
      email,
      firstName,
      lastName,
      message,
      type,
    };
    submitFeedback(payload);
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      className={styles.contactUsForm}
      name="contactUs-form"
      onValuesChange={() => setError('')}
      initialValues={{
        type: 'feedback',
      }}
    >
      <Row span={24} className="gutter-row">
        <Col span={24}>
          <Form.Item name="type">
            <Radio.Group>
              <Radio value="question">Question</Radio>
              <Radio value="feedback">Feedback</Radio>
              {/* <Radio value="query">Query</Radio> */}
              {/*<Radio value="wishlist">Wishlist</Radio>*/}
              <Radio value="new idea">New Idea</Radio>
              <Radio value="issue">Issue</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        {/*<Col span={24}>*/}
        {/*  <p className={styles.formText}>Little about you</p>*/}
        {/*</Col>*/}
        <Col span={12}>
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: 'First Name is required' }]}
          >
            <Input placeholder="First Name" className={styles.inputForm} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: 'Last Name is required' }]}
          >
            <Input placeholder="Last Name" className={styles.inputForm} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please enter valid Email!',
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="message"
            label="Your Message"
            rules={[
              {
                required: true,
                message: 'Message is required',
              },
            ]}
          >
            <TextArea
              rows={4}
              style={{ width: 560 }}
              placeholder="Your Message"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item style={{ width: '100%', marginBottom: 0 }}>
            <Button type="primary" loading={isLoading} htmlType="submit">
              Send
            </Button>
          </Form.Item>
          {error && <span style={{ color: red }}>{error}</span>}
        </Col>
      </Row>
    </Form>
  );
};

export default contactForm;
