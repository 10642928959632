import React, { useState, useEffect } from 'react';

import { Form, Row, Col, Select, Button } from 'antd';
// import moment from 'moment-timezone';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { esSearchCompany, esSearchAllInstitution } from '../../graphql';
import { green } from '../../utils';
import styles from './style.module.scss';

const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  reportType: string;
  reportSubType: string;
}
const OptionSearch: React.FC<Props> = ({
  history,
  reportType,
  reportSubType,
  prevPage,
}) => {
  const [form] = Form.useForm();
  const [symbolData, setSymbolData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [institutionsData, setInstitutionsData] = useState([]);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  const [loadingCik, setLoadingCik] = useState<boolean>(false);

  useEffect(() => {
    if (
      reportSubType === 'OPTION_ALL_INSTITUTION' &&
      prevPage !== 'searchReport'
    ) {
      const filters = {
        type: reportType.toLowerCase(),
        subtype: reportSubType.toLowerCase(),
      };
      history.push({
        pathname:
          `/search/report/` +
          queryString.stringify(filters, {
            skipNull: true,
          }),
        state: { prevPage: 'search' },
      });
    }
  }, [history, prevPage, reportSubType, reportType]);

  const fetchSymbols = async (value) => {
    setFetching(true);
    const res = await esSearchCompany(value);
    if (res) {
      setSymbolData(res);
    }
    setFetching(false);
  };

  const fetchInstitution = async (value) => {
    setLoadingCik(true);
    const res = await esSearchAllInstitution(value);
    setInstitutionsData(res);
    setLoadingCik(false);
  };
  const handleSelect = (value) => {
    const ins = selectedInstitutions;
    ins.push(value.key);
    setSelectedInstitutions(ins);
    setInstitutionsData([]);
  };

  const handleDeSelect = (value) => {
    const ins = selectedInstitutions.filter((i) => i !== value.key);
    setSelectedInstitutions(ins);
  };
  const onFinish = (values) => {
    const { symbol } = values;
    const filters = {
      type: reportType.toLowerCase(),
      subtype: reportSubType.toLowerCase(),
    };
    const ciks = [];
    const names = [];
    // eslint-disable-next-line array-callback-return
    (selectedInstitutions || []).map((inst) => {
      const value = inst.split(',');
      ciks.push(value[0]);
      names.push(value[1]);
    });
    switch (reportSubType) {
      case 'OPTION_BY_INSTITUTION':
        filters.institutions = ciks;
        filters.institutionsName = names;
        break;
      case 'OPTION_BY_SYMBOL':
        filters.symbols = symbol;
        break;
      default:
        break;
    }
    history.push({
      pathname:
        `/search/report/` +
        queryString.stringify(filters, {
          skipNull: true,
        }),

      state: { prevPage: 'search' },
    });
  };

  return (
    <Form
      name="sector-filter-form"
      onFinish={onFinish}
      labelCol={{ span: 24, padding: 0 }}
      wrapperCol={{ span: 24 }}
      form={form}
      initialValues={{}}
    >
      <Row span={24}>
        {reportSubType === 'OPTION_BY_INSTITUTION' && (
          <Col span={24}>
            <Form.Item
              name="institutions"
              label="Enter Institution/ Insiders"
              style={{ margin: 0 }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Institution is required`,
                },
              ]}
            >
              <Select
                // mode={'multiple'}
                placeholder="Enter Here"
                notFoundContent={
                  loadingCik ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                filterOption={false}
                onSearch={fetchInstitution}
                size="large"
                showSearch={true}
                allowClear={true}
                showArrow={false}
                onSelect={(value, key) => handleSelect(key)}
                onDeselect={(value, key) => handleDeSelect(key)}
              >
                {institutionsData.map((institution) => (
                  <Option
                    key={institution.cik + ',' + institution.name}
                    value={institution.name}
                  >
                    {institution.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {reportSubType === 'OPTION_BY_SYMBOL' && (
          <Col span={24}>
            <Form.Item
              name="symbol"
              label="Search Symbols"
              style={{ width: '100%' }}
              className={styles.vFormSearhNew}
              rules={[
                {
                  required: true,
                  message: `Symbol is required`,
                },
              ]}
            >
              <Select
                placeholder="Enter valid symbols"
                // mode={'multiple'}
                notFoundContent={
                  fetching ? (
                    <span className={styles.spinnerStyle}>
                      <BounceLoader color={green} loading={true} size={50} />
                    </span>
                  ) : null
                }
                onSearch={fetchSymbols}
                showSearch={true}
                allowClear={true}
                showArrow={false}
                filterOption={false}
                onSelect={() => setSymbolData([])}
                size="large"
              >
                {symbolData.map((d) => (
                  <Option key={d.symbol} className={styles.optionStyle}>
                    <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
                    {d.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row span={24} justify="start" className={styles.buttonSectionBox}>
        <Form.Item colon={false} style={{ margin: 0, marginTop: 20 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};
export default OptionSearch;
