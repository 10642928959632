import React, { useEffect, useState, useContext, useCallback } from 'react';

import { Row } from 'antd';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';

import { Table, Pagination } from '../../components';
import { getSymbolWeeklyGains } from '../../graphql';
import { useTheme } from '../../ThemeContext';
import { errorHandler, formatColumn, formatTableData } from '../../utils';
import { SearchReportContext } from './SearchReportContext';
import styles from './style.module.scss';

const SymbolWeeklyGainsView = () => {
  const { history, filterOptions } = useContext(SearchReportContext);
  const { theme } = useTheme();
  const [loading, setLoading] = useState();
  const [symbolWeeklyGainData, setSymbolWeeklyGainData] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortDetail, setSortDetail] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const { params } = getLocationDetails(location);
    if (params['symbols']) {
      filterOptions['symbols'] = params['symbols'];
    }
    setLoading(true);
    const promises = [
      getSymbolWeeklyGains({
        filters: {
          symbols: filterOptions.symbols ? filterOptions.symbols : undefined,
          minStartPrice: filterOptions.minStartPrice
            ? filterOptions.minStartPrice
            : undefined,
          week: filterOptions.week ? filterOptions.week : undefined,
          startDate: filterOptions.startDate
            ? filterOptions.startDate
            : undefined,
          endDate: filterOptions.endDate ? filterOptions.endDate : undefined,
          maxStartPrice: filterOptions.maxStartPrice
            ? filterOptions.maxStartPrice
            : undefined,
          minEndPrice: filterOptions.minEndPrice
            ? filterOptions.minEndPrice
            : undefined,
          maxEndPrice: filterOptions.maxEndPrice
            ? filterOptions.maxEndPrice
            : undefined,
          minPerChange: filterOptions.minPerChange
            ? filterOptions.minPerChange
            : undefined,
          maxPerChange: filterOptions.maxPerChange
            ? filterOptions.maxPerChange
            : undefined,
        },
        sort: sortDetail,
        page: pageNo,
        size: pageSize,
      }),
    ];
    Promise.all(promises)
      .then((res) => {
        setSymbolWeeklyGainData(res[0]);
      })
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  }, [
    pageNo,
    pageSize,
    sortDetail,
    location.pathname,
    getLocationDetails,
    location,
    filterOptions,
  ]);

  const tableColumns = get(symbolWeeklyGainData, 'columns', []);
  let tableData = get(symbolWeeklyGainData, 'data', []);
  const total = get(symbolWeeklyGainData, 'total', 0);

  const formatColumnsToArray = (columns: Object) => {
    const formattedColumns = Object.keys(columns).map((col, index) => ({
      key: col,
      name: col,
      value: columns[col],
      visible: true, //findColumnVisibility(col),
      index: col === 'symbol' ? 0 : index + 1,
      sort: false,
      // col !== 'status' || col !== 'specialStatus' || col !== 'changeStatus',
    }));
    return formattedColumns;
  };

  const onPaginationChange = (page: number, pageSize: number) => {
    changePageSize(pageSize);
    changePageNo(page);
  };

  const changePageNo = (newPageNo: number) => {
    setPageNo(newPageNo);
  };
  const changePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    let index = sortDetail.findIndex((d) => d.sortBy === field);
    if (index > -1) {
      let newOrder =
        order === 'ascend' ? 'ASC' : order === 'descend' ? 'DESC' : null;
      handleSort(field, newOrder, false);
    } else {
      if (order === 'ascend') {
        handleSort(field, 'ASC', false);
      } else if (order === 'descend') {
        handleSort(field, 'DESC', false);
      } else {
        handleSort(field, null, false);
      }
    }
  };

  const convertStringToArray = (object) => {
    return typeof object === 'string' ? Array(object) : object;
  };

  const getLocationDetails = useCallback((location: any) => {
    const pathname = location.pathname;
    const hasParams = pathname.includes('=');

    let path = pathname;
    if (hasParams) {
      const pathWithFirstFilter = pathname.substring(0, pathname.indexOf('='));
      path = pathWithFirstFilter.substring(
        0,
        pathWithFirstFilter.lastIndexOf('/'),
      );
    }

    const params: any = {};
    if (hasParams) {
      pathname
        .replace(path + '/', '')
        .split('&')
        .forEach((fv: string) => {
          const filterValue = fv.split('=');
          if (params[filterValue[0]]) {
            params[filterValue[0]] = convertStringToArray(
              params[filterValue[0]],
            );
            params[filterValue[0]] = [
              ...params[filterValue[0]],
              filterValue[1],
            ];
          } else {
            params[filterValue[0]] = filterValue[1];
          }
        });
    }
    return { path, params };
  });

  const handleSort = (sortBy: string, sortOrder: string, multi: boolean) => {
    if (multi) {
      let newSort = sortDetail;
      if (sortOrder) {
        newSort = sortDetail.filter((s) => s.sortBy !== sortBy);
        newSort.push({
          sortBy: sortBy,
          sortOrder: sortOrder,
        });
      } else {
        newSort = sortDetail.filter((s) => s.sortBy !== sortBy);
      }
      setSortDetail([...newSort]);
    } else {
      if (sortOrder) {
        setSortDetail([
          {
            sortBy: sortBy,
            sortOrder: sortOrder,
          },
        ]);
      } else {
        setSortDetail([]);
      }
    }
    // setFetchData(new Date());
  };

  return (
    <>
      {symbolWeeklyGainData && (
        <>
          <Row justify="space-between">
            <h3
              className={styles.sectorComponent}
              style={{
                margin: 'auto',
                marginBottom: '10px',
                marginTop: '30px',
              }}
            >
              {`Weekly Gains Data For`}{' '}
              <span style={{ fontSize: 20 }}>{`${
                getLocationDetails(location).params['symbols']
                  ? getLocationDetails(location).params['symbols']
                  : 'NVDA'
              }`}</span>{' '}
            </h3>
          </Row>
          <Table
            style={{
              height: '420px',
              overflowX: 'auto',
            }}
            loading={loading}
            columns={formatColumn(
              formatColumnsToArray(tableColumns),
              true,
              sortDetail,
              history,
              false,
              null,
              'search_report',
              null,
              null,
              theme,
            )}
            // scroll={{ y: y }}
            dataSource={formatTableData(tableData)}
            pagination={false}
            size="small"
            bordered={true}
            onChange={onTableChange}
            rowClassName={styles.tableHeader}
            className={styles.TabularData}
            showSorterTooltip={false}
            id="allPicksId"
          />
          <Pagination
            pageSize={pageSize}
            total={total}
            pageNo={pageNo}
            onChange={onPaginationChange}
            loading={loading}
            showPagination={true}
            size={
              window.innerWidth > 1700 || window.innerWidth < 1200
                ? 'default'
                : 'small'
            }
          />
        </>
      )}
    </>
  );
};

export default SymbolWeeklyGainsView;
