import { Auth } from 'aws-amplify';
import axios from 'axios';

import authenticatorConfig from '../config/authenticatorConfig';

const { aws_cloud_logic_custom } = authenticatorConfig;
const baseUrl = aws_cloud_logic_custom[0].endpoint;
const schoolBaseUrl = 'https://api.schooldigger.com/v2.0/schools';

const { REACT_APP_SCHOOL_API_KEY, REACT_APP_SCHOOL_APP_ID } = process.env;

const getToken = async () =>
  `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`;

export const getStripeInfo = async () =>
  await axios.get(`${baseUrl}/stripe-info`);

export const subscribePlan = async (payload: Object) =>
  await axios({
    method: 'post',
    url: `${baseUrl}/create-subscription`,
    headers: {
      Authorization: await getToken(),
    },
    data: payload,
  });

// fixme: some configuration issue in test amplify, testing with staging for now
export const donation = async (payload: Object) =>
  await axios({
    method: 'post',
    url: `${baseUrl}/donate`,
    data: payload,
  });

export const updatePaymentInfo = async (payload: Object) =>
  await axios({
    method: 'post',
    url: `${baseUrl}/update-payment-info`,
    headers: {
      Authorization: await getToken(),
    },
    data: payload,
  });

export const cancelSubscriptionPlan = async (payload: Object) =>
  await axios({
    method: 'post',
    url: `${baseUrl}/cancel-subscription`,
    headers: {
      Authorization: await getToken(),
    },
    data: payload,
  });

export const updateSubscriptionPlan = async (payload: Object) =>
  await axios({
    method: 'post',
    url: `${baseUrl}/update-subscription`,
    headers: {
      Authorization: await getToken(),
    },
    data: payload,
  });

export const contactUs = async (payload: Object) =>
  await axios({
    method: 'post',
    url: `${baseUrl}/contact-us`,
    // headers: {
    //   Authorization: `Bearer ${(await Auth.currentSession())
    //     .getAccessToken()
    //     .getJwtToken()}`,
    // },
    data: payload,
  });

export const referAFriend = async (payload: Object) =>
  await axios({
    method: 'post',
    url: `${baseUrl}/refer`,
    headers: {
      Authorization: await getToken(),
    },
    data: payload,
  });

export const getRecentPayments = async (payload: Object) =>
  await axios({
    method: 'post',
    url: `${baseUrl}/user-payments`,
    headers: {
      Authorization: await getToken(),
    },
    data: payload,
  });

export const getTelegramLinkUrl = async () =>
  await axios({
    method: 'get',
    url: `${baseUrl}/telegram-deep-linking`,
    headers: {
      Authorization: await getToken(),
    },
  });

export const getTelegramChatGroups = async () =>
  await axios({
    method: 'get',
    url: `${baseUrl}/telegram-chats`,
    headers: {
      Authorization: await getToken(),
    },
  });

export const getTelegramChatInviteLink = async (chatId: string) =>
  await axios({
    method: 'get',
    url: `${baseUrl}/telegram-chat/create-invite-link/${chatId}`,
    headers: {
      Authorization: await getToken(),
    },
  });

export const unlinkTelegram = async () =>
  await axios({
    method: 'get',
    url: `${baseUrl}/unlink-telegram`,
    headers: {
      Authorization: await getToken(),
    },
  });

export const emailInviteLink = async (chatId: number) =>
  await axios({
    method: 'post',
    url: `${baseUrl}/email-invite-link`,
    headers: {
      Authorization: await getToken(),
    },
    data: { chatId: chatId },
  });

export const getSchoolList = async (stateName: string, searchString: string) =>
  await axios({
    method: 'get',
    url: `${schoolBaseUrl}?st=${stateName}&q=${searchString}&page=1&perPage=50&appID=${REACT_APP_SCHOOL_APP_ID}&appKey=${REACT_APP_SCHOOL_API_KEY}`,
  });
