import React, { useEffect, useState } from 'react';

import 'antd/dist/antd.css';
import 'rsuite/dist/rsuite.css';
import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Pagination, Table } from '../../components';
import { getUserReferrals } from '../../graphql';
// import { statusOptions, marketCapOptions } from '../../utils';
import { errorHandler, formatColumn } from '../../utils';
import styles from './styles.module.scss';
// import { get } from 'lodash';
// import { useTheme } from '../../ThemeContext';

const Referrals: React.FC<RouteComponentProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [sortDetail, setSortDetail] = useState<Array>([]);
  // const { theme } = useTheme();
  const { personalData } = useSelector(
    ({ user }) => ({
      personalData: get(user, 'personalData', {}),
    }),
    shallowEqual,
  );
  const userId = personalData['id'];

  useEffect(() => {
    setIsLoading(true);
    let payload = {
      id: userId,
      page: pageNo,
      size: pageSize,
      sortBy: sortDetail[0]?.sortBy ? sortDetail[0].sortBy : 'referral_to_name',
      sortOrder: sortDetail[0]?.sortOrder ? sortDetail[0].sortOrder : 'ASC',
    };
    getUserReferrals(payload)
      .then((res) => {
        const { columns = [] } = res;
        setTableData(res.data);
        setTotal(res.total);
        setTableColumns(formatColumns(columns));
      })
      .catch((error) => {
        errorHandler(error);
        setTableData({});
        // setError('Error While Fetching Data. Please try again');
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, pageSize, sortDetail]);
  const formatColumns = (columns: Object) => {
    const formattedColumns = Object.keys(columns).map((col, index) => ({
      key: col,
      name: col,
      value: columns[col],
      index: col === 'symbol' ? 0 : index + 1,
      sort:
        col !== 'status' || col !== 'specialStatus' || col !== 'changeStatus',
    }));
    return formattedColumns;
  };

  const handleSort = (sortBy, sortOrder) => {
    if (sortOrder) {
      setSortDetail([
        {
          sortBy: sortBy,
          sortOrder: sortOrder,
        },
      ]);
    } else {
      setSortDetail([]);
    }
  };

  const handleTableOnChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    let index = sortDetail.findIndex((d) => d.sortBy === field);
    if (index > -1) {
      let newOrder =
        order === 'ascend' ? 'ASC' : order === 'descend' ? 'DESC' : null;
      handleSort(field, newOrder, false);
    } else {
      if (order === 'ascend') {
        handleSort(field, 'ASC', false);
      } else if (order === 'descend') {
        handleSort(field, 'DESC', false);
      } else {
        handleSort(field, null, false);
      }
    }
  };

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setPageNo(page);
  };

  // const { data, total } = [];
  return (
    <>
      <div>
        <div>
          <h2 style={{ color: 'var(--whitecolor)', textAlign: 'center' }}>
            Referral Details
          </h2>
        </div>
        <div style={{ width: '42%', margin: 'auto' }}>
          <Table
            scroll={{ x: true }}
            loading={isLoading}
            columns={formatColumn(
              tableColumns,
              true,
              sortDetail,
              null,
              false,
              null,
              'alert',
              null,
              null,
              null,
            )}
            dataSource={tableData}
            pagination={false}
            size="small"
            bordered={true}
            rowClassName={styles.tableHeader}
            className={styles.TabularData}
            id="allPicksId"
            onChange={handleTableOnChange}
          />
          <div style={{ width: '150%' }}>
            <Pagination
              pageSize={pageSize}
              total={total}
              onChange={onPaginationChange}
              pageNo={pageNo}
              showPagination={true}
              loading={false}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              color: 'var(--whitecolor)',
              textAlign: 'left',
              margin: 'auto',
              width: '800px',
            }}
          >
            Note : When you share your referral link to your friend and they
            signup using your referral link, the registered user is mapped under
            you and you will be getting the refferral bonus of free subscription
            of monthly plan
            <span style={{ color: 'green', marginLeft: '10px' }}>
              (monthly subscription of $9.99)
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Referrals;
