import React, { useState } from 'react';

import { Row, Col, Form, Button, Input, Select, notification } from 'antd';

import { movePortfolioStock } from '../../../graphql';
import { errorHandler } from '../../../utils';
import styles from './style.module.scss';
const { Option } = Select;

interface Props {
  handleCancel: Function;
  data: Array<{}>;
  portfolioList: Array;
  portfolioName: string;
}

const MoveStock: React.FC<Props> = ({
  handleCancel,
  data,
  portfolioList,
  portfolioName,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [formFields, setFormFields] = useState(
    (data || []).map((item) => ({
      name: item.id,
      symbol: item.symbol,
      id: item.id,
      portfolioName: item.portfolioName,
      moveTo: item.portfolioName,
      moveFrom: portfolioList.find((d) => d.name === portfolioName).id,
    })),
  );

  const onFinish = () => {
    const payload = formFields
      .filter((field) => field.portfolioName !== field.moveTo)
      .map((field) => ({
        id: field.id,
        moveTo: field.moveTo,
      }));
    setLoading(true);
    movePortfolioStock(payload)
      .then((res) => {
        notification['success']({
          message: 'Portfolio Updated successfully',
        });
        handleCancel();
      })
      .catch(errorHandler)
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = (value, name) => {
    let fields = formFields.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          moveTo: value,
        };
      } else return item;
    });

    setFormFields(fields);
  };
  return (
    <Form form={form} name="add-form" onFinish={onFinish} autoComplete="off">
      <p
        className={styles.movePortfolioText}
      >{`Move stocks from ${portfolioName} to another portfolio`}</p>
      <Row span={24}>
        <Col span={12} className={styles.updateFormLabel}>
          Symbol
        </Col>
        <Col className={styles.updateFormLabel} span={12}>
          Move to
        </Col>
      </Row>
      <Form.List name="portfolio">
        {(fields) => (
          <div style={{ maxHeight: 400, overflow: 'auto' }}>
            {formFields.map((field, i) => {
              const { name, symbol } = field;
              return (
                <Row span={24} className={styles.sortFormScroll} key={name}>
                  <Col span={12}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'symbol']}
                      fieldKey={[field.fieldKey, 'symbol']}
                      rules={[
                        { required: true, message: 'Symbol is required' },
                      ]}
                      initialValue={symbol}
                      style={{ width: '90%' }}
                    >
                      <Input disabled size="middle" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'price']}
                      //  label={i === 0 ? 'Avg Cost' : null}
                      fieldKey={[field.fieldKey, 'price']}
                      // rules={[
                      //   { required: true, message: 'Avg Cost is required' },
                      // ]}
                      initialValue={portfolioName}
                      style={{ width: '90%' }}
                    >
                      <Select
                        placeholder="Move to"
                        showSearch
                        style={{ width: '90%' }}
                        onChange={(value) => onChange(value, name)}
                      >
                        {portfolioList.map((p, i) => (
                          <Option key={p.name} value={p.id}>
                            {p.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              );
            })}
          </div>
        )}
      </Form.List>
      <Row
        span={24}
        justify="center"
        // className={styles.buttonSectionBoxNew}
      >
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default MoveStock;
