import React from 'react';

import { Row, Col, Modal } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';

// import styles from './style.module.scss';
import { MembershipForm } from '..';

interface Props {
  handleCancel: Function;
  visible: boolean;
  customerId: string;
}

const SubscribePlanModal: React.FC<Props> = ({
  visible,
  handleCancel,
  selectedPlan,
}) => {
  // const [stripeInfo, setStripeInfo] = useState({});
  const { customer, stripeInfo } = useSelector(
    ({ user }) => ({
      customer: get(user, 'profile.stripeCustomer', {}),
      stripeInfo: get(user, 'stripeInfo', {}),
    }),
    shallowEqual,
  );

  const getProductInfo = () => {
    if (selectedPlan === 'monthly') {
      return prices.find(
        (price: Object) => price.interval === 'MONTHLY' && !price.is_new,
      );
    }
    if (selectedPlan === 'yearly') {
      return prices.find(
        (price: Object) => price.interval === 'YEARLY' && !price.is_new,
      );
    }
    return {};
  };

  const { stripe_payment_method_id } = customer;

  const { prices = [] } = stripeInfo;
  const isNewUser =
    stripe_payment_method_id === null ||
    stripe_payment_method_id === undefined ||
    customer.interval === 'YEARLY';
  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={'Subscribe Plan'}
      bodyStyle={{ minHeight: 300 }}
      width={750}
      style={{ top: 20 }}
      // className={styles.vPortFoiloTypeN2}
    >
      <Row>
        <Col span={24}>
          <MembershipForm
            selectedPlan={selectedPlan}
            // stripePKey={key}
            product={getProductInfo()}
            isNewUser={isNewUser}
            closeModal={handleCancel}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default SubscribePlanModal;
