// import moment from 'moment-timezone';

const initialState = {
  user: {
    loggedInUser: {},
    profile: {},
    userInfo: {},
    pages: {
      ourPicks: undefined,
    },
    financialData: {},
    personalData: {},
    wallet: {
      remainingBalance: 0,
      investedValue: 0,
      currentValue: 0,
    },
  },
  report: {
    quarters: {},
    sectors: {},
  },
  // dashboard: {
  //   data: {},
  // },
  search: {
    recentSearches: {},
    savedSearches: {},
  },
  portfolio: {
    data: {},
    virtualPortfolio: {},

    transactionHistory: {},
  },
  picks: {
    data: {},
  },
  trending: {
    data: {},
  },
  studentInfo: {},
  // tour: {
  //   dashboard: {
  //     createdAt: moment(),
  //     status: 'NOT_FINISHED',
  //     count: 0,
  //   },
  //   search: {
  //     createdAt: moment(),
  //     status: 'NOT_FINISHED',
  //     count: 0,
  //   },
  //   report: {
  //     createdAt: moment(),
  //     status: 'NOT_FINISHED',
  //     count: 0,
  //   },
  // },
  // chat: {
  //   savedMessages: {
  //     lastEvaluatedKey: null,
  //     data: [],
  //   },
  // },
};

export default initialState;
