import React, { useState, useEffect } from 'react';

import { Image } from 'antd';
import BounceLoader from 'react-spinners/BounceLoader';

import { fetchSuccessStories } from '../../../graphql';
import { errorHandler, green } from '../../../utils';
import styles from './style.module.scss';

const SuccessStories: React.FC<Props> = ({ history, match }) => {
  const { params } = match;
  const { type } = params;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    const payload = {
      page: 1,
      size: 50,
      type: type,
    };
    const promises = [fetchSuccessStories(payload)];
    Promise.all(promises)
      .then((res) => {
        setData(res[0]);
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [type]);
  return (
    <div className={styles.successStories}>
      <div className={styles.universityHeaderView}>
        <h1>Success Stories</h1>
        <button onClick={() => history.goBack()}>BACK</button>
      </div>
      <div className={styles.header}>
        <h3>Success speaks here</h3>
        {type.toLowerCase() === 'options' && <h4>Swing & Intra Day options</h4>}
      </div>
      {isLoading && (
        <div className={styles.loaderStyle}>
          <BounceLoader color={green} loading={isLoading} size={150} />
        </div>
      )}

      <div className={styles.photos}>
        {data.map(({ id, url }) => (
          <div key={id} className={styles.card}>
            <Image key={id} src={url} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuccessStories;
