import React, { useState } from 'react';

import { notification } from 'antd';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import {
  cancelSubscriptionPlan,
  updateSubscriptionPlan,
} from '../../api/RestApi';
import { getCustomerSubscriptionInfo } from '../../graphql';
import { setUserProfile } from '../../redux/reducers/user';
import { errorHandler } from '../../utils';
import PaymentProfilePage from './PaymentProfilePage';

type StripePriceType = {
  interval: string;
  price: string;
};
interface Props extends RouteComponentProps<any> {
  stripePrices: Array<StripePriceType>;
  customer: Object;
}
const PaymentProfile: React.FC<Props> = ({
  stripePrices,
  customer = {},
  history,
}) => {
  const getPlanDetail = () =>
    stripePrices.find(
      (price) => price.stripe_price_id === get(customer, 'stripe_price_id', ''),
    );

  // const stripe = useStripe();
  // const elements = useElements();
  const dispatch = useDispatch();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPlan] = useState(getPlanDetail());
  const { stripe_subscription_id } = customer;

  const cancelSubscription = (undo: boolean, reason: string) =>
    cancelSubscriptionPlan({
      subscriptionId: stripe_subscription_id,
      undo: undo,
      cancellationReason: reason,
      // priceId: undo ? get(selectedPlan, 'stripe_price_id', '') : '',
    })
      .then((cancelSubscriptionResponse) => {
        // Display to the user that the subscription has been cancelled.
        undo
          ? notification['success']({
              message: 'Successfully resumed the subscription  plan',
            })
          : notification['success']({
              message: 'Successfully canceled the subscription',
            });

        updateUserSubscriptionStatus();
      })
      .catch((error) => {
        setError(error.message);
      });

  const updateSubscription = (newPriceId: string) =>
    updateSubscriptionPlan({
      subscriptionId: stripe_subscription_id,
      newPriceId: newPriceId,
    })
      .then((updateSubscriptionResponse) => {
        notification['success']({
          message: 'Successfully changed the subscription plan',
        });
      })
      .catch((error) => {
        setError(error.message);
      });

  const updateUserSubscriptionStatus = () => {
    const promises = [getCustomerSubscriptionInfo()];
    setIsLoading(true);
    Promise.all(promises)
      .then((res) => {
        dispatch(setUserProfile(res[0]));
        history.go(0);
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <PaymentProfilePage
      isLoading={isLoading}
      cancelSubscription={cancelSubscription}
      stripePrices={stripePrices}
      selectedPlan={selectedPlan}
      // setSelectedPlan={(val) => setSelectedPlan(getPlanDetail())}
      // onPayment={onPayment}
      // setError={(val: string) => setError(val)}
      error={error}
      updateSubscription={updateSubscription}
      customer={customer}
    />
  );
};

export default PaymentProfile;
