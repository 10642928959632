import React, { useState, useEffect } from 'react';

import { CaretRightOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { get } from 'lodash';

import { getCustomerPaymentCardInfo } from '../../graphql';
import { errorHandler } from '../../utils';
import styles from './style.module.scss';

const PaymentCard: React.FC<Props> = ({ visible, handleCancel }) => {
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [card, setCard] = useState<boolean>(false);

  useEffect(() => {
    //setIsLoading(true);
    getCustomerPaymentCardInfo()
      .then((res) => {
        setCard(res);
      })
      .catch(errorHandler)
      .finally(() => {
        // setIsLoading(false);
      });
  }, []);
  const getFormattedMonth = (month) =>
    month ? ('0' + +month).slice(-2) : '00';
  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={'Payment Card'}
      bodyStyle={{ minHeight: 300, padding: '40px 25px' }}
      className={styles.vPortFoiloTypeN2}
    >
      <div className={styles.paymentCardInfo}>
        <div className={styles.chipImage}>
          <img
            src="https://thinksabio-asset.s3.amazonaws.com/chip.svg"
            alt="chipimage"
          />
        </div>
        <div className={styles.cardNumber}>
          <p>card number</p>
          <ul>
            <li id="first-li">XXXX</li>
            <li>XXXX</li>
            <li>XXXX</li>
            <li id="last-li">{get(card, 'last4', '')}</li>
          </ul>
        </div>
        <div className={styles.nameExpireBox}>
          <div className={styles.Name}>
            <p>CardHolder Name</p>
            <h3>{get(card, 'name', '')}</h3>
          </div>
          <div className={styles.Expire}>
            <p>Expiration</p>
            <h4>
              <span>Valid Thru</span>
              <CaretRightOutlined />{' '}
              <span>{`${getFormattedMonth(get(card, 'expiry_month', ''))}/${get(
                card,
                'expiry_year',
                '',
              ).substr(-2)}`}</span>
            </h4>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentCard;
