// export const inputFieldsPositive = () => {
//   let fields = [
//     // {
//     //   name: 'minPrice',
//     //   placeholder: 'Min Price',
//     //   label: 'Min Price',
//     //   allowDecimal: true,
//     //   visible: true,
//     // },
//     // {
//     //   name: 'maxPrice',
//     //   placeholder: 'Max Price',
//     //   label: 'Max Price',
//     //   allowDecimal: true,
//     //   visible: true,
//     // },
//     {
//       name: 'minInstCount',
//       placeholder: 'Min Inst count',
//       label: 'Min Inst count',
//       allowDecimal: false,
//       visible: true,
//     },
//     {
//       name: 'minSecInstCount',
//       placeholder: 'Min Sec Inst count',
//       label: 'Min Sec Inst count',
//       allowDecimal: false,
//       visible: true,
//     },
//   ];
//   return fields;
// };

// export const inputFields = () => {
//   let fields = [
//     {
//       name: 'minYTD',
//       placeholder: 'Min YTD',
//       label: 'Min YTD',
//       allowDecimal: true,
//       visible: true,
//     },
//     {
//       name: 'min1Yr',
//       placeholder: 'Min 1Yr %',
//       label: 'Min 1Yr %',
//       allowDecimal: true,
//       visible: true,
//     },
//     {
//       name: 'min5Yr',
//       placeholder: 'Min 5Yr %',
//       label: 'Min 5Yr %',
//       allowDecimal: true,
//       visible: true,
//     },
//   ];
//   return fields;
// };

export const filterFields = (type) =>
  type === 'SYMBOL'
    ? [
        {
          name: 'symbols',
          placeholder: 'Enter Symbol',
          label: 'Enter Symbol',
          visible: true,
          type: 'symbols',
          size: 24,
        },

        {
          name: 'sector',
          placeholder: 'Sector',
          size: 12,
          type: 'sector',
        },

        {
          name: 'minPrice',
          placeholder: 'Min Price',
          label: 'Min Price',
          allowDecimal: true,
          visible: true,
          type: 'minPrice',
          size: 12,
        },

        {
          name: 'maxPrice',
          placeholder: 'Max Price',
          label: 'Max Price',
          allowDecimal: true,
          visible: true,
          type: 'maxPrice',
          size: 12,
        },
        {
          name: 'minYTD',
          placeholder: 'Min YTD',
          label: 'Min YTD',
          allowDecimal: true,
          visible: true,
          type: 'inputField',
          size: 12,
        },
        {
          name: 'maxYTD',
          placeholder: 'Max YTD',
          label: 'Max YTD',
          allowDecimal: true,
          visible: true,
          type: 'inputField',
          size: 12,
        },
        {
          name: 'min1Yr',
          placeholder: 'Min 1Yr %',
          label: 'Min 1Yr %',
          allowDecimal: true,
          visible: true,
          type: 'inputField',
          size: 12,
        },
        {
          name: 'max1Yr',
          placeholder: 'Max 1Yr %',
          label: 'Max 1Yr %',
          allowDecimal: true,
          visible: true,
          type: 'inputField',
          size: 12,
        },
        {
          name: 'min5Yr',
          placeholder: 'Min 5Yr %',
          label: 'Min 5Yr %',
          allowDecimal: true,
          visible: true,
          type: 'inputField',
          size: 12,
        },
        {
          name: 'minInstCount',
          placeholder: 'Min Inst count',
          label: 'Min Inst count',
          allowDecimal: false,
          visible: true,
          type: 'inputFieldPositive',
          size: 12,
        },
        // {
        //   name: 'minSecInstCount',
        //   placeholder: 'Min Sec Inst count',
        //   label: 'Min Sec Inst count',
        //   allowDecimal: false,
        //   visible: true,
        //   type: 'inputFieldPositive',
        //   size: 12,
        // },
        {
          name: 'minSec13dCount',
          placeholder: 'Min SEC 13D/G Count',
          label: 'Min SEC 13D/G Count',
          allowDecimal: false,
          visible: true,
          size: 12,
          type: 'inputFieldPositive',
        },
      ]
    : [
        {
          name: 'institutions',
          placeholder: 'Enter Institution',
          label: 'Enter Institution',
          visible: true,
          type: 'ciks',
          size: 24,
        },
        {
          name: 'minTotalStocks',
          placeholder: 'Min Total Stocks',
          label: 'Min Total Stocks',
          allowDecimal: false,
          visible: true,
          size: 12,
          type: 'inputFieldPositive',
        },
        {
          name: 'minPositiveStocks',
          placeholder: 'Min Positive Stocks',
          label: 'Min Positive Stocks',
          allowDecimal: false,
          visible: true,
          size: 12,
          type: 'inputFieldPositive',
        },
        {
          name: 'minNegativeStocks',
          placeholder: 'Min Negative Stocks',
          label: 'Min Negative Stocks',
          allowDecimal: false,
          visible: true,
          size: 12,
          type: 'inputFieldPositive',
        },
      ];
