import React, { useEffect, useState } from 'react';

// import { DownloadOutlined } from '@ant-design/icons';
import { Row, Select, Col, Form, DatePicker, Button } from 'antd';
import { get, round } from 'lodash';
import moment from 'moment-timezone';
// import { CSVLink } from 'react-csv';
import { getHolidays } from 'nyse-holidays';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import { getTransactionHistory } from '../../../graphql';
import { setPortfolioHistory } from '../../../redux/reducers/portfolio';
import { green, errorHandler, currencyFormat } from '../../../utils';
import { TransactionHistoryType } from '../portfolio.types';
import styles from './style.module.scss';
const { Option } = Select;

interface Props {
  transactionHistory: TransactionHistoryType;
  portfolioData: Object;
}

const RealizedGain: React.FC<Props> = ({ portfolioData }) => {
  const { transactionHistory } = useSelector(
    ({ portfolio }) => ({
      transactionHistory: get(portfolio, 'transactionHistory', {}),
    }),
    shallowEqual,
  );
  const checkData = (currentDate) => {
    const year = moment(currentDate).year();
    const holidays = getHolidays(year).map((d) => d.dateString);
    let result = holidays.includes(moment(currentDate).format('YYYY-MM-DD'));
    let result1 =
      moment(currentDate).format('YYYY-MM-DD') <
      moment(disableStart).format('YYYY-MM-DD');
    return result || result1;
  };
  const onFinish = async (values) => {
    const { startDate, endDate, symbol } = values;
    if (startDate || endDate) {
      setDateFiltered(true);
    }
    setFromDate(startDate);
    setToDate(endDate);
    if (!endDate) {
      setToDate(new Date());
    }
    let filteredRows = [];
    if (startDate && endDate) {
      filteredRows = rows.filter(
        (a) =>
          moment(a.transactionTime) >= moment(startDate) &&
          moment(a.transactionTime) <= moment(endDate),
      );
    } else if (startDate && !endDate) {
      filteredRows = rows.filter(
        (a) => moment(a.transactionTime) >= moment(startDate),
      );
    } else if (!startDate && endDate) {
      filteredRows = rows.filter(
        (a) => moment(a.transactionTime) <= moment(endDate),
      );
    }
    await setFilteredRows(filteredRows);
    const filterSell = symbol
      ? filteredRows.filter((a) => a.type === 'SELL' && a.symbol === symbol)
      : filteredRows.filter((a) => a.type === 'SELL');
    if (!filterSell.length) {
      setFilteredRows([]);
    }
    setSelectedSymbolsOnFinish(symbol ? [symbol] : []);
  };
  const dispatch = useDispatch();
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);
  const [dateFiltered, setDateFiltered] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [tableData, setTableData] = useState(transactionHistory);
  const [totalData, setTotalData] = useState(transactionHistory);
  const [filteredRows, setFilteredRows] = useState([]);
  // const [symbolData, setSymbolData] = useState({});
  const [selectedSymbolsOnFinish, setSelectedSymbolsOnFinish] = useState<
    Array<string>
  >([]);
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [disableStart, setDisableStart] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(new Date());
  const [form] = Form.useForm();

  useEffect(() => {
    setTableDataLoading(true);
    let payload = {
      symbols: selectedSymbolsOnFinish,
    };
    let payload1 = {
      symbols: [],
    };

    const apis = [
      getTransactionHistory(payload),
      getTransactionHistory(payload1),
    ];
    Promise.all(apis)
      .then((res) => {
        dispatch(setPortfolioHistory(res[0]));
        setTableData(res[0]);
        setTotalData(res[1]);
        const filterSellRows = res[1].data.filter((a) => a.type === 'SELL');
        if (!filterSellRows.length) {
          setFilteredRows([]);
          setTableData({});
          setShowForm(false);
        }
        const dates = res[1].data.map((p) => p.transactionTime);
        let start = dates.reduce(function (a, b) {
          return a < b ? a : b;
        });
        if (!dateFiltered) {
          setFromDate(start);
        }
        setDisableStart(start);
        if (!dateFiltered) {
          setToDate(new Date());
        }
      })
      .catch(errorHandler)
      .finally(() => {
        setTableDataLoading(false);
      });
  }, [dispatch, selectedSymbolsOnFinish]);

  const rows = get(tableData, 'data', []);
  const totalRows = get(totalData, 'data', []);
  const filterSellRows = totalRows.filter((a) => a.type === 'SELL');
  const symbolRows = filterSellRows.map((a) => {
    if (a.type === 'SELL') {
      return a.symbol;
    }
  });
  const rowSymbols = [...new Set(symbolRows)];
  let rows1 = [];
  rows1.push(rows && rows[0] && rows[0].type === 'BUY' ? rows[0] : rows[1]);

  const getInvested = () => {
    let totalQuantity = 0;
    let buyValue = 0;
    const rowsForCalucultion =
      dateFiltered && filteredRows.length ? filteredRows : rows;
    totalRows.map((val) => {
      if (
        val.symbol === selectedSymbolsOnFinish[0] &&
        val.type === 'BUY' &&
        val.price > buyValue
      ) {
        buyValue = val.price;
      }
    });
    rowsForCalucultion.map((val) => {
      if (val.symbol === selectedSymbolsOnFinish[0] && val.type === 'SELL') {
        totalQuantity = totalQuantity + +val.quantity * buyValue;
      }
    });
    return totalQuantity;
  };
  const getLoss = () => {
    let totalLoss = 0;
    const rowsForCalucultion =
      dateFiltered && filteredRows.length ? filteredRows : rows;
    rowsForCalucultion.map((val) => {
      if (val.type === 'SELL' && val.symbol === selectedSymbolsOnFinish[0]) {
        totalLoss = totalLoss + +val.quantity * val.price;
      }
    });
    return totalLoss;
  };
  const getInvestedTotal = () => {
    let totalInvested = 0;
    const rowsForCalucultion =
      dateFiltered && filteredRows.length ? filteredRows : rows;
    let buyValue = 0;
    rowsForCalucultion.map((val) => {
      if (val.type === 'SELL') {
        totalRows.map((v) => {
          if (v.type === 'BUY' && v.symbol === val.symbol) {
            buyValue = v.price;
          }
        });
        totalInvested = totalInvested + +val.quantity * buyValue;
      }
    });
    return totalInvested;
  };
  const getLossTotal = () => {
    let totalLoss = 0;
    const rowsForCalucultion =
      dateFiltered && filteredRows.length ? filteredRows : rows;
    rowsForCalucultion.map((val) => {
      if (val.type === 'SELL') {
        totalLoss = totalLoss + +val.quantity * val.price;
      }
    });
    return totalLoss;
  };

  // const getRemaining = () => {
  //   if (selectedSymbolsOnFinish && selectedSymbolsOnFinish[0]) {
  //     let totalQuantity = 0;
  //     let totalQuantity1 = 0;
  //     const rowsForCalucultion = dateFiltered ? filteredRows : rows;
  //     rowsForCalucultion.map((val) => {
  //       if (val.symbol === selectedSymbolsOnFinish[0] && val.type === 'SELL') {
  //         totalQuantity = totalQuantity + +val.quantity;
  //       } else if (
  //         val.symbol === selectedSymbolsOnFinish[0] &&
  //         val.type === 'BUY'
  //       ) {
  //         totalQuantity1 = totalQuantity1 + +val.quantity;
  //       }
  //     });
  //     return totalQuantity1 - totalQuantity;
  //   }
  // };
  // const getRemainingTotal = () => {
  //   let totalQuantity = 0;
  //   let totalQuantity1 = 0;
  //   const rowsForCalucultion = dateFiltered ? filteredRows : rows;
  //   rowsForCalucultion.map((val) => {
  //     if (val.type === 'SELL') {
  //       totalQuantity = totalQuantity + +val.quantity * (val.last_sale / 100);
  //     } else if (val.type === 'BUY') {
  //       totalQuantity1 = totalQuantity1 + +val.quantity * (val.last_sale / 100);
  //     }
  //   });
  //   return totalQuantity1 - totalQuantity;
  // };

  const getPercentage = () => {
    return isNaN(((getLoss() - getInvested()) / getInvested()) * 100)
      ? 0
      : ((getLoss() - getInvested()) / getInvested()) * 100;
  };
  const getPercentageTotal = () => {
    return isNaN(
      ((getLossTotal() - getInvestedTotal()) / getInvestedTotal()) * 100,
    )
      ? 0
      : ((getLossTotal() - getInvestedTotal()) / getInvestedTotal()) * 100;
  };

  // const handleChange = (symbols: [string]) => {
  //   if (!symbols || !symbols.length) {
  //     setSelectedSymbols([]);
  //   }
  // };
  // const symbols = portfolioData
  //   .filter((i) => i.symbol !== 'Total')
  //   .map((d) => d.symbol);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          hidden={!showForm}
          style={{ width: 500, marginLeft: 100, marginRight: -200 }}
        >
          <Form
            onFinish={onFinish}
            layout="vertical"
            className={styles.referForm}
            name="realisedGain-form"
            form={form}
          >
            <Row span={24}>
              <Col span={8}>
                <Form.Item
                  name="startDate"
                  label="Start Date"
                  style={{ width: '100%', marginBottom: 5, marginRight: 10 }}
                >
                  <DatePicker
                    style={{ width: 140 }}
                    disabledDate={(currentDate: Date) =>
                      currentDate > moment().tz('America/New_York') ||
                      moment(currentDate).day() === 0 ||
                      moment(currentDate).day() === 6 ||
                      checkData(currentDate)
                    }
                    // onChange={onChange}
                    // defaultValue={date}
                    clearIcon={false}
                    // format={'DD MMM YYYY'}
                    className={styles.vDatePickerNew1}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="endDate"
                  label="End Date"
                  style={{ width: '100%', marginBottom: 5 }}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('startDate') <= value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'End Date should be Greater than Start Date!',
                        );
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    style={{ width: 140 }}
                    disabledDate={(currentDate: Date) =>
                      currentDate > moment().tz('America/New_York') ||
                      moment(currentDate).day() === 0 ||
                      moment(currentDate).day() === 6 ||
                      checkData(currentDate)
                    }
                    // onChange={onChange}
                    // defaultValue={date}
                    clearIcon={false}
                    // format={'DD MMM YYYY'}
                    className={styles.vDatePickerNew1}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="symbol"
                  label={'Symbol'}
                  initialValue={selectedSymbolsOnFinish[0] || ''}
                >
                  <Select
                    style={{ width: 100 }}
                    mode={false}
                    size="default"
                    placeholder="Please select Symbol"
                    className={styles.vSelectSymbolNew}
                    showArrow
                    allowClear={true}
                    showSearch={false}
                  >
                    {rowSymbols.map((s) => (
                      <Option key={s}>{s}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row span={24} style={{ marginLeft: '100px' }}>
              <Col span={10} style={{ marginTop: 20 }}>
                <Button style={{ width: 100 }} type="primary" htmlType="submit">
                  Submit
                </Button>
              </Col>
              <Col span={10} style={{ marginTop: 20 }}>
                <Button
                  style={{ width: 100 }}
                  type="primary"
                  onClick={() => window.location.reload()}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <div
          style={{
            fontWeight: '600',
            color: 'var(--whitecolor)',
            width: '400px',
            height: '170px',
            textAlign: 'center',
            paddingTop: '100px',
            margin: 'auto',
          }}
          hidden={
            (dateFiltered ? filteredRows.length : rows.length) ||
            tableDataLoading
          }
        >
          <span>
            {showForm ? 'No Records With Selected Criteria' : 'No Records'}
          </span>
        </div>
        <div
          hidden={
            !selectedSymbolsOnFinish ||
            !selectedSymbolsOnFinish[0] ||
            (dateFiltered ? !filteredRows.length : !rows.length) ||
            tableDataLoading
          }
          style={{
            fontWeight: '600',
            color: 'var(--whitecolor)',
            width: '400px',
            height: '170px',
            border: 'solid 2px #1abd50',
            borderRadius: '10px',
            margin: 'auto',
          }}
        >
          <div>
            <span
              style={{
                fontWeight: '600',
                fontSize: 'large',
                color: 'var(--whitecolor)',
                marginLeft: 10,
                borderBottom: '1px solid #1abd50',
              }}
            >
              {`Gain or Loss Summary`}
              {' :'}
            </span>
            <span
              style={{
                fontWeight: '600',
                color: '#1abd50',
                marginLeft: 50,
                fontSize: 'large',
              }}
            >
              {`#${selectedSymbolsOnFinish[0]}`}{' '}
            </span>
            <br />
            <span
              style={{
                color: 'var(--whitecolor)',
                fontWeight: '400',
                paddingLeft: 10,
              }}
            >
              Reporting Period (
              <span>
                {`${moment(fromDate).format('DD MMM YYYY')} to ${moment(
                  toDate,
                ).format(' DD MMM YYYY')}`}
              </span>
              )<span style={{ height: 10 }}></span>
              <br />
              <br />
            </span>
          </div>
          <div
            style={{
              textAlign: 'left',
              paddingLeft: '10px',
              margin: 'auto',
            }}
          >
            <span
              style={{
                textAlign: 'left',
                margin: 'auto',
              }}
            >
              {`Amount After Sold Shares:`}
              <span style={{ marginLeft: '50px' }}>
                {currencyFormat(getLoss())}
              </span>
            </span>
            <br />
            <span style={{ fontWeight: '600' }}>
              {`Total Invested:`}
              <span style={{ marginLeft: '125px' }}>
                {currencyFormat(getInvested())}
              </span>{' '}
            </span>
            <br />
            <span
              style={{
                fontWeight: '600',
                color: getLoss() > getInvested() ? '#1abd50' : 'red',
              }}
            >
              ${getLoss() > getInvested() ? 'Total Gain:' : 'Total Loss:'}
              <span style={{ marginLeft: '140px' }}>
                {currencyFormat(getLoss() - getInvested())}
              </span>{' '}
              {'('}
              {Math.abs(round(getPercentage(), 2))}
              {'%)'}
            </span>
            {/*<span>*/}
            {/*  {`${item.quantity} Shares at ${currencyFormat(+item.price)}`}{' '}*/}
            {/*</span>*/}
          </div>
        </div>

        {tableDataLoading && (
          <span style={{ marginLeft: 350 }} className={styles.spinnerStyle}>
            <BounceLoader color={green} loading={true} size={50} />
          </span>
        )}
        <div
          hidden={
            selectedSymbolsOnFinish[0] ||
            tableDataLoading ||
            (dateFiltered ? !filteredRows.length : !rows.length)
          }
          style={{
            fontWeight: '600',
            color: 'var(--whitecolor)',
            width: '360px',
            height: '160px',
            border: 'solid 2px #1abd50',
            borderRadius: '10px',
            margin: 'auto',
          }}
        >
          <div>
            <span
              style={{
                fontWeight: '600',
                fontSize: 'large',
                color: 'var(--whitecolor)',
                marginLeft: 10,
                borderBottom: '1px solid #1abd50',
              }}
            >
              {`Gain or Loss Summary`}
            </span>
            <span style={{ height: 10 }}></span>
            <br />
            {/*<span style={{ fontWeight: '600' }}>*/}
            {/*  {`Amount After Sold Shares`}:*/}
            {/*  {currencyFormat(*/}
            {/*    (getRemaining() * get(symbolData, 'latestPrice', 0)) / 100,*/}
            {/*  )}*/}
            {/*</span>*/}
            {/*<br />*/}
            <span
              hidden={!rows.length}
              style={{
                color: 'var(--whitecolor)',
                fontWeight: '400',
                paddingLeft: 10,
              }}
            >
              Reporting Period {'('}
              <span>
                {`${moment(fromDate).format('DD MMM YYYY')} to ${moment(
                  toDate,
                ).format('DD MMM YYYY')}`}
              </span>
              {')'}
              <br />
            </span>
            <span style={{ height: 10 }}></span>
            <br />
          </div>
          <div
            style={{
              textAlign: 'left',
              paddingLeft: '10px',
              margin: 'auto',
            }}
          >
            <span style={{ fontWeight: '600', paddingTop: 10 }}>
              {`Amount After Sold Shares:`}
              <span style={{ marginLeft: '50px' }}>
                {currencyFormat(getLossTotal())}{' '}
              </span>
            </span>
            <br />
            <span style={{ fontWeight: '600' }}>
              {`Total Invested:`}
              <span style={{ marginLeft: '125px' }}>
                {currencyFormat(getInvestedTotal())}
              </span>
            </span>
            <br />
            <span
              style={{
                fontWeight: '600',
                color: getLossTotal() > getInvestedTotal() ? '#1abd50' : 'red',
              }}
            >
              {getLossTotal() > getInvestedTotal()
                ? 'Total Gain:'
                : 'Total Loss:'}
              <span style={{ marginLeft: '150px' }}>
                {currencyFormat(getLossTotal() - getInvestedTotal())}{' '}
              </span>
              {'('}
              {`${Math.abs(round(getPercentageTotal(), 2))}`}
              {'%)'}
            </span>
            {/*<span>*/}
            {/*  {`${item.quantity} Shares at ${currencyFormat(+item.price)}`}{' '}*/}
            {/*</span>*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default RealizedGain;
