export const Category1FAQs = [
  {
    question: `Why should you become a member of ThinkSabio? What's special about ThinkSabio?`,
    answer: `For one, ThinkSabio makes investing extremely easy. ThinkSabio provides rock solid stock choices among many available based off the institutional investors data. 
    In one line we are following the leaders(institutions). You don't need an advanced degree or financial domain expertise to understand our methods. ThinkSabio employs a unique strategy. Consider this analogy - say we pool top students from Ivy League Universities and ask them a single question.90% of them come up with the same answer. What's the likelihood of this answer being correct? Very high, right? We are using the same method by pooling the knowledge of highly skilled institutional investors and price trends of stocks`,
  },
  {
    question: `How can we confidently say that our method works?`,
    answer: `Well, most institutional investors hire mathematicians, physicists, computer experts, financial analysts, and many others. When so many elite-minded people's brains are put together to reach a final decision, how can it be wrong? This smart money has access to company data, resources, and materials that may not be available to the average person. We are making an effort to provide you with advice based off on information and opinions from highly qualified individuals.`,
    extra: ['https://www.youtube.com/watch?v=U5kIdtMJGc8'],
  },
];

export const Category2FAQs = [
  {
    question: `Is there a free trial option? How do you sign up?`,
    answer: `We have a free trial membership for one week! All you need to provide is your email - no 
    credit card information necessary. You can always cancel at any time and there are no hidden 
    charges.`,
  },
  {
    question: `What's an example of a successful stock pick that ThinkSabio suggested?`,
    answer: `Although we have plenty of options, one of our best examples is Sea Limited. We targeted it when it was in the $30s and it is now worth over $120. We looked at institutional investors' patterns and followed the actions of Jim Simons, a highly successful expert.`,
  },
  {
    question: `How can you become a part of the ThinkSabio community? How much does it cost?`,
    answer: `Anyone can easily become a member of ThinkSabio! Our goal is to make our excellent product available to all regardless of their social status, gender, or race. Our subscription charges are under a dollar per trading day; you will gain access to $28 trillion dollars' worth of stocking holdings of smart money. Again, you can cancel at any time and there are no hidden fees or charges.`,
  },
];

export const Category3FAQs = [
  {
    question: `How frequently is our data updated?`,
    answer: `We have data extractions from SEC quarterly and daily! We get quarterly data within 45 days 
    of the quarter's end. Whenever an institution buys or sells 5% or more of any company's 
    stock in a day, we get that data. `,
  },
  {
    question: `Do we have insider's data too?`,
    answer: `Yes, of course! Currently, we have more than 3000 insiders, including CEOs, CFOs, directors, buying and selling information in our tool. Their expert opinions help you make stock selections.    `,
  },

  {
    question: `How many institutions are in ThinkSabio?`,
    answer: `We have more than 6300 institutional investors' stock holdings; this translates into approximately $28 trillion dollars. In other words, we are accessing $28 trillion dollars of investments before we conclude where investments should go. Also, we separate top hedge funds from the total population and study them individually to better understand their patterns. `,
  },
  {
    question: `And finally, you may be wondering - why the name ThinkSabio? What does it mean?`,
    answer: `Well, we want our investors to Think Wisely and "Sabio" translates to "wise" in Spanish! 
    Our company values patience, confidence, and wisdom. We strongly believe that those who 
    possess these qualities will be the most successful in the stock market world. We sincerely hope that you join ThinkSabio to become one of our wise investors!`,
  },
];
