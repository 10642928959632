import React, { useEffect, useState } from 'react';

import { Modal, notification, Checkbox, Button } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { getTelegramChatGroups, unlinkTelegram } from '../../api/RestApi';
import { getUserPersonalInfo } from '../../graphql';
import { setTelegramGroups } from '../../redux/reducers/user';
import { setPersonalData } from '../../redux/reducers/user';
import { red, errorHandler } from '../../utils';
import CommunityGuidelines from '../CommunityGuidelines';
import styles from './style.module.scss';

const Community: React.FC = () => {
  const dispatch = useDispatch();
  const { telegramId, joinTelegramLink, telegramGroups, personalData } =
    useSelector(
      ({ user }) => ({
        telegramId: get(user, 'personalData.additionalInfo.telegram_id', ''),
        joinTelegramLink: get(user, 'joinTelegramLink', ''),
        telegramGroups: get(user, 'telegramGroups', ''),
        personalData: get(user, 'personalData', {}),
      }),
      shallowEqual,
    );
  const [guidelinesAccepted, setGuidelinesAccepted] = useState(false);
  const [openGuidelines, setOpenGuidelines] = useState(false);
  const [error, setError] = useState('');
  const botName = process.env.REACT_APP_TS_BOT;
  const userType = personalData['type'];
  const isStudent = userType === 'student';
  useEffect(() => {
    if (!telegramId && !isStudent) {
      const interval = setInterval(() => {
        getUserPersonalInfo()
          .then(async (res) => {
            const {
              first_name = '',
              last_name = '',
              email = '',
              additionalInfo = {},
              userBillingAddress = {},
            } = res;
            if (res) {
              const data = {
                userBillingAddress: userBillingAddress
                  ? userBillingAddress
                  : {},
                additionalInfo: additionalInfo ? additionalInfo : {},
                first_name: first_name,
                last_name: last_name,
                email: email,
                date: +new Date(),
              };
              const newTelegramId = get(additionalInfo, 'telegram_id', '');

              if (newTelegramId) {
                notification['success']({
                  message: 'Telegram Linked Successfully',
                });
                await dispatch(setPersonalData(data));
              }
            } else {
              //setPersonalInfo({});
            }
          })
          .catch(errorHandler);
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  });

  useEffect(() => {
    if (telegramId) {
      const interval = setInterval(() => {
        getTelegramChatGroups()
          .then((res) => {
            dispatch(setTelegramGroups(res));
          })
          .catch(errorHandler)
          .finally(() => {});
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, telegramId]);

  const onCheckboxChange = (e) => {
    setGuidelinesAccepted(e.target.checked);
    setError('');
  };

  const onUnlink = () =>
    Modal.confirm({
      title: 'Unlink Telegram',
      content: <p>Are you sure you want to unlink your Telegram ?</p>,
      onOk: () => handleTelegramUnlink(),
    });

  const handleTelegramUnlink = () =>
    unlinkTelegram()
      .then((res) => {
        dispatch(setTelegramGroups({}));
        checkTelegramLinkingStatusHandler(true);
        notification['success']({
          message: 'Telegram Unlinked Successfully',
        });
      })
      .catch(errorHandler);

  const handleLinkTelegram = () => {
    if (guidelinesAccepted) {
      window.open(joinTelegramLink, '_blank').focus();
    } else {
      setError('Please Accept chat function guidelines');
    }
  };
  const checkTelegramLinkingStatusHandler = (unlink: boolean) =>
    getUserPersonalInfo()
      .then(async (res) => {
        const {
          first_name = '',
          last_name = '',
          email = '',
          additionalInfo = {},
          userBillingAddress = {},
        } = res;
        if (res) {
          const data = {
            userBillingAddress: userBillingAddress ? userBillingAddress : {},
            additionalInfo: additionalInfo ? additionalInfo : {},
            first_name: first_name,
            last_name: last_name,
            email: email,
            date: +new Date(),
          };
          const newTelegramId = get(additionalInfo, 'telegram_id', '');

          if (!unlink) {
            if (newTelegramId) {
              notification['success']({
                message: 'Telegram Linked Successfully',
              });
            } else {
              notification['error']({
                message: 'Telegram not linked properly',
              });
            }
          }

          await dispatch(setPersonalData(data));
        } else {
          //setPersonalInfo({});
        }
      })
      .catch(errorHandler);

  const onAgree = () => {
    setOpenGuidelines(false);
    setGuidelinesAccepted(true);
  };
  return (
    <>
      <div className={styles.joinTelegramSection}>
        {!isStudent && (
          <div className={styles.joinTelegramContent}>
            <h2 className={styles.titleHeaderFeature}>
              Welcome to ThinkSabio Community
            </h2>
            <h3 className={styles.titleSubFeature}>
              If you don't have Telegram Account yet, please create an account
              with Telegram (it's a Instant cloud based Messaging software )
            </h3>
            <h3 className={styles.videoLink} style={{ marginTop: 15 }}>
              <b style={{ color: red }}>Support/Help Center: </b>Any support
              related queries, Reach out to our Telegram Support Group by
              joining
              <a
                href="https://t.me/joinchat_THW6rMZj0"
                target="_blank"
                rel="noopener noreferrer"
                // activeClassName={styles.navActiveClass}
              >
                &nbsp;here
              </a>
            </h3>
            <div className={styles.joinTelegramBox}>
              {telegramId ? (
                <ol start={1}>
                  <p onClick={onUnlink} className={styles.unlinkTelegramBtn}>
                    Unlink Telegram{' '}
                  </p>
                  <p className={styles.joinBelowText}>
                    Join Our ThinkSabio Community via Telegram App
                  </p>
                  <h3 className={styles.videoLink}>
                    (Watch the
                    <a
                      href="https://youtu.be/ZBFEkXQFwCI"
                      target="_blank"
                      rel="noopener noreferrer"
                      // activeClassName={styles.navActiveClass}
                    >
                      &nbsp;Demo
                    </a>
                    )
                  </h3>
                  {(telegramGroups || [])
                    .sort((a, b) => a.index - b.index)
                    .map((group) => (
                      <li className={styles.telegramGroups} key={group.id}>
                        <div
                          className={
                            group.participant
                              ? styles.telegramGroupsText
                              : styles.telegramGroupsAlreadyJoin
                          }
                        >
                          <p>{`${group.index}. ${group.title}`}</p>
                          {!group.participant ? (
                            <>
                              <a
                                href={`https://t.me/${botName}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                // activeClassName={styles.navActiveClass}
                              >
                                Open ThinkSabio Bot
                              </a>
                            </>
                          ) : (
                            <span>{`You already joined this group`}</span>
                          )}
                        </div>
                      </li>
                    ))}
                  <p className={styles.noteText}>
                    Note: By Linking Telegram, you agree to follow our{' '}
                    <span
                      className={styles.guideline}
                      onClick={() => setOpenGuidelines(true)}
                    >
                      &nbsp;Chat Function Guidelines.
                    </span>
                  </p>
                </ol>
              ) : (
                <>
                  <h2>
                    Follow these Instructions to Join our Community via Telegram
                    App
                  </h2>
                  <h3 className={styles.videoLink}>
                    (Watch the
                    <a
                      href="https://youtu.be/ZBFEkXQFwCI"
                      target="_blank"
                      rel="noopener noreferrer"
                      // activeClassName={styles.navActiveClass}
                    >
                      &nbsp;Demo
                    </a>
                    )
                  </h3>
                  <div className={styles.clickBoxSection}>
                    <div className={styles.clickBoxSectionText}>
                      <img
                        alt="mail_icon"
                        src="https://thinksabio-asset.s3.amazonaws.com/Send.svg"
                      />
                      <h6>Step 1</h6>
                      <p>Click on 'Link Telegram' Button</p>
                    </div>
                    <div className={styles.clickBoxSectionText}>
                      <img
                        alt="mail_icon"
                        src="https://thinksabio-asset.s3.amazonaws.com/Join.svg"
                      />
                      <h6>Step 2</h6>
                      <p>Click on 'Send Message' Button</p>
                    </div>
                    <div className={styles.clickBoxSectionText}>
                      <img
                        alt="mail_icon"
                        src="https://thinksabio-asset.s3.amazonaws.com/Start.svg"
                      />
                      <h6>Step 3</h6>
                      <p>Click on 'Start' Button</p>
                    </div>
                    {/* <div className={styles.clickBoxSectionText}>
                    <img
                      alt="finish_icon"
                      src="https://thinksabio-asset.s3.amazonaws.com/Finish.svg"
                    />
                    <h6>Step 4</h6>
                    <p className={styles.clickHereText}>
                      <span onClick={checkTelegramLinkingStatus}>
                        Click here
                      </span>{' '}
                      to finish & follow Next Steps
                    </p>
                  </div> */}
                  </div>
                  <Checkbox
                    checked={guidelinesAccepted}
                    onChange={onCheckboxChange}
                  >
                    I accept to the{' '}
                  </Checkbox>
                  <span
                    // to="/guidelines"
                    onClick={() => setOpenGuidelines(true)}
                    className={styles.guideline}
                  >
                    Chat Function Guidelines.
                  </span>
                  <p style={{ color: 'red', marginBottom: '15px' }}>{error}</p>
                  <div className={styles.joinTelegramBtn}>
                    <Button
                      // disabled={!guidelinesAccepted}
                      // type="primary"
                      shape="round"
                      size="large"
                      onClick={handleLinkTelegram}
                    >
                      Link Telegram
                    </Button>
                  </div>
                  <p className={styles.accessIssue}>
                    <b>Note</b>: If you are facing{' '}
                    <a
                      href="https://thinksabio-asset.s3.amazonaws.com/Telegram_issue.png"
                      // className={styles.guideline}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      this
                    </a>{' '}
                    issue then please use mobile.
                  </p>
                </>
              )}
            </div>
          </div>
        )}{' '}
      </div>
      {openGuidelines && (
        <CommunityGuidelines
          isVisible={openGuidelines}
          onCancel={() => setOpenGuidelines(false)}
          onAgree={onAgree}
          showFooter={!telegramId}
        />
      )}
    </>
  );
};

export default Community;
