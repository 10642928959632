import React from 'react';

import { Avatar, Image, Layout, Dropdown } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

import { useTheme } from '../../ThemeContext';
import { userActionDropDown } from './DashboardHeader.constant';
import styles from './style.module.scss';
const { Header } = Layout;

interface Props extends RouteComponentProps<any> {
  signOut: Function;
  menuLinkStyle: Object;
}

const SubscriptionPageHeader: React.FC<Props> = ({
  signOut,
  menuLinkStyle,
}) => {
  // const dispatch = useDispatch();
  const { loggedInUser, image, imageTime } = useSelector(
    ({ user }) => ({
      image: get(user, 'personalData.additionalInfo.image_url', ''),
      imageTime: get(user, 'personalData.date', ''),
      loggedInUser: get(user, 'loggedInUser', {}),
      telegramId: get(user, 'personalData.additionalInfo.telegram_id', ''),
    }),
    shallowEqual,
  );
  const { theme, setTheme } = useTheme();

  const name = get(loggedInUser, 'given_name', []);
  const lastName = get(loggedInUser, 'family_name', []);

  const onThemeChange = (darkMode) => {
    setTheme(darkMode ? 'dark' : 'light');
    localStorage.setItem('theme', darkMode ? 'dark' : 'light');
  };
  return (
    <Header className={styles.VheaderBarNew}>
      <div className={styles.VheaderBarNewContent}>
        <div className={styles.headerLogoBox}>
          <NavLink to="/portfolio/Portfolio/real" className={styles.logo}>
            <img
              src={`https://thinksabio-asset.s3.amazonaws.com/TSLOGO-${theme}.png`}
              alt="logo"
            />
          </NavLink>
        </div>
        <div className={styles.menuContainer}>
          {image ? (
            <div className={styles.profilePic}>
              <Image key={imageTime} src={image} />
            </div>
          ) : (
            <Avatar size={32} style={{ paddingLeft: 15 }}>
              {`${name[0]}${lastName ? lastName[0] : ''}`}
            </Avatar>
          )}

          <div id="vAvtorNew" className={styles.dashboardProfileIcon}>
            <Dropdown
              overlay={userActionDropDown(signOut, onThemeChange, theme, false)}
              placement="bottomCenter"
              arrow
            >
              <p
                style={{
                  ...menuLinkStyle,
                  margin: 0,
                  cursor: 'pointer',
                  fontWeight: 700,
                }}
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <span style={{ paddingLeft: 10, textTransform: 'capitalize' }}>
                  {name}
                </span>
              </p>
            </Dropdown>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default SubscriptionPageHeader;
