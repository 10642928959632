import React, { useEffect, useState } from 'react';

// import RangeSlider from 'react-range-slider-input';
// import 'react-range-slider-input/dist/style.css';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Col, Form, Checkbox, Row, Select, Button, Input, message } from 'antd';
import 'antd/dist/antd.css';
import 'rsuite/dist/rsuite.css';
import BounceLoader from 'react-spinners/BounceLoader';

import { createTSAlert, esSearchCompany, updateMyAlerts } from '../../graphql';
import { errorHandler, green, red } from '../../utils';
import styles from '../refer/styles.module.scss';

interface Props {
  record: Object;
  alerts: Object;
  tabNum: string;
}

const Insiders: React.FC<Props> = ({ record, tabNum, alerts }) => {
  const [symbols, setSymbols] = useState([]);
  const [name, setName] = useState('');
  let symbolValue = record && record.symbols ? record.symbols : undefined;
  let nameValue = record && record.name ? record.name : undefined;
  useEffect(() => {
    if (record && record.name && tabNum === '5') {
      if (record && record.symbols) {
        setSymbols(record.symbols);
      }
      if (record && record.name) {
        setName(record.name);
      }
      if (record && record.trxn_val_min) {
        setTxnValue(record.trxn_val_min / 1000000);
      }
      if (record && record?.type === 'INSIDER') {
        if (
          record &&
          record.status &&
          ((record.status.includes('INCREASED') &&
            record.status.includes('DECREASED')) ||
            record.status.includes('ALL'))
        ) {
          setIsInsiderAll(true);
        } else {
          if (record.status.includes('INCREASED')) {
            setIsBuy(true);
          }
          if (record.status.includes('DECREASED')) {
            setIsSell(true);
          }
        }
      }
      console.log(symbols);
    }
  }, [record, tabNum, symbols]);
  const [form] = Form.useForm();
  const [txnValue, setTxnValue] = React.useState<number>(0);

  const handleChange = (event: Event, newValue: number) => {
    setTxnValue(newValue);
  };
  const handleInputChange = (event: Event) => {
    let { value } = event.target;
    value = value < 1000 ? value : 1000;
    value = value < 0 ? 0 : value;
    setTxnValue(value);
  };
  const fetchSymbols = async (value: Object) => {
    setFetching(true);
    const res = await esSearchCompany(value);
    if (res) {
      setSymbolData(res);
    }
    setFetching(false);
  };

  const [symbolData, setSymbolData] = useState([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<string>(' ');
  const [isSell, setIsSell] = useState(false);
  const [isBuy, setIsBuy] = useState(false);
  const [isInsiderAll, setIsInsiderAll] = useState(false);
  const [saving, setIsSaving] = useState<boolean>(false);
  const { Option } = Select;
  //
  //   enum ReportStatus {
  //     ALL
  //   INCREASED
  //   DECREASED
  //   NO_CHANGE
  //   NEWLY_BOUGHT
  //   SOLD_OFF
  //   CONSISTENTLY_DECREASED
  //   CONSISTENTLY_INCREASED
  //   UP
  //   DOWN
  // }
  const marks = [
    {
      value: 0,
      label: '0M',
    },
    {
      value: 1000,
      label: '1000M',
    },
  ];

  const onFinish = (values) => {
    if ((!record || !record.name) && alerts.length) {
      // values.alertName
      const alertExsists = alerts.map((a) => {
        if (a.name === values.alertName) {
          return true;
        }
      });
      if (alertExsists.includes(true)) {
        setError('Alert Already Exsists with given name');
        return;
      } else {
        setError(' ');
      }
    }
    setIsSaving(true);
    // eslint-disable-next-line
    console.log(values);
    const type = 'INSIDER';
    let status = [];
    if (isInsiderAll) {
      status = ['ALL'];
    } else {
      if (isBuy && isSell) {
        status = ['ALL'];
      } else {
        if (isBuy) {
          status.push('INCREASED');
        }
        if (isSell) {
          status.push('DECREASED');
        }
      }
    }
    const payload = {
      type,
      options: {
        symbols: values.symbols,
        name: values.alertName,
        trxn_val_min: txnValue * 1000000,
        status: status,
      },
    };
    const apis =
      record && record.name
        ? [updateMyAlerts(payload)]
        : [createTSAlert(payload)];
    Promise.all(apis)
      .then((data) => {
        message.success('Alert Created');
      })
      .catch(errorHandler)
      .finally(() => {
        form.resetFields();
        setIsBuy(false);
        setIsSell(false);
        setIsInsiderAll(false);
        setIsSaving(false);
        window.location.reload();
      });
    console.log(payload);
  };
  const onSellChange = (e) => {
    setIsSell(e.target.checked);
  };
  const onBuyChange = (e) => {
    setIsBuy(e.target.checked);
  };
  const onInsiderAllChange = (e) => {
    setIsInsiderAll(e.target.checked);
  };

  return (
    <>
      <>
        <div>
          {saving && (
            <div style={{ marginLeft: '300px', marginTop: '50px' }}>
              <div className={styles.loaderStyle}>
                <BounceLoader color={green} loading={saving} size={150} />
              </div>
            </div>
          )}
          {!saving && (
            <Form
              onFinish={onFinish}
              layout="vertical"
              className={styles.referForm}
              name="refer-form"
              form={form}
            >
              <Row
                span={12}
                className="gutter-row"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Col span={10} style={{ marginRight: '70px' }}>
                  <Form.Item
                    name="alertName"
                    label="Alert Name"
                    initialValue={nameValue || name}
                    style={{ marginBottom: 5 }}
                    rules={[
                      {
                        required: true,
                        message: `Name is required`,
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: '95%',
                        marginTop: '10px',
                        marginBottom: error ? '0' : '20px',
                      }}
                      onChange={() => {
                        setError('');
                      }}
                      placeholder="Give a Name for your alert"
                    />
                  </Form.Item>
                  {error && (
                    <p style={{ color: red, marginBottom: '20px' }}>{error}</p>
                  )}
                </Col>
                <p style={{ color: 'var(--whitecolor)', marginBottom: '20px' }}>
                  Shares Activity
                </p>
                <div
                  style={{
                    border: '1px solid green',
                    marginBottom: '30px',
                    paddingTop: '20px',
                    width: '400px',
                  }}
                >
                  <Col
                    span={24}
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Form.Item name="allIndider" style={{ marginLeft: '10px' }}>
                      <Checkbox
                        checked={isInsiderAll}
                        onChange={onInsiderAllChange}
                      >
                        All
                      </Checkbox>
                    </Form.Item>
                    <Form.Item name="buy" style={{ marginLeft: '10px' }}>
                      <Checkbox
                        disabled={isInsiderAll}
                        checked={isBuy}
                        onChange={onBuyChange}
                      >
                        Bought
                      </Checkbox>
                    </Form.Item>
                    <Form.Item name="sell" style={{ marginLeft: '10px' }}>
                      <Checkbox
                        disabled={isInsiderAll}
                        checked={isSell}
                        onChange={onSellChange}
                      >
                        Sold
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </div>
                <Col span={10} style={{ marginRight: '70px' }}>
                  <Form.Item
                    name="symbols"
                    label={'Add Symbols'}
                    initialValue={symbolValue || symbols}
                    className={styles.vFormSearhNew}
                  >
                    <Select
                      placeholder="Enter valid symbols"
                      mode={'multiple'}
                      notFoundContent={
                        fetching ? (
                          <span className={styles.spinnerStyle}>
                            <BounceLoader
                              color={green}
                              loading={true}
                              size={50}
                            />
                          </span>
                        ) : null
                      }
                      onSearch={fetchSymbols}
                      showSearch={true}
                      allowClear={true}
                      showArrow={false}
                      filterOption={false}
                      onSelect={() => setSymbolData([])}
                      size="large"
                      style={{ width: 'calc(100% - 10px)', marginTop: '20px' }}
                    >
                      {symbolData.map((d) => (
                        <Option key={d.symbol} className={styles.optionStyle}>
                          <span className={styles.optionSymbol}>
                            {d.symbol}
                          </span>{' '}
                          {d.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Form.Item
                style={{ width: '100%', marginBottom: 0, textAlign: 'center' }}
              > */}
                <div>
                  <p
                    style={{ color: 'var(--whitecolor)', marginBottom: '40px' }}
                  >
                    Transaction Value ( in Millions )
                    <span style={{ marginLeft: '100px' }}>
                      <span style={{ color: '#1abd50', fontSize: 15 }}>$</span>
                      <Input
                        onChange={handleInputChange}
                        placeholder={0}
                        onKeyDown={(e) => {
                          if (e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                        type="number"
                        style={{
                          width: 95,
                          fontSize: 15,
                        }}
                        value={txnValue !== 0 ? txnValue : ''}
                        defaultValue={txnValue !== 0 ? txnValue : ''}
                        className={styles.VdailySearch}
                      />
                    </span>
                  </p>
                  <div style={{ width: '600px' }}>
                    {/*<RangeSlider id="range-slider" />*/}
                    <Box sx={{ width: 400, color: 'var(--whitecolor)' }}>
                      <Slider
                        getAriaLabel={() => 'value range'}
                        value={txnValue}
                        step={1}
                        min={0}
                        max={1000}
                        marks={marks}
                        onChange={handleChange}
                        valueLabelDisplay="on"
                      />
                    </Box>
                  </div>
                  <div className={styles.btnSectionBox}>
                    <Button
                      type="primary"
                      style={{ width: '150px', marginTop: '50px' }}
                      htmlType="submit"
                    >
                      Apply
                    </Button>
                  </div>
                </div>
                {/* </Form.Item> */}
                {/*{error && <span style={{ color: red }}>{error}</span>}*/}
              </Row>
            </Form>
          )}
        </div>
      </>
    </>
  );
};

export default Insiders;
