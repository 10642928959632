import React from 'react';

import { Row, Col, Tabs } from 'antd';
import { get } from 'lodash';

import { BarChart, LineChart } from '../../components';
import { green, red, currencyFormat, formatNumber2 } from '../../utils';
import styles from './style.module.scss';

const { TabPane } = Tabs;

interface Props {
  portfolioSnapshotData: any;
  marketValue: any;
  investedValue: any;
  yesterdayValue: any;
  summary: any;
  portfolioMovementData: any;
}

const PortfolioMovement: React.FC<Props> = ({
  portfolioSnapshotData,
  marketValue,
  investedValue,
  yesterdayValue,
  summary,
  portfolioMovementData,
}) => {
  const gainLoss = get(summary, '0.totalReturns', marketValue - investedValue);
  let gainLossPercent = get(
    summary,
    '0.totalReturnsPerc',
    investedValue > 0 ? ((gainLoss / investedValue) * 100).toFixed(2) : 0,
  );

  const oneDayGainLoss = get(
    summary,
    '0.todayReturns',
    marketValue - yesterdayValue,
  );
  let oneDayGainLossPercent = get(
    summary,
    '0.todayReturnsPerc',
    yesterdayValue > 0
      ? ((oneDayGainLoss / yesterdayValue) * 100).toFixed(2)
      : 0,
  );
  let market = '';
  (portfolioMovementData || []).forEach((i) => {
    market = i.market;
  });
  return (
    <Row
      style={{
        // minHeight: 400,
        border: `1px solid ${green}`,
        borderRadius: 20,
        margin: 'auto',
        width: 900,
        padding: 20,
      }}
    >
      <Col span="8" className={styles.portfolioValue}>
        <div className={styles.portfolioMarketValue}>
          <p>Total Market Value</p>
          <h3>{currencyFormat(marketValue, false, false)}</h3>

          <p
            style={{
              color: oneDayGainLoss >= 0 ? green : red,
              //marginBottom: 10,
            }}
          >
            <span>{`Today:  `}</span>
            {`${oneDayGainLoss > 0 ? '+' : ''}${currencyFormat(
              oneDayGainLoss,
              true,
              false,
            )} (${oneDayGainLossPercent > 0 ? '+' : ''}${formatNumber2(
              oneDayGainLossPercent || 0,
            )}%)`}
          </p>
          <p
            style={{
              color: gainLoss >= 0 ? green : red,
              //marginBottom: 10,
            }}
          >
            <span>{`ALL:  `}</span>
            {`${gainLoss > 0 ? '+' : ''}${currencyFormat(
              gainLoss,
              true,
              false,
            )} (${gainLossPercent > 0 ? '+' : ''}${formatNumber2(
              gainLossPercent || 0,
            )}%)`}
          </p>
        </div>
        <div className={styles.portfolioInvestedValue}>
          <p>Invested Amount:</p>
          <p>{currencyFormat(investedValue, false, false)}</p>
        </div>
      </Col>
      <Col span="16" className={styles.vStockTodayInfo}>
        <Tabs tabPosition="bottom" centered id="portfolioMovement">
          <TabPane tab="Price Movement" key="1">
            <LineChart data={portfolioMovementData} />
            {market === '' && (
              <p className={styles.marketIndication}>Market Closed</p>
            )}
            {market === 'pre' && (
              <p
                className={styles.marketIndication}
                // style={{
                //   color: green,
                // }}
              >
                Pre-Market
              </p>
            )}
            {market === 'open' && (
              <p
                className={styles.marketIndication}
                // style={{
                //   color: green,
                // }}
              >
                {/* <span>Pre-Market</span> */}
                {/* <span>Live</span> */}
                Live
              </p>
            )}
            {market === 'post' && (
              <p className={styles.marketIndication}>Today</p>
            )}
          </TabPane>
          <TabPane tab="Portfolio Snapshot" key="2">
            <BarChart data={portfolioSnapshotData} />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default PortfolioMovement;
