import { API } from 'aws-amplify';

import {} from './queries';

export const saveFinancialInfo = (UserInfo) =>
  API.graphql({
    variables: { user: { financialInfo: UserInfo } },
    query: `mutation UpdateUser($user: UserInput) {
      updateUser(user: $user) {
        financialInfo {
          expected_return_range
          experiences
          id
          income_range
          investment_exp
          investment_range
          lost_money
          has_portfolio
        }
      }
    }`,
  }).then(({ data }) => data.updateUser);

export const savePersonalInfo = (UserInfo, address) =>
  API.graphql({
    variables: {
      user: { additionalInfo: UserInfo, userBillingAddress: address },
    },
    query: `mutation UpdateUser($user: UserInput) {
    updateUser(user: $user) {
      additionalInfo {
        id
        mobile
        gender
        marital_status
        country
        profession
        birth_year
        qualification
        race
        image_url
        telegram_id
      }
      userBillingAddress {
        id
        address
        city
        state
        country
        zip
      }
    }
  }`,
  }).then(({ data }) => data.updateUser);

export const getUserPersonalInfo = async () =>
  API.graphql({
    variables: { request: { dashboard: true } },
    query: `query GetUser {
      getUser {
        id
        first_name
        last_name
        email
        type
        additionalInfo {
          id
          mobile
          gender
          marital_status
          country
          profession
          birth_year
          qualification
          race
          image_url
          telegram_id
        }
        userBillingAddress {
          id
          address
          city
          state
          country
          zip
        }
      }
    }`,
  }).then(({ data }) => data.getUser);

export const saveStudentInfo = (studentInfo) =>
  API.graphql({
    variables: {
      user: {
        studentInfo: studentInfo,
      },
    },
    query: `mutation UpdateUser($user: UserInput) {
    updateUser(user: $user) {
      studentInfo {
        id
        school_state
        school_county
        school_name
        school_email
        grade
        graduation_date
        birth_year
        student_id
        student_picture_id
      }
    }
  }`,
  }).then(({ data }) => data.updateUser);

export const getStudentInfo = async () =>
  API.graphql({
    variables: { request: { dashboard: true } },
    query: `query GetUser {
      getUser {
        first_name
        last_name
        email
        type
        studentInfo {
          id
          school_state
          school_name
          school_county
          grade
          graduation_date
          student_id
          student_picture_id
          birth_year
        }
      }
    }`,
  }).then(({ data }) => data.getUser);

export const getUserFinancialInfo = async () =>
  API.graphql({
    variables: { request: { dashboard: true } },
    query: `query GetUser {
      getUser {
        financialInfo {
          expected_return_range
          experiences
          id
          income_range
          investment_exp
          investment_range
          lost_money
          has_portfolio
        }
      }
    }`,
  }).then(({ data }) => data.getUser);
