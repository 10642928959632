import React, { useState } from 'react';

import { Empty, Popconfirm, Modal, message } from 'antd';
import moment from 'moment-timezone';

import { DeleteIcon } from '../../components';
import { deleteAllSavedSearch } from '../../graphql';
import { red, errorHandler } from '../../utils';
import styles from './style.module.scss';

interface Props {
  data: Array<Object>;
  isLoading: Boolean;
  handleDelete: Function;
  redirectToReportPage: Function;
  setFetchData: Function;
}

const SavedSearches: React.FC<Props> = ({
  data,
  isLoading,
  handleDelete,
  redirectToReportPage,
  setFetchData,
}) => {
  const [visible, setVisible] = useState<number>(0);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const onSavedSearchClick = (search) => {
    const { type, subType, request = {} } = search;
    const { filters = {}, symbols } = request;
    redirectToReportPage(type, subType, filters, symbols, '');
  };

  const handleOk = (id) => {
    setConfirmLoading(true);
    handleDelete(
      {
        id: id,
      },
      'saved',
      () => {
        setVisible(0);
        setConfirmLoading(false);
      },
    );
  };

  const handleCancel = () => {
    setVisible(0);
  };

  const showPopconfirm = (id) => {
    setVisible(id);
  };
  const handleAllDelete = () =>
    Modal.confirm({
      title: 'Delete All ',
      content: <p>Are you sure you want to delete All Saved Searches</p>,
      onOk: () => handleDeleteAll(),
    });

  const handleDeleteAll = () =>
    deleteAllSavedSearch()
      .then((res) => {
        message.success('Saved Searches deleted successfully');
        setFetchData(new Date());
      })
      .catch(errorHandler);

  return (
    <>
      {data.length > 0 ? (
        <>
          {data.map((search, i) => {
            const { search_criteria, created_at, name, id } = search;
            return (
              <div
                key={`savedSearches-${i}`}
                className={styles.dashboardReportsListNewSaved}
              >
                <div className={styles.dashboardReportsContent}>
                  <div
                    className={styles.reportsDetails}
                    onClick={() =>
                      onSavedSearchClick(JSON.parse(search_criteria))
                    }
                  >
                    <p>{moment(created_at).format('DD MMM YYYY HH:MM:SS')}</p>
                    <h4>{name}</h4>
                  </div>
                </div>
                <div className={styles.reportsBtn2}>
                  <Popconfirm
                    title="Are you sure delete this search?"
                    visible={visible === id}
                    onConfirm={() => handleOk(id)}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteIcon
                      style={{ color: red }}
                      size={15}
                      onClick={() => showPopconfirm(id)}
                    />
                  </Popconfirm>
                </div>
              </div>
            );
          })}
          <div
            className={styles.vDeleteAllNew}
            key="delete"
            onClick={handleAllDelete}
          >
            Delete All
          </div>
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};
export default SavedSearches;
