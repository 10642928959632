import React, { useState } from 'react';

import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import {
  getCustomerSubscriptionInfo,
  getUserPersonalInfo,
} from '../../graphql';
// import { getUserPersonalInfo } from '../../graphql';
import {
  setUserProfile,
  setLoggedInUserInfo,
  setPersonalData,
  // setPersonalData,
} from '../../redux/reducers/user';
import { errorHandler } from '../../utils';
import LogInForm from './LogInForm';
// import {
//   setFinancialData,
//   setPersonalData,
// } from '../../redux/reducers/portfolio';
// import { isUserProfileComplete } from '../../graphql';

interface Props extends RouteComponentProps<any> {
  onLogIn: Function;
}

const Login: React.FC<Props> = ({ history, onLogIn }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const dispatch = useDispatch();
  const facebookSignIn = () => {
    Auth.federatedSignIn({ provider: 'Facebook' });
  };

  const googleSignIn = async () => {
    await Auth.federatedSignIn({ provider: 'Google' });
  };

  const onFinish = async ({ username, password }) => {
    try {
      setIsLoading(true);
      // await Auth.signOut({ global: true });
      await Auth.signIn(username.toLowerCase(), password);
      const res = await Auth.currentAuthenticatedUser();
      const personalInfo = await getUserPersonalInfo();
      const {
        first_name = '',
        last_name = '',
        email = '',
        type = '',
        additionalInfo = {},
        userBillingAddress = {},
      } = personalInfo;
      const data = {
        userBillingAddress: userBillingAddress ? userBillingAddress : {},
        additionalInfo: additionalInfo ? additionalInfo : {},
        first_name: first_name,
        last_name: last_name,
        email: email,
        type: type,
        date: new Date(),
      };
      await dispatch(setPersonalData(data));
      dispatch(
        setLoggedInUserInfo({
          ...get(res, 'attributes', {}),
          groups: get(
            res,
            'signInUserSession.accessToken.payload.cognito:groups',
            [],
          ),
        }),
      );
      handleRedirection();
      // await onStateChange('signedIn');
      // history.push('/dashboard');
    } catch (e) {
      setIsLoading(false);
      errorHandler(e.message);
      let message =
        e.message === 'User does not exist.'
          ? 'The email or password you entered did not match our records.Please try again.'
          : e.message;
      setError(message);
    }
  };
  const handleRedirection = async () => {
    const user = await getCustomerSubscriptionInfo();
    if (user) {
      await dispatch(setUserProfile(user));
      if (onLogIn) {
        onLogIn();
      }
      // await onStateChange('signedIn');
      // history.push('/dashboard');
    } else {
      setError('Error in Login');
    }
    setIsLoading(false);
  };

  return (
    <LogInForm
      // setFormType={setFormType}
      isLoading={isLoading}
      error={error}
      onFinish={onFinish}
      googleSignIn={googleSignIn}
      facebookSignIn={facebookSignIn}
      setError={(val: string) => setError(val)}
      history={history}
    />
  );
};

export default Login;
