import React, { useEffect, useState } from 'react';

import { Checkbox, Row } from 'antd';
import { get, round } from 'lodash';

import { Table, Pagination } from '../../components';
import { getSectorSymbols } from '../../graphql';
import { useTheme } from '../../ThemeContext';
import {
  errorHandler,
  formatColumn,
  formatTableData,
  green,
  red,
} from '../../utils';
import styles from './style.module.scss';

const SectorSymbolOverview = ({
  selectedSector,
  history,
  filterOptions,
  barGraphData,
}) => {
  const { theme } = useTheme();
  const [loading, setLoading] = useState();
  const [isSP, setIsSP] = useState(false);
  const [sectorSymbols, setSectorSymbols] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortDetail, setSortDetail] = useState([]);
  const { endDate, startDate } = filterOptions;
  useEffect(() => {
    if (selectedSector) {
      setLoading(true);
      const promises = [
        getSectorSymbols({
          filters: {
            sector: selectedSector,
            endDate: endDate, // '2022-01-10',
            startDate: startDate, //'2022-01-07',
            isSP: isSP,
          },
          sort: sortDetail,
          page: pageNo,
          size: pageSize,
        }),
      ];
      Promise.all(promises)
        .then((res) => {
          setSectorSymbols(res[0]);
        })
        .catch(errorHandler)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [endDate, isSP, pageNo, pageSize, selectedSector, sortDetail, startDate]);

  const tableColumns = get(sectorSymbols, 'columns', []);
  const tableData = get(sectorSymbols, 'data', []);
  const total = get(sectorSymbols, 'total', 0);

  const formatColumnsToArray = (columns: Object) => {
    const formattedColumns = Object.keys(columns).map((col, index) => ({
      key: col,
      name: col,
      value: columns[col],
      visible: true, //findColumnVisibility(col),
      index: col === 'symbol' ? 0 : index + 1,
      sort: false,
      // col !== 'status' || col !== 'specialStatus' || col !== 'changeStatus',
    }));
    return formattedColumns;
  };

  const onPaginationChange = (page: number, pageSize: number) => {
    changePageSize(pageSize);
    changePageNo(page);
  };

  const changePageNo = (newPageNo: number) => {
    setPageNo(newPageNo);
  };
  const changePageSize = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    let index = sortDetail.findIndex((d) => d.sortBy === field);
    if (index > -1) {
      let newOrder =
        order === 'ascend' ? 'ASC' : order === 'descend' ? 'DESC' : null;
      handleSort(field, newOrder, false);
    } else {
      if (order === 'ascend') {
        handleSort(field, 'ASC', false);
      } else if (order === 'descend') {
        handleSort(field, 'DESC', false);
      } else {
        handleSort(field, null, false);
      }
    }
  };

  const handleSort = (sortBy: string, sortOrder: string, multi: boolean) => {
    if (multi) {
      let newSort = sortDetail;
      if (sortOrder) {
        newSort = sortDetail.filter((s) => s.sortBy !== sortBy);
        newSort.push({
          sortBy: sortBy,
          sortOrder: sortOrder,
        });
      } else {
        newSort = sortDetail.filter((s) => s.sortBy !== sortBy);
      }
      setSortDetail([...newSort]);
    } else {
      if (sortOrder) {
        setSortDetail([
          {
            sortBy: sortBy,
            sortOrder: sortOrder,
          },
        ]);
      } else {
        setSortDetail([]);
      }
    }
    // setFetchData(new Date());
  };

  const getPercentChange = () => {
    const item = barGraphData.find((d) => d.sector === selectedSector);
    return get(item, 'perChange', '') > 0 ? (
      <span style={{ color: green }}>{`% Change: +${round(
        get(item, 'perChange', ''),
        2,
      )}%`}</span>
    ) : (
      <span style={{ color: red }}>{`% Change: ${round(
        get(item, 'perChange', ''),
        2,
      )}%`}</span>
    );
  };

  const onSPChange = (e) => {
    setIsSP(e.target.checked);
  };
  return (
    <>
      {sectorSymbols && (
        <>
          <Row justify="space-between">
            <h3 className={styles.sectorComponent}>
              {`Components For`}{' '}
              <span style={{ fontSize: 20 }}>{`${selectedSector}.`}</span>{' '}
              {getPercentChange()}
            </h3>
            {/* <Row justify="end"> */}
            <Checkbox checked={isSP} onChange={onSPChange}>
              S&P 500
            </Checkbox>
          </Row>
          <Table
            loading={loading}
            columns={formatColumn(
              formatColumnsToArray(tableColumns),
              true,
              sortDetail,
              history,
              false,
              null,
              'search_report',
              null,
              null,
              theme,
            )}
            // scroll={{ y: y }}
            dataSource={formatTableData(tableData)}
            pagination={false}
            size="small"
            bordered={true}
            onChange={onTableChange}
            rowClassName={styles.tableHeader}
            className={styles.TabularData}
            showSorterTooltip={false}
            id="allPicksId"
          />
          <Pagination
            pageSize={pageSize}
            total={total}
            pageNo={pageNo}
            onChange={onPaginationChange}
            loading={loading}
            showPagination={true}
            reportId={'SECTOR_TRENDING'}
            size={
              window.innerWidth > 1700 || window.innerWidth < 1200
                ? 'default'
                : 'small'
            }
          />
        </>
      )}
    </>
  );
};

export default SectorSymbolOverview;
