import React, { useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';

import { contactUs } from '../../api/RestApi';
// import FAQ from '../faq';
import ContactForm from './ContactForm';
import styles from './styles.module.scss';
import SuccessMessage from './SuccessMessage';

interface payloadType {
  email: string;
  firstName: string;
  lastName: string;
  message: string;
  type: string;
}

const ContactUs: React.FC<Props> = ({ history }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitFeedback = (payload: payloadType) => {
    const promises = [contactUs(payload)];
    setIsLoading(true);
    Promise.all(promises)
      .then((res) => {
        setShowSuccessMessage(true);
      })
      .catch((err) => setError(err.message))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.contactAndFaqContainer}>
      <div
        style={{ marginTop: 50, marginLeft: 50 }}
        className={styles.vGoBackSaveBar}
      >
        <p onClick={() => history.goBack()} className={styles.vGoBackBtnBlock}>
          <span className={styles.vGoBackBtn}>
            <ArrowLeftOutlined /> Go Back
          </span>
        </p>
      </div>
      <div className={styles.contactAndFaqContent}>
        <div className={styles.contactFormBox}>
          <div className={styles.contactFormBoxLeft}>
            <h4 className={styles.contactHeaderText}>
              Good Day!
              <br />
              Thanks for visiting us
            </h4>
            <p className={styles.subHeaderText}>
              {`Have any questions, feedback or new ideas ?`}
            </p>
            <p>{`Please feel free to reach out to us. `}</p>
            {/* <p className={styles.contactText}>
              Mobile:
              <span>+1 434 4567, +1 354 5638</span>
            </p> */}
            <p className={styles.contactSubText}>
              Email:
              <span>
                <a href="mailto:support@thinksabio.com">
                  support@thinksabio.com
                </a>{' '}
              </span>
            </p>
            <p className={styles.contactText}>Address:</p>
            <h5>6512 Six Forks Rd</h5>
            <h5>Suite# 304A,</h5>
            <h5>Raleigh, NC. 27615</h5>
          </div>
          <div className={styles.contactFormBoxRight}>
            {!showSuccessMessage ? (
              <ContactForm
                error={error}
                setError={(val: string) => setError(val)}
                submitFeedback={submitFeedback}
                isLoading={isLoading}
              />
            ) : (
              <SuccessMessage
                handleClick={() => setShowSuccessMessage(false)}
              />
            )}
          </div>
        </div>
        {/* <FAQ /> */}
      </div>
    </div>
  );
};
export default ContactUs;
