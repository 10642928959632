import React, { useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, NavLink } from 'react-router-dom';

import styles from './style.module.scss';

const FreeTrial: React.FC<Props> = ({ history }) => {
  const [showMinerviniText, setShowMinerviniText] = useState<boolean>(false);
  return (
    <>
      <div style={{ minHeight: '90vh' }}>
        <div
          style={{ marginTop: 100, marginLeft: 50 }}
          className={styles.vGoBackSaveBar}
        >
          <p
            onClick={() => history.goBack()}
            className={styles.vGoBackBtnBlock}
          >
            <span className={styles.vGoBackBtn}>
              <ArrowLeftOutlined /> Go Back
            </span>
          </p>
        </div>
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {/*<h4 style={{ marginBottom: '20px', color: '#1abd50' }}>*/}
          {/*  By subscribing to ThinkSabio, investors like you gain:*/}
          {/*</h4>*/}
          <h6
            style={{
              marginBottom: '10px',
              marginTop: '20px !important',
              color: 'var(--whitecolor)',
              width: 1000,
              // margin: 'auto',
              marginRight: 'auto',
              marginLeft: 'auto',
              textAlign: 'left',
            }}
          >
            Have you ever felt anxious planning your finances? Have you wanted
            to unlock new ways to make money so you can be financially free? We
            can help!
          </h6>
          <h6
            style={{
              marginBottom: '10px',
              paddingTop: '20px  !important',
              color: 'var(--whitecolor)',
              width: 1000,
              marginRight: 'auto',
              marginLeft: 'auto',
              textAlign: 'left',
            }}
          >
            We’re ThinkSabio- a subscription service that offers an inside look
            at the stock market and gives investors of any experience level a
            unique edge. In 2023 while the stock market grew 24%, ThinkSabio
            picked 59 stocks that grew 50% or more!
          </h6>
          <br />
          <button
            style={{
              backgroundColor: '#414a4c',
              width: '200px',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '40px',
            }}
            onClick={() => setShowMinerviniText(!showMinerviniText)}
          >
            {showMinerviniText
              ? 'Click here to Show Less'
              : 'Click here to Show more'}
          </button>
          <div hidden={!showMinerviniText}>
            <h5
              style={{
                marginBottom: '40px  !important',
                color: '#1abd50',
                width: 1000,
                marginRight: 'auto',
                marginLeft: 'auto',
                textAlign: 'left',
              }}
            >
              We can help you achieve these results with the help of insight and
              information you can’t get anywhere else, including:
            </h5>
            <br />
            {/*<br />*/}
            <ul style={{ width: '800px', margin: 'auto', textAlign: 'left' }}>
              <li
                style={{
                  color: 'var(--whitecolor)',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  marginTop: '20px  !important',
                }}
              >
                Access to stock pricing, trends and forecasting value on over
                10,000 stocks
              </li>
              <li
                style={{
                  color: 'var(--whitecolor)',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  marginBottom: '20px  !important',
                }}
              >
                Transactions of over 9,000 major financial institutions so you
                can model their success
              </li>
              <li
                style={{
                  color: 'var(--whitecolor)',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  marginBottom: '20px  !important',
                }}
              >
                40 methods and strategies for picking the best performing stocks
                (backed by technical analysis)
              </li>
            </ul>
            {/*<h5 style={{ marginTop: '20px', color: 'var(--disclaimer)' }}>*/}
            {/*  Sign up now for a two-month free trial and get an inside look at the*/}
            {/*  stock market you never knew you needed!*/}
            {/*</h5>*/}
            <br />
            {/*<br />*/}
            <h5
              style={{
                color: 'var(--disclaimer)',
                margin: 'auto',
                width: 1000,
                marginTop: '20px  !important',
              }}
            >
              we’re offering this incredible value in a 7-Days free trial. After
              that, you can keep your membership for the price of two cups of
              coffee.
            </h5>
            <h5 style={{ marginTop: '10px', color: 'var(--disclaimer)' }}>
              Sign up now so you can make the stock market work for you and
              build a bright financial future!
            </h5>
          </div>
        </div>
        <div className={styles.freeTrialCardContainer}>
          <div className={styles.freeTrialCard}>
            <div className={styles.paymentCardContent}>
              <p className={styles.cardTitle}>7-Days Free Trial</p>
              <div className={styles.fee}>
                <p className={styles.amount}>No Credit Card required</p>
              </div>
              <h6>Full access to Web and Telegram Groups</h6>
              <Link to="/sign-up">
                <p className={styles.membershipBtn}>Get Free Access</p>
              </Link>
            </div>
          </div>
          <div style={{ marginLeft: 50 }} className={styles.freeTrialCard}>
            <div className={styles.paymentCardContent}>
              <p className={styles.cardTitle}>Promo Code Sign up</p>
              <div className={styles.fee}>
                <p className={styles.amount}>No Credit Card required</p>
              </div>
              <h6>Full access to Web and Telegram Groups</h6>
              <NavLink
                to={{
                  pathname: '/sign-up',
                  state: {
                    name: {
                      properties: {
                        name: 'promo',
                      },
                    },
                  },
                }}
              >
                <p className={styles.membershipBtn}>Get Promo Code Access</p>
              </NavLink>
            </div>
          </div>
        </div>
        <div
          style={{
            color: 'var(--whitecolor)',
            textAlign: 'center',
            fontSize: '20px',
            marginTop: 0,
            marginBottom: '10px',
          }}
        >
          <span>
            <Link to="/pricing">Click here</Link>{' '}
          </span>
          for our pricing details
        </div>
      </div>
    </>
  );
};

export default FreeTrial;
