// this is an auto generated file. This will be overwritten

export const notifications = /* GraphQL */ `
  subscription Notifications($email: String!) {
    notifications(email: $email) {
      title
      body
      createdAt
      read
      email
      type
    }
  }
`;
export const notificationsByType = /* GraphQL */ `
  subscription NotificationsByType($type: AlertType!) {
    notificationsByType(type: $type) {
      title
      body
      createdAt
      read
      email
      type
    }
  }
`;
export const newAlert = /* GraphQL */ `
  subscription NewAlert {
    newAlert {
      title
      body
      createdAt
      read
      email
      type
    }
  }
`;
