import React, { useEffect, useState } from 'react';

// import { DownloadOutlined } from '@ant-design/icons';
import { Empty, Row, Select, List } from 'antd';
import { get } from 'lodash';
import moment from 'moment-timezone';
// import { CSVLink } from 'react-csv';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import BounceLoader from 'react-spinners/BounceLoader';

import { getTransactionHistory } from '../../../graphql';
import { setPortfolioHistory } from '../../../redux/reducers/portfolio';
import { green, red, errorHandler, currencyFormat } from '../../../utils';
import {
  TransactionHistoryData,
  TransactionHistoryType,
} from '../portfolio.types';
import styles from './style.module.scss';
const { Option } = Select;

interface Props {
  transactionHistory: TransactionHistoryType;
  portfolioData: Object;
}

const TransactionHistory: React.FC<Props> = ({ portfolioData }) => {
  const { transactionHistory } = useSelector(
    ({ portfolio }) => ({
      transactionHistory: get(portfolio, 'transactionHistory', {}),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);
  const [transactionType, setTransactionType] = useState<string>('both');
  const [tableData, setTableData] = useState(transactionHistory);
  const [selectedSymbols, setSelectedSymbols] = useState<Array<string>>([]);

  useEffect(() => {
    setTableDataLoading(true);
    let payload = {
      symbols: selectedSymbols,
    };
    if (transactionType === 'buy' || transactionType === 'sell') {
      payload.type = transactionType === 'buy' ? 'A' : 'D';
    }

    const apis = [getTransactionHistory(payload)];
    Promise.all(apis)
      .then((res) => {
        setTableData(res[0]);
        dispatch(setPortfolioHistory(res[0]));
      })
      .catch(errorHandler)
      .finally(() => {
        setTableDataLoading(false);
      });
  }, [dispatch, selectedSymbols, transactionType]);

  // const changeType = (val) => {
  //   setType(val);
  // };
  const rows = get(tableData, 'data', []);

  const handleSelect = (symbol: string) => {
    setSelectedSymbols([...selectedSymbols, symbol]);
  };

  const handleDeSelect = (symbol: string) => {
    const newSymbols = selectedSymbols.filter((s) => s !== symbol);
    setSelectedSymbols(newSymbols);
  };

  const handleChange = (symbols: [string]) => {
    if (!symbols.length) {
      setSelectedSymbols([]);
    }
  };
  const symbols = portfolioData
    .filter((i) => i.symbol !== 'Total')
    .map((d) => d.symbol);

  return (
    <>
      <Row
        span={24}
        justify="center"
        style={{ marginBottom: 10, marginTop: 20 }}
      >
        <Select
          size="middle"
          placeholder="Type"
          defaultValue="both"
          style={{ width: 150, marginRight: 50 }}
          onChange={(key) => setTransactionType(key)}
        >
          <Option value="buy">BUY</Option>
          <Option value="sell">SELL</Option>
          <Option value="both">{`BUY&SELL`}</Option>
        </Select>

        <Select
          mode="tags"
          size="default"
          placeholder="Please select Symbol"
          className={styles.vSelectSymbolNew}
          showArrow
          allowClear={true}
          showSearch={false}
          onChange={(value: [string]) => handleChange(value)}
          onSelect={(value: string) => handleSelect(value)}
          onDeselect={(value: string) => handleDeSelect(value)}
        >
          {symbols.map((s) => (
            <Option key={s}>{s}</Option>
          ))}
        </Select>

        {/* <CSVLink
            filename={'Transaction History.csv'}
            data={rows}
            className={styles.vDownloadN1}
          >
            <Button>
              <DownloadOutlined /> Download
            </Button>
          </CSVLink> */}
      </Row>

      {tableDataLoading && (
        <span className={styles.spinnerStyle}>
          <BounceLoader color={green} loading={true} size={50} />
        </span>
      )}
      <div>
        {rows.length > 0 ? (
          <List
            dataSource={rows}
            renderItem={(item: TransactionHistoryData) => (
              <List.Item
                key={item.transactionTime}
                style={{ width: 500, margin: 'auto' }}
                id="transaction_history"
              >
                <div>
                  <span style={{ fontWeight: '600' }}>
                    {`${item.symbol} (${item.name})`}{' '}
                  </span>
                  <span
                    style={{
                      color: item.type === 'SELL' ? red : green,
                      fontWeight: '600',
                    }}
                  >{`${item.type === 'SELL' ? '  SELL' : '  BUY'}`}</span>
                  <br />
                  <span>
                    {moment(item.transactionTime).format(
                      'YYYY-MM-DD hh:MM:SS A',
                    )}
                  </span>
                </div>
                <div style={{ width: 150, textAlign: 'left' }}>
                  <span style={{ fontWeight: '600' }}>
                    {`${currencyFormat(+item.quantity * +item.price)}`}{' '}
                  </span>
                  <br />
                  <span>
                    {`${item.quantity} Shares at ${currencyFormat(
                      +item.price,
                    )}`}{' '}
                  </span>
                </div>
              </List.Item>
            )}
          ></List>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </>
  );
};

export default TransactionHistory;
