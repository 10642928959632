import React from 'react';

const Navigation = ({ items, activeCharacter, handleCLick }) => {
  const renderItems = () => {
    return items.map((item) => {
      const activeClass =
        activeCharacter === item ? 'navigation-list__item--active' : '';
      return (
        // <li>
        //   <a href="#community"></a>
        // </li>

        <li
          key={item}
          id={item}
          onClick={() => handleCLick(item)}
          className={`navigation-list__item ${activeClass}`}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a href={`#${item}`}></a>
        </li>
      );
    });
  };
  return <ul className="navigation-list">{renderItems()}</ul>;
};

export default Navigation;
