import React, { useState, useEffect } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Card, Row, Col } from 'antd';
import { get } from 'lodash';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { Table } from '../../components';
import {
  getUserPortFolio,
  getUserWalletBalances,
  getPortfolioSnapshot,
  getSymbolDetail,
} from '../../graphql';
import {
  setUserPortfolioData,
  setPortfolioSnapshot,
} from '../../redux/reducers/portfolio';
import { setUserWalletBalance } from '../../redux/reducers/user';
import { useTheme } from '../../ThemeContext';
import {
  green,
  errorHandler,
  formatColumn,
  currencyFormat,
  formatTableData,
} from '../../utils';
import { arrangeColumns } from '../searchReport/SearchReport.constants';
// import StockPageSidebarReal from './StockPageSidebarReal';
import StockPageSidebarVirtual from './StockPageSidebarVirtual';
import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {}

const StockPage: React.FC<Props> = ({ match, history }) => {
  const { portfolioData, wallet } = useSelector(
    ({ portfolio, user }) => ({
      portfolioData: get(portfolio, 'data.virtual', {}),
      wallet: get(user, 'wallet', {}),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const [investIn, setInvestIn] = useState('share');
  const [fetchPortfolioData, setFetchPortfolioData] = useState<Date>(
    new Date(),
  );
  const symbol = get(match, 'params.symbol');
  const portfolioType = 'virtual'; //  get(match, 'params.type', 'real');
  const stockDataFromPortfolio = get(portfolioData, `data`, []).filter(
    (i) => i.symbol === symbol,
  );
  const availableShare = get(stockDataFromPortfolio[0], 'no_of_shares', 0);
  const [activeKey, setActiveKeyKey] = useState(
    portfolioType === 'virtual' ? 'buy' : availableShare ? 'edit' : 'add',
  );
  const [symbolData, setSymbolData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPortfolioData, setLoadingPortfolioData] = useState(false);
  const tabList = (portfolioType) => {
    let list = [];
    if (portfolioType === 'virtual') {
      list = [
        {
          key: 'buy',
          tab: `Buy ${symbol}`,
        },
      ];
      if (availableShare) {
        list.push({
          key: 'sell',
          tab: `Sell ${symbol}`,
        });
      }
    }
    if (portfolioType === 'real') {
      list = [
        {
          key: 'add',
          tab: `Add ${symbol}`,
        },
      ];
      if (availableShare) {
        list = [
          {
            key: 'edit',
            tab: `Edit ${symbol}`,
          },
        ];
      }
    }
    return list;
  };

  const onTabChange = (key) => {
    setActiveKeyKey(key);
  };

  useEffect(() => {
    setIsLoading(true);
    const apis = [getSymbolDetail(symbol)];
    Promise.all(apis)
      .then((res) => {
        setSymbolData(res[0]);
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [symbol]);

  useEffect(() => {
    setLoadingPortfolioData(true);
    const payload = {
      type: portfolioType.toUpperCase(),
      // name: portfolioName,
    };

    const apis = [
      getUserPortFolio(payload),
      getPortfolioSnapshot(payload),
      getUserWalletBalances(payload),
    ];
    Promise.all(apis)
      .then((res) => {
        dispatch(setUserPortfolioData(res[0], portfolioType));
        dispatch(setPortfolioSnapshot(res[1], portfolioType));
        dispatch(setUserWalletBalance(res[2], portfolioType));
      })
      .catch(errorHandler)
      .finally(() => {
        setLoadingPortfolioData(false);
      });
  }, [dispatch, fetchPortfolioData, portfolioType]);
  return (
    <div className={styles.vStocksBlockPageNew}>
      <p
        onClick={() => history.goBack()}
        style={{
          // marginLeft: 30,
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
      >
        <span className={styles.vGoBackBtn}>
          <ArrowLeftOutlined /> Go Back
        </span>
      </p>

      <Row span={24}>
        <Col
          xs={{ span: 24 }}
          md={{ span: 16 }}
          lg={{ span: 14 }}
          className={styles.vStockdescNew}
        >
          {isLoading ? (
            <div className={styles.loaderStyle}>
              <BounceLoader color={green} loading={isLoading} size={150} />
            </div>
          ) : (
            <>
              <h2>{`${get(symbolData, 'company', '')}(${symbol})`}</h2>
              <p>{currencyFormat(get(symbolData, 'latestPrice', 0) / 100)}</p>
              <Table
                scroll={{ y: true }}
                columns={formatColumn(
                  arrangeColumns(get(symbolData, 'quarterlyCounts.columns')),
                  false,
                  [],
                  history,
                  false,
                  false,
                  'symbol_detail',
                  null,
                  null,
                  theme,
                )}
                dataSource={formatTableData(
                  get(symbolData, 'quarterlyCounts.data'),
                )}
                pagination={false}
                size="small"
                bordered={true}
                loading={isLoading}
                className={styles.vStockTableNew}
                style={{ margin: '0 !important' }}
              />
            </>
          )}
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 10 }}>
          <h2 className={styles.portfolioType}>{`You are on ${
            portfolioType === 'virtual' ? 'Virtual' : 'Real'
          } Portfolio`}</h2>
          <Card
            style={{ width: '100%' }}
            tabList={tabList(portfolioType)}
            activeTabKey={activeKey}
            onTabChange={(key) => {
              onTabChange(key);
            }}
            // loading={loadingPortfolioData}
          >
            {/* {portfolioType === 'virtual' && ( */}
            <StockPageSidebarVirtual
              investIn={investIn}
              type={activeKey}
              setInvestIn={(val) => setInvestIn(val)}
              stockDataFromPortfolio={stockDataFromPortfolio[0]}
              portfolioType={portfolioType}
              wallet={get(wallet, `${portfolioType}`, {})}
              symbol={symbol}
              setFetchPortfolioData={() => setFetchPortfolioData(new Date())}
              currentPrice={get(symbolData, 'latestPrice', 0) / 100}
              loading={loadingPortfolioData}
              history={history}
            />
            {/* )} */}
            {/* {portfolioType === 'real' && (
              <StockPageSidebarReal
                // investIn={investIn}
                type={activeKey}
                // setInvestIn={(val) => setInvestIn(val)}
                stockDataFromPortfolio={stockDataFromPortfolio[0]}
                portfolioType={portfolioType}
                // wallet={wallet}
                symbol={symbol}
                setFetchPortfolioData={() => setFetchPortfolioData(new Date())}
                history={history}
              />
            )} */}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default StockPage;
