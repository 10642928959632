import React, { useState, useContext } from 'react';

import { ArrowLeftOutlined, FilterOutlined } from '@ant-design/icons';
import { Row, Col, Breadcrumb, Button } from 'antd';
import BounceLoader from 'react-spinners/BounceLoader';

// import CustomLoaderGif from '../../assets/loader.gif';
import { green } from '../../utils';
import CommonHeader from './CommonHeader';
import SaveReport from './SaveReport';
import {
  getSearchReportTitle,
  NonClickAbleFilter,
} from './SearchReport.constants';
import { SearchReportContext } from './SearchReportContext';
import SearchReportFilterForm from './SearchReportFilterForm';
import styles from './style.module.scss';
import SymbolWeeklyGainsView from './SymbolWeeklyGains';
import WeeklyGainsDataView from './weeklyGainsReport';

const WeeklyGainsView = () => {
  const {
    searchData = {},
    isLoading,
    currentSearchCriteria,
    reportType,
    reportSubType,
    history,
    reportTitle,
    fetchReportName,
    fetchingName,
    filterOptions,
    queryData,

    onFilter,
    sortDetail,
    selectedNoOfQtrs,
    tableColumns,
  } = useContext(SearchReportContext);
  const [openFilterForm, setOpenFilterForm] = useState<boolean>(false);
  // const [selectedSector, setSelectedSector] = useState('');
  const { data = [] } = searchData;
  const breadcrumb = ['WEEKLY_GAINS_REPORT'];

  const handleFilterValueClick = (name, value) => {
    let updatedFilters = { ...filterOptions };
    if (name === 'symbols') {
      updatedFilters = {
        ...filterOptions,
        symbols: Array.isArray(updatedFilters.symbols)
          ? updatedFilters.symbols.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'week') {
      updatedFilters = {
        ...filterOptions,
        week: Array.isArray(updatedFilters.week)
          ? updatedFilters.week.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minStartPrice') {
      updatedFilters = {
        ...filterOptions,
        minStartPrice: Array.isArray(updatedFilters.minStartPrice)
          ? updatedFilters.minStartPrice.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxStartPrice') {
      updatedFilters = {
        ...filterOptions,
        maxStartPrice: Array.isArray(updatedFilters.maxStartPrice)
          ? updatedFilters.maxStartPrice.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'minEndPrice') {
      updatedFilters = {
        ...filterOptions,
        minEndPrice: Array.isArray(updatedFilters.minEndPrice)
          ? updatedFilters.minEndPrice.filter((s) => s !== value)
          : undefined,
      };
    } else if (name === 'maxEndPrice') {
      updatedFilters = {
        ...filterOptions,
        maxEndPrice: Array.isArray(updatedFilters.maxEndPrice)
          ? updatedFilters.maxEndPrice.filter((s) => s !== value)
          : undefined,
      };
    } else {
      delete updatedFilters[name];
    }
    onFilter(updatedFilters);
  };

  const handleReset = () => {
    onFilter({ noOfWeeks: null }, [], false, true);
  };
  const mandatoryFilters = NonClickAbleFilter(reportSubType || reportType);
  const isFilterEmpty = Object.keys(filterOptions).filter((d) =>
    d === 'symbols' ||
    d === 'week' ||
    d === 'minStartPrice' ||
    d === 'maxStartPrice' ||
    d === 'minEndPrice' ||
    d === 'maxEndPrice'
      ? filterOptions[d] && filterOptions[d].length === 0
        ? undefined
        : filterOptions[d]
      : mandatoryFilters.includes(d)
      ? undefined
      : filterOptions[d],
  );

  return (
    <div className={styles.vReportsNewPage1}>
      <div className={styles.vGoBackSaveBar}>
        <p
          onClick={() =>
            history.push({
              pathname: '/search',
              state: {
                type: reportType,
                subType: reportSubType,
                prevPage: 'searchReport',
              },
            })
          }
          className={styles.vGoBackBtnBlock}
        >
          {history.length > 2 && (
            <span className={styles.vGoBackBtn}>
              <ArrowLeftOutlined /> Go Back
            </span>
          )}
        </p>

        {!isLoading && (
          <div className={styles.SaveReportBoxNew2}>
            <SaveReport
              searchCriteria={currentSearchCriteria}
              reportType={reportType}
              reportSubType={reportSubType}
              reportTitle={reportTitle}
              fetchReportName={fetchReportName}
              fetchingName={fetchingName}
            />
          </div>
        )}
      </div>
      <div className={styles.symbolSearchContentBox}>
        <Breadcrumb>
          {breadcrumb.map((item) => (
            <Breadcrumb.Item key={item}>{item}</Breadcrumb.Item>
          ))}
        </Breadcrumb>

        <div className={styles.trendingSectorTitleContainer}>
          <h2>{getSearchReportTitle(reportSubType).toUpperCase()}</h2>

          <div className={styles.vMobFilterNew}>
            <Button
              icon={<FilterOutlined />}
              // size={}
              onClick={() => {
                setOpenFilterForm(!openFilterForm);
              }}
              //style={{ marginRight: 14 }}
              className={styles.CommonBtnsFilter}
              disabled={isLoading}
            >
              Filter/Sort
            </Button>
          </div>
        </div>

        {isFilterEmpty.length > 0 && (
          <div className={styles.filterValueBox}>
            <CommonHeader
              handleClick={handleFilterValueClick}
              handleReset={handleReset}
            />
          </div>
        )}
      </div>
      <Row span={24} id={reportSubType}>
        <Col span={24} style={{ marginTop: 20 }}>
          {!isLoading || (data || []).length > 0 ? (
            <Row justify="start">
              {
                <Col sm={{ span: 24 }} xl={{ span: 12 }} style={{ border: 1 }}>
                  {/*{isLoading && (*/}
                  {/*  <div className={styles.miniLoaderStyle}>*/}
                  {/*    <BounceLoader*/}
                  {/*      color={green}*/}
                  {/*      loading={isLoading}*/}
                  {/*      size={50}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*)}*/}
                  <WeeklyGainsDataView
                    history={history}
                    isLoading={isLoading}
                    filterOptions={filterOptions}
                    tableColumns={tableColumns}
                    searchData={searchData}
                  />
                </Col>
              }

              <Col sm={{ span: 24 }} xl={{ span: 12 }} style={{ border: 1 }}>
                <SymbolWeeklyGainsView
                  history={history}
                  isLoading={isLoading}
                  filterOptions={filterOptions}
                  tableColumns={tableColumns}
                  searchData={searchData}
                />
              </Col>
            </Row>
          ) : (
            <div className={styles.loaderStyle}>
              <BounceLoader color={green} loading={isLoading} size={150} />
            </div>
          )}
        </Col>
        {openFilterForm && (
          <SearchReportFilterForm
            visible={openFilterForm}
            onCancel={() => setOpenFilterForm(false)}
            onFilter={onFilter}
            filterOptions={filterOptions}
            queryData={queryData}
            reportSubType={reportSubType}
            columns={tableColumns.filter(
              (col) => col.visible === true && col.value,
            )}
            sortDetail={sortDetail}
            // handleSortReset={() => setSortDetail([])}
            // handleSortSubmit={(val) => setSortDetail(val)}
            reportType={reportType}
            selectedNoOfQtrs={selectedNoOfQtrs}
          />
        )}
      </Row>
    </div>
  );
};

export default WeeklyGainsView;
