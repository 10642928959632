import React from 'react';

import { Row, Col } from 'antd';

import {
  Camera,
  DownArrow,
  unName,
  UpArrow,
  usa,
  usa1,
  phoenix,
  pen,
  bulb,
} from '../../images';
import styles from './style.module.scss';

const FounderStory: React.FC<Props> = () => {
  const [view, setView] = React.useState('0');
  // const [setTime, setTimeOut] = React.useState(false);
  const [fadeArrow, setFadeArrow] = React.useState(true);

  const handleShowView = () => {
    switch (view) {
      case '0':
        return (
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 7 }}></Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 15 }}>
              <div className={styles.container}>
                <div className={styles.vFounderPic}>
                  <img className={styles.fadeInText} src={unName} alt="Snow" />
                </div>
                <div className={`${styles.topLeft}`}>
                  <div className={styles.founderStoryBannerText}>
                    <h2 className={styles.fadeInText}>Founder of</h2>
                    <h3 className={styles.fadeInText}>ThinkSabio</h3>
                  </div>
                  <div className={styles.founderStoryBannerTextBottom}>
                    <h2 className={styles.fadeInText}>Sairam</h2>
                    <h3 className={styles.fadeInText}>Yeruva</h3>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        );
      case '1':
        return (
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 7 }}></Col>
            <Col xs={{ span: 24 }} sm={{ span: 17 }} lg={{ span: 15 }}>
              <h2 className={styles.fadeInText}>The Sketch</h2>
              <div className={styles.fadeInText}>
                <h4>
                  <span>M</span>ay 30th, 2018
                </h4>
                <p className={styles.fadeInText}>
                  Not being an avid reader, I was surprisingly captivated by an
                  article in CNBC about the greatest investors of all time, Jim
                  Simons! I was fascinated by his story, his quant fund
                  Renaissance Technologies, and its long-term returns. Digging
                  deep, I found a lot of information was right in front of me as
                  publicly accessible documents. If Mr. Simons can invest $XXX
                  and earn $XX, I am ok to simulate his investments with $xx and
                  make $x. Simple enough right? Well, it may not be that simple,
                  but the concept got me hooked. I started researching into more
                  institutions, opened a trading account and started testing my
                  theories. Then USCIS opened an investigation on me for
                  Immigration issues. Wait what?? <br />
                  OK Let’s pause and ride from the beginning.
                </p>
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 7 }} lg={{ span: 2 }}>
              <div className={styles.sketchBoxImage}>
                <img className={styles.fadeInText} src={pen} alt="The Sketch" />
              </div>
            </Col>
          </Row>
        );
      case '2':
        return (
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 7 }}></Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 15 }}>
              <h2 className={styles.fadeInText}>Basics</h2>
              <div className={styles.vBasicsBlock}>
                <div className={styles.vBasicsContent}>
                  <p className={styles.fadeInText}>
                    <span>I</span> hail from Pedapalakaluru village in Guntur
                    District of Andhra Pradesh, India. Our situation can be
                    gleaned from the fact that I received financial help from
                    Sri Kommineni Srinivas for my Bachelors and had to pawn my
                    mother’s ornaments for my Mainframe training. During my
                    final semester of MCA, I bagged software job in Chennai. My
                    happiness was short lived as I lost my inspiration, my
                    father, to a heart attack.
                  </p>
                </div>
                <div className={styles.vBasicsImg}>
                  <img className={styles.fadeInText} src={unName} alt="Snow" />
                </div>
              </div>
              <div className={styles.vBasicsBottomContent}>
                <p className={styles.fadeInText}>
                  I have always believed that the best way to excel at anything
                  is to teach it to others. So, I started an institution in
                  Guntur and commuted over the weekends from Chennai to teach
                  Mainframe. At the end of that stint, I ended up helping many
                  of my friends finding jobs, gained a ton of experience and a
                  debt of INR 2 lakhs. To repay the debt, my friends suggested I
                  should look for opportunities in US.
                </p>
              </div>
            </Col>
          </Row>
        );
      case '3':
        return (
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 7 }}></Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 15 }}>
              <div className={styles.vUsaTitle}>
                <h2 className={styles.fadeInText}>
                  USA <br />
                  Here I Come!!
                </h2>
              </div>
              <Row>
                <Col span={22}>
                  <div
                  // className={styles.slideDown}
                  >
                    <p className={`${styles.vUsaContent} ${styles.fadeInText}`}>
                      <span>I</span> came to USA in Jan of 1998 and worked at
                      BCBSNC for 10 years as a software professional. Before
                      coming to US, there was a felicitation as I was the first
                      person in my village to cross the boundaries of the
                      country. At that gathering, in a fit of raging emotion, I
                      pledged to build a high school and end the woes of
                      aspiring children who, until then, had to travel to a
                      different city after 7th grade or quit education. My
                      antics can be watched here!
                    </p>
                  </div>
                </Col>
                <Col>
                  <div className={styles.usaImage}>
                    <img
                      className={styles.fadeInText}
                      src={usa}
                      alt="sketch.png"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      case '4':
        return (
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 7 }}></Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 15 }}>
              <div className={styles.vFundsBlock}>
                <p className={styles.fadeInText}>
                  <span>W</span>ith funds from friends and well-wishers, hard
                  work, blood, sweat and tears from my family members, we
                  achieved the mammoth task of establishing the school in just
                  under 3 years. The school is now equipped with the latest
                  teaching aids, computers and every year the economically poor
                  and meritorious students are awarded scholarships.
                </p>
                <div className={styles.vFundsPic}>
                  <img className={styles.fadeInText} src={usa1} alt="USA 1" />
                </div>
              </div>
              <div className={styles.vFundsBottomContent}>
                <p>
                  In 2011, we started a non-profit organization called Drive
                  Education NC and raised funds under the name of Talent for
                  Education to support local public schools in Durham and Wake
                  Counties in NC. During the years of 2011 and 2013, a sum of
                  $42,000 was distributed among local schools. Further details
                  at “Talent for Education -2013 - What and Why”
                </p>
                <p>
                  In year 2012, after having 2 children of my own, we realized
                  the importance and necessity of educating the next generation
                  even more. So, we decided to expand our reach. With yearly
                  fund-raising efforts, we now support about 1800 students from
                  42 schools who are meritorious but economically poor. The
                  total collections and distributions aggregated to $385,580
                  over the period of 2012-2019. The details of our incredible
                  and extremely rewarding journey can be found at
                  https://www.ykrscholarships.com/new/
                </p>
              </div>
            </Col>
          </Row>
        );
      case '5':
        return (
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 7 }}></Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 15 }}>
              <div
              // className={styles.slideDown}
              >
                <div className={styles.vDarkAlleyBlock}>
                  <div className={styles.vDarkAlleyContent}>
                    <h2>A Dark Alley</h2>
                    <p>
                      <span>2</span>018, things were going smoothly, I had
                      worked tirelessly over the past 9 years to build 3
                      consulting companies with employees who did not see me as
                      their employer but as their friend and trusted confidante.
                      In the torrent of everything, I failed to follow the
                      immigration laws and was indicted for filing In-House
                      petitions for H1B visa to USCIS in 2010. I accepted and
                      took full responsibility of my misdoing, not my proudest
                      achievement. I have served my sentence of 1year and a day.
                    </p>
                  </div>
                  <div className={styles.vDarkAlleyPic}>
                    <img src={bulb} alt="Bulb" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        );
      case '6':
        return (
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 7 }}></Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 15 }}>
              <h2 className={`${styles.vPhoenixTitle} ${styles.fadeInText}`}>
                ThinkSabio – <br />
                My Phoenix
              </h2>
              <div>
                <div className={`${styles.vPhoenixBlock}`}>
                  <p className={styles.fadeInText}>
                    <span>T</span>hinkSabio was born in the arduous, tumultuous
                    period during my court proceedings. This was my symbolic
                    rebirth from the ashes, my drive to move forward. I was not
                    going to let one misstep parenthesize my life. The
                    investment theories I was experimenting and sharing with few
                    friends over Whatsapp is now shaping into a full-blown
                    project with growing members and requests of multiple
                    reports. Over the past 2 years, I am fortunate to have an
                    amazing team who have worked relentlessly to keep the embers
                    burning.
                  </p>
                  <div className={styles.vPhoenixPic}>
                    <img
                      className={styles.fadeInText}
                      src={phoenix}
                      alt="Phoenix"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        );
      case '7':
        return (
          <Row>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 7 }}></Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 17 }}>
              <h2 className={styles.fadeInText}>The Road Ahead</h2>
              <div
              // className={styles.slideDown}
              >
                <div className={`${styles.vRoadAhead} ${styles.fadeInText}`}>
                  <p>
                    During my time at the Federal Satellite prison camp, I came
                    across another sad-but-true fact. Majority of the inmates
                    don't choose this path. Lack of opportunities or skillset
                    and the bait of easy money makes them pick the wrong
                    choices. A Barron’s article stated “31% of African-American
                    households headed by non-college graduates had stock
                    ownership as against 56% white households of non-college
                    graduates and 62% of African-American households headed by
                    college graduates had stock ownership as against 86% white
                    households of non-college graduates.”
                  </p>
                  <p>
                    The over-arching funda in my life, if y’all had not gathered
                    from my story thus far, is “Go big or go home!” I have now
                    firmly resolved to raise awareness and change the above
                    stated statistic. Accordingly, I formed a team and helped
                    few inmates in understanding the concept that you do not
                    need a million-dollar capital and an Armani suit to start
                    stock investments. My next step is to reach out to schools
                    and educate the students at the grass root level, mainly to
                    underprivileged and minority class about investing at an
                    early stage of life. I believe we, ThinkSabiens, as a family
                    can because We Can!!
                  </p>
                  <h3>
                    Until next time!
                    <br />
                    Yours Truly,
                    <br />
                    Sairam Yeruva
                  </h3>
                </div>
              </div>
            </Col>
          </Row>
        );
      default:
        return <div> </div>;
    }
  };
  return (
    <div className={styles.vPageAlignment}>
      <div>
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 4 }}>
            <Row>
              <Col span={24}>
                <div>
                  <div className={styles.vCamereBlock}>
                    <img src={Camera} alt="camera.png" />
                  </div>
                </div>
              </Col>
              {/* <Col span={12}>
                <div className={`${styles.cone}`}></div>
              </Col> */}
            </Row>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 20 }}>
            <div
              className={`${styles.mainContainer} ${styles.theSketch}`}
              // style={{ display: setTime ? 'none' : 'block' }}
            >
              <div className={styles.fadeInText}>
                <div className={styles.upArrowAlignment}>
                  {view > '0' && fadeArrow ? (
                    <img
                      className={styles.fadeInText}
                      src={UpArrow}
                      alt="Up"
                      style={{
                        fontSize: '40px',
                        marginLeft: '30%',
                        paddingLeft: '20%',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setFadeArrow(false);
                        setView(
                          view === '0' ? '0' : (parseInt(view) - 1).toString(),
                        );
                        setTimeout(() => {
                          setFadeArrow(true);
                        }, 100);
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <div className={styles.vTopMar}>{handleShowView()}</div>
              <div className={styles.fadeInText}>
                <div className={styles.downAlignment}>
                  {view !== '7' && fadeArrow ? (
                    <img
                      className={styles.fadeInText}
                      src={DownArrow}
                      alt="Down"
                      style={{
                        fontSize: '40px',
                        marginLeft: '30%',
                        paddingLeft: '20%',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setFadeArrow(false);
                        setView(
                          view === '7' ? '0' : (parseInt(view) + 1).toString(),
                        );
                        setTimeout(() => {
                          setFadeArrow(true);
                        }, 1000);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* New page content here */}
      {/*<FounderStoryNew />*/}
    </div>
  );
};

export default FounderStory;
