import { API } from 'aws-amplify';

import {
  savedReportName,
  searchSymbolCompare,
  searchSymbol,
  search,
  searchOptionBySymbols,
  esSearchCompany as searchCompany,
  esSearchInstitution as searchInstitute,
  esSearchTop10Institution as searchTop10Institution,
  esSearchActiveCompanies,
  esSearchInstitutionAll as searchAllInstitute,
  getSymbolsBySector,
  getSymbolWeeklyGainsReport,
  getWeeklyGainsReport,
  pricesData,
} from './queries';
export const getSearchSymbolData = async (symbol) =>
  API.graphql({
    variables: { symbol: symbol },
    query: searchSymbol,
  }).then(({ data }) => data.searchSymbol);

export const getSearchSymbolCompareData = async (symbols) =>
  API.graphql({
    variables: { symbols: symbols },
    query: searchSymbolCompare,
  }).then(({ data }) => data.searchSymbolCompare);

export const getOptionBySymbols = async (symbols) =>
  API.graphql({
    variables: { symbols: symbols },
    query: searchOptionBySymbols,
  }).then(({ data }) => data.searchOptionBySymbols);

export const commonSearchQuery = async (query) =>
  API.graphql({
    variables: { request: query },
    query: search,
  }).then(({ data }) => data.search);

export const esSearchCompany = (value) =>
  API.graphql({
    variables: { keyword: value },
    query: searchCompany,
  }).then(({ data }) => data.esSearchCompany);

export const esSearchActiveCompany = (value) =>
  API.graphql({
    variables: { keyword: value },
    query: esSearchActiveCompanies,
  }).then(({ data }) => data.esSearchActiveCompanies);

export const esSearchInstitution = (value) =>
  API.graphql({
    variables: { keyword: value },
    query: searchInstitute,
  }).then(({ data }) => data.esSearchInstitution);

export const esSearchAllInstitution = (value) =>
  API.graphql({
    variables: { keyword: value },
    query: searchAllInstitute,
  }).then(({ data }) => data.esSearchInstitutionAll);

export const esSearchTop10Institution = (value) =>
  API.graphql({
    variables: { keyword: value },
    query: searchTop10Institution,
  }).then(({ data }) => data.esSearchTop10Institution);

export const getRecentAndSavedSearches = async () =>
  API.graphql({
    variables: { request: { dashboard: true } },
    query: `query GetUser {
        getUser {
          recentSearches {
            id
            search_criteria
            title
            displayText
            created_at
          }
          savedSearches {
            search_criteria
            name,
            id
            created_at
          }
        }
      }`,
  }).then(({ data }) => data.getUser);

export const saveSearch = (search) =>
  API.graphql({
    variables: { user: { saveSearch: [search] } },
    query: `mutation UpdateUser($user: UserInput) {
    updateUser(user: $user) {
      message
    }
  }`,
  }).then(({ data }) => data.updateUser);

export const deletedSavedSearch = (id: string) =>
  API.graphql({
    variables: { user: { deletedSavedSearch: [id] } },
    query: `mutation UpdateUser($user: UserInput) {
    updateUser(user: $user) {
      message
    }
  }`,
  }).then(({ data }) => data.updateUser);

export const deletedRecentSearch = (id: string) =>
  API.graphql({
    variables: { user: { deletedRecentSearch: [id] } },
    query: `mutation UpdateUser($user: UserInput) {
    updateUser(user: $user) {
      message
    }
  }`,
  }).then(({ data }) => data.updateUser);

export const deleteAllSavedSearch = () =>
  API.graphql({
    variables: { user: { deletedAllSavedSearch: { delete: true } } },
    query: `mutation UpdateUser($user: UserInput) {
    updateUser(user: $user) {
      message
    }
  }`,
  }).then(({ data }) => data.updateUser);

export const deleteAllRecentSearch = () =>
  API.graphql({
    variables: { user: { deletedAllRecentSearch: { delete: true } } },
    query: `mutation UpdateUser($user: UserInput) {
    updateUser(user: $user) {
      message
    }
  }`,
  }).then(({ data }) => data.updateUser);

export const getSavedSearchName = (searchCriteria) =>
  API.graphql({
    variables: { searchCriteria: searchCriteria },
    query: savedReportName,
  })
    .then(({ data }) => data.savedReportName)
    .catch((e) => ({
      reportName: '',
    }));

export const getSectorSymbols = (payload) =>
  API.graphql({
    variables: { request: payload },
    query: getSymbolsBySector,
  }).then(({ data }) => data.getSymbolsBySector);

export const getSymbolWeeklyGains = (payload) =>
  API.graphql({
    variables: { request: payload },
    query: getSymbolWeeklyGainsReport,
  }).then(({ data }) => data.getSymbolWeeklyGainsReport);

export const getWeeklyGainsData = (payload) =>
  API.graphql({
    variables: { request: payload },
    query: getWeeklyGainsReport,
  }).then(({ data }) => data.getWeeklyGainsReport);

export const getPricesOnDates = (payload) =>
  API.graphql({
    variables: { request: payload },
    query: pricesData,
  }).then(({ data }) => data.pricesData);
