import React, { useState, useEffect } from 'react';

import { SettingOutlined } from '@ant-design/icons';
import { Button, Select, Drawer } from 'antd';
import { get, orderBy } from 'lodash';

import { Table, ColumnVisibility } from '../../components';
import { useTheme } from '../../ThemeContext';
import { formatColumn, formatTableData, formatNumber2 } from '../../utils';
import StockNote from './StockNote';
import styles from './style.module.scss';

const { Option } = Select;
interface Props {
  portfolioData: Object;
  isLoading: boolean;
  history: Object;
  setFetchPortfolioData: Function;
  portfolioType: string;
  portfolioName: string;
  setOpenAddStock: Function;
}

const StocksTable: React.FC<Props> = ({
  portfolioData,
  isLoading,
  history,
  setFetchPortfolioData,
  portfolioType,
  portfolioName,
  setOpenAddStock,
}) => {
  const { theme } = useTheme();
  const [openColumnFilter, setOpenColumnFilter] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [record, setRecord] = useState({});
  const [selectedSymbols, setSelectedSymbols] = useState<Array<string>>([]);

  const [sortDetail, setSortDetail] = useState([
    {
      sortBy: 'todayReturns',
      sortOrder: 'DESC',
    },
  ]);
  const data = orderBy(
    get(portfolioData, 'data', []).filter((i) => i.symbol !== 'Total'),
    [sortDetail[0].sortBy],
    [sortDetail[0].sortOrder.toLowerCase()],
  );

  const filteredData = JSON.parse(JSON.stringify(data));
  filteredData.forEach((obj) => delete obj['id']);

  const columns = get(portfolioData, 'columns', {});
  const filteredColumnsData = JSON.parse(JSON.stringify(columns));
  delete filteredColumnsData['id'];
  const [tableColumns, setTableColumns] = useState(
    Object.keys(columns).map((col, index) => ({
      key: col,
      name: col,
      value: columns[col],
      visible: true,
      index: index,
    })),
  );

  useEffect(() => {
    setTableColumns(
      Object.keys(filteredColumnsData).map((col, index) => ({
        key: col,
        name: col,
        value: columns[col],
        visible: true,
        index: index,
      })),
    );
  }, [columns]);

  const onColumnFilter = (filteredColumns) => {
    setTableColumns(filteredColumns);
  };

  // let summary = get(portfolioData, 'data', []).filter(
  //   (i) => i.symbol === 'Total',
  // );
  // let columnToShow = tableColumns
  //   .filter((col) => col.visible !== false && col.value)
  //   .map((d) => d.key);
  // summary = summary.map((item) => {
  //   let obj = {};
  //   columnToShow.forEach((key) => (obj[key] = item[key]));
  //   return obj;
  // });

  const handleSort = (sortBy: string, sortOrder: string) => {
    // onSort(sortBy, sortOrder, multi);
    setSortDetail([
      {
        sortBy: sortBy,
        sortOrder: sortOrder,
      },
    ]);
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    const { field, order } = sorter;
    if (order === 'ascend') {
      handleSort(field, 'ASC');
    } else {
      handleSort(field, 'DESC');
    }
  };

  const total = data ? data.length : 0;
  const symbols = data.map((d) => d.symbol);

  const handleSelect = (symbol: string) => {
    setSelectedSymbols([...selectedSymbols, symbol]);
  };

  const handleDeSelect = (symbol: string) => {
    const newSymbols = selectedSymbols.filter((s) => s !== symbol);
    setSelectedSymbols(newSymbols);
  };

  const showDetail = (rec) => {
    data.forEach((i) => {
      if (i.symbol === rec['symbol']) {
        rec['id'] = i.id;
        return;
      }
    });
    setRecord(rec);
    setShowDetails(true);
  };
  const handleChange = (symbols: [string]) => {
    if (!symbols.length) {
      setSelectedSymbols([]);
    }
  };
  const getSummary = () => {
    let summary = [];
    if (selectedSymbols.length) {
      const rows = data.filter((d) => selectedSymbols.includes(d.symbol));
      // console.log('summary', rows, summary);
      let newSummary = rows.reduce((pv, cv) => {
        return {
          avg_cost: null,
          dollarChange: null,
          equity: +cv.equity + (+pv.equity || 0),
          lastSale: null,
          name: null,
          no_of_shares: null,
          note: null,
          symbol: 'Total',
          todayReturns: +cv.todayReturns + (+pv.todayReturns || 0),
          todayReturnsPerc: +cv.todayReturnsPerc + (+pv.todayReturnsPerc || 0),
          totalCost: +cv.totalCost + (+pv.totalCost || 0),
          totalReturns: +cv.totalReturns + (+pv.totalReturns || 0),
          totalReturnsPerc: +cv.totalReturnsPerc + (+pv.totalReturnsPerc || 0),
        };
      }, {});
      newSummary.todayReturnsPerc = newSummary.todayReturnsPerc / rows.length;
      newSummary.totalReturnsPerc = newSummary.totalReturnsPerc / rows.length;
      summary = [newSummary];
    } else {
      summary = get(portfolioData, 'data', []).filter(
        (i) => i.symbol === 'Total',
      );
    }

    let columnToShow = tableColumns
      .filter((col) => col.visible !== false && col.value)
      .map((d) => d.key);
    summary = summary.map((item) => {
      let obj = {};
      columnToShow.forEach((key) => (obj[key] = item[key]));
      return obj;
    });
    return summary;
  };
  // console.log('summary', summary);
  return (
    <div className={styles.stockTableContainer} id="stockTable">
      <div className={styles.stockTableTitle}>
        <Select
          mode="tags"
          // size="default"
          placeholder="Please select Symbol"
          allowClear={true}
          onChange={(value: [string]) => handleChange(value)}
          // style={{ width: 300 }}
          className={styles.vSelectSymbolNew}
          showArrow
          showSearch={false}
          onSelect={(value: string) => handleSelect(value)}
          onDeselect={(value: string) => handleDeSelect(value)}
        >
          {symbols.map((s) => (
            <Option key={s}>{s}</Option>
          ))}
        </Select>
        <p className={styles.portfolioName}>
          {portfolioType === 'real'
            ? portfolioName
              ? portfolioName
              : 'Combined'
            : ''}
        </p>
        <div className={styles.actionButtons}>
          {get(portfolioData, 'data', []).length > 0 &&
            (portfolioName || portfolioType !== 'real') && (
              <Button
                size="middle"
                onClick={() => {
                  setOpenAddStock(true);
                }}
                disabled={isLoading}
              >
                {portfolioType === 'real' ? 'Add/Edit' : 'Buy/Sell'}
              </Button>
            )}
          <Button
            icon={<SettingOutlined />}
            size={20}
            onClick={() => {
              setOpenColumnFilter(!openColumnFilter);
            }}
            disabled={isLoading}
          >
            Column Visibility
          </Button>
        </div>
      </div>

      <>
        <Table
          scroll={{ y: true }}
          // scroll={{ x: true }}
          columns={formatColumn(
            tableColumns.filter((col) => col.visible !== false && col.value),
            true,
            sortDetail,
            history,
            showDetail,
            false,
            'stock_table',
            null,
            null,
            theme,
          )}
          dataSource={formatTableData(
            selectedSymbols.length > 0
              ? filteredData.filter((d) =>
                  selectedSymbols.includes(d['symbol']),
                )
              : filteredData,
          )}
          pagination={false}
          size="small"
          bordered={true}
          loading={isLoading}
          id="allPicksId"
          style={{ margin: '20px 0 0 0' }}
          summary={getSummary()}
          source="stock_table"
          onChange={onTableChange}
        />
        {total > 0 && (
          <p className={styles.showingResultText}>
            {`Showing 1 to ${total} of ${formatNumber2(total)} entries`}{' '}
          </p>
        )}
      </>
      {openColumnFilter && (
        <ColumnVisibility
          visible={openColumnFilter}
          onCancel={() => setOpenColumnFilter(false)}
          // onFilter={onFilter}
          tableColumns={tableColumns}
          onColumnFilter={onColumnFilter}
        />
      )}
      {showDetails && (
        <Drawer
          title="Stock Note"
          width={720}
          onClose={() => setShowDetails(false)}
          visible={true}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <StockNote
            onClose={() => setShowDetails(false)}
            // date={moment(get(record, 'date')).format('YYYY-MM-DD')}
            data={record}
            source={'stock'}
            setFetchPortfolioData={setFetchPortfolioData}
            editable={portfolioName.length > 0 || portfolioType !== 'real'}
          />
        </Drawer>
      )}
    </div>
  );
};

export default StocksTable;
