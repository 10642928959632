import React from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';

import styles from '../style.module.scss';

const StudentPrivacyPolicy: React.FC<Props> = ({ history }) => {
  return (
    <>
      <div
        style={{ marginTop: 50, marginLeft: 50 }}
        className={styles.vGoBackSaveBar}
      >
        <p onClick={() => history.goBack()} className={styles.vGoBackBtnBlock}>
          <span className={styles.vGoBackBtn}>
            <ArrowLeftOutlined /> Go Back
          </span>
        </p>
      </div>
      <div className={styles.PrivacyPolicyBox}>
        <div className={styles.PrivacyPolicyContent}>
          <h2>THINKSABIO, INC. WEBSITE PRIVACY POLICY</h2>
          <h3>Last Modified: October 18, 2021</h3>
          <h5>
            PLEASE READ THIS POLICY CAREFULLY TO UNDERSTAND HOW WE TREAT YOUR
            PERSONAL INFORMATION AND YOUR CHOICES AND RIGHTS. IF YOU DO NOT
            AGREE WITH THE TERMS AND CONDITIONS OF THIS POLICY, YOU SHOULD NOT
            ACCESS OR USE THE WEBSITE.
          </h5>
          <h5>
            THE WEBSITE IS INTENDED FOR USERS LOCATED IN THE UNITED STATES. IT
            IS NOT INTENDED FOR USERS LOCATED IN OTHER COUNTRIES, INCLUDING THE
            EUROPEAN UNION AND THE EUROPEAN ECONOMIC AREA.
          </h5>
          <h3>Introduction</h3>
          <p>
            THINKSABIO, INC., together with its affiliates, subsidiaries,
            agents, and licensors (collectively <strong>“THINKSABIO”</strong>)
            (also referred to herein as “<strong>we</strong>,” “
            <strong>us</strong>,” and “<strong>our</strong>”) is committed to
            protecting the privacy and security of the personal information we
            collect, use, share, and otherwise process as part of our business.
            We also believe in transparency, and we are committed to informing
            you about how we treat the data we collect and process.
          </p>
          <p>
            <b>When does this Policy apply?</b> This Privacy Policy (the “
            <strong>Policy</strong>”) describes our practices regarding the
            collection of personal information when you visit the THINKSABIO
            website (the “<strong>Website</strong>”). It does not apply to
            information collected from other sources.
          </p>
          <h4>What information do we collect?</h4>
          <p>
            We collect the following information for the purposes described in
            the “How Do We Use and Share Your Information?” section of this
            Policy.
          </p>
          <p>
            <b>Contact Information: </b>Depending on how you interact with us
            via the Website, we may collect your name, email address, and phone
            number. We may also collect information about your communication
            preferences, including whether you would like to receive text
            messages and/or emails from us.{' '}
          </p>
          <p>
            <b>User Account Registration: </b>In order to access certain
            portions of the Website, you may be required to create and register
            a user account. If you choose to create a user account, we will
            collect your name, email address, and phone number, in addition to
            the username and password that you choose.{' '}
          </p>
          <p>
            <b>Automated Information: </b>When you visit the Website, we
            automatically collect information from your browser or your mobile
            device, which includes the date and time of your visit, your
            Internet Protocol (IP) address or unique device identifier, domain
            server, your browser type, access time, cookies, and data about
            which pages you visit in order to allow us to operate and provide
            the Website and our services. This information is used to understand
            how you interact with the Website and to provide you with a more
            personalized experience.{' '}
          </p>
          <p>
            <b>Your Communications: </b>When you communicate with us, we may
            retain your communications in order to process and respond to them,
            as well as to improve our services. We may use your email address to
            communicate with you about our products and services or send you
            periodic emails if you decide to opt-in to any of our mailing lists.
            If at any time you would like to unsubscribe from receiving future
            emails, you may unsubscribe using the link at the bottom of each
            email.{' '}
          </p>
          <p>
            <b>Location Information: </b>When you use the Website, we may
            collect information about your location and use of the Website,
            including your interaction with advertising and analytics services
            on the Website, in order to (a) serve you the content and
            functionality you request and (b) maintain the privacy and security
            of the Website and services. Location information collected may
            include your Internet Protocol (IP) address and unique device
            identifier.
          </p>
          <p>
            <b>Cookies and Similar Technologies: </b>When you visit the Website,
            we use cookies and similar technologies as described in the{' '}
            <i>“Cookies and Similar Technologies”</i> section of this Policy.
          </p>
          <p>
            <b>Contact Us: </b>If you choose to contact us via the Contact Us
            form on the Website, we will collect your name, phone number, and
            email address, and the questions or comments that you choose to
            provide. We may request that you provide additional user information
            relevant to the support requested. This information helps us direct
            your request and respond more quickly to your needs.
          </p>
          <h6>Cookies and Similar Technologies</h6>
          <h6>
            <span>First and Third-Party Cookies</span>
          </h6>
          <p>
            A “cookie” is a small file created by a web server that can be
            stored on your device (if you allow) for use either during a
            particular browsing session (a “session” cookie) or a future
            browsing session (a “persistent” or “permanent” cookie). “Session”
            cookies are temporarily stored on your hard drive and only last
            until they expire at the end of your browsing session. “Persistent”
            or “permanent” cookies remain stored on your hard drive until they
            expire or are deleted by you. Local stored objects (or “flash”
            cookies) are used to collect and store information about your
            preferences and navigation to, from, and on a website. First-party
            cookies are set by the website you’re visiting, and they can only be
            read by that site. Third-party cookies are set by a party other than
            that website.
          </p>
          <h6>
            <span>Other Similar Technologies</span>
          </h6>
          <p>
            In addition to cookies, there are other automatic data collection
            technologies, such as Internet tags, web beacons (clear gifs, pixel
            tags, and single-pixel gifs), and navigational data collection (log
            files, server logs, etc.) that can be used to collect data as users
            navigate through and interact with a website:
          </p>
          <ul>
            <li>
              <p>
                <b>Web beacons: </b> These are tiny graphics (sometimes called
                “clear GIFs” or “web pixels”) with unique identifiers that are
                used to understand browsing activity. In contrast to cookies,
                which are stored on a user’s computer hard drive, web beacons
                are rendered invisible on web pages when you open a page.
              </p>
            </li>
            <li>
              <p>
                <b>Social Widgets: </b>These are buttons or icons provided by
                third-party social media platforms that allow you to interact
                with social media services when you view a webpage or mobile app
                screen. These social widgets may collect browsing data, which
                may be received by the third party that provided the widget and
                are controlled by third parties.
              </p>
            </li>
            <li>
              <p>
                <b>UTM Codes: </b>These are strings that can appear in a URL
                (the “Uniform Resource Locator,” which is typically the http or
                https address entered to go to a webpage) when you move from one
                web page or website to another. The string can represent
                information about browsing, such as which advertisement, page,
                or publisher sent the user to the receiving website.
              </p>
            </li>
          </ul>
          <h6>
            <span>
              What Cookies and Similar Technologies Are in Use and Why Do We Use
              Them?
            </span>
          </h6>
          <p>
            <b>Google Marketing: </b>We use Google’s marketing technologies,
            including DoubleClick, to track user activity on the Site and to
            serve personalized advertisements. Your browser is assigned a
            pseudonymous ID to track the ads that have been served to your
            browser and to identify those on which you’ve clicked. The cookies
            enable Google and its partners to select and display ads based on
            your browsing behavior. For more information on how Google uses this
            information, you can visit{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://policies.google.com/technologies/ads"
            >
              https://policies.google.com/technologies/ads
            </a>
            ,
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.google.com/displayvideo/answer/7621162"
            >
              https://support.google.com/displayvideo/answer/7621162
            </a>
            , and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.google.com/google-ads/answer/7548399?hl=en"
            >
              https://support.google.com/google-ads/answer/7548399?hl=en
            </a>
            . To block certain ads served by Google, please visit{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.google.com/ads/answer/2662922"
            >
              https://support.google.com/ads/answer/2662922
            </a>
            . Google’s Privacy Policy is available at:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/policies/privacy"
            >
              https://www.google.com/policies/privacy
            </a>
            .
          </p>
          <p>
            <b>Google Analytics: </b>We use Google Analytics to collect and
            process statistical data about the number of people using the Site
            and to better understand how they find and use the Site. The data
            collected includes data related to your device/browser, your IP
            address, and on-site activities to measure and report statistics
            about user interactions on the Site. Any data collected is used in
            accordance with this Policy and Google’s privacy policy. You may
            learn more about Google Analytics by visiting
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/policies/privacy/partners/"
            >
              https://www.google.com/policies/privacy/partners/
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.google.com/analytics/answer/6004245"
            >
              https://support.google.com/analytics/answer/6004245
            </a>
            . You can learn more about Google’s restrictions on data use by
            visiting the Google Privacy Policy at:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.google.com/policies/privacy"
            >
              https://www.google.com/policies/privacy
            </a>
            . To opt-out of Google Analytics, visit{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://tools.google.com/dlpage/gaoptout"
            >
              https://tools.google.com/dlpage/gaoptout
            </a>{' '}
            and install the opt-out browser add-on feature. For more details,
            visit the “Google Analytics opt-out browser add-on” page (located at{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.google.com/analytics/answer/181881?hl=en)"
            >
              https://support.google.com/analytics/answer/181881?hl=en
            </a>
            ).
          </p>
          <h6>
            <span>Other Third-Party Technologies</span>
          </h6>
          <p>
            Some third parties may use automated data collection technologies to
            collect information about you when you browse the Internet. The
            information they collect about your online browsing activities over
            time and across different websites and other online services may be
            associated with your personal information and used to provide you
            with targeted content. We do not control these third parties’
            technologies or how they may be used. If you have any questions
            about targeted content, you should contact the responsible party
            directly or consult their privacy policies.
          </p>
          <h6>
            <span>Choices About Cookies</span>
          </h6>
          <p>
            Most web browsers are set by default to accept cookies. If you do
            not wish to receive cookies, you may set your browser to refuse all
            or some types of cookies or to alert you when cookies are being sent
            by website tracking technologies and advertising. You may adjust
            your browser settings to opt out of accepting a “persistent” cookie
            and to only accept “session” cookies, but you will need to log in
            each time you want to enjoy the full functionality of the Website.
          </p>
          <p>
            Please be aware that, if you decline the use of cookies, you may not
            have access to the full benefits of the Website. In addition,
            adjusting the cookie settings on the Website may not fully delete
            all of the cookies that have already been created. To delete them,
            visit your web browser settings after you have changed your cookie
            settings on the Website. Additional information is provided below
            about how to disable cookies or manage the cookie settings for some
            of the leading web browsers:
          </p>
          <p>
            <b>Google Chrome: </b>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.google.com/chrome/answer/95647?hl=en"
            >
              https://support.google.com/chrome/answer/95647?hl=en
            </a>
          </p>
          <p>
            <b>Firefox: </b>
            <a
              href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
"
            >
              https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
            </a>
          </p>
          <p>
            <b>Internet Explorer: </b>
            <a
              href="http://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies
"
            >
              http://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies
            </a>
          </p>
          <p>
            <b>Safari: </b>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
            >
              https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.apple.com/en-us/HT201265"
            >
              https://support.apple.com/en-us/HT201265
            </a>
          </p>
          <p>
            To learn how you can manage your Flash cookie settings, visit the
            Flash player settings page on Adobe’s website at:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.macromedia.com/support/documentation/en/flashplayer/help/help09.html"
            >
              https://www.macromedia.com/support/documentation/en/flashplayer/help/help09.html
            </a>
          </p>
          <p>
            For more information on how to modify your browser settings to block
            or filter cookies, visit{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.aboutcookies.org/"
            >
              http://www.aboutcookies.org/
            </a>{' '}
            or{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.cookiecentral.com/faq/"
            >
              http://www.cookiecentral.com/faq/
            </a>
            . You may learn more about internet advertising practices and
            related consumer resources at{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.aboutads.info/consumers/"
            >
              http://www.aboutads.info/consumers/
            </a>
            ,
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.networkadvertising.org/choices"
            >
              http://www.networkadvertising.org/choices
            </a>
            , and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://youronlinechoices.eu/"
            >
              http://youronlinechoices.eu/
            </a>
            .
          </p>
          <h4>How do we use and share your information?</h4>
          <p>
            <b>How do we use the information described in this Policy?</b> To
            the extent permitted by applicable law, we may use the information
            described above in order to:
          </p>
          <ul>
            <li>
              <p>
                Operate and improve our business, services, and the Website;
              </p>
            </li>
            <li>
              <p>
                Provide you with services, products, content, customer service,
                and functionality;
              </p>
            </li>
            <li>
              <p>Honor our Terms of Use and contracts;</p>
            </li>
            <li>
              <p>Manage our relationship with you;</p>
            </li>
            <li>
              <p>Process and complete transactions;</p>
            </li>
            <li>
              <p>
                Improve our products and services and develop new products and
                services;
              </p>
            </li>
            <li>
              <p>
                Communicate with you and respond to your feedback, requests,
                questions, or inquiries;
              </p>
            </li>
            <li>
              <p>Maintain, manage, and improve our relationships with you;</p>
            </li>
            <li>
              <p>
                Ensure the privacy and security of our Website and our services;
              </p>
            </li>
            <li>
              <p>Detect fraud and prevent loss;</p>
            </li>
            <li>
              <p>
                Support and improve the Website, including evaluations of
                functionality and features;
              </p>
            </li>
            <li>
              <p>Improve our customer service;</p>
            </li>
            <li>
              <p>
                Maintain our databases and back-ups, including records of our
                communications with you;
              </p>
            </li>
            <li>
              <p>Promote our products and services;</p>
            </li>
            <li>
              <p>Contact you about other products and services;</p>
            </li>
            <li>
              <p>
                Analyze use of the Website and our services and prepare
                aggregate traffic information;
              </p>
            </li>
            <li>
              <p>
                Accomplish any other purpose related to and/or ancillary to any
                of the purposes and uses described in this Policy for which your
                information was provided to us;
              </p>
            </li>
            <li>
              <p>
                Accomplish another purpose described to you when you provide the
                information, for which you have consented, or for which we have
                a legal basis under law;
              </p>
            </li>
            <li>
              <p>Comply with federal, state, or local laws;</p>
            </li>
            <li>
              <p>
                Comply with a civil, criminal, or regulatory inquiry,
                investigation, subpoena, order, or summons by federal, state, or
                local authorities;
              </p>
            </li>
            <li>
              <p>Cooperate with law enforcement agencies;</p>
            </li>
            <li>
              <p>Exercise or defend legal rights or claims;</p>
            </li>
            <li>
              <p>
                Create, use, retain, or disclose de-identified or aggregated
                data; and
              </p>
            </li>
            <li>
              <p>Any other purpose with your consent.</p>
            </li>
          </ul>
          <p>
            <b>
              How do we disclose or share the information described in this
              Policy?{' '}
            </b>{' '}
            Where permitted by applicable law, we may share the information
            described above in the following contexts:
          </p>
          <p>
            <b>Affiliates: </b>We may share your information with our
            subsidiaries and affiliates and with their respective officers,
            directors, employees, accountants, attorneys and agents.
          </p>
          <p>
            <b>Acquisitions and Similar Transactions: </b>We may disclose your
            information in the event of a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of our company assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding.
          </p>
          <p>
            <b>Disclosures with Your Consent: </b>We may ask if you would like
            us to share your information with other unaffiliated third parties
            who are not described elsewhere in this Policy. We will only
            disclose your information in this context with your consent.{' '}
          </p>
          <p>
            <b>
              Legal Obligations and Rights (Subpoenas, Court Orders, and
              Warrants):{' '}
            </b>
            We may disclose information in response to subpoenas, warrants,
            court orders or other legal process, or to comply with relevant
            laws. We may also share information in order to establish or
            exercise our legal rights or claims; to defend against a legal
            claim; and to investigate, prevent, or take action regarding
            possible illegal activities, suspected fraud, safety of person or
            property, or a violation of our contracts.
          </p>
          <p>
            <b>Third Parties: </b>We may provide information about you to third
            parties that may offer products and services specifically requested
            by you.
          </p>
          <p>
            <b>Service Providers: </b>We may share information with our service
            providers that need access to information to provide operational or
            other support services on our behalf. Among other things, service
            providers help us to administer the Website; support our provision
            of services requested by you; provide technical support; support our
            provision of promotions, sweepstakes, or contests, send
            communications to you; provide payment processing; and assist with
            other legitimate purposes permitted by law.
          </p>
          <p>
            <b>Professional Advisors: </b>We may share your information with our
            insurers and other professional advisors, including attorneys and
            accountants, that need access to your information to provide
            operational or other support services on our behalf.
          </p>
          <p>
            <b>Deidentified or Aggregated Data: </b>We may disclose aggregated
            information or de-identified information about our users that does
            not identify any specific individual, such as groupings of
            demographic data or customer preferences to potential advertisers,
            partners, affiliates, and other third parties.
          </p>
          <h4>How long do we store and use your information?</h4>
          <p>
            We retain and use your information for as long as is necessary to
            fulfill the purposes for which it was collected, to comply with our
            business requirements and legal obligations, to resolve disputes, to
            protect our assets, to provide our services, and to enforce our
            agreements.
          </p>
          <p>
            We may delete your information if we believe it is incomplete,
            inaccurate, or that our continued storage of it is contrary to our
            objectives or legal obligations. When we delete data, it will be
            removed from our active servers and databases, but it may remain in
            our archives when it is not practical or possible to delete it.
          </p>
          <p>
            To the extent permitted by law, we may retain and use anonymous,
            de-identified, or aggregated information for performance reporting,
            benchmarking, and analytic purposes and for product and service
            improvement.
          </p>
          <h4>How do we protect your information?</h4>
          <p>
            We have put security measures in place to protect the personal
            information that you share with us from being accidentally lost,
            used, altered, disclosed, or accessed in an unauthorized manner.
            From time to time, we review our security procedures to consider
            appropriate new technologies and methods.
          </p>
          <p>
            While our security measures seek to protect the personal information
            in our possession, no security system is perfect, and no data
            transmission is 100% secure. As a result, while we strive to protect
            your information, we cannot guarantee or warrant the security of any
            information transmitted to or from the Website. Your use of the
            Website is at your own risk. We cannot guarantee that your data will
            remain secure in all circumstances.
          </p>
          <p>
            The safety and security of your personal information also depends on
            you. Where you use a password to access parts of the Website, you
            are responsible for keeping the username and password confidential.
            Do not share your username and/or password with anyone.
          </p>
          <p>
            If a data breach compromises your personal information, we will
            notify you and any applicable regulator when we are required to do
            so by applicable law.
          </p>
          <h4>Your rights and choices regarding personal information</h4>
          <p>
            Please use the “Contact Us” details provided at the end of this
            Policy to exercise your rights and choices under this Policy. We
            honor such requests when we are required to do so under applicable
            law.
          </p>
          <p>
            <b>Accuracy and Updating Your Information. </b>Our goal is to keep
            your information accurate, current, and complete. If any of the
            information you have provided to us changes, please let us know via
            the “Contact Us” details at the end of this Policy. For instance, if
            your email address changes, you may wish to let us know so that we
            can communicate with you. If you become aware of inaccurate personal
            information about you, you may want to update your information. If
            you have created a user account for the Website, you may access,
            review, and make changes to your registration information by
            following the instructions on the Website. We are not responsible
            for any losses arising from any inaccurate, inauthentic, deficient
            or incomplete personal information that you provide to us.
          </p>
          <p>
            <b>Complaints. </b>If you believe your rights relating to your
            personal information have been violated, please contact us via the
            “Contact Us” details provided at the end of this Policy.
          </p>
          <p>
            <b>Cookies. </b>You may be able to set your browser to refuse all or
            some browser cookies, or to alert you when cookies are being sent.
            If you disable or refuse cookies, please note that some parts of the
            Website may then be inaccessible or not function properly.
          </p>
          <p>
            <b>California Shine the Light. </b>Under California Civil Code
            Section 1798.83, California residents who provide personal
            information in obtaining products or services for personal, family,
            or household use may be entitled to request and obtain from us once
            a calendar year information about the information we shared, if any,
            with other businesses for direct marketing uses. Please be aware
            that not all information sharing is covered by the “Shine the Light”
            requirements and only information on covered sharing, if any, will
            be included in our response. As part of the California Online
            Privacy Protection Act, all users of our App may make any changes to
            their information at any time by contacting us at{' '}
            <a href="mailto:support@thinksabio.com">support@thinksabio.com</a>
          </p>
          <p>
            <b>Nevada Residents. </b>You may submit a verified request to us at{' '}
            <a href="mailto:support@thinksabio.com">support@thinksabio.com</a>{' '}
            to request that we not make any sale (as defined under Nevada law)
            of any covered information (as defined under Nevada law) that we
            have collected or will collect about you. Please provide your name
            and contact information in your request. We will respond to your
            request in accordance with Nevada law.
          </p>
          <h4>Third-party sites and services</h4>
          <p>
            This Policy only applies to the Website, and it does not apply to
            any third-party websites or applications.
          </p>
          <p>
            The Website may contain links to, and media or other content from,
            third parties. These links are to external websites and third
            parties that have their own privacy policies and with which we may
            have no relationship. Because of the dynamic media capabilities of
            the Internet, it may not be clear to you which links are to
            external, third-party websites. If you click on an embedded
            third-party link, you will be redirected away from the Website to
            the external third-party website. You can check the URL to confirm
            that you have left the Website.
          </p>
          <p>
            We cannot and do not (1) guarantee the adequacy of the privacy or
            security practices employed by or the content and media provided by
            any third parties or their websites, (2) control third parties’
            independent collection or use or your information, or (3) endorse
            any third-party information, products, services or websites that may
            be reached through embedded links on the Website.
          </p>
          <p>
            Some third parties may use automated data collection technologies to
            collect information about you when you browse the Internet. The
            information they collect about your online browsing activities over
            time and across different websites and other online services may be
            associated with your personal information and used to provide you
            with targeted content. We do not control these third parties’
            technologies or how they may be used. If you have any questions
            about targeted content, you should contact the responsible party
            directly or consult their privacy policies.
          </p>
          <p>
            Any information provided by you or automatically collected from you
            by a third party will be governed by that party’s privacy policy and
            terms of use. If you are unsure whether a website is controlled,
            affiliated, or managed by us, you should review the privacy policy
            and practices applicable to each linked website.
          </p>
          <p>
            Your use of Google Pay or Apple Pay is subject to the terms and
            conditions, including the privacy policies, of Google and Apple,
            respectively.
          </p>
          <h4>Children’s online privacy protection act</h4>
          <p>
            The Children’s Online Privacy Protection Act (“COPPA”), as well as
            other data privacy regulations, restrict the collection, use, or
            disclosure of personal information from and about children on the
            Internet. Our Website and services are not directed to children
            under 18, nor is information knowingly collected from children under
            the age of 18. No one under the age of 18 may access, browse, or use
            the Website or provide any information to or on the Website. If you
            are under 18, please do not use or provide any information on the
            Website (including, for example, your name, telephone number, email
            address, or username). If we learn that we have collected or
            received personal information from a child under the age of 18
            without a parent’s or legal guardian’s consent, we will take steps
            to stop collecting that information and delete it. If you believe we
            might have any information from or about a child under the age of
            18, please contact us via the “Contact Us” details provided at the
            end of this Policy.
          </p>
          <p>
            For more information about COPPA, please visit the Federal Trade
            Commission's website at:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule. "
            >
              https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule.
            </a>
          </p>
          <h4>Updates and changes to this policy</h4>
          <p>
            We reserve the right, at any time and without notice, to add to,
            change, update, or modify this Policy to reflect any changes to how
            we treat your information or in response to changes in law. Should
            this Policy change, we will post all changes to this Policy on this
            page. Any such changes, updates, or modifications will be effective
            immediately upon posting on the Website. The date on which this
            Policy was last modified is identified at the beginning of this
            Policy.
          </p>
          <p>
            You are expected to, and you acknowledge and agree that it is your
            responsibility to, carefully review this Policy prior to using the
            Website, and from time to time, so that you are aware of any
            changes. Your continued use of the Website after the “Last Updated”
            date will constitute your acceptance of and agreement to such
            changes and to our collection and sharing of your information
            according to the terms of the then-current Policy. If you do not
            agree with this Policy and our practices, you should not use the
            Website.
          </p>
          <h4>Contact us</h4>
          <p>
            For more information, if you have any questions or concerns
            regarding this Policy, or if you want to opt-out from receiving
            future promotional correspondence from us, correspondence from our
            affiliated companies, and withdraw your consent to sharing your
            personally identifiable information with others, you may contact us
            using the information below. Please note, if your communication is
            sensitive, you may wish to contact us by postal mail or telephone.
          </p>
          <p>
            <b>In Writing: </b>6512 Six Forks Rd, Suite #304A, Raleigh NC 27615{' '}
          </p>
          <p>
            <b>By Email: </b>
            <a href="mailto:support@thinksabio.com">support@thinksabio.com</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default StudentPrivacyPolicy;
