import React from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import styles from './styles.module.scss';

interface Props {
  handleClick: Function;
}
const SuccessMessage: React.FC<Props> = ({ handleClick }) => {
  return (
    <div className={styles.contactUsSuccessMsg}>
      <CheckCircleOutlined style={{ fontSize: 50, color: '#1ABD50' }} />
      <h2>Thank You for contacting us.</h2>
      <p>{`Your request has been successfully sent and a ThinkSabio executive will reach out to you soon.`}</p>
      <Button onClick={() => handleClick()}>Send Another</Button>
    </div>
  );
};

export default SuccessMessage;
