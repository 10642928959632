import React, { useEffect, useState } from 'react';

import { Switch, Tabs, Button } from 'antd';
import { get } from 'lodash';
import queryString from 'query-string';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { DeleteIcon } from '../../components';
import {
  getUserPortFolio,
  getUserWalletBalances,
  getPortfolioSnapshot,
  getUserPortFolioMovement,
  getUserPortfolios,
  getTSWatchlistData,
} from '../../graphql';
import {
  setUserPortfolioData,
  setPortfolioSnapshot,
  setUserPortfolioMovementData,
  setUserPortfoliosList,
} from '../../redux/reducers/portfolio';
import { setUserWalletBalance } from '../../redux/reducers/user';
import { errorHandler, green, red } from '../../utils';
import TSWatchlistSymbol from './addTSWatchlistSymbol';
import TSWatchlistDelSymbol from './delTSWatchlistSymbol';
import AddNewPortfolio from './Real/AddNewPortfolio';
import RealPortfolioPage from './Real/RealPortfolioPage';
import styles from './style.module.scss';
import VirtualPortfolioPage from './Virtual/VirtualPortfolioPage';
import TSWatchlistTable from './WatchListTable';
const { TabPane } = Tabs;

interface Props extends RouteComponentProps<any> {
  history: any;
}

const Portfolio: React.FC<Props> = ({ match, history }) => {
  const { portfolioList } = useSelector(
    ({ portfolio }) => ({
      portfolioList: get(portfolio, `portfolios`, []),
    }),
    shallowEqual,
  );
  const arrangeColumns = (columns = {}) => {
    const formattedColumns = Object.keys(columns).map((col, index) => ({
      key: col,
      name: col,
      value: columns[col],
      visible: true,
      index: col === 'symbol' || col === 'symbols' ? 0 : index + 1,
    }));
    return formattedColumns;
  };
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [symbolModal, setSymbolModal] = useState<boolean>(false);
  const [delSymbolModal, setDelSymbolModal] = useState<boolean>(false);
  const [tableData, setTableData] = useState(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [tableColumns, setTableColumns] = useState();
  const [noOfQtrs, setNoOfQtrs] = useState(2);
  const [fetchPortfolioData, setFetchPortfolioData] = useState<Date>(
    new Date(),
  );
  const [openAddForm, setOpenAddForm] = useState<boolean>(false);
  // const [openAddSymbol, setOpenAddSymbol] = useState(false);

  const portfolioType = get(match, 'params.type', 'real');
  const mainTab = get(match, 'params.mainTab', 'Portfolio');
  // const type = get(match, 'params.subType', 'Portfolio').toUpperCase();

  const portfolioQueryData = queryString.parse(
    get(match, 'params.portfolioQueryData', {}),
  );

  const portfolioName = get(portfolioQueryData, 'name', '');

  useEffect(() => {
    setIsLoading(true);
    setLoadingData(true);
    const payload = {
      type: portfolioType,
      name: portfolioName,
    };
    const apis = [
      getPortfolioSnapshot(payload),
      getUserWalletBalances(payload),
      getUserPortfolios(),
      getUserPortFolio(payload),
      getUserPortFolioMovement(payload),
      getTSWatchlistData({
        noOfQtrs: noOfQtrs,
      }),
    ];

    Promise.all(apis)
      .then((res) => {
        dispatch(setPortfolioSnapshot(res[0], portfolioType));
        dispatch(setUserWalletBalance(res[1], portfolioType));
        dispatch(setUserPortfoliosList(res[2]));
        dispatch(setUserPortfolioData(res[3], portfolioType));
        dispatch(setUserPortfolioMovementData(res[4], portfolioType));
        dispatch(setTableData(res[5]));
        dispatch(setTableColumns(arrangeColumns(get(res[5], 'columns'))));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
        setLoadingData(false);
      });
  }, [dispatch, portfolioType, fetchPortfolioData, portfolioName]);

  // useEffect(() => {
  //   setIsLoading(true);
  //   const apis = [
  //     getUserPortFolio({
  //       type: portfolioType,
  //       name: portfolioName,
  //     }),
  //     getUserPortFolioMovement({
  //       type: portfolioType,
  //       name: portfolioName,
  //     }),
  //   ];

  //   Promise.all(apis)
  //     .then((res) => {
  //       dispatch(setUserPortfolioData(res[0], portfolioType));
  //       dispatch(setUserPortfolioMovementData(res[1], portfolioType));
  //     })
  //     .catch(errorHandler)
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }, [dispatch, portfolioType, fetchPortfolioData, portfolioName]);
  useEffect(() => {
    const interval = setInterval(() => {
      setFetchPortfolioData(new Date());
    }, 900000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const portfolioChangeHandler = (checked: boolean) => {
    if (checked) {
      history.push(`/portfolio/${mainTab}/virtual`);
    } else {
      history.push(`/portfolio/${mainTab}/real`);
    }
  };
  const handleCancel = () => {
    setSymbolModal(false);
    setDelSymbolModal(false);
  };
  const handleTabChange = (key: string) => {
    if (key === 'add') {
      setOpenAddForm(true);
    } else if (key === 'combine') {
      const path =
        `/portfolio/${mainTab}/${portfolioType}/` +
        queryString.stringify(
          {},
          {
            skipNull: true,
          },
        );
      history.push(path);
    } else {
      const path =
        `/portfolio/${mainTab}/${portfolioType}/` +
        queryString.stringify(
          {
            name: key,
          },
          {
            skipNull: true,
          },
        );
      history.push(path);
    }
  };
  const handleMainTabChange = (key: string) => {
    if (key === 'tsWatchlist') {
      const path = `/portfolio/tsWatchlist/${portfolioType}`;
      history.push(path);
    } else {
      const path = `/portfolio/Portfolio/${portfolioType}`;
      history.push(path);
    }
  };

  const handleCancelAddPortfolio = () => {
    setOpenAddForm(false);
    // setFetchPortfolioData(new Date());
  };

  const portfoliosTabsList = [...portfolioList];
  if (portfolioList.length > 1) {
    portfoliosTabsList.unshift({
      name: 'Combined',
      type: 'combine',
    });
  }

  if (
    portfolioType === 'real' &&
    !portfolioName &&
    portfolioList.length === 1
  ) {
    const path =
      `/portfolio/${mainTab}/${portfolioType}/` +
      queryString.stringify(
        {
          name: get(portfolioList, '0.name', ''),
        },
        {
          skipNull: true,
        },
      );
    history.push(path);
  }

  const onAdd = () => {
    setOpenAddForm(true);
  };
  const openAddSymbolModal = () => {
    setSymbolModal(true);
  };
  const openDelSymbolModal = () => {
    setDelSymbolModal(true);
  };
  // Modal.confirm({
  //   title: 'Add Symbol',
  //   content: (
  //     <>
  //       <Form name={`addSymbol`} wrapperCol={{ span: 24 }} form={form}>
  //         <Row span={24}>
  //           <Col span={24}>
  //             <Form.Item
  //               name="symbols"
  //               label={'Select Symbols'}
  //               className={styles.vFormSearhNew}
  //             >
  //               <Select
  //                 placeholder="Enter valid symbols"
  //                 mode={'multiple'}
  //                 notFoundContent={
  //                   fetching ? (
  //                     <span className={styles.spinnerStyle}>
  //                       <BounceLoader color={green} loading={true} size={50} />
  //                     </span>
  //                   ) : null
  //                 }
  //                 onSearch={fetchSymbols}
  //                 showSearch={true}
  //                 allowClear={true}
  //                 showArrow={false}
  //                 filterOption={false}
  //                 onSelect={() => setSymbolData([])}
  //                 size="large"
  //               >
  //                 {symbolData.map((d) => (
  //                   <Option key={d.symbol} className={styles.optionStyle}>
  //                     <span className={styles.optionSymbol}>{d.symbol}</span>{' '}
  //                     {d.name}
  //                   </Option>
  //                 ))}
  //               </Select>
  //             </Form.Item>
  //           </Col>
  //         </Row>
  //         {/*{error && <span style={{ color: red }}>{error}</span>}*/}
  //         {/*<Row span={24} justify="start">*/}
  //         {/*  <Form.Item colon={false} style={{ margin: 0, marginTop: 20 }}>*/}
  //         {/*    <Button type="primary" loading={loading} htmlType="submit">*/}
  //         {/*      Create Alert*/}
  //         {/*    </Button>*/}
  //         {/*  </Form.Item>*/}
  //         {/*</Row>*/}
  //       </Form>
  //     </>
  //   ),
  //   onOk: () => form.submit,
  //   okText: 'Confirm',
  // });
  const afterDeleteHandler = () => {
    window.location.reload();
  };
  return (
    <div className={styles.containerBlockPortFolio}>
      <Tabs
        defaultActiveKey={mainTab}
        className={styles.vCenterTabsNew}
        centered
        onChange={handleMainTabChange}
        //id="vTabs1"
        id="Portfolio"
        // hideAdd={portfolioList.length >= 6}
      >
        <TabPane closable={false} tab={'Portfolio'} key={'Portfolio'}></TabPane>
        <TabPane
          closable={false}
          tab={'TS Watchlist'}
          key={'tsWatchlist'}
        ></TabPane>
      </Tabs>
      {mainTab === 'tsWatchlist' && (
        <>
          <div>
            <p
              style={{
                color: 'var(--whitecolor)',
                fontSize: '12px',
                textAlign: 'center',
              }}
            >
              <span style={{ color: 'red' }}>*</span>The Default Sort is based
              on the Date added
            </p>
          </div>
          <div
            style={{
              position: 'absolute',
              top: 150,
              right: 200,
              minHeight: 300,
            }}
          >
            <Button
              size="large"
              onClick={() => {
                openAddSymbolModal();
              }}
              shape="circle"
              disabled={isLoading}
            >
              +
            </Button>
            <Button
              size="large"
              onClick={() => {
                openDelSymbolModal();
              }}
              shape="circle"
              disabled={isLoading}
            >
              <DeleteIcon style={{ color: red }} size={50} />
            </Button>
          </div>
          <div>
            <div
              style={{ minHeight: 400, minWidth: '1400px' }}
              id="healthCheck"
            >
              {loadingData ? (
                <div
                  className={styles.loaderStyle}
                  style={{
                    marginTop: '50px',
                    minHeight: '600px',
                    minWidth: '1400px',
                  }}
                >
                  <BounceLoader
                    color={green}
                    loading={loadingData}
                    size={150}
                  />
                </div>
              ) : (
                <TSWatchlistTable
                  data={tableData}
                  selectedKey={1}
                  isLoading={loadingData}
                  history={history}
                  setTableColumns={setTableColumns}
                  tableColumns={tableColumns}
                  showNoOfQtrs={true}
                  onNoQtrsChange={setNoOfQtrs}
                  noOfQtrs={noOfQtrs}
                  setFetchPortfolioData={setFetchPortfolioData}
                  healthCheckType="institutions"
                ></TSWatchlistTable>
              )}
            </div>
          </div>
        </>
      )}
      <div className={styles.portfolioSwitch}>
        <span>Real Portfolio</span>
        <Switch
          checked={portfolioType === 'virtual'}
          defaultChecked={portfolioType === 'virtual'}
          onChange={portfolioChangeHandler}
        />
        <span>Virtual Portfolio</span>
      </div>
      {portfolioType === 'virtual' && mainTab === 'Portfolio' && (
        <VirtualPortfolioPage
          history={history}
          // portfolioSnapshot={portfolioSnapshot}
          // portfolioData={portfolioData}
          // wallet={wallet}
          portfolioType={portfolioType}
          isLoading={isLoading}
          setFetchPortfolioData={setFetchPortfolioData}
          fetchPortfolioData={fetchPortfolioData}
          match={match}
          // onSort={handleSort}
          // sortDetail={sortDetail}
        />
      )}
      {portfolioType === 'real' &&
        mainTab === 'Portfolio' &&
        !(
          portfolioList.length === 1 && portfolioList[0].name === 'DEFAULT'
        ) && (
          <Tabs
            defaultActiveKey={
              portfolioName
                ? portfolioName
                : get(portfoliosTabsList, '0.name', 'combine')
            }
            className={styles.vCenterTabsNew}
            centered
            type="editable-card"
            onChange={handleTabChange}
            //id="vTabs1"
            id="realPortfolio"
            onEdit={onAdd}
            // hideAdd={portfolioList.length >= 6}
          >
            {portfoliosTabsList.map((p) => (
              <TabPane
                closable={false}
                tab={p.name}
                key={p.type === 'combine' ? p.type : p.name}
              >
                <RealPortfolioPage
                  history={history}
                  // portfolioSnapshot={portfolioSnapshot}
                  // portfolioData={portfolioData}
                  // wallet={wallet}
                  portfolioType={portfolioType}
                  isLoading={isLoading}
                  setFetchPortfolioData={setFetchPortfolioData}
                  fetchPortfolioData={fetchPortfolioData}
                  match={match}
                  portfolioQueryData={portfolioQueryData}
                  afterDeleteHandler={afterDeleteHandler}
                  // onSort={handleSort}
                  // sortDetail={sortDetail}
                />
              </TabPane>
            ))}
          </Tabs>
        )}
      {portfolioType === 'real' &&
        !portfolioList.length &&
        mainTab === 'Portfolio' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 300,
              margin: 'auto',
              width: 900,
              padding: 20,
            }}
          >
            <Button
              size="large"
              onClick={() => {
                setOpenAddForm(true);
              }}
              disabled={isLoading}
            >
              Add Portfolio
            </Button>
          </div>
        )}
      {mainTab === 'Portfolio' &&
        (openAddForm ||
          (portfolioList.length === 1 &&
            portfolioList[0].name === 'DEFAULT')) && (
          <AddNewPortfolio
            handleCancel={handleCancelAddPortfolio}
            visible={
              openAddForm ||
              (portfolioList.length === 1 &&
                portfolioList[0].name === 'DEFAULT')
            }
            isNew={openAddForm}
            portfolioList={portfolioList}
            history={history}
            editing={false}
          />
        )}
      {symbolModal && (
        <TSWatchlistSymbol
          visible={symbolModal}
          handleCancel={handleCancel}
          data={tableData}
        />
      )}
      {delSymbolModal && (
        <TSWatchlistDelSymbol
          visible={delSymbolModal}
          handleCancel={handleCancel}
          data={tableData}
        />
      )}
    </div>
  );
};

export default Portfolio;
