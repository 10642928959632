import React, { useState } from 'react';

import {
  GiftOutlined,
  ShareAltOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import SentToUserIcon from '@rsuite/icons/SentToUser';
import WaitIcon from '@rsuite/icons/Wait';
import 'antd/dist/antd.css';
import 'rsuite/dist/rsuite.css';
import { Form, Input, Col, Row, Button, message } from 'antd';
import { get } from 'lodash';
import { shallowEqual, useSelector } from 'react-redux';
import { Steps } from 'rsuite';

import { referAFriend } from '../../api/RestApi';
import authenticatorConfig from '../../config/authenticatorConfig';
import { red } from '../../utils';
import styles from './styles.module.scss';

interface FormType {
  email: string;
  name: string;
}

const Refer: React.FC = () => {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSteps, setShowSteps] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { oauth } = authenticatorConfig;
  const redirectSignIn = oauth.redirectSignIn;
  const { personalData } = useSelector(
    ({ user }) => ({
      personalData: get(user, 'personalData', {}),
    }),
    shallowEqual,
  );

  const onFinish = (values: FormType) => {
    setError('');
    const { email, name } = values;
    const userId = personalData['id'];
    const payload = {
      userId,
      name,
      referTo: email,
    };
    const promises = [referAFriend(payload)];
    setIsLoading(true);
    Promise.all(promises)
      .then((res) => {
        message.success('Successfully sent a email');
        form.resetFields();
      })
      .catch((err) => setError(err.message))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const share = async () => {
    const userId = personalData['id'];
    const url = redirectSignIn ? redirectSignIn : 'http://localhost:3000/';
    const data = {
      title: 'ThinkSabio',
      text: 'Join ThinkSabio Community',
      url: `${url}sign-up?user=${userId}`,
    };

    try {
      await navigator.share(data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleShare = async () => {
    const url = redirectSignIn ? redirectSignIn : 'http://localhost:3000';
    navigator.clipboard.writeText(`${url}sign-up?user=${personalData['id']}`);
    message.success('copied Referral Url');
  };
  return (
    <>
      <div className={styles.referContainer}>
        <div className={styles.referContent} style={{ marginLeft: '50px' }}>
          <Form
            onFinish={onFinish}
            layout="vertical"
            className={styles.referForm}
            name="refer-form"
            form={form}
            onValuesChange={() => setError('')}
          >
            <h2 className={styles.referHeaderText}>
              Referral And Rewards
              <p style={{ color: '#1abd50' }}>
                Refer a friend and you both can get reward of $9.99 each
                {/*<p style={{ color: 'white', fontSize: 14 }}>*/}
                {/*  Your referal id : {personalData['id']}*/}
                {/*</p>*/}
              </p>
            </h2>

            <Row span={12} className="gutter-row">
              <Col span={24}>
                <p className="ant-form-text">Little about your Friend</p>
              </Col>
              <Col span={10} style={{ marginRight: '70px' }}>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: 'Name is required' }]}
                >
                  <Input placeholder="Name" className={styles.inputForm} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Email is required',
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
              <Col span={24}>
                {/* <Form.Item
                style={{ width: '100%', marginBottom: 0, textAlign: 'center' }}
              > */}
                <div className={styles.btnSectionBox}>
                  <Button
                    type="primary"
                    style={{ width: '150px' }}
                    loading={isLoading}
                    htmlType="submit"
                  >
                    Refer a Friend !
                  </Button>
                </div>
                {/* </Form.Item> */}
                {error && <span style={{ color: red }}>{error}</span>}
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div
        style={{
          color: 'white',
          textAlign: 'center',
          fontSize: 18,
          marginTop: '20px',
          marginLeft: '192px',
          marginBottom: '30px',
          width: '500px',
        }}
      >
        <div
          style={{
            marginBottom: '10px',
            marginLeft: '186px',
            width: '120px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Button
              type="primary"
              shape="circle"
              icon={<ShareAltOutlined />}
              size="middle"
              onClick={() => share()}
            />
            Share
          </div>
          <div>
            <Button
              type="primary"
              shape="circle"
              icon={<CopyOutlined />}
              size="middle"
              onClick={() => handleShare()}
            />
            Copy
          </div>
        </div>
        <a
          onClick={() => {
            setShowSteps(!showSteps);
          }}
        >
          How it Works ?
        </a>
      </div>
      {showSteps && (
        <Steps
          current={3}
          currentStatus={'finish'}
          style={{ width: '650px', marginLeft: '130px' }}
        >
          <Steps.Item
            title="Invite"
            icon={<SentToUserIcon style={{ fontSize: 20 }} />}
            description="Refer your friends by sharing a unique referral link"
          />
          <Steps.Item
            title="Verify Signup"
            icon={<WaitIcon style={{ fontSize: 20 }} />}
            description="Ask your friend to signup thinksabio using referral link."
          />
          <Steps.Item
            title="Get Rewards"
            icon={<GiftOutlined style={{ fontSize: 20 }} />}
            description="You and your friend will get reward worth of $9.99/each once your friend subscribes for yearly plan"
          />
        </Steps>
      )}
    </>
  );
};

export default Refer;
