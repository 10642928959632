import initialState from '../initialState';
// Actions

const SET_TODAYS_PICKS = 'picks/SET_TODAYS_PICKS';
const SET_DAILY_PICKS = 'picks/SET_DAILY_PICKS';
const SET_PICKS_QUERY_DATA = 'picks/SET_PICKS_QUERY_DATA';
const SET_QUARTERLY_PICKS = 'picks/SET_QUARTERLY_PICKS';
const RESET_PICKS = 'picks/RESET_PICKS';
const SET_ALL_TIME_PICKS = 'picks/SET_ALL_TIME_PICKS';
const SET_BIO_PICKS = 'picks/SET_BIO_PICKS';
const SET_CATALYST_PICKS = 'picks/SET_CATALYST_PICKS';

// Reducer
export default function reducer(state = initialState.picks, action = {}) {
  switch (action.type) {
    case SET_TODAYS_PICKS:
      return {
        ...state,
        today: action.payload,
      };
    case SET_DAILY_PICKS:
      return {
        ...state,
        daily: action.payload,
      };
    case SET_PICKS_QUERY_DATA:
      return {
        ...state,
        queryData: {
          ...state.queryData,
          [action.payload.type]: action.payload.data,
        },
      };
    case SET_QUARTERLY_PICKS:
      return {
        ...state,
        quarterly: action.payload,
      };
    case SET_ALL_TIME_PICKS:
      return {
        ...state,
        alltime: action.payload,
      };

    case SET_BIO_PICKS:
      return {
        ...state,
        bio: action.payload,
      };
    case SET_CATALYST_PICKS:
      return {
        ...state,
        catalyst: action.payload,
      };

    case RESET_PICKS:
      return {};
    default:
      return state;
  }
}

// Action Creators

export function setTodaysPicks(data) {
  return {
    type: SET_TODAYS_PICKS,
    payload: data,
  };
}

export function setDailyPicks(data) {
  return {
    type: SET_DAILY_PICKS,
    payload: data,
  };
}

// export function setPickQueryData(data, type) {
//   return {
//     type: SET_PICKS_QUERY_DATA,
//     payload: { type: type, data: data },
//   };
// }

export function setQuarterlyPicks(data) {
  return {
    type: SET_QUARTERLY_PICKS,
    payload: data,
  };
}

export function setAllTimePicks(data) {
  return {
    type: SET_ALL_TIME_PICKS,
    payload: data,
  };
}
export function setBioPicks(data) {
  return {
    type: SET_BIO_PICKS,
    payload: data,
  };
}
export function setCatalystPicks(data) {
  return {
    type: SET_CATALYST_PICKS,
    payload: data,
  };
}
export function resetPicks() {
  return {
    type: RESET_PICKS,
  };
}
